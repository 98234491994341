import React, { useEffect,useState } from 'react';
import Image from 'next/image';

import Popup from 'reactjs-popup';

import { useTranslation } from './TranslationContext';
import { useRouter } from 'next/router';

export const SVG = ({ region, RegionData }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const openPopup = (regionValue) => {
    setSelectedRegion(regionValue);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setSelectedRegion(null);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (region)
      openPopup(region);
  }, [region]);

  const regionDataArray = RegionData?.data;

  const handleAndColorList = regionDataArray?.map((item) => ({
    handle: item.handle,
    color: item.color?.code,
  }));

  const agstafaColor =
    handleAndColorList?.find((item) => item.handle === 'agstafa')?.color ||
    null;
  const abseronColor =
    handleAndColorList?.find((item) => item.handle === 'abseron')?.color ||
    null;
  const agcabediColor =
    handleAndColorList?.find((item) => item.handle === 'agcabedi')?.color ||
    null;
  const agdamColor =
    handleAndColorList?.find((item) => item.handle === 'agdam')?.color || null;
  const agsuColor =
    handleAndColorList?.find((item) => item.handle === 'agsu')?.color || null;
  const astaraColor =
    handleAndColorList?.find((item) => item.handle === 'astara')?.color || null;
  const agdasColor =
    handleAndColorList?.find((item) => item.handle === 'agdas')?.color || null;
  const babekColor =
    handleAndColorList?.find((item) => item.handle === 'babek')?.color || null;
  const balakenColor =
    handleAndColorList?.find((item) => item.handle === 'balaken')?.color ||
    null;
  const berdeColor =
    handleAndColorList?.find((item) => item.handle === 'berde')?.color || null;
  const beyleqanColor =
    handleAndColorList?.find((item) => item.handle === 'beyleqan')?.color ||
    null;
  const bilesuvarColor =
    handleAndColorList?.find((item) => item.handle === 'bilesuvar')?.color ||
    null;
  const cebrayilColor =
    handleAndColorList?.find((item) => item.handle === 'cebrayil')?.color ||
    null;
  const celilabadColor =
    handleAndColorList?.find((item) => item.handle === 'celilabad')?.color ||
    null;
  const culfaColor =
    handleAndColorList?.find((item) => item.handle === 'culfa')?.color || null;
  const daskesenColor =
    handleAndColorList?.find((item) => item.handle === 'daskesen')?.color ||
    null;
  const fuzuliColor =
    handleAndColorList?.find((item) => item.handle === 'fuzuli')?.color || null;
  const genceColor =
    handleAndColorList?.find((item) => item.handle === 'gence')?.color || null;
  const gedebeyColor =
    handleAndColorList?.find((item) => item.handle === 'gedebey')?.color ||
    null;
  const goranboyColor =
    handleAndColorList?.find((item) => item.handle === 'goranboy')?.color ||
    null;
  const goycayColor =
    handleAndColorList?.find((item) => item.handle === 'goycay')?.color || null;
  const haciqabulColor =
    handleAndColorList?.find((item) => item.handle === 'haciqabul')?.color ||
    null;
  const goygolColor =
    handleAndColorList?.find((item) => item.handle === 'goygol')?.color || null;
  const imisliColor =
    handleAndColorList?.find((item) => item.handle === 'imisli')?.color || null;
  const ismayilliColor =
    handleAndColorList?.find((item) => item.handle === 'ismayilli')?.color ||
    null;
  const kelbecerColor =
    handleAndColorList?.find((item) => item.handle === 'kelbecer')?.color ||
    null;
  const kengerliColor =
    handleAndColorList?.find((item) => item.handle === 'kengerli')?.color ||
    null;
  const kurdemirColor =
    handleAndColorList?.find((item) => item.handle === 'kurdemir')?.color ||
    null;
  const lacinColor =
    handleAndColorList?.find((item) => item.handle === 'lacin')?.color || null;
  const lenkeranColor =
    handleAndColorList?.find((item) => item.handle === 'lenkeran')?.color ||
    null;
  const lerikColor =
    handleAndColorList?.find((item) => item.handle === 'lerik')?.color || null;
  const masalliColor =
    handleAndColorList?.find((item) => item.handle === 'masalli')?.color ||
    null;
  const mingecevirColor =
    handleAndColorList?.find((item) => item.handle === 'mingecevir')?.color ||
    null;
  const naftalanColor =
    handleAndColorList?.find((item) => item.handle === 'naftalan')?.color ||
    null;
  const neftcalaColor =
    handleAndColorList?.find((item) => item.handle === 'neftcala')?.color ||
    null;
  const naxcivanColor =
    handleAndColorList?.find((item) => item.handle === 'naxcivan')?.color ||
    null;
  const oguzColor =
    handleAndColorList?.find((item) => item.handle === 'oguz')?.color || null;
  const ordubadColor =
    handleAndColorList?.find((item) => item.handle === 'ordubad')?.color ||
    null;
  const qaxColor =
    handleAndColorList?.find((item) => item.handle === 'qax')?.color || null;
  const qazaxColor =
    handleAndColorList?.find((item) => item.handle === 'qazax')?.color || null;
  const qubaColor =
    handleAndColorList?.find((item) => item.handle === 'quba')?.color || null;
  const qebeleColor =
    handleAndColorList?.find((item) => item.handle === 'qebele')?.color || null;
  const qubadliColor =
    handleAndColorList?.find((item) => item.handle === 'qubadli')?.color ||
    null;
  const qobustanColor =
    handleAndColorList?.find((item) => item.handle === 'qobustan')?.color ||
    null;
  const qusarColor =
    handleAndColorList?.find((item) => item.handle === 'qusar')?.color || null;
  const sumqayitColor =
    handleAndColorList?.find((item) => item.handle === 'sumqayit')?.color ||
    null;
  const sabirabadColor =
    handleAndColorList?.find((item) => item.handle === 'sabirabad')?.color ||
    null;
  const sederekColor =
    handleAndColorList?.find((item) => item.handle === 'sederek')?.color ||
    null;
  const sahbuzColor =
    handleAndColorList?.find((item) => item.handle === 'sahbuz')?.color || null;
  const sekiColor =
    handleAndColorList?.find((item) => item.handle === 'seki')?.color || null;
  const salyanColor =
    handleAndColorList?.find((item) => item.handle === 'salyan')?.color || null;
  const samaxiColor =
    handleAndColorList?.find((item) => item.handle === 'samaxi')?.color || null;
  const serurColor =
    handleAndColorList?.find((item) => item.handle === 'serur')?.color || null;
  const saatliColor =
    handleAndColorList?.find((item) => item.handle === 'saatli')?.color || null;
  const sabranColor =
    handleAndColorList?.find((item) => item.handle === 'sabran')?.color || null;
  const siyezenColor =
    handleAndColorList?.find((item) => item.handle === 'siyezen')?.color ||
    null;
  const semkirColor =
    handleAndColorList?.find((item) => item.handle === 'semkir')?.color || null;
  const samuxColor =
    handleAndColorList?.find((item) => item.handle === 'samux')?.color || null;
  const sirvanColor =
    handleAndColorList?.find((item) => item.handle === 'sirvan')?.color || null;
  const susaColor =
    handleAndColorList?.find((item) => item.handle === 'susa')?.color || null;
  const terterColor =
    handleAndColorList?.find((item) => item.handle === 'terter')?.color || null;
  const tovuzColor =
    handleAndColorList?.find((item) => item.handle === 'tovuz')?.color || null;
  const ucarColor =
    handleAndColorList?.find((item) => item.handle === 'ucar')?.color || null;
  const xankendiColor =
    handleAndColorList?.find((item) => item.handle === 'xankendi')?.color ||
    null;
  const xacmazColor =
    handleAndColorList?.find((item) => item.handle === 'xacmaz')?.color || null;
  const xocaliColor =
    handleAndColorList?.find((item) => item.handle === 'xocali')?.color || null;
  const xiziColor =
    handleAndColorList?.find((item) => item.handle === 'xizi')?.color || null;
  const xocavendColor =
    handleAndColorList?.find((item) => item.handle === 'xocavend')?.color ||
    null;
  const yardimliColor =
    handleAndColorList?.find((item) => item.handle === 'yardimli')?.color ||
    null;
  const zengilanColor =
    handleAndColorList?.find((item) => item.handle === 'zengilan')?.color ||
    null;
  const zaqatalaColor =
    handleAndColorList?.find((item) => item.handle === 'zaqatala')?.color ||
    null;
  const zerdabColor =
    handleAndColorList?.find((item) => item.handle === 'zerdab')?.color || null;

  const bakiColor =
    handleAndColorList?.find((item) => item.handle === 'baki')?.color || null;
  const yevlaxColor =
    handleAndColorList?.find((item) => item.handle === 'yevlax')?.color || null;

  return (
    <div>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1920 1080" filter="url(#border)">
        <defs>
          <filter id="border" x="-50%" y="-50%" width="200%" height="200%">
            <feMorphology result="expanded" operator="dilate" radius="1" />
            <feFlood floodColor="white" result="border-color" />
            <feComposite in2="expanded" operator="in" result="border" />
            <feMerge>
              <feMergeNode in="border" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g>
          <g
            className={`st0 ${region == 'agstafa' ? 'active' : null}`}
            onClick={() => openPopup('agstafa')}
          >
            <g>
              <polygon
                className="st1"
                points="423.31,181.31 428.95,242.52 419.55,243.49 413.9,182.27 			"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="431.86,182.32 437.51,243.54 428.95,242.52 423.31,181.31 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="447.78,189.46 453.43,250.67 437.51,243.54 431.86,182.32 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="413.9,182.27 419.55,243.49 368.18,259.77 362.53,198.55 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="452.34,190.47 457.99,251.68 453.43,250.67 447.78,189.46 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="362.53,198.55 368.18,259.77 367.83,260.73 362.18,199.52 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="458.3,190.68 463.95,251.9 457.99,251.68 452.34,190.47 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="468.32,189.88 473.96,251.09 463.95,251.9 458.3,190.68 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="471.6,190.55 477.24,251.76 473.96,251.09 468.32,189.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="362.51,200.8 368.16,262.01 367.83,260.73 362.18,199.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="360.12,201.29 365.77,262.51 365.37,260.74 359.72,199.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="474.25,193.43 479.9,254.65 477.24,251.76 471.6,190.55 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="371.92,209.08 377.56,270.3 368.16,262.01 362.51,200.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="481.06,199.17 486.71,260.38 479.9,254.65 474.25,193.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="369.87,209.98 375.52,271.19 365.77,262.51 360.12,201.29 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="376.27,211.33 381.92,272.54 377.56,270.3 371.92,209.08 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="374.65,212.46 380.3,273.67 375.52,271.19 369.87,209.98 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="387.92,214.16 393.57,275.37 381.92,272.54 376.27,211.33 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="386.21,215.24 391.86,276.46 380.3,273.67 374.65,212.46 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="397.11,219.62 402.76,280.83 393.57,275.37 387.92,214.16 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="395.38,220.69 401.03,281.9 391.86,276.46 386.21,215.24 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="402.6,221.86 408.25,283.07 402.76,280.83 397.11,219.62 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="517.62,211.96 523.27,273.17 486.71,260.38 481.06,199.17 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="401.16,223.06 406.81,284.27 401.03,281.9 395.38,220.69 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="534.69,215.63 540.33,276.85 523.27,273.17 517.62,211.96 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="433.28,226.77 438.93,287.99 408.25,283.07 402.6,221.86 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="539.97,217.51 545.62,278.73 540.33,276.85 534.69,215.63 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="434.81,227.25 440.46,288.47 438.93,287.99 433.28,226.77 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="539.97,217.51 545.62,278.73 545.52,279.21 539.87,218 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="432.16,228.08 437.8,289.29 406.81,284.27 401.16,223.06 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="539.87,218 545.52,279.21 541.95,279.92 536.3,218.71 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="536.3,218.71 541.95,279.92 534.53,280.71 528.89,219.5 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="542.56,216.73 548.21,277.94 547.75,280.17 542.1,218.96 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="542.1,218.96 547.75,280.17 542.32,281.24 536.67,220.03 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="536.67,220.03 542.32,281.24 535.42,281.99 529.77,220.78 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="434.81,227.25 440.46,288.47 439.66,291.56 434.01,230.34 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="528.89,219.5 534.53,280.71 525.21,285.09 519.56,223.87 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="529.77,220.78 535.42,281.99 528.06,285.44 522.41,224.23 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="434.01,230.34 439.66,291.56 438.47,297 432.82,235.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="523.62,231.02 529.27,292.23 525.21,285.09 519.56,223.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="532.81,237.98 538.46,299.2 529.27,292.23 523.62,231.02 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="432.82,235.79 438.47,297 424.87,309.86 419.22,248.64 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="535.87,239.59 541.52,300.8 538.46,299.2 532.81,237.98 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="535.87,239.59 541.52,300.8 533.84,302.34 528.19,241.12 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="528.19,241.12 533.84,302.34 521.36,304.47 515.71,243.26 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="419.22,248.64 424.87,309.86 425.05,313.52 419.4,252.31 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="540.54,240.07 546.19,301.29 534.36,303.63 528.71,242.42 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="528.71,242.42 534.36,303.63 521.99,305.77 516.34,244.55 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="515.71,243.26 521.36,304.47 511.76,307.73 506.11,246.51 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="419.4,252.31 425.05,313.52 420.17,316.28 414.52,255.07 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="516.34,244.55 521.99,305.77 513.02,308.82 507.37,247.6 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="414.52,255.07 420.17,316.28 418.79,319.12 413.14,257.91 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="482.91,249.53 488.56,310.75 476.85,314.06 471.2,252.85 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="506.11,246.51 511.76,307.73 504.19,312.24 498.54,251.03 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="498.54,251.03 504.19,312.24 488.56,310.75 482.91,249.53 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="483.06,250.96 488.7,312.18 479.38,314.82 473.73,253.6 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="499.21,252.52 504.86,313.73 488.7,312.18 483.06,250.96 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="507.37,247.6 513.02,308.82 504.86,313.73 499.21,252.52 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="413.14,257.91 418.79,319.12 418.34,321.99 412.69,260.78 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="471.2,252.85 476.85,314.06 477.39,321.1 471.74,259.88 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="473.73,253.6 479.38,314.82 479.82,320.88 474.17,259.66 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="412.69,260.78 418.34,321.99 416.87,329.43 411.22,268.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="472.34,266.12 477.99,327.34 477.39,321.1 471.74,259.88 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="411.22,268.21 416.87,329.43 414.11,336.86 408.46,275.65 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="408.46,275.65 414.11,336.86 414.02,337.62 408.37,276.41 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="410.29,276.11 415.93,337.33 414.02,337.62 408.37,276.41 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="419.35,276.33 425,337.55 415.93,337.33 410.29,276.11 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="410.12,277.49 415.77,338.7 411.37,339.48 405.72,278.26 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="472.34,266.12 477.99,327.34 471.67,334.13 466.03,272.92 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="416.75,277.65 422.4,338.86 415.77,338.7 410.12,277.49 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="474.84,266.28 480.49,327.49 473.51,334.99 467.86,273.78 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="446.88,277.77 452.53,338.99 443.15,339.62 437.51,278.41 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="455.81,277.06 461.46,338.28 452.53,338.99 446.88,277.77 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="466.03,272.92 471.67,334.13 461.46,338.28 455.81,277.06 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="446.93,279.14 452.57,340.36 444,340.93 438.35,279.72 			"
              />
            </g>
            <g>
              <polygon
                className="st57"
                points="456.52,278.37 462.17,339.59 452.57,340.36 446.93,279.14 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="467.86,273.78 473.51,334.99 462.17,339.59 456.52,278.37 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="419.35,276.33 425,337.55 424.25,343.5 418.6,282.29 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="437.51,278.41 443.15,339.62 436.16,343.09 430.52,281.88 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="438.35,279.72 444,340.93 437.68,344.07 432.03,282.85 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="430.52,281.88 436.16,343.09 428.77,346.91 423.12,285.69 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="432.03,282.85 437.68,344.07 430.48,347.79 424.84,286.57 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="418.6,282.29 424.25,343.5 418.69,349.97 413.04,288.75 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="415.03,290.75 420.67,351.97 418.69,349.97 413.04,288.75 			"
              />
            </g>
            <g>
              <polygon
                className={`st59`}
                style={{ fill: agstafaColor ? agstafaColor : null }}
                points="423.31,181.31 431.86,182.32 447.78,189.46 452.34,190.47 458.3,190.68 468.32,189.88 
471.6,190.55 474.25,193.43 481.06,199.17 517.62,211.96 534.69,215.63 539.97,217.51 539.87,218 536.3,218.71 528.89,219.5 
519.56,223.87 523.62,231.02 532.81,237.98 535.87,239.59 528.19,241.12 515.71,243.26 506.11,246.51 498.54,251.03 
482.91,249.53 471.2,252.85 471.74,259.88 472.34,266.12 466.03,272.92 455.81,277.06 446.88,277.77 437.51,278.41 
430.52,281.88 423.12,285.69 417.35,291.34 415.03,290.75 413.04,288.75 418.6,282.29 419.35,276.33 410.29,276.11 
408.37,276.41 408.46,275.65 411.22,268.21 412.69,260.78 413.14,257.91 414.52,255.07 419.4,252.31 419.22,248.64 
432.82,235.79 434.01,230.34 434.81,227.25 433.28,226.77 402.6,221.86 397.11,219.62 387.92,214.16 376.27,211.33 
371.92,209.08 362.51,200.8 362.18,199.52 362.53,198.55 413.9,182.27 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="417.35,291.34 422.99,352.55 420.67,351.97 415.03,290.75 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="423.12,285.69 428.77,346.91 422.99,352.55 417.35,291.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="413.17,291.85 418.82,353.07 415.94,350.15 410.29,288.93 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="417.17,292.88 422.82,354.09 418.82,353.07 413.17,291.85 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M534.71,237.01l5.84,3.06l-11.83,2.35l-12.37,2.13l-8.97,3.05l-8.16,4.91l-16.16-1.55l-9.33,2.64l0.44,6.06
l0.67,6.61l-6.98,7.5l-11.34,4.59l-9.6,0.77l-8.57,0.58l-6.32,3.13l-7.2,3.72l-6.6,6.54l-1.07-0.23l-4-1.03l-2.88-2.92l5.89-6.87
l0.58-4.41l-6.63-0.16l-4.41,0.78l0.32-2.65l2.78-7.47l1.93-10.33l1.63-3.36l4.54-2.56l-0.19-3.46l13.73-12.96l1.69-7.39
l-30.99-5.02l-5.78-2.37l-9.17-5.45l-11.56-2.78l-4.78-2.48l-9.75-8.68l-0.41-1.77l0.65-1.79l0.57-0.18l52.57-16.6l10.08-1.04
l9.72,1.22l15.99,7.16l3.81,0.82l5.48,0.19l10.22-0.83l4.6,0.93l2.97,3.24l6.62,5.58l35.87,12.5l17.09,3.68l6.58,2.34l-0.45,2.23
l-5.43,1.07l-6.9,0.75l-7.36,3.45l3.52,6.14L534.71,237.01z M528.19,241.12l7.67-1.54l-3.06-1.61l-9.19-6.96l-4.06-7.14
l9.33-4.38l7.41-0.79l3.58-0.71l0.1-0.49l-5.28-1.88l-17.07-3.67l-36.56-12.79l-6.81-5.74l-2.66-2.89l-3.28-0.67l-10.02,0.81
l-5.96-0.22l-4.56-1.01l-15.92-7.13l-8.55-1.02l-9.4,0.96l-51.38,16.28l-0.34,0.96l0.33,1.28l9.41,8.28l4.36,2.24l11.65,2.83
l9.19,5.46l5.49,2.24l30.69,4.92l1.53,0.48l-0.8,3.09l-1.2,5.44l-13.6,12.85l0.18,3.66l-4.88,2.76l-1.39,2.84l-0.44,2.87
l-1.47,7.44l-2.76,7.44l-0.09,0.76l1.91-0.29l9.06,0.22l-0.75,5.95l-5.56,6.46l1.99,2l2.32,0.59l5.77-5.64l7.4-3.82l6.99-3.47
l9.38-0.63l8.93-0.71l10.22-4.14l6.32-6.79l-0.6-6.24l-0.54-7.04l11.71-3.31l15.63,1.5l7.57-4.52l9.6-3.25L528.19,241.12"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="418.24,293.11 423.89,354.33 422.82,354.09 417.17,292.88 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="424.84,286.57 430.48,347.79 423.89,354.33 418.24,293.11 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qazax' ? 'active' : null}`}
            onClick={() => openPopup('qazax')}
          >
            <g>
              <polygon
                className="st6"
                points="363.16,196.78 368.81,258 367.84,260.65 362.2,199.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="360.14,201.21 365.79,262.43 365.42,260.57 359.77,199.35 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="359.77,199.35 365.42,260.57 335.7,269.97 330.05,208.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="369.89,209.9 375.54,271.11 365.79,262.43 360.14,201.21 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="374.66,212.38 380.31,273.59 375.54,271.11 369.89,209.9 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="386.22,215.16 391.87,276.38 380.31,273.59 374.66,212.38 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="334.38,222.08 340.03,283.3 335.7,269.97 330.05,208.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="331.79,221.93 337.44,283.15 332.95,269.34 327.31,208.12 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="334.38,222.08 340.03,283.3 335.03,287.59 329.38,226.38 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="395.39,220.61 401.04,281.82 391.87,276.38 386.22,215.16 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="329.61,228.62 335.26,289.83 335.03,287.59 329.38,226.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="327.23,229.47 332.88,290.68 332.52,287.36 326.87,226.15 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="401.18,222.98 406.83,284.19 401.04,281.82 395.39,220.61 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="333.24,229.99 338.89,291.21 335.26,289.83 329.61,228.62 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="331.92,231.24 337.57,292.46 332.88,290.68 327.23,229.47 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="351.66,231.66 357.31,292.87 338.89,291.21 333.24,229.99 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="350.48,232.93 356.13,294.15 337.57,292.46 331.92,231.24 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="356.37,233.11 362.02,294.33 357.31,292.87 351.66,231.66 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="354.53,234.14 360.18,295.35 356.13,294.15 350.48,232.93 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="432.17,228 437.82,289.21 406.83,284.19 401.18,222.98 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="434.82,227.17 440.47,288.39 439.68,291.48 434.03,230.26 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="432.04,227.95 437.69,289.16 436.13,296.6 430.48,235.39 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="368.09,241.18 373.74,302.4 362.02,294.33 356.37,233.11 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="365.83,241.88 371.48,303.09 360.18,295.35 354.53,234.14 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="434.03,230.26 439.68,291.48 438.48,296.92 432.83,235.71 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="370.41,244.7 376.05,305.91 373.74,302.4 368.09,241.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="367.86,244.96 373.51,306.18 371.48,303.09 365.83,241.88 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="301.13,253.1 306.78,314.32 302.61,315.43 296.96,254.22 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="370.41,244.7 376.05,305.91 374.61,309.91 368.96,248.69 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="296.96,254.22 302.61,315.43 300.64,317.87 294.99,256.66 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="310.36,255.31 316.01,316.53 306.78,314.32 301.13,253.1 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="329.53,249.15 335.17,310.36 329.68,316.17 324.03,254.95 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="368.96,248.69 374.61,309.91 362.05,313.39 356.4,252.17 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="356.4,252.17 362.05,313.39 335.17,310.36 329.53,249.15 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="430.48,235.39 436.13,296.6 422.39,309.56 416.74,248.34 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="294.99,256.66 300.64,317.87 300.85,320.95 295.2,259.74 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="432.83,235.71 438.48,296.92 424.89,309.78 419.24,248.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="325.76,258.42 331.41,319.64 329.68,316.17 324.03,254.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="323.46,259.07 329.11,320.29 327.04,316.15 321.39,254.94 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="345.46,257.75 351.11,318.96 345.61,316.38 339.96,255.16 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="310.36,255.31 316.01,316.53 316.24,322.25 310.59,261.03 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="297.27,262.34 302.92,323.56 300.85,320.95 295.2,259.74 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="312.8,254.34 318.45,315.56 318.7,322.58 313.05,261.36 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="416.74,248.34 422.39,309.56 422.58,313.02 416.93,251.8 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="343.4,258.64 349.05,319.85 346.54,318.68 340.89,257.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="295.27,263.38 300.92,324.6 298.41,321.28 292.76,260.06 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="339.96,255.16 345.61,316.38 342.35,321.06 336.7,259.85 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="419.24,248.56 424.89,309.78 425.06,313.44 419.41,252.23 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="330.33,261.08 335.97,322.29 331.41,319.64 325.76,258.42 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="340.89,257.46 346.54,318.68 344.42,321.67 338.77,260.45 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="416.93,251.8 422.58,313.02 418.04,315.58 412.39,254.37 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="334.45,261.69 340.1,322.91 335.97,322.29 330.33,261.08 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="336.7,259.85 342.35,321.06 340.1,322.91 334.45,261.69 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="328.87,262.33 334.52,323.54 329.11,320.29 323.46,259.07 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="305.28,264.86 310.93,326.07 302.92,323.56 297.27,262.34 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="310.59,261.03 316.24,322.25 310.93,326.07 305.28,264.86 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M301.33,251.59l11.46,2.75l0.26,7.02l-7.34,5.29l-10.44-3.27l-2.52-3.32l-0.24-3.53l2.65-3.3L301.33,251.59
z M305.28,264.86l5.31-3.82l-0.23-5.72l-9.23-2.21l-4.17,1.12l-1.97,2.44l0.21,3.08l2.08,2.61L305.28,264.86"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qazaxColor ? qazaxColor : null }}
                points="301.13,253.1 310.36,255.31 310.59,261.03 305.28,264.86 297.27,262.34 295.2,259.74 
294.99,256.66 296.96,254.22 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="419.41,252.23 425.06,313.44 420.18,316.2 414.54,254.99 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="335.31,263.3 340.96,324.51 334.52,323.54 328.87,262.33 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="338.77,260.45 344.42,321.67 340.96,324.51 335.31,263.3 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="305.72,266.65 311.36,327.87 300.92,324.6 295.27,263.38 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="313.05,261.36 318.7,322.58 311.36,327.87 305.72,266.65 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="412.39,254.37 418.04,315.58 416.4,318.95 410.75,257.73 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="414.54,254.99 420.18,316.2 418.8,319.04 413.15,257.83 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="351.96,263.97 357.61,325.18 351.11,318.96 345.46,257.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="349.89,264.83 355.53,326.04 349.05,319.85 343.4,258.64 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="413.15,257.83 418.8,319.04 418.35,321.91 412.71,260.7 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="356.82,266.6 362.47,327.81 357.61,325.18 351.96,263.97 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="354.73,267.45 360.38,328.66 355.53,326.04 349.89,264.83 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="359.9,269.67 365.54,330.89 362.47,327.81 356.82,266.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="357.81,270.51 363.46,331.73 360.38,328.66 354.73,267.45 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="363.16,272.07 368.8,333.29 365.54,330.89 359.9,269.67 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="410.75,257.73 416.4,318.95 414.48,329.28 408.83,268.06 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="412.71,260.7 418.35,321.91 416.88,329.35 411.24,268.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="361.58,273.29 367.23,334.5 363.46,331.73 357.81,270.51 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="350.91,275.95 356.56,337.17 355.83,337.17 350.18,275.95 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="350.18,275.95 355.83,337.17 355.11,337.32 349.46,276.11 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="349.46,276.11 355.11,337.32 354.73,337.48 349.08,276.26 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="351.52,276.08 357.17,337.3 356.56,337.17 350.91,275.95 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="375.92,274.14 381.57,335.35 368.8,333.29 363.16,272.07 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="374.56,275.37 380.21,336.58 367.23,334.5 361.58,273.29 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="349.08,276.26 354.73,337.48 355.06,341.79 349.42,280.58 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="408.83,268.06 414.48,329.28 411.7,336.75 406.05,275.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="356.86,280.15 362.51,341.36 357.17,337.3 351.52,276.08 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="411.24,268.13 416.88,329.35 414.13,336.78 408.48,275.57 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="386.74,278.08 392.38,339.29 381.57,335.35 375.92,274.14 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: abseronColor ? abseronColor : null }}
                points="350.91,275.95 351.52,276.08 356.86,280.15 354.25,281.49 349.42,280.58 349.08,276.26 
349.46,276.11 350.18,275.95 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="354.25,281.49 359.9,342.7 355.06,341.79 349.42,280.58 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="356.86,280.15 362.51,341.36 359.9,342.7 354.25,281.49 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="406.05,275.53 411.7,336.75 411.54,337.97 405.89,276.76 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="385.34,279.3 390.99,340.51 380.21,336.58 374.56,275.37 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="392.36,279.13 398.01,340.35 392.38,339.29 386.74,278.08 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="405.89,276.76 411.54,337.97 398.01,340.35 392.36,279.13 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M411.24,268.13l-2.76,7.44l-0.26,2.11l-0.93,0.24l-15.23,2.67l-6.72-1.29l-10.78-3.93l-12.98-2.08
l-3.77-2.78l-3.08-3.06l-4.85-2.62l-6.49-6.19l-2.51-1.18l-2.12,2.99l-3.45,2.85l-6.44-0.97l-5.41-3.25l-2.06-4.13l6.96-7.36
l27.96,3.16l10.52-2.92l1.02-2.86l-2.03-3.09l-11.31-7.74l-4.05-1.21l-18.56-1.69l-4.69-1.77l-0.36-3.32l4.92-4.21l-4.49-13.81
l35.86-11.34l-0.97,2.66l0.33,1.28l9.41,8.28l4.36,2.24l11.65,2.83l9.19,5.46l5.49,2.24l30.69,4.92l1.53,0.48l-0.8,3.09
l-1.2,5.44l-13.6,12.85l0.18,3.66l-4.88,2.76l-1.39,2.84l-0.44,2.87L411.24,268.13z M392.36,279.13l13.53-2.37l0.16-1.22
l2.78-7.47l1.93-10.33l1.63-3.36l4.54-2.56l-0.19-3.46l13.73-12.96l1.69-7.39l-30.99-5.02l-5.79-2.37l-9.17-5.45l-11.56-2.78
l-4.78-2.48l-9.75-8.68l-0.37-1.86l-29.73,9.4l4.34,13.33l-5,4.29l0.23,2.24l3.63,1.38l18.42,1.66l4.71,1.46l11.73,8.07
l2.31,3.52l-1.44,3.99l-12.57,3.48l-26.87-3.03l-5.5,5.81l1.74,3.47l4.56,2.66l4.13,0.62l2.25-1.84l3.26-4.69l5.5,2.59l6.5,6.22
l4.86,2.63l3.08,3.08l3.26,2.4l12.77,2.06l10.81,3.94L392.36,279.13"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qazaxColor ? qazaxColor : null }}
                points="408.83,268.06 406.05,275.53 405.89,276.76 392.36,279.13 386.74,278.08 375.92,274.14 
363.16,272.07 359.9,269.67 356.82,266.6 351.96,263.97 345.46,257.75 339.96,255.16 336.7,259.85 334.45,261.69 330.33,261.08 
325.76,258.42 324.03,254.95 329.53,249.15 356.4,252.17 368.96,248.69 370.41,244.7 368.09,241.18 356.37,233.11 351.66,231.66 
333.24,229.99 329.61,228.62 329.38,226.38 334.38,222.08 330.05,208.76 359.77,199.35 360.14,201.21 369.89,209.9 
374.66,212.38 386.22,215.16 395.39,220.61 401.18,222.98 432.04,227.95 430.48,235.39 416.74,248.34 416.93,251.8 
412.39,254.37 410.75,257.73 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="408.48,275.57 414.13,336.78 413.86,338.89 408.21,277.68 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="408.21,277.68 413.86,338.89 412.93,339.13 407.28,277.91 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M351.58,274.58l1.6,0.32l6.95,5.31l-5.52,2.85l-7.58-1.43l-0.45-5.95l2.08-0.83l1.58-0.28L351.58,274.58z
 M354.25,281.49l2.61-1.34l-5.34-4.06l-0.6-0.13l-0.73,0l-0.72,0.15l-0.39,0.16l0.34,4.32L354.25,281.49"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="360.13,280.22 365.78,341.43 360.26,344.28 354.61,283.06 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="354.61,283.06 360.26,344.28 352.68,342.85 347.03,281.63 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="407.28,277.91 412.93,339.13 397.7,341.8 392.06,280.59 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="392.06,280.59 397.7,341.8 390.99,340.51 385.34,279.3 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'tovuz' ? 'active' : null}`}
            onClick={() => openPopup('tovuz')}
          >
            <g>
              <polygon
                className="st34"
                points="537.82,240.53 543.47,301.75 534.38,303.55 528.73,242.34 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="528.73,242.34 534.38,303.55 522,305.69 516.36,244.47 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="516.36,244.47 522,305.69 513.04,308.74 507.39,247.53 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="549.12,246.48 554.77,307.7 543.47,301.75 537.82,240.53 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="483.07,250.88 488.72,312.1 479.39,314.74 473.74,253.52 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="499.23,252.44 504.88,313.65 488.72,312.1 483.07,250.88 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="507.39,247.53 513.04,308.74 504.88,313.65 499.23,252.44 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="473.74,253.52 479.39,314.74 479.84,320.8 474.19,259.58 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="472.36,266.04 478.01,327.26 477.4,321.02 471.75,259.8 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="474.86,266.2 480.51,327.41 479.84,320.8 474.19,259.58 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="586.85,258.85 592.5,320.07 554.77,307.7 549.12,246.48 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="600.3,261.07 605.95,322.29 592.5,320.07 586.85,258.85 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="474.86,266.2 480.51,327.41 473.52,334.91 467.87,273.7 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="621.94,258.68 627.59,319.9 619.46,321.84 613.81,260.62 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="607.4,261.33 613.04,322.54 605.95,322.29 600.3,261.07 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="613.81,260.62 619.46,321.84 613.04,322.54 607.4,261.33 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="446.94,279.06 452.59,340.28 444.01,340.85 438.36,279.64 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="467.87,273.7 473.52,334.91 462.18,339.51 456.54,278.29 			"
              />
            </g>
            <g>
              <polygon
                className="st57"
                points="456.54,278.29 462.18,339.51 452.59,340.28 446.94,279.06 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="438.36,279.64 444.01,340.85 437.69,343.99 432.05,282.77 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="635.97,265.54 641.62,326.75 627.59,319.9 621.94,258.68 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="432.05,282.77 437.69,343.99 430.5,347.71 424.85,286.49 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="424.85,286.49 430.5,347.71 425.14,353.03 419.49,291.81 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="648.64,270.92 654.29,332.13 641.62,326.75 635.97,265.54 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="421.38,292.37 427.03,353.59 425.14,353.03 419.49,291.81 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="419.54,293.4 425.19,354.61 421.75,353.73 416.1,292.51 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="427.29,296.65 432.93,357.87 427.03,353.59 421.38,292.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="425.07,297.39 430.72,358.61 425.19,354.61 419.54,293.4 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="648.64,270.92 654.29,332.13 642.48,339.26 636.83,278.05 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="652.26,270.63 657.91,331.85 643.88,340.3 638.23,279.09 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="636.83,278.05 642.48,339.26 627.67,345.29 622.02,284.07 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="565.36,290.07 571.01,351.29 556.03,351.65 550.38,290.43 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="638.23,279.09 643.88,340.3 629.09,346.32 623.45,285.1 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="564.77,291.45 570.42,352.66 557.17,353 551.52,291.78 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="622.02,284.07 627.67,345.29 619.25,349.53 613.61,288.31 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="623.45,285.1 629.09,346.32 620.88,350.46 615.23,289.24 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="550.38,290.43 556.03,351.65 548.59,357.53 542.94,296.31 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="435.1,306.57 440.75,367.79 432.93,357.87 427.29,296.65 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="592.57,294.54 598.22,355.76 571.01,351.29 565.36,290.07 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="578.03,293.65 583.68,354.87 570.42,352.66 564.77,291.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="432.91,307.31 438.55,368.53 430.72,358.61 425.07,297.39 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="551.52,291.78 557.17,353 550.51,358.27 544.86,297.05 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="613.61,288.31 619.25,349.53 614.95,352.67 609.3,291.45 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="615.23,289.24 620.88,350.46 616.88,353.39 611.23,292.17 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="438.11,309.36 443.76,370.57 440.75,367.79 435.1,306.57 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="609.3,291.45 614.95,352.67 611.95,355.17 606.3,293.96 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="606.3,293.96 611.95,355.17 598.22,355.76 592.57,294.54 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="592.04,295.94 597.69,357.15 583.68,354.87 578.03,293.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="436.25,310.43 441.9,371.65 438.55,368.53 432.91,307.31 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="611.23,292.17 616.88,353.39 613.19,356.48 607.54,295.27 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="607.54,295.27 613.19,356.48 597.69,357.15 592.04,295.94 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="542.94,296.31 548.59,357.53 540.5,363.29 534.85,302.07 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="442.94,310.79 448.59,372.01 443.76,370.57 438.11,309.36 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="544.86,297.05 550.51,358.27 541.97,364.35 536.33,303.13 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="441.72,312.06 447.36,373.28 441.9,371.65 436.25,310.43 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="449.7,311.75 455.35,372.97 448.59,372.01 442.94,310.79 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="534.85,302.07 540.5,363.29 529.87,366.34 524.22,305.12 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="448.23,312.94 453.88,374.15 447.36,373.28 441.72,312.06 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="536.33,303.13 541.97,364.35 530.73,367.56 525.08,306.34 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="524.22,305.12 529.87,366.34 518.68,369.3 513.03,308.08 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="454.37,313.88 460.02,375.1 455.35,372.97 449.7,311.75 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="451.72,314.53 457.36,375.74 453.88,374.15 448.23,312.94 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="525.08,306.34 530.73,367.56 519.64,370.5 513.99,309.29 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="513.03,308.08 518.68,369.3 509.19,372.51 503.54,311.3 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="513.99,309.29 519.64,370.5 510.11,373.73 504.46,312.51 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="503.54,311.3 509.19,372.51 498.94,374.99 493.29,313.78 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="504.46,312.51 510.11,373.73 500.28,376.11 494.63,314.89 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="454.37,313.88 460.02,375.1 458.13,380.37 452.49,319.15 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="493.29,313.78 498.94,374.99 491.15,379.98 485.5,318.76 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="494.63,314.89 500.28,376.11 493.35,380.53 487.7,319.31 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="452.49,319.15 458.13,380.37 448.28,388.97 442.63,327.75 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="485.5,318.76 491.15,379.98 488.21,386.66 482.57,325.44 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="487.7,319.31 493.35,380.53 490.52,387 484.87,325.78 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="442.63,327.75 448.28,388.97 438.13,393.28 432.49,332.07 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="432.49,332.07 438.13,393.28 414.73,397.21 409.08,335.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="407.16,338.12 412.81,399.33 411.92,396.28 406.27,335.07 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="409.64,338.06 415.28,399.27 414.73,397.21 409.08,335.99 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="482.57,325.44 488.21,386.66 480.22,395.64 474.57,334.42 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="484.87,325.78 490.52,387 482.56,395.95 476.91,334.73 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="409.64,338.06 415.28,399.27 413.56,406.02 407.91,344.8 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="414.12,348.26 419.77,409.47 413.56,406.02 407.91,344.8 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="474.57,334.42 480.22,395.64 476.9,405.11 471.25,343.89 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="412.76,349.54 418.41,410.76 410.97,406.62 405.32,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="476.91,334.73 482.56,395.95 479.3,405.25 473.65,344.04 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="424.54,349.37 430.18,410.59 419.77,409.47 414.12,348.26 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="423.19,350.62 428.84,411.83 418.41,410.76 412.76,349.54 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="432.49,352.35 438.14,413.57 430.18,410.59 424.54,349.37 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="430.43,353.33 436.08,414.55 428.84,411.83 423.19,350.62 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="471.25,343.89 476.9,405.11 472.79,414.28 467.14,353.06 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="473.65,344.04 479.3,405.25 475.14,414.53 469.49,353.32 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="436.35,357.78 442,418.99 438.14,413.57 432.49,352.35 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="433.75,357.96 439.4,419.17 436.08,414.55 430.43,353.33 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="436.35,357.78 442,418.99 438.61,424.55 432.97,363.34 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="467.14,353.06 472.79,414.28 466.74,423.99 461.09,362.77 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="469.49,353.32 475.14,414.53 469,424.38 463.35,363.16 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="437.83,366.11 443.48,427.32 438.61,424.55 432.97,363.34 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="435.91,367.08 441.55,428.29 435.83,425.04 430.18,363.82 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="443.05,369.81 448.7,431.02 443.48,427.32 437.83,366.11 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="461.09,362.77 466.74,423.99 461.7,430.09 456.05,368.88 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="441.14,370.79 446.79,432 441.55,428.29 435.91,367.08 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="463.35,363.16 469,424.38 463.6,430.92 457.95,369.71 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="456.05,368.88 461.7,430.09 454,433.23 448.36,372.02 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="448.36,372.02 454,433.23 448.7,431.02 443.05,369.81 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: tovuzColor ? tovuzColor : null }}
                points="537.82,240.53 549.12,246.48 586.85,258.85 600.3,261.07 607.4,261.33 613.81,260.62 
621.94,258.68 635.97,265.54 648.64,270.92 636.83,278.05 622.02,284.07 613.61,288.31 609.3,291.45 606.3,293.96 592.57,294.54 
565.36,290.07 550.38,290.43 542.94,296.31 534.85,302.07 524.22,305.12 513.03,308.08 503.54,311.3 493.29,313.78 485.5,318.76 
482.57,325.44 474.57,334.42 471.25,343.89 467.14,353.06 461.09,362.77 456.05,368.88 448.36,372.02 443.05,369.81 
437.83,366.11 432.97,363.34 436.35,357.78 432.49,352.35 424.54,349.37 414.12,348.26 407.91,344.8 409.64,338.06 
409.08,335.99 432.49,332.07 442.63,327.75 452.49,319.15 454.37,313.88 449.7,311.75 442.94,310.79 438.11,309.36 435.1,306.57 
427.29,296.65 421.38,292.37 419.49,291.81 424.85,286.49 432.05,282.77 438.36,279.64 446.94,279.06 456.54,278.29 
467.87,273.7 474.86,266.2 474.19,259.58 473.74,253.52 483.07,250.88 499.23,252.44 507.39,247.52 516.36,244.47 528.73,242.34 
            "
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="457.95,369.71 463.6,430.92 453.76,434.93 448.11,373.72 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M637.63,264.43l14.63,6.2l-14.03,8.45l-14.78,6.02l-8.22,4.14l-4,2.93l-3.68,3.1l-15.5,0.67l-14.02-2.28
l-13.25-2.21l-13.25,0.33l-6.66,5.27l-8.53,6.08l-11.24,3.21l-11.09,2.95l-9.54,3.22l-9.83,2.38l-6.93,4.42l-2.84,6.47
l-7.96,8.95l-3.26,9.31l-4.16,9.28l-6.14,9.85l-5.4,6.54l-9.84,4.01l-6.96-2.93l-5.24-3.71l-5.72-3.26l3.57-5.86l-3.32-4.63
l-7.24-2.71l-10.43-1.08l-7.44-4.13l1.84-7.29l-0.89-3.05l25.53-4.27l9.26-4.01l9.13-8.04l1.53-4.22l-3.49-1.59l-6.51-0.88
l-5.47-1.63l-3.34-3.12l-7.83-9.92l-5.53-3.99l-3.44-0.89l7.03-6.9l7.4-3.82l6.99-3.47l9.38-0.63l8.93-0.71l10.22-4.14l6.32-6.79
l-0.6-6.24l-0.54-7.04l11.71-3.31l15.63,1.5l7.57-4.52l9.6-3.25l12.48-2.13l10.41-2.07l12.19,6.42l37.34,12.21l12.83,2.11
l6.66,0.24l5.81-0.65l9.29-2.22l1.74,1.01L637.63,264.43z M636.83,278.05l11.8-7.13l-12.66-5.38l-14.03-6.85l-8.13,1.94
l-6.41,0.7l-7.1-0.25l-13.45-2.22l-37.73-12.37l-11.31-5.95l-9.09,1.81l-12.37,2.13l-8.97,3.05l-8.16,4.91l-16.16-1.55
l-9.33,2.64l0.44,6.06l0.67,6.61l-6.98,7.5l-11.34,4.59l-9.6,0.77l-8.57,0.58l-6.32,3.13l-7.2,3.72l-5.36,5.32l1.89,0.56
l5.9,4.28l7.81,9.92l3.01,2.79l4.83,1.43l6.76,0.96l4.68,2.13l-1.89,5.27l-9.86,8.6l-10.14,4.32l-23.41,3.92l0.56,2.06
l-1.72,6.75l6.21,3.45l10.42,1.12l7.96,2.98l3.85,5.43l-3.38,5.56l4.86,2.77l5.22,3.7l5.3,2.21l7.7-3.14l5.04-6.1l6.05-9.71
l4.11-9.17l3.32-9.47l7.99-8.98l2.94-6.68l7.79-4.98l10.25-2.48l9.49-3.21l11.19-2.96l10.63-3.05l8.09-5.76l7.44-5.88l14.98-0.36
l27.21,4.47l13.73-0.58l3-2.51l4.3-3.14l8.42-4.24L636.83,278.05"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="448.11,373.72 453.76,434.93 446.79,432 441.14,370.79 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'semkir' ? 'active' : null}`}
            onClick={() => openPopup('semkir')}
          >
            <g>
              <polygon
                className="st43"
                points="649.99,272 655.64,333.21 643.88,340.3 638.23,279.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="656.86,281.22 662.51,342.44 655.64,333.21 649.99,272 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="638.23,279.08 643.88,340.3 629.09,346.31 623.44,285.1 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="564.77,291.44 570.42,352.66 557.17,352.99 551.52,291.78 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="623.44,285.1 629.09,346.31 620.88,350.45 615.23,289.24 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="578.03,293.65 583.68,354.86 570.42,352.66 564.77,291.44 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="551.52,291.78 557.17,352.99 550.5,358.26 544.85,297.05 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="615.23,289.24 620.88,350.45 616.87,353.38 611.23,292.17 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="592.04,295.93 597.69,357.15 583.68,354.86 578.03,293.65 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="611.23,292.17 616.87,353.38 613.19,356.48 607.54,295.26 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="607.54,295.26 613.19,356.48 597.69,357.15 592.04,295.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="663.9,291.28 669.55,352.49 662.51,342.44 656.86,281.22 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="544.85,297.05 550.5,358.26 541.97,364.34 536.32,303.13 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="536.32,303.13 541.97,364.34 530.73,367.55 525.08,306.34 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="525.08,306.34 530.73,367.55 519.64,370.5 513.99,309.28 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="513.99,309.28 519.64,370.5 510.1,373.72 504.46,312.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="666.83,298.8 672.47,360.02 669.55,352.49 663.9,291.28 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="504.46,312.51 510.1,373.72 500.28,376.1 494.63,314.89 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="494.63,314.89 500.28,376.1 493.35,380.52 487.7,319.31 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="666.83,298.8 672.47,360.02 667.67,367.09 662.02,305.87 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="669.38,298.76 675.03,359.97 669.58,367.84 663.93,306.62 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="662.02,305.87 667.67,367.09 661.7,370.75 656.05,309.53 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="487.7,319.31 493.35,380.52 490.66,386.69 485.01,325.47 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="663.93,306.62 669.58,367.84 663.55,371.53 657.9,310.32 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="656.05,309.53 661.7,370.75 657.04,375.73 651.39,314.52 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="488.19,329.64 493.84,390.86 490.66,386.69 485.01,325.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="485.94,330.33 491.59,391.54 488.07,386.96 482.42,325.74 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="657.9,310.32 663.55,371.53 659.22,376.23 653.57,315.02 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="651.39,314.52 657.04,375.73 653.9,379.41 648.25,318.2 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="653.57,315.02 659.22,376.23 656.01,379.98 650.36,318.77 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="491.65,333.66 497.29,394.87 493.84,390.86 488.19,329.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="489.34,334.26 494.99,395.47 491.59,391.54 485.94,330.33 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="648.25,318.2 653.9,379.41 649.81,382.95 644.16,321.73 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="650.36,318.77 656.01,379.98 651.91,383.53 646.26,322.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="491.12,337.97 496.76,399.19 494.99,395.47 489.34,334.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="493.57,337.75 499.22,398.97 497.29,394.87 491.65,333.66 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="493.57,337.75 499.22,398.97 499.02,403.35 493.37,342.14 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="644.16,321.73 649.81,382.95 643.27,390.49 637.63,329.27 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="646.26,322.31 651.91,383.53 645.8,390.59 640.15,329.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="639.02,337 644.66,398.21 643.27,390.49 637.63,329.27 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="498.04,351.13 503.69,412.34 499.02,403.35 493.37,342.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="495.73,351.76 501.37,412.97 496.55,403.58 490.9,342.37 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="639.02,337 644.67,398.21 638.46,401.95 632.81,340.74 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="641.58,337.36 647.22,398.58 640.21,402.8 634.56,341.58 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="632.81,340.74 638.46,401.95 632.33,406 626.68,344.78 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="634.56,341.58 640.21,402.8 634.25,406.73 628.6,345.51 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="507.2,357.73 512.85,418.95 503.69,412.34 498.04,351.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="505.14,358.61 510.79,419.83 501.37,412.97 495.73,351.76 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="626.68,344.78 632.33,406 629.07,410.18 623.42,348.96 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="628.6,345.51 634.25,406.73 631.33,410.55 625.68,349.34 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="623.42,348.96 629.07,410.18 626.45,414.17 620.8,352.95 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="625.68,349.34 631.33,410.55 628.49,414.79 622.84,353.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="515.14,364.7 520.79,425.92 512.85,418.95 507.2,357.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="512.82,365.28 518.47,426.49 510.79,419.83 505.14,358.61 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="620.8,352.95 626.45,414.17 619.18,419.82 613.54,358.61 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="622.84,353.58 628.49,414.79 621.21,420.47 615.56,359.26 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="613.54,358.61 619.18,419.82 616.45,423.6 610.8,362.38 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="615.56,359.26 621.21,420.47 618.65,424.05 613,362.83 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="518.57,372.01 524.22,433.22 520.79,425.92 515.14,364.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="515.99,372.06 521.64,433.28 518.47,426.49 512.82,365.28 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="554.8,365.52 560.45,426.74 546.25,431.27 540.6,370.06 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="554.3,367.17 559.95,428.39 547.42,432.41 541.77,371.19 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="590.61,367.15 596.26,428.36 584.02,428.9 578.37,367.68 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="610.8,362.38 616.45,423.6 613.4,426.9 607.75,365.69 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="613,362.83 618.65,424.05 615.13,427.88 609.48,366.67 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="590.22,368.53 595.87,429.75 584.17,430.27 578.52,369.05 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="566.35,370.86 572,432.07 560.45,426.74 554.8,365.52 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="578.37,367.68 584.02,428.9 572,432.07 566.35,370.86 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="599.47,368.03 605.12,429.24 596.26,428.36 590.61,367.15 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="607.75,365.69 613.4,426.9 605.12,429.24 599.47,368.03 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="518.57,372.01 524.22,433.22 520.85,437.95 515.2,376.74 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="565.76,372.47 571.41,433.69 559.95,428.39 554.3,367.17 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="578.52,369.05 584.17,430.27 571.41,433.69 565.76,372.47 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="609.48,366.67 615.13,427.88 605.24,430.68 599.59,369.47 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="599.59,369.47 605.24,430.68 595.87,429.75 590.22,368.53 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="540.6,370.06 546.25,431.27 530.44,439.65 524.79,378.43 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="541.77,371.19 547.42,432.41 531.98,440.61 526.33,379.4 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="515.2,376.74 520.85,437.95 519.26,443.16 513.61,381.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="513.61,381.94 519.26,443.16 516.33,445.35 510.69,384.14 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="524.79,378.43 530.44,439.65 516.68,446.5 511.03,385.29 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="511.03,385.29 516.68,446.5 516.33,445.35 510.69,384.14 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: semkirColor ? semkirColor : null }}
                points="649.99,272 656.86,281.22 663.9,291.28 666.83,298.8 662.02,305.87 656.05,309.53 651.39,314.52 
648.25,318.2 644.16,321.73 637.63,329.27 639.02,337 632.81,340.74 626.68,344.78 623.42,348.96 620.8,352.95 613.54,358.61 
610.8,362.38 607.75,365.69 599.47,368.03 590.61,367.15 578.37,367.68 566.35,370.86 554.8,365.52 540.6,370.06 524.79,378.43 
511.03,385.29 510.69,384.14 513.61,381.94 515.2,376.74 518.57,372.01 515.14,364.7 507.2,357.73 498.04,351.13 493.37,342.14 
493.57,337.75 491.65,333.66 488.19,329.64 485.01,325.47 487.7,319.31 494.63,314.89 504.46,312.51 513.99,309.28 
525.08,306.34 536.32,303.13 544.86,297.05 551.52,291.78 564.77,291.44 578.03,293.65 592.04,295.93 607.54,295.26 
611.23,292.17 615.23,289.24 623.44,285.1 638.23,279.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="509.27,387.89 514.92,449.1 513.73,445.14 508.08,383.92 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="526.33,379.4 531.98,440.61 514.92,449.1 509.27,387.89 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M666.25,290.76l3.12,8l-5.45,7.87l-6.03,3.69l-4.33,4.7l-3.21,3.75l-4.09,3.54l-6.11,7.07l1.42,7.98
l-7.01,4.22l-5.96,3.93l-2.92,3.83l-2.85,4.24l-7.28,5.68l-2.56,3.58l-3.52,3.84l-9.89,2.8l-9.37-0.93l-11.7,0.52l-12.76,3.42
l-11.46-5.3l-12.53,4.02l-15.44,8.2l-17.07,8.49l-1.19-3.97l3.21-2.42l1.54-4.99l3.16-4.44l-3.17-6.78l-7.68-6.67l-9.41-6.86
l-4.82-9.39l0.21-4.39l-1.78-3.71l-3.4-3.93l-3.52-4.58l3.08-6.99l7.79-4.98l10.25-2.48l9.49-3.21l11.19-2.96l10.63-3.05
l8.09-5.76l7.44-5.88l14.98-0.36l27.21,4.47l13.73-0.58l3-2.51l4.3-3.14l8.42-4.24l14.81-6.03l14.07-8.5l8.26,11.04
L666.25,290.76z M662.02,305.87l4.8-7.07l-2.93-7.53l-7.04-10.05l-6.88-9.22l-11.76,7.08l-14.78,6.02l-8.22,4.14l-4,2.93
l-3.68,3.1l-15.5,0.67l-14.02-2.28l-13.25-2.21l-13.25,0.33l-6.66,5.27l-8.53,6.08l-11.24,3.21l-11.09,2.95l-9.54,3.22
l-9.83,2.38l-6.93,4.42l-2.69,6.17l3.18,4.17l3.45,4.01l1.93,4.1l-0.21,4.38l4.68,8.99l9.16,6.6l7.94,6.97l3.43,7.3l-3.37,4.73
l-1.59,5.21l-2.92,2.19l0.34,1.15l13.76-6.86l15.81-8.37l14.2-4.54l11.55,5.33l12.02-3.17l12.24-0.54l8.86,0.88l8.27-2.34
l3.05-3.3l2.74-3.78l7.27-5.65l2.62-3.99l3.26-4.18l6.13-4.04l6.21-3.74l-1.39-7.72l6.54-7.54l4.09-3.53l3.14-3.68l4.66-4.99
L662.02,305.87"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'samux' ? 'active' : null}`}
            onClick={() => openPopup('samux')}
          >
            <g>
              <polygon
                className="st92"
                points="637.2,256.27 642.85,317.48 636.57,317.75 630.92,256.53 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="630.92,256.53 636.57,317.75 630.14,319.29 624.49,258.08 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="622.71,259.12 628.36,320.33 625.85,318.89 620.2,257.68 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="657.99,258.03 663.63,319.25 642.85,317.48 637.2,256.27 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="662.15,259.37 667.8,320.58 663.63,319.25 657.99,258.03 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="637.63,264.43 643.28,325.64 630.14,319.29 624.49,258.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="665.21,262.05 670.86,323.26 667.8,320.58 662.15,259.37 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="635.97,265.54 641.62,326.75 628.36,320.33 622.71,259.12 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="651.48,270.3 657.13,331.51 643.28,325.64 637.63,264.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="670.68,268.71 676.33,329.93 670.86,323.26 665.21,262.05 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="649.42,271.24 655.07,332.45 641.62,326.75 635.97,265.54 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="659.16,280.59 664.81,341.8 657.13,331.51 651.48,270.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="656.86,281.23 662.51,342.44 655.07,332.45 649.42,271.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="683.28,278.87 688.93,340.09 676.33,329.93 670.68,268.71 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="688.19,281.37 693.83,342.58 688.93,340.09 683.28,278.87 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="703.39,285.97 709.04,347.19 693.83,342.58 688.19,281.37 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="666.26,290.76 671.9,351.98 664.81,341.8 659.16,280.59 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="663.9,291.28 669.55,352.5 662.51,342.44 656.86,281.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="706.69,288.53 712.34,349.75 709.04,347.19 703.39,285.97 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="741.49,288.26 747.14,349.48 740.9,350.93 735.25,289.72 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="746.24,288.96 751.89,350.17 747.14,349.48 741.49,288.26 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="751.42,290.32 757.07,351.53 751.89,350.17 746.24,288.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="735.25,289.72 740.9,350.93 736.45,353.86 730.81,292.65 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="762.68,287.82 768.32,349.03 763.58,351.5 757.94,290.29 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="757.94,290.29 763.58,351.5 757.07,351.53 751.42,290.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="666.83,298.81 672.48,360.02 669.55,352.5 663.9,291.28 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="669.38,298.76 675.03,359.98 671.9,351.98 666.26,290.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="711.6,295.24 717.25,356.45 712.34,349.75 706.69,288.53 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="768.66,291.79 774.31,353 768.32,349.03 762.68,287.82 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="730.81,292.65 736.45,353.86 733.14,357.17 727.49,295.95 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="716.3,297.79 721.95,359.01 717.25,356.45 711.6,295.24 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="727.49,295.95 733.14,357.17 729.15,359.45 723.5,298.23 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="723.5,298.23 729.15,359.45 721.95,359.01 716.3,297.79 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="781.39,293.17 787.04,354.38 774.31,353 768.66,291.79 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="669.38,298.76 675.03,359.98 669.58,367.84 663.93,306.63 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="790.12,297.68 795.76,358.9 787.04,354.38 781.39,293.17 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="663.93,306.63 669.58,367.84 663.55,371.54 657.9,310.32 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="657.9,310.32 663.55,371.54 659.22,376.24 653.57,315.02 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="790.12,297.68 795.76,358.9 794.26,364.62 788.61,303.4 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="792.73,297.04 798.37,358.26 796.69,364.64 791.04,303.43 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="653.57,315.02 659.22,376.24 656.01,379.99 650.36,318.77 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="650.36,318.77 656.01,379.99 651.91,383.53 646.27,322.32 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="788.61,303.4 794.26,364.62 792.99,370.98 787.34,309.76 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="791.04,303.43 796.69,364.64 795.46,370.87 789.81,309.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="789.09,315.08 794.74,376.29 792.99,370.98 787.34,309.76 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="646.27,322.32 651.91,383.53 645.8,390.6 640.15,329.38 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="789.09,315.08 794.74,376.29 793.48,381.63 787.83,320.42 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="791.55,314.99 797.2,376.2 795.77,382.28 790.12,321.06 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="641.51,337.04 647.16,398.26 645.8,390.6 640.15,329.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="639.08,337.38 644.73,398.59 643.28,390.49 637.63,329.28 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="787.83,320.42 793.48,381.63 777.56,388.45 771.91,327.24 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="790.12,321.06 795.77,382.28 778.94,389.49 773.29,328.28 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="771.91,327.24 777.56,388.45 761.6,395.6 755.95,334.39 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="773.29,328.28 778.94,389.49 762.91,396.67 757.26,335.46 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="644.94,348.23 650.59,409.45 647.16,398.26 641.51,337.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="642.51,348.59 648.16,409.81 644.73,398.59 639.08,337.38 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="755.95,334.39 761.6,395.6 743.62,402.5 737.97,341.28 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="757.26,335.46 762.91,396.67 744.8,403.62 739.15,342.41 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="737.97,341.28 743.62,402.5 725.62,408.98 719.97,347.77 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="719.97,347.77 725.62,408.98 718.96,410.39 713.31,349.18 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="739.15,342.41 744.8,403.62 726.38,410.24 720.73,349.02 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="720.73,349.02 726.38,410.24 720.34,411.52 714.69,350.3 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="713.31,349.18 718.96,410.39 714.61,413.54 708.96,352.33 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="714.69,350.3 720.34,411.52 716.99,413.94 711.35,352.73 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="648.35,361.08 654,422.3 650.59,409.45 644.94,348.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="646.14,362.29 651.79,423.51 648.16,409.81 642.51,348.59 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="708.96,352.33 714.61,413.54 713.96,417.93 708.31,356.71 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="711.35,352.73 716.99,413.94 716.29,418.59 710.64,357.37 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="662.53,362.51 668.18,423.73 654,422.3 648.35,361.08 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="708.31,356.71 713.96,417.93 708.14,420.24 702.49,359.02 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="660.27,363.72 665.92,424.94 651.79,423.51 646.14,362.29 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="710.64,357.37 716.29,418.59 709.35,421.35 703.7,360.13 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="702.49,359.02 708.14,420.24 693.77,425.37 688.12,364.15 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="703.7,360.13 709.35,421.35 695.51,426.3 689.86,365.08 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="664.39,371.16 670.04,432.37 668.18,423.73 662.53,362.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="662.12,372.3 667.77,433.52 665.92,424.94 660.27,363.72 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="678.9,373.16 684.55,434.37 670.04,432.37 664.39,371.16 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="688.12,364.15 693.77,425.37 684.55,434.37 678.9,373.16 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M791.55,314.99l-1.43,6.07l-16.84,7.21l-16.02,7.18l-18.11,6.95l-18.42,6.62l-6.04,1.28l-3.34,2.42
l-0.71,4.64l-6.94,2.76l-13.84,4.95l-9.92,9.67l-17.82-2.45l-1.85-8.58l-14.13-1.43l-3.63-13.7l-3.42-11.22l-1.46-8.1l6.54-7.54
l4.09-3.53l3.14-3.68l4.66-4.99l5.97-3.66l4.8-7.07l-2.93-7.53l-7.04-10.05l-7.45-9.99l-13.44-5.7l-13.26-6.42l-2.51-1.44
l10.6-2.5l6.92-0.27l21.32,1.81l5.1,1.7l3.25,2.89l5.45,6.64l12.36,9.97l4.64,2.36l15.46,4.72l3.62,2.86l4.88,6.64l3.77,1.99
l5.22,0.32l2.91-1.65l3.22-3.25l4.99-3.29l7.66-1.78l5.73,0.85l4.9,1.28l4.98-0.02l5.92-3.08l7.05,4.67l12.56,1.37l10.01,5.16
l-1.68,6.38l-1.23,6.23L791.55,314.99z M771.91,327.24l15.92-6.82l1.26-5.34l-1.75-5.32l1.27-6.36l1.51-5.72l-8.72-4.51
l-12.73-1.38l-5.98-3.97l-4.74,2.47l-6.52,0.03l-5.17-1.36l-4.75-0.7l-6.24,1.46l-4.45,2.93l-3.32,3.31l-3.98,2.28l-7.21-0.44
l-4.7-2.55l-4.91-6.71l-3.3-2.56l-15.2-4.61l-4.9-2.5l-12.6-10.16l-5.46-6.66l-3.06-2.68l-4.16-1.33l-20.79-1.77l-6.27,0.27
l-6.43,1.54l13.14,6.35l13.85,5.87l7.68,10.29l7.09,10.17l3.12,8l-5.45,7.87l-6.03,3.69l-4.33,4.7l-3.21,3.75l-4.09,3.54
l-6.11,7.07l1.35,7.66l3.44,11.19l3.41,12.85l14.17,1.43l1.86,8.65l14.51,2l9.22-9l14.37-5.13l5.82-2.31l0.65-4.38l4.35-3.15
l6.66-1.41l18-6.49l17.98-6.9L771.91,327.24"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: samuxColor ? samuxColor : null }}
                points="657.99,258.03 662.15,259.37 665.21,262.05 670.68,268.71 683.28,278.87 688.19,281.37 
703.39,285.97 706.69,288.53 711.6,295.24 716.3,297.79 723.51,298.23 727.49,295.95 730.81,292.65 735.25,289.72 741.49,288.26 
746.24,288.96 751.42,290.32 757.94,290.29 762.68,287.82 768.66,291.79 781.39,293.17 790.12,297.68 788.61,303.4 
787.34,309.76 789.09,315.08 787.83,320.42 771.91,327.24 755.95,334.39 737.97,341.28 719.97,347.77 713.31,349.18 
708.96,352.33 708.31,356.71 702.49,359.02 688.12,364.15 678.9,373.16 664.39,371.16 662.53,362.51 648.35,361.08 
644.94,348.23 641.51,337.04 640.15,329.38 646.27,322.32 650.36,318.77 653.57,315.02 657.9,310.32 663.93,306.63 
669.38,298.76 666.26,290.76 659.16,280.59 651.48,270.3 637.63,264.43 624.49,258.08 630.92,256.53 637.2,256.27 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="689.86,365.08 695.51,426.3 685.59,435.97 679.94,374.75 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="679.94,374.75 685.59,435.97 667.77,433.52 662.12,372.3 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'balaken' ? 'active' : null}`}
            onClick={() => openPopup('balaken')}
          >
            <g>
              <polygon
                className="st15"
                points="795.41,116.79 801.06,178.01 796.17,176.74 790.53,115.52 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="803.03,117.25 808.68,178.47 801.06,178.01 795.41,116.79 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="815.28,119.37 820.93,180.59 808.68,178.47 803.03,117.25 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="818.98,121.26 824.63,182.48 820.93,180.59 815.28,119.37 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="790.53,115.52 796.17,176.74 780.18,188 774.53,126.79 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="818.98,121.26 824.63,182.48 824.29,186.29 818.64,125.07 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="821.48,120.58 827.13,181.79 826.75,186.4 821.1,125.19 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="774.53,126.79 780.18,188 771.43,193.13 765.78,131.92 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="720,139.11 725.65,200.33 720.01,200.19 714.36,138.97 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="714.36,138.97 720.01,200.19 713.7,202.75 708.05,141.53 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="765.78,131.92 771.43,193.13 755.24,200.61 749.59,139.39 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="818.64,125.07 824.29,186.29 818.81,194.93 813.16,133.71 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="821.1,125.19 826.75,186.4 821.25,195.04 815.6,133.83 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="732.32,141.99 737.97,203.2 725.65,200.33 720,139.11 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="749.59,139.39 755.24,200.61 749.67,202.49 744.02,141.28 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="738.51,142.17 744.16,203.39 737.97,203.2 732.32,141.99 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="744.02,141.28 749.67,202.49 744.16,203.39 738.51,142.17 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="815.6,133.83 821.25,195.04 821.1,198.5 815.45,137.28 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="813.16,133.71 818.81,194.93 818.61,199.07 812.96,137.86 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="708.05,141.53 713.7,202.75 707.78,209.64 702.13,148.43 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="846.67,135.66 852.32,196.88 846.4,197.52 840.75,136.31 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="858.65,135.31 864.3,196.52 852.32,196.88 846.67,135.66 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="861.51,134.56 867.16,195.78 864.3,196.52 858.65,135.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="817.3,141.03 822.95,202.24 818.61,199.07 812.96,137.86 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="840.75,136.31 846.4,197.52 835.32,201.25 829.67,140.03 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="829.67,140.03 835.32,201.25 829.08,202.75 823.43,141.54 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="823.43,141.54 829.08,202.75 822.95,202.24 817.3,141.03 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="861.51,134.56 867.16,195.78 866.86,199.57 861.21,138.35 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="864.12,132.33 869.77,193.55 869.29,199.83 863.64,138.62 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="702.13,148.43 707.78,209.64 702.82,220.42 697.17,159.2 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="861.21,138.35 866.86,199.57 859.7,206.39 854.06,145.18 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="863.64,138.62 869.29,199.83 861.49,207.27 855.84,146.06 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="854.06,145.18 859.7,206.39 847.82,211.24 842.17,150.03 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="855.84,146.06 861.49,207.27 848.78,212.45 843.14,151.24 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="842.17,150.03 847.82,211.24 835.03,214.68 829.39,153.46 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="843.14,151.24 848.78,212.45 835.82,215.92 830.18,154.71 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="829.39,153.46 835.03,214.68 824.66,217.14 819.01,155.92 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="830.18,154.71 835.82,215.92 825.5,218.36 819.86,157.14 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="697.17,159.2 702.82,220.42 701.87,230.09 696.22,168.88 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="819.01,155.92 824.66,217.14 814.36,220.24 808.71,159.03 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="713.91,168.79 719.55,230.01 714.08,229.66 708.43,168.44 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="819.86,157.14 825.5,218.36 815.89,221.27 810.25,160.05 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="708.43,168.44 714.08,229.66 710.82,231.49 705.17,170.27 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="711.52,170.04 717.17,231.25 714.77,231.11 709.12,169.89 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="709.12,169.89 714.77,231.11 712.61,232.3 706.96,171.08 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="713.91,168.79 719.55,230.01 719.65,231.79 714,170.57 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="705.17,170.27 710.82,231.49 708.68,233.2 703.03,171.99 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="701.23,172.56 706.88,233.77 701.87,230.09 696.22,168.88 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="703.03,171.99 708.68,233.2 706.88,233.77 701.23,172.56 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="706.96,171.08 712.61,232.3 710.27,234.21 704.62,172.99 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="808.71,159.03 814.36,220.24 806.71,226.1 801.06,164.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="700.4,174.32 706.04,235.54 699.39,230.64 693.74,169.43 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="704.62,172.99 710.27,234.21 706.04,235.54 700.4,174.32 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="810.25,160.05 815.89,221.27 808.7,226.76 803.06,165.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="714.52,178.2 720.17,239.41 717.23,232.11 711.58,170.9 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="717,178.08 722.65,239.3 719.65,231.79 714,170.57 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="801.06,164.89 806.71,226.1 800.36,232.3 794.71,171.08 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="717,178.08 722.65,239.3 722.42,240.4 716.77,179.18 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="803.06,165.55 808.7,226.76 802.05,233.2 796.4,171.98 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="722.67,180.56 728.32,241.77 722.42,240.4 716.77,179.18 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="794.71,171.08 800.36,232.3 791.9,236.45 786.25,175.24 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="721.1,181.71 726.75,242.92 719.79,241.36 714.14,180.14 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="796.4,171.98 802.05,233.2 792.91,237.64 787.26,176.42 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="786.25,175.24 791.9,236.45 782.43,238.92 776.78,177.7 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="766.37,178.69 772.02,239.91 761.77,240.31 756.12,179.09 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="776.78,177.7 782.43,238.92 772.02,239.91 766.37,178.69 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="756.12,179.09 761.77,240.31 752.27,242.85 746.62,181.63 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="787.26,176.42 792.91,237.64 782.73,240.26 777.08,179.04 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="766.37,180.05 772.02,241.27 761.9,241.67 756.25,180.46 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="777.08,179.04 782.73,240.26 772.02,241.27 766.37,180.05 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="729.61,183.87 735.25,245.08 728.32,241.77 722.67,180.56 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="756.25,180.46 761.9,241.67 753.14,244.07 747.49,182.85 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="737.68,184.16 743.33,245.38 735.25,245.08 729.61,183.87 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="746.62,181.63 752.27,242.85 743.33,245.38 737.68,184.16 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: balakenColor ? balakenColor : null }}
                points="790.53,115.52 795.41,116.79 803.03,117.25 815.28,119.37 818.98,121.26 818.64,125.07 
813.16,133.71 812.96,137.86 817.3,141.03 823.43,141.54 829.67,140.03 840.75,136.31 846.67,135.66 858.65,135.31 
861.51,134.56 861.21,138.35 854.06,145.18 842.17,150.03 829.39,153.46 819.01,155.92 808.71,159.03 801.06,164.89 
794.71,171.08 786.25,175.24 776.78,177.7 766.37,178.69 756.12,179.09 746.62,181.63 737.68,184.16 729.61,183.87 
722.67,180.56 716.77,179.18 717,178.08 714,170.57 713.91,168.79 708.43,168.44 705.17,170.27 703.03,171.99 701.23,172.56 
696.22,168.88 697.17,159.2 702.13,148.43 708.05,141.53 714.36,138.97 720,139.11 732.32,141.99 738.51,142.17 744.02,141.28 
749.59,139.39 765.78,131.92 774.53,126.79 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="728.42,185.2 734.07,246.42 726.75,242.92 721.1,181.71 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="737.93,185.56 743.58,246.78 734.07,246.42 728.42,185.2 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M864.12,132.33l-0.48,6.28l-7.8,7.44l-12.7,5.18l-12.96,3.47l-10.32,2.44l-9.61,2.91l-7.19,5.49l-6.66,6.43
l-9.14,4.44l-10.18,2.62l-10.71,1.01l-10.11,0.4l-8.77,2.4l-9.56,2.71l-9.51-0.36l-7.32-3.5l-6.96-1.57l0.39-1.94l-2.95-7.3
l-0.06-0.86l-2.4-0.15l-2.16,1.19l-2.35,1.91l-4.22,1.33l-6.66-4.89l1.03-10.36l5.06-10.99l6.35-7.39l7.66-3.11l6.97,0.15
l12.32,2.88l5.58,0.18l4.71-0.8l4.91-1.7l15.9-7.33l8.54-5.01l17.28-12.15l6.29,1.63l7.46,0.47l13.1,2.29l4.61,2.37l-0.38,4.61
l-5.5,8.64l-0.15,3.46l3.34,2.46l4.57,0.37l5.38-1.29l11.59-3.86l6.31-0.67l11.86-0.35L864.12,132.33z M854.06,145.18l7.15-6.82
l0.3-3.79l-2.86,0.74l-11.98,0.36l-5.92,0.64l-11.08,3.72l-6.24,1.51l-6.13-0.51l-4.34-3.17l0.2-4.14l5.48-8.64l0.33-3.81
l-3.7-1.89l-12.25-2.12l-7.62-0.46l-4.88-1.27l-16,11.27l-8.75,5.13l-16.18,7.47l-5.57,1.88l-5.51,0.9l-6.2-0.18L720,139.11
l-5.64-0.14l-6.32,2.56l-5.92,6.89l-4.96,10.78l-0.95,9.67l5.01,3.68l1.8-0.57l2.14-1.71l3.26-1.83l5.47,0.35l0.09,1.78l3,7.51
l-0.23,1.1l5.9,1.37l6.94,3.31l8.07,0.3l8.94-2.53l9.5-2.54l10.25-0.4l10.41-0.99l9.47-2.46l8.47-4.16l6.34-6.19l7.65-5.86
l10.3-3.1l10.37-2.46l12.79-3.43L854.06,145.18"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="747.49,182.85 753.14,244.07 743.58,246.78 737.93,185.56 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'zaqatala' ? 'active' : null}`}
            onClick={() => openPopup('zaqatala')}
          >
            <g>
              <polygon
                className="st47"
                points="872.02,131.31 877.66,192.53 876.71,192.43 871.06,131.22 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="871.06,131.22 876.71,192.43 871.12,194.62 865.48,133.41 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="865.48,133.41 871.12,194.62 869.66,195.05 864.01,133.84 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="872.02,131.31 877.66,192.53 877.71,194.46 872.06,133.24 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="874.44,130.13 880.09,191.35 880.15,194.35 874.51,133.14 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="864.01,133.84 869.66,195.05 869.29,199.83 863.64,138.62 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="872.06,133.24 877.71,194.46 876.23,203.22 870.58,142 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="874.51,133.14 880.15,194.35 878.68,203.1 873.04,141.89 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="863.64,138.62 869.29,199.83 861.49,207.27 855.84,146.06 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="873.04,141.89 878.68,203.1 878.8,206 873.16,144.78 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="870.58,142 876.23,203.22 876.34,206.28 870.69,145.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="872.31,147.97 877.96,209.19 876.34,206.28 870.69,145.06 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="855.84,146.06 861.49,207.27 848.78,212.45 843.14,151.24 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="843.14,151.24 848.78,212.45 835.82,215.92 830.18,154.71 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="830.18,154.71 835.82,215.92 825.5,218.36 819.86,157.14 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="819.86,157.14 825.5,218.36 815.89,221.27 810.25,160.05 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="810.25,160.05 815.89,221.27 808.7,226.76 803.06,165.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="886.39,159.62 892.04,220.84 877.96,209.19 872.31,147.97 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="890.38,161.75 896.03,222.96 892.04,220.84 886.39,159.62 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="894.08,162.76 899.72,223.97 896.03,222.96 890.38,161.75 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="803.06,165.55 808.7,226.76 802.05,233.2 796.4,171.98 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="900.92,163.92 906.57,225.13 899.72,223.97 894.08,162.76 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="721.1,181.71 726.75,242.92 722.12,241.88 716.47,180.66 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="903.34,164.96 908.98,226.17 906.57,225.13 900.92,163.92 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="796.4,171.98 802.05,233.2 792.91,237.64 787.26,176.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="903.89,166.04 909.54,227.26 908.98,226.17 903.34,164.96 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="787.26,176.42 792.91,237.64 782.73,240.26 777.08,179.04 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="777.08,179.04 782.73,240.26 772.02,241.27 766.37,180.05 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="766.37,180.05 772.02,241.27 761.9,241.67 756.25,180.46 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="756.25,180.46 761.9,241.67 753.14,244.07 747.49,182.85 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="728.42,185.2 734.07,246.42 726.75,242.92 721.1,181.71 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="903.89,166.04 909.54,227.26 909.2,231.31 903.55,170.09 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="906.36,165.84 912.01,227.05 911.64,231.13 905.99,169.91 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="747.49,182.85 753.14,244.07 743.58,246.78 737.93,185.56 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="737.93,185.56 743.58,246.78 734.07,246.42 728.42,185.2 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="716.47,180.66 722.12,241.88 720.6,249.45 714.96,188.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="903.82,171.83 909.47,233.04 909.2,231.31 903.55,170.09 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="714.96,188.24 720.6,249.45 720.89,254.11 715.24,192.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="906.31,175.3 911.96,236.51 909.47,233.04 903.82,171.83 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="718.22,196.68 723.87,257.9 720.89,254.11 715.24,192.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="716.04,197.45 721.69,258.67 718.45,254.44 712.8,193.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="917.05,183.38 922.7,244.59 911.96,236.51 906.31,175.3 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="730.01,203.72 735.66,264.94 723.87,257.9 718.22,196.68 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="728.29,204.8 733.93,266.01 721.69,258.67 716.04,197.45 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="732.74,204.8 738.39,266.02 735.66,264.94 730.01,203.72 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="731.35,206.02 737,267.24 733.93,266.01 728.29,204.8 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="738.16,206.12 743.81,267.34 738.39,266.02 732.74,204.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="920.81,190.24 926.46,251.45 922.7,244.59 917.05,183.38 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="736.4,207.25 742.05,268.47 737,267.24 731.35,206.02 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="856.22,201.91 861.87,263.12 845.48,264.66 839.83,203.45 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="920.81,190.24 926.46,251.45 925.88,257.98 920.23,196.77 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="923.28,190.03 928.93,251.25 928.33,257.78 922.68,196.56 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="855.9,203.31 861.54,264.52 846.51,265.94 840.87,204.72 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="748.02,214.33 753.67,275.55 743.81,267.34 738.16,206.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="746.06,215.28 751.71,276.5 742.05,268.47 736.4,207.25 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="875.07,204.22 880.72,265.44 861.87,263.12 856.22,201.91 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="873.87,205.49 879.51,266.71 861.54,264.52 855.9,203.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="922.29,201.04 927.94,262.26 925.88,257.98 920.23,196.77 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="839.83,203.45 845.48,264.66 831.75,272.71 826.1,211.49 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="840.87,204.72 846.51,265.94 833.54,273.53 827.89,212.31 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="891.31,209.03 896.95,270.25 880.72,265.44 875.07,204.22 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="922.29,201.04 927.94,262.26 913.81,269.62 908.16,208.4 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="908.16,208.4 913.81,269.62 896.95,270.25 891.31,209.03 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="890.53,210.43 896.18,271.65 879.51,266.71 873.87,205.49 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="908.99,209.75 914.64,270.97 896.18,271.65 890.53,210.43 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="926.95,200.39 932.6,261.61 914.64,270.97 908.99,209.75 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="772.03,223.29 777.67,284.51 753.67,275.55 748.02,214.33 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="770.39,224.4 776.04,285.61 751.71,276.5 746.06,215.28 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="826.1,211.49 831.75,272.71 817.26,283.42 811.61,222.2 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="827.89,212.31 833.54,273.53 818.83,284.36 813.18,223.14 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="783.98,229.42 789.63,290.63 777.67,284.51 772.03,223.29 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="781.66,230.19 787.31,291.41 776.04,285.61 770.39,224.4 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="811.61,222.2 817.26,283.42 800.53,291.48 794.88,230.27 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="813.18,223.14 818.83,284.36 802.45,292.26 796.8,231.05 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="789.76,234.81 795.41,296.02 791.14,296.55 785.49,235.34 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="785.49,235.34 791.14,296.55 789.63,290.63 783.98,229.42 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="790.58,235.27 796.23,296.49 795.41,296.02 789.76,234.81 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="794.88,230.27 800.53,291.48 796.23,296.49 790.58,235.27 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M924.59,200.38l2.37,0.02l-17.96,9.36l-18.46,0.68l-16.67-4.94l-17.97-2.18l-15.03,1.41l-12.98,7.59
l-14.71,10.83l-16.39,7.9l-5.68,6.62l-2.38-1.36l-5.35,0.67l-1.72-6.78l-11.27-5.79l-24.33-9.12l-9.66-8.03l-5.06-1.23
l-3.06-1.22l-12.25-7.35l-3.24-4.23l-0.29-4.89l1.93-9.68l8.23,1.89l6.94,3.31l8.07,0.3l8.94-2.53l9.5-2.54l10.25-0.4l10.41-0.99
l9.47-2.46l8.47-4.16l6.34-6.19l7.65-5.86l10.3-3.1l10.37-2.46l12.79-3.43l11.88-4.85l7.15-6.82l0.41-5.3l2.76-0.8l6.35-2.49
l3.71,0.38l0.07,3l-1.47,8.75l0.12,2.89l1.46,2.55l13.71,11.34l3.71,1.96l3.13,0.85l7.22,1.26l3.12,1.33l0.85,1.77l-0.37,4.07
l0.26,1.55l2.28,3.12l10.82,8.17l3.93,7.28l-0.6,6.53L924.59,200.38z M908.16,208.4l14.13-7.36l-2.06-4.28l0.58-6.53l-3.75-6.86
l-10.74-8.08l-2.49-3.47l-0.27-1.73l0.34-4.05l-0.55-1.08l-2.42-1.04l-6.84-1.16l-3.69-1.01l-3.99-2.12l-14.07-11.65l-1.62-2.91
l-0.11-3.06l1.47-8.76l-0.04-1.93l-0.95-0.1l-5.59,2.19l-1.47,0.43l-0.37,4.78l-7.8,7.44l-12.7,5.18l-12.96,3.47l-10.32,2.44
l-9.61,2.91l-7.19,5.49l-6.66,6.43l-9.14,4.44l-10.18,2.62l-10.71,1.01l-10.11,0.4l-8.77,2.4l-9.56,2.71l-9.51-0.36l-7.32-3.5
l-4.64-1.04l-1.51,7.57l0.29,4.66l2.98,3.79l11.79,7.04l2.73,1.08l5.42,1.32l9.86,8.21l24,8.96l11.96,6.12l1.51,5.92l4.27-0.53
l0.82,0.47l4.3-5.01l16.73-8.06l14.49-10.71l13.72-8.05l16.4-1.54l18.84,2.31l16.24,4.81L908.16,208.4"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: zaqatalaColor ? zaqatalaColor : null }}
                points="871.06,131.22 872.02,131.31 872.06,133.24 870.58,142 870.69,145.06 872.31,147.97 886.39,159.62 
890.38,161.75 894.08,162.76 900.92,163.92 903.34,164.96 903.89,166.04 903.55,170.09 903.82,171.83 906.31,175.3 
917.06,183.38 920.81,190.24 920.23,196.77 922.29,201.04 908.16,208.4 891.31,209.03 875.07,204.22 856.22,201.91 
839.83,203.45 826.1,211.49 811.61,222.2 794.88,230.27 790.58,235.27 789.76,234.81 785.49,235.34 783.98,229.42 772.03,223.29 
748.02,214.33 738.16,206.12 732.74,204.8 730.01,203.72 718.22,196.68 715.24,192.89 714.96,188.24 716.47,180.66 721.1,181.71 
728.42,185.2 737.93,185.56 747.49,182.85 756.25,180.46 766.37,180.05 777.08,179.04 787.26,176.42 796.4,171.98 803.06,165.55 
810.25,160.05 819.86,157.14 830.18,154.71 843.14,151.24 855.84,146.06 863.64,138.62 864.01,133.84 865.48,133.41 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="788.74,236.31 794.39,297.53 789.03,298.19 783.39,236.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="783.39,236.98 789.03,298.19 787.31,291.41 781.66,230.19 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="791.12,237.67 796.77,298.88 794.39,297.53 788.74,236.31 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="796.8,231.05 802.45,292.26 796.77,298.88 791.12,237.67 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qax' ? 'active' : null}`}
            onClick={() => openPopup('qax')}
          >
            <g>
              <polygon
                className="st8"
                points="855.9,203.31 861.55,264.52 846.52,265.94 840.87,204.72 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="873.87,205.49 879.51,266.71 861.55,264.52 855.9,203.31 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="935.7,201.85 941.35,263.06 929.99,262.96 924.34,201.75 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="941.74,201.1 947.39,262.31 941.35,263.06 935.7,201.85 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="947.14,200.95 952.79,262.16 947.39,262.31 941.74,201.1 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="840.87,204.72 846.52,265.94 833.54,273.53 827.89,212.31 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="951.25,202.01 956.9,263.22 952.79,262.16 947.14,200.95 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="890.53,210.43 896.18,271.65 879.51,266.71 873.87,205.49 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="954.02,205.08 959.67,266.3 956.9,263.22 951.25,202.01 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="908.99,209.75 914.64,270.97 896.18,271.65 890.53,210.43 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="924.34,201.75 929.99,262.96 914.64,270.97 908.99,209.75 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="954.02,205.08 959.67,266.3 959.96,269.95 954.31,208.73 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="956.47,204.73 962.12,265.95 962.41,269.84 956.76,208.63 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="827.89,212.31 833.54,273.53 818.83,284.36 813.19,223.14 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="954.31,208.73 959.96,269.95 959.23,273.81 953.58,212.59 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="956.76,208.63 962.41,269.84 961.67,273.67 956.02,212.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="954.12,216.1 959.77,277.31 959.23,273.81 953.58,212.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="813.19,223.14 818.83,284.36 802.45,292.26 796.8,231.05 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="958.23,218.81 963.88,280.02 959.77,277.31 954.12,216.1 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="958.16,218.73 963.81,279.95 963.19,280.55 957.54,219.33 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="961.15,218.78 966.8,280 965.18,281.22 959.53,220 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="796.8,231.05 802.45,292.26 797.94,297.53 792.29,236.32 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="789.93,236.99 795.58,298.2 795.04,297.89 789.39,236.67 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="793.34,238.37 798.99,299.59 797.94,297.53 792.29,236.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="791.04,239 796.69,300.21 795.58,298.2 789.93,236.99 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="957.54,219.33 963.19,280.55 956.14,288.95 950.49,227.73 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="797.02,242.23 802.67,303.44 798.99,299.59 793.34,238.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="794.76,242.88 800.41,304.1 796.69,300.21 791.04,239 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="959.53,220 965.18,281.22 958.05,289.7 952.4,228.49 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="950.49,227.73 956.14,288.95 946.68,294.97 941.03,233.75 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="952.4,228.49 958.05,289.7 948.02,296.07 942.37,234.85 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="802.29,251 807.94,312.22 802.67,303.44 797.02,242.23 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="941.03,233.75 946.68,294.97 919.78,301.9 914.13,240.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="799.85,251.33 805.5,312.54 800.41,304.1 794.76,242.88 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="942.37,234.85 948.02,296.07 920.37,303.19 914.72,241.98 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="914.13,240.69 919.78,301.9 878.66,308.04 873.01,246.82 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="914.72,241.98 920.37,303.19 879.22,309.34 873.57,248.12 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="802.65,254.85 808.3,316.06 807.94,312.22 802.29,251 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="802.65,254.85 808.3,316.06 805.91,316.52 800.26,255.3 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="800.26,255.3 805.91,316.52 803.92,316.17 798.27,254.95 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="873.01,246.82 878.66,308.04 865.94,311.08 860.3,249.87 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="798.27,254.95 803.92,316.17 801.5,317.24 795.85,256.03 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="873.57,248.12 879.22,309.34 866.88,312.3 861.23,251.08 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="795.85,256.03 801.5,317.24 800.32,318.67 794.67,257.45 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="860.3,249.87 865.94,311.08 859.4,314.08 853.75,252.87 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="861.23,251.08 866.88,312.3 860.99,315.04 855.34,253.82 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="794.67,257.45 800.32,318.67 798.32,322.3 792.67,261.08 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="853.75,252.87 859.4,314.08 854.73,317.82 849.08,256.6 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="792.67,261.08 798.32,322.3 796.68,323.98 791.03,262.76 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="855.34,253.82 860.99,315.04 856.44,318.71 850.79,257.49 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="791.03,262.76 796.68,323.98 794.17,325.29 788.52,264.07 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="849.08,256.6 854.73,317.82 849.91,320.41 844.26,259.19 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="788.52,264.07 794.17,325.29 789.52,326.96 783.87,265.74 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="850.79,257.49 856.44,318.71 851.05,321.55 845.41,260.34 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="844.26,259.19 849.91,320.41 843.55,322.36 837.9,261.15 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="783.87,265.74 789.52,326.96 787.61,327.95 781.96,266.74 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="845.41,260.34 851.05,321.55 845.08,323.39 839.43,262.17 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="781.96,266.74 787.61,327.95 782.28,333.35 776.63,272.14 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="837.9,261.15 843.55,322.36 833.65,330 828,268.79 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="839.43,262.17 845.08,323.39 835.69,330.63 830.04,269.41 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="776.63,272.14 782.28,333.35 777.42,340.44 771.77,279.23 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="828,268.79 833.65,330 826.87,340.23 821.22,279.01 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="830.04,269.41 835.69,330.63 829.2,340.51 823.55,279.29 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="771.77,279.23 777.42,340.44 771.47,347.4 765.83,286.18 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="765.83,286.18 771.47,347.4 770.24,348.04 764.59,286.83 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="770.15,290.51 775.8,351.73 770.24,348.04 764.59,286.83 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="768.66,291.79 774.31,353 766.84,348.04 761.19,286.82 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="821.22,279.01 826.87,340.23 824.38,349.21 818.73,287.99 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="823.55,279.29 829.2,340.51 827.01,348.53 821.36,287.32 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="782.71,291.88 788.36,353.1 775.8,351.73 770.15,290.51 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="781.39,293.17 787.04,354.38 774.31,353 768.66,291.79 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="829.83,293.03 835.48,354.25 824.38,349.21 818.73,287.99 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="792.73,297.04 798.38,358.26 788.36,353.1 782.71,291.88 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="790.12,297.68 795.77,358.9 787.04,354.38 781.39,293.17 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="792.73,297.04 798.37,358.26 796.69,364.64 791.04,303.43 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="791.04,303.43 796.69,364.64 795.46,370.87 789.81,309.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="789.09,315.08 794.74,376.29 792.99,370.98 787.34,309.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="791.55,314.99 797.2,376.2 795.46,370.87 789.81,309.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="840.54,312.45 846.19,373.67 835.48,354.25 829.83,293.03 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="791.55,314.99 797.2,376.2 795.95,381.53 790.3,320.31 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="811.92,326.57 817.57,387.78 795.95,381.53 790.3,320.31 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="810.32,327.7 815.97,388.92 793.31,382.38 787.66,321.17 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="830.55,335.35 836.2,396.57 817.57,387.78 811.92,326.57 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="840.54,312.45 846.19,373.67 836.2,396.57 830.55,335.35 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M960.23,217.87l0.92,0.91l-1.62,1.22l-7.14,8.48l-10.02,6.37l-27.65,7.12l-41.15,6.14l-12.34,2.96
l-5.89,2.74l-4.55,3.67l-5.38,2.85l-5.97,1.84l-9.39,7.24l-6.49,9.88l-2.19,8.02l10.64,4.82l11.09,20.13l-11.19,25.62
l-21.58-10.18l-22.66-6.54l1.43-6.09l-1.75-5.32l1.27-6.36l1.51-5.72l-8.72-4.51l-12.73-1.38l-7.47-4.96l2.88-1.5l5.45-6.51
l4.9-7.13l5.81-5.81l2.29-1.18l4.72-1.72l2.04-1.07l1.13-1.23l1.98-3.59l1.54-1.85l4.08-1.82l2.05,0.37l-0.22-2.46l-5.09-8.44
l-3.71-3.88l-1.11-2.01l-0.54-0.32l5.49-6.41l16.73-8.06l14.49-10.71l13.72-8.05l16.4-1.54l18.84,2.31l16.24,4.81l16.86-0.63
l15.45-8.04l12.27,0.1l5.78-0.73l6.19-0.19l5.28,1.37l3.33,3.81l0.29,3.89l-0.74,3.83l0.48,2.98L960.23,217.87z M957.54,219.33
l0.69-0.53l-4.12-2.71l-0.54-3.5l0.74-3.86l-0.3-3.65l-2.77-3.07l-4.12-1.06l-5.4,0.15l-6.04,0.75l-11.36-0.1l-15.35,8.01
l-18.46,0.68l-16.67-4.94l-17.97-2.18l-15.03,1.41l-12.98,7.59l-14.71,10.83l-16.39,7.9l-4.51,5.27l1.05,2.06l3.68,3.86
l5.27,8.78l0.36,3.85l-2.39,0.45l-1.99-0.35l-2.42,1.08l-1.18,1.43l-2,3.63l-1.64,1.68l-2.51,1.31l-4.65,1.67l-1.91,0.99
l-5.33,5.4l-4.86,7.09l-5.95,6.95l-1.24,0.64l5.56,3.69l12.56,1.37l10.01,5.16l-1.68,6.38l-1.23,6.23l1.74,5.33l-1.25,5.32
l21.62,6.25l18.63,8.79l10-22.9l-10.72-19.42l-11.09-5.04l2.43-8.91l6.84-10.29l9.89-7.64l6.36-1.96l4.82-2.59l4.67-3.74l6.54-3
l12.72-3.05l41.12-6.13l26.89-6.93l9.46-6.02L957.54,219.33"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qaxColor ? qaxColor : null }}
                points="947.14,200.95 951.25,202.01 954.02,205.08 954.31,208.73 953.58,212.59 954.12,216.1 
958.16,218.73 957.54,219.33 950.49,227.73 941.03,233.75 914.13,240.69 873.01,246.82 860.3,249.87 853.75,252.87 849.08,256.6 
844.26,259.19 837.9,261.15 828,268.79 821.22,279.01 818.73,287.99 829.83,293.03 840.54,312.45 830.55,335.35 811.92,326.57 
790.3,320.31 791.55,314.99 789.81,309.66 791.04,303.43 792.73,297.04 782.71,291.88 770.15,290.51 764.59,286.83 
765.83,286.18 771.77,279.23 776.63,272.14 781.96,266.74 783.87,265.74 788.52,264.07 791.03,262.76 792.67,261.08 
794.67,257.45 795.85,256.03 798.27,254.95 800.26,255.3 802.65,254.85 802.29,251 797.02,242.23 793.34,238.37 792.29,236.32 
796.8,231.05 813.19,223.14 827.89,212.31 840.87,204.72 855.9,203.31 873.87,205.49 890.53,210.43 908.99,209.75 924.34,201.75 
935.7,201.85 941.74,201.1 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="831.9,337.88 837.55,399.1 815.97,388.92 810.32,327.7 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="843.09,312.27 848.74,373.48 837.55,399.1 831.9,337.88 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'seki' ? 'active' : null}`}
            onClick={() => openPopup('seki')}
          >
            <g>
              <polygon
                className="st11"
                points="960.08,220.57 965.73,281.79 965.17,281.24 959.52,220.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="960.47,222.38 966.12,283.6 965.73,281.79 960.08,220.57 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="962.94,222.15 968.59,283.37 968.54,285.62 962.89,224.4 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="960.47,222.38 966.12,283.6 966.08,285.85 960.43,224.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="960.92,226.94 966.57,288.15 966.08,285.85 960.43,224.64 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="959.52,220.03 965.17,281.24 958.05,289.7 952.4,228.49 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="952.4,228.49 958.05,289.7 948.02,296.07 942.37,234.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="966.69,234.09 972.34,295.3 966.57,288.15 960.92,226.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="967.55,236.62 973.2,297.84 972.34,295.3 966.69,234.09 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="942.37,234.85 948.02,296.07 920.37,303.19 914.72,241.98 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="914.72,241.98 920.37,303.19 879.22,309.34 873.57,248.12 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="873.57,248.12 879.22,309.34 866.88,312.3 861.23,251.08 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="967.55,236.62 973.2,297.84 971.83,302.95 966.18,241.73 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="970,236.56 975.65,297.77 974.23,303.09 968.58,241.88 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="861.23,251.08 866.88,312.3 860.99,315.04 855.34,253.82 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="855.34,253.82 860.99,315.04 856.44,318.71 850.79,257.49 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="850.79,257.49 856.44,318.71 851.05,321.55 845.41,260.34 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="845.41,260.34 851.05,321.55 845.08,323.39 839.43,262.17 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="966.18,241.73 971.83,302.95 965.97,314.22 960.33,253.01 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="968.58,241.88 974.23,303.09 968.43,314.23 962.78,253.02 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="839.43,262.17 845.08,323.39 835.69,330.63 830.04,269.41 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="960.33,253.01 965.97,314.22 966.45,319.65 960.8,258.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="964.55,261.82 970.2,323.04 966.45,319.65 960.8,258.43 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="830.04,269.41 835.69,330.63 829.2,340.51 823.55,279.29 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="936.17,271.57 941.82,332.78 936.79,334.28 931.14,273.07 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="935.38,273.29 941.03,334.5 938.43,335.27 932.78,274.05 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="939.63,273.94 945.28,335.15 941.82,332.78 936.17,271.57 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="937.31,274.56 942.96,335.78 941.03,334.5 935.38,273.29 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="982.75,271.27 988.4,332.48 970.2,323.04 964.55,261.82 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="931.14,273.07 936.79,334.28 933.01,337.77 927.36,276.55 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="932.78,274.05 938.43,335.27 935.11,338.35 929.46,277.13 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="823.55,279.29 829.2,340.51 827.01,348.53 821.36,287.32 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="987.68,272.92 993.32,334.14 988.4,332.48 982.75,271.27 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="927.36,276.55 933.01,337.77 929.24,341.57 923.59,280.35 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="929.46,277.13 935.11,338.35 931.33,342.16 925.68,280.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="940.22,280.52 945.87,341.74 942.96,335.78 937.31,274.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="942.68,280.44 948.33,341.66 945.28,335.15 939.63,273.94 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="832,292.14 837.65,353.35 827.01,348.53 821.36,287.32 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="923.59,280.35 929.24,341.57 924.63,345.77 918.98,284.56 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="829.83,293.03 835.48,354.25 824.38,349.21 818.73,287.99 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="925.68,280.95 931.33,342.16 926.74,346.34 921.09,285.12 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="942.68,280.44 948.33,341.66 947.05,346.24 941.4,285.02 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="987.68,272.92 993.32,334.14 978.45,353.17 972.8,291.96 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="990.74,272.27 996.38,333.49 989.44,342.5 983.79,281.28 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="921.09,285.12 926.74,346.34 925.01,348.92 919.36,287.7 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="918.98,284.56 924.63,345.77 921.96,349.69 916.31,288.48 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="941.4,285.02 947.05,346.24 939.38,351.87 933.73,290.65 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="933.73,290.65 939.38,351.87 933.91,352.87 928.26,291.66 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="928.26,291.66 933.91,352.87 921.96,349.69 916.31,288.48 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="843.09,312.27 848.74,373.48 837.65,353.35 832,292.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="840.54,312.45 846.19,373.67 835.48,354.25 829.83,293.03 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="972.8,291.96 978.45,353.17 959.69,366.85 954.04,305.63 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="974.79,292.64 980.44,353.85 961.58,367.6 955.93,306.38 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="954.04,305.63 959.69,366.85 949.72,374.34 944.07,313.13 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="955.93,306.38 961.58,367.6 951.75,374.97 946.1,313.75 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="944.07,313.13 949.72,374.34 942.01,382.9 936.36,321.69 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="946.1,313.75 951.75,374.97 944.62,382.92 938.97,321.7 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="843.1,312.27 848.74,373.48 838.24,397.51 832.59,336.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="938.8,327.32 944.45,388.54 942.01,382.9 936.36,321.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="942.95,331.74 948.6,392.96 944.45,388.54 938.8,327.32 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="942.95,331.74 948.6,392.96 946.16,395.73 940.51,334.51 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="945.72,331.58 951.37,392.79 948.87,395.63 943.22,334.42 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="947.75,336.91 953.4,398.12 948.35,398.66 942.7,337.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="942.7,337.45 948.35,398.66 946.16,395.73 940.51,334.51 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="950.58,337.31 956.23,398.52 953.4,398.12 947.75,336.91 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="950.58,337.31 956.23,398.52 956.16,401.96 950.51,340.74 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="953.04,336.2 958.69,397.41 958.63,401.86 952.98,340.64 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="950.51,340.74 956.16,401.96 955.51,406.62 949.86,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="952.98,340.64 958.63,401.86 957.96,406.45 952.32,345.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="951.78,349.76 957.43,410.97 955.51,406.62 949.86,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="955.26,353.52 960.91,414.74 957.43,410.97 951.78,349.76 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="983.79,281.28 989.44,342.5 980.44,353.85 974.79,292.64 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="975.19,366.78 980.84,428 960.91,414.74 955.26,353.52 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="997.61,377.03 1003.26,438.25 980.84,428 975.19,366.78 			"
              />
            </g>
            <g>
              <path
                className="st59"
                style={{ fill: sekiColor ? sekiColor : null }}
                d="M975.19,366.78l22.42,10.25l-4.35,4.5l-9.85,0.73l-11.4,0.25l-9.64-2.3l-4.32-1.86l-4.96-1.43l-3.73,0.62
l-2.24,0.51l-6.27-0.59l-6.15-2.35l-10.79-2.68l-10.91-2.37l-18.73-7.15l-18.9-6.9l-21.37-10.31l-21.43-9.43l10.51-24.03
L832,292.14l-10.64-4.82l2.19-8.02l6.49-9.88l9.39-7.24l5.97-1.84l5.38-2.85l4.55-3.67l5.89-2.74l12.34-2.96l41.15-6.14
l27.65-7.12l10.02-6.37l7.13-8.46l0.56,0.55l0.39,1.81l-0.04,2.25l0.49,2.3l5.77,7.15l0.87,2.54l-1.37,5.11l-5.86,11.27
l0.48,5.42l3.75,3.39l18.2,9.45l4.92,1.66l-14.87,19.03l-18.76,13.68l-9.98,7.49l-7.71,8.56l2.44,5.63l4.15,4.42l-2.44,2.77
l2.19,2.93l5.05-0.54l2.83,0.4l-0.07,3.44l-0.65,4.66l1.92,4.35l3.48,3.76L975.19,366.78z M933.73,290.65l7.68-5.63l1.28-4.58
l-3.05-6.5l-3.45-2.37l-5.04,1.5l-3.78,3.48l-3.77,3.8l-4.6,4.2l-2.68,3.92l11.95,3.18L933.73,290.65"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M939.63,273.94l3.05,6.5l-1.28,4.58l-7.68,5.63l-5.47,1l-11.95-3.18l2.68-3.92l4.6-4.2l3.77-3.8l3.78-3.48
l5.04-1.5L939.63,273.94z M932.38,289.5l6.72-4.92l1.12-4.06l-2.91-5.96l-1.93-1.28l-2.6,0.77l-3.32,3.08l-3.78,3.81l-4.59,4.18
l-1.73,2.58l9.3,2.48L932.38,289.5"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="854.02,345.72 859.67,406.94 838.24,397.51 832.59,336.3 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="852.34,346.82 857.99,408.04 835.51,398.15 829.86,336.94 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="875.38,356.03 881.03,417.25 859.67,406.94 854.02,345.72 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="873.79,357.16 879.44,418.37 857.99,408.04 852.34,346.82 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="894.28,362.93 899.93,424.15 881.03,417.25 875.38,356.03 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="892.77,364.1 898.42,425.31 879.44,418.37 873.79,357.16 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="913.01,370.08 918.66,431.29 899.93,424.15 894.28,362.93 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="911.6,371.28 917.25,432.5 898.42,425.31 892.77,364.1 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="923.92,372.45 929.57,433.66 918.66,431.29 913.01,370.08 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="922.76,373.74 928.41,434.95 917.25,432.5 911.6,371.28 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="934.71,375.13 940.36,436.34 929.57,433.66 923.92,372.45 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="933.36,376.35 939.01,437.56 928.41,434.95 922.76,373.74 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="940.86,377.48 946.51,438.69 940.36,436.34 934.71,375.13 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="953.1,376.94 958.75,438.16 955.02,438.77 949.37,377.56 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="939.52,378.72 945.17,439.94 939.01,437.56 933.36,376.35 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="949.37,377.56 955.02,438.77 952.78,439.28 947.13,378.06 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="947.13,378.06 952.78,439.28 946.51,438.69 940.86,377.48 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="952.6,378.42 958.25,439.63 955.59,440.06 949.94,378.85 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="958.06,378.37 963.71,439.59 958.75,438.16 953.1,376.94 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="949.94,378.85 955.59,440.06 952.78,440.7 947.13,379.48 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="947.13,379.48 952.78,440.7 945.17,439.94 939.52,378.72 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="956.59,379.56 962.24,440.77 958.25,439.63 952.6,378.42 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="962.38,380.23 968.03,441.44 963.71,439.59 958.06,378.37 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="960.85,381.4 966.5,442.61 962.24,440.77 956.59,379.56 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="972.02,382.52 977.67,443.74 968.03,441.44 962.38,380.23 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="983.41,382.27 989.06,443.49 977.67,443.74 972.02,382.52 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="997.61,377.03 1003.26,438.25 998.91,442.75 993.26,381.54 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="993.26,381.54 998.91,442.75 989.06,443.49 983.41,382.27 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="971.32,383.91 976.97,445.12 966.5,442.61 960.85,381.4 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="983.34,383.64 988.99,444.86 976.97,445.12 971.32,383.91 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="994.71,382.8 1000.36,444.02 988.99,444.86 983.34,383.64 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1000.71,376.58 1006.36,437.79 1000.36,444.02 994.71,382.8 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M977.01,365.75l23.7,10.82l-6,6.22l-11.36,0.84l-12.02,0.26l-10.47-2.51l-4.26-1.84l-3.99-1.14l-2.67,0.43
l-2.81,0.63l-7.61-0.76l-6.16-2.38l-10.6-2.61l-11.16-2.46l-18.83-7.18l-18.99-6.94l-21.45-10.33l-22.48-9.89l10.68-24.49
l-10.72-19.42l-11.09-5.04l2.43-8.91l6.84-10.29l9.89-7.64l6.36-1.96l4.82-2.59l4.67-3.74l6.54-3l12.72-3.05l41.12-6.13
l26.89-6.93l9.46-6.02l7.05-8.4l2.35-1.77l2.61,2.56l0.44,2.03l-0.04,2.25l0.44,2.12l5.73,7.08l0.94,2.95l-1.43,5.32l-5.79,11.14
l0.46,4.86l3.37,3.06l17.74,9.19l6.38,2.14l-6.94,9.01l-9,11.36l-18.86,13.75l-9.83,7.37l-7.13,7.95l2.17,5.09l4.58,4.79
l-2.5,2.84l1.1,1.49l3.78-0.4l4.94,0.69l-0.06,4.45l-0.66,4.6l1.83,3.97l3.21,3.46L977.01,365.75z M993.26,381.54l4.35-4.5
l-22.42-10.25l-19.93-13.26l-3.48-3.76l-1.92-4.35l0.65-4.66l0.07-3.44l-2.83-0.4l-5.05,0.54l-2.19-2.93l2.44-2.77l-4.15-4.42
l-2.44-5.63l7.71-8.56l9.98-7.49l18.76-13.68l14.87-19.03l-4.92-1.66l-18.2-9.45l-3.75-3.39l-0.48-5.42l5.86-11.27l1.37-5.11
l-0.87-2.54l-5.77-7.15l-0.49-2.3l0.04-2.25l-0.39-1.81l-0.56-0.55l-7.13,8.46l-10.02,6.37l-27.65,7.12l-41.15,6.14l-12.34,2.96
l-5.89,2.74l-4.55,3.67l-5.38,2.85l-5.97,1.84l-9.39,7.24l-6.49,9.88l-2.19,8.02l10.64,4.82l11.09,20.13l-10.51,24.03l21.43,9.43
l21.37,10.31l18.9,6.9l18.73,7.15l10.91,2.37l10.79,2.68l6.15,2.35l6.27,0.59l2.24-0.51l3.73-0.62l4.96,1.43l4.32,1.86l9.64,2.3
l11.4-0.25L993.26,381.54"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'oguz' ? 'active' : null}`}
            onClick={() => openPopup('oguz')}
          >
            <g>
              <polygon
                className="st19"
                points="990.59,273.91 996.23,335.12 995.35,334.83 989.7,273.61 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="989.7,273.61 995.35,334.83 989.44,342.5 983.79,281.28 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1019.04,278.09 1024.69,339.31 996.23,335.12 990.59,273.91 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1024.14,279.7 1029.79,340.91 1024.69,339.31 1019.04,278.09 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1028.92,286.55 1034.57,347.76 1029.79,340.91 1024.14,279.7 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="983.79,281.28 989.44,342.5 980.44,353.85 974.79,292.64 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1057.33,286.57 1062.97,347.78 1057.4,348.48 1051.76,287.27 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1033.32,289.65 1038.97,350.86 1034.57,347.76 1028.92,286.55 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="1062.28,287.36 1067.93,348.58 1062.97,347.78 1057.33,286.57 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1051.76,287.27 1057.4,348.48 1045.45,351.47 1039.8,290.25 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1039.8,290.25 1045.45,351.47 1038.97,350.86 1033.32,289.65 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1074.15,292.59 1079.79,353.81 1067.93,348.58 1062.28,287.36 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1074.15,292.59 1079.79,353.81 1078.38,355.96 1072.73,294.74 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1077.05,292.03 1082.7,353.25 1080.71,356.25 1075.06,295.03 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="974.79,292.64 980.44,353.85 961.58,367.6 955.93,306.38 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1072.73,294.74 1078.38,355.96 1075.46,361.53 1069.81,300.31 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1075.06,295.03 1080.71,356.25 1077.93,361.57 1072.28,300.35 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="955.93,306.38 961.58,367.6 951.75,374.97 946.1,313.75 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1069.81,300.31 1075.46,361.53 1075.59,364.27 1069.94,303.06 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1072.28,300.35 1077.93,361.57 1078.02,364.14 1072.37,302.92 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1069.94,303.06 1075.59,364.27 1075.39,366.66 1069.74,305.45 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1072.37,302.92 1078.02,364.14 1077.82,366.93 1072.17,305.71 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="946.1,313.75 951.75,374.97 944.62,382.92 938.97,321.7 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1069.74,305.45 1075.39,366.66 1070.67,371.3 1065.02,310.09 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1072.17,305.71 1077.82,366.93 1072.79,371.83 1067.14,310.62 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1065.02,310.09 1070.67,371.3 1065.99,376.51 1060.34,315.29 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="941.14,326.79 946.79,388 944.62,382.92 938.97,321.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="938.8,327.32 944.45,388.54 942.01,382.9 936.36,321.69 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1067.14,310.62 1072.79,371.83 1067.97,377.19 1062.33,315.97 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="945.72,331.58 951.37,392.79 946.79,388 941.14,326.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="942.95,331.74 948.6,392.96 944.45,388.54 938.8,327.32 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1060.34,315.29 1065.99,376.51 1053.44,385.74 1047.79,324.52 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="945.72,331.58 951.37,392.79 948.87,395.63 943.22,334.42 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1062.33,315.97 1067.97,377.19 1055.26,386.54 1049.61,325.32 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="944.32,335.9 949.97,397.12 948.87,395.63 943.22,334.42 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="948.1,335.51 953.75,396.72 949.97,397.12 944.32,335.9 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="953.04,336.2 958.69,397.41 953.75,396.72 948.1,335.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="942.7,337.45 948.35,398.66 946.16,395.73 940.51,334.51 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="947.75,336.91 953.4,398.12 948.35,398.66 942.7,337.45 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="950.58,337.31 956.23,398.52 953.4,398.12 947.75,336.91 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1047.79,324.52 1053.44,385.74 1040.15,394.27 1034.5,333.05 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="953.04,336.2 958.68,397.41 958.63,401.86 952.98,340.64 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1049.61,325.32 1055.26,386.54 1042.45,394.75 1036.8,333.54 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="952.98,340.64 958.63,401.86 957.96,406.45 952.32,345.24 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1034.5,333.05 1040.15,394.27 1038.89,399.52 1033.24,338.3 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1036.8,333.54 1042.45,394.75 1041.28,399.71 1035.64,338.5 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="954.15,349.21 959.79,410.43 957.96,406.45 952.32,345.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="951.78,349.76 957.43,410.97 955.51,406.62 949.86,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1033.24,338.3 1038.89,399.52 1035.57,404.38 1029.92,343.16 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1035.64,338.5 1041.28,399.71 1037.49,405.12 1031.84,343.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="957.35,352.67 963,413.89 959.79,410.43 954.15,349.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="955.26,353.52 960.91,414.74 957.43,410.97 951.78,349.76 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1029.92,343.16 1035.57,404.38 1021.96,412.86 1016.31,351.64 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1031.84,343.91 1037.49,405.12 1023.81,413.63 1018.16,352.42 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="977.01,365.75 982.66,426.97 963,413.89 957.35,352.67 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1016.31,351.64 1021.96,412.86 1010.27,424.82 1004.62,363.6 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="975.19,366.78 980.84,428 960.91,414.74 955.26,353.52 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1018.16,352.42 1023.81,413.63 1012.46,425.29 1006.81,364.08 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1004.62,363.6 1010.27,424.82 1004.19,436.8 998.54,375.58 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="998.54,375.58 1004.19,436.8 982.66,426.97 977.01,365.75 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: oguzColor ? oguzColor : null }}
                points="989.7,273.61 990.59,273.91 1019.04,278.09 1024.14,279.7 1028.92,286.55 1033.32,289.65 
1039.8,290.25 1051.76,287.27 1057.33,286.57 1062.28,287.36 1074.15,292.59 1072.73,294.74 1069.81,300.31 1069.94,303.06 
1069.74,305.45 1065.02,310.09 1060.34,315.29 1047.79,324.52 1034.5,333.05 1033.24,338.3 1029.92,343.16 1016.31,351.64 
1004.62,363.6 998.54,375.58 977.01,365.75 957.35,352.67 954.15,349.21 952.32,345.24 952.98,340.64 953.04,336.2 948.1,335.51 
944.32,335.9 943.22,334.42 945.72,331.58 941.14,326.79 938.97,321.7 946.1,313.75 955.93,306.38 974.79,292.64 983.79,281.28 
            "
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1072.37,302.92l-0.21,2.79l-5.02,4.9l-4.82,5.36l-12.72,9.35l-12.81,8.22l-1.17,4.96l-3.8,5.41
l-13.68,8.51l-11.35,11.66l-7.03,13.95l-24.59-11.25l-19.93-13.26l-3.48-3.76l-1.92-4.35l0.65-4.66l0.07-3.44l-2.83-0.4
l-5.05,0.54l-2.19-2.93l2.44-2.77l-4.15-4.42l-2.44-5.63l7.71-8.56l9.98-7.49l18.76-13.68l15.9-20.37l3.17,1.08l28.4,4.17
l5.88,1.84l4.99,7.16l3.64,2.54l4.96,0.46l11.62-2.89l6.31-0.8l6.11,1.03l13.28,5.86l-2,3l-2.78,5.32L1072.37,302.92z
 M1065.02,310.09l4.72-4.64l0.2-2.39l-0.13-2.74l2.92-5.57l1.41-2.15l-11.87-5.23l-4.95-0.79l-5.57,0.7l-11.95,2.98l-6.48-0.61
l-4.4-3.1l-4.78-6.85l-5.1-1.61l-28.46-4.19l-0.88-0.29l-5.91,7.67l-9,11.36l-18.86,13.75l-9.83,7.37l-7.13,7.95l2.17,5.09
l4.58,4.79l-2.5,2.84l1.1,1.49l3.78-0.4l4.94,0.69l-0.06,4.45l-0.66,4.6l1.83,3.97l3.21,3.46l19.66,13.08l21.53,9.83l6.08-11.98
l11.69-11.96l13.62-8.48l3.32-4.86l1.26-5.25l13.29-8.53l12.55-9.23L1065.02,310.09"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1006.81,364.08 1012.46,425.29 1005.43,439.24 999.78,378.03 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="999.78,378.03 1005.43,439.24 980.84,428 975.19,366.78 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'seki' ? 'active' : null}`}
            onClick={() => openPopup('seki')}
          >
            <g>
              <polygon
                className="st52"
                points="935.38,273.29 941.03,334.5 938.43,335.27 932.78,274.05 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="937.31,274.56 942.96,335.78 941.03,334.5 935.38,273.29 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="932.78,274.05 938.43,335.27 935.11,338.35 929.46,277.13 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="929.46,277.13 935.11,338.35 931.33,342.16 925.68,280.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="940.22,280.52 945.87,341.74 942.96,335.78 937.31,274.56 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="925.68,280.95 931.33,342.16 926.74,346.34 921.09,285.12 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="940.22,280.52 945.87,341.74 944.75,345.8 939.1,284.58 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="942.68,280.44 948.33,341.66 947.05,346.24 941.4,285.02 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="921.09,285.12 926.74,346.34 925.01,348.92 919.36,287.7 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="939.1,284.58 944.75,345.8 938.03,350.72 932.38,289.5 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="932.38,289.5 938.03,350.72 934.31,351.4 928.66,290.19 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="928.66,290.19 934.31,351.4 925.01,348.92 919.36,287.7 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M942.68,280.44l-1.28,4.58l-7.68,5.63l-5.47,1l-11.95-3.18l2.68-3.92l4.6-4.2l3.77-3.8l3.78-3.48l5.04-1.5
l3.45,2.37L942.68,280.44z M932.38,289.5l6.72-4.92l1.12-4.06l-2.91-5.96l-1.93-1.28l-2.6,0.77l-3.32,3.08l-3.78,3.81l-4.59,4.18
l-1.73,2.58l9.3,2.48L932.38,289.5"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sekiColor ? sekiColor : null }}
                points="935.38,273.29 937.31,274.56 940.22,280.52 939.1,284.58 932.38,289.5 928.66,290.19 919.36,287.7 
921.09,285.12 925.68,280.94 929.46,277.13 932.78,274.05 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="941.4,285.02 947.05,346.24 939.38,351.87 933.73,290.65 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="933.73,290.65 939.38,351.87 933.91,352.87 928.26,291.66 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="928.26,291.66 933.91,352.87 921.96,349.69 916.31,288.48 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'gedebey' ? 'active' : null}`}
            onClick={() => openPopup('gedebey')}
          >
            <g>
              <polygon
                className="st99"
                points="406.67,336.39 412.32,397.61 400.87,399.53 395.22,338.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="485.94,330.33 491.59,391.55 489.23,388.46 483.58,327.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="407.16,338.12 412.81,399.33 412.32,397.61 406.67,336.39 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="395.22,338.31 400.87,399.53 387.8,403.64 382.16,342.42 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="483.58,327.24 489.23,388.46 482.56,395.95 476.91,334.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="489.34,334.26 494.99,395.48 491.59,391.55 485.94,330.33 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="409.64,338.06 415.28,399.27 413.56,406.02 407.91,344.8 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="407.16,338.12 412.81,399.33 410.97,406.62 405.32,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="382.16,342.42 387.8,403.64 377.76,409.9 372.11,348.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="491.12,337.98 496.77,399.19 494.99,395.48 489.34,334.26 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="412.76,349.54 418.4,410.75 410.97,406.62 405.32,345.41 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="493.57,337.76 499.22,398.97 499.02,403.36 493.37,342.14 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="491.12,337.98 496.77,399.19 496.55,403.59 490.91,342.37 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="476.91,334.73 482.56,395.95 479.3,405.25 473.65,344.04 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="423.19,350.62 428.84,411.83 418.4,410.75 412.76,349.54 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="372.11,348.69 377.76,409.9 373.62,418.57 367.97,357.36 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="406.81,354.15 412.46,415.36 407.95,415.68 402.3,354.47 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="410.94,354.47 416.59,415.69 412.46,415.36 406.81,354.15 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="402.3,354.47 407.95,415.68 403.05,416.99 397.4,355.77 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="406.38,355.53 412.03,416.74 408.23,417.03 402.58,355.82 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="430.43,353.33 436.08,414.55 428.84,411.83 423.19,350.62 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="415.42,355.28 421.06,416.5 416.59,415.69 410.94,354.47 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="402.58,355.82 408.23,417.03 404.53,418.05 398.88,356.83 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="410.15,355.8 415.8,417.02 412.03,416.74 406.38,355.53 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="397.4,355.77 403.05,416.99 400.19,419.1 394.54,357.88 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="413.84,356.45 419.49,417.66 415.8,417.02 410.15,355.8 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="398.88,356.83 404.53,418.05 402.21,419.76 396.56,358.54 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="369.59,362.36 375.23,423.57 373.62,418.57 367.97,357.36 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="394.54,357.88 400.19,419.1 398.33,421.52 392.68,360.31 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="473.65,344.04 479.3,405.25 475.13,414.53 469.49,353.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="367.19,362.86 372.84,424.08 371.18,418.52 365.53,357.31 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="396.56,358.54 402.21,419.76 400.72,421.73 395.07,360.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="495.73,351.76 501.38,412.98 496.55,403.59 490.91,342.37 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="422.34,358.67 427.99,419.88 421.06,416.5 415.42,355.28 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="419.11,359.01 424.76,420.23 419.49,417.66 413.84,356.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="433.75,357.96 439.4,419.17 436.08,414.55 430.43,353.33 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="392.68,360.31 398.33,421.52 397.8,423.88 392.15,362.66 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="395.07,360.52 400.72,421.73 400.26,423.79 394.61,362.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="373.43,365.78 379.08,427 375.23,423.57 369.59,362.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="371.37,366.66 377.02,427.87 372.84,424.08 367.19,362.86 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="393,365.34 398.64,426.55 397.8,423.88 392.15,362.66 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="422.34,358.67 427.99,419.88 420.75,425.76 415.1,364.54 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="433.75,357.96 439.4,419.17 435.83,425.03 430.18,363.82 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="436.35,357.78 441.99,418.99 438.61,424.55 432.97,363.34 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="377.95,368.91 383.6,430.12 379.08,427 373.43,365.78 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="375.74,369.67 381.39,430.88 377.02,427.87 371.37,366.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="505.14,358.62 510.79,419.83 501.38,412.98 495.73,351.76 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="415.1,364.54 420.75,425.76 408.51,429.34 402.87,368.13 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="402.87,368.13 408.51,429.34 398.64,426.55 393,365.34 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="469.49,353.32 475.13,414.53 469,424.38 463.35,363.16 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="435.91,367.08 441.55,428.29 435.83,425.03 430.18,363.82 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="381.28,373.09 386.93,434.3 383.6,430.12 377.95,368.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="378.83,373.42 384.48,434.64 381.39,430.88 375.74,369.67 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="381.28,373.09 386.93,434.3 387.02,436.63 381.38,375.42 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="441.14,370.79 446.79,432 441.55,428.29 435.91,367.08 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="463.35,363.16 469,424.38 463.6,430.92 457.95,369.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="512.82,365.28 518.47,426.5 510.79,419.83 505.14,358.62 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="457.95,369.71 463.6,430.92 453.76,434.93 448.11,373.72 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="448.11,373.72 453.76,434.93 446.79,432 441.14,370.79 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="381.38,375.42 387.02,436.63 385.47,441.28 379.82,380.06 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="380.02,382.22 385.67,443.44 385.47,441.28 379.82,380.06 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="381.64,383.93 387.28,445.15 385.67,443.44 380.02,382.22 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="515.99,372.07 521.64,433.28 518.47,426.5 512.82,365.28 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="386.5,386.69 392.15,447.91 387.28,445.15 381.64,383.93 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="515.99,372.07 521.64,433.28 518.48,437.72 512.83,376.51 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="387.79,389.52 393.43,450.74 392.15,447.91 386.5,386.69 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="512.83,376.51 518.48,437.72 516.94,442.72 511.29,381.5 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="511.29,381.5 516.94,442.72 513.73,445.14 508.08,383.93 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="513.61,381.95 519.26,443.16 516.34,445.36 510.69,384.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="508.76,386.19 514.41,447.41 513.73,445.14 508.08,383.93 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="508.76,386.19 514.41,447.41 491.59,452.62 485.95,391.4 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="391.6,401.34 397.24,462.55 393.43,450.74 387.79,389.52 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="485.95,391.4 491.59,452.62 479.91,466.16 474.26,404.95 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="403.78,411.54 409.43,472.75 397.24,462.55 391.6,401.34 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="487.65,392.44 493.3,453.66 482.5,466.18 476.85,404.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="475.94,409.2 481.59,470.42 479.91,466.16 474.26,404.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="477.97,412.94 483.62,474.15 481.59,470.42 475.94,409.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="478.42,417.02 484.07,478.23 483.62,474.15 477.97,412.94 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="478.42,417.02 484.07,478.23 479.29,481.95 473.64,420.73 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="473.64,420.73 479.29,481.95 476.51,487.23 470.86,426.01 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="470.86,426.01 476.51,487.23 472.61,491.21 466.96,430 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="466.96,430 472.61,491.21 468.68,493.37 463.03,432.15 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="463.03,432.15 468.68,493.37 466.99,496.63 461.34,435.41 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="458.17,438.08 463.82,499.29 409.43,472.75 403.78,411.54 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="461.34,435.41 466.99,496.63 463.82,499.29 458.17,438.08 			"
              />
            </g>
            <g>
              <path
                className="st59"
                style={{ fill: gedebeyColor ? gedebeyColor : null }}
                d="M512.83,376.51l-1.54,4.99l-3.21,2.42l0.68,2.27l-22.82,5.21l-11.68,13.54l1.68,4.25l2.03,3.74l0.45,4.08
l-4.77,3.71l-2.78,5.28l-3.9,3.98l-3.93,2.16l-1.69,3.26l-3.17,2.67l-54.39-26.54l-12.19-10.2l-3.81-11.81l-1.28-2.83l-4.87-2.76
l-1.61-1.71l-0.2-2.16l1.55-4.65l-0.1-2.33l-3.33-4.18l-4.52-3.12l-3.84-3.42l-1.61-5l4.14-8.67l10.04-6.26l13.06-4.11
l11.45-1.92l0.49,1.73l-1.84,7.29l7.44,4.13l10.43,1.08l7.24,2.71l3.32,4.63l-3.57,5.86l5.72,3.26l5.24,3.71l6.96,2.93l9.84-4.01
l5.4-6.54l6.14-9.85l4.16-9.28l3.26-9.31l6.67-7.49l2.36,3.09l3.4,3.93l1.78,3.71l-0.21,4.39l4.82,9.39l9.41,6.86l7.68,6.67
l3.17,6.78L512.83,376.51z M415.1,364.54l7.24-5.87l-6.92-3.39l-4.48-0.81l-4.13-0.32l-4.5,0.32l-4.9,1.31l-2.86,2.11l-1.86,2.43
l-0.53,2.35l0.84,2.68l9.87,2.79L415.1,364.54"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M478.33,408.71l2.08,3.92l0.51,4.66l-5.2,4.05l-2.66,5.16l-4.34,4.34l-3.54,1.95l-1.73,3.19l-5.35,4.52
l-0.96-1.02l-55.36-27.02l-12.55-10.61l-3.84-11.89l-1.13-2.46l-4.75-2.74l-1.93-2.16l-0.2-2.54l1.54-4.66l-0.09-1.97l-3.09-3.75
l-4.37-3.01l-4.18-3.8l-1.66-5.55l4.39-9.2l11.01-6.82l13.7-4.27l14.05-2.35l0.95,3.39l-1.72,6.75l6.21,3.45l10.42,1.12
l7.96,2.98l3.85,5.43l-3.38,5.56l4.86,2.77l5.22,3.7l5.3,2.21l7.7-3.14l5.04-6.1l6.05-9.71l4.11-9.17l3.32-9.47l9.28-10.44
l4.34,5.66l3.45,4.01l1.93,4.1l-0.21,4.38l4.68,8.99l9.16,6.6l7.94,6.97l3.43,7.3l-3.37,4.73l-1.59,5.21l-2.92,2.19l0.86,2.84
l-23.89,5.46l-10.8,12.52L478.33,408.71z M473.64,420.73l4.77-3.71l-0.45-4.08l-2.03-3.74l-1.68-4.25l11.68-13.54l22.82-5.21
l-0.68-2.27l3.21-2.42l1.54-4.99l3.16-4.44l-3.17-6.78l-7.68-6.67l-9.41-6.86l-4.82-9.39l0.21-4.39l-1.78-3.71l-3.4-3.93
l-2.36-3.09l-6.67,7.49l-3.26,9.31l-4.16,9.28l-6.14,9.85l-5.4,6.54l-9.84,4.01l-6.96-2.93l-5.24-3.71l-5.72-3.26l3.57-5.86
l-3.32-4.63l-7.24-2.71l-10.43-1.08l-7.44-4.13l1.84-7.29l-0.49-1.73l-11.45,1.92l-13.06,4.11l-10.04,6.26l-4.14,8.67l1.61,5
l3.84,3.42l4.52,3.12l3.33,4.18l0.1,2.33l-1.55,4.65l0.2,2.16l1.61,1.71l4.87,2.76l1.28,2.83l3.81,11.81l12.19,10.2l54.39,26.54
l3.17-2.67l1.69-3.26l3.93-2.16l3.9-3.98L473.64,420.73"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M415.42,355.28l-4.48-0.81l-4.13-0.32l-4.5,0.32l-4.9,1.31l-2.86,2.11l-1.86,2.43l-0.53,2.35l0.84,2.68
l9.87,2.79l12.24-3.58l7.24-5.87L415.42,355.28z M413.54,363.52l-10.45,3.05l-7.87-2.22l-0.6-1.79l0.46-2.05l1.49-1.97l2.32-1.71
l3.7-1.02l3.8-0.29l3.77,0.28l3.69,0.64l5.27,2.57L413.54,363.52"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="379.52,384.76 385.16,445.97 383.23,443.81 377.58,382.6 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="384.26,387.49 389.91,448.71 385.16,445.97 379.52,384.76 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="518.57,372.01 524.22,433.23 520.85,437.96 515.2,376.74 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="385.39,389.95 391.03,451.17 389.91,448.71 384.26,387.49 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="515.2,376.74 520.85,437.96 519.26,443.16 513.61,381.95 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="511.54,386.98 517.19,448.2 493.3,453.66 487.65,392.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="389.22,401.84 394.87,463.06 391.03,451.17 385.39,389.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="401.77,412.46 407.42,473.67 394.87,463.06 389.22,401.84 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="480.91,417.29 486.56,478.5 481.36,482.55 475.71,421.34 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="475.71,421.34 481.36,482.55 478.7,487.71 473.05,426.5 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="473.05,426.5 478.7,487.71 474.36,492.05 468.71,430.84 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="468.71,430.84 474.36,492.05 470.82,494 465.18,432.79 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="465.18,432.79 470.82,494 469.09,497.19 463.45,435.98 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="457.13,439.48 462.78,500.7 407.42,473.67 401.77,412.46 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="463.45,435.98 469.09,497.19 463.74,501.71 458.09,440.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="458.09,440.5 463.74,501.71 462.78,500.7 457.13,439.48 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'daskesen' ? 'active' : null}`}
            onClick={() => openPopup('daskesen')}
          >
            <g>
              <polygon
                className="st4"
                points="554.3,367.18 559.95,428.39 547.42,432.41 541.77,371.2 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="565.15,372.18 570.8,433.4 559.95,428.39 554.3,367.18 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="541.77,371.2 547.42,432.41 531.98,440.62 526.33,379.4 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="573.65,376.93 579.3,438.15 570.8,433.4 565.15,372.18 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="526.33,379.4 531.98,440.62 516.14,448.44 510.5,387.22 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="581.63,382.21 587.28,443.43 579.3,438.15 573.65,376.93 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="510.5,387.22 516.14,448.44 493.3,453.66 487.65,392.44 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="588.04,385.51 593.69,446.73 587.28,443.43 581.63,382.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="592.43,388.73 598.08,449.94 593.69,446.73 588.04,385.51 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="487.65,392.44 493.3,453.66 482.5,466.18 476.85,404.97 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="592.43,388.73 598.08,449.94 585.84,456.81 580.2,395.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="595.69,388.73 601.34,449.95 587.47,457.73 581.83,396.51 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="478.33,408.71 483.97,469.92 482.5,466.18 476.85,404.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="475.94,409.2 481.59,470.42 479.91,466.16 474.26,404.95 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="580.2,395.59 585.84,456.81 571.01,464.95 565.36,403.73 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="581.83,396.51 587.47,457.73 573.84,465.21 568.19,403.99 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="480.4,412.63 486.05,473.84 483.97,469.92 478.33,408.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="477.97,412.94 483.62,474.15 481.59,470.42 475.94,409.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="478.42,417.02 484.07,478.23 483.62,474.15 477.97,412.94 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="480.91,417.29 486.56,478.5 486.05,473.84 480.4,412.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="569.79,411.23 575.44,472.44 571.01,464.95 565.36,403.73 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="480.91,417.29 486.56,478.5 481.36,482.55 475.71,421.34 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="475.71,421.34 481.36,482.55 478.7,487.71 473.05,426.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="577.27,416.97 582.92,478.18 575.44,472.44 569.79,411.23 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="473.05,426.5 478.7,487.71 474.36,492.05 468.71,430.84 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="584.34,421.54 589.99,482.76 582.92,478.18 577.27,416.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="468.71,430.84 474.36,492.05 470.82,494 465.17,432.79 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="465.17,432.79 470.82,494 469.09,497.19 463.45,435.98 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="584.34,421.54 589.99,482.76 587.15,488.48 581.51,427.26 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="463.45,435.98 469.09,497.19 465.36,500.35 459.71,439.13 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="587.03,421.06 592.68,482.28 589.27,489.21 583.62,428 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="498.02,437.98 503.66,499.2 485.19,501.47 479.54,440.26 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="497.59,439.41 503.23,500.63 486.81,502.65 481.16,441.43 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="581.51,427.26 587.15,488.48 570.78,495.36 565.13,434.15 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="583.62,428 589.27,489.21 572.32,496.34 566.67,435.12 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="515.1,441.43 520.75,502.64 503.66,499.2 498.02,437.98 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="514.17,442.75 519.81,503.96 503.23,500.63 497.59,439.41 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="467.51,447.15 473.16,508.37 465.36,500.35 459.71,439.13 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="472.37,447.73 478.02,508.94 473.16,508.37 467.51,447.15 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="473.45,447.66 479.09,508.87 478.02,508.94 472.37,447.73 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="479.54,440.26 485.19,501.47 479.09,508.87 473.45,447.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="465.78,448.38 471.43,509.59 462.49,500.42 456.84,439.2 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="481.16,441.43 486.81,502.65 480.72,510.03 475.07,448.82 			"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="472,449.12 477.65,510.34 471.43,509.59 465.78,448.38 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="474.25,448.96 479.9,510.18 477.65,510.34 472,449.12 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="475.07,448.82 480.72,510.03 479.9,510.18 474.25,448.96 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="534,443.99 539.65,505.2 520.75,502.64 515.1,441.43 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="533.39,445.34 539.04,506.55 519.81,503.96 514.17,442.75 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="553.17,444.54 558.81,505.76 539.65,505.2 534,443.99 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="565.13,434.15 570.78,495.36 558.81,505.76 553.17,444.54 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M579.24,416.04l7.8,5.02l-3.41,6.93l-16.95,7.13l-12.35,10.83l-20.93-0.62l-19.22-2.59l-16.58-3.34
l-16.43,2.02l-6.09,7.39l-0.82,0.14l-2.25,0.16l-6.22-0.75l-8.94-9.18l4.5-3.79l1.69-3.26l3.93-2.16l3.9-3.98l2.78-5.28
l4.77-3.71l-0.45-4.08l-2.03-3.74l-1.68-4.25l11.68-13.54l23.64-5.39l15.21-7.57l15.81-8.37l14.2-4.54l12.12,5.59l8.6,4.82
l7.97,5.26l6.46,3.33l5.74,4.2l-13.86,7.78l-13.63,7.48l3.89,6.58L579.24,416.04z M581.51,427.26l2.84-5.72l-7.07-4.57
l-7.48-5.74l-4.43-7.49l14.83-8.14l12.23-6.86l-4.39-3.22l-6.41-3.3l-7.98-5.28l-8.5-4.75l-10.84-5l-12.53,4.02l-15.44,8.2
l-15.84,7.82l-22.84,5.22l-10.8,12.52l1.47,3.74l2.08,3.92l0.51,4.66l-5.2,4.05l-2.66,5.16l-4.34,4.34l-3.54,1.95l-1.73,3.19
l-3.73,3.16l7.8,8.02l4.85,0.58l1.08-0.07l6.09-7.4l18.48-2.27l17.08,3.45l18.9,2.56l19.16,0.55l11.97-10.39L581.51,427.26"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: daskesenColor ? daskesenColor : null }}
                points="554.31,367.18 565.15,372.18 573.65,376.93 581.63,382.21 588.04,385.51 592.43,388.73 
580.2,395.59 565.36,403.73 569.79,411.23 577.27,416.97 584.34,421.54 581.51,427.26 565.13,434.15 553.17,444.54 534,443.99 
515.1,441.43 498.02,437.98 479.54,440.26 473.45,447.66 472.37,447.73 467.51,447.15 459.71,439.13 463.45,435.98 
465.18,432.79 468.71,430.84 473.05,426.5 475.71,421.34 480.91,417.29 480.4,412.63 478.33,408.71 476.85,404.97 487.65,392.44 
510.5,387.22 526.33,379.4 541.77,371.2 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="566.67,435.12 572.32,496.34 559.97,507.17 554.32,445.96 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="554.32,445.96 559.97,507.17 539.04,506.55 533.39,445.34 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'goygol' ? 'active' : null}`}
            onClick={() => openPopup('goygol')}
          >
            <g>
              <polygon
                className="st43"
                points="639.47,338.63 645.12,399.85 640.22,402.8 634.57,341.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="634.57,341.59 640.22,402.8 634.25,406.73 628.6,345.52 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="628.6,345.52 634.25,406.73 631.33,410.56 625.69,349.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="642.51,348.59 648.16,409.81 645.12,399.85 639.47,338.63 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="625.69,349.34 631.33,410.56 628.49,414.8 622.84,353.58 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="622.84,353.58 628.49,414.8 621.21,420.48 615.56,359.26 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="615.56,359.26 621.21,420.48 618.65,424.05 613.01,362.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="645.96,361.59 651.61,422.8 648.16,409.81 642.51,348.59 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="645.96,361.59 651.61,422.8 649.11,424.75 643.46,363.54 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="613.01,362.84 618.65,424.05 615.13,427.89 609.48,366.68 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="590.22,368.54 595.87,429.75 584.17,430.27 578.52,369.06 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="648.52,361.8 654.17,423.01 650.93,425.56 645.28,364.35 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="578.52,369.06 584.17,430.27 573.83,433.04 568.18,371.82 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="599.59,369.47 605.24,430.69 595.87,429.75 590.22,368.54 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="609.48,366.68 615.13,427.89 605.24,430.69 599.59,369.47 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="643.46,363.54 649.11,424.75 645.15,427.07 639.5,365.86 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="645.28,364.35 650.93,425.56 646.78,428.01 641.13,366.79 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="639.5,365.86 645.15,427.07 640.85,429.32 635.2,368.11 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="641.13,366.79 646.78,428.01 642.54,430.21 636.89,369 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="575.52,375.94 581.17,437.15 573.83,433.04 568.18,371.82 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="573.65,376.93 579.3,438.15 569.59,432.72 563.94,371.5 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="635.2,368.11 640.85,429.32 633.08,435.94 627.44,374.73 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="636.89,369 642.54,430.21 635.5,436.24 629.85,375.03 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="583.49,381.2 589.14,442.41 581.17,437.15 575.52,375.94 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="581.63,382.21 587.28,443.43 579.3,438.15 573.65,376.93 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="678.29,374.53 683.94,435.75 669.21,433.72 663.56,372.5 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="589.95,384.53 595.6,445.75 589.14,442.41 583.49,381.2 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="629.85,375.03 635.5,436.24 635.03,442.35 629.38,381.13 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="663.56,372.5 669.21,433.72 657.82,440.44 652.17,379.22 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="588.04,385.51 593.69,446.73 587.28,443.43 581.63,382.21 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="627.44,374.73 633.08,435.94 632.51,443.4 626.86,382.19 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="638.56,384.17 644.21,445.38 632.51,443.4 626.86,382.19 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="652.17,379.22 657.82,440.44 644.21,445.38 638.56,384.17 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="595.69,388.73 601.34,449.95 595.6,445.75 589.95,384.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="592.43,388.73 598.08,449.94 593.69,446.73 588.04,385.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="681,384.83 686.65,446.05 683.94,435.75 678.29,374.53 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="595.69,388.73 601.34,449.95 587.47,457.73 581.83,396.51 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="581.83,396.51 587.47,457.73 573.84,465.21 568.19,403.99 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="681,384.83 686.65,446.05 680.08,458.67 674.43,397.46 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="683.51,384.77 689.16,445.99 682.7,458.39 677.05,397.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="677.25,400.58 682.89,461.79 680.08,458.67 674.43,397.46 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="572.08,410.57 577.73,471.79 573.84,465.21 568.19,403.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="569.79,411.23 575.44,472.44 571.01,464.95 565.36,403.73 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="677.25,400.58 682.89,461.79 679.9,464.88 674.25,403.67 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="680.01,400.45 685.66,461.66 681.52,465.82 675.88,404.6 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="674.25,403.67 679.9,464.88 671.96,468.5 666.31,407.28 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="579.24,416.04 584.89,477.26 577.73,471.79 572.08,410.57 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="675.88,404.6 681.52,465.82 673.33,469.54 667.68,408.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="577.27,416.97 582.92,478.18 575.44,472.44 569.79,411.23 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="666.31,407.28 671.96,468.5 663.72,472.03 658.07,410.81 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="667.68,408.32 673.33,469.54 664.96,473.12 659.31,411.9 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="658.07,410.81 663.72,472.03 650.76,476.66 645.12,415.44 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="587.03,421.06 592.68,482.28 584.89,477.26 579.24,416.04 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="584.34,421.54 589.99,482.76 582.92,478.18 577.27,416.97 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="659.31,411.9 664.96,473.12 652.03,477.74 646.38,416.52 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="645.12,415.44 650.76,476.66 638.51,482.21 632.86,420.99 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="646.38,416.52 652.03,477.74 640.03,483.17 634.38,421.95 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="587.03,421.06 592.68,482.28 589.27,489.21 583.62,428 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="632.86,420.99 638.51,482.21 630.79,486.76 625.14,425.54 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="634.38,421.95 640.03,483.17 632.33,487.71 626.69,426.5 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="625.14,425.54 630.79,486.76 622.25,490.69 616.6,429.48 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="583.62,428 589.27,489.21 572.32,496.34 566.67,435.12 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="626.69,426.5 632.33,487.71 623.84,491.65 618.19,430.43 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="566.67,435.12 572.32,496.34 561.08,506.18 555.43,444.97 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="616.6,429.48 622.25,490.69 607.9,502.12 602.26,440.9 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="618.19,430.43 623.84,491.65 610,502.71 604.35,441.49 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="574.12,451.12 579.77,512.34 561.08,506.18 555.43,444.97 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="572.83,452.36 578.47,513.57 557.68,506.74 552.03,445.53 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="602.26,440.9 607.9,502.12 600.92,516.15 595.27,454.93 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="595.27,454.93 600.92,516.15 579.77,512.34 574.12,451.12 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: goygolColor ? goygolColor : null }}
                points="639.47,338.63 642.51,348.59 645.96,361.59 643.46,363.54 639.5,365.86 635.2,368.11 
627.44,374.73 626.86,382.19 638.56,384.17 652.17,379.22 663.56,372.5 678.29,374.53 681,384.83 674.43,397.46 677.25,400.58 
674.25,403.67 666.31,407.28 658.07,410.81 645.12,415.44 632.86,420.99 625.14,425.54 616.6,429.48 602.26,440.9 595.27,454.93 
574.12,451.12 555.43,444.97 566.67,435.12 583.62,428 587.03,421.06 579.24,416.04 572.08,410.57 568.19,403.99 581.83,396.51 
595.69,388.73 589.95,384.53 583.49,381.2 575.52,375.94 568.18,371.82 578.53,369.06 590.22,368.54 599.6,369.47 609.48,366.68 
613.01,362.84 615.56,359.26 622.84,353.58 625.69,349.34 628.6,345.52 634.57,341.59 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="596.88,456.71 602.52,517.92 578.47,513.57 572.83,452.36 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="604.35,441.49 610,502.71 602.52,517.92 596.88,456.71 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M677.05,397.18l2.96,3.27l-4.14,4.15l-8.2,3.72l-8.37,3.58l-12.93,4.62l-12,5.43l-7.7,4.54l-8.5,3.94
l-13.84,11.06l-7.48,15.21l-24.05-4.35l-20.79-6.83l13.1-11.38l16.37-6.88l2.84-5.72l-7.07-4.57l-7.48-5.74l-4.43-7.49
l14.83-8.14l12.23-6.86l-4.39-3.22l-6.41-3.3l-7.98-5.28l-9.71-5.43l14.43-3.81l12.24-0.54l8.86,0.88l8.27-2.34l3.05-3.3
l2.74-3.78l7.27-5.65l2.62-3.99l3.26-4.18l6.13-4.04l8.32-5.01l3.82,12.5l3.58,13.57l-3.25,2.55l-4.15,2.44l-4.24,2.21
l-7.04,6.03l-0.47,6.11l9.04,1.53l12.44-4.52l12.11-7.17l17.55,2.42l2.99,11.38L677.05,397.18z M674.25,403.67l2.99-3.09
l-2.81-3.12l6.56-12.63l-2.7-10.3l-14.74-2.03l-11.39,6.72l-13.61,4.94l-11.7-1.98l0.57-7.46l7.77-6.62l4.3-2.25l3.96-2.32
l2.5-1.95l-3.45-12.99l-3.04-9.96l-4.9,2.95l-5.96,3.93l-2.92,3.83l-2.85,4.24l-7.28,5.68l-2.56,3.58l-3.52,3.84l-9.89,2.8
l-9.37-0.93l-11.7,0.52l-10.34,2.77l7.34,4.11l7.97,5.26l6.46,3.33l5.74,4.2l-13.86,7.78l-13.63,7.48l3.89,6.58l7.16,5.47
l7.8,5.02l-3.41,6.93l-16.95,7.13l-11.24,9.85l18.69,6.15l21.15,3.81l6.98-14.03l14.34-11.42l8.54-3.93l7.72-4.55l12.25-5.55
l12.95-4.63l8.24-3.53L674.25,403.67"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'gence' ? 'active' : null}`}
            onClick={() => openPopup('gence')}
          >
            <g>
              <polygon
                className="st31"
                points="647.71,362.46 653.35,423.67 650.93,425.56 645.28,364.35 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="660.27,363.72 665.92,424.94 653.35,423.67 647.71,362.46 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="645.28,364.35 650.93,425.56 646.78,428.01 641.13,366.79 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="641.13,366.79 646.78,428.01 642.54,430.21 636.89,369 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="661.96,371.56 667.61,432.77 665.92,424.94 660.27,363.72 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="636.89,369 642.54,430.21 635.5,436.24 629.85,375.03 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="661.96,371.56 667.61,432.77 656.51,439.35 650.86,378.14 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="629.85,375.03 635.5,436.24 635.03,442.35 629.38,381.13 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="664.55,371.91 670.2,433.13 657.82,440.44 652.17,379.22 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="650.86,378.14 656.51,439.35 644.07,443.88 638.42,382.66 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="638.42,382.66 644.07,443.88 635.03,442.35 629.38,381.13 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M662.53,362.51l2.02,9.4l-12.38,7.31l-13.61,4.94l-11.7-1.98l0.57-7.46l7.77-6.62l4.3-2.25l3.96-2.32
l3.32-2.61L662.53,362.51z M650.86,378.14l11.1-6.58l-1.69-7.84l-12.57-1.27l-2.43,1.89l-4.15,2.44l-4.24,2.21l-7.04,6.03
l-0.47,6.11l9.04,1.53L650.86,378.14"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: genceColor ? genceColor : null }}
                points="647.71,362.46 660.27,363.72 661.96,371.56 650.86,378.14 638.42,382.66 629.38,381.13 
629.85,375.03 636.89,369 641.13,366.79 645.28,364.35 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="638.56,384.17 644.21,445.38 632.51,443.4 626.86,382.19 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="652.17,379.22 657.82,440.44 644.21,445.38 638.56,384.17 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'goranboy' ? 'active' : null}`}
            onClick={() => openPopup('goranboy')}
          >
            <g>
              <polygon
                className="st31"
                points="786.04,322.83 791.69,384.04 778.94,389.51 773.29,328.29 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="786.04,322.83 791.69,384.04 786.88,390.09 781.23,328.87 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="791.93,318.69 797.58,379.9 789.24,390.37 783.59,329.16 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="773.29,328.29 778.94,389.51 762.92,396.69 757.27,335.48 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="757.27,335.48 762.92,396.69 744.83,403.64 739.18,342.43 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="739.18,342.43 744.83,403.64 726.39,410.26 720.74,349.04 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="720.74,349.04 726.39,410.26 720.34,411.54 714.7,350.32 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="781.23,328.87 786.88,390.09 780.52,407.59 774.87,346.38 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="783.59,329.16 789.24,390.37 782.85,407.87 777.21,346.66 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="714.7,350.32 720.34,411.54 717,413.96 711.35,352.75 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="711.35,352.75 717,413.96 716.29,418.61 710.65,357.39 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="710.65,357.39 716.29,418.61 709.35,421.37 703.7,360.15 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="774.87,346.38 780.52,407.59 774.28,415.77 768.64,354.56 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="777.21,346.66 782.85,407.87 776.43,416.3 770.78,355.09 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="703.7,360.15 709.35,421.37 695.52,426.32 689.87,365.1 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="768.64,354.56 774.28,415.77 765.16,423.85 759.51,362.64 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="770.78,355.09 776.43,416.3 767.84,423.91 762.19,362.69 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="689.87,365.1 695.52,426.32 686.34,435.26 680.69,374.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="763.44,369.97 769.09,431.18 765.16,423.85 759.51,362.64 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="774.53,371.97 780.17,433.19 769.09,431.18 763.44,369.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="681,384.85 686.65,446.06 683.79,435.12 678.14,373.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="683.52,384.79 689.17,446 686.34,435.26 680.69,374.04 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="781.21,375.95 786.86,437.17 780.17,433.19 774.53,371.97 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="781.21,375.95 786.86,437.17 777.65,442.52 772,381.31 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="784.59,375.85 790.24,437.07 779.25,443.45 773.61,382.24 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="772,381.31 777.65,442.52 765.2,448.92 759.55,387.7 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="773.61,382.24 779.25,443.45 768.02,449.23 762.37,388.01 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="683.52,384.79 689.17,446 682.71,458.41 677.06,397.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="677.25,400.59 682.9,461.81 680.09,458.69 674.44,397.48 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="680.02,400.47 685.67,461.68 682.71,458.41 677.06,397.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="762.98,393.87 768.63,455.09 765.2,448.92 759.55,387.7 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="776.88,390.71 782.53,451.93 768.63,455.09 762.98,393.87 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="785.4,391.94 791.05,453.16 782.53,451.93 776.88,390.71 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="680.02,400.47 685.67,461.68 681.53,465.83 675.88,404.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="789.98,396.72 795.63,457.93 791.05,453.16 785.4,391.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="675.88,404.62 681.53,465.83 673.34,469.56 667.69,408.34 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="667.69,408.34 673.34,469.56 664.96,473.13 659.32,411.92 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="659.32,411.92 664.96,473.13 652.04,477.76 646.39,416.54 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="789.98,396.72 795.63,457.93 791.03,466.74 785.38,405.52 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="792.61,396.41 798.26,457.63 793.4,466.9 787.75,405.68 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="646.39,416.54 652.04,477.76 640.04,483.19 634.39,421.97 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="634.39,421.97 640.04,483.19 632.34,487.73 626.69,426.51 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="785.38,405.52 791.03,466.74 788.15,476.54 782.5,415.32 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="787.75,405.68 793.4,466.9 790.83,475.7 785.19,414.49 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="763.67,416 769.32,477.21 766.1,478.35 760.45,417.13 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="626.69,426.51 632.34,487.73 623.84,491.67 618.2,430.45 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="766.03,417.72 771.68,478.94 769.32,477.21 763.67,416 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="762.91,417.81 768.56,479.02 767.41,479.42 761.77,418.2 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="760.45,417.13 766.1,478.35 761.91,480.35 756.26,419.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="763.95,418.56 769.6,479.78 768.56,479.02 762.91,417.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="761.77,418.2 767.41,479.42 764.51,480.82 758.86,419.61 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="767.36,419.05 773.01,480.27 771.68,478.94 766.03,417.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="764.79,419.41 770.44,480.63 769.6,479.78 763.95,418.56 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="790.77,417.76 796.42,478.97 788.15,476.54 782.5,415.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="756.64,420.93 762.29,482.15 761.91,480.35 756.26,419.14 			"
              />
            </g>
            <g>
              <polygon
                className="st141"
                points="767.36,419.05 773.01,480.27 772.58,481.5 766.93,420.29 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="799.24,417.82 804.89,479.04 796.42,478.97 790.77,417.76 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="766.93,420.29 772.58,481.5 771.42,482.92 765.77,421.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="758.43,422.59 764.08,483.81 762.29,482.15 756.64,420.93 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="761.85,422.82 767.5,484.03 764.08,483.81 758.43,422.59 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="765.77,421.7 771.42,482.92 767.5,484.03 761.85,422.82 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M766.03,417.72l1.33,1.33l-0.43,1.24l-1.16,1.42l-3.92,1.11l-3.42-0.22l-1.79-1.66l-0.37-1.79l4.19-2.01
l3.22-1.13L766.03,417.72z M763.98,420.74l0.59-0.74l0.21-0.59l-0.84-0.85l-1.04-0.75l-1.14,0.4l-2.91,1.4l0.19,0.87l0.95,0.81
l1.66,0.11L763.98,420.74"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="800.24,419.31 805.89,480.53 804.89,479.04 799.24,417.82 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="800.24,419.31 805.89,480.53 803.51,483.28 797.86,422.06 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="802.92,419.24 808.57,480.46 805.58,483.87 799.93,422.65 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="618.2,430.45 623.84,491.67 610.01,502.73 604.36,441.51 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="797.86,422.06 803.51,483.28 798.65,487.25 793,426.04 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="793,426.04 798.65,487.25 791.89,488.31 786.25,427.09 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="799.93,422.65 805.58,483.87 800.03,488.43 794.38,427.22 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="794.38,427.22 800.03,488.43 792.48,489.6 786.84,428.38 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="786.25,427.09 791.89,488.31 775.19,494.44 769.54,433.22 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="769.54,433.22 775.19,494.44 768.73,496 763.08,434.79 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="786.84,428.38 792.48,489.6 776.05,495.67 770.4,434.46 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="722.28,438.17 727.93,499.38 711.9,501.78 706.25,440.57 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="763.08,434.79 768.73,496 762.2,497.83 756.55,436.61 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="770.4,434.46 776.05,495.67 769.54,497.23 763.89,436.02 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="732.94,439.33 738.59,500.54 727.93,499.38 722.28,438.17 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="722.11,439.57 727.75,500.78 712.44,503.08 706.79,441.86 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="763.89,436.02 769.54,497.23 763.12,499.04 757.47,437.83 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="756.55,436.61 762.2,497.83 755.45,499.81 749.8,438.6 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="706.25,440.57 711.9,501.78 696.34,505.39 690.69,444.18 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="732.3,440.69 737.95,501.9 727.75,500.78 722.11,439.57 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="690.69,444.18 696.34,505.39 678.81,507.53 673.17,446.32 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="743.11,440.03 748.76,501.24 738.59,500.54 732.94,439.33 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="749.8,438.6 755.45,499.81 748.76,501.24 743.11,440.03 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="757.47,437.83 763.12,499.04 756.24,501.06 750.59,439.84 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="706.79,441.86 712.44,503.08 696.81,506.7 691.16,445.48 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="691.16,445.48 696.81,506.7 679.77,508.79 674.12,447.58 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="750.59,439.84 756.24,501.06 748.8,502.65 743.15,441.44 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="743.15,441.44 748.8,502.65 737.95,501.9 732.3,440.69 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="604.36,441.51 610.01,502.73 603.2,516.58 597.55,455.37 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="673.17,446.32 678.81,507.53 665.61,514.02 659.96,452.8 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="674.12,447.58 679.77,508.79 666.54,515.24 660.89,454.02 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="626.15,460.61 631.8,521.83 603.2,516.58 597.55,455.37 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="659.96,452.8 665.61,514.02 631.8,521.83 626.15,460.61 			"
              />
            </g>
            <g>
              <path
                className="st59"
                style={{ fill: goranboyColor }}
                d="M799.24,417.82l1.01,1.49l-2.39,2.75l-4.85,3.98l-6.76,1.05l-16.7,6.13l-6.47,1.56l-6.52,1.83l-6.75,1.99
l-6.69,1.43l-10.17-0.7l-10.66-1.16l-16.03,2.4l-15.56,3.61l-17.53,2.14l-13.21,6.48l-33.81,7.81l-28.6-5.24l6.81-13.86
l13.84-11.06l8.5-3.94l7.7-4.54l12-5.43l12.93-4.62l8.37-3.58l8.2-3.72l4.14-4.15l-2.96-3.27l6.46-12.41l-2.83-10.74l9.17-8.94
l13.84-4.95l6.94-2.76l0.71-4.64l3.34-2.42l6.04-1.28l18.44-6.61l18.09-6.95l16.02-7.18l12.75-5.47l-4.81,6.05l-6.36,17.5
l-6.24,8.18l-9.12,8.08l3.93,7.33l11.08,2l6.69,3.98l-9.21,5.35l-12.46,6.4l3.44,6.17l13.9-3.16l8.52,1.23l4.58,4.78l-4.6,8.81
l-2.88,9.8l8.26,2.43L799.24,417.82z M765.77,421.7l1.16-1.42l0.43-1.24l-1.33-1.33l-2.36-1.72l-3.22,1.13l-4.19,2.01l0.37,1.79
l1.79,1.66l3.42,0.22L765.77,421.7"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="660.89,454.02 666.54,515.24 631.63,523.3 625.98,462.09 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="625.98,462.09 631.63,523.3 600.26,517.54 594.61,456.32 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M801.04,416.46l1.88,2.78l-2.99,3.41l-5.55,4.57l-7.54,1.16l-16.43,6.08l-6.51,1.56l-6.43,1.81l-6.88,2.01
l-7.43,1.59l-10.86-0.75l-10.19-1.12l-15.31,2.3l-15.63,3.62l-17.04,2.09l-13.23,6.44l-34.91,8.07l-31.37-5.77l7.65-15.4
l14.34-11.42l8.54-3.93l7.72-4.55l12.25-5.55l12.95-4.63l8.24-3.53l7.95-3.62l2.99-3.09l-2.81-3.12l6.56-12.63l-2.87-10.94
l9.99-9.74l14.37-5.13l5.82-2.31l0.65-4.38l4.35-3.15l6.66-1.41l18-6.49l17.98-6.9l15.96-7.15l20.01-8.57l-8.34,10.47l-6.38,17.5
l-6.42,8.43l-8.59,7.6l3.29,6.15l10.55,1.91l8.56,5.1l-10.99,6.39l-11.23,5.78l2.26,4.05l12.33-2.8l10.21,1.47l5.44,5.68
l-4.87,9.27l-2.56,8.81l6.43,1.9L801.04,416.46z M797.86,422.06l2.39-2.75l-1.01-1.49l-8.47-0.07l-8.26-2.43l2.88-9.8l4.6-8.81
l-4.58-4.78l-8.52-1.23l-13.9,3.16l-3.44-6.17l12.46-6.4l9.21-5.35l-6.69-3.98l-11.08-2l-3.93-7.33l9.12-8.08l6.24-8.18
l6.36-17.5l4.81-6.05l-12.75,5.47l-16.02,7.18l-18.09,6.95l-18.44,6.61l-6.04,1.28l-3.34,2.42l-0.71,4.64l-6.94,2.76l-13.84,4.95
l-9.17,8.94l2.83,10.74l-6.46,12.41l2.96,3.27l-4.14,4.15l-8.2,3.72l-8.37,3.58l-12.93,4.62l-12,5.43l-7.7,4.54l-8.5,3.94
l-13.84,11.06l-6.81,13.86l28.6,5.24l33.81-7.81l13.21-6.48l17.53-2.14l15.56-3.61l16.03-2.4l10.66,1.16l10.17,0.7l6.69-1.43
l6.75-1.99l6.52-1.83l6.47-1.56l16.7-6.13l6.76-1.05L797.86,422.06"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'mingecevir' ? 'active' : null}`}
            onClick={() => openPopup('mingecevir')}
          >
            <g>
              <polygon
                className="st67"
                points="789.51,321.7 795.16,382.91 789.23,390.35 783.58,329.14 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="810.32,327.7 815.97,388.92 795.16,382.91 789.51,321.7 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="830.39,337.18 836.04,398.39 815.97,388.92 810.32,327.7 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="783.58,329.14 789.23,390.35 782.85,407.86 777.2,346.64 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="852.34,346.82 857.99,408.04 836.04,398.39 830.39,337.18 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="777.2,346.64 782.85,407.86 776.42,416.29 770.77,355.07 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="770.77,355.07 776.42,416.29 767.84,423.89 762.19,362.67 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="873.79,357.16 879.43,418.37 857.99,408.04 852.34,346.82 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="765.47,368.83 771.12,430.04 767.84,423.89 762.19,362.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="763.43,369.95 769.08,431.17 765.15,423.83 759.51,362.62 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="824.56,364.09 830.21,425.3 818.15,427.57 812.51,366.35 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="776.03,370.74 781.68,431.95 771.12,430.04 765.47,368.83 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="824.03,365.59 829.68,426.8 821.67,428.31 816.02,367.09 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="774.52,371.96 780.17,433.17 769.08,431.17 763.43,369.95 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="840.03,366.63 845.68,427.84 839.72,428.52 834.07,367.31 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="834.07,367.31 839.72,428.52 830.21,425.3 824.56,364.09 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="846.16,367.29 851.81,428.51 845.68,427.84 840.03,366.63 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="839.77,368.03 845.42,429.25 839.03,429.98 833.39,368.76 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="833.39,368.76 839.03,429.98 829.68,426.8 824.03,365.59 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="844.33,368.52 849.98,429.74 845.42,429.25 839.77,368.03 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="892.77,364.1 898.42,425.31 879.43,418.37 873.79,357.16 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="784.58,375.83 790.23,437.05 781.68,431.95 776.03,370.74 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="781.21,375.93 786.86,437.15 780.17,433.17 774.52,371.96 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="848.35,370.12 854,431.34 851.81,428.51 846.16,367.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846,370.67 851.65,431.89 849.98,429.74 844.33,368.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846.95,373.14 852.6,434.35 851.65,431.89 846,370.67 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="849.87,374.13 855.52,435.35 854,431.34 848.35,370.12 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="784.58,375.83 790.23,437.05 779.25,443.43 773.6,382.22 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M846.16,367.29l2.19,2.83l1.52,4.01l-27.38,3.59l-9.99-11.37l12.06-2.26l9.51,3.22l5.96-0.68L846.16,367.29
z M823.97,376.14l22.97-3l-0.94-2.47l-1.67-2.15l-4.56-0.49l-6.38,0.73l-9.35-3.18l-8.01,1.51L823.97,376.14"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="822.49,377.72 828.14,438.94 818.15,427.57 812.51,366.35 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="849.87,374.13 855.52,435.35 828.14,438.94 822.49,377.72 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="910.77,370.97 916.42,432.18 898.42,425.31 892.77,364.1 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="773.6,382.22 779.25,443.43 768.02,449.21 762.37,388 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="764.63,392.05 770.28,453.26 768.02,449.21 762.37,388 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="776.96,389.25 782.61,450.46 770.28,453.26 764.63,392.05 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="787.17,390.71 792.81,451.93 782.61,450.46 776.96,389.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="762.97,393.85 768.62,455.07 765.19,448.9 759.54,387.69 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="776.88,390.69 782.52,451.91 768.62,455.07 762.97,393.85 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="785.4,391.92 791.04,453.14 782.52,451.91 776.88,390.69 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="910.77,370.97 916.42,432.18 901.02,448.11 895.37,386.89 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="792.61,396.4 798.26,457.61 792.81,451.93 787.17,390.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="789.98,396.7 795.63,457.92 791.04,453.14 785.4,391.92 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="913.95,370.44 919.6,431.66 903.12,448.67 897.48,387.46 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="895.37,386.89 901.02,448.11 893.96,454.91 888.31,393.7 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="897.48,387.46 903.12,448.67 896.15,455.41 890.5,394.19 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="792.61,396.4 798.26,457.61 793.39,466.88 787.74,405.66 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="867.89,399.07 873.53,460.28 860.04,459.14 854.4,397.92 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="873.99,399.45 879.64,460.66 873.53,460.28 867.89,399.07 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="867.31,400.43 872.96,461.65 860.71,460.6 855.06,399.38 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="854.4,397.92 860.04,459.14 852.03,463.69 846.38,402.48 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="877.59,399.99 883.24,461.21 879.64,460.66 873.99,399.45 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="855.06,399.38 860.71,460.6 854.57,464.11 848.92,402.89 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="873.27,400.79 878.91,462 872.96,461.65 867.31,400.43 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="875.99,401.22 881.64,462.43 878.91,462 873.27,400.79 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="879.23,401.22 884.88,462.43 883.24,461.21 877.59,399.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="876.45,401.56 882.1,462.78 881.64,462.43 875.99,401.22 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="888.31,393.7 893.96,454.91 889.95,462.84 884.3,401.62 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="890.5,394.19 896.15,455.41 892.41,462.84 886.77,401.62 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="879.23,401.22 884.88,462.43 882.61,465.6 876.96,404.39 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="787.74,405.66 793.39,466.88 790.83,475.68 785.18,414.47 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="876.96,404.39 882.61,465.6 880.3,467.53 874.65,406.32 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="874.65,406.32 880.3,467.53 878.08,469.12 872.43,407.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="847.24,410.29 852.89,471.51 852.03,463.69 846.38,402.48 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="791.61,416.37 797.25,477.59 790.83,475.68 785.18,414.47 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="872.43,407.91 878.08,469.12 876.92,470.36 871.27,409.14 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="884.3,401.62 889.95,462.84 890.51,469.46 884.86,408.24 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="886.77,401.62 892.41,462.84 892.98,469.62 887.33,408.41 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="801.03,416.44 806.68,477.66 797.25,477.59 791.61,416.37 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="790.76,417.74 796.41,478.95 788.15,476.52 782.5,415.31 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="799.23,417.8 804.88,479.02 796.41,478.95 790.76,417.74 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="871.27,409.14 876.92,470.36 875.94,472.76 870.29,411.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="800.24,419.29 805.89,480.51 804.88,479.02 799.23,417.8 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="855.19,414.37 860.84,475.59 852.89,471.51 847.24,410.29 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="802.91,419.22 808.56,480.44 806.68,477.66 801.03,416.44 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="870.29,411.55 875.94,472.76 876.02,474.43 870.37,413.21 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="884.86,408.24 890.51,469.46 884.89,475.42 879.25,414.2 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="870.37,413.21 876.02,474.43 871.3,476.86 865.65,415.64 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="865.65,415.64 871.3,476.86 860.84,475.59 855.19,414.37 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="802.91,419.22 808.56,480.44 805.57,483.85 799.92,422.63 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="879.25,414.2 884.89,475.42 876.91,478.98 871.26,417.77 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="871.26,417.77 876.91,478.98 867.61,480.99 861.97,419.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="799.92,422.63 805.57,483.85 800.02,488.42 794.37,427.2 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="794.37,427.2 800.02,488.42 793.41,489.44 787.76,428.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="785.76,431.23 791.41,492.45 790.74,488.46 785.09,427.24 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="788.21,431.04 793.86,492.25 793.41,489.44 787.76,428.23 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="788.21,431.04 793.86,492.25 793.63,495.84 787.98,434.63 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="830.15,431.15 835.8,492.36 817.17,492.34 811.52,431.12 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="846.62,431.07 852.27,492.28 835.8,492.36 830.15,431.15 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="861.97,419.77 867.61,480.99 852.27,492.28 846.62,431.07 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="790.41,438.5 796.06,499.71 793.63,495.84 787.98,434.63 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="793.1,441.3 798.75,502.51 796.06,499.71 790.41,438.5 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="811.52,431.12 817.17,492.34 798.75,502.51 793.1,441.3 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M897.48,387.46l-6.98,6.74l-3.73,7.43l0.57,6.78l-6.21,6.62L872.1,419l-8.75,1.89l-15.69,11.55l-17.78,0.09
l-17.57-0.02l-19.95,11.02l-4.22-4.36l-2.62-4.31l0.25-3.63l-0.67-3.99l7.9-1.22l4.85-3.98l2.39-2.75l-1.01-1.49l-8.47-0.07
l-8.26-2.43l2.88-9.8l4.6-8.81l-4.58-4.78l-8.52-1.23l-13.9,3.16l-3.44-6.17l12.46-6.4l9.21-5.35l-6.69-3.98l-11.08-2l-3.93-7.33
l9.12-8.08l6.24-8.18l6.36-17.5l7.24-9.07l23.45,6.78l20.15,9.51l21.95,9.65l21.37,10.31l18.9,6.9l19.67,7.51L897.48,387.46z
 M884.86,408.24l-0.56-6.62l4.01-7.93l7.06-6.81l15.4-15.92l-18-6.87l-18.99-6.94l-21.45-10.33l-21.95-9.65l-20.08-9.47l-20.8-6
l-5.93,7.44l-6.38,17.5l-6.42,8.43l-8.59,7.6l3.29,6.15l10.55,1.91l8.56,5.1l-10.99,6.39L762.37,388l2.26,4.05l12.33-2.8
l10.21,1.47l5.44,5.68l-4.87,9.27l-2.56,8.81l6.43,1.9l9.42,0.07l1.88,2.78l-2.99,3.41l-5.55,4.57l-6.61,1.03l0.45,2.81
l-0.23,3.59l2.43,3.87l2.7,2.8l18.41-10.17l18.64,0.02l16.46-0.08l15.35-11.3l9.29-2.01l7.99-3.56L884.86,408.24"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M873.99,399.45l3.61,0.55l1.64,1.23l-2.27,3.17l-2.31,1.93l-2.22,1.59l-1.16,1.24l-0.98,2.4l0.08,1.67
l-4.72,2.43l-10.45-1.27l-7.95-4.08l-0.86-7.82l8.01-4.55l13.49,1.14L873.99,399.45z M874.87,403.78l1.58-2.22l-0.46-0.35
l-2.73-0.43l-5.96-0.36l-12.25-1.05l-6.14,3.51l0.72,6.66l6.89,3.55l8.63,1.04l2.73-1.41l-0.03-1.25l1.09-2.69l1.48-1.54
l2.29-1.65L874.87,403.78"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="887.33,408.41 892.98,469.62 886.77,476.25 881.12,415.03 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="881.12,415.03 886.77,476.25 877.75,480.22 872.1,419 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="872.1,419 877.75,480.22 869,482.1 863.35,420.89 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="829.88,432.53 835.53,493.74 817.96,493.72 812.31,432.51 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="863.35,420.89 869,482.1 853.31,493.65 847.66,432.44 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="847.66,432.44 853.31,493.65 835.53,493.74 829.88,432.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="788.14,439.17 793.79,500.38 791.16,496.07 785.52,434.86 			"
              />
            </g>
            <g>
              <path
                className="st59"
                style={{ fill: mingecevirColor ? mingecevirColor : null }}
                d="M895.37,386.89l-7.06,6.81l-4.01,7.93l0.56,6.62l-5.61,5.96l-7.99,3.56l-9.29,2.01l-15.35,11.3l-16.46,0.08
l-18.64-0.02L793.1,441.3l-2.7-2.8l-2.43-3.87l0.23-3.59l-0.45-2.81l6.61-1.03l5.55-4.57l2.99-3.41l-1.88-2.78l-9.42-0.07
l-6.43-1.9l2.56-8.81l4.87-9.27l-5.44-5.68l-10.21-1.47l-12.33,2.8l-2.26-4.05l11.23-5.78l10.99-6.39l-8.56-5.1l-10.55-1.91
l-3.29-6.15l8.59-7.6l6.37-8.37l6.43-17.56l5.93-7.44l20.8,6l20.08,9.47l21.95,9.65l21.45,10.33l18.99,6.94l18,6.87
L895.37,386.89z M822.49,377.72l27.38-3.59l-1.52-4.01l-2.19-2.83l-6.12-0.66l-5.96,0.68l-9.51-3.22l-12.06,2.26L822.49,377.72
 M876.96,404.39l2.27-3.17l-1.64-1.23l-3.61-0.55l-6.1-0.38l-13.49-1.14l-8.01,4.55l0.86,7.82l7.95,4.08l10.45,1.27l4.72-2.43
l-0.08-1.67l0.98-2.4l1.16-1.24l2.22-1.59L876.96,404.39"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="812.31,432.51 817.96,493.72 798.01,504.74 792.36,443.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="792.36,443.53 798.01,504.74 793.79,500.38 788.14,439.17 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                className="st35"
                points="824.03,365.59 829.68,426.8 821.67,428.31 816.02,367.09 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="839.77,368.03 845.42,429.25 839.03,429.98 833.39,368.76 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="833.39,368.76 839.03,429.98 829.68,426.8 824.03,365.59 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="844.33,368.52 849.98,429.74 845.42,429.25 839.77,368.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846,370.67 851.65,431.89 849.98,429.74 844.33,368.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846.95,373.14 852.6,434.35 851.65,431.89 846,370.67 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="846.95,373.14 852.6,434.35 829.62,437.36 823.97,376.14 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="823.97,376.14 829.62,437.36 821.67,428.31 816.02,367.09 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: yevlaxColor ? yevlaxColor : null }}
                points="824.03,365.59 833.39,368.76 839.77,368.03 844.33,368.52 846,370.67 846.95,373.14 823.97,376.14 
816.02,367.09 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M848.35,370.12l1.52,4.01l-27.38,3.59l-9.99-11.37l12.06-2.26l9.51,3.22l5.96-0.68l6.12,0.66L848.35,370.12
z M816.02,367.09l7.96,9.05l22.97-3l-0.94-2.47l-1.67-2.15l-4.56-0.49l-6.38,0.73l-9.35-3.18L816.02,367.09"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="849.87,374.13 855.52,435.35 828.14,438.94 822.49,377.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="822.49,377.72 828.14,438.94 818.15,427.57 812.51,366.35 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'naftalan' ? 'active' : null}`}
            onClick={() => openPopup('naftalan')}
          >
            <g>
              <polygon
                className="st32"
                points="762.91,417.81 768.56,479.02 767.41,479.42 761.77,418.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="763.95,418.56 769.6,479.78 768.56,479.02 762.91,417.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="761.77,418.2 767.41,479.42 764.51,480.82 758.86,419.61 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="764.79,419.41 770.44,480.63 769.6,479.78 763.95,418.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="759.05,420.48 764.7,481.69 764.51,480.82 758.86,419.61 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="764.79,419.41 770.44,480.63 770.23,481.22 764.58,420.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="756.64,420.93 762.29,482.15 761.91,480.35 756.26,419.14 			"
              />
            </g>
            <g>
              <polygon
                className="st141"
                points="767.36,419.05 773.01,480.27 772.58,481.5 766.93,420.29 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="764.58,420.01 770.23,481.22 769.63,481.96 763.98,420.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="760,421.29 765.65,482.5 764.7,481.69 759.05,420.48 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: naftalanColor ? naftalanColor : null }}
                points="762.91,417.81 763.95,418.56 764.79,419.41 764.58,420.01 763.98,420.74 761.66,421.4 760,421.29 
759.05,420.48 758.86,419.61 761.77,418.2 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="763.98,420.74 769.63,481.96 767.3,482.61 761.66,421.4 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="761.66,421.4 767.3,482.61 765.65,482.5 760,421.29 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="766.93,420.29 772.58,481.5 771.42,482.92 765.77,421.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="758.43,422.59 764.07,483.81 762.29,482.15 756.64,420.93 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M767.36,419.05l-0.43,1.24l-1.16,1.42l-3.92,1.11l-3.42-0.22l-1.79-1.66l-0.37-1.79l4.19-2.01l3.22-1.13
l2.36,1.72L767.36,419.05z M763.98,420.74l0.59-0.74l0.21-0.59l-0.84-0.85l-1.04-0.75l-1.14,0.4l-2.91,1.4l0.19,0.87l0.95,0.81
l1.66,0.11L763.98,420.74"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="765.77,421.7 771.42,482.92 767.5,484.03 761.85,422.82 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="761.85,422.82 767.5,484.03 764.07,483.81 758.43,422.59 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'yevlax' ? 'active' : null}`}
            onClick={() => openPopup('yevlax')}
          >
            <g>
              <polygon
                className="st109"
                points="867.31,400.43 872.96,461.65 860.71,460.6 855.06,399.38 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="855.06,399.38 860.71,460.6 854.57,464.11 848.92,402.89 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="873.27,400.79 878.91,462 872.96,461.65 867.31,400.43 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="875.99,401.22 881.64,462.43 878.91,462 873.27,400.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="876.45,401.56 882.1,462.78 881.64,462.43 875.99,401.22 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="876.45,401.56 882.1,462.78 880.52,465 874.87,403.78 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="879.23,401.22 884.88,462.43 882.61,465.6 876.96,404.39 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="874.87,403.78 880.52,465 878.37,466.81 872.72,405.6 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="876.96,404.39 882.61,465.6 880.3,467.53 874.65,406.32 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="872.72,405.6 878.37,466.81 876.08,468.46 870.43,407.25 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="849.64,409.55 855.28,470.76 854.57,464.11 848.92,402.89 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="874.65,406.32 880.3,467.53 878.08,469.12 872.43,407.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="847.24,410.29 852.89,471.51 852.03,463.69 846.38,402.48 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="870.43,407.25 876.08,468.46 874.6,470 868.95,408.79 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="872.43,407.91 878.08,469.12 876.92,470.36 871.27,409.14 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="868.95,408.79 874.6,470 873.51,472.69 867.86,411.47 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="871.27,409.14 876.92,470.36 875.94,472.76 870.29,411.55 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="856.53,413.1 862.18,474.31 855.28,470.76 849.64,409.55 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="867.86,411.47 873.51,472.69 873.54,473.94 867.89,412.73 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="855.19,414.37 860.84,475.59 852.89,471.51 847.24,410.29 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="870.29,411.55 875.94,472.76 876.02,474.43 870.37,413.21 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="865.16,414.13 870.81,475.35 862.18,474.31 856.53,413.1 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="867.89,412.73 873.54,473.94 870.81,475.35 865.16,414.13 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: yevlaxColor ? yevlaxColor : null }}
                points="873.27,400.79 875.99,401.22 876.45,401.56 874.87,403.78 872.72,405.6 870.43,407.25 
868.95,408.79 867.86,411.47 867.89,412.73 865.16,414.13 856.53,413.1 849.64,409.55 848.92,402.89 855.06,399.38 
867.31,400.43 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M877.59,399.99l1.64,1.23l-2.27,3.17l-2.31,1.93l-2.22,1.59l-1.16,1.24l-0.98,2.4l0.08,1.67l-4.72,2.43
l-10.45-1.27l-7.95-4.08l-0.86-7.82l8.01-4.55l13.49,1.14l6.1,0.38L877.59,399.99z M874.87,403.78l1.58-2.22l-0.46-0.35
l-2.73-0.43l-5.96-0.36l-12.25-1.05l-6.14,3.51l0.72,6.66l6.89,3.55l8.63,1.04l2.73-1.41l-0.03-1.25l1.09-2.69l1.48-1.54
l2.29-1.65L874.87,403.78"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="870.37,413.21 876.02,474.43 871.3,476.86 865.65,415.64 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="865.65,415.64 871.3,476.86 860.84,475.59 855.19,414.37 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'agdas' ? 'active' : null}`}
            onClick={() => openPopup('agdas')}
          >
            <g>
              <polygon
                className="st9"
                points="922.76,373.74 928.41,434.96 918.48,432.8 912.83,371.59 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="933.36,376.35 939,437.57 928.41,434.96 922.76,373.74 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="939.52,378.73 945.16,439.95 939,437.57 933.36,376.35 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="952.6,378.42 958.25,439.64 955.58,440.07 949.93,378.85 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="949.93,378.85 955.58,440.07 952.78,440.7 947.13,379.49 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="947.13,379.49 952.78,440.7 945.16,439.95 939.52,378.73 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="956.59,379.56 962.24,440.78 958.25,439.64 952.6,378.42 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="960.85,381.4 966.5,442.62 962.24,440.78 956.59,379.56 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="912.83,371.59 918.48,432.8 903.12,448.68 897.48,387.46 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="971.32,383.91 976.97,445.13 966.5,442.62 960.85,381.4 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="981.84,383.68 987.49,444.9 976.97,445.13 971.32,383.91 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="897.48,387.46 903.12,448.68 896.15,455.41 890.5,394.2 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="981.84,383.68 987.49,444.9 984.4,453.45 978.75,392.24 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="984.8,382.25 990.45,443.46 986.8,453.59 981.15,392.37 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="890.5,394.2 896.15,455.41 892.41,462.84 886.77,401.63 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="886.77,401.63 892.41,462.84 892.98,469.63 887.33,408.41 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="978.75,392.24 984.4,453.45 980.63,463.04 974.98,401.82 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="981.15,392.37 986.8,453.59 983.23,462.69 977.58,401.47 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="887.33,408.41 892.98,469.63 886.77,476.25 881.12,415.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="980,406.88 985.65,468.09 980.63,463.04 974.98,401.82 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="881.12,415.04 886.77,476.25 881.68,478.51 876.03,417.3 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="873.4,417.81 879.05,479.02 877.98,478.51 872.33,417.29 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="877.29,418.12 882.94,479.34 881.68,478.51 876.03,417.3 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="875.21,418.99 880.86,480.2 879.05,479.02 873.4,417.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="877.11,420.89 882.76,482.1 880.86,480.2 875.21,418.99 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="987.77,410.82 993.42,472.04 985.65,468.09 980,406.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="880.14,420.96 885.79,482.17 882.94,479.34 877.29,418.12 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="888.44,421.92 894.09,483.13 890.92,483.68 885.27,422.47 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="880.14,420.96 885.79,482.17 881.87,484.55 876.22,423.33 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="885.27,422.47 890.92,483.68 887.03,485.14 881.38,423.93 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="887.43,423.49 893.08,484.7 891.6,484.97 885.95,423.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="990.78,414.2 996.42,475.41 993.42,472.04 987.77,410.82 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="885.95,423.75 891.6,484.97 888.4,486.19 882.75,424.98 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="876.22,423.33 881.87,484.55 880.33,486.97 874.68,425.75 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="881.38,423.93 887.03,485.14 881.81,487.68 876.16,426.47 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="893.27,425.07 898.92,486.28 894.09,483.13 888.44,421.92 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="874.68,425.75 880.33,486.97 880.37,488 874.73,426.79 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="876.16,426.47 881.81,487.68 880.37,488 874.73,426.79 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="890.48,425.47 896.13,486.69 893.08,484.7 887.43,423.49 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="882.75,424.98 888.4,486.19 882.7,488.91 877.05,427.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="992.91,417.38 998.56,478.6 996.42,475.41 990.78,414.2 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="877.05,427.69 882.7,488.91 877.97,489.93 872.33,428.72 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="992.91,417.38 998.56,478.6 996.68,480.42 991.03,419.21 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="893.27,425.07 898.92,486.28 896.07,490.54 890.42,429.32 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="995.63,417.36 1001.28,478.58 998.48,481.31 992.83,420.09 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="991.03,419.21 996.68,480.42 992.59,482.12 986.94,420.9 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="992.83,420.09 998.48,481.31 994.13,483.09 988.48,421.88 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="890.42,429.32 896.07,490.54 892.79,493.96 887.14,432.75 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="888.1,434.14 893.75,495.36 892.79,493.96 887.14,432.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="886.07,435.14 891.72,496.35 890.1,494.01 884.45,432.79 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="890.9,435.08 896.55,496.29 893.75,495.36 888.1,434.14 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="986.94,420.9 992.59,482.12 985.77,488.38 980.12,427.17 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="888.97,436.11 894.62,497.32 891.72,496.35 886.07,435.14 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="988.48,421.88 994.13,483.09 987.89,488.91 982.24,427.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="892.59,436.91 898.24,498.12 896.55,496.29 890.9,435.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="890.29,437.54 895.94,498.75 894.62,497.32 888.97,436.11 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="967.31,430.79 972.96,492.01 961.49,491.76 955.84,430.54 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="955.84,430.54 961.49,491.76 950.94,494.39 945.29,433.18 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="893.48,438.64 899.12,499.86 898.24,498.12 892.59,436.91 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="966.42,432.16 972.07,493.37 961.7,493.14 956.05,431.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="891.16,439.23 896.8,500.45 895.94,498.75 890.29,437.54 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="956.05,431.92 961.7,493.14 952.71,495.39 947.06,434.17 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="893.97,439.25 899.62,500.46 899.12,499.86 893.48,438.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="891.75,440 897.4,501.21 896.8,500.45 891.16,439.23 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="895.03,440 900.68,501.22 899.62,500.46 893.97,439.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="892.32,440.43 897.97,501.65 897.4,501.21 891.75,440 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="976.15,433.42 981.8,494.63 972.96,492.01 967.31,430.79 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="980.12,427.17 985.77,488.38 981.8,494.63 976.15,433.42 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="895.03,440 900.68,501.22 899.56,503.26 893.91,442.05 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="897.88,442.58 903.53,503.79 899.78,503.41 894.13,442.2 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="982.24,427.7 987.89,488.91 983.05,496.63 977.4,435.41 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="977.4,435.41 983.05,496.63 972.07,493.37 966.42,432.16 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="901.31,442.49 906.96,503.71 903.53,503.79 897.88,442.58 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="905.08,441.99 910.73,503.21 906.96,503.71 901.31,442.49 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="892.64,443.47 898.29,504.68 896.85,503.69 891.2,442.48 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="897.43,443.95 903.08,505.17 898.29,504.68 892.64,443.47 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="908.09,443.05 913.74,504.26 910.73,503.21 905.08,441.99 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="904.38,443.46 910.03,504.68 907.01,505.06 901.36,443.85 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="901.36,443.85 907.01,505.06 903.08,505.17 897.43,443.95 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="905.15,443.72 910.8,504.94 910.03,504.68 904.38,443.46 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="908.09,443.05 913.74,504.26 911.04,508.33 905.39,447.11 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="905.39,447.11 911.04,508.33 908.16,510.01 902.51,448.8 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="945.29,433.18 950.94,494.39 939.82,508.36 934.17,447.14 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="902.51,448.8 908.16,510.01 901.36,511.96 895.71,450.74 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="947.06,434.17 952.71,495.39 942.35,508.4 936.7,447.18 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="895.71,450.74 901.36,511.96 899.83,512.65 894.18,451.44 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="906.85,455.58 912.5,516.8 899.83,512.65 894.18,451.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="935.82,453.23 941.47,514.44 939.82,508.36 934.17,447.14 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="904.67,456.54 910.32,517.75 895.57,512.93 889.92,451.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="905.51,458.42 911.16,519.64 910.32,517.75 904.67,456.54 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="908.24,458.75 913.89,519.97 912.5,516.8 906.85,455.58 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="942.48,456.48 948.13,517.69 941.47,514.44 935.82,453.23 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="908.24,458.75 913.89,519.97 906.54,523.86 900.89,462.65 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="904.17,463.8 909.82,525.02 906.54,523.86 900.89,462.65 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="915.61,463.77 921.26,524.99 909.82,525.02 904.17,463.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="944.26,461.21 949.91,522.43 948.13,517.69 942.48,456.48 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="903.25,465.18 908.9,526.39 902.57,524.18 896.93,462.96 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="913.83,465.14 919.48,526.36 908.9,526.39 903.25,465.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="915.26,467.29 920.91,528.5 919.48,526.36 913.83,465.14 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="918.12,467.45 923.77,528.66 921.26,524.99 915.61,463.77 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="944.26,461.21 949.91,522.43 943.76,527.49 938.12,466.27 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="946.89,461.37 952.54,522.58 945.69,528.21 940.04,466.99 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="918.12,467.45 923.77,528.66 920.65,530.6 915.01,469.38 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="915.01,469.38 920.65,530.6 918.8,532.27 913.15,471.05 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="913.15,471.05 918.8,532.27 917.31,534.14 911.67,472.93 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="938.12,466.27 943.76,527.49 937.12,532.39 931.47,471.18 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="911.67,472.93 917.31,534.14 916.73,535.2 911.08,473.99 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="911.56,473.91 917.2,535.13 916.73,535.2 911.08,473.99 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="940.04,466.99 945.69,528.21 938.6,533.39 932.95,472.17 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="910.18,475.5 915.83,536.72 913.18,537.12 907.53,475.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="914,476.45 919.65,537.66 917.2,535.13 911.56,473.91 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="931.47,471.18 937.12,532.39 925.7,537.2 920.06,475.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="911.72,477.08 917.37,538.29 915.83,536.72 910.18,475.5 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="932.95,472.17 938.6,533.39 926.91,538.31 921.26,477.1 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="914.67,477.74 920.32,538.96 919.65,537.66 914,476.45 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: agdasColor ? agdasColor : null }}
                points="912.83,371.59 922.76,373.74 933.36,376.35 939.52,378.73 947.13,379.49 949.93,378.85 
952.6,378.42 956.59,379.56 960.85,381.4 971.32,383.91 981.84,383.68 978.75,392.24 974.98,401.82 980,406.88 987.77,410.82 
990.78,414.2 992.91,417.38 991.03,419.21 986.94,420.9 980.12,427.17 976.15,433.42 967.31,430.79 955.84,430.54 945.29,433.18 
934.17,447.14 935.82,453.23 942.48,456.48 944.26,461.21 938.12,466.27 931.47,471.18 920.06,475.98 914.67,477.74 914,476.45 
911.56,473.91 911.08,473.99 911.67,472.93 913.15,471.05 915.01,469.38 918.12,467.45 915.61,463.77 904.17,463.8 
900.89,462.65 908.24,458.75 906.85,455.58 894.18,451.44 895.71,450.74 902.51,448.8 905.39,447.11 908.09,443.05 
905.08,441.99 901.31,442.49 897.88,442.58 894.13,442.2 895.03,440 893.97,439.25 893.48,438.64 892.59,436.91 890.9,435.08 
888.1,434.14 887.14,432.75 890.42,429.32 893.27,425.07 888.44,421.92 885.27,422.47 881.38,423.93 876.16,426.47 
874.73,426.79 874.68,425.75 876.22,423.33 880.14,420.96 877.29,418.12 876.03,417.3 881.12,415.04 887.33,408.41 
886.77,401.63 890.5,394.2 897.48,387.46 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="920.06,475.98 925.7,537.2 920.32,538.96 914.67,477.74 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="921.26,477.1 926.91,538.31 918.74,540.99 913.09,479.77 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M993.04,413.54l2.59,3.82l-2.8,2.73l-4.35,1.79l-6.23,5.82l-4.84,7.71l-10.98-3.26l-10.37-0.23l-8.99,2.25
l-10.36,13.01l1.43,5.24l6.61,3.24l2.15,5.7l-6.85,5.63l-7.09,5.18l-11.69,4.92l-8.17,2.68l-1.37-2.7l-1.54-1.58l-2.66,0.41
l1.84-3.33l1.64-2.06l2.13-1.88l2.12-1.34l-1.43-2.14l-10.58,0.03l-6.33-2.21l8.58-4.54l-0.84-1.88l-14.75-4.82l4.74-2.15
l6.7-1.91l2.12-1.29l1.67-2.63l-0.77-0.26l-3.02,0.39l-3.93,0.1l-4.79-0.48l-1.44-0.99l1.12-2.04l-0.57-0.43l-0.59-0.77
l-0.86-1.7l-1.32-1.43l-2.89-0.97l-1.62-2.35l3.76-3.92l2.27-3.4l-3.06-1.99l-1.48,0.27l-3.2,1.23l-5.7,2.72l-4.73,1.03
l-0.12-3.05l2.11-3.09l2.8-1.69l-1.9-1.9l-1.82-1.18l-1.07-0.51l6.92-3.08l5.61-5.96l-0.56-6.62l4.01-7.93l7.06-6.81l16.49-17.05
l12.06,2.61l10.79,2.68l6.15,2.35l6.27,0.59l2.24-0.51l3.73-0.62l4.96,1.43l4.32,1.86l9.64,2.3l12.79-0.28l-3.65,10.13l-3.57,9.1
l4.51,4.55l7.81,3.96L993.04,413.54z M991.03,419.21l1.88-1.83l-2.14-3.18l-3-3.37l-7.77-3.95l-5.02-5.06l3.77-9.58l3.09-8.56
l-10.52,0.23l-10.47-2.51l-4.26-1.84l-3.99-1.14l-2.67,0.43l-2.81,0.63l-7.61-0.76l-6.16-2.38l-10.6-2.61l-9.92-2.16
l-15.36,15.88l-6.98,6.74l-3.73,7.43l0.57,6.78l-6.22,6.62l-5.08,2.26l1.25,0.82l2.85,2.84l-3.92,2.37l-1.54,2.42l0.04,1.04
l1.44-0.32l5.22-2.54l3.89-1.46l3.17-0.55l4.84,3.15l-2.85,4.25l-3.28,3.42l0.96,1.4l2.8,0.93l1.69,1.83l0.89,1.74l0.49,0.6
l1.06,0.76l-1.12,2.04l3.97,0.53l3.43-0.08l3.77-0.5l3.01,1.05l-2.7,4.06l-2.88,1.69l-6.8,1.94l-1.53,0.7l12.67,4.14l1.39,3.17
l-7.35,3.9l3.27,1.15l11.45-0.03l2.5,3.68l-3.11,1.93l-1.86,1.67l-1.48,1.87l-0.59,1.06l0.48-0.07l2.44,2.54l0.68,1.3l5.38-1.76
l11.42-4.8l6.65-4.9l6.14-5.06l-1.78-4.73l-6.66-3.25l-1.65-6.09l11.13-13.96l10.55-2.64l11.47,0.25l8.84,2.62l3.97-6.25
l6.82-6.27L991.03,419.21"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="913.09,479.77 918.74,540.99 917.37,538.29 911.72,477.08 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'terter' ? 'active' : null}`}
            onClick={() => openPopup('terter')}
          >
            <g>
              <polygon
                className="st11"
                points="785.76,431.21 791.41,492.42 791.02,490.09 785.38,428.88 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="785.38,428.88 791.02,490.09 776.04,495.63 770.4,434.41 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="770.4,434.41 776.04,495.63 769.54,497.19 763.89,435.98 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="722.1,439.53 727.75,500.74 712.43,503.04 706.78,441.82 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="788.21,431.01 793.86,492.23 793.63,495.82 787.98,434.6 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="785.76,431.21 791.41,492.42 791.16,496.05 785.51,434.83 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="763.89,435.98 769.54,497.19 763.11,499 757.46,437.79 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="732.29,440.65 737.94,501.86 727.75,500.74 722.1,439.53 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="757.46,437.79 763.11,499 756.23,501.01 750.58,439.8 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="706.78,441.82 712.43,503.04 696.8,506.66 691.16,445.44 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="691.16,445.44 696.8,506.66 679.77,508.75 674.12,447.54 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="750.58,439.8 756.23,501.01 748.8,502.61 743.15,441.39 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="743.15,441.39 748.8,502.61 737.94,501.86 732.29,440.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="788.14,439.14 793.79,500.36 791.16,496.05 785.51,434.83 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="674.12,447.54 679.77,508.75 667.17,514.93 661.52,453.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="791.5,442.61 797.14,503.83 793.79,500.36 788.14,439.14 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="661.52,453.72 667.17,514.93 664.49,518.4 658.84,457.18 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="791.5,442.61 797.14,503.83 796.62,508.74 790.97,447.53 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="658.84,457.18 664.49,518.4 660.64,521.99 654.99,460.77 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="654.99,460.77 660.64,521.99 656.85,523.14 651.21,461.92 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="651.21,461.92 656.85,523.14 653.92,524.17 648.28,462.95 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="793.97,442.17 799.62,503.38 798.64,513.53 793,452.31 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="790.97,447.53 796.62,508.74 796.19,513.94 790.54,452.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="792.67,455.34 798.32,516.56 796.19,513.94 790.54,452.72 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="648.28,462.95 653.92,524.17 649.25,531.35 643.6,470.13 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="795.84,457.38 801.49,518.6 798.32,516.56 792.67,455.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="798.23,462.81 803.88,524.02 801.49,518.6 795.84,457.38 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="643.6,470.13 649.25,531.35 648.78,539 643.14,477.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="803.38,467.08 809.03,528.3 803.88,524.02 798.23,462.81 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="643.14,477.78 648.78,539 648.01,546.14 642.36,484.92 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="810.34,469.44 815.99,530.66 809.03,528.3 803.38,467.08 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="643.2,490.29 648.85,551.5 648.01,546.14 642.36,484.92 			"
              />
            </g>
            <g>
              <rect
                x="646.01"
                y="489.9"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -45.0941 61.8203)"
                className="st121"
                width="5.64"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="656.51,487.44 662.16,548.65 654.46,550.99 648.81,489.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="640.95,491.85 646.6,553.07 645.58,546.31 639.93,485.09 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="649.16,491.12 654.8,552.33 646.6,553.07 640.95,491.85 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="656.57,488.91 662.22,550.12 654.8,552.33 649.16,491.12 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="673.73,489.99 679.38,551.2 662.16,548.65 656.51,487.44 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="672.07,491.21 677.72,552.42 662.22,550.12 656.57,488.91 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="767.31,482.95 772.96,544.16 768.42,542.6 762.77,481.39 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="762.77,481.39 768.42,542.6 764.47,545.64 758.83,484.42 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="765.33,483.9 770.98,545.11 768.91,544.43 763.26,483.22 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="763.26,483.22 768.91,544.43 766.67,546.16 761.02,484.95 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="769.34,484.72 774.99,545.93 772.96,544.16 767.31,482.95 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="810.34,469.44 815.99,530.66 808.69,543.38 803.04,482.17 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="813.26,468.73 818.9,529.94 811.05,543.6 805.4,482.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="767.61,485.88 773.26,547.1 770.98,545.11 765.33,483.9 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="775.33,485.94 780.98,547.16 774.99,545.93 769.34,484.72 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="783.12,484.92 788.77,546.13 780.98,547.16 775.33,485.94 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="758.83,484.42 764.47,545.64 764.26,549.01 758.61,487.8 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="774.92,487.39 780.57,548.6 773.26,547.1 767.61,485.88 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="781.6,486.49 787.25,547.71 780.57,548.6 774.92,487.39 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="761.02,484.95 766.67,546.16 766.73,549.98 761.08,488.77 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="758.61,487.8 764.26,549.01 745.31,552.28 739.66,491.06 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="739.66,491.06 745.31,552.28 727.64,555.01 721.99,493.79 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="761.08,488.77 766.73,549.98 745.73,553.59 740.08,492.38 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="740.08,492.38 745.73,553.59 729.33,556.13 723.68,494.92 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="684.41,499.06 690.05,560.28 679.38,551.2 673.73,489.99 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="787.08,489.88 792.73,551.1 788.77,546.13 783.12,484.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="784.63,490.19 790.27,551.4 787.25,547.71 781.6,486.49 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="682.36,499.95 688.01,561.17 677.72,552.42 672.07,491.21 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="803.04,482.17 808.69,543.38 805.97,550.66 800.32,489.44 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="805.4,482.39 811.05,543.6 808.38,550.72 802.73,489.5 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="690.12,503.03 695.77,564.25 690.05,560.28 684.41,499.06 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="721.99,493.79 727.64,555.01 722,562.14 716.36,500.92 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="687.81,503.73 693.46,564.95 688.01,561.17 682.36,499.95 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="800.32,489.44 805.97,550.66 805.87,554.76 800.22,493.54 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="802.73,489.5 808.38,550.72 808.33,554.67 802.69,493.45 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="787.08,489.88 792.73,551.1 792.77,556.37 787.12,495.15 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="723.68,494.92 729.33,556.13 723.9,562.97 718.25,501.75 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="716.36,500.92 722,562.14 713.05,565.69 707.4,504.48 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="793.05,496.88 798.7,558.1 792.77,556.37 787.12,495.15 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="799.7,496.95 805.35,558.17 798.7,558.1 793.05,496.88 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="800.22,493.54 805.87,554.76 805.35,558.17 799.7,496.95 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="718.25,501.75 723.9,562.97 714.5,566.7 708.85,505.49 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="792.21,498.25 797.85,559.46 790.32,557.27 784.67,496.06 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="691.7,507.73 697.35,568.95 695.77,564.25 690.12,503.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="689.28,508.11 694.93,569.32 693.46,564.95 687.81,503.73 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="801.93,498.35 807.58,559.56 797.85,559.46 792.21,498.25 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="802.69,493.45 808.33,554.67 807.58,559.56 801.93,498.35 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="707.4,504.48 713.05,565.69 705.95,571.18 700.3,509.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="708.85,505.49 714.5,566.7 708.01,571.8 702.36,510.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="693.02,512.65 698.66,573.86 697.35,568.95 691.7,507.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="690.62,513.11 696.27,574.33 694.93,569.32 689.28,508.11 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="696.28,516.08 701.93,577.3 698.66,573.86 693.02,512.65 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: terterColor ? terterColor : null }}
                points="785.38,428.88 785.76,431.21 785.51,434.83 788.14,439.14 791.5,442.61 790.97,447.53 
790.54,452.72 792.67,455.34 795.84,457.38 798.23,462.81 803.38,467.08 810.34,469.44 803.04,482.17 800.32,489.44 
800.22,493.54 799.7,496.95 793.05,496.88 787.12,495.15 787.08,489.88 783.12,484.92 775.33,485.94 769.34,484.72 
767.31,482.95 762.77,481.39 758.83,484.42 758.61,487.8 739.66,491.06 721.99,493.79 716.36,500.92 707.4,504.48 700.3,509.97 
696.28,516.08 693.02,512.65 691.7,507.73 690.12,503.03 684.41,499.06 673.73,489.99 656.51,487.44 648.81,489.77 643.2,490.29 
642.36,484.92 643.14,477.78 643.6,470.13 648.28,462.95 651.21,461.92 654.99,460.77 658.84,457.18 661.52,453.72 
674.12,447.54 691.16,445.44 706.78,441.82 722.1,439.53 732.29,440.65 743.15,441.39 750.58,439.8 757.46,437.79 763.89,435.98 
770.4,434.41 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="700.3,509.97 705.95,571.18 701.93,577.3 696.28,516.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="696.58,519.48 702.23,580.69 696.27,574.33 690.62,513.11 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="702.36,510.58 708.01,571.8 702.23,580.69 696.58,519.48 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M802.69,493.45l-0.76,4.9l-9.72-0.1l-7.53-2.19l-0.05-5.87l-3.03-3.7l-6.68,0.89l-7.3-1.51l-2.28-1.99
l-2.08-0.68l-2.24,1.73l0.06,3.82l-20.99,3.61l-16.41,2.54l-5.43,6.83l-9.4,3.73l-6.49,5.1l-5.78,8.9l-5.96-6.36l-1.34-5
l-1.46-4.38l-5.45-3.78l-10.3-8.75l-15.5-2.3l-7.41,2.21l-8.21,0.74l-1.02-6.76l0.75-7.22l0.49-7.85l5.14-7.93l3.85-1.35
l3.18-0.96l3.34-3.13l2.85-3.69l13.62-6.69l17.53-2.14l15.56-3.61l16.03-2.4l10.66,1.16l10.17,0.7l6.69-1.43l6.75-1.99l6.52-1.83
l6.47-1.56l17.94-6.63l0.73,4.46l-0.23,3.59l2.43,3.87l3.56,3.69L793,452.31l1.86,2.26l3.31,2.2l2.39,5.46l4.76,3.87l7.93,2.62
l-7.85,13.66l-2.67,7.11L802.69,493.45z M793.05,496.88l6.65,0.07l0.52-3.41l0.09-4.1l2.73-7.27l7.3-12.73l-6.97-2.36l-5.15-4.28
l-2.39-5.43l-3.17-2.04l-2.14-2.62l0.43-5.19l0.52-4.92l-3.36-3.47l-2.62-4.31l0.25-3.63l-0.39-2.33l-14.98,5.54l-6.51,1.56
l-6.43,1.81l-6.88,2.01l-7.43,1.59l-10.86-0.75l-10.19-1.12l-15.31,2.3l-15.63,3.62l-17.04,2.09l-12.6,6.18l-2.68,3.46
l-3.85,3.59l-3.78,1.15l-2.93,1.03l-4.68,7.18l-0.46,7.65l-0.78,7.14l0.84,5.37l5.62-0.52l7.7-2.34l17.22,2.55l10.68,9.07
l5.72,3.97l1.57,4.7l1.32,4.91l3.26,3.44l4.02-6.12l7.1-5.49l8.96-3.55l5.63-7.13l17.67-2.73l18.95-3.27l-0.04-3.12l4.2-3.29
l4.55,1.56l2.03,1.77l5.99,1.23l7.79-1.03l3.96,4.96l0.04,5.27L793.05,496.88"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'kelbecer' ? 'active' : null}`}
            onClick={() => openPopup('kelbecer')}
          >
            <g>
              <polygon
                className="st130"
                points="497.59,439.41 503.23,500.63 486.81,502.65 481.16,441.43 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="514.17,442.75 519.81,503.96 503.23,500.63 497.59,439.41 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="481.16,441.43 486.81,502.65 481.85,508.67 476.21,447.45 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="478.78,447.27 484.43,508.48 481.85,508.67 476.21,447.45 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="485.37,447.6 491.02,508.82 484.43,508.48 478.78,447.27 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="478.41,448.65 484.06,509.87 477.9,510.32 472.25,449.11 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="484.2,448.94 489.85,510.16 484.06,509.87 478.41,448.65 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="533.39,445.34 539.04,506.55 519.81,503.96 514.17,442.75 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="489.35,449.46 495,510.67 491.02,508.82 485.37,447.6 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="487.32,450.38 492.97,511.59 489.85,510.16 484.2,448.94 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="553.27,445.93 558.91,507.14 539.04,506.55 533.39,445.34 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="492.32,452.06 497.97,513.28 495,510.67 489.35,449.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="490.13,452.8 495.78,514.02 492.97,511.59 487.32,450.38 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="497.11,458.51 502.76,519.73 497.97,513.28 492.32,452.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="494.65,458.78 500.3,520 495.78,514.02 490.13,452.8 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="572.82,452.36 578.47,513.57 558.91,507.14 553.27,445.93 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="497.11,458.51 502.76,519.73 502.69,520.99 497.05,459.77 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="497.05,459.77 502.69,520.99 502.27,522.35 496.62,461.14 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="496.62,461.14 502.27,522.35 501.46,523.7 495.81,462.48 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="595.56,456.48 601.21,517.69 578.47,513.57 572.82,452.36 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="658.3,454.59 663.95,515.81 631.62,523.29 625.97,462.07 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="658.3,454.59 663.95,515.81 662.34,517.9 656.69,456.69 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="662.76,452.14 668.41,513.36 664.49,518.42 658.84,457.21 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="625.97,462.07 631.62,523.29 601.21,517.69 595.56,456.48 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="656.69,456.69 662.34,517.9 658.99,521.03 653.35,459.81 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="495.81,462.48 501.46,523.7 489.92,536.85 484.27,475.63 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="653.35,459.81 658.99,521.03 655.81,521.99 650.16,460.77 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="658.84,457.21 664.49,518.42 660.64,522.01 654.99,460.8 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="650.16,460.77 655.81,521.99 651.96,523.33 646.31,462.12 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="654.99,460.8 660.64,522.01 656.85,523.16 651.21,461.95 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="651.21,461.95 656.85,523.16 653.93,524.19 648.28,462.98 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="484.27,475.63 489.92,536.85 483.74,541.94 478.09,480.73 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="478.09,480.73 483.74,541.94 476.22,545.69 470.57,484.47 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="646.31,462.12 651.96,523.33 646.81,531.26 641.16,470.05 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="648.28,462.98 653.93,524.19 649.25,531.37 643.6,470.16 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="470.57,484.47 476.22,545.69 468.81,548.18 463.16,486.96 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="380.85,492.59 386.5,553.81 366.78,558.57 361.13,497.36 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="463.16,486.96 468.81,548.18 467.17,549.52 461.52,488.31 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="361.13,497.36 366.78,558.57 365.56,562.8 359.91,501.59 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="641.16,470.05 646.81,531.26 646.32,539.11 640.67,477.9 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="643.6,470.16 649.25,531.37 648.78,539.02 643.14,477.81 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="367.01,504.4 372.66,565.62 365.56,562.8 359.91,501.59 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="365.62,505.62 371.27,566.84 362.91,563.53 357.26,502.31 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="461.52,488.31 467.17,549.52 459.24,558.93 453.59,497.72 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="424.6,500.96 430.25,562.17 386.5,553.81 380.85,492.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="453.59,497.72 459.24,558.93 455.87,561.19 450.22,499.97 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="445.24,501.58 450.89,562.79 430.25,562.17 424.6,500.96 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="450.22,499.97 455.87,561.19 450.89,562.79 445.24,501.58 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="640.67,477.9 646.32,539.11 645.58,546.33 639.93,485.12 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="643.14,477.81 648.78,539.02 648.01,546.16 642.36,484.95 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="395.65,508.77 401.3,569.99 372.66,565.62 367.01,504.4 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="394.2,509.97 399.84,571.19 371.27,566.84 365.62,505.62 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="402.57,511.8 408.21,573.02 401.3,569.99 395.65,508.77 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="400.49,512.66 406.14,573.88 399.84,571.19 394.2,509.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="640.95,491.88 646.6,553.09 645.58,546.33 639.93,485.12 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="649.16,491.14 654.81,552.36 646.6,553.09 640.95,491.88 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="656.57,488.93 662.22,550.15 654.81,552.36 649.16,491.14 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="672.07,491.23 677.72,552.45 662.22,550.15 656.57,488.93 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="406.94,516.37 412.58,577.58 408.21,573.02 402.57,511.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="404.54,516.87 410.19,578.09 406.14,573.88 400.49,512.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="682.36,499.98 688.01,561.19 677.72,552.45 672.07,491.23 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="687.81,503.76 693.46,564.97 688.01,561.19 682.36,499.98 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="413.4,534.88 419.04,596.09 412.58,577.58 406.94,516.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="410.05,532.7 415.7,593.91 410.19,578.09 404.54,516.87 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="689.28,508.13 694.93,569.35 693.46,564.97 687.81,503.76 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="475.56,523.82 481.2,585.04 472.08,590.3 466.43,529.08 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="481.99,528.43 487.64,589.65 481.2,585.04 475.56,523.82 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="475.18,525.89 480.83,587.11 473.87,591.12 468.22,529.9 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="411.01,535.41 416.66,596.62 415.7,593.91 410.05,532.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="479.91,529.27 485.56,590.48 480.83,587.11 475.18,525.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="690.62,513.14 696.27,574.35 694.93,569.35 689.28,508.13 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="466.43,529.08 472.08,590.3 463.68,596.67 458.03,535.45 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="486.7,533.31 492.35,594.52 487.64,589.65 481.99,528.43 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="468.22,529.9 473.87,591.12 465.02,597.73 459.37,536.51 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="496,533.5 501.65,594.71 492.35,594.52 486.7,533.31 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="506.41,532.62 512.06,593.83 501.65,594.71 496,533.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="485.09,534.66 490.74,595.87 485.56,590.48 479.91,529.27 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="495.85,534.86 501.5,596.07 490.74,595.87 485.09,534.66 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="506.29,534 511.94,595.21 501.5,596.07 495.85,534.86 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="621.32,520.79 626.97,582 607.37,586.42 601.72,525.21 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="458.03,535.45 463.68,596.67 451.3,600.93 445.65,539.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="694.23,517.01 699.88,578.23 696.27,574.35 690.62,513.14 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="694.23,517.01 699.88,578.23 692.43,577.92 686.78,516.7 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="677,518.67 682.65,579.88 670.74,580.05 665.09,518.84 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="686.78,516.7 692.43,577.92 682.65,579.88 677,518.67 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="562.74,528.59 568.39,589.81 554.63,591.77 548.98,530.55 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="665.09,518.84 670.74,580.05 658.37,582.35 652.72,521.13 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="527.74,532.71 533.39,593.93 512.06,593.83 506.41,532.62 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="548.98,530.55 554.63,591.77 533.39,593.93 527.74,532.71 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="601.72,525.21 607.37,586.42 594.23,588.35 588.58,527.13 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="588.58,527.13 594.23,588.35 581.31,589.8 575.66,528.59 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="575.66,528.59 581.31,589.8 568.39,589.81 562.74,528.59 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="422.43,543.05 428.08,604.27 419.04,596.09 413.4,534.88 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="620.72,522.35 626.36,583.57 607.89,587.72 602.24,526.5 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="459.37,536.51 465.02,597.73 452.29,602.13 446.64,540.91 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="629.28,524.19 634.93,585.4 626.97,582 621.32,520.79 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="686.59,518.09 692.24,579.31 682.64,581.26 676.99,520.04 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="676.99,520.04 682.64,581.26 670.71,581.43 665.06,520.21 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="562.63,529.96 568.27,591.18 554.88,593.12 549.23,531.9 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="665.06,520.21 670.71,581.43 659.5,583.53 653.85,522.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="420.39,543.95 426.04,605.16 416.66,596.62 411.01,535.41 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="527.67,534.07 533.32,595.29 511.94,595.21 506.29,534 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="549.23,531.9 554.88,593.12 533.32,595.29 527.67,534.07 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="602.24,526.5 607.89,587.72 594.51,589.68 588.86,528.47 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="698.64,518.58 704.29,579.8 692.24,579.31 686.59,518.09 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="588.86,528.47 594.51,589.68 581.17,591.18 575.52,529.97 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="575.52,529.97 581.17,591.18 568.27,591.18 562.63,529.96 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="627.5,525.24 633.15,586.46 626.36,583.57 620.72,522.35 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="445.65,539.71 451.3,600.93 438.24,604.51 432.6,543.29 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="426.86,545.01 432.51,606.22 428.08,604.27 422.43,543.05 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="432.6,543.29 438.24,604.51 432.51,606.22 426.86,545.01 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="446.64,540.91 452.29,602.13 439.16,605.72 433.51,544.51 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="652.72,521.13 658.37,582.35 649.68,587.09 644.03,525.87 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="426.38,546.63 432.03,607.84 426.04,605.16 420.39,543.95 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="433.51,544.51 439.16,605.72 432.03,607.84 426.38,546.63 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="644.03,525.87 649.68,587.09 640.63,588.99 634.98,527.77 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: kelbecerColor ? kelbecerColor : null }}
                points="497.59,439.41 514.17,442.75 533.39,445.34 553.27,445.93 572.82,452.36 595.56,456.48 
625.97,462.07 658.3,454.59 656.69,456.69 653.35,459.81 650.16,460.77 646.31,462.12 641.16,470.05 640.67,477.9 639.93,485.12 
640.95,491.88 649.16,491.14 656.57,488.93 672.07,491.23 682.36,499.98 687.81,503.76 689.28,508.13 690.62,513.14 
694.23,517.01 686.78,516.7 677,518.67 665.09,518.84 652.72,521.13 644.03,525.87 634.98,527.77 629.28,524.19 621.32,520.79 
601.72,525.21 588.58,527.13 575.66,528.59 562.74,528.59 548.98,530.55 527.74,532.71 506.41,532.62 496,533.5 486.7,533.31 
481.99,528.43 475.56,523.82 466.43,529.08 458.03,535.45 445.65,539.71 432.6,543.29 426.86,545.01 422.43,543.05 413.4,534.88 
406.94,516.37 402.57,511.8 395.65,508.77 367.01,504.4 359.91,501.59 361.13,497.36 380.85,492.59 424.6,500.96 445.24,501.58 
450.22,499.97 453.59,497.72 461.52,488.31 463.16,486.96 470.57,484.47 478.09,480.73 484.27,475.63 495.81,462.48 
496.62,461.14 497.05,459.77 497.11,458.51 492.32,452.06 489.35,449.46 485.37,447.6 478.78,447.27 476.21,447.45 
481.16,441.43 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="634.98,527.77 640.63,588.99 634.93,585.4 629.28,524.19 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="653.85,522.32 659.5,583.53 650.86,588.25 645.21,527.03 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="634.06,529.39 639.71,590.6 633.15,586.46 627.5,525.24 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M693.02,512.67l5.62,5.91l-12.04-0.49l-9.6,1.95l-11.93,0.17l-11.21,2.11l-8.64,4.72l-11.15,2.35
l-6.57-4.15l-6.78-2.89l-18.48,4.15l-13.38,1.97l-13.34,1.5l-12.89-0.01l-13.39,1.94l-21.56,2.17L506.29,534l-10.44,0.86
l-10.76-0.2l-5.18-5.39l-4.73-3.37l-6.95,4.01l-8.85,6.61l-12.73,4.4l-13.13,3.6l-7.13,2.12l-5.99-2.68l-9.37-8.54l-0.96-2.71
l-5.51-15.83l-4.05-4.21l-6.3-2.69l-28.58-4.35l-8.36-3.31l1.7-5.86l22.06-5.34l44.3,8.48l19.59,0.59l4.06-1.3l2.68-1.87
l7.8-9.28l2.07-1.76l7.86-2.63l7.07-3.54l5.75-4.75l11.37-12.97l0.72-1.15l0.34-1.14l0.03-1l-4.52-5.98l-2.8-2.43l-3.12-1.43
l-5.79-0.29l-6.16,0.46l7.29-8.85l18.48-2.27l17.08,3.45l18.9,2.56l20.21,0.58l19.9,6.55l52.02,9.47l36.62-8.45l-3.92,5.06
l-3.85,3.59l-3.78,1.15l-2.93,1.03l-4.68,7.18l-0.46,7.65l-0.78,7.14l0.84,5.37l5.62-0.52l7.7-2.34l17.22,2.55l10.68,9.07
l5.72,3.97l1.57,4.7L693.02,512.67z M686.78,516.7l7.45,0.31l-3.61-3.87l-1.34-5l-1.46-4.38l-5.45-3.78l-10.3-8.75l-15.5-2.3
l-7.41,2.21l-8.21,0.74l-1.02-6.76l0.75-7.22l0.49-7.85l5.14-7.93l3.85-1.35l3.18-0.96l3.34-3.13l1.61-2.09l-32.32,7.48
l-30.41-5.59l-22.74-4.12l-19.56-6.43l-19.88-0.59l-19.22-2.59l-16.58-3.34l-16.43,2.02l-4.95,6.02l2.57-0.18l6.59,0.33
l3.99,1.85l2.96,2.61l4.79,6.45l-0.06,1.26l-0.42,1.37l-0.81,1.34l-11.54,13.15l-6.18,5.1l-7.52,3.74l-7.41,2.49l-1.64,1.35
l-7.93,9.41l-3.37,2.26l-4.98,1.61l-20.64-0.62l-43.75-8.37l-19.72,4.77l-1.22,4.23l7.1,2.82l28.64,4.37l6.92,3.03l4.37,4.57
l6.46,18.51l9.03,8.18l4.43,1.96l5.74-1.72l13.06-3.58l12.38-4.26l8.4-6.37l9.12-5.26l6.44,4.61l4.71,4.88l9.3,0.19l10.4-0.88
l21.33,0.09l21.24-2.16l13.76-1.96l12.92,0l12.92-1.46l13.14-1.92l19.6-4.42l7.96,3.4l5.7,3.59l9.04-1.9l8.7-4.74l12.36-2.3
l11.91-0.17L686.78,516.7"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="645.21,527.03 650.86,588.25 639.71,590.6 634.06,529.39 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'berde' ? 'active' : null}`}
            onClick={() => openPopup('berde')}
          >
            <g>
              <polygon
                className="st31"
                points="874.03,418.19 879.68,479.41 877.75,480.22 872.1,419.01 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="875.21,418.99 880.86,480.2 879.68,479.41 874.03,418.19 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="872.1,419.01 877.75,480.22 869,482.11 863.35,420.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="877.11,420.89 882.76,482.1 880.86,480.2 875.21,418.99 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="877.11,420.89 882.76,482.1 879.96,483.8 874.32,422.58 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="880.14,420.96 885.79,482.17 881.87,484.55 876.22,423.33 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="887.43,423.49 893.08,484.7 891.6,484.97 885.95,423.75 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="874.32,422.58 879.96,483.8 877.86,486.89 872.21,425.67 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="885.95,423.75 891.6,484.97 888.4,486.19 882.75,424.98 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="876.22,423.33 881.87,484.55 880.33,486.97 874.68,425.75 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="874.68,425.75 880.33,486.97 880.37,488 874.73,426.79 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="890.48,425.47 896.13,486.69 893.08,484.7 887.43,423.49 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="882.75,424.98 888.4,486.19 882.7,488.91 877.05,427.69 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="829.88,432.53 835.53,493.75 817.96,493.73 812.31,432.51 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="872.21,425.67 877.86,486.89 877.97,489.93 872.33,428.72 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="877.05,427.69 882.7,488.91 877.97,489.93 872.33,428.72 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="847.66,432.44 853.31,493.66 835.53,493.75 829.88,432.53 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="863.35,420.89 869,482.11 853.31,493.66 847.66,432.44 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="890.48,425.47 896.13,486.69 893.86,490.09 888.21,428.87 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="893.27,425.07 898.92,486.28 896.07,490.54 890.42,429.32 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="888.21,428.87 893.86,490.09 890.1,494.01 884.45,432.79 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="890.42,429.32 896.07,490.54 892.79,493.96 887.14,432.75 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="812.31,432.51 817.96,493.73 799.58,503.88 793.93,442.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="886.07,435.14 891.72,496.35 890.1,494.01 884.45,432.79 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="888.97,436.11 894.62,497.32 891.72,496.35 886.07,435.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="890.29,437.54 895.94,498.75 894.62,497.32 888.97,436.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="891.16,439.23 896.8,500.45 895.94,498.75 890.29,437.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="891.75,440 897.4,501.21 896.8,500.45 891.16,439.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="892.32,440.43 897.97,501.65 897.4,501.21 891.75,440 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="895.03,440 900.68,501.22 899.56,503.26 893.91,442.05 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="892.32,440.43 897.97,501.65 896.85,503.69 891.2,442.48 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="793.93,442.66 799.58,503.88 798.64,513.56 793,452.34 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="892.64,443.47 898.29,504.68 896.85,503.69 891.2,442.48 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="897.43,443.95 903.08,505.17 898.29,504.68 892.64,443.47 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="904.38,443.46 910.03,504.68 907.01,505.06 901.36,443.85 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="901.36,443.85 907.01,505.06 903.08,505.17 897.43,443.95 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="905.15,443.72 910.8,504.94 910.03,504.68 904.38,443.46 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="794.86,454.6 800.51,515.81 798.64,513.56 793,452.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="792.67,455.37 798.32,516.59 796.19,513.97 790.54,452.75 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="905.15,443.72 910.8,504.94 909.13,507.57 903.48,446.36 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="798.17,456.8 803.82,518.01 800.51,515.81 794.86,454.6 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="908.09,443.05 913.74,504.26 911.04,508.33 905.39,447.11 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="903.48,446.36 909.13,507.57 907.01,508.87 901.36,447.65 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="795.84,457.41 801.49,518.63 798.32,516.59 792.67,455.37 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="905.39,447.11 911.04,508.33 908.16,510.01 902.51,448.8 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="901.36,447.65 907.01,508.87 900.31,510.78 894.67,449.56 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="902.51,448.8 908.16,510.01 901.36,511.96 895.71,450.74 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="894.67,449.56 900.31,510.78 895.57,512.93 889.92,451.71 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="895.71,450.74 901.36,511.96 899.83,512.65 894.18,451.44 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="800.56,462.26 806.21,523.48 803.82,518.01 798.17,456.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="798.23,462.84 803.88,524.05 801.49,518.63 795.84,457.41 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="904.67,456.54 910.32,517.75 895.57,512.93 889.92,451.71 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="805.33,466.14 810.97,527.35 806.21,523.48 800.56,462.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="803.38,467.11 809.03,528.33 803.88,524.05 798.23,462.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="905.51,458.42 911.16,519.64 910.32,517.75 904.67,456.54 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="813.26,468.76 818.9,529.97 810.97,527.35 805.33,466.14 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="810.34,469.47 815.99,530.69 809.03,528.33 803.38,467.11 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="905.51,458.42 911.16,519.64 902.57,524.18 896.93,462.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="908.24,458.75 913.89,519.97 906.54,523.86 900.89,462.65 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="903.25,465.18 908.9,526.39 902.57,524.18 896.93,462.96 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="913.83,465.14 919.48,526.36 908.9,526.39 903.25,465.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="915.26,467.29 920.91,528.5 919.48,526.36 913.83,465.14 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="915.26,467.29 920.91,528.5 918.79,529.85 913.14,468.63 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="918.12,467.45 923.77,528.66 920.65,530.6 915.01,469.38 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="913.14,468.63 918.79,529.85 916.66,531.73 911.01,470.51 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="915.01,469.38 920.65,530.6 918.8,532.27 913.15,471.05 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="911.01,470.51 916.66,531.73 915.02,533.79 909.37,472.57 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="813.26,468.76 818.9,529.97 811.05,543.63 805.4,482.42 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="913.15,471.05 918.8,532.27 917.31,534.14 911.67,472.93 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="911.67,472.93 917.31,534.14 916.73,535.2 911.08,473.99 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="909.37,472.57 915.02,533.79 913.18,537.12 907.53,475.91 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="910.18,475.5 915.83,536.72 913.18,537.12 907.53,475.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="911.72,477.08 917.37,538.29 915.83,536.72 910.18,475.5 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="805.4,482.42 811.05,543.63 808.38,550.75 802.73,489.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="912.94,479.48 918.59,540.7 917.37,538.29 911.72,477.08 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="915.5,479.33 921.15,540.55 920.75,541.29 915.1,480.08 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="912.94,479.48 918.59,540.7 917.77,542.28 912.13,481.07 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="802.73,489.53 808.38,550.75 808.33,554.7 802.69,493.48 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="932.7,484.42 938.34,545.64 917.77,542.28 912.13,481.07 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="932.7,484.42 938.34,545.64 937.2,546.23 931.55,485.01 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="802.69,493.48 808.33,554.7 807.77,558.24 802.12,497.03 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="931.55,485.01 937.2,546.23 931.72,547.01 926.08,485.8 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="926.08,485.8 931.72,547.01 928.37,548.18 922.72,486.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="937.48,483.73 943.13,544.95 938.21,547.46 932.57,486.25 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="932.57,486.25 938.21,547.46 932.29,548.31 926.64,487.09 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="926.64,487.09 932.29,548.31 929.74,549.23 924.09,488.02 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="821.34,497.78 826.99,559 807.77,558.24 802.12,497.03 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="922.72,486.97 928.37,548.18 925.8,549.92 920.15,488.71 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="924.09,488.02 929.74,549.23 927.98,550.47 922.33,489.26 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="922.33,489.26 927.98,550.47 927.18,551 921.53,489.78 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="820.77,499.15 826.41,560.36 805.14,559.53 799.49,498.32 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="840.12,499.75 845.77,560.97 826.99,559 821.34,497.78 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="841.42,495.51 847.07,556.72 845.77,560.97 840.12,499.75 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="848.79,500.81 854.44,562.02 847.07,556.72 841.42,495.51 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="842.83,498.87 848.48,560.08 847.71,562.59 842.06,501.38 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="842.06,501.38 847.71,562.59 826.41,560.36 820.77,499.15 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="920.15,488.71 925.8,549.92 897.75,558.02 892.11,496.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846.86,501.76 852.5,562.97 848.48,560.08 842.83,498.87 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="921.53,489.78 927.18,551 898.59,559.25 892.94,498.04 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="892.11,496.8 897.75,558.02 870.05,564.89 864.4,503.68 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="864.4,503.68 870.05,564.89 860.65,565.79 855,504.58 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="855,504.58 860.65,565.79 854.44,562.02 848.79,500.81 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M915.1,480.08l22.38,3.65l-4.92,2.52l-5.93,0.85l-2.55,0.93l-1.76,1.24l-0.81,0.53l-28.58,8.25l-28.23,6.98
l-10.81,1.03l-7.05-4.29l-4.03-2.89l-0.76,2.51l-21.3-2.23l-21.27-0.83l0.73-4.74l0.09-4.1l2.73-7.27l7.3-12.73l-6.97-2.36
l-5.15-4.28l-2.39-5.43l-3.17-2.04l-2.14-2.62l0.43-5.19l0.57-5.38l19.98-11.05l18.64,0.02l16.46-0.08l15.35-11.3l9.29-2.01
l3.12-1.4l1,0.47l1.91,1.28l2.85,2.84l-3.92,2.37l-1.54,2.42l0.04,1.04l1.44-0.32l5.22-2.54l3.89-1.46l3.17-0.55l4.84,3.15
l-2.85,4.25l-3.28,3.42l0.96,1.4l2.8,0.93l1.69,1.83l0.89,1.74l0.49,0.6l1.06,0.76l-1.12,2.04l3.97,0.53l3.43-0.08l3.77-0.5
l3.01,1.05l-2.7,4.06l-2.88,1.69l-6.8,1.94l-1.53,0.7l12.67,4.14l1.39,3.17l-7.35,3.9l3.27,1.15l11.45-0.03l2.5,3.68l-3.11,1.93
l-1.86,1.67l-1.48,1.87l-0.59,1.06l2.92,2.46l1.5,2.89L915.1,480.08z M931.55,485.01l1.14-0.59l-20.57-3.36l0.82-1.59l-1.22-2.4
l-1.54-1.58l-2.66,0.41l1.84-3.33l1.64-2.06l2.13-1.88l2.12-1.34l-1.43-2.14l-10.58,0.03l-6.33-2.21l8.58-4.54l-0.84-1.88
l-14.75-4.82l4.74-2.15l6.7-1.91l2.12-1.29l1.67-2.63l-0.77-0.26l-3.02,0.39l-3.93,0.1l-4.79-0.48l-1.44-0.99l1.12-2.04
l-0.57-0.43l-0.59-0.77l-0.86-1.7l-1.32-1.43l-2.89-0.97l-1.62-2.35l3.76-3.92l2.27-3.4l-3.06-1.99l-1.48,0.27l-3.2,1.23
l-5.7,2.72l-4.73,1.03l-0.12-3.05l2.11-3.09l2.8-1.69l-1.9-1.9l-1.18-0.79l-1.94,0.81l-8.75,1.89l-15.69,11.55l-17.78,0.09
l-17.57-0.02l-18.38,10.15l-0.93,9.68l1.86,2.26l3.31,2.2l2.39,5.46l4.76,3.87l7.93,2.62l-7.85,13.66l-2.67,7.11l-0.05,3.95
l-0.57,3.55l19.22,0.75l18.78,1.97l1.3-4.25l7.37,5.3l6.21,3.77l9.39-0.9l27.71-6.87l28.04-8.1l2.57-1.74l3.35-1.17
L931.55,485.01"
              />
            </g>
            <g>
              <polygon
                className={`st59`}
                style={{ fill: berdeColor ? berdeColor : null }}
                points="874.03,418.19 875.21,418.99 877.11,420.89 874.32,422.58 872.21,425.67 872.33,428.72 
877.05,427.69 882.75,424.98 885.95,423.75 887.43,423.49 890.48,425.47 888.21,428.87 884.45,432.79 886.07,435.14 
888.97,436.11 890.29,437.54 891.16,439.23 891.75,440 892.32,440.43 891.2,442.48 892.64,443.47 897.43,443.95 901.36,443.85 
904.38,443.46 905.15,443.72 903.48,446.36 901.36,447.65 894.67,449.56 889.92,451.71 904.67,456.54 905.51,458.42 
896.93,462.96 903.25,465.18 913.83,465.14 915.26,467.29 913.14,468.63 911.01,470.51 909.37,472.57 907.53,475.91 
910.18,475.5 911.72,477.08 912.94,479.48 912.13,481.07 932.7,484.42 931.55,485.01 926.08,485.8 922.72,486.97 920.15,488.71 
892.11,496.8 864.4,503.68 855,504.58 848.79,500.81 841.42,495.51 840.12,499.75 821.34,497.78 802.12,497.03 802.69,493.48 
802.73,489.53 805.4,482.42 813.26,468.76 805.33,466.14 800.56,462.26 798.17,456.8 794.86,454.6 793,452.34 793.93,442.66 
812.31,432.51 829.88,432.53 847.66,432.44 863.35,420.89 872.1,419.01 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="892.94,498.04 898.59,559.25 870.37,566.23 864.72,505.02 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="853.91,506.05 859.56,567.27 852.5,562.97 846.86,501.76 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="864.72,505.02 870.37,566.23 859.56,567.27 853.91,506.05 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'agdam' ? 'active' : null}`}
            onClick={() => openPopup('agdam')}
          >
            <g>
              <polygon
                className="st110"
                points="765.34,483.91 770.99,545.13 768.91,544.45 763.27,483.24 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="763.27,483.24 768.91,544.45 766.68,546.18 761.03,484.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="767.62,485.9 773.27,547.11 770.99,545.13 765.34,483.91 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="774.93,487.41 780.57,548.62 773.27,547.11 767.62,485.9 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="781.61,486.51 787.26,547.73 780.57,548.62 774.93,487.41 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="761.03,484.97 766.68,546.18 766.74,550 761.09,488.78 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="761.09,488.78 766.74,550 745.74,553.61 740.09,492.39 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="740.09,492.39 745.74,553.61 729.33,556.15 723.69,494.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="784.63,490.21 790.28,551.42 787.26,547.73 781.61,486.51 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="787.09,489.9 792.74,551.11 792.78,556.39 787.13,495.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="723.69,494.94 729.33,556.15 723.91,562.99 718.26,501.77 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="784.63,490.21 790.28,551.42 790.33,557.29 784.68,496.08 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="718.26,501.77 723.91,562.99 714.51,566.72 708.86,505.5 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="792.21,498.27 797.86,559.48 790.33,557.29 784.68,496.08 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="800.59,498.35 806.24,559.57 797.86,559.48 792.21,498.27 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="820.77,499.13 826.42,560.35 806.24,559.57 800.59,498.35 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="708.86,505.5 714.51,566.72 708.02,571.81 702.37,510.6 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="838.03,500.95 843.68,562.16 826.42,560.35 820.77,499.13 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="838.03,500.95 843.68,562.16 831.55,566.84 825.9,505.63 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="686.6,518.08 692.25,579.3 684.91,580.83 679.27,519.61 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="697.22,518.53 702.87,579.74 692.25,579.3 686.6,518.08 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="702.37,510.6 708.02,571.81 702.87,579.74 697.22,518.53 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="844.17,500.16 849.82,561.38 832.41,568.07 826.76,506.85 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="825.9,505.63 831.55,566.84 815.63,570.69 809.98,509.48 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="826.76,506.85 832.41,568.07 817.44,571.69 811.8,510.48 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="685.69,523.64 691.33,584.86 684.91,580.83 679.27,519.61 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="697.95,522.78 703.6,583.99 691.33,584.86 685.69,523.64 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="684.53,525.1 690.18,586.31 680.61,580.3 674.97,519.08 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="697,524.21 702.65,585.43 690.18,586.31 684.53,525.1 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="809.98,509.48 815.63,570.69 811.77,576.06 806.12,514.84 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="811.8,510.48 817.44,571.69 814.54,575.73 808.89,514.51 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="710.29,528.23 715.94,589.45 703.6,583.99 697.95,522.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="812.14,519.39 817.79,580.6 811.77,576.06 806.12,514.84 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="707.99,529.06 713.64,590.28 702.65,585.43 697,524.21 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="812.14,519.39 817.79,580.6 809.59,583.43 803.94,522.22 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="803.94,522.22 809.59,583.43 797.69,584.47 792.04,523.25 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="815.73,519.69 821.37,580.9 809.96,584.76 804.31,523.55 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="804.31,523.55 809.96,584.76 799.52,585.67 793.87,524.46 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="792.04,523.25 797.69,584.47 794.29,590.64 788.64,529.42 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="793.87,524.46 799.52,585.67 796.62,590.92 790.97,529.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="712.92,538.26 718.57,599.47 715.94,589.45 710.29,528.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="710.54,538.74 716.19,599.95 713.64,590.28 707.99,529.06 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="788.64,529.42 794.29,590.64 791.01,596.13 785.36,534.91 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="785.36,534.91 791.01,596.13 787.37,597.25 781.72,536.04 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="790.97,529.7 796.62,590.92 792.98,597.02 787.34,535.8 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="781.72,536.04 787.37,597.25 782.22,598.43 776.57,537.22 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="787.34,535.8 792.98,597.02 788.21,598.47 782.56,537.26 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="782.56,537.26 788.21,598.47 784.8,599.26 779.15,538.04 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="720.01,544.26 725.66,605.47 718.57,599.47 712.92,538.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="718.01,545.19 723.66,606.41 716.19,599.95 710.54,538.74 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="730.68,548.6 736.33,609.81 725.66,605.47 720.01,544.26 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="727.55,549.12 733.2,610.33 723.66,606.41 718.01,545.19 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="778.03,548.98 783.67,610.19 782.22,598.43 776.57,537.22 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="730.68,548.6 736.33,609.81 730.42,615.98 724.77,554.77 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="750,554.46 755.65,615.68 741.58,616.31 735.94,555.09 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="724.77,554.77 730.42,615.98 722.67,619.84 717.02,558.63 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="749.04,555.87 754.69,617.08 742.92,617.61 737.27,556.4 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="814,552.47 819.65,613.69 806.3,615.26 800.65,554.04 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="800.65,554.04 806.3,615.26 783.67,610.19 778.03,548.98 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="735.94,555.09 741.58,616.31 735.4,622.35 729.75,561.13 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="825.9,552.48 831.55,613.69 819.65,613.69 814,552.47 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="760.38,558.89 766.03,620.1 755.65,615.68 750,554.46 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="737.27,556.4 742.92,617.61 737.18,623.18 731.54,561.96 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="758.63,559.96 764.28,621.18 754.69,617.08 749.04,555.87 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="717.02,558.63 722.67,619.84 712.88,625.95 707.23,564.74 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="729.75,561.13 735.4,622.35 727.9,626.67 722.25,565.45 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="722.25,565.45 727.9,626.67 716.03,628.15 710.38,566.94 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="707.23,564.74 712.88,625.95 707.51,629.7 701.86,568.49 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="710.38,566.94 716.03,628.15 707.51,629.7 701.86,568.49 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="731.54,561.96 737.18,623.18 728.98,627.91 723.33,566.69 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="723.33,566.69 728.98,627.91 716.43,629.48 710.78,568.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="829.01,558.34 834.66,619.55 831.55,613.69 825.9,552.48 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="710.78,568.26 716.43,629.48 700.81,632.32 695.16,571.1 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="772.88,566.08 778.53,627.29 766.03,620.1 760.38,558.89 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="771.18,567.18 776.83,628.4 764.28,621.18 758.63,559.96 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="807.78,563.81 813.43,625.03 803.52,624.8 797.87,563.59 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="806.96,565.17 812.61,626.39 804.19,626.2 798.55,564.98 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="797.87,563.59 803.52,624.8 797.08,628.05 791.43,566.84 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="829.01,558.34 834.66,619.55 825.79,625.92 820.14,564.7 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="820.14,564.7 825.79,625.92 823.67,626.61 818.02,565.4 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="798.55,564.98 804.19,626.2 798.68,628.99 793.03,567.78 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="818.02,565.4 823.67,626.61 821.58,627.08 815.93,565.87 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="815.93,565.87 821.58,627.08 813.43,625.03 807.78,563.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="831.74,558.49 837.39,619.7 827.07,627.03 821.42,565.81 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="791.43,566.84 797.08,628.05 792.48,630.65 786.83,569.43 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: agdamColor ? agdamColor : null }}
                points="763.27,483.24 765.34,483.91 767.62,485.9 774.93,487.41 781.61,486.51 784.63,490.21 
784.68,496.08 792.21,498.27 800.59,498.35 820.77,499.13 838.03,500.95 825.9,505.63 809.98,509.48 806.12,514.84 
812.14,519.39 803.94,522.22 792.04,523.25 788.64,529.42 785.36,534.91 781.72,536.04 776.57,537.22 778.03,548.98 
800.65,554.04 814,552.47 825.9,552.48 829.01,558.34 820.14,564.7 818.02,565.4 815.93,565.87 807.78,563.81 797.87,563.59 
791.43,566.84 786.83,569.43 772.88,566.08 760.38,558.89 750,554.46 735.94,555.09 729.75,561.13 722.25,565.45 710.38,566.94 
701.86,568.49 707.23,564.74 717.02,558.63 724.77,554.77 730.68,548.6 720.01,544.26 712.92,538.26 710.29,528.23 
697.95,522.78 685.69,523.64 679.27,519.61 686.6,518.08 697.22,518.53 702.37,510.6 708.86,505.5 718.26,501.77 723.69,494.94 
740.09,492.39 761.09,488.78 761.03,484.97 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="786.83,569.43 792.48,630.65 778.53,627.29 772.88,566.08 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="821.42,565.81 827.07,627.03 824.51,627.84 818.86,566.62 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="815.6,567.37 821.25,628.58 812.61,626.39 806.96,565.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="818.86,566.62 824.51,627.84 821.25,628.58 815.6,567.37 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="787.19,571.08 792.83,632.3 776.83,628.4 771.18,567.18 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M827.81,551.1l3.93,7.38l-10.32,7.32l-2.56,0.81l-3.26,0.75l-8.64-2.2l-8.41-0.19l-5.52,2.8l-5.84,3.3
l-16-3.9l-12.55-7.22l-9.59-4.09l-11.77,0.53l-5.74,5.57l-8.21,4.73l-12.55,1.57l-15.62,2.84l11.02-7.72l9.21-5.67l7.66-3.82
l4.5-4.77l-9.54-3.92l-7.47-6.46l-2.55-9.68L697,524.21l-12.47,0.89l-9.57-6.02l11.83-2.38l8.86,0.37l4.65-7.08l7.1-5.49
l8.96-3.55l5.63-7.13l17.67-2.73l18.95-3.27l-0.04-3.12l4.2-3.29l4.55,1.56l2.03,1.77l5.99,1.23l7.79-1.03l3.96,4.96l0.04,5.27
l5.93,1.73l7.91,0.08l20.38,0.79l22.82,2.39l-17.41,6.69l-14.97,3.63l-2.9,4.03l6.83,5.18l-11.41,3.86l-10.44,0.91l-2.9,5.24
l-3.64,6.1l-4.77,1.46l-3.41,0.79l1.21,9.93l20.76,4.64l13.03-1.51L827.81,551.1z M820.14,564.7l8.87-6.37l-3.11-5.86L814,552.47
l-13.35,1.57l-22.62-5.07l-1.46-11.76l5.15-1.18l3.64-1.12l3.28-5.49l3.4-6.17l11.9-1.04l8.2-2.83l-6.02-4.54l3.87-5.36
l15.91-3.85l12.13-4.68l-17.26-1.82l-20.19-0.78l-8.37-0.08l-7.53-2.19l-0.05-5.87l-3.03-3.7l-6.68,0.89l-7.3-1.51l-2.28-1.99
l-2.08-0.68l-2.24,1.73l0.06,3.82l-20.99,3.61l-16.41,2.54l-5.43,6.83l-9.4,3.73l-6.49,5.1l-5.14,7.93l-10.62-0.44l-7.34,1.53
l6.42,4.03l12.26-0.87l12.34,5.45l2.63,10.03l7.09,6l10.67,4.34l-5.91,6.17l-7.75,3.86l-9.79,6.11l-5.37,3.75l8.52-1.55
l11.87-1.49l7.5-4.32l6.18-6.04l14.06-0.63l10.38,4.43l12.5,7.19l13.96,3.35l4.6-2.6l6.44-3.25l9.9,0.23l8.15,2.05l2.09-0.47
L820.14,564.7"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="793.03,567.78 798.68,628.99 792.83,632.3 787.19,571.08 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'xocali' ? 'active' : null}`}
            onClick={() => openPopup('xocali')}
          >
            <g>
              <polygon
                className="st80"
                points="620.72,522.35 626.36,583.57 608.31,587.63 602.66,526.41 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="676.48,520.05 682.13,581.26 670.71,581.43 665.06,520.21 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="665.06,520.21 670.71,581.43 659.5,583.53 653.85,522.32 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="627.5,525.24 633.15,586.46 626.36,583.57 620.72,522.35 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="653.85,522.32 659.5,583.53 650.86,588.25 645.21,527.03 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="602.66,526.41 608.31,587.63 601.93,593.15 596.28,531.94 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="645.21,527.03 650.86,588.25 639.71,590.6 634.06,529.39 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="634.06,529.39 639.71,590.6 633.15,586.46 627.5,525.24 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="684.52,525.1 690.17,586.32 682.13,581.26 676.48,520.05 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="697,524.22 702.64,585.43 690.17,586.32 684.52,525.1 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="596.28,531.94 601.93,593.15 601.61,598.28 595.96,537.06 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="707.98,529.07 713.63,590.28 702.64,585.43 697,524.22 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="600.92,539.87 606.57,601.08 601.61,598.28 595.96,537.06 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="598.97,540.79 604.62,602.01 599.13,598.94 593.48,537.72 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="605.5,543.85 611.15,605.07 606.57,601.08 600.92,539.87 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="603.12,544.37 608.77,605.59 604.62,602.01 598.97,540.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="710.53,538.74 716.18,599.96 713.63,590.28 707.98,529.07 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="718.01,545.2 723.65,606.42 716.18,599.96 710.53,538.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="609.77,556.43 615.42,617.65 611.15,605.07 605.5,543.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="607.31,556.6 612.96,617.82 608.77,605.59 603.12,544.37 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="727.54,549.12 733.19,610.34 723.65,606.42 718.01,545.2 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="685.01,553.02 690.66,614.23 687.59,614.91 681.94,553.69 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="687.14,553.74 692.78,614.95 690.66,614.23 685.01,553.02 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="681.94,553.69 687.59,614.91 682.48,616.56 676.83,555.35 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="684.53,554.54 690.18,615.76 688.43,616.13 682.78,554.91 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="685.55,554.89 691.2,616.1 690.18,615.76 684.53,554.54 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="690.14,555.14 695.79,616.35 692.78,614.95 687.14,553.74 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="682.78,554.91 688.43,616.13 683.66,617.69 678.01,556.47 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="687.9,555.97 693.55,617.19 691.2,616.1 685.55,554.89 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="727.54,549.12 733.19,610.34 728.69,615.11 723.04,553.89 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="676.83,555.35 682.48,616.56 675.29,620.36 669.64,559.15 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="730.67,548.6 736.32,609.82 730.41,615.99 724.76,554.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="688.74,558.1 694.39,619.31 693.55,617.19 687.9,555.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="678.01,556.47 683.66,617.69 677.56,620.95 671.91,559.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="691.37,558.25 697.02,619.47 695.79,616.35 690.14,555.14 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="723.04,553.89 728.69,615.11 721.03,618.93 715.38,557.71 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="688.73,560.41 694.38,621.62 692,621.55 686.35,560.34 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="691.37,558.25 697.02,619.47 694.38,621.62 688.73,560.41 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="724.76,554.77 730.41,615.99 722.66,619.85 717.01,558.63 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="686.35,560.34 692,621.55 690.21,623.08 684.56,561.87 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="609.77,556.43 615.42,617.65 614.09,630.52 608.44,569.3 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="671.91,559.74 677.56,620.95 676.18,625.3 670.53,564.08 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="633.72,567.05 639.37,628.26 632.47,629.48 626.82,568.27 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="669.64,559.15 675.29,620.36 673.56,625.86 667.91,564.64 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="620.36,569.35 626.01,630.56 614.09,630.52 608.44,569.3 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="626.82,568.27 632.47,629.48 626.01,630.56 620.36,569.35 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="646.2,567.23 651.85,628.45 639.37,628.26 633.72,567.05 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="613.66,570.66 619.3,631.88 611.5,631.9 605.85,570.68 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="633.6,568.43 639.25,629.64 632.94,630.79 627.29,569.57 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="627.29,569.57 632.94,630.79 625.89,631.94 620.24,570.73 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="620.24,570.73 625.89,631.94 619.3,631.88 613.66,570.66 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="645.5,568.62 651.15,629.83 639.25,629.64 633.6,568.43 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="684.56,561.87 690.21,623.08 685.36,626.99 679.72,565.78 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="715.38,557.71 721.03,618.93 711.82,624.6 706.17,563.39 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="679.72,565.78 685.36,626.99 678.13,628.53 672.49,567.32 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="672.49,567.32 678.13,628.53 673.56,625.86 667.91,564.64 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="706.17,563.39 711.82,624.6 703.35,630.55 697.71,569.33 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="670.4,572.63 676.05,633.85 664.2,630.82 658.55,569.6 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="658.55,569.6 664.2,630.82 651.85,628.45 646.2,567.23 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="668.55,573.67 674.2,634.88 663.1,632.11 657.45,570.9 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="697.71,569.33 703.35,630.55 698.47,633.18 692.82,571.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="699.43,570.2 705.08,631.41 700.17,634.04 694.52,572.83 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="674.68,575.7 680.32,636.91 676.05,633.85 670.4,572.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="672.21,576.27 677.86,637.49 674.2,634.88 668.55,573.67 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="692.82,571.96 698.47,633.18 694.21,636.03 688.56,574.82 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="694.52,572.83 700.17,634.04 696.87,636.28 691.22,575.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="690.2,579.35 695.85,640.56 694.21,636.03 688.56,574.82 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="674.68,575.7 680.32,636.91 679.9,644.58 674.25,583.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="693.89,583.04 699.54,644.26 695.85,640.56 690.2,579.35 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="674.25,583.36 679.9,644.58 677.64,650.72 671.99,589.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="700.15,589.44 705.8,650.66 699.54,644.26 693.89,583.04 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="671.99,589.5 677.64,650.72 675.85,654.85 670.2,593.63 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="670.2,593.63 675.85,654.85 673.92,658.46 668.27,597.24 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="707.22,564.74 712.87,625.96 705.08,631.41 699.43,570.2 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="700.15,589.44 705.8,650.66 705.39,658.48 699.74,597.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="673.3,601.21 678.95,662.43 673.92,658.46 668.27,597.24 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="699.74,597.26 705.39,658.48 695.28,662.2 689.63,600.98 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="678.94,603.84 684.59,665.05 678.95,662.43 673.3,601.21 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="689.63,600.98 695.28,662.2 684.59,665.05 678.94,603.84 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M699.43,570.2l-4.91,2.63l-3.3,2.24l1.35,3.77l3.5,3.44l6.56,6.7l-0.48,8.96l-11.52,4.22l-12.28,3.3
l-7.03-3.29l-5.71-4.54l2.23-4.21l1.77-4.07l2.2-5.98l0.4-7.1l-3.65-2.6l-11.1-2.77l-11.95-2.28l-11.9-0.19l-6.31,1.14
l-7.05,1.16l-6.58-0.07l-7.8,0.02l1.46-14.08l-4.19-12.23l-4.15-3.58l-5.5-3.07l0.36-6.08l7.3-6.33l20.19-4.53l7.96,3.4l5.7,3.59
l9.04-1.9l8.7-4.74l12.36-2.3l12.65-0.18l7.94,4.99l12.26-0.87l12.34,5.45l2.63,10.03l7.09,6l10.67,4.34l-5.91,6.17l-7.75,3.86
l-9.79,6.11L699.43,570.2z M688.56,574.82l4.26-2.85l4.88-2.63l8.47-5.94l9.21-5.67l7.66-3.82l4.5-4.77l-9.54-3.92l-7.47-6.46
l-2.55-9.68L697,524.22l-12.47,0.89l-8.05-5.05l-11.42,0.16l-11.21,2.11l-8.64,4.72l-11.15,2.35l-6.57-4.15l-6.78-2.89
l-18.06,4.06l-6.38,5.53l-0.31,5.13l4.96,2.81l4.58,3.98l4.27,12.58l-1.33,12.87l11.92,0.05l6.46-1.08l6.9-1.22l12.48,0.19
l12.36,2.37l11.84,3.03l4.28,3.07l-0.42,7.67l-2.26,6.14l-1.79,4.13l-1.93,3.61l5.03,3.97l5.64,2.62l10.69-2.85l10.1-3.72
l0.41-7.82l-6.25-6.4l-3.69-3.69L688.56,574.82"
              />
            </g>
            <g>
              <path
                className="st59"
                style={{ fill: xocaliColor ? xocaliColor : null }}
                d="M697.71,569.33l-4.88,2.63l-4.26,2.85l1.63,4.53l3.69,3.69l6.25,6.4l-0.41,7.82l-10.1,3.72l-10.69,2.85
l-5.64-2.62l-5.03-3.97l1.93-3.61l1.79-4.13l2.26-6.14l0.42-7.67l-4.28-3.07l-11.84-3.03l-12.36-2.37l-12.48-0.19l-6.9,1.22
l-6.46,1.08l-11.92-0.05l1.33-12.87l-4.27-12.58l-4.58-3.98l-4.96-2.81l0.31-5.13l6.38-5.53l18.06-4.06l6.78,2.89l6.57,4.15
l11.15-2.35l8.64-4.72l11.21-2.11l11.42-0.16l8.05,5.05l12.47-0.89l10.98,4.85l2.55,9.68l7.47,6.46l9.54,3.92l-4.5,4.77
l-7.66,3.82l-9.21,5.67L697.71,569.33z M688.73,560.41l2.64-2.15l-1.23-3.12l-3-1.4l-2.12-0.72l-3.07,0.68l-5.11,1.66l-7.19,3.8
l-1.73,5.5l4.58,2.67l7.23-1.54l4.85-3.91l1.79-1.53L688.73,560.41"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M687.14,553.74l3,1.4l1.23,3.12l-2.64,2.15l-2.39-0.07l-1.79,1.53l-4.85,3.91l-7.23,1.54l-4.58-2.67
l1.73-5.5l7.19-3.8l5.11-1.66l3.07-0.68L687.14,553.74z M687.65,558.99l1.09-0.89l-0.84-2.13l-2.35-1.09l-1.01-0.34l-1.75,0.37
l-4.78,1.56l-6.09,3.27l-1.39,4.34l2.8,1.64l4.93-1.05l4.31-3.48l2.65-2.28L687.65,558.99"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="717.01,558.63 722.66,619.85 712.87,625.96 707.22,564.74 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="657.45,570.9 663.1,632.11 651.15,629.83 645.5,568.62 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="702.63,588.97 708.28,650.19 707.8,659.15 702.15,597.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="671.32,602.16 676.97,663.38 671.25,658.83 665.61,597.62 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="702.15,597.93 707.8,659.15 696.28,663.37 690.63,602.16 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="678.35,605.46 684,666.67 676.97,663.38 671.32,602.16 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="690.63,602.16 696.28,663.37 684,666.67 678.35,605.46 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'xankendi' ? 'active' : null}`}
            onClick={() => openPopup('xankendi')}
          >
            <g>
              <polygon
                className="st34"
                points="684.55,554.54 690.2,615.76 688.45,616.13 682.8,554.91 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="685.56,554.89 691.21,616.1 690.2,615.76 684.55,554.54 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="682.8,554.91 688.45,616.13 683.67,617.69 678.02,556.47 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="687.91,555.97 693.56,617.19 691.21,616.1 685.56,554.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="688.75,558.1 694.4,619.31 693.56,617.19 687.91,555.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="678.02,556.47 683.67,617.69 677.57,620.95 671.93,559.74 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="687.66,558.99 693.31,620.2 690.88,620.13 685.23,558.91 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="688.75,558.1 694.4,619.31 693.31,620.2 687.66,558.99 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="688.75,560.41 694.4,621.62 692.01,621.55 686.36,560.34 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="691.39,558.25 697.03,619.47 694.4,621.62 688.75,560.41 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="685.23,558.91 690.88,620.13 688.23,622.41 682.58,561.2 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="686.36,560.34 692.01,621.55 690.22,623.08 684.57,561.87 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="671.93,559.74 677.57,620.95 676.19,625.3 670.54,564.08 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="682.58,561.2 688.23,622.41 683.92,625.89 678.28,564.67 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="673.34,565.72 678.99,626.93 676.19,625.3 670.54,564.08 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="678.28,564.67 683.92,625.89 678.99,626.93 673.34,565.72 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: xankendiColor ? xankendiColor : null }}
                points="684.55,554.54 685.56,554.89 687.91,555.97 688.75,558.1 687.66,558.99 685.23,558.91 
682.58,561.2 678.28,564.67 673.34,565.72 670.54,564.08 671.93,559.74 678.02,556.47 682.8,554.91 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="684.57,561.87 690.22,623.08 685.38,626.99 679.73,565.78 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M690.15,555.14l1.23,3.12l-2.64,2.15l-2.39-0.07l-1.79,1.53l-4.85,3.91l-7.23,1.54l-4.58-2.67l1.73-5.5
l7.19-3.8l5.11-1.66l3.07-0.68l2.12,0.72L690.15,555.14z M687.66,558.99l1.09-0.89l-0.84-2.13l-2.35-1.09l-1.01-0.34l-1.75,0.37
l-4.78,1.56l-6.09,3.27l-1.39,4.34l2.8,1.64l4.93-1.05l4.31-3.48l2.65-2.28L687.66,558.99"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="679.73,565.78 685.38,626.99 678.15,628.53 672.5,567.32 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="672.5,567.32 678.15,628.53 673.57,625.86 667.92,564.64 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qusar' ? 'active' : null}`}
            onClick={() => openPopup('qusar')}
          >
            <g>
              <polygon
                className="st37"
                points="1342.97,201.76 1348.62,262.97 1346.63,263.95 1340.98,202.73 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1349.24,201.97 1354.89,263.19 1348.62,262.97 1342.97,201.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1353.82,206.7 1359.47,267.91 1354.89,263.19 1349.24,201.97 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1340.98,202.73 1346.63,263.95 1326.1,281.47 1320.45,220.26 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1353.82,206.7 1359.47,267.91 1347.95,278.54 1342.3,217.32 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1356.64,206.6 1362.29,267.82 1350.4,278.79 1344.76,217.58 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1320.45,220.26 1326.1,281.47 1317.17,287.35 1311.52,226.14 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1311.52,226.14 1317.17,287.35 1308.54,290.91 1302.89,229.69 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1344.76,217.58 1350.4,278.79 1350.52,288.98 1344.87,227.76 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1342.3,217.32 1347.95,278.54 1348.06,290.09 1342.41,228.88 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1302.89,229.69 1308.54,290.91 1280.18,297.51 1274.54,236.29 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1350.44,229.97 1356.09,291.19 1348.06,290.09 1342.41,228.88 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1355.99,231.48 1361.64,292.7 1356.09,291.19 1350.44,229.97 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1274.54,236.29 1280.18,297.51 1261.97,303.54 1256.32,242.33 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1212.63,244.98 1218.28,306.2 1207.99,308.56 1202.34,247.35 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1256.32,242.33 1261.97,303.54 1252.11,305.43 1246.46,244.22 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1246.46,244.22 1252.11,305.43 1218.28,306.2 1212.63,244.98 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1355.99,231.48 1361.64,292.7 1359.9,296.19 1354.25,234.98 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1358.89,230.66 1364.53,291.88 1362.06,296.84 1356.41,235.62 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1354.25,234.98 1359.9,296.19 1353.23,299.74 1347.58,238.52 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1202.34,247.35 1207.99,308.56 1200.11,313.87 1194.46,252.65 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1356.41,235.62 1362.06,296.84 1355.9,300.1 1350.25,238.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1349.9,245.73 1355.55,306.95 1353.23,299.74 1347.58,238.52 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1162.94,267.69 1168.59,328.91 1165.63,330.09 1159.99,268.87 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1194.46,252.65 1200.11,313.87 1183.49,328.53 1177.84,267.31 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1169.69,269.01 1175.34,330.22 1168.59,328.91 1162.94,267.69 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1177.84,267.31 1183.49,328.53 1175.34,330.22 1169.69,269.01 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1159.99,268.87 1165.63,330.09 1162.88,333.15 1157.23,271.94 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1349.9,245.73 1355.55,306.95 1354.82,315.59 1349.17,254.38 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1352.37,245.58 1358.02,306.79 1357.18,316.51 1351.53,255.3 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1332.03,258.01 1337.68,319.22 1319.53,320.8 1313.88,259.58 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1349.17,254.38 1354.82,315.59 1337.68,319.22 1332.03,258.01 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1157.23,271.94 1162.88,333.15 1158.28,337.1 1152.63,275.88 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1332.25,259.37 1337.9,320.58 1319.83,322.12 1314.18,260.91 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1351.53,255.3 1357.18,316.51 1337.9,320.58 1332.25,259.37 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1313.88,259.58 1319.53,320.8 1295.86,325.09 1290.21,263.88 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1314.18,260.91 1319.83,322.12 1296.52,326.37 1290.87,265.16 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1152.63,275.88 1158.28,337.1 1152.56,339.68 1146.91,278.47 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1146.91,278.47 1152.56,339.68 1148.25,340.85 1142.6,279.64 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1142.6,279.64 1148.25,340.85 1145.08,342.49 1139.43,281.27 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1290.21,263.88 1295.86,325.09 1272.25,332.63 1266.6,271.42 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1290.87,265.16 1296.52,326.37 1273.19,333.83 1267.54,272.62 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1139.43,281.27 1145.08,342.49 1142.34,346.6 1136.69,285.38 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1136.69,285.38 1142.34,346.6 1141.57,351.01 1135.92,289.79 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1135.92,289.79 1141.57,351.01 1141.71,354 1136.06,292.79 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1266.6,271.42 1272.25,332.63 1206.15,350.14 1200.5,288.92 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1136.06,292.79 1141.71,354 1139.09,356.4 1133.44,295.19 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1111.85,298.17 1117.5,359.38 1114.81,359.29 1109.17,298.08 			"
              />
            </g>
            <g>
              <polygon
                className="st57"
                points="1124.97,297.12 1130.62,358.34 1117.5,359.38 1111.85,298.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1133.44,295.19 1139.09,356.4 1130.62,358.34 1124.97,297.12 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1267.54,272.62 1273.19,333.83 1207.02,351.36 1201.37,290.15 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1109.68,299.84 1115.33,361.06 1114.81,359.29 1109.17,298.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1107.37,300.6 1113.02,361.81 1111.86,357.79 1106.21,296.58 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1116.61,303.83 1122.26,365.04 1115.33,361.06 1109.68,299.84 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1114.92,304.93 1120.57,366.15 1113.02,361.81 1107.37,300.6 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1126.04,306.01 1131.69,367.22 1122.26,365.04 1116.61,303.83 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1124.96,307.3 1130.61,368.52 1120.57,366.15 1114.92,304.93 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1135.42,307.82 1141.06,369.04 1131.69,367.22 1126.04,306.01 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1200.5,288.92 1206.15,350.14 1141.06,369.04 1135.42,307.82 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1352.37,245.58l-0.84,9.72l-19.28,4.07l-18.07,1.54l-23.32,4.25l-23.33,7.46l-66.17,17.53l-66.04,19.17
l-10.37-2.02l-10.05-2.37l-7.54-4.33l-1.17-4.02l5.94,0.21l12.58-1.02l7.13-1.66l1.74-1.57l-0.12-2.65l0.81-4.67l3.11-4.65
l4.17-2.11l4.33-1.18l5.15-2.36l4.08-3.57l3.02-3.33l4.64-1.88l7.13,1.4l6.43-1.34l16.2-14.28l8.46-5.7l11.58-2.61l33.86-0.77
l9.17-1.79l18.03-5.97l28.38-6.61l8.04-3.34l8.49-5.61l20.8-17.71l3-1.46l8.52,0.3l5.78,5.95l-11.89,10.98l0.11,10.19l6.61,0.91
l7.41,1.99l-2.48,4.96l-6.16,3.26L1352.37,245.58z M1332.03,258.01l17.14-3.63l0.73-8.65l-2.32-7.21l6.67-3.55l1.74-3.5
l-5.55-1.51l-8.03-1.1l-0.11-11.55l11.52-10.62l-4.58-4.72l-6.27-0.22l-1.99,0.97l-20.53,17.53l-8.93,5.88l-8.63,3.56l-28.36,6.6
l-18.21,6.03l-9.86,1.89l-33.84,0.76l-10.29,2.37l-7.88,5.31l-16.62,14.66l-8.15,1.7l-6.75-1.32l-2.96,1.18l-2.76,3.06l-4.6,3.95
l-5.73,2.59l-4.3,1.17l-3.17,1.64l-2.74,4.11l-0.77,4.41l0.14,3l-2.62,2.4l-8.46,1.94l-13.12,1.05l-2.69-0.09l0.52,1.77
l6.93,3.99l9.43,2.18l9.38,1.82l65.09-18.9l66.1-17.51l23.61-7.54l23.67-4.29L1332.03,258.01"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qusarColor ? qusarColor : null }}
                points="1349.24,201.97 1353.82,206.7 1342.3,217.32 1342.41,228.88 1350.44,229.97 1355.99,231.48 
1354.25,234.98 1347.58,238.52 1349.9,245.73 1349.17,254.38 1332.03,258.01 1313.88,259.58 1290.21,263.88 1266.6,271.42 
1200.5,288.92 1135.42,307.82 1126.04,306.01 1116.61,303.83 1109.68,299.84 1109.17,298.08 1111.85,298.17 1124.97,297.12 
1133.44,295.19 1136.06,292.79 1135.92,289.79 1136.69,285.38 1139.43,281.27 1142.6,279.64 1146.91,278.47 1152.63,275.88 
1157.23,271.94 1159.99,268.87 1162.94,267.69 1169.69,269.01 1177.84,267.31 1194.46,252.65 1202.34,247.35 1212.63,244.98 
1246.46,244.22 1256.32,242.33 1274.54,236.29 1302.89,229.69 1311.52,226.14 1320.45,220.26 1340.98,202.73 1342.97,201.76 			
"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1201.37,290.15 1207.02,351.36 1140.98,370.53 1135.33,309.32 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1135.33,309.32 1140.98,370.53 1130.61,368.52 1124.96,307.3 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qebele' ? 'active' : null}`}
            onClick={() => openPopup('qebele')}
          >
            <g>
              <polygon
                className="st35"
                points="1076.11,293.46 1081.76,354.67 1080.71,356.25 1075.06,295.03 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1081.95,296.03 1087.6,357.25 1081.76,354.67 1076.11,293.46 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1087.45,297.28 1093.1,358.49 1087.6,357.25 1081.95,296.03 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1075.06,295.03 1080.71,356.25 1077.93,361.57 1072.28,300.35 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1106.81,297.97 1112.46,359.18 1093.1,358.49 1087.45,297.28 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1072.28,300.35 1077.93,361.57 1078.02,364.14 1072.37,302.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1107.58,300.57 1113.23,361.79 1112.46,359.18 1106.81,297.97 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1072.37,302.92 1078.02,364.14 1077.81,366.93 1072.17,305.71 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1115.12,304.9 1120.77,366.12 1113.23,361.79 1107.58,300.57 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1072.17,305.71 1077.81,366.93 1072.79,371.83 1067.14,310.62 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1125.17,307.28 1130.82,368.49 1120.77,366.12 1115.12,304.9 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1134.4,309.07 1140.05,370.28 1130.82,368.49 1125.17,307.28 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1067.14,310.62 1072.79,371.83 1067.97,377.19 1062.32,315.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1062.32,315.97 1067.97,377.19 1055.26,386.54 1049.61,325.32 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1136.76,308.02 1142.41,369.24 1143.62,382.7 1137.97,321.48 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1134.4,309.07 1140.05,370.28 1141.2,383.44 1135.55,322.23 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1049.61,325.32 1055.26,386.54 1042.45,394.75 1036.8,333.54 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1036.8,333.54 1042.45,394.75 1041.28,399.71 1035.63,338.5 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1153.52,331.29 1159.17,392.5 1141.2,383.44 1135.55,322.23 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1035.63,338.5 1041.28,399.71 1037.48,405.12 1031.84,343.91 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1153.52,331.29 1159.17,392.5 1154.6,394.51 1148.95,333.3 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1157.33,331.25 1162.98,392.47 1156.11,395.5 1150.46,334.28 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1148.95,333.3 1154.6,394.51 1149.52,397.98 1143.87,336.77 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1150.46,334.28 1156.11,395.5 1151.44,398.7 1145.79,337.49 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1143.87,336.77 1149.52,397.98 1146.12,401.83 1140.47,340.62 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1031.84,343.91 1037.48,405.12 1023.81,413.63 1018.16,352.42 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1145.79,337.49 1151.44,398.7 1148.2,402.41 1142.55,341.2 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1134.39,342.64 1140.04,403.85 1135.49,403.11 1129.84,341.89 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1129.84,341.89 1135.49,403.11 1131.4,405.46 1125.75,344.24 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1137.19,343.29 1142.84,404.5 1140.04,403.85 1134.39,342.64 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1140.47,340.62 1146.12,401.83 1142.84,404.5 1137.19,343.29 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="1133.36,343.94 1139.01,405.15 1136.02,404.66 1130.37,343.45 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1130.37,343.45 1136.02,404.66 1133.18,406.29 1127.54,345.07 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1142.55,341.2 1148.2,402.41 1143.56,406.23 1137.91,345.01 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1137.91,345.01 1143.56,406.23 1139.01,405.15 1133.36,343.94 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1125.75,344.24 1131.4,405.46 1128.31,408.23 1122.67,347.02 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1127.54,345.07 1133.18,406.29 1130.74,408.51 1125.09,347.29 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1018.16,352.42 1023.81,413.63 1012.46,425.29 1006.81,364.08 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1122.67,347.02 1128.31,408.23 1127.88,415.64 1122.23,354.43 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1125.09,347.29 1130.74,408.51 1130.33,415.43 1124.68,354.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1125.83,361.71 1131.47,422.92 1127.88,415.64 1122.23,354.43 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1006.81,364.08 1012.46,425.29 1005.87,438.3 1000.22,377.09 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1125.83,361.71 1131.47,422.92 1127.74,428.95 1122.09,367.73 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1128.39,361.59 1134.04,422.8 1129.7,429.82 1124.05,368.61 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1108.51,370.82 1114.16,432.03 1109.4,432.63 1103.75,371.41 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1112.82,370.74 1118.46,431.95 1114.16,432.03 1108.51,370.82 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1122.09,367.73 1127.74,428.95 1118.46,431.95 1112.82,370.74 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1108.54,372.17 1114.19,433.39 1111.12,433.78 1105.47,372.57 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="994.71,382.8 1000.35,444.02 989.98,444.79 984.33,383.58 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1000.22,377.09 1005.87,438.3 1000.35,444.02 994.71,382.8 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1124.05,368.61 1129.7,429.82 1118.91,433.32 1113.26,372.1 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1113.26,372.1 1118.91,433.32 1114.19,433.39 1108.54,372.17 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1103.75,371.41 1109.4,432.63 1105.92,437.68 1100.28,376.46 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1105.47,372.57 1111.12,433.78 1108.24,437.98 1102.6,376.77 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1100.28,376.46 1105.92,437.68 1103.53,442.23 1097.88,381.01 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="984.33,383.58 989.98,444.79 986.8,453.58 981.15,392.37 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1102.6,376.77 1108.24,437.98 1105.54,443.09 1099.89,381.88 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1097.88,381.01 1103.53,442.23 1091.01,446.04 1085.36,384.83 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1085.36,384.83 1091.01,446.04 1076.85,448.2 1071.2,386.98 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1099.89,381.88 1105.54,443.09 1091.55,447.34 1085.9,386.12 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1085.9,386.12 1091.55,447.34 1077.41,449.5 1071.76,388.28 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1071.2,386.98 1076.85,448.2 1066.42,451.58 1060.77,390.37 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1071.76,388.28 1077.41,449.5 1067.68,452.67 1062.03,391.46 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="981.15,392.37 986.8,453.58 983.23,462.68 977.58,401.47 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1060.77,390.37 1066.42,451.58 1057.45,457.37 1051.8,396.15 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1062.03,391.46 1067.68,452.67 1059.11,458.26 1053.46,397.05 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="982.09,406.02 987.74,467.24 983.23,462.68 977.58,401.47 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1051.8,396.15 1057.45,457.37 1047.7,462.35 1042.05,401.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="980,406.87 985.65,468.09 980.63,463.03 974.98,401.82 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1053.46,397.05 1059.11,458.26 1049.21,463.34 1043.56,402.13 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1012.29,406.71 1017.94,467.93 1006.37,468.45 1000.72,407.23 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1000.72,407.23 1006.37,468.45 994.82,470.78 989.17,409.56 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="989.17,409.56 994.82,470.78 987.74,467.24 982.09,406.02 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1042.05,401.14 1047.7,462.35 1037.86,466.97 1032.21,405.75 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1011.95,408.09 1017.6,469.3 1006.46,469.82 1000.81,408.6 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1022.34,407.39 1027.99,468.6 1017.94,467.93 1012.29,406.71 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1032.21,405.75 1037.86,466.97 1027.99,468.6 1022.34,407.39 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1137.97,321.48l19.36,9.77l-6.87,3.03l-4.66,3.2l-3.24,3.71l-4.64,3.82l-4.55-1.07l-2.99-0.49l-2.83,1.63
l-2.45,2.22l-0.4,6.92l3.71,7.38l-4.34,7.02l-10.8,3.49l-4.72,0.07l-3.07,0.39l-2.87,4.2l-2.7,5.11l-13.99,4.25l-14.14,2.16
l-9.74,3.18l-8.57,5.59l-9.9,5.08l-10.64,4.9l-10.91,1.75l-10.06-0.69l-11.14,0.52l-12.4,2.52l-8.4-4.26l-5.02-5.06l3.77-9.58
l3.58-9.88l10.93-0.82l4.75-4.93l6.61-13.01l11.69-11.96l13.62-8.48l3.32-4.86l1.26-5.25l13.29-8.53l12.55-9.23l4.68-5.2
l4.72-4.64l0.2-2.39l-0.13-2.74l2.92-5.57l2.37-3.57l8.38,3.69l4.76,1.05l20.72,0.73l0.92,3.17l6.93,3.99l9.43,2.18l10.51,2.04
L1137.97,321.48z M1148.95,333.3l4.57-2.01l-17.96-9.06l-1.15-13.16l-9.23-1.79l-10.05-2.37l-7.54-4.33l-0.77-2.61l-19.37-0.69
l-5.49-1.25l-5.84-2.58l-1.06,1.58l-2.78,5.32l0.09,2.57l-0.21,2.79l-5.03,4.9l-4.82,5.36l-12.72,9.35l-12.81,8.22l-1.17,4.96
l-3.8,5.41l-13.68,8.51l-11.35,11.66l-6.59,13.01l-5.52,5.72l-10.38,0.78l-3.18,8.79l-3.57,9.1l4.51,4.55l7.07,3.54l11.56-2.33
l11.57-0.52l10.05,0.67l9.87-1.64l9.84-4.61l9.75-4.99l8.97-5.78l10.43-3.39l14.16-2.15l12.52-3.82l2.39-4.55l3.48-5.05
l4.76-0.59l4.3-0.08l9.28-3l3.73-6.03l-3.6-7.28l0.44-7.41l3.09-2.78l4.08-2.35l4.55,0.74l2.8,0.65l3.28-2.67l3.39-3.85
L1148.95,333.3"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qebeleColor ? qebeleColor : null }}
                points="1076.11,293.46 1081.95,296.03 1087.45,297.28 1106.81,297.97 1107.58,300.57 1115.12,304.9 
1125.17,307.28 1134.4,309.07 1135.55,322.23 1153.52,331.29 1148.95,333.3 1143.87,336.77 1140.47,340.62 1137.19,343.29 
1134.39,342.64 1129.84,341.89 1125.75,344.24 1122.67,347.02 1122.23,354.43 1125.83,361.71 1122.09,367.73 1112.82,370.74 
1108.51,370.82 1103.75,371.41 1100.28,376.46 1097.88,381.01 1085.36,384.83 1071.2,386.98 1060.77,390.37 1051.8,396.15 
1042.05,401.14 1032.21,405.75 1022.34,407.39 1012.29,406.71 1000.72,407.23 989.17,409.56 982.09,406.02 977.58,401.47 
981.15,392.37 984.33,383.58 994.71,382.8 1000.22,377.08 1006.81,364.08 1018.16,352.42 1031.84,343.91 1035.63,338.5 
1036.8,333.54 1049.61,325.32 1062.32,315.97 1067.14,310.62 1072.17,305.71 1072.37,302.92 1072.28,300.35 1075.06,295.03 			
"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1000.81,408.6 1006.46,469.82 994.05,472.34 988.4,411.13 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="988.4,411.13 994.05,472.34 985.65,468.09 980,406.87 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1043.56,402.13 1049.21,463.34 1038.57,468.24 1032.92,407.02 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1032.92,407.02 1038.57,468.24 1027.66,469.99 1022.01,408.77 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1022.01,408.77 1027.66,469.99 1017.6,469.3 1011.95,408.09 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'quba' ? 'active' : null}`}
            onClick={() => openPopup('quba')}
          >
            <g>
              <polygon
                className="st8"
                points="1332.46,259.35 1338.11,320.56 1320.04,322.1 1314.39,260.89 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1349.62,255.72 1355.27,316.93 1338.11,320.56 1332.46,259.35 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1314.39,260.89 1320.04,322.1 1296.73,326.35 1291.08,265.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1352.19,262.03 1357.84,323.24 1355.27,316.93 1349.62,255.72 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1291.08,265.14 1296.73,326.35 1273.4,333.81 1267.75,272.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1356.34,268.93 1361.99,330.15 1357.84,323.24 1352.19,262.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1358.52,275.36 1364.17,336.58 1361.99,330.15 1356.34,268.93 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1267.75,272.6 1273.4,333.81 1207.23,351.34 1201.58,290.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1361.02,281.78 1366.67,343 1364.17,336.58 1358.52,275.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1364.29,286.47 1369.94,347.68 1366.67,343 1361.02,281.78 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1379.33,285.36 1384.98,346.57 1378.42,347.26 1372.77,286.05 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1372.77,286.05 1378.42,347.26 1369.94,347.68 1364.29,286.47 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1201.58,290.13 1207.23,351.34 1142.5,370.14 1136.85,308.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1382.81,288 1388.46,349.21 1384.98,346.57 1379.33,285.36 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1382.81,288 1388.46,349.21 1381.43,357.37 1375.78,296.16 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1385.63,287.72 1391.28,348.94 1383.68,357.79 1378.03,296.57 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1136.85,308.93 1142.5,370.14 1143.62,382.7 1137.97,321.49 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1375.78,296.16 1381.43,357.37 1377.32,366.44 1371.68,305.23 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1378.03,296.57 1383.68,357.79 1379.92,366.16 1374.27,304.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1374.22,308.22 1379.86,369.43 1377.32,366.44 1371.68,305.23 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1156.17,330.67 1161.81,391.88 1143.62,382.7 1137.97,321.49 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1374.22,308.22 1379.86,369.43 1378.69,372.06 1373.04,310.84 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1154.54,331.82 1160.19,393.03 1141.2,383.45 1135.56,322.23 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1376.8,307.92 1382.45,369.13 1380.84,372.72 1375.19,311.5 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1373.04,310.84 1378.69,372.06 1374.25,374.26 1368.6,313.04 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1163.77,332.43 1169.42,393.65 1161.81,391.88 1156.17,330.67 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1375.19,311.5 1380.84,372.72 1375.71,375.27 1370.06,314.05 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1162.77,333.75 1168.42,394.96 1160.19,393.03 1154.54,331.82 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1368.6,313.04 1374.25,374.26 1369.46,376.45 1363.82,315.24 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1172.16,333.65 1177.81,394.87 1169.42,393.65 1163.77,332.43 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1370.06,314.05 1375.71,375.27 1370.7,377.54 1365.05,316.33 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1170.81,334.87 1176.46,396.09 1168.42,394.96 1162.77,333.75 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1179.22,336.28 1184.87,397.5 1177.81,394.87 1172.16,333.65 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1363.82,315.24 1369.46,376.45 1351.1,382.67 1345.45,321.45 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="1177.58,337.4 1183.23,398.62 1176.46,396.09 1170.81,334.87 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1365.05,316.33 1370.7,377.54 1352.7,383.66 1347.05,322.45 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="1185.97,339.57 1191.62,400.78 1184.87,397.5 1179.22,336.28 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1345.45,321.45 1351.1,382.67 1338.12,392.85 1332.48,331.63 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1347.05,322.45 1352.7,383.66 1341.06,392.78 1335.41,331.57 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="1198.59,345.89 1204.24,407.1 1191.62,400.78 1185.97,339.57 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1196.92,346.99 1202.56,408.2 1183.23,398.62 1177.58,337.4 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1211.66,351.2 1217.31,412.42 1204.24,407.1 1198.59,345.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1341.46,339.81 1347.11,401.03 1338.12,392.85 1332.48,331.63 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1210.25,352.42 1215.89,413.63 1202.56,408.2 1196.92,346.99 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1232.65,353.96 1238.3,415.17 1217.31,412.42 1211.66,351.2 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1231.04,355.19 1236.69,416.41 1215.89,413.63 1210.25,352.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1347.72,348.04 1353.37,409.26 1347.11,401.03 1341.46,339.81 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1347.72,348.04 1353.37,409.26 1347.72,413.88 1342.08,352.66 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1350.51,348.05 1356.16,409.27 1349.28,414.91 1343.63,353.69 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1342.08,352.66 1347.72,413.88 1339.38,416.32 1333.73,355.11 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1245.12,363.82 1250.77,425.04 1238.3,415.17 1232.65,353.96 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1343.63,353.69 1349.28,414.91 1340.5,417.47 1334.85,356.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1243.57,365.1 1249.21,426.32 1236.69,416.41 1231.04,355.19 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1333.73,355.11 1339.38,416.32 1330.91,420.95 1325.26,359.74 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1334.85,356.25 1340.5,417.47 1333.56,421.3 1327.91,360.08 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1267.63,365.94 1273.28,427.16 1250.77,425.04 1245.12,363.82 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1266.51,367.24 1272.16,428.45 1249.21,426.32 1243.57,365.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1327.09,365.53 1332.74,426.75 1330.91,420.95 1325.26,359.74 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1287.83,371.49 1293.48,432.7 1273.28,427.16 1267.63,365.94 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1286.44,372.69 1292.09,433.91 1272.16,428.45 1266.51,367.24 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1327.09,365.53 1332.74,426.75 1330.51,434.67 1324.86,373.45 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1329.56,365.48 1335.21,426.7 1332.89,434.85 1327.24,373.63 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1304.05,377.56 1309.7,438.78 1293.48,432.7 1287.83,371.49 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="1302.66,378.77 1308.31,439.98 1292.09,433.91 1286.44,372.69 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1324.86,373.45 1330.51,434.67 1325.56,443.17 1319.91,381.96 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1319.91,381.96 1325.56,443.17 1309.7,438.78 1304.05,377.56 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1365.05,316.33l-18.01,6.12l-11.63,9.12l8.26,7.51l6.84,8.98l-6.88,5.64l-8.78,2.56l-6.94,3.83l1.64,5.4
l-2.32,8.15l-6,10.3l-18.58-5.17l-16.22-6.07l-19.93-5.46l-22.94-2.13l-12.52-9.91l-20.8-2.78l-13.33-5.43l-19.33-9.59
l-6.77-2.53l-8.04-1.12l-8.23-1.93l-18.98-9.58l-1.24-14.05l66.4-19.28l66.1-17.51l23.61-7.54l23.67-4.29l18.15-1.58l19.24-4.07
l3.07,7.6l4.19,6.92l2.21,6.51l2.44,6.31l2.65,3.75l6.7-0.32l7.85-0.83l5.06,3.86l-7.6,8.85l-3.75,8.37l2.52,2.98l-1.6,3.58
l-5.13,2.55L1365.05,316.33z M1332.48,331.63l12.98-10.18l18.36-6.21l4.79-2.2l4.44-2.2l1.17-2.63l-2.54-2.99l4.05-9l7.09-8.23
l-3.48-2.64l-6.56,0.69l-8.48,0.42l-3.27-4.69l-2.5-6.42l-2.18-6.43l-4.15-6.91l-2.57-6.31l-17.16,3.63l-18.07,1.54l-23.32,4.25
l-23.33,7.46l-66.17,17.53l-64.73,18.8l1.13,12.56l18.19,9.18l7.6,1.76l8.39,1.22l7.06,2.63l6.75,3.28l12.62,6.32l13.07,5.31
l20.99,2.75l12.48,9.87l22.51,2.12l20.2,5.54l16.22,6.07l15.86,4.4l4.95-8.51l2.23-7.92l-1.83-5.8l8.47-4.63l8.35-2.45l5.65-4.62
l-6.26-8.23L1332.48,331.63"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qubaColor ? qubaColor : null }}
                points="1349.62,255.72 1352.19,262.03 1356.34,268.93 1358.52,275.36 1361.02,281.78 1364.29,286.47 
1372.77,286.05 1379.33,285.36 1382.81,288 1375.78,296.16 1371.68,305.23 1374.22,308.22 1373.04,310.84 1368.6,313.04 
1363.82,315.24 1345.45,321.45 1332.48,331.63 1341.46,339.81 1347.72,348.04 1342.08,352.66 1333.73,355.11 1325.26,359.74 
1327.09,365.53 1324.86,373.45 1319.91,381.96 1304.05,377.56 1287.83,371.49 1267.63,365.94 1245.12,363.82 1232.65,353.96 
1211.66,351.2 1198.59,345.89 1185.97,339.57 1179.22,336.28 1172.16,333.65 1163.77,332.43 1156.17,330.67 1137.97,321.49 
1136.85,308.93 1201.58,290.13 1267.75,272.6 1291.08,265.14 1314.39,260.89 1332.46,259.35 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1327.24,373.63 1332.89,434.85 1326.89,445.15 1321.24,383.94 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1321.24,383.94 1326.89,445.15 1308.31,439.98 1302.66,378.77 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'ismayilli' ? 'active' : null}`}
            onClick={() => openPopup('ismayilli')}
          >
            <g>
              <polygon
                className="st31"
                points="1155.48,332.06 1161.13,393.27 1156.11,395.5 1150.46,334.28 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1162.77,333.74 1168.42,394.96 1161.13,393.27 1155.48,332.06 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1170.81,334.86 1176.46,396.08 1168.42,394.96 1162.77,333.74 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1150.46,334.28 1156.11,395.5 1151.44,398.7 1145.79,337.48 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="1177.58,337.4 1183.23,398.61 1176.46,396.08 1170.81,334.86 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1145.79,337.48 1151.44,398.7 1148.2,402.41 1142.56,341.19 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="1133.36,343.94 1139.01,405.15 1136.02,404.66 1130.37,343.44 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1130.37,343.44 1136.02,404.66 1133.19,406.29 1127.54,345.07 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1137.91,345.01 1143.56,406.22 1139.01,405.15 1133.36,343.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1142.56,341.19 1148.2,402.41 1143.56,406.22 1137.91,345.01 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1127.54,345.07 1133.19,406.29 1130.74,408.51 1125.09,347.29 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1196.91,346.98 1202.56,408.2 1183.23,398.61 1177.58,337.4 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1125.09,347.29 1130.74,408.51 1130.33,415.42 1124.69,354.21 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1210.24,352.41 1215.89,413.62 1202.56,408.2 1196.91,346.98 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1128.39,361.59 1134.04,422.8 1130.33,415.42 1124.69,354.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1125.83,361.71 1131.48,422.92 1127.88,415.64 1122.23,354.43 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1231.04,355.19 1236.69,416.4 1215.89,413.62 1210.24,352.41 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1128.39,361.59 1134.04,422.8 1129.7,429.82 1124.06,368.61 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1108.54,372.17 1114.19,433.39 1111.12,433.78 1105.47,372.57 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1113.26,372.1 1118.91,433.32 1114.19,433.39 1108.54,372.17 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1124.06,368.61 1129.7,429.82 1118.91,433.32 1113.26,372.1 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1105.47,372.57 1111.12,433.78 1108.25,437.98 1102.6,376.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1243.25,364.82 1248.9,426.04 1236.69,416.4 1231.04,355.19 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1102.6,376.77 1108.25,437.98 1105.55,443.09 1099.9,381.88 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1099.9,381.88 1105.55,443.09 1091.55,447.34 1085.9,386.12 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1085.9,386.12 1091.55,447.34 1077.41,449.49 1071.77,388.28 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1071.77,388.28 1077.41,449.49 1067.68,452.67 1062.03,391.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1251.74,374.15 1257.39,435.37 1248.9,426.04 1243.25,364.82 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1062.03,391.46 1067.68,452.67 1059.11,458.26 1053.46,397.05 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1263.52,381.32 1269.16,442.53 1257.39,435.37 1251.74,374.15 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1053.46,397.05 1059.11,458.26 1049.21,463.34 1043.56,402.13 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1043.56,402.13 1049.21,463.34 1040.57,467.38 1034.92,406.17 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1042.28,408.12 1047.93,469.34 1040.57,467.38 1034.92,406.17 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1040.96,409.36 1046.61,470.57 1036.12,467.78 1030.47,406.57 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1263.52,381.32 1269.16,442.53 1266.68,451.59 1261.04,390.37 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1266.11,380.75 1271.76,441.97 1269,452.08 1263.35,390.87 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="1057.29,412.8 1062.94,474.02 1047.93,469.34 1042.28,408.12 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1064.2,413.08 1069.85,474.29 1062.94,474.02 1057.29,412.8 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1056.36,414.16 1062.01,475.38 1046.61,470.57 1040.96,409.36 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1063.54,414.43 1069.19,475.65 1062.01,475.38 1056.36,414.16 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1261.04,390.37 1266.68,451.59 1253.79,459.85 1248.14,398.63 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1263.35,390.87 1269,452.08 1255.65,460.62 1250,399.41 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="1078.38,415.26 1084.02,476.48 1069.85,474.29 1064.2,413.08 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1077.13,416.53 1082.78,477.74 1069.19,475.65 1063.54,414.43 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1091.34,419.2 1096.99,480.42 1084.02,476.48 1078.38,415.26 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1090.05,420.44 1095.7,481.65 1082.78,477.74 1077.13,416.53 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1248.14,398.63 1253.79,459.85 1243.4,469.87 1237.75,408.66 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1250,399.41 1255.65,460.62 1245.25,470.66 1239.6,409.44 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="1105.47,422.95 1111.11,484.16 1096.99,480.42 1091.34,419.2 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1103.61,423.97 1109.25,485.19 1095.7,481.65 1090.05,420.44 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1237.75,408.66 1243.4,469.87 1230.92,477.72 1225.27,416.51 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1239.6,409.44 1245.25,470.66 1232.07,478.87 1226.42,417.65 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1115.14,430.1 1120.79,491.31 1111.11,484.16 1105.47,422.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1112.09,430.25 1117.74,491.47 1109.25,485.19 1103.61,423.97 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1225.27,416.51 1230.92,477.72 1211.25,483.32 1205.6,422.11 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1226.42,417.65 1232.07,478.87 1211.94,484.58 1206.29,423.36 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1205.6,422.11 1211.25,483.32 1190.65,486.76 1185,425.55 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1185,425.55 1190.65,486.76 1185.3,487.59 1179.65,426.38 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1179.65,426.38 1185.3,487.59 1179.98,488.56 1174.34,427.34 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1206.29,423.36 1211.94,484.58 1185.75,488.91 1180.1,427.69 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1180.1,427.69 1185.75,488.91 1180.79,489.81 1175.14,428.6 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1174.34,427.34 1179.98,488.56 1176.18,490.37 1170.53,429.15 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1175.14,428.6 1180.79,489.81 1177.83,491.28 1172.19,430.06 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1170.53,429.15 1176.18,490.37 1173.79,492.26 1168.15,431.05 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1172.19,430.06 1177.83,491.28 1175.32,493.31 1169.67,432.1 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1168.15,431.05 1173.79,492.26 1166.42,494.25 1160.77,433.03 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1160.77,433.03 1166.42,494.25 1158.03,496.04 1152.38,434.82 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1115.14,430.1 1120.79,491.31 1108.92,500.69 1103.27,439.48 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1169.67,432.1 1175.32,493.31 1167.16,495.5 1161.51,434.28 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1161.51,434.28 1167.16,495.5 1158.82,497.27 1153.17,436.05 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1152.38,434.82 1158.03,496.04 1150.93,498.22 1145.29,437.01 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1153.17,436.05 1158.82,497.27 1152.12,499.33 1146.47,438.12 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1145.29,437.01 1150.93,498.22 1144.21,501.22 1138.57,440 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1146.47,438.12 1152.12,499.33 1146.13,502.01 1140.48,440.8 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1103.27,439.48 1108.92,500.69 1106.34,508.33 1100.69,447.11 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1124.41,445.02 1130.06,506.23 1117.13,507.8 1111.48,446.59 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1111.48,446.59 1117.13,507.8 1106.34,508.33 1100.69,447.11 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1123.42,446.51 1129.06,507.72 1117.22,509.16 1111.57,447.95 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1111.57,447.95 1117.22,509.16 1103.36,509.84 1097.71,448.62 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1138.57,440 1144.21,501.22 1139.27,507.16 1133.63,445.95 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1133.63,445.95 1139.27,507.16 1134.25,508.63 1128.61,447.41 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1128.61,447.41 1134.25,508.63 1130.06,506.23 1124.41,445.02 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1266.11,380.75l-2.76,10.12l-13.36,8.54l-10.4,10.03l-13.18,8.21l-20.12,5.71l-26.2,4.33l-4.96,0.91
l-2.96,1.46l-2.52,2.04l-8.16,2.18l-8.34,1.77l-6.7,2.07l-5.99,2.68l-5.06,6.11l-7.49,2.18l-4.51-2.58l-11.85,1.44l-13.86,0.68
l3.28-9.57l11.1-8.8l-8.49-6.28l-13.56-3.53l-12.91-3.91l-13.59-2.1l-7.18-0.27l-15.4-4.8l-10.49-2.79l11.58-5.43l9.75-4.99
l8.97-5.78l10.43-3.39l14.16-2.15l12.52-3.82l2.39-4.55l3.48-5.05l4.76-0.59l4.3-0.08l9.28-3l3.73-6.03l-3.6-7.28l0.44-7.41
l3.09-2.78l4.08-2.35l4.55,0.74l2.8,0.65l3.28-2.67l3.39-3.85l5.08-3.47l6.39-2.82l8.42,1.95l8.39,1.22l7.06,2.63l6.75,3.28
l12.62,6.32l13.07,5.31l20.99,2.75l12.74,10.07l8.49,9.33L1266.11,380.75z M1248.14,398.63l12.89-8.26l2.48-9.06l-11.78-7.17
l-8.49-9.33l-12.21-9.64l-20.8-2.78l-13.33-5.43l-19.33-9.59l-6.77-2.53l-8.04-1.12l-7.28-1.68l-5.03,2.23l-4.66,3.2l-3.24,3.71
l-4.64,3.82l-4.55-1.07l-2.99-0.49l-2.83,1.63l-2.45,2.22l-0.4,6.92l3.71,7.38l-4.34,7.02l-10.8,3.49l-4.72,0.07l-3.07,0.39
l-2.87,4.2l-2.7,5.11l-13.99,4.25l-14.14,2.16l-9.74,3.18l-8.57,5.59l-9.9,5.08l-8.63,4.04l7.36,1.96l15.01,4.68l6.91,0.27
l14.17,2.18l12.97,3.94l14.12,3.75l9.68,7.15l-11.87,9.38l-2.58,7.63l10.79-0.52l12.93-1.57l4.2,2.4l5.02-1.46l4.94-5.95l6.72-3
l7.09-2.19l8.39-1.79l7.37-1.98l2.38-1.9l3.81-1.81l5.32-0.96l5.34-0.83l20.6-3.44l19.67-5.6l12.48-7.85L1248.14,398.63"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: ismayilliColor ? ismayilliColor : null }}
                points="1155.48,332.06 1162.77,333.74 1170.81,334.86 1177.58,337.4 1196.91,346.98 1210.24,352.41 
1231.04,355.19 1243.25,364.82 1251.74,374.15 1263.52,381.32 1261.04,390.37 1248.14,398.63 1237.75,408.66 1225.27,416.51 
1205.6,422.11 1185,425.55 1179.65,426.38 1174.34,427.34 1170.53,429.15 1168.15,431.05 1160.77,433.03 1152.38,434.82 
1145.29,437.01 1138.57,440 1133.63,445.95 1128.61,447.41 1124.41,445.02 1111.48,446.59 1100.69,447.11 1103.27,439.48 
1115.14,430.1 1105.47,422.95 1091.34,419.2 1078.38,415.26 1064.2,413.08 1057.29,412.8 1042.28,408.12 1034.92,406.17 
1043.56,402.13 1053.46,397.05 1062.03,391.46 1071.77,388.28 1085.9,386.12 1099.9,381.88 1102.6,376.77 1105.47,372.57 
1108.54,372.17 1113.26,372.1 1124.06,368.61 1128.39,361.59 1124.69,354.21 1125.09,347.29 1127.54,345.07 1130.37,343.44 
1133.36,343.94 1137.91,345.01 1142.56,341.19 1145.79,337.48 1150.46,334.28 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1140.48,440.8 1146.13,502.01 1141.06,508.12 1135.41,446.91 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1135.41,446.91 1141.06,508.12 1133.57,510.3 1127.93,449.09 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1127.93,449.09 1133.57,510.3 1129.06,507.72 1123.42,446.51 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'goycay' ? 'active' : null}`}
            onClick={() => openPopup('goycay')}
          >
            <g>
              <polygon
                className="st98"
                points="1011.95,408.08 1017.6,469.3 1006.46,469.82 1000.81,408.6 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1000.81,408.6 1006.46,469.82 996.19,471.92 990.54,410.7 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1022.02,408.77 1027.66,469.99 1017.6,469.3 1011.95,408.08 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1032.46,407.1 1038.11,468.31 1027.66,469.99 1022.02,408.77 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="993.04,413.53 998.69,474.75 996.19,471.92 990.54,410.7 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1040.96,409.36 1046.61,470.57 1038.11,468.31 1032.46,407.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="990.78,414.19 996.43,475.41 992.7,471.22 987.05,410 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="992.91,417.38 998.56,478.59 996.43,475.41 990.78,414.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="995.63,417.36 1001.28,478.57 998.69,474.75 993.04,413.53 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1056.36,414.16 1062.01,475.38 1046.61,470.57 1040.96,409.36 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="995.63,417.36 1001.28,478.57 998.48,481.3 992.83,420.09 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1063.54,414.43 1069.19,475.65 1062.01,475.38 1056.36,414.16 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="992.83,420.09 998.48,481.3 994.13,483.09 988.48,421.87 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1077.13,416.53 1082.78,477.74 1069.19,475.65 1063.54,414.43 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="988.48,421.87 994.13,483.09 987.89,488.91 982.25,427.69 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1090.05,420.44 1095.7,481.65 1082.78,477.74 1077.13,416.53 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="982.25,427.69 987.89,488.91 983.75,495.51 978.11,434.29 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1103.61,423.97 1109.25,485.19 1095.7,481.65 1090.05,420.44 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="981.02,437.62 986.67,498.84 983.75,495.51 978.11,434.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="978.85,438.4 984.5,499.62 981.09,495.74 975.45,434.52 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="997.78,439.27 1003.43,500.48 990.87,501.73 985.22,440.52 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="985.22,440.52 990.87,501.73 986.67,498.84 981.02,437.62 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="996.84,440.73 1002.49,501.94 989.7,503.22 984.05,442.01 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="984.05,442.01 989.7,503.22 984.5,499.62 978.85,438.4 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1112.09,430.25 1117.74,491.47 1109.25,485.19 1103.61,423.97 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="1004.4,442.45 1010.05,503.66 1003.43,500.48 997.78,439.27 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1002.54,443.46 1008.19,504.68 1002.49,501.94 996.84,440.73 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1009.79,446.26 1015.44,507.48 1010.05,503.66 1004.4,442.45 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1007.92,447.26 1013.57,508.47 1008.19,504.68 1002.54,443.46 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1112.09,430.25 1117.74,491.47 1106.64,500.27 1100.99,439.05 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1115.14,430.1 1120.79,491.31 1108.92,500.69 1103.27,439.48 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1026.87,445.31 1032.52,506.52 1026.48,508.48 1020.84,447.27 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1033.29,446.23 1038.94,507.44 1032.52,506.52 1026.87,445.31 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1020.84,447.27 1026.48,508.48 1020.78,509.51 1015.13,448.3 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1015.13,448.3 1020.78,509.51 1015.44,507.48 1009.79,446.26 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1031.84,447.43 1037.49,508.64 1032.64,507.99 1027,446.77 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1027,446.77 1032.64,507.99 1027.12,509.76 1021.47,448.55 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1021.47,448.55 1027.12,509.76 1020.15,511.03 1014.5,449.81 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1014.5,449.81 1020.15,511.03 1013.57,508.47 1007.92,447.26 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1100.99,439.05 1106.64,500.27 1103.65,509.03 1098,447.82 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1103.27,439.48 1108.92,500.69 1106.05,509.12 1100.4,447.9 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="1049.6,453.53 1055.25,514.75 1038.94,507.44 1033.29,446.23 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1047.97,454.67 1053.62,515.88 1037.49,508.64 1031.84,447.43 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1058.49,457.13 1064.13,518.35 1055.25,514.75 1049.6,453.53 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1057.03,458.34 1062.67,519.56 1053.62,515.88 1047.97,454.67 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1068.69,458.88 1074.34,520.09 1064.13,518.35 1058.49,457.13 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1076.41,459.04 1082.06,520.26 1074.34,520.09 1068.69,458.88 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1068.02,460.25 1073.67,521.46 1062.67,519.56 1057.03,458.34 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1075.43,460.41 1081.08,521.63 1073.67,521.46 1068.02,460.25 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1098,447.82 1103.65,509.03 1100.61,519.98 1094.96,458.77 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1100.4,447.9 1106.05,509.12 1102.79,520.95 1097.15,459.73 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1094.96,458.77 1100.61,519.98 1088.29,522.51 1082.64,461.3 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: goycayColor ? goycayColor : null }}
                points="1032.46,407.1 1040.96,409.36 1056.36,414.16 1063.54,414.43 1077.13,416.53 1090.05,420.44 
1103.61,423.97 1112.09,430.25 1100.99,439.05 1098,447.82 1094.96,458.77 1082.64,461.3 1076.41,459.04 1068.69,458.88 
1058.49,457.13 1049.6,453.53 1033.29,446.23 1026.87,445.31 1020.84,447.27 1015.13,448.3 1009.79,446.26 1004.4,442.45 
997.78,439.27 985.22,440.52 981.02,437.62 978.11,434.29 982.25,427.69 988.48,421.87 992.83,420.08 995.63,417.36 
993.04,413.53 990.54,410.7 1000.81,408.6 1011.95,408.08 1022.02,408.77 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1082.64,461.3 1088.29,522.51 1082.06,520.26 1076.41,459.04 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1103.27,439.48l-2.87,8.42l-3.26,11.83l-15.04,3.08l-6.68-2.41l-7.4-0.16l-11-1.91l-9.05-3.67l-16.14-7.24
l-4.84-0.65l-5.52,1.77l-6.97,1.27l-6.59-2.55l-5.38-3.8l-5.7-2.73l-12.79,1.28l-5.2-3.61l-3.4-3.88l4.68-7.36l6.82-6.27
l4.09-1.7l1.88-1.83l-2.14-3.18l-3.73-4.19l13.67-2.77l11.57-0.52l10.05,0.67l10.59-1.75l9.35,2.49l15.01,4.68l6.91,0.27
l14.17,2.18l12.97,3.94l14.12,3.75l9.68,7.15L1103.27,439.48z M1082.64,461.3l12.32-2.53l3.04-10.95l2.99-8.77l11.1-8.8
l-8.49-6.28l-13.56-3.53l-12.91-3.91l-13.59-2.1l-7.18-0.27l-15.4-4.8l-8.5-2.26l-10.45,1.68l-10.06-0.69l-11.14,0.52l-10.27,2.1
l2.5,2.83l2.59,3.82l-2.8,2.73l-4.35,1.79l-6.23,5.82l-4.14,6.6l2.91,3.33l4.2,2.89l12.55-1.25l6.62,3.18l5.39,3.81l5.34,2.04
l5.7-1.03l6.03-1.96l6.42,0.92l16.3,7.31l8.89,3.6l10.21,1.75l7.71,0.16L1082.64,461.3"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1097.15,459.73 1102.79,520.95 1087.75,524.03 1082.1,462.82 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1082.1,462.82 1087.75,524.03 1081.08,521.63 1075.43,460.41 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'ucar' ? 'active' : null}`}
            onClick={() => openPopup('ucar')}
          >
            <g>
              <polygon
                className="st51"
                points="966.42,432.15 972.07,493.37 961.7,493.13 956.05,431.92 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="956.05,431.92 961.7,493.13 952.71,495.38 947.07,434.17 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="975.8,434.94 981.45,496.15 972.07,493.37 966.42,432.15 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="978.85,438.4 984.5,499.62 981.45,496.15 975.8,434.94 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="996.84,440.73 1002.49,501.95 989.7,503.22 984.05,442.01 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="984.05,442.01 989.7,503.22 984.5,499.62 978.85,438.4 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="947.07,434.17 952.71,495.38 942.35,508.39 936.7,447.18 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1002.54,443.46 1008.19,504.68 1002.49,501.95 996.84,440.73 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="938.13,452.42 943.78,513.63 942.35,508.39 936.7,447.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="935.82,453.22 941.47,514.44 939.82,508.35 934.17,447.13 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1007.92,447.26 1013.57,508.48 1008.19,504.68 1002.54,443.46 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1031.84,447.43 1037.48,508.64 1032.64,507.99 1027,446.77 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1027,446.77 1032.64,507.99 1027.12,509.76 1021.47,448.55 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="944.75,455.66 950.4,516.88 943.78,513.63 938.13,452.42 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1021.47,448.55 1027.12,509.76 1020.15,511.03 1014.5,449.81 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1014.5,449.81 1020.15,511.03 1013.57,508.48 1007.92,447.26 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="942.48,456.47 948.13,517.69 941.47,514.44 935.82,453.22 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="946.71,460.9 952.36,522.11 950.4,516.88 944.75,455.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="944.44,461.67 950.09,522.88 948.13,517.69 942.48,456.47 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1047.97,454.67 1053.62,515.88 1037.48,508.64 1031.84,447.43 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1057.02,458.34 1062.67,519.56 1053.62,515.88 1047.97,454.67 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="959.85,468.48 965.5,529.69 952.36,522.11 946.71,460.9 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="957.96,469.47 963.61,530.69 950.09,522.88 944.44,461.67 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1068.02,460.25 1073.67,521.46 1062.67,519.56 1057.02,458.34 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1075.43,460.41 1081.08,521.63 1073.67,521.46 1068.02,460.25 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1080.91,462.38 1086.56,523.6 1081.08,521.63 1075.43,460.41 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="972.58,477.09 978.23,538.31 965.5,529.69 959.85,468.48 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="970.31,477.76 975.96,538.97 963.61,530.69 957.96,469.47 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1080.91,462.38 1086.56,523.6 1081.35,531.66 1075.7,470.45 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1083.86,461.72 1089.51,522.94 1083.61,532.05 1077.96,470.84 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="975.27,481.5 980.92,542.71 978.23,538.31 972.58,477.09 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="972.97,482.1 978.62,543.31 975.96,538.97 970.31,477.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="977.81,484.96 983.46,546.17 980.92,542.71 975.27,481.5 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="984.19,484.99 989.84,546.21 983.46,546.17 977.81,484.96 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="992.3,483.01 997.94,544.23 989.84,546.21 984.19,484.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="976.06,486.32 981.71,547.54 978.62,543.31 972.97,482.1 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1006.86,483.94 1012.51,545.16 997.94,544.23 992.3,483.01 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1075.7,470.45 1081.35,531.66 1075,539.67 1069.35,478.45 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="992.41,484.42 998.05,545.64 989.81,547.58 984.16,486.37 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="984.16,486.37 989.81,547.58 981.71,547.54 976.06,486.32 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1077.96,470.84 1083.61,532.05 1076.91,540.41 1071.26,479.19 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1006.11,485.29 1011.76,546.51 998.05,545.64 992.41,484.42 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1021.07,486.33 1026.72,547.55 1012.51,545.16 1006.86,483.94 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1069.35,478.45 1075,539.67 1067.26,544.68 1061.61,483.46 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1020.44,487.71 1026.09,548.92 1011.76,546.51 1006.11,485.29 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1037.15,486.23 1042.79,547.45 1026.72,547.55 1021.07,486.33 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1071.26,479.19 1076.91,540.41 1068.68,545.71 1063.03,484.49 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1036.79,487.61 1042.43,548.82 1026.09,548.92 1020.44,487.71 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1061.61,483.46 1067.26,544.68 1058.64,548.21 1052.99,486.99 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1052.99,486.99 1058.64,548.21 1042.79,547.45 1037.15,486.23 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1076.41,459.04l7.46,2.68l-5.9,9.11l-6.7,8.36l-8.23,5.3l-9.58,3.92l-16.67-0.81l-16.34,0.1l-14.33-2.41
l-13.71-0.87l-8.24,1.94l-8.1-0.05l-3.09-4.22l-2.66-4.34l-12.36-8.29l-13.52-7.8l-1.96-5.2l-6.66-3.25l-1.65-6.09l11.12-13.96
l10.55-2.64l11.47,0.25l10.44,3.09l3.27,3.74l4.2,2.89l12.55-1.25l6.62,3.18l5.39,3.81l5.34,2.04l5.7-1.03l6.03-1.96l6.43,0.92
l16.3,7.31l8.89,3.6l10.21,1.75L1076.41,459.04z M1075.7,470.45l5.21-8.06l-5.48-1.97l-7.4-0.16l-11-1.91l-9.05-3.67l-16.14-7.24
l-4.84-0.65l-5.52,1.77l-6.97,1.27l-6.59-2.55l-5.38-3.8l-5.7-2.73l-12.79,1.28l-5.2-3.61l-3.05-3.47l-9.38-2.79l-10.37-0.23
l-8.99,2.25l-10.36,13.01l1.43,5.24l6.61,3.24l1.97,5.24l13.14,7.58l12.73,8.61l2.69,4.41l2.54,3.46l6.38,0.03l8.11-1.98
l14.57,0.93l14.21,2.39l16.07-0.1l15.85,0.76l8.62-3.53l7.74-5.01L1075.7,470.45"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: ucarColor ? ucarColor : null }}
                points="966.42,432.15 975.8,434.94 978.85,438.4 984.05,442.01 996.84,440.73 1002.54,443.46 
1007.92,447.26 1014.5,449.81 1021.47,448.55 1027,446.77 1031.84,447.43 1047.97,454.67 1057.02,458.34 1068.02,460.25 
1075.43,460.41 1080.91,462.38 1075.7,470.45 1069.35,478.45 1061.61,483.46 1052.99,486.99 1037.15,486.23 1021.07,486.33 
1006.86,483.94 992.3,483.01 984.19,484.99 977.81,484.96 975.27,481.5 972.58,477.09 959.85,468.48 946.71,460.9 944.75,455.66 
938.13,452.42 936.7,447.18 947.07,434.17 956.05,431.92 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1053.45,488.41 1059.1,549.63 1042.43,548.82 1036.79,487.61 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1063.03,484.49 1068.68,545.71 1059.1,549.63 1053.45,488.41 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'zerdab' ? 'active' : null}`}
            onClick={() => openPopup('zerdab')}
          >
            <g>
              <polygon
                className="st31"
                points="945.66,462.38 951.31,523.59 945.69,528.2 940.04,466.99 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="957.96,469.47 963.61,530.69 951.31,523.59 945.66,462.38 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="940.04,466.99 945.69,528.2 938.6,533.38 932.95,472.17 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="932.95,472.17 938.6,533.38 926.91,538.31 921.26,477.09 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="921.26,477.09 926.91,538.31 921,540.25 915.35,479.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="912.94,479.47 918.59,540.69 918.09,539.69 912.44,478.47 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="915.5,479.33 921.15,540.54 921,540.25 915.35,479.03 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="915.5,479.33 921.15,540.54 920.75,541.29 915.1,480.07 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="970.31,477.76 975.96,538.97 963.61,530.69 957.96,469.47 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="937.48,483.73 943.13,544.94 920.75,541.29 915.1,480.07 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="932.7,484.42 938.34,545.63 917.78,542.28 912.13,481.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="972.97,482.1 978.62,543.31 975.96,538.97 970.31,477.76 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="937.48,483.73 943.13,544.94 938.21,547.46 932.57,486.24 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="932.57,486.24 938.21,547.46 932.29,548.3 926.64,487.09 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="926.64,487.09 932.29,548.3 929.74,549.23 924.09,488.01 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="924.09,488.01 929.74,549.23 927.98,550.47 922.33,489.25 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="922.38,489.53 928.02,550.75 927.98,550.47 922.33,489.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="920,490.23 925.65,551.45 925.44,550.24 919.79,489.03 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="923.52,490.3 929.17,551.51 928.02,550.75 922.38,489.53 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="921.29,491.02 926.94,552.24 925.65,551.45 920,490.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="976.06,486.32 981.71,547.54 978.62,543.31 972.97,482.1 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="924.5,491.71 930.15,552.93 929.17,551.51 923.52,490.3 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="992.4,484.42 998.05,545.64 989.81,547.58 984.16,486.37 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="984.16,486.37 989.81,547.58 981.71,547.54 976.06,486.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="922.15,492.22 927.8,553.43 926.94,552.24 921.29,491.02 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1006.11,485.29 1011.76,546.51 998.05,545.64 992.4,484.42 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1020.44,487.71 1026.09,548.92 1011.76,546.51 1006.11,485.29 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1036.79,487.61 1042.43,548.82 1026.09,548.92 1020.44,487.71 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="926.98,499.38 932.63,560.59 930.15,552.93 924.5,491.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="924.59,499.83 930.24,561.05 927.8,553.43 922.15,492.22 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1051.67,488.32 1057.32,549.54 1042.43,548.82 1036.79,487.61 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="946.7,503.17 952.35,564.38 945.39,565.92 939.74,504.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="930.71,506.23 936.36,567.45 932.63,560.59 926.98,499.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="928.3,506.65 933.95,567.86 930.24,561.05 924.59,499.83 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="953.96,504.35 959.6,565.57 952.35,564.38 946.7,503.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="946.55,504.62 952.2,565.84 946.23,567.14 940.58,505.92 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="939.74,504.7 945.39,565.92 939.79,567.76 934.14,506.55 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="930.83,506.87 936.48,568.08 936.36,567.45 930.71,506.23 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="934.14,506.55 939.79,567.76 936.48,568.08 930.83,506.87 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="952.34,505.56 957.99,566.78 952.2,565.84 946.55,504.62 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="940.58,505.92 946.23,567.14 940.18,569.08 934.54,507.87 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="934.54,507.87 940.18,569.08 934.29,569.68 928.64,508.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="928.64,508.46 934.29,569.68 933.95,567.86 928.3,506.65 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="956.16,506.02 961.81,567.24 959.6,565.57 953.96,504.35 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="953.78,506.66 959.43,567.87 957.99,566.78 952.34,505.56 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="984.72,505.07 990.37,566.28 985.38,566.04 979.73,504.82 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="956.63,508.65 962.28,569.87 961.81,567.24 956.16,506.02 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="981.97,506.32 987.62,567.53 985.95,567.46 980.3,506.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="954.24,509.25 959.89,570.46 959.43,567.87 953.78,506.66 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="957.54,509.41 963.19,570.62 962.28,569.87 956.63,508.65 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="966.16,508.74 971.81,569.95 968.45,570.29 962.8,509.08 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="962.8,509.08 968.45,570.29 965.66,570.93 960.01,509.72 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="960.01,509.72 965.66,570.93 963.19,570.62 957.54,509.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="955.93,510.65 961.58,571.86 959.89,570.46 954.24,509.25 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="965.11,510.21 970.75,571.43 968.77,571.64 963.12,510.42 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="979.73,504.82 985.38,566.04 974.24,571.32 968.59,510.11 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="968.59,510.11 974.24,571.32 971.81,569.95 966.16,508.74 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1051.67,488.32 1057.32,549.54 1049.92,564.51 1044.27,503.3 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="959.98,511.15 965.63,572.37 961.58,571.86 955.93,510.65 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="963.12,510.42 968.77,571.64 965.63,572.37 959.98,511.15 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1054.77,487.07 1060.42,548.29 1052.32,564.65 1046.67,503.43 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="980.3,506.25 985.95,567.46 973.87,573.19 968.22,511.98 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="968.22,511.98 973.87,573.19 970.75,571.43 965.11,510.21 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="984.72,505.07 990.37,566.28 988.89,572.55 983.24,511.34 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="983.84,513.83 989.49,575.04 988.89,572.55 983.24,511.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="981.56,514.8 987.21,576.02 986.44,572.62 980.79,511.41 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="987.46,514.86 993.11,576.07 989.49,575.04 983.84,513.83 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="986.28,516.13 991.93,577.35 987.21,576.02 981.56,514.8 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="992.77,515.64 998.42,576.86 993.11,576.07 987.46,514.86 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="991.3,516.84 996.95,578.06 991.93,577.35 986.28,516.13 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="996.38,517.26 1002.03,578.47 998.42,576.86 992.77,515.64 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="993.7,517.92 999.35,579.13 996.95,578.06 991.3,516.84 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="996.38,517.26 1002.03,578.47 1000.95,581.43 995.3,520.21 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1044.27,503.3 1049.92,564.51 1046.03,579.65 1040.38,518.43 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1046.67,503.43 1052.32,564.65 1048.33,580.13 1042.68,518.91 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="995.3,520.21 1000.95,581.43 999.98,586.68 994.33,525.46 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1040.38,518.43 1046.03,579.65 1039.6,583.76 1033.95,522.55 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1042.68,518.91 1048.33,580.13 1041.02,584.79 1035.37,523.58 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1033.95,522.55 1039.6,583.76 1031.64,587.09 1025.99,525.87 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="995.48,529.18 1001.13,590.4 999.98,586.68 994.33,525.46 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1003.86,528.12 1009.51,589.33 1006.06,590.01 1000.41,528.8 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1000.41,528.8 1006.06,590.01 1001.13,590.4 995.48,529.18 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1025.99,525.87 1031.64,587.09 1022.44,588.61 1016.79,527.4 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1007.33,528.86 1012.98,590.07 1009.51,589.33 1003.86,528.12 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1035.37,523.58 1041.02,584.79 1032.32,588.37 1026.67,527.15 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="993.4,530.71 999.05,591.93 997.53,586.78 991.88,525.56 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1000.61,530.16 1006.26,591.37 999.05,591.93 993.4,530.71 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1003.6,529.57 1009.24,590.79 1006.26,591.37 1000.61,530.16 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1026.67,527.15 1032.32,588.37 1023.07,589.89 1017.43,528.68 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1016.79,527.4 1022.44,588.61 1014.69,590.73 1009.04,529.51 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="1009.04,529.51 1014.69,590.73 1012.98,590.07 1007.33,528.86 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1046.67,503.43l-3.98,15.48l-7.31,4.67l-8.7,3.58l-9.24,1.53l-8.82,2.42l-2.7-1.04l-2.3-0.48l-2.98,0.58
l-7.21,0.56l-1.52-5.15l1-5.41l0.81-2.24l-2.4-1.08l-5.02-0.71l-4.72-1.33l-0.77-3.4l1.18-5.09l-1.68-0.07l-12.07,5.73
l-3.12-1.77l-1.99,0.21l-3.14,0.73l-4.04-0.51l-1.69-1.4l-0.46-2.59l-1.44-1.1l-5.79-0.94l-5.96,1.3l-6.05,1.95l-5.9,0.59
l-0.34-1.82l-3.71-6.82l-2.44-7.61l-0.86-1.2l-1.29-0.79l-0.21-1.2l0.35-0.31l2.58-1.75l3.35-1.17l5.48-0.78l1.14-0.59
l-20.57-3.36l0.82-1.59l-0.5-1l7.62-2.5l11.42-4.8l6.65-4.9l7.38-6.08l14.36,8.28l12.73,8.61l2.69,4.41l2.54,3.46l6.38,0.03
l8.11-1.98l14.57,0.93l14.21,2.39l16.07-0.1l17.63,0.84L1046.67,503.43z M1033.95,522.55l6.43-4.12l3.89-15.13l7.41-14.98
l-14.89-0.72l-16.34,0.1l-14.33-2.41l-13.71-0.87l-8.24,1.94l-8.1-0.05l-3.09-4.22l-2.66-4.34l-12.36-8.29l-12.3-7.1l-5.62,4.61
l-7.09,5.18l-11.69,4.92l-5.91,1.94l0.15,0.29l-0.4,0.74l22.38,3.65l-4.92,2.52l-5.93,0.85l-2.55,0.93l-1.76,1.24l0.04,0.28
l1.15,0.76l0.98,1.41l2.48,7.67l3.73,6.86l0.12,0.64l3.3-0.32l5.6-1.84l6.96-1.53l7.26,1.18l2.21,1.67l0.47,2.63l0.91,0.76
l2.47,0.31l2.79-0.64l3.36-0.34l2.44,1.37l11.13-5.29l4.99,0.25l-1.48,6.27l0.6,2.49l3.62,1.03l5.31,0.78l3.61,1.62l-1.08,2.95
l-0.97,5.25l1.15,3.72l4.93-0.38l3.45-0.68l3.47,0.74l1.71,0.66l7.75-2.12l9.2-1.53L1033.95,522.55"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: zerdabColor ? zerdabColor : null }}
                points="945.66,462.38 957.96,469.47 970.31,477.76 972.97,482.1 976.06,486.32 984.16,486.37 
992.4,484.42 1006.11,485.29 1020.44,487.71 1036.79,487.61 1051.67,488.32 1044.27,503.3 1040.38,518.43 1033.95,522.55 
1025.99,525.87 1016.79,527.4 1009.04,529.51 1007.33,528.86 1003.86,528.12 1000.41,528.8 995.48,529.18 994.33,525.46 
995.3,520.21 996.38,517.26 992.77,515.64 987.46,514.86 983.84,513.83 983.24,511.34 984.72,505.07 979.73,504.82 
968.59,510.11 966.16,508.74 962.8,509.08 960.01,509.72 957.54,509.41 956.63,508.65 956.16,506.02 953.96,504.35 946.7,503.17 
939.74,504.7 934.14,506.55 930.83,506.87 930.71,506.23 926.98,499.38 924.5,491.71 923.52,490.3 922.38,489.53 922.33,489.25 
924.09,488.01 926.64,487.09 932.57,486.24 937.48,483.73 915.1,480.07 915.5,479.33 915.35,479.03 921.26,477.09 932.95,472.17 
940.04,466.99 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1005.9,530.06 1011.55,591.27 1009.24,590.79 1003.6,529.57 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1017.43,528.68 1023.07,589.89 1014.25,592.31 1008.6,531.1 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1008.6,531.1 1014.25,592.31 1011.55,591.27 1005.9,530.06 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'agcabedi' ? 'active' : null}`}
            onClick={() => openPopup('agcabedi')}
          >
            <g>
              <polygon
                className="st148"
                points="921.3,491.02 926.94,552.24 925.66,551.44 920.01,490.22 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="922.15,492.22 927.8,553.43 926.94,552.24 921.3,491.02 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="842.83,498.86 848.48,560.08 847.86,562.14 842.21,500.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="846.86,501.75 852.51,562.97 848.48,560.08 842.83,498.86 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="920.01,490.22 925.66,551.44 898.59,559.25 892.95,498.03 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="842.21,500.93 847.86,562.14 832.4,568.07 826.75,506.86 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="892.95,498.03 898.59,559.25 870.37,566.23 864.72,505.01 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="853.91,506.04 859.56,567.26 852.51,562.97 846.86,501.75 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="864.72,505.01 870.37,566.23 859.56,567.26 853.91,506.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="924.59,499.83 930.24,561.05 927.8,553.43 922.15,492.22 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="826.75,506.86 832.4,568.07 817.44,571.7 811.79,510.48 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="811.79,510.48 817.44,571.7 814.53,575.73 808.89,514.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="928.3,506.65 933.95,567.86 930.24,561.05 924.59,499.83 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="946.55,504.62 952.2,565.84 946.23,567.14 940.58,505.92 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="952.34,505.56 957.99,566.78 952.2,565.84 946.55,504.62 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="940.58,505.92 946.23,567.14 940.18,569.08 934.54,507.87 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="934.54,507.87 940.18,569.08 934.29,569.68 928.64,508.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="928.64,508.46 934.29,569.68 933.95,567.86 928.3,506.65 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="815.72,519.69 821.37,580.91 814.53,575.73 808.88,514.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="812.13,519.39 817.78,580.61 811.76,576.06 806.11,514.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="953.78,506.66 959.43,567.87 957.99,566.78 952.34,505.56 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="981.97,506.32 987.62,567.53 985.95,567.46 980.3,506.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="954.24,509.25 959.89,570.46 959.43,567.87 953.78,506.66 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="804.3,523.55 809.95,584.77 799.51,585.68 793.86,524.46 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="815.72,519.69 821.37,580.91 809.95,584.77 804.3,523.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="955.93,510.65 961.58,571.86 959.89,570.46 954.24,509.25 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="965.11,510.21 970.75,571.43 968.77,571.64 963.12,510.42 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="963.12,510.42 968.77,571.64 965.63,572.37 959.98,511.15 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="959.98,511.15 965.63,572.37 961.58,571.86 955.93,510.65 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="968.22,511.98 973.87,573.19 970.75,571.43 965.11,510.21 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="980.3,506.25 985.95,567.46 973.87,573.19 968.22,511.98 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="981.97,506.32 987.62,567.53 986.44,572.62 980.79,511.41 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="984.72,505.07 990.37,566.28 988.89,572.55 983.24,511.34 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="793.86,524.46 799.51,585.68 796.61,590.92 790.96,529.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="981.56,514.8 987.21,576.02 986.44,572.62 980.79,511.41 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="986.13,516.08 991.78,577.3 987.21,576.02 981.56,514.8 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="790.96,529.71 796.61,590.92 792.98,597.02 787.33,535.81 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="787.33,535.81 792.98,597.02 788.2,598.48 782.56,537.26 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="782.56,537.26 788.2,598.48 784.79,599.26 779.14,538.05 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="986.13,516.08 991.78,577.3 973.43,591.09 967.78,529.87 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="780.36,547.98 786.01,609.19 784.79,599.26 779.14,538.05 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="990.15,515.23 995.8,576.45 975.32,591.83 969.68,530.61 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="778.02,548.98 783.67,610.2 782.21,598.44 776.56,537.22 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="967.78,529.87 973.43,591.09 963.33,598.51 957.69,537.29 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="969.68,530.61 975.32,591.83 965.33,599.18 959.68,537.96 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="814.16,551.1 819.8,612.32 806.77,613.83 801.12,552.61 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="801.12,552.61 806.77,613.83 786.01,609.19 780.36,547.98 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="827.8,551.11 833.45,612.32 819.8,612.32 814.16,551.1 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="800.64,554.05 806.29,615.27 783.67,610.2 778.02,548.98 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="813.99,552.48 819.64,613.69 806.29,615.27 800.64,554.05 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="825.89,552.48 831.54,613.7 819.64,613.69 813.99,552.48 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="831.73,558.49 837.38,619.71 833.45,612.32 827.8,551.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="829,558.34 834.65,619.56 831.54,613.7 825.89,552.48 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="957.69,537.29 963.33,598.51 955.57,608.54 949.93,547.33 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="959.68,537.96 965.33,599.18 957.79,608.98 952.14,547.76 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="831.73,558.49 837.38,619.71 827.06,627.03 821.41,565.82 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="821.41,565.82 827.06,627.03 824.5,627.84 818.85,566.63 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="818.85,566.63 824.5,627.84 822.79,628.23 817.14,567.01 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="949.93,547.33 955.57,608.54 947.93,618.01 942.28,556.8 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="952.14,547.76 957.79,608.98 950.05,618.54 944.4,557.33 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="942.28,556.8 947.93,618.01 940.24,624.97 934.6,563.75 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="944.4,557.33 950.05,618.54 941.78,625.96 936.13,564.74 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="891.3,568.61 896.95,629.83 892.39,631.03 886.74,569.81 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="819.79,576.07 825.43,637.28 822.79,628.23 817.14,567.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="817.47,576.84 823.12,638.05 820.03,627.44 814.38,566.22 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="898.36,569.56 904,630.78 896.95,629.83 891.3,568.61 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="891.31,570.06 896.96,631.27 893.81,632.11 888.16,570.89 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="924.62,567.77 930.27,628.98 920.33,629.67 914.68,568.45 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="934.6,563.75 940.24,624.97 930.27,628.98 924.62,567.77 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="904.85,569.83 910.5,631.04 904,630.78 898.36,569.56 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="914.68,568.45 920.33,629.67 910.5,631.04 904.85,569.83 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="886.74,569.81 892.39,631.03 889.58,632.99 883.93,571.77 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="883.93,571.77 889.58,632.99 880.12,634.26 874.47,573.05 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="897.71,570.92 903.36,632.14 896.96,631.27 891.31,570.06 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="936.13,564.74 941.78,625.96 930.96,630.29 925.31,569.08 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="925.31,569.08 930.96,630.29 920.49,631.02 914.84,569.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="888.16,570.89 893.81,632.11 890.81,634.19 885.16,572.98 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="904.57,571.2 910.22,632.42 903.36,632.14 897.71,570.92 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="914.84,569.81 920.49,631.02 910.22,632.42 904.57,571.2 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="885.16,572.98 890.81,634.19 881.24,635.5 875.59,574.28 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="874.47,573.05 880.12,634.26 872.47,638.84 866.82,577.62 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="875.59,574.28 881.24,635.5 874.98,639.22 869.33,578.01 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="831.33,582.37 836.97,643.59 825.43,637.28 819.79,576.07 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="828.91,583.08 834.55,644.29 823.12,638.05 817.47,576.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="867.48,583.85 873.12,645.07 872.47,638.84 866.82,577.62 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="867.48,583.85 873.12,645.07 869.5,645.97 863.85,584.76 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="863.85,584.76 869.5,645.97 864.05,647.74 858.4,586.53 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="870.05,584.65 875.7,645.87 870.4,647.18 864.75,585.96 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="864.75,585.96 870.4,647.18 866.9,648.32 861.25,587.11 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="831.33,582.37 836.97,643.59 837.46,652.56 831.82,591.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="860.66,591.56 866.31,652.77 864.05,647.74 858.4,586.53 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="831.82,591.34 837.46,652.56 827.02,659.64 821.37,598.43 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="841.69,597.17 847.34,658.39 827.02,659.64 821.37,598.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="863.23,596.13 868.88,657.35 866.31,652.77 860.66,591.56 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="863.23,596.13 868.88,657.35 847.34,658.39 841.69,597.17 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M987.46,514.86l2.69,0.38l-20.47,15.38l-10,7.35l-7.54,9.8l-7.74,9.57l-8.28,7.41l-10.82,4.33l-10.47,0.73
l-10.27,1.4l-6.86-0.28l-6.41-0.87l-3.15,0.83l-3,2.08l-9.56,1.31l-6.27,3.72l0.72,6.64l-5.3,1.31l-3.49,1.15l1.78,3.94
l3.53,6.29l-24.89,1.19l-25.84,1.6l13.49-9.15l-0.42-7.91l-11.44-6.24l-3.09-10.61l3.63-0.82l2.13-0.69l8.87-6.37l-3.11-5.86
l-11.89-0.01l-13.35,1.57l-22.62-5.07l-1.46-11.76l5.15-1.18l3.64-1.12l3.28-5.49l3.4-6.17l11.9-1.04l8.2-2.83l-6.02-4.54
l3.87-5.36l15.91-3.85l14.09-5.44l1.44-4.7l7.37,5.3l6.21,3.77l9.39-0.9l27.71-6.87l30.05-8.67l0.22,1.41l1.15,0.76l0.98,1.41
l2.48,7.67l3.73,6.86l0.12,0.64l3.3-0.32l5.6-1.84l6.96-1.53l7.26,1.18l2.21,1.67l0.47,2.63l0.91,0.76l2.47,0.31l2.79-0.64
l3.36-0.34l2.44,1.37l11.13-5.29l4.99,0.25l-1.48,6.27l0.6,2.49L987.46,514.86z M967.78,529.87l18.35-13.79l-4.57-1.28l-0.77-3.4
l1.18-5.09l-1.68-0.07l-12.07,5.73l-3.12-1.77l-1.99,0.21l-3.14,0.73l-4.04-0.51l-1.69-1.4l-0.46-2.59l-1.44-1.1l-5.79-0.94
l-5.96,1.3l-6.05,1.95l-5.9,0.59l-0.34-1.82l-3.71-6.82l-2.44-7.61l-0.86-1.2l-1.29-0.8l-27.06,7.81l-28.23,6.98l-10.81,1.03
l-7.05-4.29l-4.03-2.89l-0.62,2.06l-15.45,5.93l-14.97,3.63l-2.9,4.03l6.83,5.18l-11.41,3.86l-10.44,0.91l-2.9,5.24l-3.64,6.1
l-4.77,1.46l-3.41,0.79l1.21,9.93l20.76,4.64l13.03-1.51l13.65,0.01l3.93,7.38l-10.32,7.32l-2.56,0.81l-1.71,0.39l2.65,9.06
l11.54,6.3l0.49,8.97l-10.45,7.08l20.33-1.26l21.53-1.04l-2.57-4.57l-2.26-5.03l5.45-1.77l3.62-0.91l-0.65-6.23l7.64-4.57
l9.46-1.28l2.81-1.96l4.56-1.2l7.06,0.95l6.49,0.26l9.84-1.37l9.94-0.69l9.98-4.01l7.68-6.96l7.65-9.47l7.76-10.04L967.78,529.87
"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: agcabediColor ? agcabediColor : null }}
                points="920.01,490.22 921.3,491.02 922.15,492.22 924.59,499.83 928.3,506.65 928.64,508.46 
934.54,507.87 940.58,505.92 946.55,504.62 952.34,505.56 953.78,506.66 954.24,509.25 955.93,510.65 959.98,511.15 
963.12,510.42 965.11,510.21 968.22,511.98 980.3,506.25 981.97,506.32 980.79,511.41 981.56,514.8 986.13,516.08 967.78,529.87 
957.69,537.29 949.93,547.33 942.28,556.8 934.6,563.75 924.62,567.77 914.68,568.45 904.85,569.83 898.36,569.56 891.3,568.61 
886.74,569.81 883.93,571.77 874.47,573.05 866.82,577.62 867.48,583.85 863.85,584.76 858.4,586.53 860.66,591.56 
863.23,596.13 841.69,597.17 821.37,598.43 831.82,591.34 831.33,582.37 819.79,576.07 817.14,567.01 818.85,566.63 
821.41,565.82 831.73,558.49 827.8,551.11 814.16,551.1 801.12,552.61 780.36,547.98 779.14,538.05 782.56,537.26 787.33,535.81 
790.96,529.71 793.86,524.46 804.3,523.55 815.72,519.69 808.88,514.52 811.79,510.48 826.75,506.86 842.21,500.93 
842.83,498.86 846.86,501.75 853.91,506.04 864.72,505.01 892.95,498.03 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="841.67,598.53 847.32,659.75 821.48,661.35 815.83,600.14 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="866.56,597.34 872.21,658.56 847.32,659.75 841.67,598.53 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'beyleqan' ? 'active' : null}`}
            onClick={() => openPopup('beyleqan')}
          >
            <g>
              <polygon
                className="st93"
                points="991.3,516.84 996.95,578.06 994.15,577.67 988.51,516.46 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="993.7,517.92 999.35,579.13 996.95,578.06 991.3,516.84 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="993.7,517.92 999.35,579.13 998.53,581.37 992.88,520.15 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="996.38,517.26 1002.03,578.47 1000.95,581.43 995.3,520.21 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="992.88,520.15 998.53,581.37 997.53,586.78 991.88,525.56 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="995.3,520.21 1000.95,581.43 999.98,586.68 994.33,525.46 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="988.51,516.46 994.15,577.67 975.32,591.83 969.68,530.61 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="991.88,525.56 997.53,586.78 982.37,592.37 976.72,531.15 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="994.62,526.35 1000.27,587.57 985.73,592.72 980.08,531.51 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="969.68,530.61 975.32,591.83 965.32,599.18 959.68,537.96 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="984.54,538.67 990.19,599.89 982.37,592.37 976.72,531.15 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="959.68,537.96 965.32,599.18 957.79,608.98 952.14,547.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="990.6,545.38 996.25,606.59 990.19,599.89 984.54,538.67 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="990.6,545.38 996.25,606.59 992.16,614.13 986.51,552.91 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="952.14,547.76 957.79,608.98 950.05,618.54 944.4,557.33 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="993.24,545.11 998.89,606.32 994.14,615.05 988.49,553.84 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="986.51,552.91 992.16,614.13 979.66,617.56 974.01,556.35 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="974.01,556.35 979.66,617.56 973.18,618.92 967.53,557.71 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="988.49,553.84 994.14,615.05 980.42,618.82 974.77,557.6 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="974.77,557.6 980.42,618.82 974.04,620.16 968.39,558.94 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="967.53,557.71 973.18,618.92 966.58,622.17 960.93,560.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="968.39,558.94 974.04,620.16 969.36,622.5 963.71,561.29 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="944.4,557.33 950.05,618.54 941.78,625.96 936.13,564.74 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="891.31,570.06 896.96,631.27 893.81,632.11 888.16,570.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="962.87,564.9 968.52,626.12 966.58,622.17 960.93,560.96 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="897.71,570.92 903.36,632.14 896.96,631.27 891.31,570.06 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="936.13,564.74 941.78,625.96 930.96,630.29 925.31,569.08 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="925.31,569.08 930.96,630.29 920.49,631.02 914.84,569.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="888.16,570.89 893.81,632.11 890.8,634.19 885.16,572.98 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="904.57,571.2 910.22,632.42 903.36,632.14 897.71,570.92 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="914.84,569.81 920.49,631.02 910.22,632.42 904.57,571.2 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="885.16,572.98 890.8,634.19 881.24,635.5 875.59,574.28 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="966.79,567.31 972.44,628.53 968.52,626.12 962.87,564.9 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="875.59,574.28 881.24,635.5 874.98,639.22 869.33,578.01 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="966.79,567.31 972.44,628.53 964.55,635.22 958.9,574.01 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="969.92,567.04 975.56,628.25 966.66,635.78 961.01,574.56 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="867.48,583.85 873.12,645.07 872.47,638.84 866.82,577.62 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="870.05,584.65 875.7,645.87 874.98,639.22 869.33,578.01 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="870.05,584.65 875.7,645.87 870.4,647.18 864.75,585.96 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="864.75,585.96 870.4,647.18 866.9,648.32 861.25,587.11 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="958.9,574.01 964.55,635.22 959.54,641.63 953.89,580.42 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="961.01,574.56 966.66,635.78 962.52,641.11 956.87,579.9 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="863.03,591.05 868.68,652.27 866.9,648.32 861.25,587.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="860.66,591.56 866.31,652.77 864.05,647.74 858.4,586.53 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="963.51,584.69 969.16,645.9 959.54,641.63 953.89,580.42 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="866.06,596.47 871.71,657.69 868.68,652.27 863.03,591.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="863.68,596.91 869.33,658.13 866.31,652.77 860.66,591.56 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="970.37,590.28 976.02,651.49 969.16,645.9 963.51,584.69 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="867.71,602.81 873.36,664.03 871.71,657.69 866.06,596.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="865.44,603.8 871.09,665.02 869.33,658.13 863.68,596.91 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="876.15,605.01 881.8,666.22 873.36,664.03 867.71,602.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="978.1,595.95 983.75,657.17 976.02,651.49 970.37,590.28 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="874.28,606.09 879.93,667.31 871.09,665.02 865.44,603.8 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="880.95,610.3 886.6,671.51 881.8,666.22 876.15,605.01 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="985.48,600.72 991.13,661.93 983.75,657.17 978.1,595.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="878.68,610.93 884.33,672.14 879.93,667.31 874.28,606.09 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="985.48,600.72 991.13,661.93 986.73,667.44 981.08,606.23 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="988.34,600.36 993.99,661.57 988.48,668.48 982.84,607.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="884.43,616.47 890.08,677.69 886.6,671.51 880.95,610.3 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="981.08,606.23 986.73,667.44 973.28,670.35 967.63,609.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="882.15,617.11 887.8,678.33 884.33,672.14 878.68,610.93 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="982.84,607.26 988.48,668.48 974.15,671.57 968.5,610.36 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="967.63,609.13 973.28,670.35 957.98,675.58 952.33,614.36 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="968.5,610.36 974.15,671.57 959.79,676.49 954.14,615.27 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="952.33,614.36 957.98,675.58 952.09,682.27 946.44,621.05 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="954.14,615.27 959.79,676.49 953.92,683.13 948.28,621.92 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="898.41,626.74 904.05,687.95 890.08,677.69 884.43,616.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="895.74,627.17 901.39,688.38 887.8,678.33 882.15,617.11 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="946.44,621.05 952.09,682.27 937.15,688.1 931.5,626.89 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="948.28,621.92 953.92,683.13 937.93,689.36 932.28,628.14 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="898.41,626.74 904.05,687.95 900.46,695.61 894.81,634.39 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="931.5,626.89 937.15,688.1 900.46,695.61 894.81,634.39 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M980.07,595.02l8.27,5.34l-5.5,6.9l-14.34,3.1l-14.35,4.92l-5.87,6.64l-15.99,6.23l-40.94,8.37l4.39-9.35
l-13.59-10.05l-3.47-6.18l-4.4-4.84l-8.84-2.29l-1.76-6.89l-3.02-5.35l-2.26-5.03l5.45-1.77l3.62-0.91l-0.65-6.23l7.64-4.57
l9.46-1.28l2.81-1.96l4.56-1.2l7.06,0.95l6.49,0.26l9.84-1.37l9.94-0.69l9.98-4.01l7.68-6.96l7.65-9.47l7.76-10.04l10.1-7.42
l19.93-14.98l5.06,0.75l3.61,1.62l-1.08,2.95l-0.97,5.25l0.29,0.89l-14.53,5.16l6.63,6.4l6.52,7.2l-4.75,8.73l-13.72,3.76
l-6.38,1.34l-4.68,2.35l1.47,2.96l4.74,2.79l-8.9,7.52l-4.14,5.34l8.61,3.85l6.92,5.64L980.07,595.02z M981.08,606.23l4.4-5.51
l-7.38-4.77l-7.73-5.68l-6.86-5.59l-9.61-4.27l5-6.41l7.89-6.69l-3.92-2.41l-1.93-3.94l6.6-3.25l6.48-1.36l12.5-3.43l4.09-7.54
l-6.07-6.7l-7.81-7.52l15.17-5.38l0.99-5.62l0.81-2.24l-2.4-1.08l-2.79-0.38l-18.83,14.16l-10,7.35l-7.54,9.8l-7.74,9.57
l-8.27,7.41l-10.82,4.33l-10.47,0.73l-10.27,1.4l-6.86-0.28l-6.41-0.87l-3.15,0.83l-3,2.08l-9.56,1.31l-6.27,3.72l0.72,6.64
l-5.3,1.31l-3.49,1.15l1.78,3.94l3.03,5.42l1.64,6.34l8.44,2.19l4.8,5.29l3.48,6.17l13.97,10.27l-3.6,7.66l36.69-7.5l14.94-5.84
l5.89-6.69l15.3-5.23L981.08,606.23"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: beyleqanColor ? beyleqanColor : null }}
                points="988.51,516.46 991.3,516.84 993.7,517.92 992.88,520.15 991.88,525.56 976.72,531.15 
984.54,538.67 990.6,545.38 986.51,552.91 974.01,556.35 967.53,557.71 960.93,560.96 962.87,564.9 966.79,567.31 958.9,574.01 
953.89,580.42 963.51,584.69 970.37,590.28 978.1,595.95 985.48,600.72 981.08,606.23 967.63,609.13 952.33,614.36 
946.44,621.05 931.5,626.89 894.81,634.39 898.41,626.74 884.43,616.47 880.95,610.3 876.15,605.01 867.71,602.81 866.06,596.47 
863.03,591.05 861.25,587.11 864.75,585.96 870.05,584.65 869.33,578.01 875.59,574.28 885.16,572.98 888.16,570.89 
891.31,570.06 897.71,570.92 904.57,571.2 914.84,569.81 925.31,569.08 936.13,564.74 944.4,557.33 952.14,547.76 959.68,537.96 
969.68,530.61 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="932.28,628.14 937.93,689.36 896.99,697.73 891.35,636.51 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'agsu' ? 'active' : null}`}
            onClick={() => openPopup('agsu')}
          >
            <g>
              <polygon
                className="st11"
                points="1248.97,402.29 1254.62,463.51 1254.09,462.12 1248.45,400.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1249.53,405.34 1255.17,466.55 1254.62,463.51 1248.97,402.29 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1248.45,400.91 1254.09,462.12 1245.24,470.66 1239.6,409.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1255.89,411.31 1261.54,472.53 1255.17,466.55 1249.53,405.34 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1239.6,409.44 1245.24,470.66 1232.06,478.87 1226.42,417.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1257.92,418.01 1263.57,479.23 1261.54,472.53 1255.89,411.31 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1226.42,417.65 1232.06,478.87 1211.94,484.58 1206.29,423.37 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1206.29,423.37 1211.94,484.58 1185.74,488.91 1180.1,427.69 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1180.1,427.69 1185.74,488.91 1180.79,489.81 1175.14,428.6 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1257.92,418.01 1263.57,479.23 1259.2,482.61 1253.55,421.39 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1260.54,418.21 1266.18,479.43 1261.08,483.38 1255.43,422.17 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1175.14,428.6 1180.79,489.81 1177.83,491.28 1172.18,430.06 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1172.18,430.06 1177.83,491.28 1175.32,493.32 1169.67,432.1 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1253.55,421.39 1259.2,482.61 1253.47,486.43 1247.82,425.21 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1255.43,422.17 1261.08,483.38 1255.77,486.91 1250.12,425.7 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1169.67,432.1 1175.32,493.32 1167.16,495.5 1161.51,434.28 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1247.82,425.21 1253.47,486.43 1253,488.11 1247.36,426.89 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1250.12,425.7 1255.77,486.91 1255.38,488.25 1249.73,427.04 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1161.51,434.28 1167.16,495.5 1158.82,497.27 1153.17,436.05 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1247.36,426.89 1253,488.11 1252.04,490.07 1246.39,428.85 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1153.17,436.05 1158.82,497.27 1152.12,499.33 1146.47,438.12 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1249.73,427.04 1255.38,488.25 1254.2,490.66 1248.55,429.45 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1246.39,428.85 1252.04,490.07 1249.65,491.49 1244.01,430.27 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1146.47,438.12 1152.12,499.33 1146.12,502.01 1140.48,440.8 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1248.55,429.45 1254.2,490.66 1251.26,492.42 1245.61,431.21 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1244.01,430.27 1249.65,491.49 1246.73,492.98 1241.08,431.77 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1245.61,431.21 1251.26,492.42 1248.08,494.03 1242.43,432.82 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1241.08,431.77 1246.73,492.98 1231.08,498.67 1225.43,437.45 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1242.43,432.82 1248.08,494.03 1232.93,499.55 1227.28,438.34 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1140.48,440.8 1146.12,502.01 1141.06,508.12 1135.41,446.91 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1127.92,449.09 1133.57,510.3 1129.65,508.07 1124.01,446.86 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1135.41,446.91 1141.06,508.12 1133.57,510.3 1127.92,449.09 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1124.01,446.86 1129.65,508.07 1120.83,515.53 1115.18,454.31 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1225.43,437.45 1231.08,498.67 1223.43,507.72 1217.78,446.5 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1227.28,438.34 1232.93,499.55 1226.13,507.6 1220.48,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1115.18,454.31 1120.83,515.53 1114.48,523.89 1108.83,462.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1223.28,453.47 1228.93,514.69 1223.43,507.72 1217.78,446.5 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1113.86,466.87 1119.51,528.09 1114.48,523.89 1108.83,462.67 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1124.28,466.68 1129.93,527.89 1119.51,528.09 1113.86,466.87 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1232.98,457.04 1238.63,518.26 1228.93,514.69 1223.28,453.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1112.44,468.28 1118.09,529.5 1111.71,524.17 1106.06,462.95 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1122.82,468.08 1128.46,529.29 1118.09,529.5 1112.44,468.28 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1252.4,459.16 1258.05,520.38 1238.63,518.26 1232.98,457.04 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1164.6,467.87 1170.25,529.08 1156.82,528.52 1151.17,467.31 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1163.49,469.23 1169.14,530.44 1157.13,529.93 1151.48,468.71 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1257.27,461.36 1262.92,522.57 1258.05,520.38 1252.4,459.16 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1151.17,467.31 1156.82,528.52 1137.74,534.61 1132.09,473.39 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1132.09,473.39 1137.74,534.61 1129.93,527.89 1124.28,466.68 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1151.48,468.71 1157.13,529.93 1136.78,536.41 1131.13,475.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1131.13,475.2 1136.78,536.41 1128.46,529.29 1122.82,468.08 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1175,472.26 1180.65,533.48 1170.25,529.08 1164.6,467.87 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1262.01,464.43 1267.66,525.64 1262.92,522.57 1257.27,461.36 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1172.29,472.93 1177.94,534.14 1169.14,530.44 1163.49,469.23 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1267.3,466.64 1272.94,527.85 1267.66,525.64 1262.01,464.43 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1270.18,468.66 1275.83,529.88 1272.94,527.85 1267.3,466.64 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1270.18,468.66 1275.83,529.88 1271.26,532.03 1265.61,470.81 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1265.61,470.81 1271.26,532.03 1264.99,533.13 1259.34,471.91 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1273.61,468.73 1279.26,529.95 1271.99,533.29 1266.35,472.08 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1259.34,471.91 1264.99,533.13 1249.04,535.47 1243.39,474.25 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1175,472.26 1180.65,533.48 1177.3,542.32 1171.65,481.1 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1266.35,472.08 1271.99,533.29 1265.42,534.44 1259.77,473.22 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1259.77,473.22 1265.42,534.44 1249.57,536.78 1243.92,475.56 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1243.39,474.25 1249.04,535.47 1233.34,540.27 1227.69,479.05 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1243.92,475.56 1249.57,536.78 1235.24,541.17 1229.59,479.96 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1171.65,481.1 1177.3,542.32 1174.08,549.78 1168.43,488.56 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1227.69,479.05 1233.34,540.27 1229.74,545.53 1224.1,484.31 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1229.59,479.96 1235.24,541.17 1232.03,545.87 1226.38,484.65 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="1175.27,490.78 1180.92,551.99 1174.08,549.78 1168.43,488.56 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1173.74,491.95 1179.39,553.16 1171.28,550.54 1165.63,489.32 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1224.1,484.31 1229.74,545.53 1226.6,550.4 1220.95,489.19 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1226.38,484.65 1232.03,545.87 1228.67,551.1 1223.02,489.88 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1182.54,493.94 1188.19,555.16 1180.92,551.99 1175.27,490.78 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1180.3,494.79 1185.95,556.01 1179.39,553.16 1173.74,491.95 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1220.95,489.19 1226.6,550.4 1219.42,554.14 1213.77,492.92 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1223.02,489.88 1228.67,551.1 1220.9,555.14 1215.25,493.92 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1213.77,492.92 1219.42,554.14 1211.3,557.83 1205.65,496.61 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1215.25,493.92 1220.9,555.14 1212.76,558.84 1207.11,497.62 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1185.38,501.66 1191.02,562.88 1188.19,555.16 1182.54,493.94 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1205.65,496.61 1211.3,557.83 1203.19,562.07 1197.55,500.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1183.02,502.22 1188.67,563.44 1185.95,556.01 1180.3,494.79 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1207.11,497.62 1212.76,558.84 1204.88,562.96 1199.23,501.74 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1197.55,500.85 1203.19,562.07 1197.26,567.43 1191.62,506.21 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1191.62,506.21 1197.26,567.43 1191.02,562.88 1185.38,501.66 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: agsuColor ? agsuColor : null }}
                points="1248.45,400.91 1248.97,402.29 1249.53,405.34 1255.89,411.31 1257.92,418.01 1253.55,421.39 
1247.82,425.21 1247.36,426.89 1246.39,428.85 1244.01,430.27 1241.08,431.77 1225.43,437.45 1217.78,446.5 1223.28,453.47 
1232.98,457.04 1252.4,459.16 1257.27,461.36 1262.01,464.43 1267.3,466.64 1270.18,468.66 1265.61,470.81 1259.34,471.91 
1243.39,474.25 1227.69,479.05 1224.1,484.31 1220.95,489.19 1213.77,492.92 1205.65,496.61 1197.55,500.85 1191.62,506.21 
1185.38,501.66 1182.54,493.94 1175.27,490.78 1168.43,488.56 1171.65,481.1 1175,472.26 1164.6,467.87 1151.17,467.31 
1132.09,473.39 1124.28,466.68 1113.86,466.87 1108.83,462.67 1115.18,454.31 1124.01,446.86 1127.92,449.09 1135.41,446.91 
1140.48,440.8 1146.47,438.12 1153.17,436.05 1161.51,434.28 1169.67,432.1 1172.18,430.06 1175.14,428.6 1180.1,427.69 
1206.29,423.37 1226.42,417.65 1239.6,409.44 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1199.23,501.74 1204.88,562.96 1197.35,569.87 1191.71,508.66 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1269.19,465.64l4.42,3.09l-7.26,3.34l-6.58,1.15l-15.84,2.34l-14.33,4.4l-3.22,4.69l-3.36,5.23l-7.77,4.04
l-8.14,3.7l-7.87,4.12l-7.53,6.92l-8.68-6.44l-2.73-7.43l-6.56-2.84l-8.11-2.62l3.63-8.38l3.03-8.01l-8.8-3.7l-12.01-0.51
l-20.36,6.48l-8.31-7.12l-10.37,0.2l-6.38-5.33l7-9.18l10.76-9.1l4.79,2.74l5.02-1.46l4.94-5.95l6.72-3l7.09-2.19l8.39-1.79
l7.37-1.98l2.38-1.9l3.81-1.81l5.32-0.96l5.34-0.83l20.6-3.44l19.67-5.6l12.48-7.85l11.85-11.46l1.8,4.74l0.53,2.97l6.35,5.93
l2.26,7.39l-5.11,3.95l-5.31,3.53l-0.39,1.34l-1.18,2.41l-2.94,1.76l-3.18,1.61l-15.15,5.52l-6.8,8.04l4.82,6.1l9.02,3.31
l19.33,2.07l5.41,2.45l4.71,3.05L1269.19,465.64z M1265.61,470.81l4.57-2.15l-2.88-2.03l-5.28-2.21l-4.74-3.07l-4.87-2.2
l-19.42-2.12l-9.7-3.57l-5.5-6.97l7.65-9.05l15.65-5.68l2.92-1.49l2.38-1.42l0.97-1.96l0.46-1.68l5.73-3.82l4.37-3.38l-2.03-6.7
l-6.37-5.98l-0.56-3.05l-0.52-1.38l-8.85,8.54l-13.18,8.21l-20.12,5.71l-26.2,4.33l-4.96,0.91l-2.96,1.46l-2.52,2.04l-8.16,2.18
l-8.34,1.77l-6.7,2.07l-5.99,2.68l-5.06,6.11l-7.49,2.18l-3.92-2.23l-8.83,7.45l-6.35,8.36l5.03,4.2l10.42-0.2l7.82,6.72
l19.08-6.09l13.43,0.56l10.4,4.39l-3.35,8.84l-3.21,7.46l6.83,2.22l7.27,3.16l2.83,7.72l6.24,4.55l5.93-5.36l8.11-4.24l8.11-3.69
l7.18-3.73l3.15-4.88l3.59-5.26l15.7-4.8l15.96-2.34L1265.61,470.81"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1191.71,508.66 1197.35,569.87 1188.67,563.44 1183.02,502.22 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'xacmaz' ? 'active' : null}`}
            onClick={() => openPopup('xacmaz')}
          >
            <g>
              <polygon
                className="st11"
                points="1389.39,179.43 1395.04,240.64 1393.48,239.43 1387.83,178.22 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1396.45,183.09 1402.1,244.31 1395.04,240.64 1389.39,179.43 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1387.83,178.22 1393.48,239.43 1375.1,250.11 1369.45,188.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1403.88,189.04 1409.52,250.25 1402.1,244.31 1396.45,183.09 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1369.45,188.89 1375.1,250.11 1351.3,261.68 1345.65,200.46 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1350.87,200.65 1356.51,261.86 1351.3,261.68 1345.65,200.46 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1349.24,201.97 1354.89,263.19 1345.34,262.86 1339.69,201.65 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1356.64,206.6 1362.29,267.82 1356.51,261.86 1350.87,200.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1353.82,206.7 1359.47,267.91 1354.89,263.19 1349.24,201.97 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1356.64,206.6 1362.29,267.82 1350.4,278.79 1344.76,217.58 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1419.33,210.96 1424.98,272.18 1409.52,250.25 1403.88,189.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1426.19,217.26 1431.84,278.48 1424.98,272.18 1419.33,210.96 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1344.76,217.58 1350.4,278.79 1350.52,288.98 1344.87,227.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1428.46,220.83 1434.11,282.05 1431.84,278.48 1426.19,217.26 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1351.48,228.67 1357.13,289.89 1350.52,288.98 1344.87,227.76 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1350.44,229.97 1356.09,291.19 1348.06,290.09 1342.41,228.88 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1358.89,230.66 1364.53,291.88 1357.13,289.89 1351.48,228.67 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1355.99,231.48 1361.64,292.7 1356.09,291.19 1350.44,229.97 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1430.92,220.65 1436.57,281.87 1435.75,287.04 1430.1,225.82 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1428.46,220.83 1434.11,282.05 1433.24,287.45 1427.59,226.24 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1358.89,230.66 1364.53,291.88 1362.06,296.84 1356.41,235.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1356.41,235.62 1362.06,296.84 1355.9,300.1 1350.25,238.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1349.9,245.73 1355.55,306.95 1353.23,299.74 1347.58,238.52 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1352.37,245.58 1358.02,306.79 1355.9,300.1 1350.25,238.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1440.97,240.39 1446.62,301.61 1433.24,287.45 1427.59,226.24 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1352.37,245.58 1358.02,306.79 1357.22,315.92 1351.57,254.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1354.33,261.54 1359.98,322.75 1357.22,315.92 1351.57,254.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1351.98,262.05 1357.63,323.26 1354.78,316.1 1349.13,254.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1445.43,254.82 1451.08,316.03 1446.62,301.61 1440.97,240.39 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1450.76,256.57 1456.4,317.78 1451.08,316.03 1445.43,254.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1453.93,259.62 1459.58,320.83 1456.4,317.78 1450.76,256.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1358.52,268.46 1364.17,329.68 1359.98,322.75 1354.33,261.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1356.13,268.95 1361.78,330.17 1357.63,323.26 1351.98,262.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1455.85,263.13 1461.5,324.34 1459.58,320.83 1453.93,259.62 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1360.73,274.97 1366.38,336.18 1364.17,329.68 1358.52,268.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1456.93,266.2 1462.57,327.42 1461.5,324.34 1455.85,263.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1358.31,275.38 1363.96,336.6 1361.78,330.17 1356.13,268.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1460.78,272.18 1466.43,333.39 1462.57,327.42 1456.93,266.2 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1363.17,281.28 1368.81,342.49 1366.38,336.18 1360.73,274.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1360.81,281.8 1366.46,343.02 1363.96,336.6 1358.31,275.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1461.33,273.57 1466.98,334.78 1466.43,333.39 1460.78,272.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1462.59,274.89 1468.24,336.1 1466.98,334.78 1461.33,273.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1365.81,285.03 1371.46,346.25 1368.81,342.49 1363.17,281.28 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1380.36,283.88 1386.01,345.1 1378.16,345.93 1372.51,284.71 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1372.51,284.71 1378.16,345.93 1371.46,346.25 1365.81,285.03 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1469.14,276.76 1474.79,337.98 1468.24,336.1 1462.59,274.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1364.08,286.49 1369.73,347.7 1366.46,343.02 1360.81,281.8 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1372.56,286.07 1378.21,347.28 1369.73,347.7 1364.08,286.49 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1379.12,285.38 1384.77,346.59 1378.21,347.28 1372.56,286.07 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1470.82,277.57 1476.47,338.79 1474.79,337.98 1469.14,276.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1384.72,287.21 1390.36,348.42 1386.01,345.1 1380.36,283.88 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1393.46,287.4 1399.11,348.61 1390.36,348.42 1384.72,287.21 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1403.73,285.86 1409.38,347.08 1399.11,348.61 1393.46,287.4 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1383.28,288.56 1388.93,349.78 1384.77,346.59 1379.12,285.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1473.16,281.31 1478.81,342.53 1476.47,338.79 1470.82,277.57 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1403.13,287.33 1408.78,348.54 1398.9,350 1393.25,288.78 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1393.25,288.78 1398.9,350 1388.93,349.78 1383.28,288.56 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="1411.94,288.51 1417.59,349.72 1409.38,347.08 1403.73,285.86 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1410.67,289.77 1416.32,350.99 1408.78,348.54 1403.13,287.33 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1421.08,289.7 1426.72,350.92 1417.59,349.72 1411.94,288.51 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1435.15,288 1440.8,349.21 1426.72,350.92 1421.08,289.7 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1420.78,291.12 1426.43,352.33 1416.32,350.99 1410.67,289.77 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1434.84,289.41 1440.49,350.63 1426.43,352.33 1420.78,291.12 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1448.28,289.9 1453.93,351.11 1440.8,349.21 1435.15,288 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1447.35,291.22 1453,352.44 1440.49,350.63 1434.84,289.41 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1462.19,292.65 1467.84,353.86 1453.93,351.11 1448.28,289.9 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1461.38,293.99 1467.03,355.21 1453,352.44 1447.35,291.22 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1477.31,293.64 1482.96,354.85 1478.81,342.53 1473.16,281.31 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1477.31,293.64 1482.96,354.85 1467.84,353.86 1462.19,292.65 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1475.53,280.82l4.69,13.9l-3.5,0.31l-15.34-1.04l-14.03-2.77l-12.51-1.81l-14.06,1.7l-10.11-1.34
l-7.55-2.45l-9.88,1.46l-9.97-0.22l-4.16-3.18l-6.56,0.69l-8.48,0.42l-3.27-4.69l-2.5-6.42l-2.18-6.43l-4.15-6.91l-2.85-7.16
l0.77-9.15l-2.32-7.21l6.67-3.55l1.74-3.5l-5.55-1.51l-8.03-1.1l-0.11-11.55l11.52-10.62l-4.58-4.72l-9.55-0.33l28.19-13.71
l20.41-11.86l3.03,2.38l7.06,3.67l7.74,6.21l15.43,21.9l6.89,6.33l2.49,4.08l-0.82,5.17l13.25,14.09l4.34,13.98l4.93,1.63
l3.63,3.49l1.99,3.64l1.05,3.05l3.86,5.96l0.53,1.37l0.77,0.77l6.31,1.82l2.2,1.07L1475.53,280.82z M1462.19,292.65l15.12,0.99
l-4.14-12.33l-2.34-3.74l-1.68-0.81l-6.55-1.87l-1.26-1.32l-0.54-1.39l-3.86-5.98l-1.08-3.08l-1.92-3.51l-3.18-3.05l-5.32-1.75
l-4.46-14.42l-13.38-14.16l0.87-5.41l-2.27-3.57l-6.86-6.3l-15.46-21.93l-7.43-5.94l-7.06-3.67l-1.56-1.21l-18.38,10.67
l-23.8,11.57l5.22,0.19l5.78,5.95l-11.89,10.98l0.11,10.19l6.61,0.91l7.41,1.99l-2.48,4.96l-6.16,3.26l2.12,6.69l-0.8,9.13
l2.76,6.84l4.19,6.92l2.21,6.51l2.44,6.31l2.65,3.75l6.7-0.32l7.85-0.83l4.36,3.33l8.74,0.19l10.27-1.54l8.21,2.64l9.14,1.19
l14.08-1.7l13.13,1.9L1462.19,292.65"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: xacmazColor ? xacmazColor : null }}
                points="1387.83,178.22 1389.39,179.43 1396.45,183.09 1403.88,189.04 1419.33,210.96 1426.19,217.26 
1428.46,220.83 1427.59,226.24 1440.97,240.39 1445.43,254.82 1450.76,256.57 1453.93,259.62 1455.85,263.12 1456.93,266.2 
1460.78,272.18 1461.33,273.57 1462.59,274.89 1469.14,276.76 1470.82,277.57 1473.16,281.31 1477.31,293.64 1462.19,292.65 
1448.28,289.9 1435.15,288 1421.08,289.7 1411.94,288.51 1403.73,285.86 1393.46,287.4 1384.72,287.21 1380.36,283.88 
1372.51,284.71 1365.81,285.03 1363.17,281.28 1360.73,274.97 1358.52,268.46 1354.33,261.54 1351.57,254.7 1352.37,245.58 
1350.25,238.89 1356.41,235.62 1358.89,230.66 1351.48,228.67 1344.87,227.76 1344.76,217.58 1356.64,206.6 1350.87,200.65 
1345.65,200.46 1369.45,188.89 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1476.72,295.03 1482.37,356.24 1467.03,355.21 1461.38,293.99 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1480.21,294.72 1485.86,355.94 1482.37,356.24 1476.72,295.03 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sabran' ? 'active' : null}`}
            onClick={() => openPopup('sabran')}
          >
            <g>
              <polygon
                className="st101"
                points="1403.34,287.31 1408.99,348.52 1399.11,349.98 1393.46,288.76 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1393.46,288.76 1399.11,349.98 1390.56,349.79 1384.91,288.58 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1410.88,289.75 1416.53,350.97 1408.99,348.52 1403.34,287.31 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1420.99,291.1 1426.64,352.31 1416.53,350.97 1410.88,289.75 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1435.05,289.39 1440.7,350.61 1426.64,352.31 1420.99,291.1 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1384.91,288.58 1390.56,349.79 1383.68,357.79 1378.03,296.57 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1447.56,291.2 1453.21,352.42 1440.7,350.61 1435.05,289.39 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1461.59,293.97 1467.24,355.19 1453.21,352.42 1447.56,291.2 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1476.93,295.01 1482.58,356.22 1467.24,355.19 1461.59,293.97 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1477.96,294.94 1483.6,356.16 1482.58,356.22 1476.93,295.01 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1378.03,296.57 1383.68,357.79 1379.92,366.16 1374.27,304.94 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1376.8,307.92 1382.45,369.13 1379.92,366.16 1374.27,304.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1374.22,308.22 1379.86,369.43 1377.32,366.44 1371.68,305.23 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1376.8,307.92 1382.45,369.13 1380.84,372.72 1375.19,311.5 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1375.19,311.5 1380.84,372.72 1375.71,375.27 1370.06,314.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1481.47,305.41 1487.12,366.62 1483.6,356.16 1477.96,294.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1370.06,314.05 1375.71,375.27 1370.7,377.54 1365.05,316.33 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1483.87,309.11 1489.52,370.33 1487.12,366.62 1481.47,305.41 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1365.05,316.33 1370.7,377.54 1352.7,383.66 1347.05,322.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1485.45,313.33 1491.1,374.55 1489.52,370.33 1483.87,309.11 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1347.05,322.45 1352.7,383.66 1341.06,392.78 1335.41,331.57 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1485.45,313.33 1491.1,374.55 1490.61,379.2 1484.97,317.98 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1487.93,313.16 1493.58,374.38 1493.06,379.18 1487.41,317.97 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1343.68,339.08 1349.32,400.29 1341.06,392.78 1335.41,331.57 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1341.46,339.81 1347.11,401.03 1338.12,392.85 1332.48,331.63 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1462.7,330.05 1468.35,391.27 1457.93,392.13 1452.29,330.91 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1452.29,330.91 1457.93,392.13 1448.32,393.46 1442.67,332.24 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1442.67,332.24 1448.32,393.46 1427.82,391.69 1422.17,330.47 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1461.47,331.53 1467.12,392.74 1458.14,393.47 1452.49,332.26 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1452.49,332.26 1458.14,393.47 1447.91,394.84 1442.26,333.62 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1442.26,333.62 1447.91,394.84 1428.39,393.15 1422.74,331.94 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1422.17,330.47 1427.82,391.69 1414.01,398.8 1408.36,337.58 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1422.74,331.94 1428.39,393.15 1416.6,399.23 1410.95,338.01 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1471.44,336.31 1477.09,397.53 1468.35,391.27 1462.7,330.05 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1350.51,348.05 1356.16,409.27 1349.32,400.29 1343.68,339.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1347.72,348.04 1353.37,409.26 1347.11,401.03 1341.46,339.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1469.45,337.24 1475.1,398.45 1467.12,392.74 1461.47,331.53 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1484.97,317.98 1490.61,379.2 1485.34,398.11 1479.69,336.9 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1487.41,317.97 1493.06,379.18 1487.76,398.07 1482.11,336.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1410.04,345.52 1415.69,406.74 1414.01,398.8 1408.36,337.58 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1482.11,336.85 1487.76,398.07 1487.71,401.84 1482.06,340.62 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1350.51,348.06 1356.16,409.27 1349.28,414.91 1343.63,353.69 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="1479.69,336.9 1485.34,398.11 1485.26,402.38 1479.61,341.16 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1480.82,342.16 1486.47,403.38 1485.26,402.38 1479.61,341.16 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1479.82,342.17 1485.47,403.39 1477.09,397.53 1471.44,336.31 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1480.82,342.16 1486.47,403.38 1485.47,403.39 1479.82,342.17 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1343.63,353.69 1349.28,414.91 1340.5,417.47 1334.85,356.25 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1478.49,343.58 1484.14,404.79 1475.1,398.45 1469.45,337.24 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1485.47,343.46 1491.12,404.67 1484.14,404.79 1478.49,343.58 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1334.85,356.25 1340.5,417.47 1333.56,421.3 1327.91,360.08 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1410.04,345.52 1415.69,406.74 1412.23,414.55 1406.58,353.33 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1412.48,345.58 1418.13,406.8 1414.48,415.04 1408.83,353.82 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1406.58,353.33 1412.23,414.55 1407.94,417.7 1402.29,356.49 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1408.83,353.82 1414.48,415.04 1409.51,418.66 1403.86,357.44 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1391.8,358.86 1397.45,420.08 1391.86,420.21 1386.21,359 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1397.39,358.81 1403.04,420.03 1397.45,420.08 1391.8,358.86 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1402.29,356.49 1407.94,417.7 1403.04,420.03 1397.39,358.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1327.09,365.53 1332.74,426.75 1330.91,420.95 1325.26,359.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1329.56,365.48 1335.21,426.7 1333.56,421.3 1327.91,360.08 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1386.21,359 1391.86,420.21 1381.35,422.95 1375.7,361.74 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1391.59,360.25 1397.24,421.46 1391.95,421.57 1386.3,360.36 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1403.86,357.44 1409.51,418.66 1403.75,421.41 1398.1,360.19 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1398.1,360.19 1403.75,421.41 1397.24,421.46 1391.59,360.25 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1386.3,360.36 1391.95,421.57 1382.85,424.02 1377.2,362.8 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1375.7,361.74 1381.35,422.95 1374.16,428.55 1368.51,367.33 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1377.2,362.8 1382.85,424.02 1376.5,428.95 1370.85,367.73 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1329.56,365.48 1335.21,426.7 1332.89,434.85 1327.24,373.63 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1368.51,367.33 1374.16,428.55 1371.87,438.71 1366.22,377.49 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1370.85,367.73 1376.5,428.95 1374.31,438.66 1368.66,377.45 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1327.24,373.63 1332.89,434.85 1327.66,443.86 1322.01,382.64 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1333.89,387.65 1339.54,448.86 1327.66,443.86 1322.01,382.64 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1332.23,388.76 1337.88,449.98 1324.79,444.47 1319.14,383.26 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1366.22,377.49 1371.87,438.71 1370.95,448.31 1365.3,387.09 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1368.66,377.45 1374.31,438.66 1373.31,449.19 1367.66,387.97 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1365.3,387.09 1370.95,448.31 1360.96,450.66 1355.31,389.44 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1367.66,387.97 1373.31,449.19 1361.8,451.89 1356.15,390.68 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1345.33,393.09 1350.98,454.3 1339.54,448.86 1333.89,387.65 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1355.31,389.44 1360.96,450.66 1350.98,454.3 1345.33,393.09 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sabranColor ? sabranColor : null }}
                points="1435.05,289.39 1447.56,291.2 1461.59,293.97 1476.93,295.01 1477.96,294.94 1481.47,305.41 
1483.87,309.11 1485.45,313.33 1484.97,317.98 1479.69,336.9 1479.61,341.16 1480.82,342.16 1479.82,342.17 1471.44,336.31 
1462.7,330.05 1452.29,330.91 1442.67,332.24 1422.17,330.47 1408.36,337.58 1410.04,345.52 1406.58,353.33 1402.29,356.49 
1397.39,358.81 1391.8,358.86 1386.21,359 1375.7,361.74 1368.51,367.33 1366.22,377.49 1365.3,387.09 1355.31,389.44 
1345.33,393.09 1333.89,387.65 1322.01,382.64 1327.24,373.63 1329.56,365.48 1327.91,360.08 1334.85,356.25 1343.63,353.69 
1350.51,348.05 1343.68,339.08 1335.41,331.57 1347.05,322.45 1365.05,316.33 1370.06,314.05 1375.19,311.5 1376.8,307.92 
1374.27,304.94 1378.03,296.57 1384.91,288.58 1393.46,288.76 1403.34,287.31 1410.88,289.75 1420.99,291.1 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1344.92,394.8 1350.57,456.01 1337.88,449.98 1332.23,388.76 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1356.15,390.68 1361.8,451.89 1350.57,456.01 1344.92,394.8 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1482.06,340.62l3.41,2.84l-6.98,0.12l-9.04-6.34l-7.98-5.71l-8.98,0.73l-10.23,1.36l-19.52-1.68
l-11.79,6.07l1.53,7.57l-3.65,8.24l-4.97,3.62l-5.76,2.75l-6.51,0.06l-5.29,0.11l-9.1,2.44l-6.35,4.93l-2.19,9.72l-1,10.52
l-11.51,2.7l-11.23,4.12l-12.69-6.03l-13.09-5.51l5.72-9.81l2.23-7.92l-1.83-5.8l8.47-4.63l8.35-2.45l5.65-4.62l-6.26-8.23
l-8.99-8.18l12.98-10.18l18.36-6.21l4.79-2.2l4.44-2.2l1.17-2.63l-2.54-2.99l4.05-9l7.79-9.08l10.15,0.22l10.27-1.54l8.21,2.64
l9.14,1.19l14.08-1.7l13.13,1.9l13.91,2.75l14.91,1.01l2.67-0.24l3.87,11.5l2.38,3.7l1.71,4.56l-0.51,4.8l-5.3,18.89
L1482.06,340.62z M1479.82,342.17l1.01-0.01l-1.22-1l0.08-4.27l5.27-18.91l0.49-4.65l-1.58-4.22l-2.4-3.7l-3.52-10.46l-1.03,0.06
l-15.34-1.04l-14.03-2.77l-12.51-1.81l-14.06,1.7l-10.11-1.34l-7.55-2.45l-9.88,1.46l-8.55-0.19l-6.88,7.99l-3.75,8.37l2.52,2.98
l-1.6,3.58l-5.13,2.55l-5.01,2.28l-18.01,6.12l-11.63,9.12l8.26,7.51l6.84,8.98l-6.88,5.64l-8.78,2.56l-6.94,3.83l1.64,5.4
l-2.32,8.15l-5.23,9.01l11.89,5.01l11.44,5.44l9.98-3.64l9.99-2.35l0.92-9.6l2.29-10.16l7.19-5.6l10.51-2.74l5.59-0.14l5.59-0.05
l4.9-2.33l4.29-3.16l3.47-7.81l-1.69-7.94l13.81-7.11l20.5,1.77l9.62-1.33l10.42-0.86l8.74,6.26L1479.82,342.17"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'siyezen' ? 'active' : null}`}
            onClick={() => openPopup('siyezen')}
          >
            <g>
              <polygon
                className="st8"
                points="1461.47,331.52 1467.12,392.73 1458.14,393.47 1452.49,332.25 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1442.26,333.61 1447.91,394.83 1428.39,393.15 1422.74,331.93 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1452.49,332.25 1458.14,393.47 1447.91,394.83 1442.26,333.61 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1422.74,331.93 1428.39,393.15 1416.6,399.22 1410.95,338.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1469.45,337.23 1475.1,398.45 1467.12,392.73 1461.47,331.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1410.04,345.52 1415.69,406.73 1414,398.79 1408.36,337.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1412.48,345.58 1418.13,406.79 1416.6,399.22 1410.95,338 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1478.49,343.57 1484.14,404.78 1475.1,398.45 1469.45,337.23 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1482.07,343.5 1487.72,404.72 1484.14,404.78 1478.49,343.57 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1482.67,345.53 1488.32,406.74 1487.72,404.72 1482.07,343.5 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1412.48,345.58 1418.13,406.79 1414.48,415.03 1408.83,353.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1408.83,353.81 1414.48,415.03 1409.51,418.65 1403.86,357.44 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1391.59,360.24 1397.24,421.45 1391.95,421.57 1386.3,360.35 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1398.1,360.18 1403.75,421.4 1397.24,421.45 1391.59,360.24 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1403.86,357.44 1409.51,418.65 1403.75,421.4 1398.1,360.18 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1386.3,360.35 1391.95,421.57 1382.84,424.01 1377.2,362.79 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1377.2,362.79 1382.84,424.01 1376.5,428.94 1370.85,367.72 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1482.67,345.53 1488.32,406.74 1485.89,422.32 1480.24,361.11 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1485.12,345.41 1490.77,406.63 1488.36,422.17 1482.71,360.95 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1370.85,367.72 1376.5,428.94 1374.3,438.66 1368.66,377.44 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1427.03,373.51 1432.68,434.72 1424.98,435.48 1419.33,374.26 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1425.77,375 1431.42,436.22 1425.78,436.77 1420.14,375.56 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1419.33,374.26 1424.98,435.48 1418.69,438.17 1413.04,376.95 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1420.14,375.56 1425.78,436.77 1420.56,438.99 1414.91,377.78 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1432.44,377.6 1438.09,438.82 1432.68,434.72 1427.03,373.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1429.9,378.11 1435.55,439.32 1431.42,436.22 1425.77,375 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1413.04,376.95 1418.69,438.17 1415.68,441.58 1410.03,380.36 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1414.91,377.78 1420.56,438.99 1417.79,442.14 1412.14,380.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1487.7,375.42 1493.35,436.64 1485.89,422.32 1480.24,361.11 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1410.03,380.36 1415.68,441.58 1412.57,444.3 1406.92,383.09 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1368.66,377.44 1374.3,438.66 1373.41,448.11 1367.77,386.89 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1412.14,380.93 1417.79,442.14 1414.1,445.39 1408.45,384.18 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1406.92,383.09 1412.57,444.3 1402.57,446.49 1396.92,385.27 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1396.92,385.27 1402.57,446.49 1391.68,447.41 1386.03,386.2 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1376.14,387.35 1381.79,448.56 1373.41,448.11 1367.77,386.89 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1386.03,386.2 1391.68,447.41 1381.79,448.56 1376.14,387.35 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1397.15,386.62 1402.8,447.83 1391.84,448.76 1386.19,387.55 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1408.45,384.18 1414.1,445.39 1402.8,447.83 1397.15,386.62 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1375.8,388.72 1381.45,449.93 1370.84,449.38 1365.19,388.16 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1386.19,387.55 1391.84,448.76 1381.45,449.93 1375.8,388.72 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1432.44,377.6 1438.09,438.82 1436.35,446.83 1430.7,385.61 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="1435.92,388.76 1441.57,449.98 1436.35,446.83 1430.7,385.61 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1434.13,389.82 1439.78,451.03 1433.77,447.41 1428.12,386.19 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="1443.07,391.24 1448.72,452.45 1441.57,449.98 1435.92,388.76 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1441.35,392.31 1446.99,453.53 1439.78,451.03 1434.13,389.82 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="1452.24,396.26 1457.89,457.48 1448.72,452.45 1443.07,391.24 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1450.51,397.35 1456.16,458.56 1446.99,453.53 1441.35,392.31 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="1462.69,399.66 1468.34,460.88 1457.89,457.48 1452.24,396.26 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1461.41,400.92 1467.06,462.13 1456.16,458.56 1450.51,397.35 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1475.73,401.52 1481.38,462.73 1468.34,460.88 1462.69,399.66 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1489.67,400.63 1495.32,461.85 1481.38,462.73 1475.73,401.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1510.95,400.17 1516.6,461.39 1493.35,436.64 1487.7,375.42 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1501.23,400.46 1506.88,461.67 1495.32,461.85 1489.67,400.63 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1475.29,402.92 1480.94,464.14 1467.06,462.13 1461.41,400.92 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1489.56,402 1495.2,463.21 1480.94,464.14 1475.29,402.92 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1500.75,401.84 1506.4,463.06 1495.2,463.21 1489.56,402 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1511.79,401.55 1517.44,462.76 1516.6,461.39 1510.95,400.17 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1511.79,401.55 1517.44,462.76 1506.88,461.67 1501.23,400.46 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: siyezenColor ? siyezenColor : null }}
                points="1461.47,331.52 1469.45,337.23 1478.49,343.57 1482.07,343.5 1482.67,345.53 1480.24,361.11 
1487.7,375.42 1510.95,400.17 1511.79,401.55 1501.23,400.46 1489.67,400.63 1475.73,401.52 1462.69,399.66 1452.24,396.26 
1443.07,391.24 1435.92,388.76 1430.7,385.61 1432.44,377.6 1427.03,373.51 1419.33,374.26 1413.04,376.95 1410.03,380.36 
1406.92,383.09 1396.92,385.27 1386.03,386.2 1376.14,387.35 1367.77,386.89 1368.66,377.44 1370.85,367.72 1377.2,362.79 
1386.3,360.35 1391.59,360.24 1398.1,360.18 1403.86,357.44 1408.83,353.81 1412.48,345.58 1410.95,338 1422.74,331.93 
1442.26,333.61 1452.49,332.25 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1512.04,403 1517.69,464.21 1506.4,463.06 1500.75,401.84 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1515.11,402.59 1520.76,463.81 1517.69,464.21 1512.04,403 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1513.22,399.52l1.9,3.07l-3.08,0.41l-11.29-1.15l-11.19,0.16l-14.26,0.92l-13.88-2.01l-10.9-3.57
l-9.16-5.04l-7.21-2.49l-6.01-3.63l1.78-8.08l-4.13-3.1l-5.63,0.55l-5.22,2.22l-2.78,3.15l-3.69,3.25l-11.3,2.44l-10.96,0.93
l-10.39,1.17l-10.61-0.56l1.03-10.68l2.29-10.16l7.19-5.6l10.51-2.74l5.59-0.14l5.59-0.05l4.9-2.33l4.29-3.16l3.47-7.81
l-1.69-7.94l13.81-7.11l20.5,1.77l9.62-1.33l10.42-0.86l8.74,6.26l8.38,5.86l4.39-0.08l0.92,3.32l-2.41,15.54l7.28,13.83
L1513.22,399.52z M1501.23,400.46l10.56,1.09l-0.84-1.37l-23.25-24.75l-7.47-14.31l2.43-15.58l-0.59-2.03l-3.58,0.07l-9.04-6.34
l-7.98-5.71l-8.98,0.73l-10.23,1.36l-19.52-1.68l-11.79,6.07l1.53,7.57l-3.65,8.24l-4.97,3.62l-5.76,2.75l-6.51,0.06l-5.29,0.11
l-9.1,2.44l-6.35,4.93l-2.19,9.72l-0.89,9.45l8.38,0.45l9.89-1.15l10.89-0.92l10-2.19l3.11-2.73l3.01-3.41l6.29-2.69l7.7-0.75
l5.41,4.1l-1.74,8.01l5.22,3.15l7.15,2.48l9.17,5.02l10.45,3.4l13.04,1.86l13.94-0.89L1501.23,400.46"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'samaxi' ? 'active' : null}`}
            onClick={() => openPopup('samaxi')}
          >
            <g>
              <polygon
                className="st42"
                points="1266.53,367.23 1272.18,428.45 1252.3,426.59 1246.65,365.38 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1253.89,373.35 1259.54,434.56 1252.3,426.59 1246.65,365.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1251.76,374.15 1257.41,435.37 1247.75,424.75 1242.1,363.54 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1286.46,372.69 1292.11,433.9 1272.18,428.45 1266.53,367.23 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="1266.13,380.76 1271.78,441.97 1259.54,434.56 1253.89,373.35 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1263.54,381.32 1269.18,442.54 1257.41,435.37 1251.76,374.15 			"
              />
            </g>
            <g>
              <polygon
                className="st62"
                points="1302.68,378.76 1308.33,439.98 1292.11,433.9 1286.46,372.69 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1319.79,383.51 1325.44,444.73 1308.33,439.98 1302.68,378.76 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1266.13,380.76 1271.78,441.97 1269.02,452.09 1263.37,390.87 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1332.25,388.76 1337.9,449.98 1325.44,444.73 1319.79,383.51 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1263.37,390.87 1269.02,452.09 1256.02,460.4 1250.37,399.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1251.42,401.93 1257.07,463.15 1256.02,460.4 1250.37,399.19 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1248.99,402.29 1254.64,463.51 1253.36,460.14 1247.71,398.93 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1343.65,394.18 1349.3,455.4 1337.9,449.98 1332.25,388.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1251.95,404.9 1257.6,466.11 1257.07,463.15 1251.42,401.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1249.55,405.34 1255.2,466.55 1254.64,463.51 1248.99,402.29 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1258.3,410.82 1263.95,472.04 1257.6,466.11 1251.95,404.9 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1255.91,411.32 1261.56,472.53 1255.2,466.55 1249.55,405.34 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1343.65,394.18 1349.3,455.4 1340.85,465.59 1335.2,404.38 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1346.63,393.7 1352.28,454.92 1343.5,465.52 1337.85,404.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1257.95,418.01 1263.59,479.23 1261.56,472.53 1255.91,411.32 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1260.56,418.22 1266.21,479.43 1263.95,472.04 1258.3,410.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1341.54,414.02 1347.19,475.24 1340.85,465.59 1335.2,404.38 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1260.56,418.22 1266.21,479.43 1261.1,483.38 1255.45,422.17 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1341.54,414.02 1347.19,475.24 1334.88,477.9 1329.23,416.68 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1344.73,414.75 1350.38,475.97 1335.67,479.14 1330.02,417.93 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1255.45,422.17 1261.1,483.38 1255.79,486.91 1250.14,425.7 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1329.23,416.68 1334.88,477.9 1321.8,481.81 1316.15,420.59 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1250.14,425.7 1255.79,486.91 1255.4,488.26 1249.75,427.04 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1330.02,417.93 1335.67,479.14 1323.55,482.76 1317.9,421.55 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1249.75,427.04 1255.4,488.26 1254.22,490.66 1248.57,429.45 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1248.57,429.45 1254.22,490.66 1251.28,492.42 1245.63,431.21 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1245.63,431.21 1251.28,492.42 1248.1,494.03 1242.45,432.82 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1316.15,420.59 1321.8,481.81 1313.73,490.71 1308.08,429.49 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1317.9,421.55 1323.55,482.76 1316.07,491.03 1310.42,429.81 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1242.45,432.82 1248.1,494.03 1232.95,499.56 1227.3,438.34 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1227.3,438.34 1232.95,499.56 1226.15,507.6 1220.5,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1308.08,429.49 1313.73,490.71 1310.49,499.91 1304.85,438.7 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1310.42,429.81 1316.07,491.03 1312.71,500.53 1307.06,439.31 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1304.85,438.7 1310.49,499.91 1303.82,503.33 1298.17,442.12 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1307.06,439.31 1312.71,500.53 1305.45,504.25 1299.8,443.04 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1298.17,442.12 1303.82,503.33 1297.22,507.34 1291.57,446.12 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1225.32,452.48 1230.97,513.7 1226.15,507.6 1220.5,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1299.8,443.04 1305.45,504.25 1299.94,507.6 1294.29,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1223.3,453.48 1228.95,514.69 1223.45,507.72 1217.8,446.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1293.04,449.41 1298.68,510.63 1297.22,507.34 1291.57,446.12 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1234.35,455.79 1240,517.01 1230.97,513.7 1225.32,452.48 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1233,457.04 1238.65,518.26 1228.95,514.69 1223.3,453.48 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1296.34,451.82 1301.99,513.03 1298.68,510.63 1293.04,449.41 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1253.68,457.87 1259.33,519.08 1240,517.01 1234.35,455.79 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="1296.34,451.82 1301.99,513.03 1301.6,516.29 1295.95,455.08 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1252.42,459.16 1258.07,520.38 1238.65,518.26 1233,457.04 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1298.84,451.26 1304.49,512.47 1304.01,516.36 1298.36,455.15 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="1259.09,460.32 1264.74,521.53 1259.33,519.08 1253.68,457.87 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1257.29,461.36 1262.94,522.58 1258.07,520.38 1252.42,459.16 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1295.95,455.08 1301.6,516.29 1299.78,520.24 1294.13,459.02 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1298.36,455.15 1304.01,516.36 1302.2,520.35 1296.55,459.13 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1263.8,463.37 1269.45,524.59 1264.74,521.53 1259.09,460.32 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1262.03,464.43 1267.68,525.64 1262.94,522.58 1257.29,461.36 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1269.21,465.64 1274.86,526.86 1269.45,524.59 1263.8,463.37 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="1294.13,459.02 1299.78,520.24 1299.72,524.89 1294.07,463.67 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1296.55,459.13 1302.2,520.35 1302.14,524.83 1296.49,463.62 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1267.32,466.64 1272.97,527.85 1267.68,525.64 1262.03,464.43 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1273.63,468.73 1279.28,529.95 1274.86,526.86 1269.21,465.64 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1270.2,468.66 1275.85,529.88 1272.97,527.85 1267.32,466.64 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1294.07,463.67 1299.72,524.89 1298.63,529.44 1292.98,468.22 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1296.49,463.62 1302.14,524.83 1301.04,529.61 1295.39,468.39 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1273.63,468.73 1279.28,529.95 1272.02,533.29 1266.37,472.08 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1266.37,472.08 1272.02,533.29 1265.44,534.44 1259.79,473.22 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1259.79,473.22 1265.44,534.44 1249.59,536.78 1243.94,475.56 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1292.98,468.22 1298.63,529.44 1296.4,533.13 1290.75,471.92 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1295.39,468.39 1301.04,529.61 1298.84,533.23 1293.19,472.01 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1243.94,475.56 1249.59,536.78 1235.26,541.18 1229.61,479.96 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1293.19,472.01 1298.84,533.23 1298.68,536.16 1293.03,474.95 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="1290.75,471.92 1296.4,533.13 1296.23,536.41 1290.58,475.19 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1292.47,478.43 1298.12,539.65 1296.23,536.41 1290.58,475.19 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1229.61,479.96 1235.26,541.18 1232.05,545.87 1226.4,484.65 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1292.47,478.43 1298.12,539.65 1295.15,542.59 1289.5,481.37 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1295.16,478.42 1300.81,539.64 1297.11,543.28 1291.46,482.07 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1226.4,484.65 1232.05,545.87 1228.69,551.1 1223.04,489.88 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1223.04,489.88 1228.69,551.1 1220.92,555.14 1215.27,493.92 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1289.5,481.37 1295.15,542.59 1285.06,549.47 1279.41,488.26 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1291.46,482.07 1297.11,543.28 1287,550.2 1281.35,488.99 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1215.27,493.92 1220.92,555.14 1214.34,558.12 1208.69,496.91 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1279.41,488.26 1285.06,549.47 1281.49,553.56 1275.84,492.35 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1281.35,488.99 1287,550.2 1283.3,554.47 1277.65,493.26 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1217.53,499.06 1223.18,560.27 1214.34,558.12 1208.69,496.91 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1275.84,492.35 1281.49,553.56 1275.81,555.42 1270.16,494.21 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1270.16,494.21 1275.81,555.42 1265.86,557.42 1260.21,496.21 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1216.31,500.31 1221.96,561.53 1209.71,558.56 1204.07,497.34 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1277.65,493.26 1283.3,554.47 1276.53,556.69 1270.88,495.47 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1270.88,495.47 1276.53,556.69 1268.41,558.32 1262.76,497.11 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1228.03,501.98 1233.67,563.19 1223.18,560.27 1217.53,499.06 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1262.76,497.11 1268.41,558.32 1268.57,560.61 1262.92,499.39 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1226.39,503.1 1232.04,564.31 1221.96,561.53 1216.31,500.31 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1260.21,496.21 1265.86,557.42 1266.2,561.85 1260.56,500.64 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1272.61,501.44 1278.26,562.65 1266.2,561.85 1260.56,500.64 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1282.41,501.66 1288.05,562.87 1278.26,562.65 1272.61,501.44 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1237.7,506.88 1243.35,568.09 1233.67,563.19 1228.03,501.98 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1236.02,507.98 1241.67,569.2 1232.04,564.31 1226.39,503.1 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1282.41,501.66 1288.05,562.87 1281.52,569.66 1275.87,508.45 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1248.12,511.18 1253.77,572.4 1243.35,568.09 1237.7,506.88 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1286.3,500.37 1291.95,561.58 1283.36,570.51 1277.71,509.3 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1246.49,512.32 1252.14,573.53 1241.67,569.2 1236.02,507.98 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1275.87,508.45 1281.52,569.66 1271.29,574.07 1265.64,512.86 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1265.64,512.86 1271.29,574.07 1261.67,575.77 1256.03,514.55 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1256.03,514.55 1261.67,575.77 1253.77,572.4 1248.12,511.18 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: samaxiColor ? samaxiColor : null }}
                points="1246.65,365.38 1266.53,367.23 1286.46,372.69 1302.68,378.76 1319.79,383.51 1332.25,388.76 
1343.65,394.18 1335.2,404.38 1341.54,414.02 1329.23,416.68 1316.15,420.59 1308.08,429.49 1304.85,438.7 1298.17,442.12 
1291.57,446.12 1293.04,449.41 1296.34,451.82 1295.95,455.08 1294.13,459.02 1294.07,463.67 1292.98,468.22 1290.75,471.92 
1290.58,475.19 1292.47,478.43 1289.5,481.37 1279.41,488.26 1275.84,492.35 1270.16,494.21 1260.21,496.21 1260.56,500.64 
1272.61,501.44 1282.41,501.66 1275.87,508.45 1265.64,512.86 1256.03,514.55 1248.12,511.18 1237.7,506.88 1228.03,501.98 
1217.53,499.06 1208.69,496.91 1215.27,493.92 1223.04,489.88 1226.4,484.65 1229.61,479.96 1243.94,475.56 1259.79,473.22 
1266.37,472.08 1273.63,468.73 1269.21,465.64 1263.8,463.37 1259.09,460.32 1253.68,457.87 1234.35,455.79 1225.32,452.48 
1220.5,446.38 1227.3,438.34 1242.45,432.82 1245.63,431.21 1248.57,429.45 1249.75,427.04 1250.14,425.7 1255.45,422.17 
1260.56,418.22 1258.3,410.82 1251.95,404.9 1251.42,401.93 1250.37,399.19 1263.37,390.87 1266.13,380.76 1253.89,373.35 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1277.71,509.3 1283.36,570.51 1272.02,575.34 1266.37,514.12 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1255.31,516.07 1260.96,577.29 1252.14,573.53 1246.49,512.32 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1266.37,514.12 1272.02,575.34 1260.96,577.29 1255.31,516.07 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1330.02,417.93l-12.12,3.62l-7.48,8.26l-3.36,9.5l-7.27,3.73l-5.51,3.34l1.07,2.43l3.48,2.45l-0.48,3.89
l-1.82,3.99l-0.05,4.49l-1.1,4.78l-2.2,3.62l-0.16,2.93l2.13,3.48l-3.7,3.64l-10.11,6.92l-3.7,4.27l-6.77,2.21l-8.12,1.64
l0.16,2.28l10.16,0.67l13.22,0.3l-8.59,8.93l-11.34,4.82l-11.07,1.95l-8.81-3.76l-10.47-4.33l-9.63-4.89l-10.07-2.78l-12.25-2.97
l9.72-4.42l7.18-3.73l3.15-4.88l3.59-5.26l15.7-4.8l15.96-2.34l6.27-1.1l4.57-2.15l-2.88-2.03l-5.28-2.21l-4.74-3.07l-4.87-2.2
l-19.42-2.12l-9.7-3.57l-5.5-6.97l7.65-9.05l15.65-5.68l2.92-1.49l2.38-1.42l0.97-1.96l0.46-1.68l5.73-3.82l4.37-3.38l-2.03-6.7
l-6.37-5.98l-0.56-3.05l-1.27-3.36l13.34-8.55l2.48-9.06l-11.78-7.17l-9.66-10.62l25.55,2.4l20.2,5.54l16.22,6.07l17.17,4.77
l12.67,5.32l12.72,6.06l-8.78,10.6l6.87,10.45L1330.02,417.93z M1308.08,429.49l8.07-8.9l13.08-3.91l12.31-2.66l-6.34-9.65
l8.46-10.19l-11.4-5.42l-12.46-5.25l-17.11-4.75l-16.22-6.07l-19.93-5.46l-19.88-1.85l7.25,7.97l12.24,7.41l-2.76,10.12
l-13.01,8.31l1.06,2.75l0.53,2.97l6.35,5.93l2.26,7.39l-5.11,3.95l-5.31,3.53l-0.39,1.34l-1.18,2.41l-2.94,1.76l-3.18,1.61
l-15.15,5.52l-6.8,8.04l4.82,6.1l9.02,3.31l19.33,2.07l5.41,2.45l4.71,3.05l5.41,2.27l4.42,3.09l-7.26,3.34l-6.58,1.15
l-15.84,2.34l-14.33,4.4l-3.22,4.69l-3.36,5.23l-7.77,4.04l-6.58,2.98l8.84,2.15l10.5,2.92l9.67,4.9l10.42,4.3l7.91,3.37
l9.61-1.7l10.23-4.41l6.54-6.79l-9.8-0.22l-12.05-0.8l-0.35-4.43l9.96-2l5.67-1.86l3.57-4.09l10.09-6.89l2.97-2.94l-1.89-3.24
l0.17-3.28l2.23-3.69l1.08-4.55l0.07-4.65l1.82-3.95l0.38-3.26l-3.3-2.41l-1.46-3.29l6.6-4l6.68-3.42L1308.08,429.49"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'xizi' ? 'active' : null}`}
            onClick={() => openPopup('xizi')}
          >
            <g>
              <polygon
                className="st1"
                points="1425.77,375 1431.42,436.22 1425.78,436.77 1420.14,375.56 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1420.14,375.56 1425.78,436.77 1420.56,438.99 1414.91,377.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1429.9,378.11 1435.55,439.32 1431.42,436.22 1425.77,375 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1414.91,377.78 1420.56,438.99 1417.79,442.14 1412.14,380.93 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1412.14,380.93 1417.79,442.14 1414.1,445.39 1408.45,384.18 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1397.15,386.62 1402.8,447.83 1391.84,448.76 1386.19,387.55 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1408.45,384.18 1414.1,445.39 1402.8,447.83 1397.15,386.62 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1375.8,388.72 1381.45,449.93 1372.18,449.44 1366.54,388.23 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1386.19,387.55 1391.84,448.76 1381.45,449.93 1375.8,388.72 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1366.54,388.23 1372.18,449.44 1361.79,451.89 1356.15,390.67 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1432.44,377.6 1438.09,438.82 1436.35,446.83 1430.7,385.61 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1429.9,378.11 1435.55,439.32 1433.77,447.41 1428.12,386.19 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1356.15,390.67 1361.79,451.89 1351.7,455.6 1346.05,394.38 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1434.13,389.82 1439.78,451.03 1433.77,447.41 1428.12,386.19 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1441.35,392.31 1446.99,453.53 1439.78,451.03 1434.13,389.82 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1346.05,394.38 1351.7,455.6 1343.48,465.51 1337.83,404.3 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1450.51,397.35 1456.16,458.56 1446.99,453.53 1441.35,392.31 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1461.41,400.91 1467.06,462.13 1456.16,458.56 1450.51,397.35 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1344.12,413.87 1349.77,475.08 1343.48,465.51 1337.83,404.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1342.1,414.9 1347.75,476.12 1340.83,465.59 1335.18,404.37 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1475.29,402.92 1480.94,464.14 1467.06,462.13 1461.41,400.91 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1489.55,402 1495.2,463.21 1480.94,464.14 1475.29,402.92 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1500.75,401.84 1506.4,463.06 1495.2,463.21 1489.55,402 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1512.58,402.93 1518.23,464.15 1506.4,463.06 1500.75,401.84 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="1359.01,418.1 1364.66,479.32 1349.77,475.08 1344.12,413.87 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1357.21,419.16 1362.86,480.37 1347.75,476.12 1342.1,414.9 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1513.48,406.53 1519.12,467.74 1518.23,464.15 1512.58,402.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1518.29,410.2 1523.94,471.42 1519.12,467.74 1513.48,406.53 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1370.95,425.92 1376.6,487.14 1364.66,479.32 1359.01,418.1 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1368.95,426.82 1374.6,488.03 1362.86,480.37 1357.21,419.16 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1375.99,430.15 1381.64,491.37 1376.6,487.14 1370.95,425.92 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1529.28,416.06 1534.93,477.28 1523.94,471.42 1518.29,410.2 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1535.86,415.67 1541.51,476.88 1534.93,477.28 1529.28,416.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1373.81,430.9 1379.46,492.12 1374.6,488.03 1368.95,426.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1539.44,418.43 1545.09,479.65 1541.51,476.88 1535.86,415.67 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1379.97,435.24 1385.62,496.46 1381.64,491.37 1375.99,430.15 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1377.75,435.96 1383.4,497.17 1379.46,492.12 1373.81,430.9 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1542.75,422.47 1548.4,483.69 1545.09,479.65 1539.44,418.43 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1547.64,424.86 1553.28,486.08 1548.4,483.69 1542.75,422.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1549.41,426.83 1555.06,488.04 1553.28,486.08 1547.64,424.86 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1387.63,442.99 1393.28,504.21 1385.62,496.46 1379.97,435.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1385.58,443.86 1391.23,505.08 1383.4,497.17 1377.75,435.96 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1549.41,426.83 1555.06,488.04 1551.95,492.15 1546.3,430.93 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1552.11,426.63 1557.75,487.85 1554.07,492.69 1548.42,431.47 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1397.17,449.16 1402.82,510.38 1393.28,504.21 1387.63,442.99 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1395.37,450.2 1401.02,511.42 1391.23,505.08 1385.58,443.86 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1546.3,430.93 1551.95,492.15 1543.26,499.69 1537.61,438.47 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1548.42,431.47 1554.07,492.69 1545.35,500.28 1539.7,439.07 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="1410.94,453.52 1416.59,514.73 1402.82,510.38 1397.17,449.16 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1537.61,438.47 1543.26,499.69 1539.59,503.73 1533.95,442.52 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1409.09,454.58 1414.74,515.79 1401.02,511.42 1395.37,450.2 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1539.7,439.07 1545.35,500.28 1541.81,504.17 1536.16,442.95 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1533.95,442.52 1539.59,503.73 1538.08,506.05 1532.43,444.83 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1536.16,442.95 1541.81,504.17 1540.42,506.27 1534.77,445.05 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="1532.43,444.83 1538.08,506.05 1537.31,508.43 1531.66,447.21 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1534.77,445.05 1540.42,506.27 1539.75,508.39 1534.1,447.17 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1532.19,448.92 1537.83,510.13 1537.31,508.43 1531.66,447.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1534.68,452.01 1540.33,513.22 1537.83,510.13 1532.19,448.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1534.87,452.69 1540.52,513.9 1540.33,513.22 1534.68,452.01 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1534.12,452.85 1539.77,514.07 1529.38,513.9 1523.73,452.68 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1534.87,452.69 1540.52,513.9 1539.77,514.07 1534.12,452.85 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1537.78,453.48 1543.43,514.69 1539.68,515.45 1534.03,454.23 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1534.03,454.23 1539.68,515.45 1530.56,515.3 1524.91,454.09 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1523.73,452.68 1529.38,513.9 1524.58,518.22 1518.93,457.01 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1524.91,454.09 1530.56,515.3 1526.4,519.03 1520.75,457.82 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1518.93,457.01 1524.58,518.22 1516.58,523.12 1510.93,461.91 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1520.75,457.82 1526.4,519.03 1517.84,524.23 1512.19,463.01 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1510.93,461.91 1516.58,523.12 1506.99,526.36 1501.34,465.15 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1430.5,472.08 1436.15,533.29 1416.59,514.73 1410.94,453.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1428.44,472.97 1434.09,534.18 1414.74,515.79 1409.09,454.58 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1512.19,463.01 1517.84,524.23 1508.23,527.47 1502.58,466.25 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1501.34,465.15 1506.99,526.36 1501.95,528.64 1496.3,467.42 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1502.58,466.25 1508.23,527.47 1503.43,529.62 1497.78,468.41 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1496.3,467.42 1501.95,528.64 1497.45,531 1491.81,469.79 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1497.78,468.41 1503.43,529.62 1499.25,531.85 1493.6,470.63 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1445.77,479.08 1451.42,540.29 1436.15,533.29 1430.5,472.08 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1444.57,480.4 1450.22,541.61 1434.09,534.18 1428.44,472.97 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1491.81,469.79 1497.45,531 1491.17,538.67 1485.52,477.45 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1466.04,480.44 1471.69,541.66 1451.42,540.29 1445.77,479.08 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1485.52,477.45 1491.17,538.67 1471.69,541.66 1466.04,480.44 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1539.7,439.07l-3.55,3.89l-1.39,2.1l-0.67,2.12l0.47,1.25l2.52,3.13l0.39,1.28l0.3,0.64l-3.75,0.76
l-9.12-0.15l-4.16,3.73l-8.56,5.2l-9.61,3.24l-4.79,2.16l-4.18,2.22l-6.42,7.95l-21.47,3.25l-21.15-1.43l-16.13-7.43
l-19.35-18.39l-13.72-4.38l-9.79-6.34l-7.83-7.9l-3.94-5.05l-4.86-4.09l-11.74-7.66l-15.11-4.26l-6.92-10.53l9.02-10.87
l11.11-4.07l11.11-2.61l9.72,0.52l9.89-1.15l10.89-0.92l10-2.19l3.11-2.73l3.01-3.41l6.29-2.69l7.7-0.75l5.41,4.1l-1.74,8.01
l5.22,3.15l7.15,2.48l9.17,5.02l10.45,3.4l13.04,1.86l13.94-0.89l11.56-0.17l11.03,1.13l2.08-0.28l0.57,0.96l0.91,3.61l4.39,3.36
l10.14,5.4l6.83-0.41l4.4,3.42l3.33,4l4.84,2.39l2.35,2.59l-3.69,4.84L1539.7,439.07z M1531.66,447.21l0.77-2.38l1.52-2.32
l3.66-4.04l8.69-7.54l3.11-4.1l-1.78-1.97l-4.89-2.39l-3.31-4.04l-3.58-2.76l-6.58,0.39l-10.99-5.86l-4.81-3.67l-0.9-3.6
l-11.83-1.09l-11.19,0.16l-14.26,0.92l-13.88-2.01l-10.9-3.57l-9.16-5.04l-7.21-2.49l-6.01-3.63l1.78-8.08l-4.13-3.1l-5.63,0.55
l-5.22,2.22l-2.78,3.15l-3.69,3.25l-11.3,2.44l-10.96,0.93l-10.39,1.17l-9.27-0.49l-10.39,2.45l-10.1,3.71l-8.22,9.92l6.29,9.57
l14.89,4.24l11.93,7.82l5.04,4.23l3.99,5.09l7.66,7.75l9.54,6.17l13.77,4.36l19.56,18.56l15.27,7l20.27,1.36l19.48-2.99
l6.28-7.66l4.49-2.36l5.05-2.28l9.59-3.24l8-4.9l4.8-4.33l10.4,0.17l0.74-0.16l-0.19-0.68l-2.49-3.09L1531.66,447.21"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: xiziColor ? xiziColor : null }}
                points="1425.77,375 1429.9,378.11 1428.12,386.19 1434.13,389.82 1441.35,392.31 1450.51,397.35 
1461.41,400.91 1475.29,402.92 1489.55,402 1500.75,401.84 1512.04,403 1513.48,406.53 1518.29,410.2 1529.28,416.06 
1535.86,415.67 1539.44,418.43 1542.75,422.47 1547.64,424.86 1549.41,426.83 1546.3,430.93 1537.61,438.47 1533.95,442.52 
1532.43,444.83 1531.66,447.21 1532.19,448.92 1534.68,452.01 1534.87,452.69 1534.12,452.85 1523.73,452.68 1518.93,457.01 
1510.93,461.91 1501.34,465.15 1496.3,467.42 1491.81,469.79 1485.52,477.45 1466.04,480.44 1445.77,479.08 1430.5,472.08 
1410.94,453.52 1397.17,449.16 1387.63,442.99 1379.97,435.24 1375.99,430.15 1370.95,425.92 1359.01,418.1 1344.12,413.87 
1337.83,404.3 1346.05,394.38 1356.15,390.67 1366.54,388.23 1375.8,388.72 1386.19,387.55 1397.15,386.62 1408.45,384.18 
1412.14,380.93 1414.91,377.78 1420.14,375.56 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1493.6,470.63 1499.25,531.85 1492.84,539.79 1487.19,478.58 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1465.72,481.83 1471.37,543.04 1450.22,541.61 1444.57,480.4 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1487.19,478.58 1492.84,539.79 1471.37,543.04 1465.72,481.83 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qobustan' ? 'active' : null}`}
            onClick={() => openPopup('qobustan')}
          >
            <g>
              <polygon
                className="st80"
                points="1342.92,415.14 1348.57,476.36 1335.65,479.14 1330,417.93 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1330,417.93 1335.65,479.14 1323.52,482.76 1317.88,421.55 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1357.21,419.16 1362.86,480.37 1348.57,476.36 1342.92,415.14 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1317.88,421.55 1323.52,482.76 1316.05,491.02 1310.4,429.81 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1368.95,426.82 1374.6,488.03 1362.86,480.37 1357.21,419.16 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1373.81,430.9 1379.46,492.12 1374.6,488.03 1368.95,426.82 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1310.4,429.81 1316.05,491.02 1312.69,500.53 1307.04,439.31 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1307.04,439.31 1312.69,500.53 1305.42,504.25 1299.78,443.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1377.75,435.96 1383.4,497.17 1379.46,492.12 1373.81,430.9 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1299.78,443.04 1305.42,504.25 1299.92,507.6 1294.27,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1295.34,448.81 1300.99,510.02 1299.92,507.6 1294.27,446.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1293.01,449.41 1298.66,510.62 1297.2,507.33 1291.55,446.12 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1298.82,451.26 1304.47,512.47 1300.99,510.02 1295.34,448.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1296.32,451.82 1301.97,513.03 1298.66,510.62 1293.01,449.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1385.58,443.86 1391.23,505.08 1383.4,497.17 1377.75,435.96 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1298.82,451.26 1304.47,512.47 1303.99,516.36 1298.34,455.14 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1298.34,455.14 1303.99,516.36 1302.17,520.35 1296.52,459.13 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1395.37,450.2 1401.02,511.42 1391.23,505.08 1385.58,443.86 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1296.52,459.13 1302.17,520.35 1302.12,524.83 1296.47,463.62 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1409.09,454.58 1414.74,515.79 1401.02,511.42 1395.37,450.2 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1296.47,463.62 1302.12,524.83 1301.02,529.61 1295.37,468.39 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1295.37,468.39 1301.02,529.61 1298.82,533.23 1293.17,472.01 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1293.17,472.01 1298.82,533.23 1298.65,536.16 1293.01,474.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1292.45,478.43 1298.1,539.64 1296.21,536.41 1290.56,475.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1295.14,478.42 1300.78,539.64 1298.65,536.16 1293.01,474.94 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1295.14,478.42 1300.78,539.64 1297.09,543.28 1291.44,482.07 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1428.44,472.97 1434.09,534.18 1414.74,515.79 1409.09,454.58 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1291.44,482.07 1297.09,543.28 1286.98,550.2 1281.33,488.99 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1281.33,488.99 1286.98,550.2 1283.28,554.47 1277.63,493.26 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1277.63,493.26 1283.28,554.47 1276.51,556.68 1270.86,495.47 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1443.42,479.88 1449.07,541.09 1434.09,534.18 1428.44,472.97 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1270.86,495.47 1276.51,556.68 1268.38,558.32 1262.74,497.11 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1262.74,497.11 1268.38,558.32 1268.55,560.61 1262.9,499.39 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1443.42,479.88 1449.07,541.09 1441.25,546.12 1435.61,484.91 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1273.06,500.06 1278.71,561.28 1268.55,560.61 1262.9,499.39 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1446.93,479.6 1452.58,540.81 1442.64,547.18 1436.99,485.96 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1272.59,501.44 1278.24,562.65 1266.18,561.85 1260.53,500.64 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1284.92,500.33 1290.57,561.55 1278.71,561.28 1273.06,500.06 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1283.77,501.69 1289.42,562.9 1278.24,562.65 1272.59,501.44 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1435.61,484.91 1441.25,546.12 1431.64,549.84 1425.99,488.62 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1436.99,485.96 1442.64,547.18 1432.76,550.99 1427.11,489.78 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1425.99,488.62 1431.64,549.84 1422.05,552.8 1416.4,491.58 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1407.16,492.96 1412.81,554.17 1403.65,554.34 1398.01,493.12 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1416.4,491.58 1422.05,552.8 1412.81,554.17 1407.16,492.96 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1398.01,493.12 1403.65,554.34 1393.62,556.46 1387.97,495.24 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1427.11,489.78 1432.76,550.99 1422.59,554.1 1416.94,492.88 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1407.11,494.33 1412.76,555.54 1403.67,555.72 1398.02,494.5 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1416.94,492.88 1422.59,554.1 1412.76,555.54 1407.11,494.33 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1398.02,494.5 1403.67,555.72 1394.92,557.59 1389.28,496.38 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1296.54,505.85 1302.19,567.07 1290.57,561.55 1284.92,500.33 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1295.39,507.21 1301.04,568.42 1289.42,562.9 1283.77,501.69 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1387.97,495.24 1393.62,556.46 1387.01,560.82 1381.36,499.6 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1312.3,506.29 1317.94,567.5 1302.19,567.07 1296.54,505.85 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1389.28,496.38 1394.92,557.59 1388.41,561.84 1382.76,500.63 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1311.3,507.64 1316.95,568.86 1301.04,568.42 1295.39,507.21 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1381.36,499.6 1387.01,560.82 1380.27,563.48 1374.62,502.26 			"
              />
            </g>
            <g>
              <rect
                x="1366.93"
                y="502.62"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -43.2025 128.3406)"
                className="st121"
                width="10.54"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1382.76,500.63 1388.41,561.84 1380.99,564.78 1375.34,503.56 			"
              />
            </g>
            <g>
              <rect
                x="1367.67"
                y="503.92"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -43.319 128.4131)"
                className="st8"
                width="10.52"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1321.34,509.55 1326.99,570.77 1317.94,567.5 1312.3,506.29 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1319.58,510.61 1325.22,571.83 1316.95,568.86 1311.3,507.64 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1364.13,503.23 1369.78,564.45 1351.59,571.66 1345.94,510.44 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1328.69,513.88 1334.33,575.1 1326.99,570.77 1321.34,509.55 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1345.94,510.44 1351.59,571.66 1343.65,574.73 1338.01,513.51 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1326.83,514.89 1332.48,576.11 1325.22,571.83 1319.58,510.61 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1364.87,504.54 1370.52,565.75 1344.94,575.81 1339.29,514.59 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1338.01,513.51 1343.65,574.73 1338.02,577.17 1332.37,515.95 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="1332.37,515.95 1338.02,577.17 1334.33,575.1 1328.69,513.88 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1430.5,472.08l16.43,7.52l-9.93,6.36l-9.88,3.82l-10.17,3.1l-9.83,1.45l-9.09,0.17l-8.74,1.88l-6.51,4.25
l-7.42,2.93l-10.47,0.97l-25.57,10.06l-7.34,3.17l-5.12-2.87l-7.26-4.28l-8.28-2.97l-15.91-0.44l-11.62-5.52l-11.18-0.25
l-12.05-0.8l-0.35-4.43l9.96-2l5.67-1.86l3.57-4.09l10.09-6.89l2.97-2.94l-1.89-3.24l0.17-3.28l2.23-3.69l1.08-4.55l0.07-4.65
l1.82-3.95l0.38-3.26l-3.3-2.41l-1.46-3.29l6.6-4l6.68-3.42l3.24-9.21l8.07-8.9l13.08-3.91l14.09-3.04l15.72,4.46l11.93,7.82
l5.04,4.23l3.99,5.09l7.66,7.75l9.54,6.17l13.77,4.36L1430.5,472.08z M1435.61,484.91l7.82-5.03l-14.98-6.91l-19.35-18.39
l-13.72-4.38l-9.79-6.34l-7.83-7.9l-3.94-5.05l-4.86-4.09l-11.74-7.66l-14.28-4.02l-12.93,2.79l-12.12,3.62l-7.48,8.26l-3.36,9.5
l-7.27,3.73l-5.51,3.34l1.07,2.43l3.48,2.45l-0.48,3.89l-1.82,3.99l-0.05,4.49l-1.1,4.78l-2.2,3.62l-0.16,2.93l2.13,3.48
l-3.7,3.64l-10.11,6.92l-3.7,4.27l-6.77,2.21l-8.12,1.64l0.16,2.28l10.16,0.67l11.86,0.27l11.62,5.52l15.76,0.43l9.05,3.27
l7.34,4.33l3.68,2.07l5.64-2.44l7.93-3.07l18.19-7.21l10.49-0.97l6.74-2.66l6.61-4.36l10.04-2.12l9.16-0.17l9.24-1.37l9.59-2.96
L1435.61,484.91"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qobustanColor ? qobustanColor : null }}
                points="1342.92,415.14 1357.21,419.16 1368.95,426.82 1373.81,430.9 1377.75,435.96 1385.58,443.86 
1395.37,450.2 1409.09,454.58 1428.44,472.97 1443.42,479.88 1435.61,484.91 1425.99,488.62 1416.4,491.58 1407.16,492.96 
1398.01,493.12 1387.97,495.24 1381.36,499.6 1374.62,502.26 1364.13,503.23 1345.94,510.44 1338.01,513.51 1332.37,515.95 
1328.69,513.88 1321.34,509.55 1312.3,506.29 1296.54,505.85 1284.92,500.33 1273.06,500.06 1262.9,499.39 1262.74,497.11 
1270.86,495.47 1277.63,493.26 1281.33,488.99 1291.44,482.07 1295.14,478.42 1293.01,474.94 1293.17,472.01 1295.37,468.39 
1296.47,463.62 1296.53,459.13 1298.34,455.14 1298.82,451.26 1295.34,448.81 1294.27,446.38 1299.78,443.04 1307.04,439.31 
1310.4,429.81 1317.88,421.55 1330,417.93 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1339.29,514.59 1344.94,575.81 1337.61,578.98 1331.96,517.77 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1331.96,517.77 1337.61,578.98 1332.48,576.11 1326.83,514.89 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sumqayit' ? 'active' : null}`}
            onClick={() => openPopup('sumqayit')}
          >
            <g>
              <polygon
                className="st35"
                points="1535.43,453.99 1541.08,515.2 1539.68,515.45 1534.03,454.23 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1534.03,454.23 1539.68,515.45 1530.56,515.3 1524.91,454.09 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1536.18,455.4 1541.83,516.62 1541.08,515.2 1535.43,453.99 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1524.91,454.09 1530.56,515.3 1527.07,518.45 1521.42,457.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1540.1,458.77 1545.75,519.98 1541.83,516.62 1536.18,455.4 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1529.88,462.12 1535.53,523.34 1527.07,518.45 1521.42,457.24 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1527.74,462.93 1533.39,524.15 1523.99,518.76 1518.35,457.54 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1544.45,461.62 1550.1,522.84 1545.75,519.98 1540.1,458.77 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1547.91,463.05 1553.56,524.26 1550.1,522.84 1544.45,461.62 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1560.2,464.62 1565.85,525.84 1553.56,524.26 1547.91,463.05 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1565.02,465.89 1570.66,527.1 1565.85,525.84 1560.2,464.62 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1536.53,469.52 1542.18,530.73 1535.53,523.34 1529.88,462.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1534.16,470.06 1539.81,531.28 1533.39,524.15 1527.74,462.93 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1568.58,467.65 1574.23,528.87 1570.66,527.1 1565.02,465.89 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1538.82,474.64 1544.46,535.85 1542.18,530.73 1536.53,469.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1536.47,475.15 1542.12,536.36 1539.81,531.28 1534.16,470.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1576.1,473.58 1581.75,534.79 1574.23,528.87 1568.58,467.65 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1576.94,473.97 1582.58,535.18 1581.75,534.79 1576.1,473.58 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1576.94,473.97 1582.58,535.18 1581.33,536.58 1575.68,475.36 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1541.42,479.09 1547.07,540.3 1544.46,535.85 1538.82,474.64 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1580.04,473.46 1585.69,534.68 1583.24,537.32 1577.59,476.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1539.43,480.25 1545.08,541.47 1542.12,536.36 1536.47,475.15 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1575.68,475.36 1581.33,536.58 1578.06,538.74 1572.41,477.53 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1577.59,476.11 1583.24,537.32 1579.84,539.57 1574.2,478.36 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1572.41,477.53 1578.06,538.74 1574.93,540.73 1569.28,479.51 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1554.42,481.03 1560.07,542.24 1547.07,540.3 1541.42,479.09 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1569.28,479.51 1574.93,540.73 1560.07,542.24 1554.42,481.03 			"
              />
            </g>
            <g>
              <polygon
                className={`st59`}
                style={{ fill: sumqayitColor ? sumqayitColor : null }}
                points="1535.43,453.99 1536.18,455.4 1540.1,458.77 1544.45,461.62 1547.91,463.05 1560.2,464.62 
1565.02,465.89 1568.58,467.65 1576.1,473.58 1576.94,473.97 1575.68,475.36 1572.41,477.53 1569.28,479.51 1554.42,481.03 
1541.42,479.09 1538.82,474.64 1536.53,469.52 1529.88,462.12 1521.42,457.24 1524.91,454.09 1534.03,454.23 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1574.2,478.36 1579.84,539.57 1576.02,541.98 1570.37,480.77 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1570.37,480.77 1576.02,541.98 1559.7,543.64 1554.05,482.43 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1554.05,482.43 1559.7,543.64 1545.08,541.47 1539.43,480.25 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1578.06,472.63l1.98,0.84l-2.45,2.64l-3.4,2.25l-3.82,2.41l-16.32,1.66l-14.62-2.17l-2.96-5.11l-2.3-5.09
l-6.42-7.13l-9.4-5.39l5.38-4.86l10.4,0.17l3.02-0.65l1.35,2.59l3.61,3.07l4.2,2.75l3.02,1.22l11.98,1.51l5.33,1.41l3.91,1.96
L1578.06,472.63z M1575.68,475.36l1.26-1.39l-0.84-0.39l-7.52-5.93l-3.56-1.77l-4.82-1.26l-12.29-1.57l-3.46-1.42l-4.36-2.86
l-3.92-3.37l-0.75-1.42l-1.4,0.25l-9.12-0.15l-3.49,3.15l8.46,4.89l6.65,7.39l2.29,5.12l2.6,4.45l13,1.94l14.86-1.52l3.13-1.98
L1575.68,475.36"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'abseron' ? 'active' : null}`}
            onClick={() => openPopup('abseron')}
          >
            <g>
              <polygon
                className="st43"
                points="1519.82,458.38 1525.47,519.59 1517.85,524.22 1512.2,463 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1527.75,462.92 1533.4,524.14 1525.47,519.59 1519.82,458.38 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1512.2,463 1517.85,524.22 1508.24,527.46 1502.59,466.24 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1502.59,466.24 1508.24,527.46 1503.44,529.62 1497.79,468.4 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1497.79,468.4 1503.44,529.62 1499.26,531.84 1493.61,470.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1534.17,470.05 1539.82,531.27 1533.4,524.14 1527.75,462.92 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1493.61,470.62 1499.26,531.84 1492.85,539.79 1487.2,478.57 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1536.48,475.14 1542.13,536.35 1539.82,531.27 1534.17,470.05 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1487.2,478.57 1492.85,539.79 1471.38,543.03 1465.73,481.82 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1465.73,481.82 1471.38,543.03 1451.24,541.68 1445.59,480.47 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1445.59,480.47 1451.24,541.68 1442.65,547.17 1437,485.95 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1437,485.95 1442.65,547.17 1432.77,550.98 1427.12,489.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1539.44,480.25 1545.09,541.46 1542.13,536.35 1536.48,475.14 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1427.12,489.77 1432.77,550.98 1422.6,554.09 1416.95,492.87 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1407.12,494.32 1412.77,555.54 1403.68,555.71 1398.03,494.49 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1416.95,492.87 1422.6,554.09 1412.77,555.54 1407.12,494.32 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1398.03,494.49 1403.68,555.71 1394.93,557.58 1389.29,496.37 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1569.1,480.89 1574.75,542.1 1559.71,543.63 1554.06,482.42 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1554.06,482.42 1559.71,543.63 1545.09,541.46 1539.44,480.25 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1389.29,496.37 1394.93,557.58 1388.42,561.84 1382.77,500.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1572.64,484.63 1578.29,545.84 1574.75,542.1 1569.1,480.89 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1382.77,500.62 1388.42,561.84 1381,564.77 1375.35,503.56 			"
              />
            </g>
            <g>
              <rect
                x="1367.68"
                y="503.91"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -43.3181 128.414)"
                className="st8"
                width="10.51"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1590.66,484.69 1596.31,545.9 1591.05,546.37 1585.4,485.16 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1579.47,486.09 1585.12,547.31 1578.29,545.84 1572.64,484.63 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1585.4,485.16 1591.05,546.37 1585.12,547.31 1579.47,486.09 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1319.59,510.6 1325.23,571.82 1318.52,569.41 1312.87,508.19 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1601.09,487.69 1606.74,548.91 1596.31,545.9 1590.66,484.69 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1312.87,508.19 1318.52,569.41 1317.6,575.96 1311.95,514.74 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1519.96,493.61 1525.61,554.82 1515.7,559.08 1510.05,497.87 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1326.84,514.89 1332.49,576.1 1325.23,571.82 1319.59,510.6 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1543.62,495.25 1549.27,556.47 1525.61,554.82 1519.96,493.61 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1364.88,504.53 1370.53,565.74 1344.95,575.8 1339.3,514.59 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1552.18,495.1 1557.83,556.31 1549.27,556.47 1543.62,495.25 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1520.41,495.05 1526.06,556.26 1517,560.16 1511.35,498.95 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1543.24,496.62 1548.89,557.84 1526.06,556.26 1520.41,495.05 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1551.8,496.47 1557.44,557.68 1548.89,557.84 1543.24,496.62 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1611.84,490.98 1617.49,552.2 1606.74,548.91 1601.09,487.69 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1561.21,495.67 1566.86,556.89 1557.83,556.31 1552.18,495.1 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1510.05,497.87 1515.7,559.08 1507.34,562.38 1501.7,501.17 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="1501.7,501.17 1507.34,562.38 1494.94,562.38 1489.29,501.16 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1339.3,514.59 1344.95,575.8 1337.62,578.97 1331.97,517.76 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1331.97,517.76 1337.62,578.97 1332.49,576.1 1326.84,514.89 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1560.26,497.02 1565.91,558.23 1557.44,557.68 1551.8,496.47 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1511.35,498.95 1517,560.16 1507.87,563.75 1502.22,502.54 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1502.22,502.54 1507.87,563.75 1495.46,563.76 1489.81,502.55 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1317.18,520.02 1322.83,581.24 1317.6,575.96 1311.95,514.74 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1567.29,497.35 1572.94,558.57 1566.86,556.89 1561.21,495.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1315.04,520.83 1320.69,582.05 1315.11,576.39 1309.46,515.18 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1489.29,501.16 1494.94,562.38 1482.88,567.09 1477.23,505.87 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1619.18,493.57 1624.83,554.79 1617.49,552.2 1611.84,490.98 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1565.98,498.59 1571.63,559.8 1565.91,558.23 1560.26,497.02 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1489.81,502.55 1495.46,563.76 1485,567.85 1479.36,506.63 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1572.61,498.96 1578.26,560.18 1572.94,558.57 1567.29,497.35 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1579.53,498.37 1585.18,559.59 1578.26,560.18 1572.61,498.96 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1587.16,497.11 1592.81,558.33 1585.18,559.59 1579.53,498.37 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1600.75,496.59 1606.4,557.81 1592.81,558.33 1587.16,497.11 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1619.18,493.57 1624.83,554.79 1619.66,556.99 1614.01,495.77 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1614.01,495.77 1619.66,556.99 1606.4,557.81 1600.75,496.59 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1571.92,500.4 1577.57,561.61 1571.63,559.8 1565.98,498.59 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1579.81,499.71 1585.46,560.92 1577.57,561.61 1571.92,500.4 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1587.18,498.48 1592.83,559.69 1585.46,560.92 1579.81,499.71 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1600.7,497.97 1606.35,559.18 1592.83,559.69 1587.18,498.48 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1623.47,493.38 1629.12,554.59 1620.39,558.3 1614.74,497.09 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1614.74,497.09 1620.39,558.3 1606.35,559.18 1600.7,497.97 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1323.67,525.45 1329.32,586.67 1322.83,581.24 1317.18,520.02 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1321.54,526.26 1327.18,587.47 1320.69,582.05 1315.04,520.83 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1539.81,506.23 1545.46,567.45 1536.56,569.48 1530.91,508.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1530.95,508.36 1536.6,569.57 1536.56,569.48 1530.91,508.26 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1550.56,506.83 1556.21,568.05 1545.46,567.45 1539.81,506.23 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1477.23,505.87 1482.88,567.09 1477.97,578.13 1472.32,516.91 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1479.36,506.63 1485,567.85 1480.36,578.23 1474.72,517.02 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1533.5,512.46 1539.15,573.67 1536.6,569.57 1530.95,508.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1531.04,512.78 1536.69,573.99 1533.43,568.76 1527.78,507.54 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1329.34,531.41 1334.99,592.62 1329.32,586.67 1323.67,525.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1327.07,532.07 1332.72,593.29 1327.18,587.47 1321.54,526.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1553.85,512.22 1559.5,573.44 1556.21,568.05 1550.56,506.83 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1533.5,512.46 1539.15,573.67 1539.49,577.41 1533.84,516.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1531.46,517.19 1537.11,578.4 1536.69,573.99 1531.04,512.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1555.23,515.1 1560.88,576.31 1559.5,573.44 1553.85,512.22 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1538.89,517.4 1544.54,578.62 1539.49,577.41 1533.84,516.2 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1555.23,515.1 1560.88,576.31 1557.53,577.7 1551.88,516.49 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1545.31,517.57 1550.96,578.79 1544.54,578.62 1538.89,517.4 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1551.88,516.49 1557.53,577.7 1550.96,578.79 1545.31,517.57 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: abseronColor }}
                points="1550.56,506.83 1553.85,512.22 1555.23,515.1 1551.88,516.49 1545.31,517.57 1538.89,517.4 
1533.84,516.2 1533.5,512.46 1530.95,508.36 1530.91,508.26 1539.81,506.23 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1538.09,518.76 1543.74,579.98 1537.11,578.4 1531.46,517.19 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1558.04,515.53 1563.69,576.75 1558.22,578.97 1552.57,517.76 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1552.46,505.54l3.76,6.14l1.82,3.85l-5.47,2.23l-7.45,1.2l-7.03-0.19l-6.63-1.57l-0.42-4.41l-3.25-5.23
l11.94-2.72L1552.46,505.54z M1551.88,516.49l3.34-1.39l-1.37-2.88l-3.29-5.39l-10.75-0.6l-8.9,2.03l0.04,0.09l2.55,4.1
l0.34,3.74l5.05,1.21l6.43,0.17L1551.88,516.49"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1552.57,517.76 1558.22,578.97 1550.77,580.17 1545.12,518.96 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1545.12,518.96 1550.77,580.17 1543.74,579.98 1538.09,518.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1334.13,539.03 1339.77,600.25 1334.99,592.62 1329.34,531.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1331.82,539.65 1337.46,600.86 1332.72,593.29 1327.07,532.07 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1472.32,516.91 1477.97,578.13 1475.13,589.84 1469.48,528.63 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1474.72,517.02 1480.36,578.23 1477.51,590 1471.87,528.79 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1339.19,545.66 1344.83,606.87 1339.77,600.25 1334.13,539.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1337.22,546.72 1342.87,607.94 1337.46,600.86 1331.82,539.65 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1347.82,548.05 1353.47,609.26 1344.83,606.87 1339.19,545.66 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1346.75,549.34 1352.4,610.56 1342.87,607.94 1337.22,546.72 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1357.63,549.42 1363.28,610.63 1353.47,609.26 1347.82,548.05 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1469.48,528.63 1475.13,589.84 1468.55,601.36 1462.9,540.14 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1356.88,550.77 1362.53,611.98 1352.4,610.56 1346.75,549.34 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1471.87,528.79 1477.51,590 1470.54,602.04 1464.89,540.83 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="1370.9,550.46 1376.55,611.68 1363.28,610.63 1357.63,549.42 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1370.07,551.79 1375.72,613.01 1362.53,611.98 1356.88,550.77 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1383.84,552.91 1389.49,614.12 1376.55,611.68 1370.9,550.46 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="1381.91,554.04 1387.56,615.26 1375.72,613.01 1370.07,551.79 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1462.9,540.14 1468.55,601.36 1456.29,609.6 1450.64,548.38 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1464.89,540.83 1470.54,602.04 1457.66,610.65 1452.01,549.43 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1450.64,548.38 1456.29,609.6 1450.23,611.86 1444.58,550.65 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1452.01,549.43 1457.66,610.65 1451.94,612.79 1446.3,551.57 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1444.58,550.65 1450.23,611.86 1446.15,615.52 1440.5,554.31 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1388.43,559.18 1394.08,620.39 1389.49,614.12 1383.84,552.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1385.95,559.53 1391.6,620.75 1387.56,615.26 1381.91,554.04 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1446.3,551.57 1451.94,612.79 1448.44,615.93 1442.79,554.71 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1440.5,554.31 1446.15,615.52 1444.78,619.08 1439.13,557.86 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1442.79,554.71 1448.44,615.93 1447.09,619.44 1441.44,558.23 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1439.13,557.86 1444.78,619.08 1441.2,622.67 1435.55,561.45 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1388.43,559.18 1394.08,620.39 1393.01,627.67 1387.37,566.46 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1441.44,558.23 1447.09,619.44 1442.94,623.53 1437.29,562.32 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1435.55,561.45 1441.2,622.67 1434.55,626.15 1428.9,564.94 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1437.29,562.32 1442.94,623.53 1436.22,627.05 1430.57,565.84 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1428.9,564.94 1434.55,626.15 1428.61,630.75 1422.96,569.53 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1390.01,573.08 1395.66,634.29 1393.01,627.67 1387.37,566.46 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1430.57,565.84 1436.22,627.05 1430.99,631.12 1425.34,569.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1387.61,573.56 1393.26,634.77 1390.57,627.82 1384.92,566.6 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="1422.96,569.53 1428.61,630.75 1427.78,635.42 1422.13,574.2 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1425.34,569.91 1430.99,631.12 1430.22,635.43 1424.57,574.21 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1393.25,579.85 1398.89,641.07 1395.66,634.29 1390.01,573.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1390.77,579.92 1396.42,641.13 1393.26,634.77 1387.61,573.56 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1422.13,574.2 1427.78,635.42 1426.73,639.51 1421.08,578.3 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1424.57,574.21 1430.22,635.43 1429.04,640.03 1423.39,578.82 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1421.08,578.3 1426.73,639.51 1420.83,642.95 1415.18,581.73 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1393.25,579.85 1398.89,641.07 1397.57,645.14 1391.92,583.92 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1423.39,578.82 1429.04,640.03 1422.45,643.87 1416.81,582.65 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1415.18,581.73 1420.83,642.95 1414.16,646.49 1408.51,585.27 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1392.61,587.4 1398.26,648.62 1397.57,645.14 1391.92,583.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1390.19,587.81 1395.84,649.03 1395.13,645.14 1389.48,583.92 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1416.81,582.65 1422.45,643.87 1415.94,647.33 1410.29,586.12 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1408.51,585.27 1414.16,646.49 1409.19,652.12 1403.54,590.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1396.35,591.62 1401.99,652.83 1398.26,648.62 1392.61,587.4 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1394.08,592.29 1399.72,653.5 1395.84,649.03 1390.19,587.81 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1410.29,586.12 1415.94,647.33 1411.3,652.69 1405.65,591.47 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1398.7,595.14 1404.35,656.35 1401.99,652.83 1396.35,591.62 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1403.54,590.91 1409.19,652.12 1404.35,656.35 1398.7,595.14 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1613.25,489.76l10.21,3.61l-8.72,3.71l-14.04,0.88l-13.52,0.51l-7.37,1.23l-7.89,0.69l-5.94-1.81
l-5.72-1.57l-8.46-0.55l-8.55,0.16l-22.83-1.58l-9.06,3.9l-9.13,3.59l-12.41,0.01l-10.45,4.09l-4.64,10.39l-2.85,11.77
l-6.98,12.04l-12.88,8.61l-5.72,2.14l-3.5,3.14l-1.35,3.52l-4.15,4.09l-6.72,3.52l-5.23,4.07l-0.77,4.31l-1.18,4.6l-6.59,3.84
l-6.52,3.46l-4.64,5.36l-7.62,6.69l-3.95-5.88l-3.88-4.47l-0.72-3.89l1.29-4.01l-3.17-6.36l-2.69-6.96l1.03-7.07l-4.04-5.49
l-11.84-2.25l-13.19-1.03l-10.14-1.42l-9.53-2.62l-5.4-7.08l-4.75-7.57l-5.53-5.82l-6.49-5.43l-5.59-5.65l1.31-9.45l10.58,3.82
l7.34,4.33l3.68,2.07l5.64-2.44l7.93-3.07l18.19-7.21l10.49-0.97l6.74-2.66l6.61-4.36l10.04-2.12l9.16-0.17l9.24-1.37l9.59-2.96
l9.61-3.71l9.18-5.89l21.26,1.43l19.48-2.99l6.28-7.66l4.49-2.36l5.05-2.28l9.59-3.24l9-5.53l9.94,5.74l6.65,7.39l2.29,5.12
l2.6,4.45l13,1.94l16.12-1.65l3.91,4.12l5.4,1.16l5.25-0.83l6.2-0.57l11.12,3.2L1613.25,489.76z M1614.01,495.77l5.17-2.2
l-7.34-2.59l-10.75-3.29l-10.43-3l-5.26,0.47l-5.93,0.94l-6.83-1.47l-3.54-3.74l-15.04,1.53l-14.62-2.17l-2.96-5.11l-2.3-5.09
l-6.42-7.13l-7.93-4.55l-7.62,4.63l-9.61,3.24l-4.79,2.16l-4.18,2.22l-6.42,7.95l-21.47,3.25l-20.14-1.35l-8.59,5.49l-9.88,3.82
l-10.17,3.1l-9.83,1.45l-9.09,0.17l-8.74,1.88l-6.51,4.25l-7.42,2.93l-10.47,0.97l-25.57,10.06l-7.34,3.17l-5.12-2.87l-7.26-4.28
l-6.72-2.41l-0.92,6.55l5.23,5.28l6.49,5.43l5.67,5.96l4.79,7.63l5.06,6.63l8.64,2.39l9.81,1.37l13.27,1.05l12.94,2.45l4.59,6.27
l-1.06,7.28l2.64,6.62l3.24,6.78l-1.32,4.07l0.69,3.48l3.73,4.22l2.35,3.52l4.84-4.23l4.97-5.64l6.66-3.54l5.91-3.44l1.05-4.09
l0.83-4.67l5.94-4.59l6.64-3.49l3.58-3.59l1.37-3.55l4.08-3.66l6.06-2.26l12.26-8.24l6.57-11.52l2.85-11.72l4.9-11.04l12.06-4.71
l12.41,0l8.35-3.3l9.92-4.26l23.66,1.65l8.55-0.16l9.03,0.57l6.09,1.68l5.32,1.61l6.92-0.59l7.63-1.26l13.59-0.52L1614.01,495.77
"
              />
            </g>
            <g>
              <polygon
                className={`st59 ${region == 'abseron' ? 'active' : null}`}
                style={{ fill: abseronColor }}
                onClick={() => openPopup('abseron')}
                points="1611.84,490.98 1619.18,493.57 1614.01,495.77 1600.75,496.59 1587.16,497.11 1579.53,498.37 
1572.61,498.96 1567.29,497.35 1561.21,495.67 1552.18,495.1 1543.62,495.25 1519.96,493.61 1510.05,497.87 1501.7,501.17 
1489.29,501.16 1477.23,505.87 1472.32,516.91 1469.48,528.63 1462.9,540.14 1450.64,548.38 1444.58,550.65 1440.5,554.31 
1439.13,557.86 1435.55,561.45 1428.9,564.94 1422.96,569.53 1422.13,574.2 1421.08,578.3 1415.18,581.73 1408.51,585.27 
1403.54,590.91 1398.7,595.14 1396.35,591.62 1392.61,587.4 1391.92,583.92 1393.25,579.85 1390.01,573.08 1387.37,566.46 
1388.43,559.18 1383.84,552.91 1370.9,550.46 1357.63,549.42 1347.82,548.05 1339.19,545.66 1334.13,539.03 1329.34,531.41 
1323.67,525.45 1317.18,520.02 1311.95,514.74 1312.87,508.19 1319.59,510.6 1326.84,514.89 1331.97,517.76 1339.3,514.59 
1364.88,504.53 1375.35,503.56 1382.77,500.62 1389.29,496.37 1398.03,494.49 1407.12,494.32 1416.95,492.87 1427.12,489.77 
1437,485.95 1445.59,480.47 1465.73,481.82 1487.2,478.57 1493.61,470.62 1497.79,468.4 1502.59,466.24 1512.2,463 
1519.82,458.38 1527.75,462.92 1534.17,470.05 1536.48,475.14 1539.44,480.25 1554.06,482.42 1569.1,480.89 1572.64,484.63 
1579.47,486.09 1585.4,485.16 1590.66,484.69 1601.09,487.69 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1405.65,591.47 1411.3,652.69 1403.67,659.38 1398.02,598.17 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1398.02,598.17 1403.67,659.38 1399.72,653.5 1394.08,592.29 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'haciqabul' ? 'active' : null}`}
            onClick={() => openPopup('haciqabul')}
          >
            <g>
              <polygon
                className="st37"
                points="1206.49,497.94 1212.14,559.15 1204.88,562.96 1199.23,501.74 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1216.29,500.31 1221.94,561.53 1212.14,559.15 1206.49,497.94 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1226.36,503.1 1232.01,564.31 1221.94,561.53 1216.29,500.31 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1199.23,501.74 1204.88,562.96 1198.62,568.7 1192.97,507.48 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1236,507.98 1241.64,569.2 1232.01,564.31 1226.36,503.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1193.05,513.32 1198.7,574.54 1195.99,568.61 1190.35,507.39 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1195.51,513.23 1201.16,574.44 1198.62,568.7 1192.97,507.48 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1295.39,507.21 1301.03,568.42 1290.26,563.31 1284.62,502.1 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1284.62,502.1 1290.26,563.31 1283.33,570.51 1277.68,509.3 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1246.47,512.32 1252.12,573.53 1241.64,569.2 1236,507.98 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1310.48,507.62 1316.13,568.84 1301.03,568.42 1295.39,507.21 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1195.51,513.23 1201.16,574.44 1199.17,581.76 1193.52,520.54 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1277.68,509.3 1283.33,570.51 1272,575.34 1266.35,514.12 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1266.35,514.12 1272,575.34 1260.93,577.29 1255.28,516.07 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1255.28,516.07 1260.93,577.29 1252.12,573.53 1246.47,512.32 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1313.1,506.31 1318.75,567.53 1317.58,575.97 1311.93,514.75 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1310.48,507.62 1316.13,568.84 1315.09,576.4 1309.44,515.19 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1193.52,520.54 1199.17,581.76 1195.88,590.27 1190.24,529.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1315.03,520.84 1320.68,582.06 1315.09,576.4 1309.44,515.19 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1190.24,529.06 1195.88,590.27 1189.94,598.11 1184.29,536.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1321.52,526.27 1327.17,587.48 1320.68,582.06 1315.03,520.84 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1209.34,539.31 1214.99,600.53 1189.94,598.11 1184.29,536.89 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1208.22,540.6 1213.87,601.82 1186.54,599.2 1180.89,537.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1327.05,532.08 1332.7,593.3 1327.17,587.48 1321.52,526.27 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="1232.92,545.55 1238.57,606.76 1214.99,600.53 1209.34,539.31 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1231.32,546.69 1236.97,607.91 1213.87,601.82 1208.22,540.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1331.8,539.66 1337.45,600.87 1332.7,593.3 1327.05,532.08 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1238.79,548.32 1244.43,609.53 1238.57,606.76 1232.92,545.55 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1235.91,548.86 1241.56,610.07 1236.97,607.91 1231.32,546.69 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1238.79,548.32 1244.43,609.53 1242.44,612.53 1236.79,551.32 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1236.79,551.32 1242.44,612.53 1241.71,616.09 1236.06,554.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1236.67,555.38 1242.32,616.59 1241.71,616.09 1236.06,554.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1234.11,555.85 1239.76,617.07 1239.17,616.58 1233.52,555.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1337.2,546.74 1342.85,607.95 1337.45,600.87 1331.8,539.66 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1236.67,555.38 1242.32,616.59 1242.01,617.87 1236.36,556.65 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1236.36,556.65 1242.01,617.87 1240.75,620.19 1235.1,558.98 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1346.73,549.35 1352.38,610.57 1342.85,607.95 1337.2,546.74 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1235.1,558.98 1240.75,620.19 1240.38,621.36 1234.73,560.14 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1235.81,560.07 1241.46,621.29 1240.38,621.36 1234.73,560.14 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1233.52,561.58 1239.17,622.8 1237.71,622.12 1232.06,560.9 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1234.82,561.5 1240.47,622.72 1239.17,622.8 1233.52,561.58 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1356.87,550.78 1362.52,611.99 1352.38,610.57 1346.73,549.35 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1242.16,563.06 1247.81,624.28 1241.46,621.29 1235.81,560.07 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1370.06,551.8 1375.71,613.02 1362.52,611.99 1356.87,550.78 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1240.61,564.23 1246.26,625.44 1240.47,622.72 1234.82,561.5 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1248.04,564.25 1253.69,625.47 1247.81,624.28 1242.16,563.06 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1246.82,565.51 1252.47,626.73 1246.26,625.44 1240.61,564.23 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="1381.9,554.05 1387.54,615.27 1375.71,613.02 1370.06,551.8 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="1253.38,565.92 1259.03,627.13 1253.69,625.47 1248.04,564.25 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1251.09,566.85 1256.74,628.07 1252.47,626.73 1246.82,565.51 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1254.18,569.03 1259.82,630.24 1259.03,627.13 1253.38,565.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1251.88,569.88 1257.53,631.1 1256.74,628.07 1251.09,566.85 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1256.61,570.03 1262.26,631.24 1259.82,630.24 1254.18,569.03 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1255.13,571.23 1260.78,632.44 1257.53,631.1 1251.88,569.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1385.94,559.54 1391.59,620.76 1387.54,615.27 1381.9,554.05 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1271.72,572.95 1277.37,634.16 1262.26,631.24 1256.61,570.03 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1270.53,574.21 1276.18,635.42 1260.78,632.44 1255.13,571.23 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="1277.28,574.68 1282.92,635.9 1277.37,634.16 1271.72,572.95 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1275.42,575.7 1281.06,636.92 1276.18,635.42 1270.53,574.21 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1385.94,559.54 1391.59,620.76 1390.56,627.83 1384.91,566.61 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1388.41,559.19 1394.06,620.4 1393,627.68 1387.35,566.47 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1284.38,579.66 1290.03,640.88 1282.92,635.9 1277.28,574.68 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1288.5,579.3 1294.15,640.51 1290.03,640.88 1284.38,579.66 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1283.16,581.15 1288.81,642.37 1281.06,636.92 1275.42,575.7 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1287.87,580.72 1293.52,641.94 1288.81,642.37 1283.16,581.15 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1294.37,580.88 1300.02,642.1 1294.15,640.51 1288.5,579.3 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1293.23,582.17 1298.87,643.39 1293.52,641.94 1287.87,580.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1387.59,573.57 1393.24,634.78 1390.56,627.83 1384.91,566.61 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1301.57,581.65 1307.22,642.87 1300.02,642.1 1294.37,580.88 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="1305.09,581.63 1310.74,642.85 1307.22,642.87 1301.57,581.65 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1301.09,583.01 1306.73,644.23 1298.87,643.39 1293.23,582.17 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1303.93,583.01 1309.58,644.22 1306.73,644.23 1301.09,583.01 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="1307.89,583.13 1313.54,644.34 1310.74,642.85 1305.09,581.63 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1305.56,583.88 1311.21,645.09 1309.58,644.22 1303.93,583.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1305.9,585.43 1311.55,646.64 1311.21,645.09 1305.56,583.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1308.39,585.4 1314.04,646.62 1313.54,644.34 1307.89,583.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1390.76,579.93 1396.41,641.14 1393.24,634.78 1387.59,573.57 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1308.39,585.4 1314.04,646.62 1312.62,649.02 1306.97,587.8 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="1390.76,579.93 1396.41,641.14 1395.11,645.15 1389.47,583.94 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1306.97,587.8 1312.62,649.02 1310.47,653.14 1304.82,591.93 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1393.23,579.86 1398.88,641.08 1397.56,645.15 1391.91,583.93 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1304.82,591.93 1310.47,653.14 1305.26,655.53 1299.61,594.31 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1299.61,594.31 1305.26,655.53 1302.58,656.6 1296.93,595.38 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1303.22,595.5 1308.87,656.71 1302.58,656.6 1296.93,595.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1390.18,587.82 1395.83,649.04 1395.11,645.15 1389.47,583.94 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1302.8,596.86 1308.45,658.08 1295.44,657.86 1289.79,596.64 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1313.94,596.07 1319.59,657.28 1308.87,656.71 1303.22,595.5 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1312.61,597.39 1318.26,658.61 1308.45,658.08 1302.8,596.86 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1394.06,592.3 1399.71,653.51 1395.83,649.04 1390.18,587.82 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1322.97,601.43 1328.62,662.65 1319.59,657.28 1313.94,596.07 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1321.19,602.48 1326.84,663.69 1318.26,658.61 1312.61,597.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1397.14,596.88 1402.79,658.09 1399.71,653.51 1394.06,592.3 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1332.15,605.74 1337.8,666.96 1328.62,662.65 1322.97,601.43 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1330.6,606.91 1336.25,668.13 1326.84,663.69 1321.19,602.48 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1399.43,602.92 1405.08,664.13 1402.79,658.09 1397.14,596.88 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1345.75,608.3 1351.4,669.51 1337.8,666.96 1332.15,605.74 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="1344.81,609.62 1350.46,670.84 1336.25,668.13 1330.6,606.91 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1359.49,610.22 1365.14,671.43 1351.4,669.51 1345.75,608.3 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1358.56,611.54 1364.21,672.75 1350.46,670.84 1344.81,609.62 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1364.18,611.14 1369.83,672.35 1365.14,671.43 1359.49,610.22 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1363.06,612.43 1368.71,673.64 1364.21,672.75 1358.56,611.54 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="1368.33,612.19 1373.98,673.4 1369.83,672.35 1364.18,611.14 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1375.8,611.68 1381.45,672.9 1373.98,673.4 1368.33,612.19 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1401.87,602.61 1407.51,663.82 1408.06,670.76 1402.41,609.54 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1383.79,611.27 1389.44,672.49 1381.45,672.9 1375.8,611.68 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1399.43,602.92 1405.08,664.13 1405.61,671.09 1399.96,609.88 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1367.68,613.6 1373.33,674.81 1368.71,673.64 1363.06,612.43 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1375.8,613.05 1381.45,674.26 1373.33,674.81 1367.68,613.6 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1382.51,612.7 1388.16,673.92 1381.45,674.26 1375.8,613.05 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1388.28,614.46 1393.93,675.67 1389.44,672.49 1383.79,611.27 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1402.74,613.58 1408.39,674.79 1405.61,671.09 1399.96,609.88 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1386.04,615.15 1391.69,676.37 1388.16,673.92 1382.51,612.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1390.72,618.11 1396.37,679.33 1393.93,675.67 1388.28,614.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1404.93,617.09 1410.58,678.3 1408.39,674.79 1402.74,613.58 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1388.48,618.81 1394.13,680.03 1391.69,676.37 1386.04,615.15 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1396.98,622.73 1402.63,683.95 1396.37,679.33 1390.72,618.11 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1404.93,617.09 1410.58,678.3 1409.38,683.43 1403.73,622.21 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1407.42,616.79 1413.07,678.01 1411.77,683.52 1406.12,622.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1394.99,623.65 1400.64,684.87 1394.13,680.03 1388.48,618.81 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1402.04,626.2 1407.69,687.41 1402.63,683.95 1396.98,622.73 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1403.73,622.21 1409.38,683.43 1407.69,687.41 1402.04,626.2 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1406.12,622.31l-2.92,6.98l-8.22-5.63l-6.5-4.84l-2.45-3.66l-3.53-2.45l-6.71,0.34l-8.12,0.55l-4.62-1.17
l-4.51-0.89l-13.75-1.92l-14.21-2.71l-9.4-4.43l-8.58-5.09l-9.81-0.53l-13.02-0.22l8.48-3.39l4.45-2.03l1.92-3.68l1.26-2.11
l-0.33-1.55l-1.63-0.87l-2.84,0.01l-7.86-0.84l-5.35-1.45l-4.71,0.43l-7.75-5.45l-4.89-1.5l-15.4-2.98l-3.25-1.34l-0.79-3.03
l-4.27-1.34l-6.21-1.28l-5.79-2.73l-1.3,0.08l-1.46-0.68l0.66-2.11l1.24-2.29l0.15-0.65l-0.59-0.49l0.86-4.22l1.53-2.29
l-4.59-2.16l-23.1-6.09l-27.33-2.62l7.01-9.21l3.21-8.34l1.94-7.11l-2.71-5.93l7.2-6.54l8.67-4.54l11.29,2.74l10.5,2.92l9.67,4.9
l10.42,4.3l7.91,3.37l9.61-1.7l10.23-4.41l8.2-8.52l12.49,5.93l16.57,0.45l-1.17,8.44l5.23,5.28l6.49,5.43l5.67,5.95l4.79,7.63
l5.06,6.63l8.64,2.39l9.81,1.37l13.27,1.05l12.94,2.45l4.59,6.27l-1.06,7.28l2.64,6.62l3.24,6.78l-1.32,4.07l0.69,3.48l3.74,4.22
l3.18,4.73l2.35,6.25l0.54,6.94l2.65,3.41l2.36,3.84L1406.12,622.31z M1396.98,622.73l5.07,3.47l1.68-3.99l1.2-5.12l-2.18-3.51
l-2.78-3.7l-0.53-6.96l-2.3-6.04l-3.08-4.58l-3.88-4.47l-0.72-3.89l1.29-4.01l-3.17-6.36l-2.69-6.96l1.03-7.07l-4.04-5.49
l-11.84-2.25l-13.19-1.03l-10.14-1.42l-9.53-2.62l-5.4-7.08l-4.75-7.57l-5.53-5.82l-6.49-5.43l-5.59-5.65l1.04-7.56l-15.1-0.42
l-10.77-5.11l-6.93,7.2l-11.34,4.82l-11.07,1.95l-8.81-3.76l-10.47-4.33l-9.63-4.89l-10.07-2.78l-9.8-2.38l-7.26,3.8l-6.26,5.74
l2.55,5.75l-1.99,7.31l-3.29,8.51l-5.94,7.83l25.05,2.42l23.58,6.23l5.86,2.77l-1.99,3l-0.73,3.56l0.61,0.5l-0.31,1.27
l-1.25,2.33l-0.37,1.17l1.08-0.07l6.36,2.99l5.88,1.19l5.34,1.67l0.8,3.11l2.43,1l15.12,2.92l5.55,1.74l7.1,4.98l4.12-0.37
l5.87,1.58l7.21,0.77l3.52-0.02l2.8,1.5l0.5,2.28l-1.42,2.4l-2.15,4.12l-5.21,2.39l-2.68,1.07l6.29,0.12l10.72,0.57l9.03,5.37
l9.17,4.31l13.6,2.55l13.75,1.92l4.69,0.92l4.14,1.05l7.48-0.51l7.99-0.41l4.49,3.19l2.44,3.66L1396.98,622.73"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: haciqabulColor ? haciqabulColor : null }}
                points="1284.62,502.1 1295.39,507.21 1310.48,507.62 1309.44,515.19 1315.03,520.84 1321.52,526.27 
1327.05,532.08 1331.8,539.66 1337.2,546.74 1346.73,549.35 1356.87,550.78 1370.06,551.8 1381.9,554.05 1385.94,559.54 
1384.91,566.61 1387.59,573.57 1390.76,579.93 1389.47,583.94 1390.18,587.82 1394.06,592.3 1397.14,596.88 1399.43,602.92 
1399.96,609.88 1402.74,613.58 1404.93,617.09 1403.73,622.21 1402.04,626.2 1396.98,622.73 1390.72,618.11 1388.28,614.46 
1383.79,611.27 1375.8,611.68 1368.33,612.19 1364.18,611.14 1359.49,610.22 1345.75,608.3 1332.15,605.74 1322.97,601.43 
1313.94,596.07 1303.22,595.5 1296.93,595.38 1299.61,594.31 1304.82,591.93 1306.97,587.8 1308.39,585.4 1307.89,583.13 
1305.09,581.63 1301.57,581.65 1294.37,580.88 1288.5,579.3 1284.38,579.66 1277.28,574.68 1271.72,572.95 1256.61,570.03 
1254.18,569.03 1253.38,565.92 1248.04,564.25 1242.16,563.06 1235.81,560.07 1234.73,560.14 1235.1,558.98 1236.36,556.65 
1236.67,555.38 1236.06,554.88 1236.79,551.32 1238.79,548.32 1232.92,545.55 1209.34,539.31 1184.29,536.89 1190.24,529.06 
1193.52,520.54 1195.51,513.23 1192.97,507.48 1199.23,501.74 1206.49,497.94 1216.29,500.31 1226.36,503.1 1236,507.98 
1246.47,512.32 1255.28,516.07 1266.35,514.12 1277.69,509.3 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1406.12,622.31 1411.77,683.52 1408.85,690.5 1403.21,629.28 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1403.21,629.28 1408.85,690.5 1400.64,684.87 1394.99,623.65 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sirvan' ? 'active' : null}`}
            onClick={() => openPopup('sirvan')}
          >
            <g>
              <polygon
                className="st8"
                points="1287.71,580.72 1293.36,641.94 1290.13,642.23 1284.49,581.01 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1293.06,582.17 1298.71,643.39 1293.36,641.94 1287.71,580.72 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1300.92,583.01 1306.57,644.23 1298.71,643.39 1293.06,582.17 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1303.77,583.01 1309.42,644.22 1306.57,644.23 1300.92,583.01 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1305.4,583.88 1311.05,645.09 1309.42,644.22 1303.77,583.01 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1284.49,581.01 1290.13,642.23 1286.61,647.9 1280.96,586.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1305.73,585.42 1311.38,646.64 1311.05,645.09 1305.4,583.88 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1280.96,586.69 1286.61,647.9 1285.27,650.59 1279.62,589.38 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1305.73,585.42 1311.38,646.64 1310.12,648.75 1304.47,587.53 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1308.23,585.4 1313.88,646.61 1312.46,649.02 1306.81,587.8 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1280.28,592.12 1285.93,653.34 1285.27,650.59 1279.62,589.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1277.86,592.54 1283.51,653.75 1282.78,650.65 1277.13,589.44 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1304.47,587.53 1310.12,648.75 1308.2,652.43 1302.55,591.22 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1281.39,593.54 1287.04,654.75 1285.93,653.34 1280.28,592.12 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1306.81,587.8 1312.46,649.02 1310.31,653.14 1304.66,591.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1279.34,594.49 1284.99,655.71 1283.51,653.75 1277.86,592.54 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1302.55,591.22 1308.2,652.43 1303.75,654.47 1298.11,593.25 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1304.66,591.92 1310.31,653.14 1305.1,655.53 1299.45,594.31 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1286.07,595.55 1291.72,656.77 1287.04,654.75 1281.39,593.54 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1282.59,595.89 1288.24,657.1 1284.99,655.71 1279.34,594.49 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1298.11,593.25 1303.75,654.47 1298.25,656.66 1292.61,595.45 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1284.01,596.44 1289.66,657.66 1288.24,657.1 1282.59,595.89 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1287.19,596.67 1292.84,657.89 1291.72,656.77 1286.07,595.55 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1288.26,597.04 1293.9,658.26 1292.84,657.89 1287.19,596.67 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1292.61,595.45 1298.25,656.66 1293.9,658.26 1288.26,597.04 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1307.73,583.12l0.5,2.28l-1.42,2.4l-2.15,4.12l-5.21,2.39l-5.61,2.24l-5.8,2.13l-2.87-1.11l-1.15-1.13
l-1.42-0.56l-3.25-1.39l-1.47-1.95l-0.73-3.1l1.49-3.02l4.1-6.62l5.61-0.5l5.87,1.58l7.21,0.77l3.52-0.02L1307.73,583.12z
 M1304.47,587.53l1.26-2.11l-0.33-1.55l-1.63-0.87l-2.84,0.01l-7.86-0.84l-5.35-1.45l-3.22,0.29l-3.52,5.67l-1.34,2.69l0.66,2.75
l1.11,1.41l4.69,2.02l1.11,1.12l1.07,0.37l4.35-1.6l5.5-2.2l4.45-2.03L1304.47,587.53"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sirvanColor ? sirvanColor : null }}
                points="1287.71,580.72 1293.06,582.17 1300.92,583.01 1303.77,583.01 1305.4,583.88 1305.73,585.42 
1304.47,587.53 1302.55,591.22 1298.11,593.25 1292.61,595.45 1288.26,597.04 1287.19,596.67 1286.07,595.55 1281.39,593.54 
1280.28,592.12 1279.62,589.38 1280.96,586.69 1284.49,581.01 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1299.45,594.31 1305.1,655.53 1299.49,657.77 1293.84,596.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1285.16,597.58 1290.81,658.79 1289.66,657.66 1284.01,596.44 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1293.84,596.55 1299.49,657.77 1293.69,659.9 1288.04,598.68 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1288.04,598.68 1293.69,659.9 1290.81,658.79 1285.16,597.58 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'kurdemir' ? 'active' : null}`}
            onClick={() => openPopup('kurdemir')}
          >
            <g>
              <polygon
                className="st130"
                points="1121.34,446.76 1126.99,507.98 1117.22,509.16 1111.57,447.95 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1111.57,447.95 1117.22,509.16 1105.9,509.72 1100.25,448.5 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1121.34,446.76 1126.99,507.98 1118.71,514.98 1113.06,453.77 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1126.48,444.76 1132.13,505.98 1120.83,515.53 1115.18,454.31 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1100.25,448.5 1105.9,509.72 1102.79,520.95 1097.14,459.74 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1097.14,459.74 1102.79,520.95 1088.96,523.78 1083.31,462.57 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1113.06,453.77 1118.71,514.98 1111.71,524.17 1106.06,462.95 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1115.18,454.31 1120.83,515.53 1114.48,523.89 1108.83,462.67 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1083.31,462.57 1088.96,523.78 1083.61,532.05 1077.96,470.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1112.44,468.28 1118.09,529.5 1111.71,524.17 1106.06,462.95 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1122.82,468.08 1128.46,529.29 1118.09,529.5 1112.44,468.28 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1163.49,469.23 1169.14,530.44 1157.13,529.93 1151.48,468.71 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1077.96,470.84 1083.61,532.05 1076.91,540.41 1071.26,479.19 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1151.48,468.71 1157.13,529.93 1136.78,536.41 1131.13,475.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1131.13,475.2 1136.78,536.41 1128.46,529.29 1122.82,468.08 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1172.29,472.93 1177.94,534.14 1169.14,530.44 1163.49,469.23 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1071.26,479.19 1076.91,540.41 1068.68,545.71 1063.03,484.49 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1063.03,484.49 1068.68,545.71 1059.93,549.29 1054.28,488.08 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1172.29,472.93 1177.94,534.14 1174.91,542.16 1169.26,480.94 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1175,472.26 1180.65,533.48 1177.3,542.32 1171.65,481.1 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1169.26,480.94 1174.91,542.16 1171.28,550.54 1165.63,489.32 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1171.65,481.1 1177.3,542.32 1174.08,549.78 1168.43,488.56 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1054.28,488.08 1059.93,549.29 1052.32,564.65 1046.67,503.43 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1173.74,491.95 1179.39,553.16 1171.28,550.54 1165.63,489.32 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1180.3,494.79 1185.95,556.01 1179.39,553.16 1173.74,491.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1183.02,502.22 1188.67,563.44 1185.95,556.01 1180.3,494.79 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1046.67,503.43 1052.32,564.65 1048.33,580.13 1042.68,518.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1190.51,507.8 1196.16,569.02 1188.67,563.44 1183.02,502.22 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1042.68,518.91 1048.33,580.13 1041.02,584.79 1035.37,523.58 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1035.37,523.58 1041.02,584.79 1032.32,588.37 1026.67,527.15 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1026.67,527.15 1032.32,588.37 1023.07,589.89 1017.43,528.68 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1193.06,513.32 1198.7,574.53 1196.16,569.02 1190.51,507.8 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1017.43,528.68 1023.07,589.89 1017.24,591.49 1011.59,530.27 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1008.06,530.88 1013.71,592.1 1012.04,591.45 1006.39,530.24 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="1012.71,530.59 1018.36,591.8 1017.24,591.49 1011.59,530.27 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1048.71,527.26 1054.36,588.47 1049.79,589.4 1044.14,528.19 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1011.12,531.74 1016.77,592.95 1013.71,592.1 1008.06,530.88 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="1014.8,531.61 1020.45,592.83 1018.36,591.8 1012.71,530.59 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1048.02,528.81 1053.67,590.02 1051.31,590.5 1045.66,529.29 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1012.68,532.47 1018.33,593.69 1016.77,592.95 1011.12,531.74 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="1052.28,528.86 1057.93,590.07 1054.36,588.47 1048.71,527.26 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1044.14,528.19 1049.79,589.4 1047.7,591.33 1042.05,530.12 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1050.37,529.84 1056.02,591.06 1053.67,590.02 1048.02,528.81 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1075.82,527.72 1081.47,588.93 1075.53,588.67 1069.88,527.45 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1045.66,529.29 1051.31,590.5 1049.85,591.87 1044.2,530.65 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1069.88,527.45 1075.53,588.67 1068.36,591.06 1062.71,529.85 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1054.78,530.61 1060.43,591.82 1057.93,590.07 1052.28,528.86 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1073.15,528.99 1078.8,590.2 1075.87,590.07 1070.22,528.86 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1025.66,533.24 1031.31,594.45 1029.09,594.92 1023.44,533.71 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1028.11,533.7 1033.76,594.91 1031.31,594.45 1025.66,533.24 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1042.05,530.12 1047.7,591.33 1046.25,593.9 1040.6,532.68 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1052.91,531.62 1058.56,592.83 1056.02,591.06 1050.37,529.84 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1056.64,531.28 1062.29,592.5 1060.43,591.82 1054.78,530.61 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1062.71,529.85 1068.36,591.06 1062.29,592.5 1056.64,531.28 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1017.95,534.91 1023.59,596.13 1020.45,592.83 1014.8,531.61 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1044.2,530.65 1049.85,591.87 1048.61,594.11 1042.96,532.89 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1070.22,528.86 1075.87,590.07 1069.25,592.28 1063.61,531.06 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1023.44,533.71 1029.09,594.92 1025.6,596.53 1019.95,535.32 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1019.95,535.32 1025.6,596.53 1024.5,596.65 1018.85,535.44 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1018.85,535.44 1024.5,596.65 1023.59,596.13 1017.95,534.91 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1025.49,534.69 1031.13,595.91 1029.95,596.16 1024.3,534.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1015.83,535.73 1021.47,596.95 1018.33,593.69 1012.68,532.47 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="1026.52,534.86 1032.17,596.07 1031.13,595.91 1025.49,534.69 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1030.34,534.85 1035.99,596.06 1033.76,594.91 1028.11,533.7 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1056.16,532.82 1061.81,594.04 1058.56,592.83 1052.91,531.62 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1063.61,531.06 1069.25,592.28 1061.81,594.04 1056.16,532.82 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1031.37,535.15 1037.02,596.37 1035.99,596.06 1030.34,534.85 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1193.06,513.32 1198.7,574.53 1196.76,581.65 1191.12,520.43 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1195.52,513.23 1201.17,574.44 1199.18,581.76 1193.53,520.54 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1024.3,534.94 1029.95,596.16 1026.51,597.8 1020.86,536.58 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1017.84,536.93 1023.48,598.14 1021.47,596.95 1015.83,535.73 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="1020.86,536.58 1026.51,597.8 1023.48,598.14 1017.84,536.93 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1028.66,535.95 1034.31,597.17 1032.17,596.07 1026.52,534.86 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1029.89,536.33 1035.53,597.54 1034.31,597.17 1028.66,535.95 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1035.44,536.93 1041.09,598.14 1037.02,596.37 1031.37,535.15 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1040.6,532.68 1046.25,593.9 1044.5,598.2 1038.85,536.98 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1037.84,537.43 1043.49,598.65 1041.09,598.14 1035.44,536.93 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1038.85,536.98 1044.5,598.2 1043.49,598.65 1037.84,537.43 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1075.82,527.72 1081.47,588.93 1080.34,595.45 1074.69,534.24 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1033.93,538.11 1039.58,599.32 1035.53,597.54 1029.89,536.33 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1042.96,532.89 1048.61,594.11 1046.69,598.89 1041.04,537.67 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1041.04,537.67 1046.69,598.89 1043.68,600.22 1038.03,539 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1038.03,539 1043.68,600.22 1039.58,599.32 1033.93,538.11 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1075.56,536.58 1081.21,597.8 1080.34,595.45 1074.69,534.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1073.3,537.38 1078.94,598.6 1077.86,595.58 1072.21,534.37 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1191.12,520.43 1196.76,581.65 1193.56,589.99 1187.91,528.77 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1193.53,520.54 1199.18,581.76 1195.89,590.27 1190.24,529.05 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="1087.88,543.16 1093.53,604.38 1081.21,597.8 1075.56,536.58 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1089.89,543.88 1095.54,605.1 1093.53,604.38 1087.88,543.16 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1086.25,544.27 1091.9,605.48 1078.94,598.6 1073.3,537.38 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1092.42,544.22 1098.07,605.44 1095.54,605.1 1089.89,543.88 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1088.55,545.12 1094.2,606.33 1091.9,605.48 1086.25,544.27 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1187.91,528.77 1193.56,589.99 1187.36,598.12 1181.71,536.9 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1090.63,545.43 1096.27,606.64 1094.2,606.33 1088.55,545.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1091.04,545.91 1096.69,607.13 1096.27,606.64 1090.63,545.43 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1093.69,545.67 1099.33,606.89 1098.07,605.44 1092.42,544.22 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1181.71,536.9 1187.36,598.12 1177.45,600.12 1171.8,538.91 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1190.24,529.05 1195.89,590.27 1189.13,599.18 1183.48,537.96 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1106.7,545.89 1112.35,607.11 1106.64,607.31 1100.99,546.09 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1183.48,537.96 1189.13,599.18 1178.2,601.39 1172.56,540.17 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1093.69,545.67 1099.33,606.89 1098.17,608.79 1092.52,547.57 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1100.99,546.09 1106.64,607.31 1098.17,608.79 1092.52,547.57 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1106.13,547.28 1111.78,608.5 1106.68,608.68 1101.03,547.46 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="1112.01,546.88 1117.65,608.09 1112.35,607.11 1106.7,545.89 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1171.8,538.91 1177.45,600.12 1167.73,603.79 1162.08,542.57 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1101.03,547.46 1106.68,608.68 1094.4,610.85 1088.75,549.64 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1110.44,548.04 1116.08,609.26 1111.78,608.5 1106.13,547.28 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="1113.79,547.76 1119.44,608.98 1117.65,608.09 1112.01,546.88 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1162.08,542.57 1167.73,603.79 1157.48,605.97 1151.84,544.75 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1172.56,540.17 1178.2,601.39 1168.51,605.03 1162.87,543.81 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1111.92,548.76 1117.57,609.97 1116.08,609.26 1110.44,548.04 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1162.87,543.81 1168.51,605.03 1158.27,607.22 1152.62,546 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1116.04,549.39 1121.69,610.6 1119.44,608.98 1113.79,547.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1113.84,550.14 1119.49,611.35 1117.57,609.97 1111.92,548.76 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1151.84,544.75 1157.48,605.97 1148.05,609.49 1142.4,548.27 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1117.53,551.3 1123.18,612.52 1121.69,610.6 1116.04,549.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1115.09,551.64 1120.73,612.85 1119.49,611.35 1113.84,550.14 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1152.62,546 1158.27,607.22 1149.45,610.53 1143.8,549.31 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1128.75,551.29 1134.4,612.5 1131.65,612.86 1126,551.65 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1126,551.65 1131.65,612.86 1130.19,613.23 1124.54,552.02 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1124.54,552.02 1130.19,613.23 1129.03,613.39 1123.38,552.17 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1131.53,551.47 1137.17,612.68 1134.4,612.5 1128.75,551.29 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1142.4,548.27 1148.05,609.49 1144.26,612.19 1138.62,550.98 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1128.42,552.68 1134.07,613.89 1132.21,614.16 1126.56,552.95 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1143.8,549.31 1149.45,610.53 1145.99,613.06 1140.34,551.84 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1130.22,552.78 1135.87,614 1134.07,613.89 1128.42,552.68 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1126.56,552.95 1132.21,614.16 1130.71,614.53 1125.06,553.31 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1125.06,553.31 1130.71,614.53 1129.62,614.68 1123.97,553.46 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1123.38,552.17 1129.03,613.39 1124.31,615.28 1118.67,554.07 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1117.53,551.3 1123.18,612.52 1123.4,615.39 1117.75,554.18 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1118.67,554.07 1124.31,615.28 1123.4,615.39 1117.75,554.18 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="1133.91,552.9 1139.56,614.12 1137.17,612.68 1131.53,551.47 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1138.62,550.98 1144.26,612.19 1139.78,614.54 1134.13,553.33 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1134.13,553.33 1139.78,614.54 1139.56,614.12 1133.91,552.9 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: kurdemirColor ? kurdemirColor : null }}
                points="1121.34,446.76 1113.06,453.77 1106.06,462.95 1112.44,468.28 1122.82,468.08 1131.13,475.2 
1151.48,468.71 1163.49,469.23 1172.29,472.93 1169.26,480.94 1165.63,489.32 1173.74,491.95 1180.3,494.79 1183.02,502.22 
1190.51,507.8 1193.06,513.32 1191.12,520.43 1187.91,528.77 1181.71,536.9 1171.8,538.91 1162.08,542.57 1151.84,544.75 
1142.4,548.27 1138.62,550.98 1134.13,553.33 1133.91,552.9 1131.53,551.47 1128.75,551.29 1126,551.65 1124.54,552.02 
1123.38,552.17 1118.67,554.07 1117.75,554.18 1117.53,551.3 1116.04,549.39 1113.79,547.76 1112.01,546.88 1106.7,545.89 
1100.99,546.09 1092.52,547.57 1093.69,545.67 1092.42,544.22 1089.89,543.88 1087.88,543.16 1075.56,536.58 1074.69,534.24 
1075.82,527.72 1069.88,527.45 1062.71,529.85 1056.64,531.28 1054.78,530.61 1052.28,528.86 1048.71,527.26 1044.14,528.19 
1042.05,530.12 1040.6,532.68 1038.85,536.98 1037.84,537.43 1035.44,536.93 1031.37,535.15 1030.34,534.85 1028.11,533.7 
1025.66,533.24 1023.44,533.71 1019.95,535.32 1018.85,535.44 1017.95,534.91 1014.8,531.61 1012.71,530.59 1011.59,530.27 
1017.43,528.68 1026.67,527.15 1035.37,523.58 1042.68,518.91 1046.67,503.43 1054.28,488.08 1063.03,484.49 1071.26,479.19 
1077.96,470.84 1083.31,462.57 1097.14,459.74 1100.25,448.5 1111.57,447.95 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1131.63,553.59 1137.28,614.81 1135.87,614 1130.22,552.78 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1123.97,553.46 1129.62,614.68 1125.13,616.57 1119.48,555.35 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1119.48,555.35 1125.13,616.57 1121.06,617.05 1115.41,555.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1132.87,555.77 1138.52,616.98 1137.28,614.81 1131.63,553.59 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1193.53,520.54l-3.29,8.51l-6.76,8.91l-10.92,2.21l-9.69,3.64l-10.25,2.19l-8.82,3.31l-3.46,2.53
l-7.47,3.93l-1.24-2.17l-1.41-0.81l-1.79-0.11l-1.86,0.27l-1.5,0.37l-1.09,0.15l-4.49,1.89l-4.07,0.49l-0.32-4.2l-1.25-1.5
l-1.92-1.38l-1.48-0.72l-4.3-0.76l-5.1,0.18l-12.28,2.18l2.29-3.73l-0.42-0.48l-2.07-0.31l-2.31-0.85l-12.95-6.89l-1.08-3.02
l0.94-5.38l-2.94-0.13l-6.61,2.2l-7.45,1.76l-3.25-1.2l-2.53-1.78l-2.35-1.03l-2.36,0.48l-1.47,1.36l-1.24,2.24l-1.93,4.78
l-3,1.33l-4.11-0.89l-4.04-1.78l-1.22-0.37l-2.15-1.09l-1.03-0.17l-1.19,0.25l-3.44,1.64l-3.03,0.35l-2.01-1.2l-3.14-3.26
l-1.56-0.74l-3.06-0.85l-1.67-0.64l10.4-2.84l9.2-1.53l7.96-3.32l6.43-4.12l3.89-15.13l7.9-15.97l9.44-3.87l7.74-5.01l6.36-8
l5.76-8.91l13.5-2.77l3.18-11.53l13.33-0.64l15-1.83l-11.3,9.55l-6.35,8.36l5.03,4.2l10.42-0.2l7.82,6.72l19.08-6.09l13.43,0.56
l10.4,4.39l-3.35,8.84l-3.21,7.46l6.83,2.22l7.27,3.16l2.83,7.72l7.47,5.53l2.68,6.04L1193.53,520.54z M1181.71,536.9l6.19-8.13
l3.21-8.34l1.94-7.11l-2.54-5.52l-7.49-5.58l-2.73-7.43l-6.56-2.84l-8.11-2.62l3.63-8.38l3.03-8.01l-8.8-3.7l-12.01-0.51
l-20.36,6.48l-8.31-7.12l-10.37,0.2l-6.38-5.33l7-9.18l8.28-7.01l-9.77,1.19l-11.32,0.55l-3.11,11.23l-13.84,2.83l-5.35,8.27
l-6.7,8.36l-8.23,5.3l-8.75,3.58l-7.61,15.35l-3.98,15.48l-7.31,4.67l-8.7,3.58l-9.24,1.53l-5.84,1.59l1.12,0.31l2.09,1.02
l3.15,3.3l0.91,0.53l1.1-0.12l3.49-1.61l2.22-0.47l2.45,0.46l2.23,1.15l1.03,0.31l4.07,1.77l2.4,0.5l1.01-0.45l1.74-4.3
l1.46-2.56l2.09-1.93l4.57-0.93l3.57,1.6l2.5,1.75l1.86,0.67l6.07-1.43l7.17-2.4l5.93,0.26l-1.13,6.52l0.87,2.35l12.32,6.58
l2.01,0.72l2.53,0.34l1.27,1.45l-1.16,1.9l8.47-1.48l5.71-0.2l5.3,0.99l1.78,0.88l2.25,1.63l1.49,1.92l0.22,2.88l0.92-0.11
l4.71-1.9l1.16-0.15l1.46-0.37l2.74-0.36l2.78,0.18l2.39,1.43l0.22,0.43l4.48-2.35l3.79-2.7l9.43-3.52l10.24-2.18l9.72-3.66
L1181.71,536.9"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1140.34,551.84 1145.99,613.06 1138.52,616.98 1132.87,555.77 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sabirabad' ? 'active' : null}`}
            onClick={() => openPopup('sabirabad')}
          >
            <g>
              <polygon
                className="st34"
                points="1182.57,538.14 1188.22,599.36 1178.2,601.39 1172.56,540.17 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1172.56,540.17 1178.2,601.39 1168.51,605.03 1162.87,543.81 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1208.23,540.6 1213.87,601.82 1188.22,599.36 1182.57,538.14 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1162.87,543.81 1168.51,605.03 1158.27,607.22 1152.62,546.01 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1152.62,546.01 1158.27,607.22 1149.45,610.53 1143.8,549.31 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1143.8,549.31 1149.45,610.53 1145.99,613.06 1140.34,551.84 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1140.34,551.84 1145.99,613.06 1140.55,615.91 1134.91,554.7 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1231.32,546.69 1236.97,607.91 1213.87,601.82 1208.23,540.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1133.38,556.71 1139.03,617.92 1137.74,615.62 1132.09,554.4 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1135.98,556.6 1141.63,617.82 1140.55,615.91 1134.91,554.7 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1235.91,548.85 1241.56,610.07 1236.97,607.91 1231.32,546.69 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1135.98,556.6 1141.63,617.82 1139.55,620.81 1133.9,559.6 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1235.91,548.85 1241.56,610.07 1240.04,612.36 1234.39,551.14 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1133.9,559.6 1139.55,620.81 1134.45,622.2 1128.8,560.99 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1128.8,560.99 1134.45,622.2 1128.79,622.58 1123.15,561.36 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1238.79,548.31 1244.44,609.53 1242.44,612.53 1236.79,551.32 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1123.15,561.36 1128.79,622.58 1121.77,624.65 1116.13,563.44 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1116.13,563.44 1121.77,624.65 1115.72,626.94 1110.07,565.73 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1236.79,551.32 1242.44,612.53 1241.72,616.09 1236.07,554.87 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1234.39,551.14 1240.04,612.36 1239.17,616.58 1233.53,555.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1234.12,555.85 1239.77,617.07 1239.17,616.58 1233.53,555.37 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1234.12,555.85 1239.77,617.07 1239.62,617.72 1233.97,556.5 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1236.67,555.37 1242.32,616.59 1242.01,617.86 1236.36,556.65 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1145.29,564.53 1150.94,625.75 1139.75,627.54 1134.1,566.32 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="1155.89,564.49 1161.54,625.71 1150.94,625.75 1145.29,564.53 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1134.1,566.32 1139.75,627.54 1131.27,629.31 1125.62,568.09 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1112.26,569.55 1117.91,630.77 1115.72,626.94 1110.07,565.73 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1145.19,565.9 1150.84,627.12 1140.26,628.85 1134.61,567.63 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1154.89,565.87 1160.54,627.09 1150.84,627.12 1145.19,565.9 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1233.97,556.5 1239.62,617.72 1238.38,620.01 1232.73,558.79 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1125.62,568.09 1131.27,629.31 1123.02,630.85 1117.37,569.63 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1117.37,569.63 1123.02,630.85 1117.91,630.77 1112.26,569.55 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1236.36,556.65 1242.01,617.86 1240.76,620.19 1235.11,558.97 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1134.61,567.63 1140.26,628.85 1131.84,630.61 1126.19,569.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1110.36,570.9 1116.01,632.12 1112.79,626.48 1107.14,565.27 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1235.11,558.97 1240.76,620.19 1240.38,621.36 1234.73,560.14 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1126.19,569.39 1131.84,630.61 1122.9,632.23 1117.25,571.01 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1117.25,571.01 1122.9,632.23 1116.01,632.12 1110.36,570.9 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1162.28,567.13 1167.93,628.35 1161.54,625.71 1155.89,564.49 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="1232.73,558.79 1238.38,620.01 1237.71,622.12 1232.07,560.9 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1160.34,568.1 1165.99,629.31 1160.54,627.09 1154.89,565.87 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1233.52,561.58 1239.17,622.79 1237.71,622.12 1232.07,560.9 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1234.82,561.5 1240.47,622.72 1239.17,622.79 1233.52,561.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1167.24,570.92 1172.89,632.13 1167.93,628.35 1162.28,567.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1164.26,571.08 1169.91,632.29 1165.99,629.31 1160.34,568.1 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1240.62,564.23 1246.27,625.44 1240.47,622.72 1234.82,561.5 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1246.83,565.51 1252.47,626.72 1246.27,625.44 1240.62,564.23 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1167.24,570.92 1172.89,632.13 1169.13,635.26 1163.48,574.05 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1251.1,566.85 1256.75,628.07 1252.47,626.72 1246.83,565.51 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1163.48,574.05 1169.13,635.26 1165.91,638.04 1160.26,576.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1251.89,569.88 1257.54,631.1 1256.75,628.07 1251.1,566.85 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1161.41,579.71 1167.05,640.92 1165.91,638.04 1160.26,576.83 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1255.14,571.22 1260.79,632.44 1257.54,631.1 1251.89,569.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1159.06,580.29 1164.71,641.51 1163.3,637.92 1157.65,576.7 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1177.94,579.01 1183.59,640.23 1176.17,641.89 1170.52,580.67 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1164.43,581.71 1170.08,642.92 1167.05,640.92 1161.41,579.71 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1185.66,580.06 1191.31,641.27 1183.59,640.23 1177.94,579.01 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1170.52,580.67 1176.17,641.89 1172.75,643.3 1167.1,582.08 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1167.1,582.08 1172.75,643.3 1170.08,642.92 1164.43,581.71 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1177.88,580.45 1183.53,641.66 1177.02,643.12 1171.38,581.91 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1162.91,582.95 1168.56,644.17 1164.71,641.51 1159.06,580.29 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1184.14,581.25 1189.79,642.47 1183.53,641.66 1177.88,580.45 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1167.39,583.59 1173.04,644.8 1168.56,644.17 1162.91,582.95 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1171.38,581.91 1177.02,643.12 1173.04,644.8 1167.39,583.59 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1270.53,574.2 1276.18,635.42 1260.79,632.44 1255.14,571.22 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1275.42,575.7 1281.07,636.92 1276.18,635.42 1270.53,574.2 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1195.95,584.91 1201.6,646.13 1191.31,641.27 1185.66,580.06 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1194.16,585.97 1199.81,647.18 1189.79,642.47 1184.14,581.25 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1282.39,580.6 1288.03,641.81 1281.07,636.92 1275.42,575.7 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1205.48,590.44 1211.13,651.65 1201.6,646.13 1195.95,584.91 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1203.79,591.53 1209.44,652.74 1199.81,647.18 1194.16,585.97 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1282.39,580.6 1288.03,641.81 1284.44,647.63 1278.79,586.42 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1285.14,580.21 1290.79,641.43 1286.78,647.9 1281.13,586.69 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="1215.99,594.56 1221.64,655.77 1211.13,651.65 1205.48,590.44 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1278.79,586.42 1284.44,647.63 1282.95,650.65 1277.3,589.44 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1281.13,586.69 1286.78,647.9 1285.44,650.59 1279.79,589.38 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1213.95,595.53 1219.6,656.74 1209.44,652.74 1203.79,591.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1278.03,592.54 1283.68,653.75 1282.95,650.65 1277.3,589.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1279.51,594.49 1285.16,655.71 1283.68,653.75 1278.03,592.54 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1282.76,595.89 1288.4,657.1 1285.16,655.71 1279.51,594.49 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1284.18,596.44 1289.83,657.66 1288.4,657.1 1282.76,595.89 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1222.1,602.39 1227.75,663.61 1221.64,655.77 1215.99,594.56 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1219.49,602.65 1225.14,663.86 1219.6,656.74 1213.95,595.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1285.1,597.37 1290.75,658.58 1289.83,657.66 1284.18,596.44 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1285.1,597.37 1290.75,658.58 1290.47,659.22 1284.82,598 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1287.7,597.02 1293.35,658.24 1293,659.07 1287.35,597.86 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1222.1,602.39 1227.75,663.61 1225.47,668.1 1219.82,606.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1287.18,603.38 1292.83,664.59 1290.47,659.22 1284.82,598 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1219.82,606.88 1225.47,668.1 1222,671.6 1216.35,610.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1289.12,606.15 1294.77,667.36 1292.83,664.59 1287.18,603.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1289.47,607.99 1295.12,669.21 1294.77,667.36 1289.12,606.15 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1216.35,610.38 1222,671.6 1220.62,676.81 1214.98,615.59 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1289.47,607.99 1295.12,669.21 1294.7,670.37 1289.05,609.15 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1291.91,608 1297.55,669.22 1297.07,670.56 1291.42,609.35 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1289.05,609.15 1294.7,670.37 1292.49,674.4 1286.84,613.18 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1291.42,609.35 1297.07,670.56 1294.85,674.6 1289.2,613.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1213.92,620.7 1219.56,681.92 1218.15,676.87 1212.5,615.66 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1216.35,620.76 1222,681.98 1220.62,676.81 1214.98,615.59 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1286.84,613.18 1292.49,674.4 1291.84,676.1 1286.19,614.89 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1289.2,613.39 1294.85,674.6 1294.28,676.15 1288.63,614.93 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1288.63,614.93 1294.28,676.15 1294.37,677.75 1288.72,616.53 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1286.19,614.89 1291.84,676.1 1291.93,678.02 1286.28,616.81 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1287.5,619.34 1293.15,680.55 1291.93,678.02 1286.28,616.81 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1287.5,619.34 1293.15,680.55 1292.92,682.18 1287.27,620.96 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1216.35,620.76 1222,681.98 1218.49,689.4 1212.84,628.19 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1289.96,619.17 1295.61,680.38 1295.32,682.5 1289.67,621.29 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1287.27,620.96 1292.92,682.18 1291.66,683.25 1286.01,622.04 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1289.67,621.29 1295.32,682.5 1293.42,684.08 1287.77,622.87 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1286.01,622.04 1291.66,683.25 1289.1,684.75 1283.46,623.54 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1287.77,622.87 1293.42,684.08 1290.93,685.54 1285.28,624.32 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1283.46,623.54 1289.1,684.75 1286.92,686.56 1281.27,625.35 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1285.28,624.32 1290.93,685.54 1289.39,686.82 1283.75,625.6 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1212.84,628.19 1218.49,689.4 1208.17,695.22 1202.53,634.01 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1283.75,625.6 1289.39,686.82 1289.5,688.23 1283.85,627.02 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1281.27,625.35 1286.92,686.56 1287.08,688.8 1281.43,627.58 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1202.53,634.01 1208.17,695.22 1192.93,697.8 1187.28,636.59 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1187.28,636.59 1192.93,697.8 1178.82,698.2 1173.17,636.99 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1173.17,636.99 1178.82,698.2 1170.8,700.79 1165.15,639.58 			"
              />
            </g>
            <g>
              <polygon
                className="st18"
                points="1170.13,641.58 1175.78,702.8 1170.8,700.79 1165.15,639.58 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1168.7,642.78 1174.35,704 1166.25,700.75 1160.6,639.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1288.03,633.16 1293.68,694.37 1287.08,688.8 1281.43,627.58 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1181.9,643.89 1187.55,705.1 1175.78,702.8 1170.13,641.58 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1180.47,645.09 1186.11,706.3 1174.35,704 1168.7,642.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1290.71,636.33 1296.36,697.54 1293.68,694.37 1288.03,633.16 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1290.71,636.33 1296.36,697.54 1295.83,699.41 1290.18,638.19 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1293.25,635.98 1298.9,697.19 1298.14,699.93 1292.49,638.71 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1192.6,647.96 1198.25,709.18 1187.55,705.1 1181.9,643.89 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1191.13,649.16 1196.78,710.37 1186.11,706.3 1180.47,645.09 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1290.18,638.19 1295.83,699.41 1291.55,702.03 1285.9,640.82 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1292.49,638.71 1298.14,699.93 1293.41,702.81 1287.76,641.59 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="1204.92,651.92 1210.57,713.14 1198.25,709.18 1192.6,647.96 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1285.9,640.82 1291.55,702.03 1285.14,707.33 1279.5,646.11 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1203.54,653.14 1209.19,714.35 1196.78,710.37 1191.13,649.16 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1287.76,641.59 1293.41,702.81 1286.57,708.36 1280.92,647.14 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1279.5,646.11 1285.14,707.33 1280.89,708.91 1275.24,647.69 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1275.24,647.69 1280.89,708.91 1275.1,709.56 1269.45,648.34 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1280.92,647.14 1286.57,708.36 1281.36,710.22 1275.71,649.01 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1275.71,649.01 1281.36,710.22 1275.89,710.84 1270.24,649.63 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1269.45,648.34 1275.1,709.56 1267.55,712.61 1261.9,651.4 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1217.29,655.67 1222.94,716.88 1210.57,713.14 1204.92,651.92 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1261.9,651.4 1267.55,712.61 1257.2,714.56 1251.55,653.35 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1270.24,649.63 1275.89,710.84 1268.29,713.87 1262.64,652.66 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1216.34,657.01 1221.99,718.23 1209.19,714.35 1203.54,653.14 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1227.16,656.06 1232.81,717.28 1222.94,716.88 1217.29,655.67 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1262.64,652.66 1268.29,713.87 1259.42,715.55 1253.77,654.33 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1237.97,656.29 1243.62,717.51 1232.81,717.28 1227.16,656.06 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1226.76,657.43 1232.41,718.64 1221.99,718.23 1216.34,657.01 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1236.8,657.64 1242.45,718.86 1232.41,718.64 1226.76,657.43 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1251.55,653.35 1257.2,714.56 1256.05,719.76 1250.4,658.54 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1243.94,659.34 1249.59,720.56 1243.62,717.51 1237.97,656.29 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1253.77,654.33 1259.42,715.55 1258.44,720.02 1252.79,658.8 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="1242.27,660.44 1247.92,721.66 1242.45,718.86 1236.8,657.64 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="1248.19,661.02 1253.84,722.24 1249.59,720.56 1243.94,659.34 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1250.4,658.54 1256.05,719.76 1253.84,722.24 1248.19,661.02 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1293.25,635.98l-0.76,2.74l-4.73,2.88l-6.84,5.55l-5.21,1.87l-5.46,0.62l-7.6,3.03l-8.87,1.67l-0.98,4.47
l-3.83,4.31l-6.69-2.66l-5.47-2.8l-10.04-0.22l-10.42-0.41l-12.8-3.87l-12.42-3.98l-10.66-4.07l-11.76-2.3l-8.1-3.25l12.1-3.9
l14.58-0.41l14.1-2.41l9.29-5.23l3.25-6.87l-1.41-5.05l1.48-5.58l3.62-3.68l1.88-3.75l-5.54-7.12l-10.16-4l-9.63-5.56
l-10.03-4.72l-6.26-0.8l-6.5,1.46l-3.99,1.68l-4.47-0.63l-3.85-2.66l-1.41-3.59l3.81-3.33l2.79-2.29l-3.92-2.98l-5.45-2.22
l-9.69,0.03l-10.58,1.73l-8.42,1.76l-8.93,1.62l-6.9-0.11l-3.22-5.64l7.92-2.99l7.84-2.27l5.65-0.36l3.46-0.99l1.36-1.95
l-1.29-2.31l6.52-3.42l3.79-2.7l9.43-3.52l10.24-2.18l9.72-3.66l10.82-2.18l26.73,2.58l23.58,6.23l5.86,2.77l-1.99,3l-0.73,3.56
l0.61,0.5l-0.31,1.27l-1.25,2.33l-0.37,1.17l1.08-0.07l6.36,2.99l5.88,1.19l5.34,1.67l0.8,3.11l2.43,1l15.12,2.92l5.55,1.74
l7.86,5.53l-4.01,6.47l-1.34,2.69l0.65,2.75l1.11,1.41l4.69,2.02l1.46,1.47l-0.35,0.84l2.18,4.97l2.01,2.93l0.36,2.24l-0.48,1.35
l-2.22,4.04l-0.57,1.54l0.09,1.6l1.24,2.63l-0.29,2.12l-1.9,1.58l-2.48,1.45l-1.54,1.28l0.11,1.41l6.34,5.38L1293.25,635.98z
 M1285.9,640.82l4.28-2.62l0.53-1.87l-2.68-3.17l-6.6-5.58l-0.17-2.23l2.19-1.81l2.55-1.5l1.26-1.08l0.23-1.62l-1.22-2.53
l-0.09-1.92l0.65-1.7l2.21-4.03l0.42-1.16l-0.35-1.84l-1.93-2.77l-2.37-5.37l0.28-0.64l-0.92-0.92l-1.42-0.56l-3.25-1.39
l-1.47-1.95l-0.73-3.1l1.49-3.02l3.59-5.82l-6.97-4.9l-4.89-1.5l-15.4-2.98l-3.25-1.34l-0.79-3.03l-4.27-1.34l-6.21-1.28
l-5.79-2.73l-1.3,0.08l-1.46-0.68l0.66-2.11l1.24-2.29l0.15-0.65l-0.59-0.49l0.86-4.22l1.53-2.29l-4.59-2.16l-23.1-6.09
l-25.65-2.46l-10.02,2.03l-9.69,3.64l-10.25,2.19l-8.82,3.31l-3.46,2.53l-5.43,2.85l1.08,1.91l-2.08,2.99l-5.1,1.39l-5.66,0.37
l-7.02,2.08l-6.05,2.29l2.19,3.83l5.11,0.08l8.25-1.54l8.47-1.77l11.19-1.79l10.61-0.04l6.39,2.64l4.96,3.79l-3.76,3.13
l-3.22,2.78l1.15,2.88l3.03,2l2.67,0.37l3.42-1.41l7.42-1.66l7.72,1.05l10.29,4.85l9.53,5.53l10.51,4.12l6.11,7.83l-2.28,4.49
l-3.47,3.5l-1.37,5.21l1.38,5.17l-3.51,7.42l-10.32,5.82l-15.25,2.58l-14.11,0.4l-8.02,2.59l4.98,2l11.76,2.3l10.71,4.07
l12.31,3.96l12.37,3.75l9.87,0.39l10.81,0.23l5.97,3.05l4.24,1.68l2.22-2.48l1.14-5.19l10.36-1.95l7.55-3.05l5.79-0.65l4.25-1.58
L1285.9,640.82"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sabirabadColor ? sabirabadColor : null }}
                points="1182.57,538.14 1208.23,540.6 1231.32,546.69 1235.91,548.85 1234.39,551.14 1233.53,555.37 
1234.12,555.85 1233.97,556.5 1232.73,558.79 1232.07,560.9 1233.52,561.58 1234.82,561.5 1240.62,564.23 1246.83,565.51 
1251.1,566.85 1251.89,569.88 1255.14,571.22 1270.53,574.2 1275.42,575.7 1282.39,580.6 1278.79,586.42 1277.3,589.44 
1278.03,592.54 1279.51,594.49 1282.76,595.89 1284.18,596.44 1285.1,597.37 1284.82,598 1287.19,603.38 1289.12,606.15 
1289.47,607.99 1289.05,609.15 1286.84,613.18 1286.19,614.89 1286.28,616.81 1287.5,619.34 1287.27,620.96 1286.01,622.04 
1283.46,623.54 1281.27,625.35 1281.43,627.58 1288.03,633.16 1290.71,636.33 1290.18,638.19 1285.9,640.82 1279.5,646.11 
1275.24,647.69 1269.45,648.34 1261.9,651.4 1251.55,653.35 1250.4,658.54 1248.19,661.02 1243.94,659.34 1237.97,656.29 
1227.16,656.06 1217.29,655.67 1204.92,651.92 1192.6,647.96 1181.9,643.89 1170.13,641.58 1165.15,639.58 1173.17,636.99 
1187.28,636.59 1202.53,634.01 1212.84,628.19 1216.35,620.76 1214.98,615.59 1216.35,610.38 1219.82,606.88 1222.1,602.39 
1215.99,594.56 1205.48,590.44 1195.95,584.91 1185.66,580.06 1177.94,579.01 1170.52,580.67 1167.1,582.08 1164.43,581.71 
1161.41,579.71 1160.26,576.83 1163.48,574.05 1167.24,570.92 1162.28,567.13 1155.89,564.49 1145.29,564.53 1134.1,566.32 
1125.62,568.09 1117.37,569.63 1112.26,569.55 1110.07,565.73 1116.13,563.44 1123.15,561.36 1128.8,560.99 1133.9,559.6 
1135.98,556.6 1134.91,554.7 1140.34,551.84 1143.8,549.31 1152.62,546.01 1162.87,543.81 1172.56,540.17 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1248.96,663.11 1254.61,724.32 1247.92,721.66 1242.27,660.44 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1252.79,658.8 1258.44,720.02 1254.61,724.32 1248.96,663.11 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'lacin' ? 'active' : null}`}
            onClick={() => openPopup('lacin')}
          >
            <g>
              <polygon
                className="st43"
                points="475.14,525.89 480.79,587.11 473.83,591.12 468.19,529.9 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="479.87,529.27 485.52,590.48 480.79,587.11 475.14,525.89 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="468.19,529.9 473.83,591.12 464.98,597.73 459.34,536.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="485.05,534.66 490.7,595.87 485.52,590.48 479.87,529.27 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="495.81,534.86 501.46,596.07 490.7,595.87 485.05,534.66 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="506.25,534 511.9,595.21 501.46,596.07 495.81,534.86 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="459.34,536.51 464.98,597.73 452.25,602.13 446.6,540.91 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="562.59,529.96 568.24,591.18 554.85,593.12 549.2,531.9 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="549.2,531.9 554.85,593.12 533.29,595.29 527.64,534.07 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="527.64,534.07 533.29,595.29 511.9,595.21 506.25,534 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="599.24,526.94 604.89,588.16 594.47,589.68 588.82,528.47 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="575.48,529.97 581.13,591.18 568.24,591.18 562.59,529.96 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="588.82,528.47 594.47,589.68 581.13,591.18 575.48,529.97 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="446.6,540.91 452.25,602.13 439.12,605.72 433.47,544.51 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="433.47,544.51 439.12,605.72 434.47,607.11 428.82,545.9 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="599.24,526.94 604.89,588.16 599.45,592.86 593.8,531.65 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="604.5,524.79 610.15,586 601.89,593.15 596.24,531.94 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="435.36,548.79 441,610.01 434.47,607.11 428.82,545.9 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="433.87,549.96 439.52,611.18 430.02,606.99 424.37,545.78 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="596.24,531.94 601.89,593.15 601.58,598.28 595.93,537.06 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="593.8,531.65 599.45,592.86 599.09,598.94 593.44,537.72 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="451.69,553.41 457.34,614.62 441,610.01 435.36,548.79 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="449.88,554.51 455.53,615.73 439.52,611.18 433.87,549.96 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="598.94,540.79 604.59,602.01 599.09,598.94 593.44,537.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="603.09,544.37 608.73,605.59 604.59,602.01 598.94,540.79 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="457.62,558.71 463.27,619.93 457.34,614.62 451.69,553.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="455.26,559.24 460.91,620.46 455.53,615.73 449.88,554.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="607.28,556.6 612.92,617.82 608.73,605.59 603.09,544.37 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="462.43,572.12 468.08,633.33 463.27,619.93 457.62,558.71 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="460.08,572.68 465.73,633.89 460.91,620.46 455.26,559.24 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="469.02,576.72 474.67,637.94 468.08,633.33 462.43,572.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="467.2,577.76 472.85,638.98 465.73,633.89 460.08,572.68 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="478.86,579.45 484.5,640.66 474.67,637.94 469.02,576.72 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="477.23,580.58 482.88,641.8 472.85,638.98 467.2,577.76 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="607.28,556.6 612.92,617.82 611.54,631.27 605.89,570.05 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="609.73,556.43 615.38,617.65 613.99,631.16 608.34,569.95 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="483.2,581.63 488.85,642.84 484.5,640.66 478.86,579.45 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="481.06,582.45 486.71,643.66 482.88,641.8 477.23,580.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="485.9,584.83 491.55,646.05 488.85,642.84 483.2,581.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="483.59,585.41 489.24,646.62 486.71,643.66 481.06,582.45 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="605.89,570.05 611.54,631.27 611.38,637.96 605.73,576.75 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="608.34,569.95 613.99,631.16 613.83,637.9 608.18,576.68 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="489.12,592.59 494.77,653.81 491.55,646.05 485.9,584.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="486.77,593.15 492.42,654.36 489.24,646.62 483.59,585.41 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="605.73,576.75 611.38,637.96 610.02,644.41 604.37,583.2 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="608.18,576.68 613.83,637.9 612.36,644.86 606.71,583.64 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="491.93,595 497.58,656.22 494.77,653.81 489.12,592.59 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="490.13,596.1 495.77,657.31 492.42,654.36 486.77,593.15 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="497.12,596.51 502.77,657.72 497.58,656.22 491.93,595 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="528.94,592.22 534.59,653.44 512.57,657.43 506.92,596.21 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="506.92,596.21 512.57,657.43 502.77,657.72 497.12,596.51 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="604.37,583.2 610.02,644.41 603.04,649.21 597.4,587.99 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="539.03,593.55 544.68,654.77 534.59,653.44 528.94,592.22 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="496.33,597.9 501.98,659.12 495.77,657.31 490.13,596.1 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="606.71,583.64 612.36,644.86 604.96,649.94 599.32,588.72 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="506.94,597.58 512.59,658.8 501.98,659.12 496.33,597.9 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="528.77,593.65 534.42,654.86 512.59,658.8 506.94,597.58 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="537.49,594.79 543.14,656.01 534.42,654.86 528.77,593.65 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="542.49,596.06 548.13,657.28 544.68,654.77 539.03,593.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="540.26,596.78 545.91,658 543.14,656.01 537.49,594.79 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="597.4,587.99 603.04,649.21 596.37,654.75 590.72,593.53 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="599.32,588.72 604.96,649.94 598.72,655.13 593.07,593.92 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="546.6,601.69 552.25,662.9 548.13,657.28 542.49,596.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="544.38,602.43 550.03,663.64 545.91,658 540.26,596.78 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="593.07,593.92 598.72,655.13 597.36,660.2 591.71,598.99 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="590.72,593.53 596.37,654.75 594.7,660.99 589.05,599.78 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="549.69,603.6 555.34,664.82 552.25,662.9 546.6,601.69 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="548.14,604.82 553.79,666.04 550.03,663.64 544.38,602.43 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="554.56,604.57 560.21,665.78 555.34,664.82 549.69,603.6 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="595.15,601.87 600.8,663.08 594.7,660.99 589.05,599.78 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="553.81,605.93 559.46,667.14 553.79,666.04 548.14,604.82 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="637.12,598.68 642.77,659.89 635.15,660.65 629.5,599.44 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="572.75,605.15 578.4,666.36 560.21,665.78 554.56,604.57 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="643.73,599.68 649.38,660.89 642.77,659.89 637.12,598.68 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="599.74,603.8 605.39,665.01 600.8,663.08 595.15,601.87 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="571.96,606.51 577.61,667.72 559.46,667.14 553.81,605.93 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="577.53,606.28 583.17,667.5 578.4,666.36 572.75,605.15 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="575.85,607.38 581.5,668.6 577.61,667.72 571.96,606.51 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="581.59,608.63 587.24,669.84 583.17,667.5 577.53,606.28 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="603.63,606.84 609.28,668.06 605.39,665.01 599.74,603.8 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="579.37,609.38 585.02,670.59 581.5,668.6 575.85,607.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="645.38,604.08 651.03,665.3 649.38,660.89 643.73,599.68 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="629.5,599.44 635.15,660.65 622.43,668.32 616.78,607.1 			"
              />
            </g>
            <g>
              <polygon
                className="st151"
                points="616.78,607.1 622.43,668.32 609.28,668.06 603.63,606.84 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="583.86,611.77 589.5,672.99 587.24,669.84 581.59,608.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="581.35,612.1 586.99,673.32 585.02,670.59 579.37,609.38 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="645.38,604.08 651.03,665.3 649.84,670.54 644.19,609.32 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="644.19,609.32 649.84,670.54 637.44,670.62 631.79,609.41 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="583.86,611.77 589.5,672.99 588.64,676.89 582.99,615.68 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="646.32,610.69 651.97,671.91 638.05,671.99 632.41,610.78 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="647.84,603.99 653.49,665.2 651.97,671.91 646.32,610.69 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="631.79,609.41 637.44,670.62 626.36,675.42 620.71,614.2 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="582.99,615.68 588.64,676.89 584.36,679.64 578.71,618.43 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="632.41,610.78 638.05,671.99 629.54,675.69 623.89,614.47 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="578.71,618.43 584.36,679.64 579.28,680.8 573.64,619.59 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="573.64,619.59 579.28,680.8 575.87,682.01 570.22,620.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="624.46,618.02 630.11,679.23 626.36,675.42 620.71,614.2 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="570.22,620.8 575.87,682.01 574.4,684.99 568.75,623.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="567.1,626.24 572.75,687.45 571.86,685.09 566.22,623.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="569.63,626.14 575.28,687.35 574.4,684.99 568.75,623.78 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="624.46,618.02 630.11,679.23 629.34,684.23 623.7,623.01 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="569.63,626.14 575.28,687.35 574.07,689.67 568.43,628.46 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="626.95,617.58 632.6,678.8 631.71,684.77 626.06,623.55 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="568.43,628.46 574.07,689.67 570.9,691.31 565.26,630.09 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="565.26,630.09 570.9,691.31 568.81,691.66 563.16,630.45 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="623.7,623.01 629.34,684.23 622.51,687.88 616.87,626.66 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="626.06,623.55 631.71,684.77 623.53,689.06 617.88,627.85 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="616.87,626.66 622.51,687.88 600.3,693.7 594.65,632.49 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="617.88,627.85 623.53,689.06 614.32,691.45 608.68,630.23 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="570.29,634.39 575.94,695.6 568.81,691.66 563.16,630.45 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="568.83,635.64 574.48,696.85 563.47,690.73 557.82,629.52 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="608.68,630.23 614.32,691.45 601.17,694.91 595.52,633.7 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="581.87,636.27 587.52,697.48 575.94,695.6 570.29,634.39 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="594.65,632.49 600.3,693.7 587.52,697.48 581.87,636.27 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: lacinColor ? lacinColor : null }}
                points="599.24,526.94 593.8,531.65 593.44,537.72 598.94,540.79 603.09,544.37 607.28,556.6 
605.89,570.05 605.73,576.75 604.37,583.2 597.4,587.99 590.72,593.53 589.05,599.78 595.15,601.87 599.74,603.8 603.63,606.84 
616.78,607.1 629.5,599.44 637.12,598.68 643.73,599.68 645.38,604.08 644.19,609.32 631.79,609.41 620.71,614.2 624.46,618.02 
623.7,623.01 616.87,626.66 594.65,632.49 581.87,636.27 570.29,634.39 563.16,630.45 565.26,630.09 568.43,628.46 
569.63,626.14 568.75,623.78 570.22,620.8 573.64,619.59 578.71,618.43 582.99,615.68 583.86,611.77 581.59,608.63 
577.53,606.28 572.75,605.15 554.56,604.57 549.69,603.6 546.6,601.69 542.49,596.06 539.03,593.55 528.94,592.22 506.92,596.21 
497.12,596.51 491.93,595 489.12,592.59 485.9,584.83 483.2,581.63 478.86,579.45 469.02,576.72 462.43,572.12 457.62,558.71 
451.69,553.41 435.36,548.79 428.82,545.9 433.47,544.51 446.6,540.91 459.34,536.51 468.19,529.9 475.14,525.9 479.87,529.27 
485.05,534.66 495.81,534.86 506.25,534 527.64,534.07 549.2,531.9 562.59,529.96 575.48,529.97 588.82,528.47 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M646.32,610.69l-13.91,0.08l-8.52,3.69l3.06,3.11l-0.89,5.97l-8.18,4.3l-9.21,2.38l-13.16,3.47l-13.63,4.04
l-13.06-2.1l-11.01-6.12l3.03-0.08l3.33-0.56l2.11-1.08l0.8-1.56l-0.88-2.36l1.95-3.89l4.55-1.6l4.68-1.08l3.26-2.1l0.69-3.1
l-1.98-2.72l-3.52-1.99l-3.89-0.88l-18.15-0.58l-5.67-1.11l-3.75-2.39l-4.13-5.64l-2.77-1.99l-8.72-1.15l-21.84,3.93l-10.61,0.32
l-6.21-1.81l-3.36-2.95l-3.18-7.74l-2.53-2.96l-3.82-1.87l-10.04-2.82l-7.12-5.09l-4.82-13.43l-5.38-4.73l-16.01-4.55l-9.5-4.19
l1.7-0.55l6.48-1.93l13.05-3.58l12.38-4.26l8.4-6.37l9.12-5.26l6.44,4.61l4.71,4.88l9.3,0.19l10.4-0.88l21.33,0.09l21.24-2.16
l13.76-1.96l12.92,0l12.92-1.46l15.96-2.34l-8.26,7.15l-0.31,5.13l4.96,2.81l4.58,3.98l4.27,12.58l-1.39,13.51l-0.16,6.73
l-1.47,6.96l-7.39,5.08l-6.25,5.19l-1.35,5.07l4.96,1.71l5.03,2.14l3.36,2.65l10.89,0.21l12.41-7.49l9.13-0.95l8.37,1.27
l1.97,5.45L646.32,610.69z M620.71,614.2l11.08-4.8l12.41-0.09l1.19-5.24l-1.65-4.4l-6.61-1l-7.62,0.76l-12.72,7.66l-13.15-0.26
l-3.89-3.04l-4.59-1.93l-6.09-2.09l1.66-6.24l6.68-5.54l6.97-4.8l1.36-6.45l0.17-6.69l1.38-13.45l-4.19-12.23l-4.15-3.58
l-5.5-3.07l0.36-6.08l5.44-4.7l-10.42,1.52l-13.34,1.5l-12.89-0.01l-13.39,1.94l-21.56,2.17L506.25,534l-10.44,0.86l-10.76-0.2
l-5.18-5.39l-4.73-3.37l-6.96,4.01l-8.85,6.61l-12.73,4.4l-13.13,3.6l-4.65,1.39l6.54,2.89l16.33,4.62l5.93,5.3l4.81,13.41
l6.59,4.61l9.84,2.72l4.34,2.18l2.7,3.21l3.22,7.76l2.81,2.41l5.2,1.5l9.79-0.29l22.02-3.99l10.09,1.33l3.45,2.51l4.11,5.63
l3.09,1.92l4.87,0.96l18.19,0.58l4.77,1.13l4.06,2.35l2.27,3.14l-0.87,3.9l-4.27,2.75l-5.08,1.16l-3.41,1.21l-1.48,2.98
l0.88,2.36l-1.21,2.32l-3.17,1.64l-2.1,0.35l7.13,3.94l11.59,1.88l12.77-3.78l22.22-5.82l6.83-3.65l0.76-5L620.71,614.2"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="581.89,637.73 587.54,698.95 574.48,696.85 568.83,635.64 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="595.52,633.7 601.17,694.91 587.54,698.95 581.89,637.73 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'qubadli' ? 'active' : null}`}
            onClick={() => openPopup('qubadli')}
          >
            <g>
              <polygon
                className="st80"
                points="539.36,631.23 545.01,692.45 535.22,694.69 529.58,633.47 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="560.23,630.84 565.88,692.05 545.01,692.45 539.36,631.23 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="529.58,633.47 535.22,694.69 529.52,699.3 523.87,638.09 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="568.87,635.64 574.52,696.85 565.88,692.05 560.23,630.84 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="614.34,631.66 619.99,692.87 613.88,691.57 608.23,630.36 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="608.23,630.36 613.88,691.57 601.2,694.91 595.55,633.7 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="621.1,632.87 626.74,694.09 619.99,692.87 614.34,631.66 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="633.37,632.22 639.02,693.44 626.74,694.09 621.1,632.87 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="595.55,633.7 601.2,694.91 587.58,698.95 581.93,637.73 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="581.93,637.73 587.58,698.95 574.52,696.85 568.87,635.64 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="524.59,644.49 530.24,705.71 529.52,699.3 523.87,638.09 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="636.76,634.34 642.41,695.56 639.02,693.44 633.37,632.22 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="522.2,645.15 527.84,706.36 527.02,699.05 521.37,637.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="638.44,637.41 644.09,698.63 642.41,695.56 636.76,634.34 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="531.55,649.18 537.19,710.4 530.24,705.71 524.59,644.49 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="529.67,650.19 535.32,711.41 527.84,706.36 522.2,645.15 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="638.44,637.41 644.09,698.63 639.41,702.95 633.76,641.73 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="641.13,637.46 646.78,698.67 641.15,703.82 635.5,642.6 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="633.76,641.73 639.41,702.95 632.8,706.5 627.15,645.29 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="635.5,642.6 641.15,703.82 634.19,707.54 628.54,646.33 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="627.15,645.29 632.8,706.5 624.08,709.73 618.43,648.52 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="628.54,646.33 634.19,707.54 625.98,710.59 620.34,649.37 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="549.39,656.19 555.04,717.4 537.19,710.4 531.55,649.18 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="547.48,657.18 553.13,718.4 535.32,711.41 529.67,650.19 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="553.04,658.84 558.69,720.06 555.04,717.4 549.39,656.19 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="550.69,659.49 556.34,720.71 553.13,718.4 547.48,657.18 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="618.43,648.52 624.08,709.73 618.92,716.44 613.27,655.22 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="620.34,649.37 625.98,710.59 621.47,716.43 615.82,655.22 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="551.28,662.43 556.93,723.64 556.34,720.71 550.69,659.49 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="553.74,662.21 559.39,723.42 558.69,720.06 553.04,658.84 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="553.74,662.21 559.39,723.42 559.32,726.86 553.67,665.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="615.24,660.39 620.89,721.61 618.92,716.44 613.27,655.22 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="554.88,668.77 560.53,729.99 559.32,726.86 553.67,665.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="552.52,669.31 558.16,730.52 556.86,727.08 551.21,665.86 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="618.3,665.33 623.95,726.55 620.89,721.61 615.24,660.39 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="559.65,673.39 565.29,734.6 560.53,729.99 554.88,668.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="557.62,674.28 563.27,735.5 558.16,730.52 552.52,669.31 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="618.3,665.33 623.95,726.55 621.74,733.29 616.09,672.08 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="620.85,665.07 626.49,726.28 624.19,733.28 618.54,672.06 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="569.19,679.16 574.84,740.37 565.29,734.6 559.65,673.39 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="567.22,680.1 572.87,741.31 563.27,735.5 557.62,674.28 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="570.69,680.36 576.34,741.58 574.84,740.37 569.19,679.16 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="568.53,681.17 574.18,742.38 572.87,741.31 567.22,680.1 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="572.19,682.22 577.84,743.43 576.34,741.58 570.69,680.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="569.76,682.47 575.41,743.69 574.18,742.38 568.53,681.17 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="617.7,678.77 623.35,739.99 621.74,733.29 616.09,672.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="569.91,683.33 575.56,744.54 575.41,743.69 569.76,682.47 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="580.7,683.51 586.35,744.72 577.84,743.43 572.19,682.22 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="577.99,684.56 583.64,745.77 575.56,744.54 569.91,683.33 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="625.99,682.98 631.64,744.2 623.35,739.99 617.7,678.77 			"
              />
            </g>
            <g>
              <polygon
                className="st148"
                points="632.2,686.85 637.85,748.07 631.64,744.2 625.99,682.98 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="632.2,686.85 637.85,748.07 626.57,750.33 620.92,689.12 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="636.54,687.39 642.19,748.6 627.23,751.6 621.58,690.39 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="620.92,689.12 626.57,750.33 613.68,753.47 608.03,692.25 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="621.58,690.39 627.23,751.6 614.59,754.68 608.94,693.47 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="580.7,683.51 586.35,744.72 582.31,760.19 576.66,698.97 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="608.03,692.25 613.68,753.47 601.01,758.95 595.36,697.74 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="608.94,693.47 614.59,754.68 602.49,759.95 596.84,698.73 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="595.36,697.74 601.01,758.95 590.64,764.5 584.99,703.28 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="584.99,703.28 590.64,764.5 582.31,760.19 576.66,698.97 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M627.81,681.95l8.73,5.43l-14.96,3l-12.64,3.08l-12.1,5.27l-12.03,6.43l-10.75-5.57l3.93-15.04l-8.08-1.23
l-0.15-0.86l-1.23-1.31l-1.31-1.07l-9.6-5.81l-5.11-4.97l-1.3-3.44l0.07-3.44l-0.59-2.93l-3.22-2.31l-17.81-6.99l-7.47-5.04
l-0.83-7.32l6.72-5.45l11.23-2.53l22.09-0.43l8.92,4.95l11.59,1.88l12.77-3.78l13.7-3.62l7,1.49l6.26,1.12l12.91-0.69l4.49,2.91
l2.08,3.77l-5.63,5.15l-6.96,3.73l-8.21,3.04l-4.51,5.84l1.79,4.67l3.24,5.18l-2.3,6.99l1.48,5.93L627.81,681.95z M620.92,689.12
l11.28-2.27l-6.21-3.87l-8.29-4.21l-1.61-6.7l2.21-6.75l-3.06-4.94l-1.97-5.17l5.16-6.7l8.72-3.23l6.61-3.55l4.68-4.32
l-1.68-3.07l-3.39-2.12l-12.27,0.65l-6.75-1.22l-6.11-1.3l-12.68,3.34l-13.63,4.04l-13.06-2.1l-8.63-4.8l-20.87,0.39l-9.78,2.24
l-5.71,4.62l0.73,6.4l6.95,4.69l17.84,7.01l3.65,2.65l0.7,3.36l-0.07,3.44l1.21,3.13l4.77,4.61l9.55,5.77l1.5,1.2l1.5,1.85
l8.51,1.29l-4.04,15.46l8.33,4.31l10.37-5.55l12.67-5.49L620.92,689.12"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: qubadliColor }}
                points="633.37,632.22 636.76,634.34 638.44,637.41 633.76,641.73 627.15,645.29 618.43,648.52 
613.27,655.22 615.24,660.39 618.3,665.33 616.09,672.08 617.7,678.77 625.99,682.98 632.2,686.85 620.92,689.12 608.03,692.25 
595.36,697.74 584.99,703.28 576.66,698.97 580.7,683.51 572.19,682.22 570.69,680.36 569.19,679.16 559.65,673.39 
554.88,668.77 553.67,665.65 553.74,662.21 553.04,658.84 549.39,656.19 531.55,649.18 524.59,644.49 523.87,638.09 
529.58,633.47 539.36,631.23 560.23,630.84 568.87,635.64 581.93,637.73 595.55,633.7 608.23,630.36 614.34,631.66 621.1,632.87 
            "
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="596.84,698.73 602.49,759.95 590.46,766.38 584.81,705.17 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="584.81,705.17 590.46,766.38 579.71,760.81 574.06,699.6 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sederek' ? 'active' : null}`}
            onClick={() => openPopup('sederek')}
          >
            <g>
              <polygon
                className="st101"
                points="115.33,537.49 120.98,598.71 113.61,599.79 107.96,538.57 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="128.62,537.27 134.27,598.48 120.98,598.71 115.33,537.49 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="106.66,541.14 112.31,602.35 110.47,598.86 104.83,537.65 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="109.12,540.92 114.77,602.13 113.61,599.79 107.96,538.57 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="140.98,538.46 146.63,599.68 134.27,598.48 128.62,537.27 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="157.61,536.72 163.26,597.93 156.96,599.19 151.31,537.98 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="151.31,537.98 156.96,599.19 146.63,599.68 140.98,538.46 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="161.46,534.53 167.11,595.74 165.96,598.37 160.31,537.16 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="157.61,536.72 163.26,597.93 156.36,601.06 150.71,539.85 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="150.71,539.85 156.36,601.06 144.62,602.04 138.97,540.83 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="160.31,537.16 165.96,598.37 157.18,602.37 151.54,541.16 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="151.54,541.16 157.18,602.37 145.29,603.36 139.64,542.15 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="138.97,540.83 144.62,602.04 129.66,607.58 124.01,546.37 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="109.12,540.92 114.77,602.13 114.25,610.19 108.6,548.97 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="139.64,542.15 145.29,603.36 131,608.64 125.36,547.43 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="110.82,552.26 116.47,613.48 114.25,610.19 108.6,548.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="108.6,553.01 114.25,614.23 111.79,610.42 106.14,549.2 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="113.12,553.41 118.77,614.63 116.47,613.48 110.82,552.26 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="124.01,546.37 129.66,607.58 118.77,614.63 113.12,553.41 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sederekColor ? sederekColor : null }}
                points="157.61,536.72 150.71,539.85 138.97,540.83 124.01,546.37 113.12,553.41 110.82,552.26 
108.6,548.97 109.12,540.92 107.96,538.57 115.33,537.49 128.62,537.27 140.98,538.46 151.31,537.98 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M161.46,534.53l-1.15,2.63l-8.77,4l-11.9,0.99l-14.28,5.28l-12.21,7.95l-4.54-2.36l-2.46-3.81l0.53-8.07
l-1.84-3.49l10.58-1.54l13.66-0.21l12.3,1.17l9.85-0.46L161.46,534.53z M150.71,539.85l6.9-3.13l-6.3,1.26l-10.33,0.49
l-12.35-1.2l-13.29,0.23l-7.37,1.08l1.16,2.35l-0.52,8.05l2.22,3.29l2.3,1.15l10.9-7.04l14.96-5.54L150.71,539.85"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="125.36,547.43 131,608.64 118.79,616.59 113.14,555.38 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="113.14,555.38 118.79,616.59 114.25,614.23 108.6,553.01 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'serur' ? 'active' : null}`}
            onClick={() => openPopup('serur')}
          >
            <g>
              <polygon
                className="st1"
                points="185.58,530.99 191.23,592.21 180.44,593.29 174.79,532.08 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="174.79,532.08 180.44,593.29 168.12,596.91 162.47,535.69 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="162.47,535.69 168.12,596.91 166.76,597.2 161.12,535.98 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="161.12,535.98 166.76,597.2 166.28,598.28 160.63,537.06 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="193.22,534.33 198.87,595.54 191.23,592.21 185.58,530.99 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="151.86,541.07 157.5,602.28 145.61,603.27 139.96,542.05 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="160.63,537.06 166.28,598.28 157.5,602.28 151.86,541.07 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="139.96,542.05 145.61,603.27 131.32,608.55 125.68,547.33 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="125.68,547.33 131.32,608.55 120.81,615.39 115.17,554.17 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="113.5,555.3 119.15,616.52 117.39,615.63 111.74,554.42 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="120.6,555.31 126.25,616.52 120.81,615.39 115.17,554.17 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="119.69,556.63 125.33,617.85 119.15,616.52 113.5,555.3 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="128.68,556.14 134.33,617.36 126.25,616.52 120.6,555.31 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="124.15,557.1 129.8,618.31 125.33,617.85 119.69,556.63 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="128.68,556.14 134.33,617.36 131.32,619.32 125.67,558.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="206.59,551.14 212.24,612.35 198.87,595.54 193.22,534.33 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="129.73,560.6 135.38,621.81 131.32,619.32 125.67,558.1 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="127.39,561.32 133.04,622.54 127.56,619.15 121.91,557.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="207.77,554.71 213.41,615.92 212.24,612.35 206.59,551.14 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="207.77,554.71 213.41,615.92 212.85,619.71 207.2,558.5 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="131.02,565.58 136.67,626.8 135.38,621.81 129.73,560.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="128.58,565.82 134.23,627.03 133.04,622.54 127.39,561.32 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="210.22,554.58 215.87,615.79 215.25,619.83 209.6,558.62 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="207.2,558.5 212.85,619.71 210.18,624.37 204.53,563.15 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="209.6,558.62 215.25,619.83 212.45,624.74 206.8,563.53 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="204.53,563.15 210.18,624.37 203.78,631.76 198.13,570.55 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="206.8,563.53 212.45,624.74 206.19,631.97 200.54,570.76 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="200.54,570.76 206.19,631.97 205.91,634.68 200.26,573.46 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="198.13,570.55 203.78,631.76 203.4,635.33 197.76,574.11 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="202,576.46 207.65,637.68 203.4,635.33 197.76,574.11 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="131.02,565.58 136.67,626.8 135.64,647.59 129.99,586.37 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="131.38,590.67 137.03,651.88 135.64,647.59 129.99,586.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="129.02,591.2 134.67,652.41 133.17,647.78 127.52,586.57 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="202,576.46 207.65,637.68 196.8,647.86 191.15,586.65 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="205.09,576.12 210.74,637.33 198.9,648.43 193.25,587.21 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="136.25,594.46 141.89,655.67 137.03,651.88 131.38,590.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="133.14,594.51 138.79,655.72 134.67,652.41 129.02,591.2 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="136.25,594.46 141.89,655.67 138.09,658.17 132.45,596.96 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="132.67,598.02 138.32,659.24 138.09,658.17 132.45,596.96 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="130.32,598.74 135.97,659.95 135.53,657.87 129.88,596.66 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="138.03,601.39 143.68,662.6 138.32,659.24 132.67,598.02 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="139.04,601.6 144.69,662.82 143.68,662.6 138.03,601.39 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="142.16,601.83 147.8,663.04 144.69,662.82 139.04,601.6 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="191.15,586.65 196.8,647.86 186.04,659.59 180.39,598.37 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="136.28,602.47 141.93,663.69 135.97,659.95 130.32,598.74 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="138.2,602.96 143.85,664.17 141.93,663.69 136.28,602.47 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="144.72,602.5 150.37,663.71 147.8,663.04 142.16,601.83 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="193.25,587.21 198.9,648.43 187.86,660.4 182.21,599.18 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="141.24,603.16 146.88,664.38 143.85,664.17 138.2,602.96 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="142.71,603.56 148.36,664.77 146.88,664.38 141.24,603.16 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="145.55,603.79 151.2,665.01 150.37,663.71 144.72,602.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="143.14,604.16 148.79,665.37 148.36,664.77 142.71,603.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="145.71,604.69 151.36,665.9 151.2,665.01 145.55,603.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="143.28,605.07 148.93,666.29 148.79,665.37 143.14,604.16 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="146.19,605.32 151.84,666.53 151.36,665.9 145.71,604.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="144.01,606.08 149.66,667.3 148.93,666.29 143.28,605.07 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="180.39,598.37 186.04,659.59 173.14,667.11 167.49,605.89 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="182.21,599.18 187.86,660.4 174.23,668.27 168.59,607.06 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="167.49,605.89 173.14,667.11 159.31,670.92 153.66,609.71 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="153.66,609.71 159.31,670.92 151.84,666.53 146.19,605.32 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M200.26,573.46l4.83,2.65l-11.83,11.09l-11.05,11.97l-13.62,7.88l-15.69,4.32l-8.89-5.29l-0.72-1.01
l-0.14-0.92l-0.43-0.6l-1.48-0.4l-3.03-0.2l-1.92-0.49l-5.96-3.74l-0.44-2.08l3.26-2.15l-4.12-3.31l-1.5-4.63l1.06-20.75
l-1.19-4.49l-5.48-3.39l2.24-0.84l-4.46-0.47l-6.19-1.33l-1.76-0.88l12.59-8.15l14.96-5.54l11.74-0.98l7.43-3.38l0.62-1.4
l2.62-0.52l12.72-3.72l12.02-1.19l8.93,3.97l13.59,17.15l1.25,3.92l-0.62,4.04l-2.81,4.91l-6.26,7.23L200.26,573.46z
 M191.15,586.65L202,576.46l-4.24-2.35l0.37-3.56l6.4-7.4l2.66-4.65l0.57-3.79l-1.18-3.57l-13.37-16.81l-7.64-3.33l-10.79,1.08
l-12.32,3.62l-1.36,0.29l-0.49,1.08l-8.77,4l-11.9,0.99l-14.28,5.28l-10.51,6.84l5.43,1.14l8.09,0.83l-3.01,1.96l4.06,2.5
l1.29,4.98l-1.03,20.79l1.39,4.29l4.87,3.79l-3.8,2.5l0.23,1.07l5.36,3.36l1.01,0.22l3.11,0.22l2.57,0.67l0.83,1.3l0.16,0.89
l0.48,0.63l7.47,4.39l13.83-3.82l12.9-7.52L191.15,586.65"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: serurColor ? serurColor : null }}
                points="185.58,530.99 193.22,534.33 206.59,551.14 207.77,554.71 207.2,558.5 204.53,563.15 
198.13,570.55 197.76,574.11 202,576.46 191.15,586.65 180.39,598.37 167.49,605.89 153.66,609.71 146.19,605.32 145.71,604.69 
145.55,603.79 144.72,602.5 142.16,601.83 139.04,601.6 138.03,601.39 132.67,598.02 132.45,596.96 136.25,594.46 131.38,590.67 
129.99,586.37 131.02,565.58 129.73,560.6 125.67,558.1 128.68,556.14 120.6,555.31 115.17,554.17 125.68,547.33 139.96,542.05 
151.86,541.07 160.63,537.06 161.12,535.98 162.47,535.69 174.79,532.08 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="168.59,607.06 174.23,668.27 158.54,672.59 152.89,611.37 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="152.89,611.37 158.54,672.59 149.66,667.3 144.01,606.08 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'kengerli' ? 'active' : null}`}
            onClick={() => openPopup('kengerli')}
          >
            <g>
              <polygon
                className="st8"
                points="231.58,572.28 237.22,633.5 230.2,634.17 224.55,572.96 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="232,572.89 237.65,634.11 237.22,633.5 231.58,572.28 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="232,572.89 237.65,634.11 235.49,635 229.84,573.79 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="234.98,573.26 240.63,634.47 236.97,636 231.33,574.79 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="224.55,572.96 230.2,634.17 220.17,637.74 214.52,576.53 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="214.52,576.53 220.17,637.74 209.54,638.24 203.9,577.03 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="229.84,573.79 235.49,635 226.28,640.13 220.63,578.91 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="231.33,574.79 236.97,636 228.03,640.97 222.39,579.76 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="220.63,578.91 226.28,640.13 219.54,646.26 213.89,585.05 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="203.9,577.03 209.54,638.24 198.59,648.51 192.94,587.29 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="222.39,579.76 228.03,640.97 221.64,646.84 215.99,585.62 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="213.89,585.05 219.54,646.26 213.61,652.48 207.96,591.26 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="215.99,585.62 221.64,646.84 215.77,652.98 210.12,591.77 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="207.96,591.26 213.61,652.48 208.85,658.03 203.21,596.81 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="210.12,591.77 215.77,652.98 211.25,658.27 205.6,597.05 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="192.94,587.29 198.59,648.51 187.54,660.48 181.89,599.26 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="203.21,596.81 208.85,658.03 207.89,663.79 202.24,602.57 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="205.6,597.05 211.25,658.27 210.33,663.68 204.69,602.47 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="181.89,599.26 187.54,660.48 173.92,668.35 168.27,607.14 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="152.03,611.13 157.67,672.34 156.65,671.74 151,610.53 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="168.27,607.14 173.92,668.35 160.32,672.1 154.67,610.89 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="155.33,611.75 160.98,672.96 160.32,672.1 154.67,610.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="152.9,612.13 158.55,673.35 157.67,672.34 152.03,611.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="203.87,608.52 209.52,669.73 207.89,663.79 202.24,602.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="155.53,613.24 161.18,674.46 160.98,672.96 155.33,611.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="153.11,613.58 158.76,674.8 158.55,673.35 152.9,612.13 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="156.78,615.53 162.43,676.75 161.18,674.46 155.53,613.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="154.49,616.16 160.13,677.38 158.76,674.8 153.11,613.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="158.52,617.55 164.16,678.76 162.43,676.75 156.78,615.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="156.37,618.37 162.02,679.58 160.13,677.38 154.49,616.16 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="203.87,608.52 209.52,669.73 208.97,675.41 203.32,614.2 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="160.77,618.64 166.42,679.85 164.16,678.76 158.52,617.55 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="206.32,608.36 211.97,669.58 211.35,675.81 205.7,614.6 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="158.63,619.52 164.27,680.74 162.02,679.58 156.37,618.37 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="203.32,614.2 208.97,675.41 206.37,677.19 200.73,615.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="205.7,614.6 211.35,675.81 208.29,677.92 202.65,616.7 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="162.2,620.98 167.85,682.2 166.42,679.85 160.77,618.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="159.8,621.45 165.45,682.66 164.27,680.74 158.63,619.52 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="200.73,615.97 206.37,677.19 203.47,679.66 197.82,618.45 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="202.65,616.7 208.29,677.92 206.03,679.84 200.38,618.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="199.33,623.82 204.97,685.04 203.47,679.66 197.82,618.45 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="164.15,627.75 169.8,688.96 167.85,682.2 162.2,620.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="161.82,628.5 167.47,689.72 165.45,682.66 159.8,621.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="162.23,629.19 167.88,690.4 167.47,689.72 161.82,628.5 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="162.94,629.04 168.59,690.26 167.88,690.4 162.23,629.19 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="169.45,629.78 175.1,691 169.8,688.96 164.15,627.75 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="199.33,623.82 204.97,685.04 202.01,689.06 196.36,627.85 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="168.03,631 173.68,692.21 168.59,690.26 162.94,629.04 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="196.36,627.85 202.01,689.06 195.96,690.54 190.31,629.32 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="201.85,623.84 207.5,685.05 203.82,690.06 198.17,628.85 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="174.72,631.26 180.37,692.48 175.1,691 169.45,629.78 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="190.31,629.32 195.96,690.54 187.95,691.85 182.3,630.64 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: kengerliColor ? kengerliColor : null }}
                points="231.58,572.28 232,572.89 229.84,573.79 220.63,578.91 213.89,585.05 207.96,591.26 203.21,596.81 
202.24,602.57 203.87,608.52 203.32,614.2 200.73,615.97 197.82,618.45 199.33,623.82 196.36,627.85 190.31,629.32 182.3,630.64 
174.72,631.26 169.45,629.78 164.15,627.75 162.2,620.98 160.77,618.64 158.52,617.55 156.78,615.53 155.53,613.24 
155.33,611.75 154.67,610.89 168.27,607.14 181.89,599.26 192.94,587.29 203.9,577.03 214.52,576.53 224.55,572.96 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="182.3,630.64 187.95,691.85 180.37,692.48 174.72,631.26 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="198.17,628.85 203.82,690.06 196.55,691.83 190.9,630.61 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="174.07,632.68 179.72,693.9 173.68,692.21 168.03,631 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="182.55,631.99 188.2,693.2 179.72,693.9 174.07,632.68 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M215.99,585.62l-5.88,6.14l-4.52,5.28l-0.92,5.42l1.63,5.9l-0.61,6.23l-3.06,2.1l-2.26,1.92l1.46,5.22
l-3.68,5.01l-7.27,1.76l-8.35,1.37l-8.48,0.7l-6.04-1.69l-5.09-1.95l-0.71,0.14l-0.41-0.69l-2.02-7.05l-1.18-1.93l-2.25-1.15
l-1.89-2.21l-1.38-2.58l-0.2-1.45l-0.88-1.01l-1.03-0.6l1.53-0.52l14.65-4.04l12.9-7.52l10.76-11.72l11.73-11.01l11.39-0.52
l10.18-3.56l9.12-0.89l1.72,2.51l-3.65,1.53l-8.94,4.97L215.99,585.62z M202.24,602.57l0.97-5.76l4.75-5.55l5.93-6.22l6.74-6.13
l9.21-5.13l2.15-0.9l-0.42-0.61l-7.02,0.67l-10.03,3.57l-10.62,0.5l-10.95,10.27l-11.05,11.97l-13.62,7.88l-13.6,3.75l0.66,0.86
l0.2,1.5l1.25,2.29l1.73,2.01l2.26,1.09l1.42,2.34l1.95,6.77l5.3,2.03l5.27,1.48l7.58-0.63l8.01-1.31l6.05-1.47l2.97-4.03
l-1.5-5.37l2.9-2.48l2.59-1.78l0.55-5.68L202.24,602.57"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="190.9,630.61 196.55,691.83 188.2,693.2 182.55,631.99 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'babek' ? 'active' : null}`}
            onClick={() => openPopup('babek')}
          >
            <g>
              <polygon
                className="st31"
                points="232.87,574.15 238.52,635.36 236.97,636.01 231.32,574.79 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="231.32,574.79 236.97,636.01 228.03,640.98 222.38,579.76 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="236.61,579.58 242.26,640.79 238.52,635.36 232.87,574.15 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="222.38,579.76 228.03,640.98 221.64,646.85 215.99,585.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="237.93,584.25 243.58,645.46 242.26,640.79 236.61,579.58 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="239.77,587.03 245.42,648.25 243.58,645.46 237.93,584.25 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="243.7,588.44 249.35,649.65 245.42,648.25 239.77,587.03 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="215.99,585.63 221.64,646.85 215.76,652.99 210.11,591.77 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="256.73,590.36 262.38,651.57 249.35,649.65 243.7,588.44 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="210.11,591.77 215.76,652.99 211.25,658.27 205.6,597.06 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="260.61,592.11 266.26,653.33 262.38,651.57 256.73,590.36 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="205.6,597.06 211.25,658.27 210.33,663.69 204.68,602.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="266.41,597.52 272.05,658.73 266.26,653.33 260.61,592.11 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="266.41,597.52 272.05,658.73 269.02,660.28 263.37,599.06 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="269.58,597.66 275.22,658.88 270.17,661.41 264.52,600.19 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="263.37,599.06 269.02,660.28 258.09,663.77 252.44,602.56 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="264.52,600.19 270.17,661.41 259.26,664.89 253.62,603.68 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="203.87,608.52 209.52,669.74 207.89,663.79 202.24,602.58 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="206.32,608.37 211.96,669.58 210.33,663.69 204.68,602.47 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="252.44,602.56 258.09,663.77 247.28,669.73 241.63,608.51 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="253.62,603.68 259.26,664.89 250.63,669.68 244.99,608.46 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="206.32,608.37 211.96,669.58 211.35,675.82 205.7,614.6 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="245.79,611.17 251.44,672.39 247.28,669.73 241.63,608.51 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="205.7,614.6 211.35,675.82 208.29,677.92 202.64,616.71 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="250.97,613.04 256.62,674.25 251.44,672.39 245.79,611.17 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="202.64,616.71 208.29,677.92 206.03,679.84 200.38,618.63 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="253.37,612.18 259.02,673.39 259.46,678.25 253.81,617.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="251.38,617.3 257.03,678.51 256.62,674.25 250.97,613.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="199.32,623.83 204.97,685.04 203.47,679.67 197.82,618.45 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="201.85,623.84 207.5,685.06 206.03,679.84 200.38,618.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="252.28,622.35 257.92,683.57 257.03,678.51 251.38,617.3 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="168.03,631 173.68,692.22 170.35,690.94 164.7,629.73 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="164.92,630.45 170.57,691.66 170.35,690.94 164.7,629.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="162.56,630.93 168.21,692.15 167.31,689.11 161.66,627.89 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="201.85,623.84 207.5,685.06 203.82,690.07 198.17,628.85 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="166.31,632.53 171.96,693.75 170.57,691.66 164.92,630.45 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="163.96,633.04 169.61,694.26 168.21,692.15 162.56,630.93 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="198.17,628.85 203.82,690.07 196.55,691.83 190.9,630.62 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="174.07,632.69 179.72,693.9 173.68,692.22 168.03,631 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="190.9,630.62 196.55,691.83 188.2,693.21 182.55,631.99 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="182.55,631.99 188.2,693.21 179.72,693.9 174.07,632.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="164.8,635.36 170.44,696.58 169.61,694.26 163.96,633.04 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="167.25,635.24 172.9,696.46 171.96,693.75 166.31,632.53 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="263.56,627.13 269.21,688.34 257.92,683.57 252.28,622.35 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="274.46,631.88 280.1,693.1 269.21,688.34 263.56,627.13 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="276.5,634.23 282.15,695.44 280.1,693.1 274.46,631.88 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="167.25,635.24 172.9,696.46 170.96,707.04 165.31,645.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="278.03,636.44 283.68,697.66 282.15,695.44 276.5,634.23 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="165.56,648.35 171.21,709.56 170.96,707.04 165.31,645.83 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="172.73,647.75 178.38,708.97 171.21,709.56 165.56,648.35 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="278.03,636.44 283.68,697.66 282.65,699.73 277,638.52 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="280.62,636.22 286.27,697.44 284.94,700.06 279.29,638.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="163.25,649.91 168.9,711.12 168.51,707.16 162.86,645.94 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="172.03,649.18 177.68,710.4 168.9,711.12 163.25,649.91 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="277,638.52 282.65,699.73 281.23,701.57 275.58,640.36 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="279.29,638.85 284.94,700.06 283.79,701.56 278.14,640.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="277.04,644.12 282.69,705.34 281.23,701.57 275.58,640.36 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="281.85,644.08 287.5,705.3 282.69,705.34 277.04,644.12 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="222.63,647.14 228.28,708.36 213.25,712.81 207.61,651.59 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="238.35,649.01 244,710.23 228.28,708.36 222.63,647.14 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="222.74,648.59 228.39,709.81 217.19,713.13 211.54,651.91 			"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="237.73,650.38 243.38,711.59 228.39,709.81 222.74,648.59 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="256.25,649.84 261.9,711.06 244,710.23 238.35,649.01 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="205.19,654.6 210.84,715.81 207.73,716.19 202.08,654.97 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="202.08,654.97 207.73,716.19 204.98,716.89 199.33,655.68 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="199.33,655.68 204.98,716.89 178.38,708.97 172.73,647.75 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="224.44,662.29 230.09,723.5 213.25,712.81 207.61,651.59 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="208.76,655.16 214.41,716.38 210.84,715.81 205.19,654.6 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="255.71,651.21 261.36,712.43 243.38,711.59 237.73,650.38 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="204.85,656.01 210.5,717.22 208.12,717.52 202.47,656.31 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="281.85,644.08 287.5,705.3 287.78,710.26 282.13,649.04 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="206.4,656.26 212.05,717.47 210.5,717.22 204.85,656.01 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="202.47,656.31 208.12,717.52 205.95,718.07 200.3,656.86 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="200.3,656.86 205.95,718.07 204.56,718.58 198.91,657.36 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="198.91,657.36 204.56,718.58 177.68,710.4 172.03,649.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="206.51,657.02 212.16,718.24 212.05,717.47 206.4,656.26 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="284.23,642.68 289.87,703.9 290.31,711.07 284.66,649.85 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="273.55,651.18 279.2,712.39 261.9,711.06 256.25,649.84 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="282.13,649.04 287.78,710.26 279.2,712.39 273.55,651.18 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="209.01,657.14 214.65,718.35 214.41,716.38 208.76,655.16 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="273.64,652.59 279.29,713.8 261.36,712.43 255.71,651.21 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="284.66,649.85 290.31,711.07 279.29,713.8 273.64,652.59 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="209.01,657.14 214.65,718.35 212.83,720.42 207.18,659.21 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="207.18,659.21 212.83,720.42 212.24,721.62 206.59,660.41 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="208.28,661.12 213.93,722.33 212.24,721.62 206.59,660.41 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="206.79,662.3 212.44,723.51 209.46,722.26 203.81,661.04 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="212.21,662.01 217.86,723.22 213.93,722.33 208.28,661.12 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="210.55,663.13 216.2,724.34 212.44,723.51 206.79,662.3 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="215.57,663.82 221.22,725.04 217.86,723.22 212.21,662.01 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="213.24,664.57 218.89,725.79 216.2,724.34 210.55,663.13 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="249.79,662.86 255.44,724.08 230.09,723.5 224.44,662.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="213.67,666.43 219.32,727.65 218.89,725.79 213.24,664.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="216.24,666.69 221.89,727.9 221.22,725.04 215.57,663.82 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="216.24,666.69 221.89,727.9 218.07,730.72 212.42,669.5 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="257.43,665.38 263.08,726.59 255.44,724.08 249.79,662.86 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="213.1,671.3 218.75,732.51 218.07,730.72 212.42,669.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="210.83,672.03 216.48,733.24 215.41,730.52 209.76,669.31 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="214.87,672.45 220.52,733.66 218.75,732.51 213.1,671.3 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="213.04,673.46 218.69,734.67 216.48,733.24 210.83,672.03 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="263.21,669.02 268.86,730.24 263.08,726.59 257.43,665.38 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="217.18,673.66 222.83,734.87 220.52,733.66 214.87,672.45 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="215.1,674.51 220.74,735.72 218.69,734.67 213.04,673.46 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="219.2,675.73 224.85,736.95 222.83,734.87 217.18,673.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="216.79,676.17 222.44,737.39 220.74,735.72 215.1,674.51 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="219.58,677.46 225.23,738.68 224.85,736.95 219.2,675.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="217.13,677.71 222.78,738.92 222.44,737.39 216.79,676.17 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="263.21,669.02 268.86,730.24 264.8,737.14 259.15,675.92 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="265.98,668.58 271.63,729.79 266.82,737.8 261.17,676.59 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="219.58,677.46 225.23,738.68 225.33,743.39 219.68,682.18 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="220.22,683.77 225.87,744.98 225.33,743.39 219.68,682.18 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="217.9,684.5 223.55,745.71 222.91,743.68 217.26,682.46 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="259.15,675.92 264.8,737.14 256.34,743.22 250.69,682.01 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="222.06,684.95 227.71,746.16 225.87,744.98 220.22,683.77 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="261.17,676.59 266.82,737.8 258.95,743.46 253.3,682.25 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="220.22,685.98 225.87,747.19 223.55,745.71 217.9,684.5 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="228.16,687.36 233.81,748.57 227.71,746.16 222.06,684.95 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="225.98,688.26 231.63,749.48 225.87,747.19 220.22,685.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="226.45,689.33 232.1,750.54 231.63,749.48 225.98,688.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="229.04,689.3 234.69,750.51 233.81,748.57 228.16,687.36 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="229.04,689.3 234.69,750.51 232.89,752.82 227.24,691.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="252.9,689.69 258.55,750.9 256.34,743.22 250.69,682.01 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="227.24,691.6 232.89,752.82 230.8,754.57 225.15,693.36 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="225.15,693.36 230.8,754.57 229.34,756.07 223.69,694.85 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="223.69,694.85 229.34,756.07 228.87,757.99 223.22,696.77 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="225.21,699 230.86,760.22 228.87,757.99 223.22,696.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="257.07,696.32 262.72,757.53 258.55,750.9 252.9,689.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="223.06,699.81 228.7,761.02 226.36,758.37 220.71,697.15 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="257.07,696.32 262.72,757.53 260.17,759.78 254.52,698.57 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="229.95,701.75 235.6,762.97 230.86,760.22 225.21,699 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="259.78,696.35 265.43,757.56 261.76,760.73 256.11,699.51 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="228.22,702.82 233.87,764.04 228.7,761.02 223.06,699.81 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="254.52,698.57 260.17,759.78 254.83,762.25 249.18,701.03 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="256.11,699.51 261.76,760.73 257.08,762.89 251.43,701.68 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="235.36,704.06 241.01,765.28 235.6,762.97 229.95,701.75 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="233.96,705.25 239.61,766.47 233.87,764.04 228.22,702.82 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="242.64,705.95 248.29,767.16 241.01,765.28 235.36,704.06 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="249.18,701.03 254.83,762.25 253.37,767.12 247.72,705.91 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="241.02,707.08 246.67,768.3 239.61,766.47 233.96,705.25 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: babekColor ? babekColor : null }}
                points="232.87,574.15 236.61,579.58 237.93,584.25 239.77,587.03 243.7,588.44 256.73,590.36 
260.61,592.11 266.41,597.52 263.37,599.06 252.44,602.56 241.63,608.51 245.79,611.17 250.97,613.04 251.38,617.3 
252.28,622.35 263.56,627.13 274.46,631.88 276.5,634.23 278.03,636.44 277,638.52 275.58,640.36 277.04,644.12 281.85,644.08 
282.13,649.04 273.55,651.18 256.25,649.84 238.35,649.01 222.63,647.14 207.61,651.59 224.44,662.29 249.79,662.86 
257.43,665.38 263.21,669.02 259.15,675.92 250.69,682.01 252.9,689.69 257.07,696.32 254.52,698.57 249.18,701.03 
247.72,705.91 247,708.14 242.64,705.95 235.36,704.06 229.95,701.75 225.21,699 223.22,696.77 223.69,694.85 225.15,693.36 
227.24,691.6 229.04,689.3 228.16,687.36 222.06,684.95 220.22,683.77 219.68,682.18 219.58,677.46 219.2,675.73 217.18,673.66 
214.87,672.45 213.1,671.3 212.42,669.5 216.24,666.69 215.57,663.82 212.21,662.01 208.28,661.12 206.59,660.41 207.18,659.21 
209.01,657.14 208.76,655.16 205.19,654.6 202.08,654.97 199.33,655.68 172.73,647.75 165.56,648.35 165.31,645.83 
167.25,635.24 166.31,632.53 164.92,630.45 164.7,629.73 168.03,631 174.07,632.69 182.55,631.99 190.9,630.62 198.17,628.85 
201.85,623.84 200.38,618.63 202.64,616.71 205.7,614.6 206.32,608.37 204.68,602.47 205.6,597.06 210.11,591.77 215.99,585.63 
222.38,579.76 231.32,574.79 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="247,708.14 252.65,769.35 248.29,767.16 242.64,705.95 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="247.72,705.91 253.37,767.12 252.65,769.35 247,708.14 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="245.75,709.47 251.4,770.69 246.67,768.3 241.02,707.08 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="248.78,710.16 254.43,771.37 251.4,770.69 245.75,709.47 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M253.3,682.25l1.97,6.96l4.51,7.14l-3.67,3.16l-4.69,2.17l-2.65,8.48l-3.03-0.69l-4.73-2.39l-7.06-1.83
l-5.73-2.43l-5.17-3.02l-2.34-2.65l0.61-2.61l1.78-1.8l2.02-1.7l1.32-1.72l-0.48-1.06l-5.76-2.29l-2.32-1.48l-0.64-2.03
l-0.13-4.76l-0.34-1.53l-1.7-1.67l-2.06-1.05l-2.21-1.43l-1.07-2.72l3.91-2.87l-0.43-1.86l-2.69-1.45l-3.76-0.83l-2.98-1.26
l1.13-2.2l1.57-1.83l-0.11-0.76l-1.55-0.25l-2.38,0.3l-2.17,0.55l-1.39,0.5l-26.88-8.18l-8.78,0.72l-0.39-3.96l1.94-10.58
l-0.84-2.32l-1.4-2.11l-0.9-3.04l1.67-0.34l0.66,0.15l5.45,2.08l5.27,1.48l7.58-0.63l8.01-1.31l6.05-1.47l2.97-4.03l-1.5-5.37
l2.9-2.48l2.59-1.78l0.55-5.68l-1.63-5.95l0.97-5.76l4.75-5.55l5.93-6.22l6.74-6.13l9.21-5.13l4.23-1.76l0.56,0.74l4.38,6.38
l1.28,4.61l1.54,2.29l3.21,1.15l13.17,1.96l4.44,2.06l6.92,6.45l-5.06,2.53l-10.9,3.48l-8.63,4.78l2.62,1.68l5.76,2.03l0.43,4.86
l0.81,4.48l10.58,4.47l11.4,5.05l2.17,2.51l1.85,2.66l-1.33,2.63l-1.15,1.49l0.93,2.39l5.15-0.05l0.43,7.17l-11.01,2.74
l-17.93-1.38l-17.99-0.83l-14.98-1.78l-11.2,3.32l14.23,9.01l24.94,0.58l8.52,2.82l6.74,4.25l-4.8,8.01L253.3,682.25z
 M254.52,698.57l2.55-2.25l-4.17-6.63l-2.21-7.68l8.47-6.08l4.06-6.9l-5.78-3.64l-7.65-2.52l-25.35-0.57l-16.84-10.69l15.02-4.45
l15.72,1.87l17.9,0.83l17.31,1.34l8.58-2.13l-0.28-4.96l-4.81,0.04l-1.46-3.76l1.42-1.84l1.03-2.08l-1.53-2.21l-2.04-2.35
l-10.9-4.75l-11.28-4.78l-0.9-5.05l-0.41-4.26l-5.18-1.87l-4.16-2.66l10.8-5.96l10.93-3.5l3.04-1.55l-5.79-5.4l-3.88-1.76
l-13.03-1.92l-3.93-1.4l-1.84-2.79l-1.32-4.67l-3.74-5.43l-1.55,0.64l-8.94,4.97l-6.39,5.87l-5.88,6.14l-4.52,5.28l-0.92,5.42
l1.63,5.9l-0.61,6.23l-3.06,2.1l-2.26,1.92l1.46,5.22l-3.68,5.01l-7.27,1.76l-8.35,1.37l-8.48,0.7l-6.04-1.69l-3.33-1.27
l0.21,0.72l1.39,2.09l0.94,2.71l-1.94,10.58l0.25,2.52l7.16-0.6l26.33,8.02l3.02-0.79l3.11-0.38l3.57,0.57l0.24,1.97l-1.82,2.07
l-0.59,1.2l1.68,0.71l3.93,0.89l3.36,1.81l0.67,2.86l-3.82,2.82l0.68,1.79l1.77,1.15l2.31,1.21l2.02,2.08l0.38,1.73l0.1,4.72
l0.54,1.59l1.84,1.18l6.1,2.41l0.88,1.94l-1.8,2.3l-2.09,1.76l-1.46,1.49l-0.46,1.92l1.99,2.23l4.74,2.75l5.41,2.31l7.28,1.89
l4.36,2.19l0.72-2.23l1.46-4.87L254.52,698.57"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="251.43,701.68 257.08,762.89 254.43,771.37 248.78,710.16 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'naxcivan' ? 'active' : null}`}
            onClick={() => openPopup('naxcivan')}
          >
            <g>
              <polygon
                className="st52"
                points="222.58,648.59 228.23,709.81 217.03,713.13 211.38,651.91 			"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="237.57,650.38 243.22,711.59 228.23,709.81 222.58,648.59 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="255.55,651.21 261.2,712.43 243.22,711.59 237.57,650.38 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="271.28,652.42 276.93,713.64 261.2,712.43 255.55,651.21 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="225.62,660.93 231.26,722.14 217.03,713.13 211.38,651.91 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="271.28,652.42 276.93,713.64 268.97,719.57 263.32,658.36 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="224.28,662.29 229.93,723.5 213.1,712.81 207.45,651.59 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="275.6,651.34 281.25,712.56 270.41,720.67 264.76,659.45 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="249.99,661.49 255.64,722.7 231.26,722.14 225.62,660.93 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="263.32,658.36 268.97,719.57 255.64,722.7 249.99,661.49 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M256.09,649.84l19.51,1.5l-10.84,8.11l-14.58,3.42l-25.9-0.58l-16.84-10.69l15.02-4.45l15.72,1.87
L256.09,649.84z M263.32,658.36l7.95-5.94l-15.72-1.21l-17.99-0.83l-14.98-1.78l-11.2,3.32l14.23,9.01l24.37,0.56L263.32,658.36"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: naxcivanColor ? naxcivanColor : null }}
                points="271.28,652.42 263.32,658.36 249.99,661.49 225.62,660.93 211.38,651.91 222.58,648.59 
237.57,650.38 255.55,651.21 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="264.76,659.45 270.41,720.67 255.83,724.09 250.18,662.87 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="250.18,662.87 255.83,724.09 229.93,723.5 224.28,662.29 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'sahbuz' ? 'active' : null}`}
            onClick={() => openPopup('sahbuz')}
          >
            <g>
              <polygon
                className="st101"
                points="318.66,591.5 324.31,652.71 316.15,653.83 310.5,592.62 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="356.85,589.65 362.5,650.86 324.31,652.71 318.66,591.5 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="363.02,590.12 368.67,651.34 362.5,650.86 356.85,589.65 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="269.71,599.39 275.36,660.61 273.57,659.7 267.92,598.49 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="267.92,598.49 273.57,659.7 270.2,661.38 264.55,600.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="310.5,592.62 316.15,653.83 289.89,660.07 284.24,598.85 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="284.24,598.85 289.89,660.07 286.37,660.58 280.72,599.36 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="273.33,600.11 278.98,661.32 275.36,660.61 269.71,599.39 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="276.9,600.02 282.55,661.24 278.98,661.32 273.33,600.11 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="280.72,599.36 286.37,660.58 282.55,661.24 276.9,600.02 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="369.65,592.4 375.3,653.61 368.67,651.34 363.02,590.12 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="264.55,600.17 270.2,661.38 259.3,664.87 253.65,603.65 			"
              />
            </g>
            <g>
              <polygon
                className="st148"
                points="374.48,595.41 380.13,656.63 375.3,653.61 369.65,592.4 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="253.65,603.65 259.3,664.87 250.67,669.65 245.02,608.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="375.62,597.95 381.27,659.16 380.13,656.63 374.48,595.41 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="247.65,610.12 253.29,671.33 250.67,669.65 245.02,608.43 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="245.83,611.14 251.48,672.36 247.32,669.7 241.67,608.48 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="253.41,612.15 259.06,673.37 253.29,671.33 247.65,610.12 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="375.62,597.95 381.27,659.16 379.78,662.45 374.13,601.23 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="251.01,613.01 256.65,674.23 251.48,672.36 245.83,611.14 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="378.15,597.8 383.8,659.01 382.12,662.72 376.47,601.5 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="253.41,612.15 259.06,673.37 259.49,678.23 253.85,617.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="251.41,617.27 257.06,678.49 256.65,674.23 251.01,613.01 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="374.13,601.23 379.78,662.45 376.36,667.66 370.72,606.45 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="376.47,601.5 382.12,662.72 378.7,667.93 373.05,606.71 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="254.66,621.5 260.31,682.71 259.49,678.23 253.85,617.01 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="252.31,622.32 257.96,683.54 257.06,678.49 251.41,617.27 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="370.72,606.45 376.36,667.66 374.36,677.45 368.71,616.23 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="265.24,625.97 270.89,687.18 260.31,682.71 254.66,621.5 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="373.05,606.71 378.7,667.93 376.75,677.69 371.1,616.48 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="263.6,627.1 269.25,688.31 257.96,683.54 252.31,622.32 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="368.71,616.23 374.36,677.45 371.77,680.38 366.13,619.17 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="371.1,616.48 376.75,677.69 373.62,681.18 367.97,619.97 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="276.64,631.02 282.29,692.24 270.89,687.18 265.24,625.97 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="274.49,631.85 280.14,693.07 269.25,688.31 263.6,627.1 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="278.81,633.53 284.45,694.75 282.29,692.24 276.64,631.02 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="276.54,634.2 282.18,695.42 280.14,693.07 274.49,631.85 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="366.13,619.17 371.77,680.38 358.27,688.4 352.62,627.18 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="367.97,619.97 373.62,681.18 360.09,689.2 354.44,627.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="278.07,636.41 283.71,697.63 282.18,695.42 276.54,634.2 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="280.66,636.19 286.3,697.41 284.45,694.75 278.81,633.53 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="352.62,627.18 358.27,688.4 354.08,692.79 348.43,631.58 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="354.44,627.98 360.09,689.2 356.63,692.89 350.99,631.67 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="280.66,636.19 286.3,697.41 284.98,700.04 279.33,638.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="348.97,633.59 354.62,694.8 354.08,692.79 348.43,631.58 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="279.33,638.82 284.98,700.04 283.83,701.53 278.18,640.31 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="320.5,636.11 326.15,697.32 316.08,699.31 310.43,638.1 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="351.65,634.25 357.3,695.47 355.99,695.85 350.34,634.64 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="310.43,638.1 316.08,699.31 302.29,701.01 296.64,639.8 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="319.22,637.77 324.87,698.98 316.51,700.63 310.86,639.41 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="279.11,642.7 284.76,703.92 283.83,701.53 278.18,640.31 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="310.86,639.41 316.51,700.63 302.74,702.33 297.09,641.11 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="282.97,642.67 288.62,703.89 284.76,703.92 279.11,642.7 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="296.64,639.8 302.29,701.01 288.62,703.89 282.97,642.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="277.08,644.1 282.73,705.31 281.26,701.55 275.62,640.33 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="348.97,633.59 354.62,694.8 343.81,699.74 338.16,638.52 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="282.96,644.05 288.61,705.26 282.73,705.31 277.08,644.1 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="297.09,641.11 302.74,702.33 288.61,705.26 282.96,644.05 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="325.29,640.76 330.94,701.98 326.15,697.32 320.5,636.11 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="350.34,634.64 355.99,695.85 345.24,700.75 339.59,639.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="323.51,641.91 329.16,703.12 324.87,698.98 319.22,637.77 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="330.87,641.96 336.52,703.18 330.94,701.98 325.29,640.76 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="338.16,638.52 343.81,699.74 336.52,703.18 330.87,641.96 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M367.97,619.97l-13.53,8.01l-3.45,3.69l0.67,2.58l-1.31,0.38l-10.75,4.9l-8.49,4.01l-7.59-1.65l-4.28-4.14
l-8.37,1.64l-13.77,1.7l-14.13,2.93l-5.88,0.05l-1.46-3.76l1.42-1.84l1.03-2.08l-1.53-2.21l-2.04-2.35l-10.9-4.75l-11.28-4.78
l-0.9-5.05l-0.41-4.26l-5.18-1.87l-4.16-2.66l10.8-5.96l10.93-3.5l4.99-2.54l0.65,0.61l2.26,1.14l2.62,0.47l2.99-0.07l3.4-0.61
l3.42-0.48l26.26-6.25l8.54-1.17l38.8-1.87l6.77,0.52l7.36,2.56l5.35,3.44l1.35,3.01l-1.68,3.7l-3.42,5.21l-1.95,9.77
L367.97,619.97z M352.62,627.18l13.51-8.01l2.59-2.94l2-9.79l3.42-5.21l1.49-3.29l-1.13-2.54l-4.84-3.01l-6.63-2.28l-6.17-0.48
l-38.19,1.85l-8.17,1.12l-26.25,6.24l-3.52,0.51l-3.82,0.66l-3.57,0.08l-3.62-0.71l-1.79-0.91l-3.37,1.68l-10.9,3.48l-8.63,4.78
l2.62,1.68l5.76,2.04l0.43,4.86l0.81,4.48l10.58,4.47l11.4,5.05l2.17,2.51l1.85,2.66l-1.33,2.63l-1.15,1.49l0.93,2.39l3.86-0.03
l13.68-2.87l13.79-1.7l10.06-1.99l4.79,4.65l5.58,1.2l7.29-3.44l10.81-4.93l-0.54-2.01L352.62,627.18"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: sahbuzColor ? sahbuzColor : null }}
                points="363.02,590.12 369.65,592.4 374.48,595.41 375.62,597.95 374.13,601.23 370.72,606.45 
368.71,616.23 366.13,619.17 352.62,627.18 348.43,631.58 348.97,633.59 338.16,638.52 330.87,641.96 325.29,640.76 
320.5,636.11 310.43,638.1 296.64,639.8 282.97,642.67 279.11,642.7 278.18,640.31 279.33,638.82 280.66,636.19 278.81,633.53 
276.64,631.02 265.24,625.97 254.66,621.5 253.85,617.01 253.41,612.15 247.65,610.12 245.02,608.43 253.65,603.65 
264.55,600.17 267.92,598.49 269.71,599.39 273.33,600.11 276.9,600.02 280.72,599.36 284.24,598.85 310.5,592.62 318.66,591.5 
356.85,589.65 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="331.1,643.55 336.75,704.77 329.16,703.12 323.51,641.91 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="339.59,639.54 345.24,700.75 336.75,704.77 331.1,643.55 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'culfa' ? 'active' : null}`}
            onClick={() => openPopup('culfa')}
          >
            <g>
              <polygon
                className="st11"
                points="349.16,635.69 354.81,696.91 354.7,696.53 349.05,635.32 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="318.92,638 324.57,699.21 316.2,700.85 310.56,639.64 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="310.56,639.64 316.2,700.85 302.44,702.56 296.79,641.34 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="296.79,641.34 302.44,702.56 289.69,705.24 284.04,644.03 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="349.05,635.32 354.7,696.53 344.94,700.98 339.29,639.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="323.21,642.14 328.85,703.35 324.57,699.21 318.92,638 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="364.25,639.5 369.9,700.72 354.81,696.91 349.16,635.69 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="330.8,643.78 336.45,705 328.85,703.35 323.21,642.14 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="339.29,639.77 344.94,700.98 336.45,705 330.8,643.78 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="284.04,644.03 289.69,705.24 290.04,711.27 284.39,650.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="368.48,642.53 374.13,703.74 369.9,700.72 364.25,639.5 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="284.39,650.05 290.04,711.27 279.71,713.83 274.06,652.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="372.36,646.4 378.01,707.61 374.13,703.74 368.48,642.53 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="274.06,652.62 279.71,713.83 270.3,720.87 264.65,659.65 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="377.66,649.97 383.31,711.19 378.01,707.61 372.36,646.4 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="264.65,659.65 270.3,720.87 258.39,723.67 252.74,662.45 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="380.24,651.09 385.89,712.31 383.31,711.19 377.66,649.97 			"
              />
            </g>
            <g>
              <polygon
                className="st139"
                points="258.98,664.53 264.62,725.74 258.39,723.67 252.74,662.45 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="380.24,651.09 385.89,712.31 378.33,716.01 372.68,654.79 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="257.17,665.58 262.82,726.8 252.88,723.53 247.24,662.31 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="384.12,650.92 389.77,712.14 379.75,717.03 374.11,655.81 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="372.68,654.79 378.33,716.01 370.94,719.29 365.29,658.08 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="374.11,655.81 379.75,717.03 372.28,720.35 366.63,659.14 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="265.71,668.78 271.36,729.99 264.62,725.74 258.98,664.53 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="262.95,669.22 268.6,730.44 262.82,726.8 257.17,665.58 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="365.29,658.08 370.94,719.29 363.36,722.35 357.72,661.14 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="366.63,659.14 372.28,720.35 364.87,723.34 359.23,662.12 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="357.72,661.14 363.36,722.35 359.56,725.76 353.91,664.54 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="359.23,662.12 364.87,723.34 361.68,726.3 356.03,665.08 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="353.91,664.54 359.56,725.76 356.58,729.23 350.94,668.02 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="265.71,668.78 271.36,729.99 266.56,738 260.91,676.79 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="356.03,665.08 361.68,726.3 358.74,729.74 353.09,668.53 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="350.94,668.02 356.58,729.23 352.92,733.05 347.27,671.83 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="353.09,668.53 358.74,729.74 354.98,733.66 349.33,672.44 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="260.91,676.79 266.56,738 258.69,743.67 253.04,682.45 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="347.27,671.83 352.92,733.05 348.54,736.67 342.9,675.45 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="349.33,672.44 354.98,733.66 350.47,737.39 344.82,676.17 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="342.9,675.45 348.54,736.67 340.68,742.37 335.03,681.16 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="255.01,689.41 260.66,750.63 258.69,743.67 253.04,682.45 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="344.82,676.17 350.47,737.39 342.56,743.12 336.92,681.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="252.63,689.89 258.28,751.11 256.07,743.43 250.42,682.21 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="335.03,681.16 340.68,742.37 332.95,748.07 327.3,686.86 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="336.92,681.91 342.56,743.12 334.54,749.01 328.89,687.8 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="327.3,686.86 332.95,748.07 325.2,751.78 319.55,690.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="259.52,696.55 265.17,757.77 260.66,750.63 255.01,689.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="256.8,696.52 262.45,757.73 258.28,751.11 252.63,689.89 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="328.89,687.8 334.54,749.01 326.63,752.8 320.98,691.58 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="319.55,690.57 325.2,751.78 317.13,755.53 311.48,694.31 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="259.52,696.55 265.17,757.77 261.5,760.93 255.85,699.71 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="320.98,691.58 326.63,752.8 318.78,756.45 313.13,695.23 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="255.85,699.71 261.5,760.93 256.81,763.09 251.16,701.88 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="311.48,694.31 317.13,755.53 313.5,759.11 307.85,697.9 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="313.13,695.23 318.78,756.45 315.69,759.6 310.04,698.38 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="307.85,697.9 313.5,759.11 310.76,762.7 305.11,701.48 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="310.04,698.38 315.69,759.6 312.82,763.33 307.17,702.11 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="305.11,701.48 310.76,762.7 308.38,764.63 302.74,703.42 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="251.16,701.88 256.81,763.09 254.62,770.17 248.97,708.96 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="307.17,702.11 312.82,763.33 310.77,765 305.12,703.78 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="250.17,709.24 255.82,770.46 254.62,770.17 248.97,708.96 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="249.24,710.55 254.89,771.77 251.88,771.09 246.23,709.88 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="305.12,703.78 310.77,765 310.5,766.68 304.85,705.47 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="257.91,710.07 263.55,771.29 255.82,770.46 250.17,709.24 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="302.74,703.42 308.38,764.63 307.96,767.28 302.31,706.06 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="256.82,711.38 262.47,772.59 254.89,771.77 249.24,710.55 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="272.69,713.69 278.34,774.9 263.55,771.29 257.91,710.07 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="310.63,711.15 316.28,772.37 307.96,767.28 302.31,706.06 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="271.63,715 277.28,776.21 262.47,772.59 256.82,711.38 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="297.86,716.09 303.51,777.31 278.34,774.9 272.69,713.69 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="296.58,717.35 302.23,778.56 277.28,776.21 271.63,715 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="316.2,716.36 321.85,777.57 316.28,772.37 310.63,711.15 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="309.9,720.3 315.55,781.51 303.51,777.31 297.86,716.09 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="316.2,716.36 321.85,777.57 315.55,781.51 309.9,720.3 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: culfaColor ? culfaColor : null }}
                points="349.05,635.32 349.16,635.69 364.25,639.5 368.48,642.53 372.36,646.4 377.66,649.97 
380.24,651.09 372.68,654.79 365.29,658.08 357.72,661.14 353.91,664.54 350.94,668.02 347.27,671.83 342.9,675.45 
335.03,681.16 327.3,686.86 319.55,690.57 311.48,694.31 307.85,697.9 305.11,701.48 302.74,703.42 302.31,706.06 310.63,711.15 
316.2,716.36 309.9,720.3 297.86,716.09 272.69,713.69 257.91,710.07 250.17,709.24 248.97,708.96 251.16,701.88 255.85,699.71 
259.52,696.55 255.01,689.41 253.04,682.45 260.91,676.79 265.71,668.78 258.98,664.53 252.74,662.45 264.65,659.65 
274.06,652.62 284.39,650.05 284.04,644.03 296.79,641.34 310.56,639.64 318.92,638 323.21,642.14 330.8,643.78 339.29,639.77 
            "
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="309.25,721.77 314.9,782.99 302.23,778.56 296.58,717.35 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="319.25,716.39 324.9,777.61 316.22,782.96 310.57,721.75 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="309.93,721.92 315.58,783.13 314.9,782.99 309.25,721.77 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M379.54,648.96l4.58,1.96l-10.01,4.89l-7.48,3.33l-7.4,2.99l-3.2,2.96l-2.94,3.44l-3.76,3.92l-4.51,3.73
l-7.9,5.74l-8.02,5.89l-7.91,3.78l-7.85,3.65l-3.09,3.15l-2.87,3.73l-2.05,1.67l-0.26,1.69l7.79,4.79l6.6,6.14l-8.68,5.35
l-0.64,0.17l-0.68-0.15l-12.67-4.42L271.63,715l-14.81-3.62l-7.58-0.83l-3.01-0.67l1.23-3.77l1.46-4.87l5.34-2.47l2.55-2.25
l-4.17-6.63l-2.21-7.68l8.47-6.08l4.06-6.9l-5.78-3.64l-9.93-3.27l15.98-3.75l9.39-7.01l9.26-2.3l-0.36-6.1l14.83-3.12l13.79-1.7
l10.06-1.99l4.79,4.65l5.58,1.2l7.29-3.44l11.62-5.19l1.44-0.41l0.5,1.54l14.68,3.78l4.48,3.21l3.85,3.87L379.54,648.96z
 M372.68,654.79l7.56-3.7l-2.58-1.12l-5.3-3.58l-3.88-3.87l-4.23-3.02l-15.09-3.81l-0.11-0.38l-9.76,4.45l-8.49,4.01l-7.59-1.65
l-4.28-4.14l-8.37,1.64l-13.77,1.7l-12.74,2.69l0.35,6.03l-10.34,2.57l-9.41,7.03l-11.91,2.8l6.23,2.08l6.74,4.25l-4.81,8.01
l-7.87,5.66l1.97,6.96l4.51,7.14l-3.67,3.16l-4.69,2.17l-2.19,7.08l1.2,0.28l7.73,0.83l14.79,3.61l25.17,2.41l12.04,4.2l6.3-3.94
l-5.58-5.21l-8.32-5.09l0.43-2.64l2.38-1.93l2.74-3.59l3.63-3.59l8.07-3.75l7.75-3.71l7.73-5.7l7.87-5.7l4.38-3.62l3.67-3.81
l2.97-3.48l3.81-3.4l7.57-3.06L372.68,654.79"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="310.57,721.75 316.22,782.96 315.58,783.13 309.93,721.92 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'ordubad' ? 'active' : null}`}
            onClick={() => openPopup('ordubad')}
          >
            <g>
              <polygon
                className="st37"
                points="382.18,651.74 387.82,712.95 379.8,716.87 374.15,655.65 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="374.15,655.65 379.8,716.87 372.33,720.19 366.68,658.98 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="395.16,657.25 400.8,718.46 387.82,712.95 382.18,651.74 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="366.68,658.98 372.33,720.19 364.92,723.18 359.27,661.96 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="399.23,660.74 404.88,721.96 400.8,718.46 395.16,657.25 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="359.27,661.96 364.92,723.18 361.73,726.14 356.08,664.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="400.18,662.34 405.83,723.56 404.88,721.96 399.23,660.74 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="400.18,662.34 405.83,723.56 405.75,724.9 400.1,663.69 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="356.08,664.92 361.73,726.14 358.79,729.58 353.14,668.37 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="402.64,662.12 408.29,723.34 408.18,725.11 402.53,663.9 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="400.1,663.69 405.75,724.9 404.72,726.06 399.07,664.85 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="402.53,663.9 408.18,725.11 406.43,726.95 400.78,665.74 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="399.07,664.85 404.72,726.06 392.96,731.83 387.31,670.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="400.78,665.74 406.43,726.95 403.87,728.18 398.22,666.97 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="353.14,668.37 358.79,729.58 355.03,733.5 349.38,672.29 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="398.22,666.97 403.87,728.18 394.91,732.59 389.26,671.37 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="349.38,672.29 355.03,733.5 350.52,737.23 344.87,676.01 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="387.31,670.62 392.96,731.83 388.51,737.26 382.86,676.04 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="389.26,671.37 394.91,732.59 390.97,737.41 385.32,676.19 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="344.87,676.01 350.52,737.23 342.61,742.96 336.97,681.75 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="385.32,676.19 390.97,737.41 390.94,742.82 385.29,681.6 			"
              />
            </g>
            <g>
              <polygon
                className="st83"
                points="382.86,676.04 388.51,737.26 388.49,743.07 382.84,681.86 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="336.97,681.75 342.61,742.96 334.59,748.86 328.94,687.64 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="328.94,687.64 334.59,748.86 326.68,752.64 321.03,691.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="386.19,688.21 391.84,749.42 388.49,743.07 382.84,681.86 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="321.03,691.42 326.68,752.64 318.83,756.29 313.18,695.07 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="313.18,695.07 318.83,756.29 315.74,759.44 310.09,698.22 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="310.09,698.22 315.74,759.44 312.87,763.17 307.22,701.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="390.6,694.76 396.25,755.97 391.84,749.42 386.19,688.21 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="307.22,701.95 312.87,763.17 310.82,764.84 305.17,703.62 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="305.17,703.62 310.82,764.84 310.55,766.52 304.9,705.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="393.17,701.72 398.82,762.93 396.25,755.97 390.6,694.76 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="312.7,710.1 318.35,771.31 310.55,766.52 304.9,705.31 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="310.68,710.99 316.32,772.21 308.01,767.12 302.36,705.9 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="319.3,716.24 324.95,777.45 318.35,771.31 312.7,710.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="316.25,716.2 321.9,777.41 316.32,772.21 310.68,710.99 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="319.3,716.24 324.95,777.45 317.6,782.05 311.95,720.83 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="316.52,722.43 322.17,783.65 317.6,782.05 311.95,720.83 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="315.22,723.68 320.87,784.9 313.15,782.21 307.5,720.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="396.76,726.49 402.41,787.71 398.82,762.93 393.17,701.72 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="372.32,734.78 377.96,795.99 322.17,783.65 316.52,722.43 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="370.86,735.98 376.51,797.19 320.87,784.9 315.22,723.68 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="388.59,741.21 394.24,802.43 377.96,795.99 372.32,734.78 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="387.21,742.43 392.86,803.65 376.51,797.19 370.86,735.98 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="404.27,744.91 409.92,806.12 402.41,787.71 396.76,726.49 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="403.39,744.89 409.03,806.11 394.24,802.43 388.59,741.21 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="404.27,744.91 409.92,806.12 409.03,806.11 403.39,744.89 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: ordubadColor ? ordubadColor : null }}
                points="382.18,651.74 395.16,657.25 399.23,660.74 400.18,662.34 400.1,663.69 399.07,664.85 
387.31,670.62 382.86,676.04 382.84,681.86 386.19,688.21 390.6,694.76 393.17,701.72 396.76,726.49 404.27,744.91 
403.39,744.89 388.59,741.21 372.32,734.78 316.52,722.43 311.95,720.83 319.3,716.24 312.7,710.1 304.9,705.31 305.17,703.62 
307.22,701.95 310.09,698.22 313.18,695.07 321.03,691.42 328.94,687.64 336.97,681.75 344.87,676.01 349.38,672.29 
353.14,668.37 356.08,664.92 359.27,661.96 366.68,658.98 374.15,655.65 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="402.6,746.27 408.25,807.48 392.86,803.65 387.21,742.43 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M399.17,726.13l8.25,20.17l-4.82-0.04l-15.39-3.83l-16.35-6.46l-55.64-12.29l-7.72-2.69l1.97-0.55
l6.78-4.24l-5.58-5.21l-8.32-5.09l0.43-2.64l2.38-1.93l2.74-3.59l3.63-3.59l8.07-3.75l7.75-3.71l7.73-5.7l7.87-5.7l4.38-3.62
l3.67-3.81l2.97-3.48l3.81-3.4l7.57-3.06l7.39-3.28l9.56-4.67l14.86,6.36l4.37,3.77l1.13,2.02l-0.11,1.78l-1.76,1.84l-2.56,1.23
l-8.96,4.41l-3.94,4.82l-0.02,5.41l3.23,6.04l4.43,6.6l2.64,7.14L399.17,726.13z M388.59,741.21l14.8,3.68l0.88,0.01l-7.51-18.41
l-3.59-24.78l-2.57-6.96l-4.41-6.55l-3.35-6.35l0.02-5.82l4.45-5.43l11.76-5.77l1.04-1.16l0.08-1.35l-0.95-1.6l-4.08-3.49
l-12.98-5.51l-8.02,3.92l-7.48,3.33l-7.4,2.99l-3.2,2.96l-2.94,3.44l-3.76,3.92l-4.51,3.73l-7.9,5.74l-8.02,5.89l-7.91,3.78
l-7.85,3.65l-3.09,3.15l-2.87,3.73l-2.05,1.67l-0.26,1.69l7.79,4.79l6.6,6.14l-7.35,4.6l4.57,1.6l55.79,12.34L388.59,741.21"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="407.43,746.31 413.07,807.52 408.25,807.48 402.6,746.27 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'imisli' ? 'active' : null}`}
            onClick={() => openPopup('imisli')}
          >
            <g>
              <polygon
                className="st32"
                points="992.32,527.17 997.97,588.39 985.73,592.72 980.08,531.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="993.4,530.71 999.05,591.93 997.97,588.39 992.32,527.17 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1000.61,530.16 1006.26,591.37 999.05,591.93 993.4,530.71 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1003.6,529.57 1009.24,590.79 1006.26,591.37 1000.61,530.16 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1005.9,530.06 1011.55,591.27 1009.24,590.79 1003.6,529.57 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1008.06,530.88 1013.71,592.1 1011.55,591.27 1005.9,530.06 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1011.12,531.74 1016.77,592.95 1013.71,592.1 1008.06,530.88 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1048.02,528.81 1053.67,590.02 1051.31,590.5 1045.66,529.29 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1012.68,532.47 1018.33,593.69 1016.77,592.95 1011.12,531.74 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1050.37,529.84 1056.02,591.06 1053.67,590.02 1048.02,528.81 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1045.66,529.29 1051.31,590.5 1049.85,591.87 1044.2,530.65 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1073.15,528.99 1078.8,590.2 1075.87,590.07 1070.22,528.86 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1052.91,531.62 1058.56,592.83 1056.02,591.06 1050.37,529.84 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="986.72,537.91 992.37,599.12 985.73,592.72 980.08,531.51 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1044.2,530.65 1049.85,591.87 1048.61,594.11 1042.96,532.89 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1070.22,528.86 1075.87,590.07 1069.25,592.28 1063.61,531.06 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1025.49,534.69 1031.13,595.91 1029.95,596.16 1024.3,534.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1015.83,535.73 1021.47,596.95 1018.33,593.69 1012.68,532.47 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="984.54,538.67 990.19,599.89 982.37,592.37 976.72,531.15 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="1026.52,534.86 1032.17,596.07 1031.13,595.91 1025.49,534.69 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1063.61,531.06 1069.25,592.28 1061.81,594.04 1056.16,532.82 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1056.16,532.82 1061.81,594.04 1058.56,592.83 1052.91,531.62 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1024.3,534.94 1029.95,596.16 1026.51,597.8 1020.86,536.58 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="1020.86,536.58 1026.51,597.8 1023.48,598.14 1017.84,536.93 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1017.84,536.93 1023.48,598.14 1021.47,596.95 1015.83,535.73 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1028.66,535.95 1034.31,597.17 1032.17,596.07 1026.52,534.86 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1029.89,536.33 1035.53,597.54 1034.31,597.17 1028.66,535.95 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1073.15,528.99 1078.8,590.2 1077.86,595.58 1072.21,534.37 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1075.82,527.72 1081.47,588.93 1080.34,595.45 1074.69,534.24 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1033.93,538.11 1039.58,599.32 1035.53,597.54 1029.89,536.33 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1042.96,532.89 1048.61,594.11 1046.69,598.89 1041.04,537.67 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1038.03,539 1043.68,600.22 1039.58,599.32 1033.93,538.11 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1041.04,537.67 1046.69,598.89 1043.68,600.22 1038.03,539 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1073.3,537.38 1078.94,598.6 1077.86,595.58 1072.21,534.37 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="993.24,545.11 998.89,606.32 992.37,599.12 986.72,537.91 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="990.6,545.38 996.25,606.59 990.19,599.89 984.54,538.67 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1086.25,544.27 1091.9,605.48 1078.94,598.6 1073.3,537.38 			"
              />
            </g>
            <g>
              <polygon
                className="st137"
                points="993.24,545.11 998.89,606.32 994.14,615.05 988.49,553.84 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1088.55,545.12 1094.2,606.33 1091.9,605.48 1086.25,544.27 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1090.63,545.43 1096.27,606.64 1094.2,606.33 1088.55,545.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1091.04,545.91 1096.69,607.13 1096.27,606.64 1090.63,545.43 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="988.49,553.84 994.14,615.05 980.42,618.82 974.77,557.6 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="974.77,557.6 980.42,618.82 974.04,620.16 968.39,558.94 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1093.69,545.67 1099.33,606.89 1098.17,608.79 1092.52,547.57 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1106.13,547.28 1111.78,608.5 1106.68,608.68 1101.03,547.46 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1091.04,545.91 1096.69,607.13 1094.4,610.85 1088.75,549.64 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1101.03,547.46 1106.68,608.68 1094.4,610.85 1088.75,549.64 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="968.39,558.94 974.04,620.16 969.36,622.5 963.71,561.29 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1110.44,548.04 1116.08,609.26 1111.78,608.5 1106.13,547.28 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1111.92,548.76 1117.57,609.97 1116.08,609.26 1110.44,548.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1113.84,550.14 1119.49,611.35 1117.57,609.97 1111.92,548.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="965.17,564.25 970.82,625.46 969.36,622.5 963.71,561.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="962.87,564.9 968.52,626.12 966.58,622.17 960.93,560.96 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1115.09,551.64 1120.73,612.85 1119.49,611.35 1113.84,550.14 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="969.92,567.04 975.57,628.25 970.82,625.46 965.17,564.25 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="966.79,567.31 972.44,628.53 968.52,626.12 962.87,564.9 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1128.42,552.68 1134.07,613.89 1132.21,614.16 1126.56,552.95 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1130.22,552.78 1135.87,614 1134.07,613.89 1128.42,552.68 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1126.56,552.95 1132.21,614.16 1130.71,614.53 1125.06,553.31 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1125.06,553.31 1130.71,614.53 1129.62,614.68 1123.97,553.46 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1117.53,551.3 1123.18,612.52 1123.4,615.39 1117.75,554.18 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1131.63,553.59 1137.28,614.81 1135.87,614 1130.22,552.78 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1123.97,553.46 1129.62,614.68 1125.13,616.57 1119.48,555.35 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1115.09,551.64 1120.73,612.85 1121.06,617.05 1115.41,555.84 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1119.48,555.35 1125.13,616.57 1121.06,617.05 1115.41,555.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1133.38,556.71 1139.03,617.92 1137.28,614.81 1131.63,553.59 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1133.38,556.71 1139.03,617.92 1137.66,619.87 1132.02,558.65 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="969.92,567.04 975.56,628.25 966.66,635.78 961.01,574.56 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1128.55,559.64 1134.2,620.85 1128.55,621.21 1122.9,560 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1132.02,558.65 1137.66,619.87 1134.2,620.85 1128.55,559.64 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1135.98,556.6 1141.63,617.82 1139.55,620.81 1133.9,559.6 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1128.8,560.99 1134.45,622.2 1128.79,622.58 1123.15,561.36 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1133.9,559.6 1139.55,620.81 1134.45,622.2 1128.8,560.99 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1122.9,560 1128.55,621.21 1120.7,623.49 1115.06,562.27 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1123.15,561.36 1128.79,622.58 1121.77,624.65 1116.13,563.44 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1115.06,562.27 1120.7,623.49 1113.65,626.16 1108,564.95 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="961.01,574.56 966.66,635.78 962.52,641.11 956.87,579.9 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1116.13,563.44 1121.77,624.65 1114.99,627.22 1109.34,566.01 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1108,564.95 1113.65,626.16 1107.85,629.45 1102.2,568.24 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1109.34,566.01 1114.99,627.22 1109.61,630.28 1103.96,569.07 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="965.48,583.75 971.13,644.97 962.52,641.11 956.87,579.9 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1102.2,568.24 1107.85,629.45 1102.86,633.15 1097.22,571.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1103.96,569.07 1109.61,630.28 1105.61,633.26 1099.96,572.05 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="963.51,584.69 969.16,645.91 959.54,641.63 953.89,580.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1099.11,575.02 1104.76,636.24 1102.86,633.15 1097.22,571.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1101.35,577.48 1107,638.69 1104.76,636.24 1099.11,575.02 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="972.41,589.4 978.05,650.61 971.13,644.97 965.48,583.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="970.37,590.28 976.02,651.49 969.16,645.91 963.51,584.69 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1101.35,577.48 1107,638.69 1106.05,643.18 1100.41,581.96 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1103.89,577.09 1109.53,638.3 1108.41,643.6 1102.76,582.38 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="980.07,595.02 985.72,656.23 978.05,650.61 972.41,589.4 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="978.1,595.95 983.75,657.17 976.02,651.49 970.37,590.28 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1100.41,581.96 1106.05,643.18 1100.54,647.18 1094.89,585.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1102.76,582.38 1108.41,643.6 1102.39,647.98 1096.74,586.77 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1094.89,585.97 1100.54,647.18 1093.56,651.76 1087.91,590.55 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="988.34,600.36 993.99,661.57 985.72,656.23 980.07,595.02 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="985.48,600.72 991.13,661.93 983.75,657.17 978.1,595.95 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1096.74,586.77 1102.39,647.98 1095.47,652.52 1089.82,591.3 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1087.91,590.55 1093.56,651.76 1088.25,657.42 1082.6,596.21 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1089.82,591.3 1095.47,652.52 1090.72,657.59 1085.07,596.37 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="988.34,600.36 993.99,661.57 988.48,668.48 982.84,607.26 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="982.84,607.26 988.48,668.48 974.15,671.57 968.5,610.36 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1085.07,596.37 1090.72,657.59 1091.1,662.43 1085.45,601.21 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1082.6,596.21 1088.25,657.42 1088.67,662.74 1083.02,601.52 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="968.5,610.36 974.15,671.57 959.79,676.49 954.14,615.27 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="966.72,616.48 972.37,677.69 965.03,678.15 959.38,616.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1085.94,606.42 1091.59,667.64 1088.67,662.74 1083.02,601.52 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="954.14,615.27 959.79,676.49 956.06,680.72 950.42,619.5 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="959.38,616.93 965.03,678.15 957.97,679.98 952.32,618.76 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1000.27,612.6 1005.92,673.82 1004.49,676.04 998.84,614.82 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="966.35,617.86 972,679.07 965.25,679.5 959.6,618.29 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="952.32,618.76 957.97,679.98 956.06,680.72 950.42,619.5 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="959.6,618.29 965.25,679.5 958.99,681.14 953.35,619.92 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1018.38,615.1 1024.03,676.32 1005.92,673.82 1000.27,612.6 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1001.71,614.26 1007.36,675.47 1006.12,677.43 1000.47,616.21 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1000.47,616.21 1006.12,677.43 1005.49,677.3 999.84,616.08 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="998.84,614.82 1004.49,676.04 1000.06,678.47 994.41,617.25 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="987.95,618.58 993.59,679.79 972.37,677.69 966.72,616.48 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="994.41,617.25 1000.06,678.47 996.8,679.62 991.15,618.41 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="991.15,618.41 996.8,679.62 993.59,679.79 987.95,618.58 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1016.88,616.35 1022.53,677.56 1007.36,675.47 1001.71,614.26 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="999.84,616.08 1005.49,677.3 1001.34,679.56 995.69,618.35 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="953.35,619.92 958.99,681.14 949.94,684.7 944.3,623.48 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="987.58,619.96 993.23,681.17 972,679.07 966.35,617.86 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="995.69,618.35 1001.34,679.56 997.39,680.96 991.74,619.75 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="991.74,619.75 997.39,680.96 993.23,681.17 987.58,619.96 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1101.43,611.28 1107.07,672.5 1091.59,667.64 1085.94,606.42 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1034.38,625.41 1040.03,686.62 1024.03,676.32 1018.38,615.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1110.98,618.92 1116.63,680.13 1107.07,672.5 1101.43,611.28 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1032.36,626.3 1038,687.52 1022.53,677.56 1016.88,616.35 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1110.98,618.92 1116.63,680.13 1115.05,682.46 1109.4,621.24 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1113.73,618.6 1119.38,679.81 1117.15,683.02 1111.5,621.81 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1109.4,621.24 1115.05,682.46 1112.47,684.77 1106.82,623.55 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1111.5,621.81 1117.15,683.02 1114.59,685.31 1108.94,624.1 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1106.82,623.55 1112.47,684.77 1109.71,689.62 1104.06,628.4 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1108.94,624.1 1114.59,685.31 1112.06,689.85 1106.42,628.63 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1047.74,637.44 1053.39,698.66 1040.03,686.62 1034.38,625.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1045.67,638.33 1051.32,699.55 1038,687.52 1032.36,626.3 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1104.06,628.4 1109.71,689.62 1107.5,694.48 1101.85,633.26 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1106.42,628.63 1112.06,689.85 1110.03,694.35 1104.38,633.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1103.92,638.71 1109.57,699.92 1107.5,694.48 1101.85,633.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1107.97,643.21 1113.61,704.42 1109.57,699.92 1103.92,638.71 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: imisliColor ? imisliColor : null }}
                points="1073.15,528.99 1072.21,534.37 1073.3,537.38 1086.25,544.27 1088.55,545.12 1090.63,545.43 
1091.04,545.91 1088.75,549.64 1101.03,547.46 1106.13,547.28 1110.44,548.04 1111.92,548.76 1113.84,550.14 1115.09,551.64 
1115.41,555.84 1119.48,555.35 1123.97,553.46 1125.06,553.31 1126.56,552.95 1128.42,552.68 1130.22,552.78 1131.63,553.59 
1133.38,556.71 1132.02,558.65 1128.55,559.64 1122.9,560 1115.06,562.27 1108,564.95 1102.2,568.24 1097.22,571.94 
1099.11,575.02 1101.35,577.48 1100.41,581.96 1094.89,585.97 1087.91,590.55 1082.6,596.21 1083.02,601.52 1085.94,606.42 
1101.43,611.28 1110.98,618.92 1109.4,621.24 1106.82,623.55 1104.06,628.4 1101.85,633.26 1103.92,638.71 1107.97,643.21 
1074.59,651.55 1047.74,637.44 1034.38,625.41 1018.38,615.1 1000.27,612.6 998.84,614.82 994.41,617.25 991.15,618.41 
987.95,618.58 966.72,616.48 959.38,616.93 952.32,618.76 950.42,619.5 954.14,615.27 968.5,610.36 982.84,607.26 988.34,600.36 
980.07,595.02 972.41,589.4 965.48,583.75 956.87,579.9 961.01,574.56 969.92,567.04 965.17,564.25 963.71,561.29 968.39,558.94 
974.77,557.6 988.49,553.84 993.24,545.11 986.72,537.91 980.08,531.51 992.32,527.17 993.4,530.71 1000.61,530.16 
1003.6,529.57 1005.9,530.06 1008.06,530.88 1011.12,531.74 1012.68,532.47 1015.83,535.73 1017.84,536.93 1020.86,536.58 
1024.3,534.94 1025.49,534.69 1026.52,534.86 1028.66,535.95 1029.89,536.33 1033.93,538.11 1038.03,539 1041.04,537.67 
1042.96,532.89 1044.2,530.65 1045.66,529.29 1048.02,528.81 1050.37,529.84 1052.91,531.62 1056.16,532.82 1063.61,531.06 
1070.22,528.86 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1107.97,643.21 1113.61,704.42 1080.24,712.77 1074.59,651.55 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1074.59,651.55 1080.24,712.77 1053.39,698.66 1047.74,637.44 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1111.37,643.8 1117.02,705.02 1079.54,714.39 1073.89,653.17 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1106.29,638.2l5.08,5.6l-37.48,9.37l-28.22-14.84l-13.32-12.03l-15.48-9.95l-15.17-2.09l-1.24,1.96
l-0.63-0.13l-4.16,2.26l-3.95,1.4l-4.16,0.21l-21.23-2.1l-6.75,0.43l-6.25,1.63l-9.05,3.56l8.04-9.12l15.3-5.23l13.45-2.91
l4.4-5.51l-7.38-4.77l-7.73-5.68l-6.86-5.59l-9.61-4.27l5-6.41l7.89-6.69l-3.92-2.41l-1.93-3.94l6.6-3.25l6.48-1.36l12.5-3.43
l4.09-7.54l-6.07-6.7l-7.81-7.52l17.46-6.19l1.29,4.22l4.93-0.38l3.45-0.68l3.47,0.74l2.67,1.02l2.71,0.71l2.09,1.02l3.15,3.3
l0.91,0.53l1.1-0.12l3.49-1.61l2.22-0.47l2.45,0.46l2.23,1.15l1.03,0.31l4.07,1.77l2.4,0.5l1.01-0.45l1.74-4.3l1.46-2.56
l2.09-1.93l4.57-0.93l3.57,1.6l2.5,1.75l1.86,0.67l6.07-1.43l7.17-2.4l5.93,0.26l-1.13,6.52l0.87,2.35l12.32,6.58l2.01,0.72
l2.53,0.34l1.27,1.45l-1.16,1.9l8.47-1.48l5.71-0.2l5.3,0.99l1.78,0.88l2.25,1.63l1.49,1.92l0.22,2.88l0.92-0.11l4.71-1.9
l1.16-0.15l1.46-0.37l2.74-0.36l2.78,0.18l2.39,1.43l2.07,3.7l-2.08,2.99l-5.1,1.39l-5.66,0.37l-7.02,2.08l-6.79,2.57l-5.38,3.06
l-4,2.98l1.41,2.33l2.52,2.71l-1.13,5.3l-6.02,4.38l-6.91,4.54l-4.75,5.07l0.38,4.84l2.56,4.2l15.32,4.87l10.39,8.31l-2.23,3.21
l-2.57,2.29l-2.52,4.54l-2.04,4.5L1106.29,638.2z M1074.59,651.55l33.38-8.34l-4.05-4.5l-2.07-5.45l2.21-4.86l2.76-4.85
l2.58-2.31l1.59-2.32l-9.56-7.63l-15.48-4.86l-2.92-4.9l-0.42-5.32l5.31-5.66l6.98-4.58l5.51-4.01l0.94-4.48l-2.24-2.45
l-1.89-3.09l4.99-3.7l5.8-3.29l7.06-2.67l7.84-2.27l5.65-0.36l3.46-0.99l1.36-1.95l-1.75-3.11l-1.41-0.81l-1.79-0.11l-1.86,0.27
l-1.5,0.37l-1.09,0.15l-4.49,1.89l-4.07,0.49l-0.32-4.2l-1.25-1.5l-1.92-1.38l-1.48-0.72l-4.3-0.76l-5.1,0.18l-12.28,2.18
l2.29-3.73l-0.42-0.48l-2.07-0.31l-2.31-0.85l-12.95-6.89l-1.08-3.02l0.94-5.38l-2.94-0.13l-6.61,2.2l-7.45,1.76l-3.25-1.2
l-2.53-1.78l-2.35-1.03l-2.36,0.48l-1.47,1.36l-1.24,2.24l-1.93,4.78l-3,1.33l-4.11-0.89l-4.04-1.78l-1.22-0.37l-2.15-1.09
l-1.03-0.17l-1.19,0.25l-3.44,1.64l-3.03,0.35l-2.01-1.2l-3.14-3.26l-1.56-0.74l-3.06-0.85l-2.16-0.83l-2.3-0.48l-2.98,0.58
l-7.21,0.56l-1.08-3.54l-12.24,4.34l6.63,6.4l6.52,7.2l-4.75,8.73l-13.72,3.76l-6.38,1.34l-4.68,2.35l1.47,2.96l4.74,2.79
l-8.9,7.52l-4.14,5.34l8.61,3.85l6.92,5.64l7.67,5.62l8.27,5.34l-5.5,6.9l-14.34,3.1l-14.35,4.92l-3.73,4.23l1.9-0.74l7.06-1.83
l7.34-0.45l21.23,2.1l3.21-0.17l3.25-1.16l4.43-2.43l1.43-2.22l18.11,2.5l16,10.3l13.36,12.04L1074.59,651.55"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1073.89,653.17 1079.54,714.39 1051.32,699.55 1045.67,638.33 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'saatli' ? 'active' : null}`}
            onClick={() => openPopup('saatli')}
          >
            <g>
              <polygon
                className="st37"
                points="1108,566.77 1113.65,627.99 1109.61,630.28 1103.96,569.07 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1145.19,565.9 1150.84,627.12 1140.26,628.85 1134.61,567.63 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1154.89,565.87 1160.54,627.09 1150.84,627.12 1145.19,565.9 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1134.61,567.63 1140.26,628.85 1131.84,630.61 1126.19,569.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1110.36,570.9 1116.01,632.12 1113.65,627.99 1108,566.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1103.96,569.07 1109.61,630.28 1105.61,633.26 1099.96,572.05 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1126.19,569.39 1131.84,630.61 1122.9,632.23 1117.25,571.01 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1117.25,571.01 1122.9,632.23 1116.01,632.12 1110.36,570.9 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="1160.34,568.1 1165.99,629.31 1160.54,627.09 1154.89,565.87 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1101.37,574.38 1107.02,635.59 1105.61,633.26 1099.96,572.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1099.11,575.02 1104.76,636.24 1102.86,633.15 1097.22,571.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1164.26,571.08 1169.91,632.29 1165.99,629.31 1160.34,568.1 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1103.89,577.09 1109.53,638.3 1107.02,635.59 1101.37,574.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1101.35,577.48 1107,638.69 1104.76,636.24 1099.11,575.02 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1164.26,571.08 1169.91,632.29 1167.12,634.59 1161.47,573.37 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1167.24,570.92 1172.89,632.13 1169.13,635.26 1163.48,574.05 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1161.47,573.37 1167.12,634.59 1163.3,637.92 1157.65,576.7 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1163.48,574.05 1169.13,635.26 1165.91,638.04 1160.26,576.83 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1103.89,577.09 1109.53,638.3 1108.41,643.6 1102.76,582.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1159.06,580.29 1164.71,641.51 1163.3,637.92 1157.65,576.7 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1102.76,582.38 1108.41,643.6 1102.39,647.98 1096.74,586.77 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1177.88,580.45 1183.53,641.66 1177.02,643.12 1171.38,581.91 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1162.91,582.95 1168.56,644.17 1164.71,641.51 1159.06,580.29 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1184.14,581.25 1189.79,642.47 1183.53,641.66 1177.88,580.45 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1167.39,583.59 1173.04,644.8 1168.56,644.17 1162.91,582.95 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1171.38,581.91 1177.02,643.12 1173.04,644.8 1167.39,583.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1096.74,586.77 1102.39,647.98 1095.47,652.52 1089.82,591.3 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1194.16,585.97 1199.81,647.18 1189.79,642.47 1184.14,581.25 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1089.82,591.3 1095.47,652.52 1090.72,657.59 1085.07,596.37 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1085.07,596.37 1090.72,657.59 1091.1,662.43 1085.45,601.21 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1203.79,591.53 1209.44,652.74 1199.81,647.18 1194.16,585.97 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1088.02,605.42 1093.66,666.63 1091.1,662.43 1085.45,601.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1085.94,606.42 1091.59,667.64 1088.67,662.74 1083.02,601.52 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1213.95,595.53 1219.6,656.74 1209.44,652.74 1203.79,591.53 			"
              />
            </g>
            <g>
              <polygon
                className="st23"
                points="1103.34,610.29 1108.99,671.51 1093.66,666.63 1088.02,605.42 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1101.43,611.28 1107.07,672.5 1091.59,667.64 1085.94,606.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1219.49,602.65 1225.14,663.86 1219.6,656.74 1213.95,595.53 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1219.49,602.65 1225.14,663.86 1223.25,667.63 1217.6,606.41 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1222.1,602.39 1227.75,663.61 1225.47,668.1 1219.82,606.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1113.73,618.6 1119.38,679.81 1108.99,671.51 1103.34,610.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1110.98,618.92 1116.63,680.13 1107.07,672.5 1101.43,611.28 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1217.6,606.41 1223.25,667.63 1219.64,671.29 1213.99,610.07 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1219.82,606.88 1225.47,668.1 1222,671.6 1216.35,610.38 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1113.73,618.6 1119.38,679.81 1117.15,683.02 1111.5,621.81 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1111.5,621.81 1117.15,683.02 1114.59,685.31 1108.94,624.1 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1213.99,610.07 1219.64,671.29 1218.15,676.87 1212.5,615.66 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1216.35,610.38 1222,671.6 1220.62,676.81 1214.98,615.59 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1108.94,624.1 1114.59,685.31 1112.06,689.85 1106.42,628.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1213.92,620.7 1219.56,681.92 1218.15,676.87 1212.5,615.66 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1106.42,628.63 1112.06,689.85 1110.03,694.35 1104.38,633.14 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1213.92,620.7 1219.56,681.92 1216.32,688.79 1210.67,627.58 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1216.35,620.76 1222,681.98 1218.49,689.4 1212.84,628.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1106.29,638.2 1111.94,699.42 1110.03,694.35 1104.38,633.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1103.92,638.71 1109.57,699.92 1107.5,694.48 1101.85,633.26 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1210.67,627.58 1216.32,688.79 1207.03,694.03 1201.38,632.81 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1187.28,635.22 1192.93,696.44 1178.35,696.85 1172.7,635.63 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1201.38,632.81 1207.03,694.03 1192.93,696.44 1187.28,635.22 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1212.84,628.19 1218.49,689.4 1208.17,695.22 1202.53,634.01 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1110.22,642.53 1115.87,703.74 1111.94,699.42 1106.29,638.2 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1129.27,635.57 1134.92,696.78 1115.87,703.74 1110.22,642.53 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1187.28,636.59 1192.93,697.8 1178.82,698.2 1173.17,636.99 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1202.53,634.01 1208.17,695.22 1192.93,697.8 1187.28,636.59 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1172.7,635.63 1178.35,696.85 1166.25,700.75 1160.6,639.54 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1128.78,637.3 1134.43,698.52 1114.77,705.7 1109.12,644.48 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1109.12,644.48 1114.77,705.7 1109.57,699.92 1103.92,638.71 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1173.17,636.99 1178.82,698.2 1170.8,700.79 1165.15,639.58 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1143.15,642.88 1148.8,704.1 1134.92,696.78 1129.27,635.57 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1141.42,643.95 1147.07,705.17 1134.43,698.52 1128.78,637.3 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1167.45,642.28 1173.1,703.49 1166.25,700.75 1160.6,639.54 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1167.45,642.28 1173.1,703.49 1160.02,709.24 1154.37,648.02 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1154.37,648.02 1160.02,709.24 1148.8,704.1 1143.15,642.88 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: saatliColor ? saatliColor : null }}
                points="1154.89,565.87 1160.34,568.1 1164.26,571.08 1161.47,573.37 1157.65,576.7 1159.06,580.29 
1162.91,582.95 1167.39,583.59 1171.38,581.91 1177.88,580.45 1184.14,581.25 1194.16,585.97 1203.79,591.53 1213.95,595.53 
1219.49,602.65 1217.6,606.41 1213.99,610.07 1212.5,615.66 1213.92,620.7 1210.67,627.58 1201.38,632.81 1187.28,635.22 
1172.7,635.63 1160.6,639.54 1167.45,642.28 1154.37,648.02 1143.15,642.88 1129.27,635.57 1110.22,642.53 1106.29,638.2 
1104.38,633.14 1106.42,628.63 1108.94,624.1 1111.5,621.81 1113.73,618.6 1103.34,610.29 1088.02,605.42 1085.45,601.21 
1085.07,596.37 1089.82,591.3 1096.74,586.77 1102.76,582.38 1103.89,577.09 1101.37,574.38 1099.96,572.05 1103.96,569.07 
1108,566.77 1110.36,570.9 1117.25,571.01 1126.19,569.39 1134.61,567.63 1145.19,565.9 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1214.98,615.59l1.38,5.17l-3.51,7.42l-10.32,5.82l-15.25,2.58l-14.11,0.4l-8.02,2.59l6.36,2.56
l-17.38,7.65l-12.72-5.83l-12.64-6.65l-19.66,7.18l-5.2-5.77l-2.07-5.45l2.21-4.86l2.76-4.85l2.58-2.31l1.59-2.32l-9.56-7.63
l-15.48-4.86l-2.92-4.9l-0.42-5.32l5.31-5.66l6.98-4.58l5.51-4.01l0.94-4.48l-2.24-2.45l-1.89-3.09l4.99-3.7l7-4.02l3.05,5.33
l5.11,0.08l8.25-1.54l8.47-1.77l11.19-1.79l10.61-0.04l6.39,2.64l4.96,3.79l-3.76,3.13l-3.22,2.78l1.15,2.88l3.03,2l2.67,0.37
l3.42-1.41l7.42-1.66l7.72,1.05l10.29,4.85l9.53,5.53l10.51,4.12l6.11,7.83l-2.28,4.49l-3.47,3.5L1214.98,615.59z
 M1210.67,627.58l3.25-6.87l-1.41-5.05l1.48-5.58l3.62-3.66l1.89-3.76l-5.54-7.12l-10.16-4l-9.63-5.56l-10.03-4.72l-6.26-0.8
l-6.5,1.46l-3.99,1.68l-4.47-0.63l-3.85-2.66l-1.41-3.59l3.81-3.33l2.79-2.29l-3.92-2.98l-5.45-2.22l-9.69,0.03l-10.58,1.73
l-8.42,1.76l-8.93,1.62l-6.9-0.11l-2.35-4.13l-4.04,2.3l-4,2.98l1.41,2.33l2.52,2.71l-1.13,5.3l-6.02,4.38l-6.91,4.54l-4.75,5.07
l0.38,4.84l2.56,4.2l15.32,4.87l10.39,8.31l-2.23,3.21l-2.57,2.29l-2.52,4.54l-2.04,4.5l1.91,5.07l3.93,4.32l19.05-6.96
l13.88,7.31l11.22,5.14l13.08-5.74l-6.85-2.74l12.1-3.9l14.58-0.41l14.1-2.41L1210.67,627.58"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1171.52,642.14 1177.16,703.35 1159.79,711 1154.14,649.79 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1154.14,649.79 1159.79,711 1147.07,705.17 1141.42,643.95 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'bilesuvar' ? 'active' : null}`}
            onClick={() => openPopup('bilesuvar')}
          >
            <g>
              <polygon
                className="st101"
                points="1001.71,614.26 1007.36,675.47 1006.96,676.11 1001.31,614.9 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1016.88,616.35 1022.53,677.56 1007.36,675.47 1001.71,614.26 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1004.31,615.7 1009.96,676.91 1006.96,676.11 1001.31,614.9 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1002.58,616.78 1008.23,678 1003.94,676.92 998.29,615.71 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1008.71,618.31 1014.36,679.53 1009.96,676.91 1004.31,615.7 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1006.74,619.25 1012.39,680.47 1008.23,678 1002.58,616.78 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1032.36,626.3 1038,687.52 1022.53,677.56 1016.88,616.35 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1021.66,629.11 1027.31,690.33 1014.36,679.53 1008.71,618.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1019.51,629.9 1025.16,691.11 1012.39,680.47 1006.74,619.25 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1045.67,638.33 1051.32,699.55 1038,687.52 1032.36,626.3 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1128.78,637.3 1134.43,698.52 1115.89,705.29 1110.24,644.07 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1141.42,643.95 1147.07,705.17 1134.43,698.52 1128.78,637.3 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1073.89,653.17 1079.54,714.39 1051.32,699.55 1045.67,638.33 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1110.24,644.07 1115.89,705.29 1079.54,714.39 1073.89,653.17 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1180.47,645.09 1186.11,706.3 1175.24,704.19 1169.59,642.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1169.59,642.97 1175.24,704.19 1159.79,711 1154.14,649.79 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1154.14,649.79 1159.79,711 1147.07,705.17 1141.42,643.95 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1191.13,649.16 1196.78,710.37 1186.11,706.3 1180.47,645.09 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1053.53,665.82 1059.18,727.03 1027.31,690.33 1021.66,629.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1051.37,666.6 1057.01,727.81 1025.16,691.11 1019.51,629.9 			"
              />
            </g>
            <g>
              <polygon
                className="st110"
                points="1203.54,653.14 1209.19,714.35 1196.78,710.37 1191.13,649.16 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1215.11,656.65 1220.76,717.87 1209.19,714.35 1203.54,653.14 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1067.85,677.61 1073.5,738.82 1059.18,727.03 1053.53,665.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1065.68,678.36 1071.33,739.58 1057.01,727.81 1051.37,666.6 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1215.11,656.65 1220.76,717.87 1210.35,727.47 1204.7,666.25 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1218.52,656.03 1224.16,717.25 1212.49,728.01 1206.84,666.79 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1072.93,684.62 1078.58,745.84 1073.5,738.82 1067.85,677.61 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1070.58,685.14 1076.22,746.36 1071.33,739.58 1065.68,678.36 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1204.7,666.25 1210.35,727.47 1203.5,738.53 1197.85,677.31 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1206.84,666.79 1212.49,728.01 1206.14,738.3 1200.49,677.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1073,692.46 1078.65,753.67 1076.22,746.36 1070.58,685.14 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1075.92,693.57 1081.57,754.78 1078.58,745.84 1072.93,684.62 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1075.92,693.57 1081.57,754.78 1078.53,755.05 1072.88,693.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1201.71,681.97 1207.36,743.19 1203.5,738.53 1197.85,677.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1071.61,696.24 1077.26,757.46 1075.25,753.98 1069.6,692.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1074.16,696.02 1079.81,757.23 1078.53,755.05 1072.88,693.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1204.62,686.58 1210.27,747.79 1207.36,743.19 1201.71,681.97 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1133.18,696.73 1138.83,757.94 1127.74,756.99 1122.09,695.77 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="1179.85,692.69 1185.49,753.9 1179.95,753.47 1174.3,692.25 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1131.24,697.97 1136.89,759.19 1128.21,758.44 1122.56,697.23 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1204.62,686.58 1210.27,747.79 1206.49,752.82 1200.85,691.6 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1174.3,692.25 1179.95,753.47 1175.1,755.85 1169.45,694.63 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1178.62,694.01 1184.27,755.22 1180.48,754.93 1174.83,693.71 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1207.26,686.48 1212.91,747.69 1208.66,753.3 1203.01,692.09 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1122.09,695.77 1127.74,756.99 1116.24,762.22 1110.59,701 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1174.83,693.71 1180.48,754.93 1176.7,756.78 1171.05,695.56 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1183.8,694.51 1189.45,755.73 1185.49,753.9 1179.85,692.69 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1074.16,696.02 1079.81,757.23 1076.09,766.98 1070.44,705.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1122.56,697.23 1128.21,758.44 1117.45,763.32 1111.8,702.1 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1182.23,695.68 1187.88,756.89 1184.27,755.22 1178.62,694.01 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1169.45,694.63 1175.1,755.85 1171.3,758.58 1165.65,697.36 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1189.92,696.03 1195.57,757.24 1189.45,755.73 1183.8,694.51 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1093.28,704.65 1098.93,765.87 1092.5,767.03 1086.85,705.81 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1171.05,695.56 1176.7,756.78 1172.63,759.65 1166.98,698.44 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1098.94,704.74 1104.59,765.96 1098.93,765.87 1093.28,704.65 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1110.59,701 1116.24,762.22 1104.59,765.96 1098.94,704.74 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1165.65,697.36 1171.3,758.58 1165.72,760.51 1160.07,699.3 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="1195.97,696.48 1201.61,757.7 1195.57,757.24 1189.92,696.03 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1200.85,691.6 1206.49,752.82 1201.61,757.7 1195.97,696.48 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1188.99,697.38 1194.64,758.59 1187.88,756.89 1182.23,695.68 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1166.98,698.44 1172.63,759.65 1167.61,761.39 1161.97,700.18 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1093.16,706.03 1098.81,767.25 1093.28,768.28 1087.63,707.07 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1099.3,706.14 1104.95,767.35 1098.81,767.25 1093.16,706.03 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1111.8,702.1 1117.45,763.32 1104.95,767.35 1099.3,706.14 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1086.85,705.81 1092.5,767.03 1087.72,769.25 1082.07,708.04 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1203.01,692.09 1208.66,753.3 1202.8,759.19 1197.15,697.98 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1197.15,697.98 1202.8,759.19 1194.64,758.59 1188.99,697.38 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1137.03,703.76 1142.68,764.98 1138.83,757.94 1133.18,696.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1134.57,703.98 1140.22,765.2 1136.89,759.19 1131.24,697.97 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1082.07,708.04 1087.72,769.25 1083.62,770.59 1077.97,709.37 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1077.97,709.37 1083.62,770.59 1076.09,766.98 1070.44,705.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1087.63,707.07 1093.28,768.28 1088.87,770.38 1083.22,709.17 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1160.07,699.3 1165.72,760.51 1163.26,763.7 1157.61,702.49 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1161.97,700.18 1167.61,761.39 1165.54,764.04 1159.89,702.83 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1083.22,709.17 1088.87,770.38 1083.12,772.27 1077.47,711.05 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1077.47,711.05 1083.12,772.27 1073.4,767.61 1067.75,706.4 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1137.03,703.76 1142.68,764.98 1142.27,772.38 1136.62,711.16 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1157.61,702.49 1163.26,763.7 1158.51,772.45 1152.86,711.23 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1159.89,702.83 1165.54,764.04 1160.56,773.26 1154.92,712.05 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1152.86,711.23 1158.51,772.45 1148.97,775.91 1143.32,714.7 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1143.32,714.7 1148.97,775.91 1142.27,772.38 1136.62,711.16 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1204.01,681.33l3.25,5.14l-4.25,5.61l-5.87,5.89l-8.15-0.6l-6.77-1.7l-3.61-1.67l-3.79-0.29l-3.78,1.85
l-4.06,2.87l-5.02,1.74l-2.07,2.65l-4.98,9.22l-12.1,4.38l-8.69-4.59l0.45-7.86l-3.33-6.01l-8.68-0.75l-10.76,4.87l-12.49,4.03
l-6.14-0.1l-5.53,1.03l-4.41,2.1l-5.75,1.88l-9.72-4.65l3.87-10.16l-2.02-3.48l3.41-0.3l-2.43-7.32l-4.9-6.78l-14.31-11.76
l-31.85-36.7l-12.77-10.65l-4.16-2.47l-4.29-1.08l1.98-3.11l18.11,2.5l16,10.3l13.36,12.04l26.85,14.11l34.69-8.67l19.99-7.31
l13.88,7.31l11.22,5.14l14.99-6.6l12.53,2.46l10.71,4.07l12.31,3.96l13.6,4.11l-11.68,10.76l-6.35,10.29L1204.01,681.33z
 M1200.85,691.6l3.77-5.03l-2.91-4.6l-3.86-4.66l6.84-11.06l10.42-9.6l-11.57-3.51l-12.42-3.98l-10.66-4.07l-10.88-2.11
l-15.45,6.81l-12.72-5.83l-12.64-6.65l-18.54,6.77l-36.35,9.1l-28.22-14.84l-13.32-12.03l-15.48-9.95l-15.17-2.09l-0.39,0.64
l3,0.8l4.4,2.62l12.95,10.8l31.87,36.7l14.32,11.79l5.08,7.01l2.99,8.95l-3.04,0.27l1.28,2.18l-3.72,9.75l7.53,3.61l4.1-1.34
l4.78-2.22l6.43-1.16l5.66,0.09l11.64-3.74l11.51-5.23l11.09,0.96l3.85,7.03l-0.41,7.4l6.7,3.53l9.54-3.46l4.75-8.74l2.46-3.19
l5.58-1.94l3.8-2.73l4.85-2.38l5.54,0.43l3.95,1.83l6.12,1.52l6.05,0.45L1200.85,691.6"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: bilesuvarColor ? bilesuvarColor : null }}
                points="1001.71,614.26 1016.88,616.35 1032.36,626.3 1045.67,638.33 1073.89,653.17 1110.24,644.07 
1128.78,637.3 1141.42,643.95 1154.14,649.79 1169.59,642.97 1180.47,645.09 1191.13,649.16 1203.54,653.14 1215.11,656.65 
1204.7,666.25 1197.85,677.31 1201.71,681.97 1204.62,686.58 1200.85,691.6 1195.97,696.48 1189.92,696.03 1183.8,694.51 
1179.85,692.69 1174.3,692.25 1169.45,694.63 1165.65,697.36 1160.07,699.3 1157.61,702.49 1152.86,711.23 1143.32,714.7 
1136.62,711.16 1137.03,703.76 1133.18,696.73 1122.09,695.77 1110.59,701 1098.94,704.74 1093.28,704.65 1086.85,705.81 
1082.07,708.04 1077.97,709.37 1070.44,705.77 1074.16,696.02 1072.88,693.84 1075.92,693.57 1072.93,684.62 1067.85,677.61 
1053.53,665.82 1021.66,629.11 1008.71,618.31 1004.31,615.7 1001.31,614.9 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1154.92,712.05 1160.56,773.26 1148.46,777.64 1142.82,716.42 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1142.82,716.42 1148.46,777.64 1139.78,773.05 1134.13,711.84 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'celilabad' ? 'active' : null}`}
            onClick={() => openPopup('celilabad')}
          >
            <g>
              <polygon
                className="st8"
                points="1070.38,694.07 1076.03,755.28 1069.22,755.87 1063.57,694.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1071.64,696.24 1077.29,757.46 1076.03,755.28 1070.38,694.07 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1027.45,699.6 1033.1,760.81 1022.67,762.73 1017.02,701.52 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1045.82,699.11 1051.47,760.33 1033.1,760.81 1027.45,699.6 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1063.57,694.66 1069.22,755.87 1051.47,760.33 1045.82,699.11 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1017.02,701.52 1022.67,762.73 1012.8,766.34 1007.15,705.13 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1131.26,697.98 1136.91,759.19 1128.23,758.45 1122.58,697.23 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1007.15,705.13 1012.8,766.34 1005.16,771.57 999.51,710.35 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1178.64,694.01 1184.29,755.22 1180.5,754.93 1174.85,693.72 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1174.85,693.72 1180.5,754.93 1176.72,756.78 1171.07,695.57 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1071.64,696.24 1077.29,757.46 1073.42,767.62 1067.77,706.4 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1074.18,696.02 1079.83,757.23 1076.11,766.99 1070.47,705.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1122.58,697.23 1128.23,758.45 1117.47,763.32 1111.82,702.1 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1182.25,695.68 1187.9,756.89 1184.29,755.22 1178.64,694.01 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1171.07,695.57 1176.72,756.78 1172.65,759.65 1167.01,698.44 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1189.02,697.38 1194.66,758.59 1187.9,756.89 1182.25,695.68 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1167.01,698.44 1172.65,759.65 1167.64,761.4 1161.99,700.18 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1093.18,706.04 1098.83,767.25 1093.3,768.28 1087.65,707.07 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1099.33,706.14 1104.97,767.35 1098.83,767.25 1093.18,706.04 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1111.82,702.1 1117.47,763.32 1104.97,767.35 1099.33,706.14 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1193.78,697.73 1199.43,758.95 1194.66,758.59 1189.02,697.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1134.6,703.98 1140.25,765.2 1136.91,759.19 1131.26,697.98 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="999.51,710.35 1005.16,771.57 1001.44,778.36 995.79,717.14 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1087.65,707.07 1093.3,768.28 1088.89,770.39 1083.24,709.17 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1161.99,700.18 1167.64,761.4 1165.56,764.05 1159.91,702.83 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1077.49,711.05 1083.14,772.27 1073.42,767.62 1067.77,706.4 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1083.24,709.17 1088.89,770.39 1083.14,772.27 1077.49,711.05 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1193.78,697.73 1199.43,758.95 1191.69,762.68 1186.04,701.47 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1199.38,696.73 1205.03,757.94 1193.26,763.64 1187.61,702.42 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="996.89,722.8 1002.54,784.02 1001.44,778.36 995.79,717.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="994.47,723.19 1000.11,784.4 998.94,778.38 993.29,717.17 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1186.04,701.47 1191.69,762.68 1182.64,767.99 1176.99,706.77 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1137.05,703.76 1142.7,764.98 1142.29,772.38 1136.64,711.16 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1187.61,702.42 1193.26,763.64 1184.5,768.78 1178.86,707.56 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="1134.6,703.98 1140.25,765.2 1139.8,773.05 1134.15,711.84 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1159.91,702.83 1165.56,764.05 1160.59,773.27 1154.94,712.05 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1001.05,728.89 1006.7,790.11 1002.54,784.02 996.89,722.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="998.84,729.62 1004.49,790.83 1000.11,784.4 994.47,723.19 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1142.84,716.43 1148.49,777.64 1139.8,773.05 1134.15,711.84 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1154.94,712.05 1160.59,773.27 1148.49,777.64 1142.84,716.43 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1176.99,706.77 1182.64,767.99 1176.36,776.06 1170.71,714.85 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1178.86,707.56 1184.5,768.78 1178.77,776.27 1173.12,715.06 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1170.71,714.85 1176.36,776.06 1174.75,784.3 1169.1,723.09 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1173.12,715.06 1178.77,776.27 1177.19,784.21 1171.54,722.99 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1012.6,738.97 1018.25,800.19 1006.7,790.11 1001.05,728.89 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1010.61,739.91 1016.26,801.12 1004.49,790.83 998.84,729.62 			"
              />
            </g>
            <g>
              <polygon
                className="st61"
                points="1026.96,744.3 1032.61,805.51 1018.25,800.19 1012.6,738.97 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1024.96,745.23 1030.61,806.45 1016.26,801.12 1010.61,739.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1030.2,747.26 1035.85,808.48 1032.61,805.51 1026.96,744.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1027.83,747.77 1033.48,808.98 1030.61,806.45 1024.96,745.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1172.93,737.07 1178.58,798.28 1174.75,784.3 1169.1,723.09 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1033.75,758.4 1039.4,819.62 1035.85,808.48 1030.2,747.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1031.32,758.71 1036.97,819.93 1033.48,808.98 1027.83,747.77 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1160.03,748.35 1165.68,809.57 1158.39,809.07 1152.74,747.85 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1031.56,761.33 1037.21,822.55 1036.97,819.93 1031.32,758.71 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1143.75,751.05 1149.4,812.26 1142.45,812.65 1136.8,751.43 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1158.91,749.68 1164.56,810.9 1160,810.59 1154.35,749.37 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1034.04,761.35 1039.69,822.56 1039.4,819.62 1033.75,758.4 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="1163.92,749.84 1169.57,811.05 1165.68,809.57 1160.03,748.35 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1172.93,737.07 1178.58,798.28 1169.57,811.05 1163.92,749.84 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1152.74,747.85 1158.39,809.07 1156.22,812.72 1150.57,751.5 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1143.02,752.45 1148.67,813.66 1144.27,813.92 1138.62,752.7 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="1148.3,752.36 1153.95,813.58 1149.4,812.26 1143.75,751.05 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1150.57,751.5 1156.22,812.72 1153.95,813.58 1148.3,752.36 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1154.35,749.37 1160,810.59 1158.24,813.52 1152.59,752.3 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1164.95,752 1170.6,813.21 1164.56,810.9 1158.91,749.68 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1175.46,737.07 1181.11,798.29 1170.6,813.21 1164.95,752 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1034.04,761.35 1039.69,822.56 1038.01,825.69 1032.37,764.48 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1152.59,752.3 1158.24,813.52 1153.88,815.18 1148.23,753.96 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1148.23,753.96 1153.88,815.18 1148.67,813.66 1143.02,752.45 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1032.37,764.48 1038.01,825.69 1036.22,827.51 1030.57,766.3 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1136.8,751.43 1142.45,812.65 1139.59,818.13 1133.95,756.91 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1138.62,752.7 1144.27,813.92 1142.14,818 1136.49,756.79 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1047.73,767.38 1053.38,828.59 1036.22,827.51 1030.57,766.3 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1054.75,766.72 1060.4,827.93 1053.38,828.59 1047.73,767.38 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1062.42,766.5 1068.07,827.71 1060.4,827.93 1054.75,766.72 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1047.35,768.76 1052.99,829.98 1032.41,828.68 1026.76,767.47 			"
              />
            </g>
            <g>
              <rect
                x="1050.16"
                y="768.29"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -68.9604 100.2142)"
                className="st8"
                width="7.41"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1061.27,767.9 1066.92,829.11 1060.38,829.29 1054.73,768.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1136.02,761.58 1141.67,822.8 1139.59,818.13 1133.95,756.91 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1136.02,761.58 1141.67,822.8 1134.98,824.65 1129.33,763.43 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1129.33,763.43 1134.98,824.65 1126.37,825.63 1120.72,764.41 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1138.91,762.24 1144.56,823.46 1135.35,825.98 1129.7,764.77 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1129.7,764.77 1135.35,825.98 1126.74,826.96 1121.09,765.75 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1120.72,764.41 1126.37,825.63 1116.72,828.11 1111.07,766.9 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1070.9,771.29 1076.55,832.51 1068.07,827.71 1062.42,766.5 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1121.09,765.75 1126.74,826.96 1117.71,829.3 1112.06,768.08 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1068.73,772.09 1074.38,833.3 1066.92,829.11 1061.27,767.9 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1111.07,766.9 1116.72,828.11 1108.01,832.26 1102.36,771.05 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1112.06,768.08 1117.71,829.3 1109.38,833.3 1103.73,772.09 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1075.76,777.13 1081.41,838.34 1076.55,832.51 1070.9,771.29 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1089.61,776.28 1095.26,837.5 1081.41,838.34 1075.76,777.13 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1102.36,771.05 1108.01,832.26 1095.26,837.5 1089.61,776.28 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1173.12,715.06l-1.57,7.94l3.92,14.08L1164.95,752l-6.03-2.31l-4.56-0.31l-1.76,2.93l-4.36,1.66
l-5.21-1.51l-4.4,0.25l-2.13,4.09l2.42,5.45l-9.21,2.52l-8.61,0.98l-9.03,2.34l-8.33,4l-13.44,5.52l-16.14,0.99l-5.43-6.51
l-7.46-4.19l-6.54,0.18l-7.38,0.68l-20.59-1.29l3.41-3.46l1.4-2.67l-0.25-2.62l-3.49-10.94l-2.87-2.53l-14.36-5.32l-11.77-10.29
l-4.38-6.43l-1.17-6.02l4.2-7.49l8.28-5.6l10.56-3.83l11.1-2.01l18.3-0.49l17.56-4.42l8.85-0.78l2.04,3.48l-3.72,9.75l7.53,3.61
l4.1-1.34l4.78-2.22l6.43-1.16l5.66,0.09l11.64-3.74l11.51-5.23l11.09,0.96l3.85,7.03l-0.41,7.4l6.7,3.53l9.54-3.46l4.75-8.74
l2.46-3.19l5.58-1.94l3.8-2.73l4.85-2.38l5.54,0.43l3.95,1.83l6.12,1.52l9.44,0.7l-11.77,5.69l-8.75,5.14L1173.12,715.06z
 M1163.92,749.84l9.01-12.77l-3.83-13.98l1.61-8.24l6.28-8.07l9.05-5.3l7.74-3.74l-4.76-0.35l-6.77-1.7l-3.61-1.67l-3.79-0.29
l-3.78,1.85l-4.06,2.87l-5.02,1.74l-2.07,2.65l-4.98,9.22l-12.1,4.38l-8.69-4.59l0.45-7.86l-3.33-6.01l-8.68-0.75l-10.76,4.87
l-12.49,4.03l-6.14-0.1l-5.53,1.03l-4.41,2.1l-5.75,1.88l-9.72-4.65l3.87-10.16l-1.26-2.18l-6.81,0.59l-17.75,4.45l-18.36,0.49
l-10.43,1.92l-9.88,3.61l-7.64,5.22l-3.72,6.79l1.1,5.66l4.16,6.09l11.55,10.08l14.36,5.32l3.24,2.96l3.55,11.14l0.3,2.95
l-1.68,3.13l-1.79,1.82l17.16,1.08l7.02-0.66l7.67-0.22l8.48,4.8l4.86,5.83l13.85-0.84l12.75-5.24l8.71-4.15l9.65-2.48l8.61-0.98
l6.69-1.85l-2.08-4.67l2.85-5.48l6.95-0.39l4.55,1.32l2.27-0.86l2.17-3.65l7.29,0.5L1163.92,749.84"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: celilabadColor ? celilabadColor : null }}
                points="1178.64,694.01 1182.25,695.68 1189.02,697.38 1193.78,697.73 1186.04,701.47 1176.99,706.77 
1170.71,714.85 1169.1,723.09 1172.93,737.07 1163.92,749.84 1160.03,748.35 1152.74,747.85 1150.57,751.5 1148.3,752.36 
1143.75,751.05 1136.8,751.43 1133.95,756.91 1136.02,761.58 1129.33,763.43 1120.72,764.41 1111.07,766.9 1102.36,771.05 
1089.61,776.28 1075.76,777.13 1070.9,771.29 1062.42,766.5 1054.75,766.72 1047.73,767.38 1030.57,766.3 1032.37,764.48 
1034.04,761.35 1033.75,758.4 1030.2,747.26 1026.96,744.3 1012.6,738.97 1001.05,728.89 996.89,722.8 995.79,717.14 
999.51,710.35 1007.15,705.13 1017.02,701.52 1027.45,699.6 1045.82,699.11 1063.57,694.66 1070.38,694.07 1071.64,696.24 
1067.77,706.4 1077.49,711.05 1083.24,709.17 1087.65,707.07 1093.18,706.04 1099.33,706.14 1111.82,702.1 1122.58,697.23 
1131.26,697.98 1134.6,703.98 1134.15,711.84 1142.84,716.43 1154.94,712.05 1159.91,702.83 1161.99,700.18 1167.01,698.44 
1171.07,695.57 1174.85,693.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1074.16,778.59 1079.8,839.81 1074.38,833.3 1068.73,772.09 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1103.73,772.09 1109.38,833.3 1095.94,838.82 1090.29,777.61 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1090.29,777.61 1095.94,838.82 1079.8,839.81 1074.16,778.59 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'yardimli' ? 'active' : null}`}
            onClick={() => openPopup('yardimli')}
          >
            <g>
              <polygon
                className="st37"
                points="1029.15,767.63 1034.79,828.84 1031.71,831.06 1026.06,769.84 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="954.24,775.97 959.89,837.19 958.88,837.99 953.24,776.78 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1047.34,768.77 1052.99,829.99 1034.79,828.84 1029.15,767.63 			"
              />
            </g>
            <g>
              <rect
                x="1050.15"
                y="768.3"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -68.9616 100.2137)"
                className="st8"
                width="7.41"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1061.24,767.91 1066.89,829.13 1060.37,829.31 1054.72,768.09 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="953.24,776.78 958.88,837.99 958.2,839.54 952.55,778.32 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1026.06,769.84 1031.71,831.06 1027.53,833.37 1021.88,772.16 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="952.55,778.32 958.2,839.54 956.38,841.13 950.73,779.91 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1021.88,772.16 1027.53,833.37 1013.5,836.47 1007.85,775.25 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1007.85,775.25 1013.5,836.47 959.89,837.19 954.24,775.97 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="950.73,779.91 956.38,841.13 939.08,845.1 933.43,783.88 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1068.73,772.09 1074.38,833.3 1066.89,829.13 1061.24,767.91 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="933.43,783.88 939.08,845.1 935.86,846.93 930.21,785.72 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1073.73,778.12 1079.38,839.34 1074.38,833.3 1068.73,772.09 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="930.21,785.72 935.86,846.93 932.67,855.1 927.02,793.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="929.67,799.97 935.32,861.19 932.67,855.1 927.02,793.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="927.34,800.57 932.99,861.79 930.15,855.26 924.5,794.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1075.13,787.11 1080.78,848.32 1079.38,839.34 1073.73,778.12 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1086.73,789.2 1092.38,850.41 1080.78,848.32 1075.13,787.11 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1086.73,789.2 1092.38,850.41 1066.16,857.31 1060.51,796.1 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1093.37,788.91 1099.02,850.12 1066.9,858.58 1061.25,797.36 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="943.73,809.77 949.38,870.99 935.32,861.19 929.67,799.97 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="941.84,810.77 947.49,871.98 932.99,861.79 927.34,800.57 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1060.51,796.1 1066.16,857.31 1036.08,863.87 1030.43,802.65 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1061.25,797.36 1066.9,858.58 1036.94,865.09 1031.29,803.87 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1030.43,802.65 1036.08,863.87 1029.28,866.08 1023.63,804.86 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1023.63,804.86 1029.28,866.08 1021.97,867.75 1016.32,806.53 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1031.29,803.87 1036.94,865.09 1030.15,867.3 1024.5,806.09 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1024.5,806.09 1030.15,867.3 1022.8,868.98 1017.16,807.76 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1016.32,806.53 1021.97,867.75 1014.81,870.54 1009.16,809.32 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1017.16,807.76 1022.8,868.98 1016.2,871.58 1010.56,810.36 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1009.16,809.32 1014.81,870.54 1008.28,873.83 1002.63,812.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1010.56,810.36 1016.2,871.58 1009.94,874.73 1004.29,813.51 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1002.63,812.62 1008.28,873.83 1003.45,877.86 997.8,816.65 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1004.29,813.51 1009.94,874.73 1005.56,878.42 999.91,817.2 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="997.8,816.65 1003.45,877.86 1000.1,882.03 994.45,820.82 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="999.91,817.2 1005.56,878.42 1002.04,882.81 996.39,821.6 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="975.99,823.63 981.64,884.84 949.38,870.99 943.73,809.77 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="994.45,820.82 1000.1,882.03 995.27,884.26 989.62,823.05 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="974.07,824.59 979.71,885.81 947.49,871.98 941.84,810.77 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="996.39,821.6 1002.04,882.81 996.79,885.24 991.14,824.02 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="978.95,825.81 984.6,887.03 981.64,884.84 975.99,823.63 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="976.48,826.39 982.13,887.6 979.71,885.81 974.07,824.59 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="989.62,823.05 995.27,884.26 990.05,887.19 984.4,825.97 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="978.95,825.81 984.6,887.03 984.57,888.16 978.93,826.95 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="984.4,825.97 990.05,887.19 984.57,888.16 978.93,826.95 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: yardimliColor ? yardimliColor : null }}
                points="1061.24,767.91 1068.73,772.09 1073.73,778.12 1075.13,787.11 1086.73,789.2 1060.51,796.1 
1030.43,802.65 1023.63,804.86 1016.32,806.53 1009.16,809.32 1002.63,812.62 997.8,816.65 994.45,820.82 989.62,823.05 
984.4,825.97 978.93,826.95 978.95,825.81 975.99,823.63 943.73,809.77 929.67,799.97 927.02,793.88 930.21,785.72 
933.43,783.88 950.73,779.91 952.55,778.32 953.24,776.78 954.24,775.97 1007.85,775.25 1021.62,772.14 1026.06,769.84 
1029.15,767.63 1047.34,768.77 1054.72,768.09 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="991.14,824.02 996.79,885.24 991.2,888.38 985.55,827.17 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1077.43,786.04l15.93,2.87l-32.12,8.45l-29.96,6.51l-6.79,2.21l-7.34,1.68l-6.6,2.6l-6.26,3.15l-4.38,3.69
l-3.52,4.4l-5.25,2.42l-5.59,3.15l-9.13,1.61l0.06-2.39l-2.42-1.79l-32.23-13.83l-14.49-10.2l-2.84-6.52l3.48-8.92l4.49-2.45
l16.73-3.84l1.09-0.94l0.68-1.59l2.16-1.7l54.71-0.74l12.9-2.97l3.59-1.92l3.3-2.39l0.44-0.46l0.86,0.06l18.78,1.18l7.02-0.66
l7.7-0.22l8.48,4.8l5.26,6.43L1077.43,786.04z M1060.51,796.1l26.21-6.9l-11.6-2.09l-1.39-8.99l-5.01-6.04l-7.48-4.18l-6.52,0.18
l-7.38,0.68l-18.19-1.15l-3.09,2.22l-4.18,2.31l-14.03,3.1l-53.61,0.72l-1,0.81l-0.69,1.55l-1.82,1.59l-17.3,3.97l-3.22,1.84
l-3.19,8.16l2.65,6.09l14.06,9.8l32.26,13.86l2.96,2.19l-0.03,1.13l5.48-0.97l5.22-2.92l4.83-2.23l3.35-4.17l4.83-4.03l6.53-3.29
l7.16-2.79l7.31-1.67l6.79-2.21L1060.51,796.1"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="985.55,827.17 991.2,888.38 982.07,889.99 976.42,828.78 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'baki' ? 'active' : null}`}
            onClick={() => openPopup('baki')}
          >
            <g>
              <polygon
                className="st34"
                points="1794.04,548.44 1799.69,609.65 1798.73,610.97 1793.08,549.75 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1804.61,548.76 1810.26,609.98 1808.27,609.85 1802.62,548.64 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1796.02,549.78 1801.67,611 1799.69,609.65 1794.04,548.44 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1802.62,548.64 1808.27,609.85 1805.23,610.7 1799.58,549.48 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1799.58,549.48 1805.23,610.7 1801.67,611 1796.02,549.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1807.04,551.17 1812.69,612.38 1810.26,609.98 1804.61,548.76 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1794.23,552.78 1799.88,613.99 1798.73,610.97 1793.08,549.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1791.88,553.34 1797.53,614.55 1796.19,610.99 1790.54,549.78 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1796.92,554.73 1802.57,615.95 1799.88,613.99 1794.23,552.78 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1800.43,554.42 1806.08,615.63 1802.57,615.95 1796.92,554.73 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1807.04,551.17 1812.69,612.38 1812.41,615.37 1806.77,554.15 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1809.53,550.71 1815.17,611.92 1814.82,615.77 1809.17,554.56 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1795.67,556.22 1801.32,617.43 1797.53,614.55 1791.88,553.34 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1799.82,555.84 1805.47,617.06 1801.32,617.43 1795.67,556.22 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="1804.77,555.52 1810.41,616.74 1806.08,615.63 1800.43,554.42 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1806.77,554.15 1812.41,615.37 1810.41,616.74 1804.77,555.52 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: bakiColor ? bakiColor : null }}
                points="1807.04,551.17 1806.77,554.15 1804.77,555.52 1800.43,554.42 1796.92,554.73 1794.23,552.78 
1793.08,549.75 1794.04,548.44 1796.02,549.78 1799.58,549.48 1802.62,548.64 1804.61,548.76 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1809.53,550.71l-0.35,3.85l-3.9,2.67l-5.45-1.38l-4.15,0.38l-3.79-2.88l-1.34-3.56l2.9-4.03l3.78,2.58
l2.06-0.18l3.14-0.91l3.82,0.23L1809.53,550.71z M1804.77,555.52l2-1.37l0.28-2.99l-2.44-2.41l-1.98-0.12l-3.04,0.84l-3.56,0.3
l-1.98-1.35l-0.96,1.32l1.15,3.02l2.69,1.96l3.5-0.32L1804.77,555.52"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1805.27,557.22 1810.92,618.44 1805.47,617.06 1799.82,555.84 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1809.17,554.56 1814.82,615.77 1810.92,618.44 1805.27,557.22 			"
              />
            </g>
          </g>
          <g
            className={`st0 ${region == 'baki' ? 'active' : null}`}
            onClick={() => openPopup('baki')}
          >
            <g>
              <polygon
                className="st20"
                points="1581.1,475.74 1586.74,536.95 1584.47,535.99 1578.82,474.77 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1578.82,474.77 1584.47,535.99 1583.21,537.33 1577.56,476.12 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1577.56,476.12 1583.21,537.33 1579.84,539.57 1574.2,478.36 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1574.2,478.36 1579.84,539.57 1576.95,541.39 1571.3,480.17 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1594.79,478.09 1600.44,539.31 1586.74,536.95 1581.1,475.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1574.45,483.5 1580.1,544.71 1576.95,541.39 1571.3,480.17 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1605.79,481.31 1611.43,542.53 1600.44,539.31 1594.79,478.09 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1572.63,484.64 1578.28,545.85 1573.96,541.32 1568.31,480.1 			"
              />
            </g>
            <g>
              <rect
                x="1587.92"
                y="483.42"
                transform="matrix(0.9958 -0.0919 0.0919 0.9958 -40.5123 148.3668)"
                className="st121"
                width="6.22"
                height="61.48"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1579.83,484.66 1585.48,545.87 1580.1,544.71 1574.45,483.5 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1585.11,483.83 1590.76,545.04 1585.48,545.87 1579.83,484.66 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1612.64,482.31 1618.29,543.52 1611.43,542.53 1605.79,481.31 			"
              />
            </g>
            <g>
              <polygon
                className="st141"
                points="1642.46,479.7 1648.11,540.92 1641.35,541 1635.7,479.79 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1590.65,484.7 1596.3,545.91 1591.01,546.38 1585.36,485.17 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1635.7,479.79 1641.35,541 1624.12,543.35 1618.47,482.13 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1618.47,482.13 1624.12,543.35 1618.29,543.52 1612.64,482.31 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1579.46,486.1 1585.11,547.32 1578.28,545.85 1572.63,484.64 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1585.36,485.17 1591.01,546.38 1585.11,547.32 1579.46,486.1 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="1602.4,486.46 1608.05,547.68 1596.95,544.48 1591.3,483.26 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1601.06,487.7 1606.71,548.91 1596.3,545.91 1590.65,484.7 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1673.51,483.11 1679.16,544.32 1648.11,540.92 1642.46,479.7 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1520.4,495.06 1526.05,556.27 1516.97,560.17 1511.32,498.96 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1613.24,489.77 1618.89,550.99 1608.05,547.68 1602.4,486.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1674.88,484.34 1680.53,545.55 1679.16,544.32 1673.51,483.11 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1543.21,496.63 1548.86,557.85 1526.05,556.27 1520.4,495.06 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1551.79,496.48 1557.43,557.69 1548.86,557.85 1543.21,496.63 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1611.83,490.99 1617.48,552.2 1606.71,548.91 1601.06,487.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1675.36,485.73 1681,546.95 1680.53,545.55 1674.88,484.34 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1560.25,497.02 1565.9,558.24 1557.43,557.69 1551.79,496.48 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1511.32,498.96 1516.97,560.17 1507.86,563.76 1502.21,502.55 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1502.21,502.55 1507.86,563.76 1495.45,563.77 1489.8,502.56 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1675.57,487.43 1681.22,548.65 1681,546.95 1675.36,485.73 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1623.46,493.38 1629.11,554.6 1618.89,550.99 1613.24,489.77 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1619.17,493.58 1624.82,554.8 1617.48,552.2 1611.83,490.99 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1565.95,498.59 1571.6,559.81 1565.9,558.24 1560.25,497.02 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1489.8,502.56 1495.45,563.77 1484.99,567.86 1479.35,506.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1676.15,488.98 1681.8,550.19 1681.22,548.65 1675.57,487.43 			"
              />
            </g>
            <g>
              <polygon
                className="st75"
                points="1571.89,500.4 1577.53,561.62 1571.6,559.81 1565.95,498.59 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1579.78,499.72 1585.43,560.93 1577.53,561.62 1571.89,500.4 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1587.17,498.49 1592.82,559.7 1585.43,560.93 1579.78,499.72 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1600.67,497.97 1606.32,559.19 1592.82,559.7 1587.17,498.49 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1614.71,497.1 1620.36,558.31 1606.32,559.19 1600.67,497.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1623.46,493.38 1629.11,554.6 1620.36,558.31 1614.71,497.1 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1679.65,493.17 1685.3,554.39 1681.8,550.19 1676.15,488.98 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1539.71,504.83 1545.36,566.05 1533.42,568.77 1527.77,507.55 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1552.45,505.55 1558.1,566.77 1545.36,566.05 1539.71,504.83 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1539.8,506.24 1545.45,567.46 1536.52,569.48 1530.88,508.27 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1550.55,506.84 1556.2,568.05 1545.45,567.46 1539.8,506.24 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1683.68,496.41 1689.32,557.62 1685.3,554.39 1679.65,493.17 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1479.35,506.64 1484.99,567.86 1480.35,578.24 1474.71,517.03 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1531.03,512.78 1536.68,574 1533.42,568.77 1527.77,507.55 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1688.68,498.83 1694.33,560.05 1689.32,557.62 1683.68,496.41 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1556.18,511.69 1561.83,572.91 1558.1,566.77 1552.45,505.55 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1553.82,512.23 1559.47,573.44 1556.2,568.05 1550.55,506.84 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1533.47,512.46 1539.12,573.68 1539.46,577.42 1533.81,516.2 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1531.03,512.78 1536.68,574 1537.08,578.41 1531.43,517.19 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1555.2,515.1 1560.84,576.32 1559.47,573.44 1553.82,512.23 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1558.03,515.54 1563.68,576.76 1561.83,572.91 1556.18,511.69 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1538.08,518.77 1543.73,579.99 1537.08,578.41 1531.43,517.19 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1558.03,515.54 1563.68,576.76 1558.19,578.98 1552.54,517.77 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1552.54,517.77 1558.19,578.98 1550.74,580.18 1545.09,518.96 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1545.09,518.96 1550.74,580.18 1543.73,579.99 1538.08,518.77 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1552.45,505.55l3.74,6.14l1.85,3.85l-5.49,2.22l-7.45,1.2l-7.01-0.19l-6.65-1.58l-0.4-4.41l-3.26-5.23
l11.94-2.72L1552.45,505.55z M1551.87,516.5l3.32-1.39l-1.37-2.88l-3.27-5.39l-10.75-0.6l-8.93,2.03l0.05,0.09l2.55,4.1
l0.34,3.74l5.07,1.21l6.4,0.18L1551.87,516.5"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1712.69,504.25 1718.34,565.47 1694.33,560.05 1688.68,498.83 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1716.47,506.07 1722.12,567.29 1718.34,565.47 1712.69,504.25 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1474.71,517.03 1480.35,578.24 1477.48,590.01 1471.84,528.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1717.95,509.24 1723.6,570.46 1722.12,567.29 1716.47,506.07 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1608.73,523.24 1614.38,584.46 1607.32,585.88 1601.67,524.67 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1719.11,514.49 1724.75,575.71 1723.6,570.46 1717.95,509.24 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1608,524.8 1613.65,586.01 1608.03,587.14 1602.38,525.93 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1747.85,512.53 1753.5,573.75 1749.2,574.15 1743.55,512.93 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1743.55,512.93 1749.2,574.15 1747.36,573.3 1741.71,512.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1748.64,513.43 1754.29,574.65 1753.5,573.75 1747.85,512.53 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1615.35,526.35 1620.99,587.56 1614.38,584.46 1608.73,523.24 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1601.67,524.67 1607.32,585.88 1594.84,590.16 1589.19,528.94 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1471.84,528.79 1477.48,590.01 1470.53,602.05 1464.88,540.84 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1741.71,512.08 1747.36,573.3 1745.54,576.97 1739.89,515.75 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1613.8,527.51 1619.44,588.73 1613.65,586.01 1608,524.8 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1602.38,525.93 1608.03,587.14 1597.21,590.86 1591.57,529.65 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1622.25,527.72 1627.89,588.93 1620.99,587.56 1615.35,526.35 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1721.28,519.57 1726.93,580.78 1724.75,575.71 1719.11,514.49 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1620.87,528.94 1626.52,590.15 1619.44,588.73 1613.8,527.51 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="1625.1,528.73 1630.75,589.95 1627.89,588.93 1622.25,527.72 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1751.18,513.09 1756.83,574.31 1755.39,578.8 1749.74,517.58 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="1748.64,513.43 1754.29,574.65 1752.85,579.06 1747.2,517.85 			"
              />
            </g>
            <g>
              <polygon
                className="st22"
                points="1623.17,529.76 1628.82,590.98 1626.52,590.15 1620.87,528.94 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1626.78,530.47 1632.43,591.69 1630.75,589.95 1625.1,528.73 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1589.19,528.94 1594.84,590.16 1594.39,595.34 1588.74,534.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1624.5,531.13 1630.15,592.34 1628.82,590.98 1623.17,529.76 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1726.12,522.15 1731.77,583.36 1726.93,580.78 1721.28,519.57 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1591.57,529.65 1597.21,590.86 1596.79,595.99 1591.14,534.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1748.95,520.57 1754.6,581.79 1752.85,579.06 1747.2,517.85 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1464.88,540.84 1470.53,602.05 1457.65,610.66 1452,549.44 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1588.74,534.12 1594.39,595.34 1585.04,599.01 1579.39,537.79 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1652.32,531.01 1657.97,592.23 1651.98,593.02 1646.33,531.8 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1731.55,523.95 1737.2,585.17 1731.77,583.36 1726.12,522.15 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1751.61,522.75 1757.26,583.96 1754.6,581.79 1748.95,520.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1628.83,534.12 1634.48,595.34 1632.43,591.69 1626.78,530.47 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1579.39,537.79 1585.04,599.01 1572.37,601.36 1566.72,540.14 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1566.72,540.14 1572.37,601.36 1552.75,602.14 1547.1,540.93 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1745.71,523.73 1751.36,584.95 1745.54,576.97 1739.89,515.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1743.04,523.87 1748.69,585.09 1742.97,577.19 1737.32,515.97 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1626.55,534.76 1632.2,595.98 1630.15,592.34 1624.5,531.13 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1591.14,534.78 1596.79,595.99 1585.76,600.27 1580.11,539.06 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1452,549.44 1457.65,610.66 1451.91,612.8 1446.26,551.58 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1651.94,532.39 1657.58,593.61 1652.5,594.32 1646.85,533.11 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1662.94,531.94 1668.59,593.16 1657.97,592.23 1652.32,531.01 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1630.02,535.04 1635.67,596.26 1634.48,595.34 1628.83,534.12 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1670.02,531.14 1675.67,592.36 1671.22,592.99 1665.57,531.77 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1665.57,531.77 1671.22,592.99 1668.59,593.16 1662.94,531.94 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1673.43,531.21 1679.08,592.43 1675.67,592.36 1670.02,531.14 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1547.1,540.93 1552.75,602.14 1533.67,606.17 1528.02,544.96 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1566.76,541.51 1572.41,602.73 1552.84,603.51 1547.19,542.3 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1580.11,539.06 1585.76,600.27 1572.41,602.73 1566.76,541.51 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1646.33,531.8 1651.98,593.02 1640.4,596.67 1634.75,535.46 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1634.75,535.46 1640.4,596.67 1635.67,596.26 1630.02,535.04 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1528.02,544.96 1533.67,606.17 1510.04,608.86 1504.39,547.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1628.48,536.32 1634.13,597.54 1632.2,595.98 1626.55,534.76 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1662.47,533.31 1668.11,594.53 1657.58,593.61 1651.94,532.39 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1669.84,532.52 1675.48,593.73 1671.41,594.33 1665.76,533.12 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1665.76,533.12 1671.41,594.33 1668.11,594.53 1662.47,533.31 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1672.76,532.58 1678.41,593.8 1675.48,593.73 1669.84,532.52 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1678.97,532.16 1684.62,593.37 1679.08,592.43 1673.43,531.21 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1547.19,542.3 1552.84,603.51 1534.08,607.5 1528.44,546.28 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1753.01,525.61 1758.66,586.83 1757.26,583.96 1751.61,522.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1735.04,527.42 1740.69,588.63 1737.2,585.17 1731.55,523.95 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1504.39,547.65 1510.04,608.86 1503.69,610.6 1498.04,549.39 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1634.96,536.89 1640.61,598.11 1634.13,597.54 1628.48,536.32 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1646.85,533.11 1652.5,594.32 1640.61,598.11 1634.96,536.89 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1528.44,546.28 1534.08,607.5 1510.44,610.19 1504.79,548.97 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1446.26,551.58 1451.91,612.8 1448.41,615.93 1442.76,554.72 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1677.84,533.43 1683.49,594.65 1678.41,593.8 1672.76,532.58 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1504.79,548.97 1510.44,610.19 1504.69,611.79 1499.04,550.57 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1745.71,523.73 1751.36,584.95 1747.89,589.51 1742.24,528.29 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1753.01,525.61 1758.66,586.83 1757.92,588.92 1752.27,527.7 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1746.27,528.78 1751.92,590 1747.89,589.51 1742.24,528.29 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1750.14,528.63 1755.79,589.84 1751.92,590 1746.27,528.78 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1752.27,527.7 1757.92,588.92 1755.79,589.84 1750.14,528.63 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: bakiColor ? bakiColor : null }}
                points="1751.61,522.75 1753.01,525.61 1752.27,527.7 1750.14,528.63 1746.27,528.78 1742.24,528.29 
1745.71,523.73 1739.89,515.75 1741.71,512.08 1743.55,512.93 1747.85,512.53 1748.64,513.43 1747.2,517.85 1748.95,520.58 			
"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1755.53,525.41 1761.17,586.63 1760.13,589.59 1754.48,528.38 			"
              />
            </g>
            <g>
              <polygon
                className="st88"
                points="1688.78,535.12 1694.43,596.33 1684.62,593.37 1678.97,532.16 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1442.76,554.72 1448.41,615.93 1447.08,619.45 1441.43,558.24 			"
              />
            </g>
            <g>
              <polygon
                className="st2"
                points="1745.84,530.16 1751.49,591.38 1744.54,590.55 1738.89,529.34 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1753.94,522.15l1.59,3.26l-1.05,2.97l-3.63,1.58l-5,0.2l-6.96-0.83l4.16-5.47l-5.73-7.9l3.14-6.36
l4.03,1.86l4.9-0.45l1.8,2.07l-1.43,4.49l1.48,2.32L1753.94,522.15z M1750.14,528.63l2.12-0.92l0.74-2.09l-1.4-2.86l-2.66-2.17
l-1.75-2.73l1.44-4.41l-0.8-0.9l-4.3,0.4l-1.84-0.85l-1.82,3.67l5.82,7.98l-3.46,4.56l4.03,0.49L1750.14,528.63"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1754.48,528.38 1760.13,589.59 1756.49,591.18 1750.84,529.96 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1750.84,529.96 1756.49,591.18 1751.49,591.38 1745.84,530.16 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1687.63,536.38 1693.28,597.6 1683.49,594.65 1677.84,533.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1737.09,531.83 1742.74,593.05 1740.69,588.63 1735.04,527.42 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1498.04,549.39 1503.69,610.6 1489.58,616.51 1483.93,555.3 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1483.93,555.3 1489.58,616.51 1483.95,618 1478.3,556.78 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1499.04,550.57 1504.69,611.79 1490.55,617.71 1484.9,556.49 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1700.89,537.21 1706.54,598.43 1694.43,596.33 1688.78,535.12 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1484.9,556.49 1490.55,617.71 1484.97,619.18 1479.32,557.97 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1441.43,558.24 1447.08,619.45 1442.91,623.54 1437.26,562.32 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1478.3,556.78 1483.95,618 1479.16,620.29 1473.51,559.08 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1699.57,538.46 1705.22,599.68 1693.28,597.6 1687.63,536.38 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1479.32,557.97 1484.97,619.18 1481.22,621 1475.57,559.78 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="1706.6,539.13 1712.25,600.34 1706.54,598.43 1700.89,537.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1737.8,536.35 1743.44,597.56 1742.74,593.05 1737.09,531.83 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1705.02,540.28 1710.67,601.49 1705.22,599.68 1699.57,538.46 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1437.26,562.32 1442.91,623.54 1436.21,627.06 1430.56,565.84 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1473.51,559.08 1479.16,620.29 1476.24,625.05 1470.6,563.84 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="1711.76,541.59 1717.41,602.81 1712.25,600.34 1706.6,539.13 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1475.57,559.78 1481.22,621 1478.66,625.21 1473.01,564 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="1709.91,542.61 1715.56,603.82 1710.67,601.49 1705.02,540.28 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1430.56,565.84 1436.21,627.06 1430.95,631.13 1425.31,569.91 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1470.6,563.84 1476.24,625.05 1475.08,630.91 1469.43,569.69 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1473.01,564 1478.66,625.21 1477.51,630.97 1471.86,569.75 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1425.31,569.91 1430.95,631.13 1430.18,635.44 1424.54,574.22 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1469.43,569.69 1475.08,630.91 1474.43,632.66 1468.78,571.45 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1468.78,571.45 1474.43,632.66 1458.09,634.68 1452.44,573.46 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1471.86,569.75 1477.51,630.97 1476.44,633.79 1470.8,572.58 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1722.7,549.36 1728.35,610.58 1717.41,602.81 1711.76,541.59 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1470.8,572.58 1476.44,633.79 1459.29,635.9 1453.64,574.69 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1720.6,550.2 1726.24,611.41 1715.56,603.82 1709.91,542.61 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1424.54,574.22 1430.18,635.44 1429.01,640.04 1423.36,578.82 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1452.44,573.46 1458.09,634.68 1453.21,638.05 1447.56,576.83 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1453.64,574.69 1459.29,635.9 1455.5,638.52 1449.85,577.3 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1423.36,578.82 1429.01,640.04 1422.42,643.88 1416.77,582.66 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="1737.8,536.35 1743.44,597.56 1738.07,615.28 1732.42,554.07 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1740.24,536.34 1745.89,597.56 1740.59,615.03 1734.94,553.81 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1727.57,554.75 1733.22,615.97 1728.35,610.58 1722.7,549.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1725.29,555.39 1730.94,616.61 1726.24,611.41 1720.6,550.2 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="1447.56,576.83 1453.21,638.05 1451.83,642.53 1446.18,581.32 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1449.85,577.3 1455.5,638.52 1454.29,642.48 1448.64,581.27 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1733.6,556.04 1739.24,617.26 1738.07,615.28 1732.42,554.07 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1416.77,582.66 1422.42,643.88 1415.91,647.34 1410.26,586.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1448.01,586.17 1453.66,647.39 1451.83,642.53 1446.18,581.32 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1734.05,560.01 1739.7,621.23 1739.24,617.26 1733.6,556.04 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1730.99,560.53 1736.64,621.75 1733.22,615.97 1727.57,554.75 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1728.62,561.03 1734.27,622.24 1730.94,616.61 1725.29,555.39 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1410.26,586.12 1415.91,647.34 1411.29,652.7 1405.64,591.48 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1450,588.21 1455.65,649.42 1453.66,647.39 1448.01,586.17 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1734.05,560.01 1739.7,621.23 1739.11,625.58 1733.46,564.36 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1453.06,590.29 1458.71,651.5 1455.65,649.42 1450,588.21 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1736.5,559.86 1742.15,621.07 1741.53,625.77 1735.88,564.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1732.83,565.15 1738.47,626.36 1736.64,621.75 1730.99,560.53 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1733.46,564.36 1739.11,625.58 1738.47,626.36 1732.83,565.15 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1405.64,591.48 1411.29,652.7 1405.3,657.94 1399.65,596.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1454.37,591.88 1460.02,653.1 1458.71,651.5 1453.06,590.29 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="1454.37,591.88 1460.02,653.1 1460.13,654.89 1454.48,593.68 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="1456.82,591.55 1462.47,652.76 1462.62,655.34 1456.98,594.13 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1454.48,593.68 1460.13,654.89 1458.57,655.74 1452.93,594.52 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1452.93,594.52 1458.57,655.74 1455.31,656.54 1449.66,595.32 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1735.88,564.56 1741.53,625.77 1737.58,630.65 1731.93,569.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1731.93,569.43 1737.58,630.65 1734.27,622.24 1728.62,561.03 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1456.98,594.13 1462.62,655.34 1459.54,656.95 1453.89,595.73 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1453.89,595.73 1459.54,656.95 1456.25,657.74 1450.6,596.53 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1449.66,595.32 1455.31,656.54 1449.28,658.68 1443.64,597.46 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1443.64,597.46 1449.28,658.68 1446.79,659.12 1441.14,597.91 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1441.14,597.91 1446.79,659.12 1444.26,659.82 1438.61,598.6 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1401.87,602.61 1407.52,663.82 1405.3,657.94 1399.65,596.73 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1450.6,596.53 1456.25,657.74 1449.94,659.96 1444.3,598.74 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1399.44,602.92 1405.09,664.13 1402.68,657.81 1397.03,596.6 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1444.3,598.74 1449.94,659.96 1447.45,660.39 1441.8,599.18 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1441.8,599.18 1447.45,660.39 1445.41,660.96 1439.76,599.75 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1438.61,598.6 1444.26,659.82 1440.8,661.98 1435.15,600.76 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1439.76,599.75 1445.41,660.96 1442.65,662.75 1437,601.54 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1435.15,600.76 1440.8,661.98 1438.17,664.49 1432.52,603.27 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1437,601.54 1442.65,662.75 1440.35,665 1434.7,603.78 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1432.52,603.27 1438.17,664.49 1436.95,666.6 1431.3,605.39 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1434.7,603.78 1440.35,665 1439.38,666.71 1433.73,605.5 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1401.87,602.61 1407.52,663.82 1408.06,670.76 1402.41,609.54 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="1431.3,605.39 1436.95,666.6 1436.41,671.13 1430.76,609.92 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1433.73,605.5 1439.38,666.71 1438.85,670.95 1433.2,609.74 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1405.04,612.95 1410.69,674.16 1408.06,670.76 1402.41,609.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1402.75,613.58 1408.4,674.79 1405.62,671.09 1399.97,609.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1407.42,616.79 1413.07,678.01 1410.69,674.16 1405.04,612.95 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1431.86,614.58 1437.51,675.8 1436.41,671.13 1430.76,609.92 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1404.91,617.08 1410.56,678.3 1408.4,674.79 1402.75,613.58 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1431.86,614.58 1437.51,675.8 1437.32,678.13 1431.67,616.91 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1434.29,614.37 1439.94,675.59 1439.74,678.4 1434.1,617.19 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1431.67,616.91 1437.32,678.13 1435.2,680.13 1429.55,618.92 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1429.55,618.92 1435.2,680.13 1432.39,681.33 1426.74,620.12 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1434.1,617.19 1439.74,678.4 1436.75,681.11 1431.1,619.9 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1407.42,616.79 1413.07,678.01 1411.78,683.52 1406.13,622.3 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1426.74,620.12 1432.39,681.33 1429.76,682.16 1424.11,620.94 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1431.1,619.9 1436.75,681.11 1433.55,682.45 1427.9,621.24 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1424.11,620.94 1429.76,682.16 1427.15,683.44 1421.51,622.22 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1427.9,621.24 1433.55,682.45 1430.9,683.3 1425.25,622.09 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1425.25,622.09 1430.9,683.3 1429.13,684.18 1423.48,622.97 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1421.51,622.22 1427.15,683.44 1425.43,685.68 1419.78,624.47 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1423.48,622.97 1429.13,684.18 1427.86,685.83 1422.22,624.62 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="1419.78,624.47 1425.43,685.68 1425.37,687.03 1419.72,625.81 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1422.22,624.62 1427.86,685.83 1427.83,686.84 1422.19,625.62 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1406.13,622.3 1411.78,683.52 1409.43,689.14 1403.78,627.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1420.15,627.06 1425.8,688.28 1425.37,687.03 1419.72,625.81 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1403.78,627.93 1409.43,689.14 1401.6,696.84 1395.95,635.62 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1412.66,631.55 1418.31,692.76 1414.86,695.88 1409.21,634.67 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1409.21,634.67 1414.86,695.88 1414.22,697.51 1408.58,636.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1409.43,637.77 1415.08,698.99 1414.22,697.51 1408.58,636.29 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1409.43,637.77 1415.08,698.99 1412.59,701.52 1406.94,640.31 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1418.31,629.97 1423.96,691.18 1418.31,692.76 1412.66,631.55 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1414.26,632.57 1419.91,693.78 1417.16,696.29 1411.51,635.07 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1411.51,635.07 1417.16,696.29 1416.77,697.29 1411.12,636.07 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1412.09,637.78 1417.74,698.99 1415.08,701.7 1409.44,640.49 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1395.95,635.62 1401.6,696.84 1397.97,705.79 1392.32,644.58 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1409.44,640.49 1415.08,701.7 1415.3,704.39 1409.65,643.17 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="1406.94,640.31 1412.59,701.52 1412.86,704.71 1407.22,643.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1411.1,649.13 1416.75,710.35 1412.86,704.71 1407.22,643.5 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1392.32,644.58 1397.97,705.79 1395.86,712.88 1390.22,651.66 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1390.22,651.66 1395.86,712.88 1395.72,717.97 1390.08,656.75 			"
              />
            </g>
            <g>
              <path
                className={`st59`}
                onClick={() => openPopup('baki')}
                style={{ fill: bakiColor ? bakiColor : null }}
                d="M1733.6,556.04l0.46,3.97l-0.59,4.35l-0.64,0.79l-1.84-4.61l-3.42-5.78l-4.87-5.39l-10.94-7.77l-5.16-2.47
l-5.71-1.92l-12.11-2.09l-9.81-2.96l-5.55-0.94l-3.41-0.07l-4.45,0.63l-2.63,0.17l-10.62-0.93l-5.99,0.79l-11.58,3.66l-4.73-0.41
l-1.19-0.92l-2.05-3.65l-1.69-1.74l-2.85-1.01l-6.9-1.37l-6.61-3.1l-7.07,1.42l-12.48,4.28l-0.45,5.18l-9.35,3.67l-12.67,2.35
l-19.62,0.78l-19.09,4.03l-23.63,2.69l-6.35,1.74l-14.11,5.91l-5.63,1.48l-4.79,2.3l-2.91,4.76l-1.17,5.85l-0.65,1.75
l-16.34,2.01l-4.88,3.37l-1.38,4.48l1.84,4.85l1.99,2.04l3.06,2.08l1.31,1.59l0.11,1.8l-1.55,0.85l-3.26,0.8l-6.03,2.14
l-2.5,0.44l-2.53,0.7l-3.46,2.16l-2.63,2.51l-1.22,2.11l-0.54,4.53l1.11,4.66l-0.19,2.33l-2.13,2.01l-2.81,1.2l-2.63,0.83
l-2.6,1.28l-1.73,2.25l-0.06,1.35l0.43,1.25l0.14,1.16l-0.76,1.13l-1.22,0.61l-5.65,1.58l-3.45,3.12l-0.64,1.63l0.86,1.48
l-2.49,2.54l0.27,3.19l3.89,5.63l-0.82,1.22l-4.02,1.18l-8.35,1.71l-7.84,3.52l0.14-5.09l2.1-7.09l3.63-8.96l7.83-7.7l2.35-5.62
l1.29-5.51l-2.38-3.84l-2.63-3.41l-0.54-6.93l-2.22-5.88l5.98-5.24l4.62-5.36l6.52-3.46l6.59-3.84l1.18-4.6l0.77-4.31l5.25-4.07
l6.7-3.52l4.17-4.09l1.33-3.52l3.5-3.14l5.74-2.14l12.88-8.61l6.96-12.04l2.87-11.76l4.64-10.39l10.45-4.09l12.41-0.01l9.11-3.59
l9.08-3.9l22.81,1.57l8.58-0.16l8.46,0.55l5.7,1.57l5.94,1.81l7.89-0.69l7.4-1.23l13.5-0.52l14.04-0.88l8.75-3.71l-10.21-3.61
l-10.85-3.31l-11.1-3.2l-6.19,0.57l-5.27,0.83l-5.38-1.16l-3.16-3.32l2.9-1.81l3.37-2.24l1.26-1.34l2.28,0.96l13.69,2.36l11,3.22
l6.86,1l5.83-0.18l17.22-2.35l6.77-0.08l31.04,3.4l1.37,1.23l0.47,1.39l0.22,1.7l0.58,1.54l3.5,4.2l4.02,3.23l5,2.43l24.01,5.42
l3.78,1.82l1.48,3.17l1.16,5.25l2.18,5.08l4.84,2.58l5.43,1.8l3.49,3.47l2.04,4.41l0.71,4.51l-5.37,17.72L1733.6,556.04z
 M1552.54,517.77l5.49-2.22l-1.85-3.85l-3.74-6.14l-12.74-0.72l-11.94,2.72l3.25,5.23l0.4,4.41l6.65,1.58l7.01,0.19
L1552.54,517.77"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1420.29,628.22 1425.94,689.44 1425.8,688.28 1420.15,627.06 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1420.29,628.22 1425.94,689.44 1425.18,690.57 1419.53,629.36 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1419.53,629.36 1425.18,690.57 1423.96,691.18 1418.31,629.97 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1422.77,628.29 1428.42,689.5 1427.22,691.29 1421.57,630.07 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1421.57,630.07 1427.22,691.29 1425.03,692.36 1419.38,631.14 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1419.38,631.14 1425.03,692.36 1419.91,693.78 1414.26,632.57 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="1411.1,649.13 1416.75,710.35 1415.93,711.56 1410.28,650.35 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1410.28,650.35 1415.93,711.56 1411.91,712.74 1406.26,651.53 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1413.73,648.96 1419.38,710.18 1417.83,712.48 1412.18,651.27 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1406.26,651.53 1411.91,712.74 1403.56,714.45 1397.91,653.23 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1412.18,651.27 1417.83,712.48 1412.7,714 1407.05,652.78 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1407.05,652.78 1412.7,714 1404.37,715.69 1398.72,654.48 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1397.91,653.23 1403.56,714.45 1395.72,717.97 1390.08,656.75 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1398.72,654.48 1404.37,715.69 1393.18,720.81 1387.54,659.59 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1736.03,555.72l0.47,4.14l-0.62,4.7l-3.94,4.87l-3.31-8.4l-3.33-5.64l-4.69-5.19l-10.69-7.59l-4.89-2.33
l-5.45-1.82l-11.94-2.08l-9.79-2.95l-5.08-0.85l-2.92-0.06l-4.08,0.6l-3.29,0.2l-10.53-0.92l-5.09,0.72l-11.89,3.78l-6.48-0.57
l-1.93-1.56l-2.06-3.64l-1.33-1.37l-2.3-0.83l-7.08-1.42l-5.8-2.71l-5.62,1.13l-10.82,3.72l-0.42,5.13l-11.03,4.28l-13.35,2.45
l-19.57,0.79l-18.75,3.98l-23.65,2.69l-5.75,1.6l-14.14,5.92l-5.58,1.48l-3.74,1.82l-2.57,4.21l-1.14,5.75l-1.07,2.83
l-17.16,2.11l-3.79,2.62l-1.21,3.96l1.73,4.37l1.7,1.71l3.18,2.19l1.56,2.01l0.16,2.58l-3.09,1.6l-3.28,0.8l-6.31,2.22
l-2.49,0.43l-2.05,0.57l-2.75,1.79l-2.3,2.25l-0.97,1.71l-0.54,4.24l1.09,4.64l-0.2,2.81l-2.99,2.71l-3.21,1.34l-2.64,0.85
l-1.77,0.88l-1.27,1.65l-0.03,1.01l0.38,1.12l0.2,1.55l-1.2,1.78l-2.19,1.07l-5.11,1.43l-2.76,2.5l-0.38,1l0.97,1.7l-2.66,2.71
l0.22,2.68l4.08,5.79l-1.55,2.3l-5.13,1.52l-8.33,1.69l-11.19,5.12l0.25-7.9l2.13-7.22l3.73-9.21l7.84-7.71l2.23-5.34l1.2-5.12
l-2.16-3.51l-2.78-3.7l-0.53-6.96l-2.41-6.32l6.5-5.68l4.97-5.64l6.67-3.54l5.89-3.44l1.07-4.09l0.83-4.67l5.94-4.59l6.64-3.49
l3.58-3.59l1.35-3.56l4.1-3.66l6.06-2.26l12.26-8.24l6.57-11.52l2.83-11.72l4.92-11.04l12.03-4.7l12.41-0.01l8.37-3.3l9.89-4.26
l23.68,1.65l8.55-0.16l9.01,0.57l6.09,1.68l5.32,1.61l6.91-0.58l7.64-1.27l13.61-0.52l13.26-0.82l5.17-2.2l-7.34-2.59
l-10.77-3.29l-10.41-3l-5.28,0.47l-5.9,0.94l-6.83-1.47l-4.32-4.54l4.1-2.57l3.26-2.16l2.46-2.71l4.44,1.88l13.35,2.28
l11.06,3.24l6.18,0.87l5.24-0.15l17.39-2.37l7.15-0.09l32.21,3.53l2.1,1.98l0.54,1.58l0.2,1.69l0.53,1.37l3.28,3.91l3.86,3.11
l4.6,2.2l24.01,5.42l4.41,2.12l1.67,3.62l1.15,5.27l2.02,4.65l4.28,2.3l5.63,1.84l3.95,3.96l2.1,4.58l0.75,4.9l-5.3,17.47
L1736.03,555.72z M1732.83,565.15l0.64-0.79l0.59-4.35l-0.46-3.97l-1.17-1.98l5.37-17.72l-0.71-4.51l-2.04-4.41l-3.49-3.47
l-5.43-1.8l-4.84-2.58l-2.17-5.08l-1.16-5.25l-1.48-3.17l-3.78-1.82l-24.01-5.42l-5-2.43l-4.02-3.23l-3.5-4.2l-0.58-1.54
l-0.22-1.7l-0.47-1.39l-1.37-1.23l-31.05-3.4l-6.77,0.08l-17.22,2.35l-5.83,0.18l-6.86-1l-11-3.22l-13.69-2.36l-2.28-0.96
l-1.26,1.34l-3.37,2.24l-2.9,1.81l3.16,3.32l5.38,1.16l5.27-0.83l6.19-0.57l11.1,3.2l10.85,3.31l10.21,3.61l-8.75,3.71
l-14.04,0.88l-13.5,0.52l-7.4,1.23l-7.89,0.69l-5.94-1.81l-5.7-1.57l-8.46-0.55l-8.58,0.16l-22.81-1.57l-9.08,3.9l-9.11,3.59
l-12.41,0.01l-10.45,4.09l-4.64,10.39l-2.87,11.76l-6.96,12.04l-12.88,8.61l-5.74,2.14l-3.5,3.14l-1.33,3.52l-4.17,4.09
l-6.7,3.52l-5.25,4.07l-0.77,4.31l-1.18,4.6l-6.59,3.84l-6.52,3.46l-4.62,5.36l-5.98,5.24l2.22,5.88l0.54,6.93l2.63,3.41
l2.38,3.84l-1.29,5.51l-2.35,5.62l-7.83,7.7l-3.63,8.96l-2.1,7.09l-0.14,5.09l7.84-3.52l8.35-1.71l4.02-1.18l0.82-1.22
l-3.89-5.63l-0.27-3.19l2.49-2.54l-0.86-1.48l0.64-1.63l3.45-3.12l5.65-1.58l1.22-0.61l0.76-1.13l-0.14-1.16l-0.43-1.25
l0.06-1.35l1.73-2.25l2.6-1.28l2.63-0.83l2.81-1.2l2.12-2.01l0.19-2.33l-1.1-4.66l0.54-4.53l1.22-2.11l2.63-2.51l3.46-2.16
l2.53-0.7l2.5-0.44l6.03-2.14l3.26-0.8l1.55-0.85l-0.11-1.79l-1.31-1.59l-3.06-2.08l-1.99-2.04l-1.84-4.85l1.39-4.48l4.88-3.37
l16.33-2.01l0.65-1.75l1.17-5.85l2.91-4.76l4.79-2.3l5.63-1.48l14.11-5.91l6.35-1.74l23.63-2.69l19.09-4.03l19.62-0.78
l12.67-2.35l9.35-3.67l0.45-5.18l12.48-4.28l7.07-1.42l6.61,3.1l6.9,1.37l2.85,1.01l1.69,1.74l2.05,3.65l1.19,0.92l4.73,0.41
l11.58-3.65l5.99-0.79l10.62,0.93l2.63-0.17l4.45-0.63l3.41,0.07l5.55,0.94l9.81,2.96l12.11,2.09l5.71,1.92l5.16,2.47l10.94,7.77
l4.87,5.39l3.42,5.78L1732.83,565.15"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'salyan' ? 'active' : null}`}
            onClick={() => openPopup('salyan')}
          >
            <g>
              <polygon
                className="st108"
                points="1302.8,596.86 1308.45,658.08 1299.25,657.92 1293.6,596.71 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="1288.2,598.68 1293.85,659.9 1293.26,659.7 1287.61,598.48 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1293.6,596.71 1299.25,657.92 1293.85,659.9 1288.2,598.68 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="1312.61,597.39 1318.26,658.6 1308.45,658.08 1302.8,596.86 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1289.53,602.83 1295.18,664.05 1293.26,659.7 1287.61,598.48 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1287.18,603.38 1292.83,664.59 1290.46,659.22 1284.82,598.01 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1321.2,602.48 1326.84,663.69 1318.26,658.6 1312.61,597.39 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1291.54,605.76 1297.19,666.98 1295.18,664.05 1289.53,602.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1289.12,606.15 1294.76,667.37 1292.83,664.59 1287.18,603.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1289.47,607.99 1295.12,669.21 1294.76,667.37 1289.12,606.15 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1291.9,608 1297.55,669.22 1297.19,666.98 1291.54,605.76 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1291.9,608 1297.55,669.22 1297.07,670.56 1291.42,609.35 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1330.6,606.91 1336.25,668.12 1326.84,663.69 1321.2,602.48 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1291.42,609.35 1297.07,670.56 1294.85,674.61 1289.2,613.39 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="1344.81,609.62 1350.46,670.84 1336.25,668.12 1330.6,606.91 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1289.2,613.39 1294.85,674.61 1294.28,676.15 1288.63,614.93 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1288.63,614.93 1294.28,676.15 1294.36,677.75 1288.72,616.54 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1358.56,611.54 1364.21,672.75 1350.46,670.84 1344.81,609.62 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1287.5,619.34 1293.15,680.55 1291.93,678.02 1286.28,616.81 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="1363.07,612.42 1368.72,673.63 1364.21,672.75 1358.56,611.54 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1289.96,619.17 1295.61,680.39 1294.36,677.75 1288.72,616.54 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1367.69,613.6 1373.34,674.81 1368.72,673.63 1363.07,612.42 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1375.8,613.05 1381.45,674.26 1373.34,674.81 1367.69,613.6 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1382.52,612.7 1388.16,673.92 1381.45,674.26 1375.8,613.05 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1289.96,619.17 1295.61,680.39 1295.32,682.5 1289.67,621.29 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1289.67,621.29 1295.32,682.5 1293.41,684.08 1287.77,622.87 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1287.77,622.87 1293.41,684.08 1290.93,685.54 1285.28,624.32 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1386.04,615.15 1391.69,676.37 1388.16,673.92 1382.52,612.7 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1285.28,624.32 1290.93,685.54 1289.39,686.82 1283.74,625.6 			"
              />
            </g>
            <g>
              <polygon
                className="st45"
                points="1283.74,625.6 1289.39,686.82 1289.5,688.23 1283.85,627.02 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1388.49,618.81 1394.14,680.03 1391.69,676.37 1386.04,615.15 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1290.19,632.39 1295.84,693.61 1289.5,688.23 1283.85,627.02 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1288.03,633.16 1293.68,694.38 1287.08,688.8 1281.43,627.58 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1394.99,623.65 1400.64,684.87 1394.14,680.03 1388.49,618.81 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1293.25,635.98 1298.9,697.19 1295.84,693.61 1290.19,632.39 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1290.71,636.33 1296.36,697.54 1293.68,694.38 1288.03,633.16 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1401.16,627.88 1406.81,689.09 1400.64,684.87 1394.99,623.65 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1293.25,635.98 1298.9,697.19 1298.14,699.93 1292.49,638.72 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1292.49,638.72 1298.14,699.93 1293.4,702.81 1287.76,641.59 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1401.16,627.88 1406.81,689.09 1399.29,696.47 1393.64,635.26 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1404.11,627.6 1409.76,688.82 1401.6,696.84 1395.95,635.62 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1287.76,641.59 1293.4,702.81 1286.57,708.36 1280.92,647.14 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1280.92,647.14 1286.57,708.36 1281.36,710.22 1275.71,649.01 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1275.71,649.01 1281.36,710.22 1275.89,710.84 1270.24,649.63 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1270.24,649.63 1275.89,710.84 1268.29,713.88 1262.64,652.66 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1262.64,652.66 1268.29,713.88 1259.42,715.55 1253.77,654.33 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1226.76,657.43 1232.4,718.64 1223.06,718.27 1217.41,657.06 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1236.8,657.64 1242.45,718.86 1232.4,718.64 1226.76,657.43 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1393.64,635.26 1399.29,696.47 1395.58,705.69 1389.93,644.47 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1395.95,635.62 1401.6,696.84 1397.99,705.8 1392.34,644.58 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1253.77,654.33 1259.42,715.55 1258.44,720.02 1252.79,658.8 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="1242.27,660.44 1247.92,721.66 1242.45,718.86 1236.8,657.64 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1217.41,657.06 1223.06,718.27 1212.48,728.01 1206.84,666.8 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1252.79,658.8 1258.44,720.02 1254.61,724.32 1248.96,663.11 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1248.96,663.11 1254.61,724.32 1247.92,721.66 1242.27,660.44 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1389.93,644.47 1395.58,705.69 1393.43,712.91 1387.78,651.7 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1392.34,644.58 1397.99,705.8 1395.86,712.88 1390.21,651.67 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="1387.78,651.7 1393.43,712.91 1393.25,719.11 1387.61,657.89 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1390.21,651.67 1395.86,712.88 1395.67,719.68 1390.02,658.46 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1206.84,666.8 1212.48,728.01 1206.14,738.3 1200.49,677.08 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1387.61,657.89 1393.25,719.11 1385.6,722.62 1379.95,661.41 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1390.02,658.46 1395.67,719.68 1387.17,723.58 1381.52,662.36 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1204.01,681.33 1209.66,742.55 1206.14,738.3 1200.49,677.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1201.71,681.97 1207.36,743.19 1203.5,738.53 1197.85,677.31 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1379.95,661.41 1385.6,722.62 1377.49,727.54 1371.84,666.32 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1381.52,662.36 1387.17,723.58 1379.71,728.09 1374.06,666.87 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="1371.84,666.32 1377.49,727.54 1376.06,731.16 1370.41,669.95 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1291.81,674.37 1297.46,735.59 1287.3,739.37 1281.65,678.15 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1374.06,666.87 1379.71,728.09 1378.3,731.69 1372.65,670.47 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1290.83,676.3 1296.48,737.52 1289.07,740.27 1283.42,679.06 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1207.26,686.48 1212.91,747.69 1209.66,742.55 1204.01,681.33 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1204.62,686.58 1210.27,747.79 1207.36,743.19 1201.71,681.97 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1281.65,678.15 1287.3,739.37 1285.04,741.66 1279.39,680.44 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1370.41,669.95 1376.06,731.16 1371.27,734.26 1365.62,673.05 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1283.42,679.06 1289.07,740.27 1287.14,742.23 1281.5,681.02 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1372.65,670.47 1378.3,731.69 1373.18,735 1367.53,673.79 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1279.39,680.44 1285.04,741.66 1281.86,744.64 1276.21,683.43 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1299.09,681.42 1304.73,742.64 1297.46,735.59 1291.81,674.37 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1296.89,682.17 1302.54,743.39 1296.48,737.52 1290.83,676.3 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1281.5,681.02 1287.14,742.23 1283.6,745.56 1277.95,684.34 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1365.62,673.05 1371.27,734.26 1366.18,738.65 1360.53,677.44 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1207.26,686.48 1212.91,747.69 1208.66,753.3 1203.01,692.09 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1367.53,673.79 1373.18,735 1368.24,739.25 1362.59,678.04 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1276.21,683.43 1281.86,744.64 1272.2,748.16 1266.55,686.94 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1266.55,686.94 1272.2,748.16 1270.5,749.38 1264.85,688.16 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1277.95,684.34 1283.6,745.56 1273.57,749.21 1267.92,688 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1264.85,688.16 1270.5,749.38 1265.92,750.66 1260.27,689.44 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1267.92,688 1273.57,749.21 1272,750.41 1266.36,689.2 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1266.36,689.2 1272,750.41 1267.04,751.81 1261.39,690.6 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="1360.53,677.44 1366.18,738.65 1361.62,743.36 1355.97,682.14 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1203.01,692.09 1208.66,753.3 1203.7,758.3 1198.05,697.09 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1362.59,678.04 1368.24,739.25 1363.81,743.84 1358.16,682.63 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1305.54,688.95 1311.19,750.17 1304.73,742.64 1299.09,681.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1303.41,689.79 1309.06,751 1302.54,743.39 1296.89,682.17 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1355.97,682.14 1361.62,743.36 1358.36,747.57 1352.72,686.36 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1260.27,689.44 1265.92,750.66 1255.13,757.47 1249.48,696.25 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1358.16,682.63 1363.81,743.84 1360.66,747.88 1355.01,686.66 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1310.64,691.12 1316.29,752.34 1311.19,750.17 1305.54,688.95 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1261.39,690.6 1267.04,751.81 1257.42,757.98 1251.77,696.76 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1309.15,692.3 1314.8,753.52 1309.06,751 1303.41,689.79 			"
              />
            </g>
            <g>
              <polygon
                className="st121"
                points="1339.21,689.58 1344.86,750.79 1333.05,751.86 1327.4,690.65 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1352.72,686.36 1358.36,747.57 1357.36,749.88 1351.71,688.67 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1351.71,688.67 1357.36,749.88 1344.86,750.79 1339.21,689.58 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="1316.55,692.33 1322.2,753.54 1316.29,752.34 1310.64,691.12 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1327.4,690.65 1333.05,751.86 1327.1,753.42 1321.45,692.21 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="1321.45,692.21 1327.1,753.42 1322.2,753.54 1316.55,692.33 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1339.29,690.95 1344.94,752.16 1333.35,753.2 1327.7,691.99 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1350.8,690.04 1356.45,751.26 1344.94,752.16 1339.29,690.95 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="1207.78,703.37 1213.43,764.59 1203.7,758.3 1198.05,697.09 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1355.01,686.66 1360.66,747.88 1359.25,751.23 1353.61,690.02 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="1353.61,690.02 1359.25,751.23 1356.45,751.26 1350.8,690.04 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1315.9,693.71 1321.55,754.93 1314.8,753.52 1309.15,692.3 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1321.54,693.57 1327.19,754.78 1321.55,754.93 1315.9,693.71 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1327.7,691.99 1333.35,753.2 1327.19,754.78 1321.54,693.57 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1205.82,704.32 1211.47,765.54 1200.73,758.59 1195.08,697.37 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="1249.48,696.25 1255.13,757.47 1253.48,762.98 1247.83,701.76 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1251.77,696.76 1257.42,757.98 1255.92,762.99 1250.27,701.77 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1247.83,701.76 1253.48,762.98 1252.82,767.66 1247.17,706.45 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1250.27,701.77 1255.92,762.99 1255.2,768.18 1249.55,706.96 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1217.45,710.12 1223.1,771.34 1213.43,764.59 1207.78,703.37 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1243.77,708.34 1249.42,769.56 1244.3,769.81 1238.66,708.6 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1247.17,706.45 1252.82,767.66 1249.42,769.56 1243.77,708.34 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1215.82,711.31 1221.47,772.52 1211.47,765.54 1205.82,704.32 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1244.67,709.67 1250.32,770.89 1244.49,771.17 1238.84,709.95 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1249.55,706.96 1255.2,768.18 1250.32,770.89 1244.67,709.67 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="1226.6,712.03 1232.25,773.25 1223.1,771.34 1217.45,710.12 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1238.66,708.6 1244.3,769.81 1232.25,773.25 1226.6,712.03 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1390.02,658.46l-8.5,3.9l-7.46,4.51l-1.41,3.6l-5.12,3.32l-4.94,4.25l-4.43,4.59l-3.15,4.04l-1.41,3.36
l-2.81,0.02l-11.51,0.91l-11.59,1.04l-6.16,1.58l-5.64,0.14l-6.75-1.41l-5.74-2.52l-6.53-7.61l-6.06-5.87l-7.41,2.76l-1.93,1.96
l-3.55,3.32l-10.03,3.65l-1.57,1.2l-4.97,1.4l-9.61,6.16l-1.5,5.01l-0.72,5.19l-4.88,2.71l-5.84,0.28l-12.35,3.59l-10.67-2.23
l-10-6.98l-10.75-6.95l5.77-5.77l3.77-5.03l-2.91-4.6l-3.86-4.66l6.84-11.06l11.52-10.62l10.94,0.43l10.81,0.23l5.97,3.05
l4.24,1.68l2.22-2.48l1.14-5.19l10.36-1.95l7.55-3.05l5.79-0.65l4.25-1.58l6.41-5.29l4.28-2.62l0.53-1.87l-2.68-3.17l-6.6-5.58
l-0.17-2.23l2.19-1.81l2.55-1.5l1.26-1.08l0.23-1.62l-1.22-2.53l-0.09-1.92l0.65-1.7l2.21-4.03l0.42-1.16l-0.35-1.84l-1.93-2.77
l-2.37-5.37l0.84-1.93l2.76,0.97l4.72-1.73l10.08,0.18l10.72,0.57l9.03,5.37l9.17,4.31l13.6,2.55l13.75,1.92l4.69,0.92l4.14,1.05
l7.48-0.51l7.99-0.41l4.49,3.19l2.45,3.66l6.26,4.62l7.13,4.87l-8.16,8.02l-3.61,8.96l-2.13,7.08L1390.02,658.46z
 M1371.84,666.32l8.12-4.92l7.65-3.51l0.17-6.2l2.15-7.22l3.71-9.22l7.53-7.38l-6.17-4.23l-6.5-4.84l-2.45-3.66l-3.53-2.45
l-6.71,0.34l-8.12,0.55l-4.61-1.18l-4.51-0.88l-13.75-1.92l-14.21-2.71l-9.4-4.43l-8.58-5.09l-9.81-0.53l-9.21-0.15l-5.4,1.98
l-0.59-0.2l1.92,4.35l2.01,2.93l0.36,2.24l-0.48,1.35l-2.22,4.04l-0.57,1.54l0.09,1.6l1.24,2.63l-0.29,2.12l-1.9,1.58l-2.48,1.45
l-1.54,1.28l0.11,1.41l6.34,5.38l3.06,3.58l-0.76,2.74l-4.73,2.88l-6.84,5.55l-5.21,1.87l-5.46,0.62l-7.6,3.03l-8.87,1.67
l-0.98,4.47l-3.83,4.31l-6.69-2.66l-5.47-2.8l-10.04-0.22l-9.35-0.37l-10.57,9.74l-6.35,10.29l3.52,4.25l3.25,5.14l-4.25,5.61
l-4.96,5l9.73,6.28l9.66,6.75l9.15,1.91l12.06-3.44l5.11-0.25l3.4-1.9l0.66-4.69l1.65-5.51l10.79-6.81l4.58-1.28l1.71-1.22
l9.65-3.52l3.19-2.99l2.26-2.29l10.15-3.78l7.28,7.05l6.45,7.53l5.1,2.17l5.91,1.21l4.9-0.12l5.95-1.56l11.81-1.07l12.5-0.91
l1-2.31l3.26-4.22l4.55-4.7l5.09-4.39l4.79-3.1L1371.84,666.32"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: salyanColor ? salyanColor : null }}
                points="1312.61,597.39 1321.2,602.48 1330.6,606.91 1344.81,609.62 1358.56,611.54 1363.07,612.42 
1367.69,613.6 1375.8,613.05 1382.52,612.7 1386.04,615.15 1388.49,618.81 1394.99,623.65 1401.16,627.88 1393.64,635.26 
1389.93,644.47 1387.78,651.7 1387.61,657.89 1379.95,661.41 1371.84,666.32 1370.41,669.95 1365.62,673.05 1360.53,677.44 
1355.97,682.14 1352.72,686.36 1351.71,688.67 1339.21,689.58 1327.4,690.65 1321.45,692.21 1316.55,692.33 1310.64,691.12 
1305.54,688.95 1299.09,681.42 1291.81,674.37 1281.65,678.15 1279.39,680.44 1276.21,683.43 1266.55,686.94 1264.85,688.16 
1260.27,689.44 1249.48,696.25 1247.83,701.76 1247.17,706.45 1243.77,708.34 1238.66,708.6 1226.6,712.03 1217.45,710.12 
1207.78,703.37 1198.05,697.09 1203.01,692.09 1207.26,686.48 1204.01,681.33 1200.49,677.08 1206.84,666.8 1217.41,657.06 
1226.76,657.43 1236.8,657.64 1242.27,660.44 1248.96,663.11 1252.79,658.8 1253.77,654.33 1262.64,652.66 1270.24,649.63 
1275.71,649.01 1280.92,647.14 1287.76,641.59 1292.49,638.72 1293.25,635.98 1290.19,632.39 1283.85,627.02 1283.74,625.6 
1285.28,624.32 1287.77,622.87 1289.67,621.29 1289.96,619.17 1288.72,616.54 1288.63,614.93 1289.2,613.39 1291.42,609.35 
1291.9,608 1291.54,605.76 1289.53,602.83 1287.61,598.48 1288.2,598.68 1293.6,596.71 1302.8,596.86 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1226.49,713.54 1232.13,774.76 1221.47,772.52 1215.82,711.31 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1238.84,709.95 1244.49,771.17 1232.13,774.76 1226.49,713.54 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'susa' ? 'active' : null}`}
            onClick={() => openPopup('susa')}
          >
            <g>
              <polygon
                className="st35"
                points="633.6,568.43 639.25,629.64 632.94,630.79 627.29,569.57 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="627.29,569.57 632.94,630.79 625.89,631.94 620.24,570.73 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="620.24,570.73 625.89,631.94 614.02,631.89 608.37,570.67 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="645.5,568.61 651.15,629.83 639.25,629.64 633.6,568.43 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="657.45,570.9 663.1,632.11 651.15,629.83 645.5,568.61 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="608.37,570.67 614.02,631.89 613.86,637.89 608.22,576.68 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="662.79,572.23 668.44,633.45 663.1,632.11 657.45,570.9 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="662.79,572.23 668.44,633.45 666.09,634.85 660.44,573.63 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="666.87,571.68 672.52,632.9 668.37,635.39 662.72,574.18 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="662.72,574.18 668.37,635.39 667.8,637.11 662.15,575.9 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="660.44,573.63 666.09,634.85 665.19,637.61 659.54,576.4 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="672.16,576.98 677.81,638.19 676.31,638.54 670.67,577.32 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="662.21,578.25 667.86,639.47 665.19,637.61 659.54,576.4 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="608.22,576.68 613.86,637.89 612.39,644.86 606.75,583.64 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="667.67,578.59 673.32,639.8 667.86,639.47 662.21,578.25 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="670.67,577.32 676.31,638.54 673.32,639.8 667.67,578.59 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="606.75,583.64 612.39,644.86 605,649.94 599.35,588.72 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="672.16,576.98 677.81,638.19 677.46,644.58 671.81,583.36 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="674.73,574.97 680.38,636.19 679.9,644.58 674.25,583.36 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="599.35,588.72 605,649.94 598.75,655.13 593.1,593.91 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="671.81,583.36 677.46,644.58 675.25,650.56 669.6,589.34 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="674.25,583.36 679.9,644.58 677.64,650.72 671.99,589.5 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="593.1,593.91 598.75,655.13 597.4,660.2 591.75,598.99 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="669.6,589.34 675.25,650.56 673.49,654.63 667.84,593.41 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="671.99,589.5 677.64,650.72 675.85,654.85 670.2,593.63 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="596.71,600.7 602.36,661.91 597.4,660.2 591.75,598.99 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="637.54,597.27 643.18,658.48 634.58,659.35 628.93,598.13 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="628.93,598.13 634.58,659.35 634.06,659.43 628.41,598.21 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="595.18,601.86 600.83,663.08 594.74,660.99 589.09,599.78 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="637.16,598.67 642.81,659.89 635.19,660.65 629.54,599.44 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="645.9,598.53 651.55,659.75 643.18,658.48 637.54,597.27 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="601.74,602.83 607.39,664.05 602.36,661.91 596.71,600.7 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="667.84,593.41 673.49,654.63 671.51,658.33 665.86,597.12 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="643.77,599.67 649.42,660.89 642.81,659.89 637.16,598.67 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="599.78,603.8 605.43,665.01 600.83,663.08 595.18,601.86 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="670.2,593.63 675.85,654.85 673.67,658.94 668.02,597.73 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="605.11,605.49 610.75,666.7 607.39,664.05 601.74,602.83 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="615.99,605.7 621.64,666.92 610.75,666.7 605.11,605.49 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="628.41,598.21 634.06,659.43 621.64,666.92 615.99,605.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="603.67,606.84 609.32,668.06 605.43,665.01 599.78,603.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="645.42,604.08 651.07,665.29 649.42,660.89 643.77,599.67 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="647.88,603.99 653.53,665.2 651.55,659.75 645.9,598.53 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="665.86,597.12 671.51,658.33 661.02,664.52 655.37,603.3 			"
              />
            </g>
            <g>
              <polygon
                className="st151"
                points="616.82,607.1 622.47,668.31 609.32,668.06 603.67,606.84 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="629.54,599.44 635.19,660.65 622.47,668.31 616.82,607.1 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="668.02,597.73 673.67,658.94 662.71,665.42 657.06,604.2 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="647.88,603.99 653.53,665.2 652.59,669.35 646.94,608.14 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="655.37,603.3 661.02,664.52 652.59,669.35 646.94,608.14 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M671.99,589.5l-1.79,4.13l-2.18,4.1l-10.96,6.48l-13.39,7.67l1.75-7.79l-1.65-4.4l-6.61-1l-7.62,0.76
l-12.72,7.66l-13.15-0.26l-3.89-3.04l-4.59-1.93l-6.09-2.09l1.66-6.24l6.68-5.54l6.97-4.8l1.36-6.45l0.19-7.45l14.41,0.05
l6.46-1.08l6.9-1.22l12.48,0.19l12.36,2.37l8.32,2.08l-4.15,2.49l-0.57,1.72l1.51,1.04l3.56,0.22l2.59-1.06l4.93-1.13l-0.48,8.39
L671.99,589.5z M665.86,597.12l1.97-3.71l1.77-4.07l2.2-5.98l0.35-6.39l-1.49,0.34l-3,1.27l-5.46-0.34l-2.68-1.85l0.91-2.77
l2.35-1.4l-5.34-1.33l-11.95-2.28l-11.9-0.19l-6.31,1.14l-7.05,1.16l-11.87-0.06l-0.15,6.01l-1.47,6.96l-7.39,5.08l-6.25,5.19
l-1.35,5.07l4.96,1.71l5.03,2.14l3.36,2.65l10.89,0.21l12.41-7.49l0.53-0.08l8.6-0.87l8.37,1.27l1.97,5.45l-0.94,4.15l8.43-4.84
L665.86,597.12"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: susaColor ? susaColor : null }}
                points="645.5,568.61 657.45,570.9 662.79,572.23 660.44,573.63 659.54,576.4 662.21,578.25 667.67,578.59 
670.67,577.32 672.16,576.98 671.81,583.36 669.6,589.34 667.84,593.41 665.86,597.12 655.37,603.3 646.94,608.14 647.88,603.99 
645.9,598.53 637.54,597.27 628.93,598.13 628.41,598.21 615.99,605.7 605.11,605.49 601.74,602.83 596.71,600.7 591.75,598.99 
593.1,593.91 599.35,588.72 606.75,583.64 608.22,576.68 608.37,570.67 620.24,570.73 627.29,569.57 633.6,568.43 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="657.06,604.2 662.71,665.42 649.32,673.09 643.67,611.87 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                className="st43"
                points="664.98,572.77 670.63,633.99 668.3,635.38 662.66,574.16 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="668.49,573.65 674.14,634.87 670.63,633.99 664.98,572.77 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="662.66,574.16 668.3,635.38 667.73,637.1 662.09,575.89 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="671.38,575.7 677.03,636.92 674.14,634.87 668.49,573.65 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="671.38,575.7 677.03,636.92 675.39,637.3 669.74,576.08 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="663.59,576.92 669.24,638.14 667.73,637.1 662.09,575.89 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="669.74,576.08 675.39,637.3 672.8,638.36 667.15,577.14 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="667.15,577.14 672.8,638.36 669.24,638.14 663.59,576.92 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M670.33,572.61l5.05,3.6l-4.78,1.09l-3,1.27l-5.46-0.34l-2.68-1.85l0.91-2.77l4.21-2.51L670.33,572.61z
 M669.74,576.08l1.64-0.38l-2.89-2.05l-3.51-0.88l-2.32,1.39l-0.57,1.72l1.51,1.04l3.56,0.22L669.74,576.08"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: susaColor ? susaColor : null }}
                points="664.98,572.77 668.49,573.65 671.38,575.7 669.74,576.08 667.15,577.14 663.59,576.92 
662.09,575.89 662.66,574.16 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="675.38,576.22 681.03,637.43 676.25,638.52 670.6,577.31 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="662.15,578.24 667.8,639.45 665.12,637.6 659.47,576.38 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="670.6,577.31 676.25,638.52 673.26,639.79 667.61,578.57 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="667.61,578.57 673.26,639.79 667.8,639.45 662.15,578.24 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'xocavend' ? 'active' : null}`}
            onClick={() => openPopup('xocavend')}
          >
            <g>
              <polygon
                className="st92"
                points="749.04,555.88 754.69,617.1 742.92,617.62 737.27,556.41 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="737.27,556.41 742.92,617.62 737.18,623.19 731.53,561.98 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="758.63,559.97 764.27,621.19 754.69,617.1 749.04,555.88 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="731.53,561.98 737.18,623.19 728.97,627.92 723.32,566.71 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="723.32,566.71 728.97,627.92 716.42,629.49 710.77,568.28 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="710.77,568.28 716.42,629.49 704.72,631.62 699.07,570.4 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="699.07,570.4 704.72,631.62 700.17,634.05 694.52,572.84 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="771.18,567.19 776.82,628.41 764.27,621.19 758.63,559.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="694.52,572.84 700.17,634.05 696.87,636.29 691.23,575.07 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="806.95,565.18 812.6,626.4 804.19,626.21 798.54,565 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="798.54,565 804.19,626.21 798.67,629.01 793.02,567.79 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="814.65,567.13 820.29,628.35 812.6,626.4 806.95,565.18 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="692.58,578.84 698.22,640.05 696.87,636.29 691.23,575.07 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="690.2,579.36 695.85,640.57 694.22,636.04 688.57,574.83 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="793.02,567.79 798.67,629.01 792.83,632.31 787.18,571.1 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="787.18,571.1 792.83,632.31 776.82,628.41 771.18,567.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="696.08,582.28 701.73,643.5 698.22,640.05 692.58,578.84 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="693.89,583.05 699.54,644.26 695.85,640.57 690.2,579.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="817.47,576.84 823.12,638.06 820.29,628.35 814.65,567.13 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="702.64,588.98 708.28,650.19 701.73,643.5 696.08,582.28 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="700.15,589.45 705.8,650.67 699.54,644.26 693.89,583.05 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="828.91,583.09 834.56,644.3 823.12,638.06 817.47,576.84 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="702.64,588.98 708.28,650.19 707.8,659.16 702.15,597.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="671.32,602.17 676.97,663.39 672.38,659.72 666.73,598.5 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="666.73,598.5 672.38,659.72 662.71,665.43 657.06,604.21 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="702.15,597.94 707.8,659.16 696.29,663.38 690.64,602.16 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="828.91,583.09 834.56,644.3 834.97,652.21 829.32,590.99 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="678.36,605.46 684,666.68 676.97,663.39 671.32,602.17 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="690.64,602.16 696.29,663.38 684,666.68 678.36,605.46 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="831.33,582.38 836.98,643.6 837.47,652.57 831.82,591.35 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="645.72,610.7 651.37,671.91 638.09,672 632.44,610.79 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="657.06,604.21 662.71,665.43 651.37,671.91 645.72,610.7 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="632.44,610.79 638.09,672 629.58,675.69 623.93,614.48 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="777.89,600.12 783.54,661.33 777.02,662.59 771.37,601.37 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="786.64,600.01 792.29,661.23 783.54,661.33 777.89,600.12 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="795.74,599.5 801.39,660.71 792.29,661.23 786.64,600.01 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="807.32,599.23 812.97,660.45 801.39,660.71 795.74,599.5 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="777.86,601.49 783.51,662.71 777.8,663.84 772.15,602.63 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="786.5,601.39 792.15,662.61 783.51,662.71 777.86,601.49 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="818.06,598.63 823.71,659.85 812.97,660.45 807.32,599.23 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="829.32,590.99 834.97,652.21 823.71,659.85 818.06,598.63 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="795.64,600.88 801.29,662.09 792.15,662.61 786.5,601.39 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="771.37,601.37 777.02,662.59 770.5,665.29 764.85,604.08 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="807.2,600.6 812.85,661.82 801.29,662.09 795.64,600.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="626.99,617.59 632.64,678.81 629.58,675.69 623.93,614.48 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="764.85,604.08 770.5,665.29 763.69,666.74 758.04,605.52 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="831.82,591.35 837.47,652.57 824.77,661.16 819.12,599.95 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="819.12,599.95 824.77,661.16 812.85,661.82 807.2,600.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="624.5,618.02 630.15,679.24 626.4,675.43 620.75,614.21 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="758.04,605.52 763.69,666.74 756.68,667.62 751.03,606.4 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="751.03,606.4 756.68,667.62 743.07,667.06 737.42,605.85 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="772.15,602.63 777.8,663.84 771.31,666.54 765.66,605.32 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="765.66,605.32 771.31,666.54 764.13,668.05 758.49,606.84 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="750.76,607.78 756.41,669 743.88,668.49 738.24,607.27 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="758.49,606.84 764.13,668.05 756.41,669 750.76,607.78 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="737.42,605.85 743.07,667.06 734.29,672.41 728.64,611.19 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="738.24,607.27 743.88,668.49 738.47,671.78 732.82,610.56 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="626.99,617.59 632.64,678.81 631.75,684.77 626.1,623.56 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="740.24,613.7 745.89,674.91 734.29,672.41 728.64,611.19 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="746.83,613.79 752.48,675.01 745.89,674.91 740.24,613.7 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="626.1,623.56 631.75,684.77 623.57,689.07 617.92,627.86 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="746.83,613.79 752.48,675.01 746.78,677.73 741.13,616.52 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="741.13,616.52 746.78,677.73 734.57,678.23 728.93,617.02 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="617.92,627.86 623.57,689.07 617.12,690.74 611.47,629.52 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="753.11,612.49 758.76,673.7 747.54,679.08 741.89,617.86 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="741.89,617.86 747.54,679.08 735.27,679.57 729.63,618.36 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="728.93,617.02 734.57,678.23 727.63,681.28 721.98,620.06 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="615.38,630.36 621.03,691.58 617.12,690.74 611.47,629.52 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="729.63,618.36 735.27,679.57 730.12,681.84 724.47,620.62 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="614.35,631.66 620,692.88 610.8,690.91 605.15,629.7 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="621.65,631.49 627.29,692.7 621.03,691.58 615.38,630.36 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="634.56,630.79 640.21,692.01 627.29,692.7 621.65,631.49 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="621.1,632.88 626.75,694.09 620,692.88 614.35,631.66 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="724.47,620.62 730.12,681.84 730.17,684.56 724.52,623.34 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="744.77,621.45 750.41,682.67 742.78,683.71 737.13,622.49 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="633.37,632.23 639.02,693.45 626.75,694.09 621.1,632.88 			"
              />
            </g>
            <g>
              <polygon
                className="st122"
                points="721.98,620.06 727.63,681.28 727.76,685.89 722.11,624.68 			"
              />
            </g>
            <g>
              <polygon
                className="st151"
                points="729.99,624.83 735.63,686.04 727.76,685.89 722.11,624.68 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="737.13,622.49 742.78,683.71 735.63,686.04 729.99,624.83 			"
              />
            </g>
            <g>
              <polygon
                className="st65"
                points="639.05,633.7 644.7,694.91 640.21,692.01 634.56,630.79 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="636.76,634.35 642.41,695.57 639.02,693.45 633.37,632.23 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="744.77,621.45 750.41,682.67 750.39,686.39 744.74,625.18 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="747.22,619.74 752.87,680.96 752.84,686.89 747.19,625.67 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="744.74,625.18 750.39,686.39 746.96,688.16 741.31,626.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="638.44,637.42 644.09,698.63 642.41,695.57 636.76,634.35 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="747.19,625.67 752.84,686.89 748.59,689.07 742.94,627.85 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="641.13,637.46 646.78,698.68 644.7,694.91 639.05,633.7 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="661.05,634.68 666.7,695.89 657.84,698.1 652.19,636.89 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="741.31,626.94 746.96,688.16 743.03,690.63 737.39,629.42 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="742.94,627.85 748.59,689.07 745.46,691.05 739.81,629.83 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="660.27,636.32 665.92,697.53 660.46,698.89 654.81,637.68 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="667.24,638.24 672.89,699.46 666.7,695.89 661.05,634.68 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="665.47,639.31 671.12,700.53 665.92,697.53 660.27,636.32 			"
              />
            </g>
            <g>
              <polygon
                className="st21"
                points="737.39,629.42 743.03,690.63 742.83,694.4 737.18,633.19 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="641.13,637.46 646.78,698.68 641.15,703.82 635.5,642.61 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="739.81,629.83 745.46,691.05 745.26,695.09 739.61,633.87 			"
              />
            </g>
            <g>
              <polygon
                className="st143"
                points="675.42,641.01 681.06,702.23 672.89,699.46 667.24,638.24 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="635.5,642.61 641.15,703.82 635.38,706.92 629.74,645.7 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="673.16,641.94 678.81,703.15 671.12,700.53 665.47,639.31 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="653.41,644.21 659.06,705.43 657.84,698.1 652.19,636.89 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="732.56,637.8 738.21,699.01 731.57,699.11 725.92,637.89 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="737.18,633.19 742.83,694.4 742.26,698.66 736.61,637.45 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="736.61,637.45 742.26,698.66 738.21,699.01 732.56,637.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="673.79,644.02 679.44,705.24 678.81,703.15 673.16,641.94 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="676.32,643.99 681.97,705.2 681.06,702.23 675.42,641.01 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="732.49,639.17 738.14,700.38 733.4,700.45 727.75,639.24 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="738.87,638.63 744.52,699.84 738.14,700.38 732.49,639.17 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="739.61,633.87 745.26,695.09 744.52,699.84 738.87,638.63 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="725.92,637.89 731.57,699.11 730.3,701.84 724.66,640.63 			"
              />
            </g>
            <g>
              <polygon
                className="st82"
                points="638.25,648.68 643.9,709.89 635.38,706.92 629.74,645.7 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="727.75,639.24 733.4,700.45 732.53,702.34 726.88,641.13 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="636.94,649.92 642.58,711.13 631.42,707.23 625.77,646.02 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="676.32,643.99 681.97,705.2 680.31,707.86 674.66,646.65 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="724.66,640.63 730.3,701.84 727.19,704.15 721.55,642.93 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="653.41,644.21 659.06,705.43 652.9,711.24 647.25,650.03 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="647.25,650.03 652.9,711.24 643.9,709.89 638.25,648.68 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="726.88,641.13 732.53,702.34 728.96,704.98 723.31,643.76 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="648.27,651.63 653.91,712.85 642.58,711.13 636.94,649.92 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="655.92,644.4 661.57,705.61 653.91,712.85 648.27,651.63 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="721.55,642.93 727.19,704.15 718.29,709.22 712.64,648.01 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="680.53,651.1 686.18,712.32 680.31,707.86 674.66,646.65 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="723.31,643.76 728.96,704.98 720.78,709.64 715.13,648.43 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="678.57,652.04 684.22,713.26 677.59,708.22 671.94,647.01 			"
              />
            </g>
            <g>
              <polygon
                className="st68"
                points="688.74,655 694.39,716.21 686.18,712.32 680.53,651.1 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="686.52,655.85 692.17,717.06 684.22,713.26 678.57,652.04 			"
              />
            </g>
            <g>
              <polygon
                className="st44"
                points="712.64,648.01 718.29,709.22 718.78,716.19 713.13,654.97 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="715.13,648.43 720.78,709.64 721.24,716.23 715.6,655.01 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="708.68,656.73 714.33,717.94 708.23,717.88 702.58,656.67 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="690.38,658.85 696.03,720.07 694.39,716.21 688.74,655 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="712.06,656.91 717.71,718.12 714.33,717.94 708.68,656.73 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="713.13,654.97 718.78,716.19 717.71,718.12 712.06,656.91 			"
              />
            </g>
            <g>
              <polygon
                className="st100"
                points="696.52,658.79 702.17,720 696.03,720.07 690.38,658.85 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="702.58,656.67 708.23,717.88 702.17,720 696.52,658.79 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M831.33,582.38l0.49,8.97l-12.7,8.6l-11.92,0.65l-11.56,0.28l-9.14,0.51l-8.64,0.1l-5.71,1.13l-6.49,2.7
l-7.18,1.52l-7.73,0.94l-12.52-0.51l-5.42,3.29l8.15,1.77l12.14,0.16l-11.22,5.37l-12.27,0.5l-5.15,2.27l0.05,2.72l5.11,0.09
l6.99-2.25l10.6-1.44l-0.03,5.93l-4.25,2.18l-3.13,1.98l-0.2,4.04l-0.74,4.76l-6.38,0.54l-4.74,0.07l-0.87,1.89l-3.58,2.63
l-8.18,4.67l0.46,6.58l-1.86,3.38l-5.46-0.29l-5.26-0.05l-6.01,2.1l-8.63,0.09l-1.85-4.4l-7.95-3.81l-6.63-5.03l1.86-2.99
l-0.64-2.08l-7.68-2.62l-5.2-2.99l-5.46,1.36l1.11,6.72l-7.66,7.24l-11.33-1.72l-11.17-3.9l7.99-4.28l4.68-4.32l-1.68-3.07
l-3.39-2.12l-12.27,0.65l-6.75-1.22l-9.19-1.97l11.75-3.03l6.83-3.65l0.76-5l-3.75-3.81l11.08-4.8l13.05-0.08l10.5-6.02
l11.81-6.95l6.12,4.86l5.64,2.62l10.69-2.85l10.1-3.72l0.41-7.82l-6.25-6.4l-3.69-3.69l-1.63-4.53l4.26-2.85l5.12-2.77
l12.43-2.25l11.87-1.49l7.5-4.32l6.18-6.04l14.06-0.63l10.38,4.43l12.5,7.19l13.96,3.35l4.6-2.59l6.44-3.25l9.9,0.23l9.1,2.3
l2.91,9.95L831.33,582.38z M818.06,598.63l11.27-7.64l-0.42-7.91l-11.44-6.24l-2.83-9.71l-7.69-1.95l-8.41-0.19l-5.52,2.8
l-5.84,3.3l-16-3.9l-12.55-7.22l-9.59-4.09l-11.77,0.53l-5.74,5.57l-8.21,4.73l-12.55,1.57l-11.7,2.13l-4.55,2.43l-3.3,2.24
l1.35,3.77l3.5,3.44l6.56,6.7l-0.48,8.96l-11.52,4.22l-12.28,3.3l-7.03-3.29l-4.59-3.67l-9.67,5.71l-11.34,6.48l-13.27,0.09
l-8.52,3.69l3.06,3.11l-0.89,5.97l-8.18,4.3l-6.45,1.67l3.91,0.84l6.26,1.12l12.91-0.69l4.49,2.91l2.08,3.77l-5.63,5.15
l-5.77,3.09l8.52,2.98l9,1.35l6.16-5.81l-1.22-7.33l8.86-2.21l6.2,3.57l8.17,2.77l0.9,2.97l-1.66,2.66l5.86,4.45l8.22,3.89
l1.64,3.86l6.13-0.06l6.06-2.12l6.1,0.06l3.38,0.18l1.07-1.93l-0.48-6.97l8.9-5.08l3.11-2.3l1.27-2.73l6.64-0.1l4.05-0.35
l0.57-4.26l0.21-3.77l3.92-2.48l3.43-1.77l0.03-3.72l-7.64,1.04l-7.14,2.33l-7.87-0.15l-0.13-4.62l6.94-3.04l12.2-0.5l5.71-2.73
l-6.59-0.09l-11.6-2.51l8.78-5.34l13.61,0.55l7.01-0.88l6.81-1.45l6.52-2.71l6.52-1.25l8.75-0.1l9.1-0.52l11.58-0.26
L818.06,598.63"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: xocavendColor ? xocavendColor : null }}
                points="749.04,555.88 758.63,559.97 771.18,567.2 787.18,571.1 793.02,567.79 798.54,565 806.95,565.18 
814.65,567.13 817.47,576.84 828.91,583.09 829.32,590.99 818.06,598.63 807.32,599.23 795.74,599.5 786.64,600.01 
777.89,600.12 771.37,601.37 764.85,604.08 758.04,605.52 751.03,606.4 737.42,605.85 728.64,611.19 740.24,613.7 746.83,613.79 
741.13,616.52 728.93,617.02 721.98,620.06 722.11,624.68 729.99,624.83 737.13,622.49 744.77,621.45 744.74,625.18 
741.31,626.94 737.39,629.42 737.18,633.19 736.61,637.45 732.56,637.8 725.92,637.89 724.66,640.63 721.55,642.93 
712.64,648.01 713.13,654.97 712.06,656.91 708.68,656.73 702.58,656.67 696.52,658.79 690.38,658.85 688.74,655 680.53,651.1 
674.66,646.65 676.32,643.99 675.42,641.01 667.24,638.24 661.05,634.68 652.19,636.89 653.41,644.21 647.25,650.03 
638.25,648.68 629.74,645.7 635.5,642.61 641.13,637.46 639.05,633.7 634.56,630.79 621.65,631.49 615.38,630.36 611.47,629.52 
617.92,627.86 626.1,623.56 626.99,617.59 623.93,614.48 632.44,610.79 645.72,610.7 657.06,604.21 666.73,598.5 671.32,602.17 
678.36,605.46 690.64,602.16 702.15,597.94 702.64,588.98 696.08,582.28 692.58,578.84 691.23,575.07 694.52,572.84 
699.07,570.4 710.77,568.28 723.32,566.71 731.53,561.98 737.27,556.41 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="708.27,658.11 713.92,719.32 708.66,719.27 703.01,658.06 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="688.37,660.24 694.02,721.46 692.17,717.06 686.52,655.85 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="713.73,658.39 719.38,719.61 713.92,719.32 708.27,658.11 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="715.6,655.01 721.24,716.23 719.38,719.61 713.73,658.39 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="697,660.16 702.65,721.37 694.02,721.46 688.37,660.24 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="703.01,658.06 708.66,719.27 702.65,721.37 697,660.16 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <polygon
                className="st80"
                points="1158.24,828.89 1163.89,890.11 1159.13,891.21 1153.48,830 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1159.5,834.83 1165.15,896.05 1163.89,890.11 1158.24,828.89 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1153.48,830 1159.13,891.21 1156.3,897.2 1150.65,835.99 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1159.5,834.83 1165.15,896.05 1164.54,897.49 1158.89,836.27 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1161.94,834.88 1167.59,896.1 1166.72,898.14 1161.07,836.92 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1150.65,835.99 1156.3,897.2 1155.84,899.78 1150.19,838.56 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1158.89,836.27 1164.54,897.49 1159.02,900.19 1153.37,838.98 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1150.57,839.41 1156.22,900.62 1155.84,899.78 1150.19,838.56 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1153.37,838.98 1159.02,900.19 1156.22,900.62 1150.57,839.41 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1161.94,834.88l-0.88,2.04l-6.7,3.29l-5.63,0.87l-1-2.38l0.51-2.81l3.24-6.86l8.87-2.04L1161.94,834.88z
 M1153.37,838.98l5.52-2.71l0.61-1.44l-1.26-5.94l-4.76,1.1l-2.83,5.99l-0.46,2.57l0.38,0.85L1153.37,838.98"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: abseronColor }}
                points="1158.24,828.89 1159.5,834.83 1158.89,836.27 1153.37,838.98 1150.57,839.41 1150.19,838.56 
1150.65,835.99 1153.48,830 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1161.07,836.92 1166.72,898.14 1160.01,901.43 1154.36,840.21 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="1154.36,840.21 1160.01,901.43 1154.38,902.3 1148.73,841.09 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1148.73,841.09 1154.38,902.3 1153.38,899.92 1147.73,838.7 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'masalli' ? 'active' : null}`}
            onClick={() => openPopup('masalli')}
          >
            <g>
              <polygon
                className="st46"
                points="1158.91,749.68 1164.56,810.9 1159.98,810.59 1154.33,749.37 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1143.02,752.45 1148.67,813.66 1144.27,813.92 1138.62,752.7 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1154.33,749.37 1159.98,810.59 1158.24,813.52 1152.59,752.3 			"
              />
            </g>
            <g>
              <polygon
                className="st64"
                points="1148.23,753.96 1153.88,815.18 1148.67,813.66 1143.02,752.45 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1152.59,752.3 1158.24,813.52 1153.88,815.18 1148.23,753.96 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1138.62,752.7 1144.27,813.92 1142.12,818 1136.47,756.79 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1176.99,756.77 1182.63,817.99 1164.56,810.9 1158.91,749.68 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1136.02,761.58 1141.67,822.8 1139.57,818.12 1133.92,756.91 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1138.91,762.24 1144.56,823.46 1142.12,818 1136.47,756.79 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1193.04,757.55 1198.69,818.77 1182.63,817.99 1176.99,756.77 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1138.91,762.24 1144.56,823.46 1135.35,825.98 1129.7,764.77 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1129.7,764.77 1135.35,825.98 1126.74,826.96 1121.09,765.75 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1121.09,765.75 1126.74,826.96 1117.69,829.3 1112.04,768.08 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1193.04,757.55 1198.69,818.77 1193.86,824.48 1188.21,763.27 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1196.66,756.33 1202.31,817.54 1196.17,824.81 1190.52,763.6 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1112.04,768.08 1117.69,829.3 1109.38,833.3 1103.73,772.09 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1103.73,772.09 1109.38,833.3 1095.94,838.82 1090.29,777.61 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1090.29,777.61 1095.94,838.82 1081.93,839.68 1076.28,778.46 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1188.21,763.27 1193.86,824.48 1190.77,831.68 1185.12,770.47 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1190.52,763.6 1196.17,824.81 1193.41,831.27 1187.76,770.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1190.39,774.59 1196.04,835.81 1190.77,831.68 1185.12,770.47 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1077.46,786.03 1083.11,847.24 1081.93,839.68 1076.28,778.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1075.13,787.1 1080.78,848.31 1079.26,838.47 1073.61,777.26 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1194.77,778.56 1200.42,839.77 1196.04,835.81 1190.39,774.59 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="1090.98,788.46 1096.63,849.67 1083.11,847.24 1077.46,786.03 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1089.14,789.62 1094.79,850.84 1080.78,848.31 1075.13,787.1 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1194.77,778.56 1200.42,839.77 1198.22,844.35 1192.57,783.14 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1197.43,778.2 1203.08,839.42 1200.61,844.5 1194.96,783.28 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1097.55,796.07 1103.2,857.29 1096.63,849.67 1090.98,788.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1095.08,796.51 1100.73,857.72 1094.79,850.84 1089.14,789.62 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1192.57,783.14 1198.22,844.35 1196.59,849.96 1190.94,788.75 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1194.96,783.28 1200.61,844.5 1199.04,849.95 1193.39,788.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1192.08,795.86 1197.73,857.08 1196.59,849.96 1190.94,788.75 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1097.55,796.07 1103.2,857.29 1102.72,866.1 1097.08,804.88 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1192.08,795.86 1197.73,857.08 1197.4,861.44 1191.76,800.22 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1194.52,795.68 1200.17,856.9 1199.81,861.79 1194.16,800.57 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1163.77,803.78 1169.42,865 1164.1,866.25 1158.45,805.04 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1103.79,810.12 1109.44,871.33 1102.72,866.1 1097.08,804.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1101.89,811.1 1107.54,872.31 1100.25,866.65 1094.6,805.43 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1163.78,805.21 1169.43,866.43 1165.01,867.47 1159.36,806.25 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1175.34,804.93 1180.99,866.15 1169.42,865 1163.77,803.78 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1158.45,805.04 1164.1,866.25 1159.13,868.39 1153.48,807.18 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1174.25,806.23 1179.89,867.44 1169.43,866.43 1163.78,805.21 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1153.48,807.18 1159.13,868.39 1149.8,870.49 1144.15,809.27 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1159.36,806.25 1165.01,867.47 1159.99,869.63 1154.34,808.41 			"
              />
            </g>
            <g>
              <polygon
                className="st33"
                points="1144.15,809.27 1149.8,870.49 1139.5,872.21 1133.86,811 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1183.13,806.88 1188.78,868.09 1180.99,866.15 1175.34,804.93 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1191.76,800.22 1197.4,861.44 1188.78,868.09 1183.13,806.88 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1154.34,808.41 1159.99,869.63 1150.36,871.79 1144.71,810.57 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="1111.56,814.12 1117.21,875.34 1109.44,871.33 1103.79,810.12 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1144.71,810.57 1150.36,871.79 1140.12,873.49 1134.47,812.28 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1194.16,800.57 1199.81,861.79 1190.35,869.05 1184.7,807.83 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1133.86,811 1139.5,872.21 1127.92,875.03 1122.27,813.81 			"
              />
            </g>
            <g>
              <polygon
                className="st81"
                points="1122.27,813.81 1127.92,875.03 1117.21,875.34 1111.56,814.12 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: masalliColor ? masalliColor : null }}
                points="1158.91,749.68 1176.99,756.77 1193.04,757.55 1188.21,763.27 1185.12,770.47 1190.39,774.59 
1194.77,778.56 1192.57,783.14 1190.94,788.75 1192.08,795.86 1191.76,800.22 1183.13,806.88 1175.34,804.93 1163.77,803.78 
1158.45,805.04 1153.48,807.18 1144.15,809.27 1133.86,811 1122.27,813.81 1111.56,814.12 1103.79,810.12 1097.08,804.88 
1097.55,796.07 1090.98,788.46 1077.46,786.03 1076.28,778.46 1090.29,777.61 1103.73,772.09 1112.04,768.08 1121.09,765.75 
1129.7,764.77 1138.91,762.24 1136.47,756.79 1138.62,752.7 1143.02,752.45 1148.23,753.96 1152.59,752.3 1154.33,749.37 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="1183.14,808.55 1188.79,869.76 1179.89,867.44 1174.25,806.23 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1184.7,807.83 1190.35,869.05 1188.79,869.76 1183.14,808.55 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1110.45,815.53 1116.1,876.75 1107.54,872.31 1101.89,811.1 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1122.36,815.18 1128,876.4 1116.1,876.75 1110.45,815.53 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1134.47,812.28 1140.12,873.49 1128,876.4 1122.36,815.18 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1194.52,795.68l-0.36,4.89l-9.47,7.26l-1.56,0.72l-8.89-2.32l-10.46-1.02l-4.42,1.04l-5.02,2.16
l-9.63,2.15l-10.24,1.71l-12.12,2.91l-11.91,0.35l-8.56-4.44l-7.29-5.66l0.49-8.92l-5.94-6.88l-14.01-2.53l-1.52-9.84l16-0.97
l12.75-5.24l8.69-4.15l9.67-2.48l8.59-0.98l6.71-1.85l-2.1-4.67l2.88-5.48l6.95-0.39l4.55,1.32l2.27-0.86l2.16-3.64l7.27,0.48
l18.07,7.09l18.58,0.89l-6.14,7.27l-2.76,6.45l4.72,3.69l4.95,4.46l-2.47,5.08l-1.58,5.45L1194.52,795.68z M1183.01,806.93
l8.74-6.71l0.33-4.36l-1.15-7.12l1.63-5.61l2.21-4.58l-4.39-3.97l-5.27-4.12l3.09-7.2l4.83-5.71l-16.06-0.78l-18.07-7.09
l-4.58-0.31l-1.74,2.93l-4.36,1.66l-5.21-1.51l-4.4,0.25l-2.15,4.08l2.44,5.46l-9.21,2.52l-8.61,0.98l-9.05,2.33l-8.31,4.01
l-13.44,5.52l-14.01,0.85l1.18,7.56l13.52,2.43l6.57,7.62l-0.48,8.81l6.72,5.23l7.76,4.01l10.71-0.31l11.58-2.81l10.29-1.73
l9.33-2.09l4.96-2.14l5.32-1.26l11.58,1.15L1183.01,806.93"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'lerik' ? 'active' : null}`}
            onClick={() => openPopup('lerik')}
          >
            <g>
              <polygon
                className="st85"
                points="1089.4,789.93 1095.05,851.14 1066.89,858.55 1061.24,797.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1095.08,796.51 1100.73,857.72 1095.05,851.14 1089.4,789.93 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1061.24,797.34 1066.89,858.55 1036.92,865.07 1031.28,803.86 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1031.28,803.86 1036.92,865.07 1030.15,867.29 1024.5,806.07 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1024.5,806.07 1030.15,867.29 1022.79,868.97 1017.14,807.75 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1017.14,807.75 1022.79,868.97 1016.19,871.56 1010.54,810.35 			"
              />
            </g>
            <g>
              <polygon
                className="st72"
                points="1095.08,796.51 1100.73,857.72 1100.25,866.18 1094.6,804.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1010.54,810.35 1016.19,871.56 1009.93,874.72 1004.28,813.5 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="1097.55,796.07 1103.2,857.29 1102.68,866.57 1097.03,805.35 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1004.28,813.5 1009.93,874.72 1005.55,878.4 999.9,817.19 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1094.6,804.96 1100.25,866.18 1088.34,874.18 1082.69,812.96 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1097.03,805.35 1102.68,866.57 1090.74,874.59 1085.09,813.38 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="999.9,817.19 1005.55,878.4 1002.05,882.8 996.4,821.59 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="996.4,821.59 1002.05,882.8 996.77,885.22 991.12,824.01 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="991.12,824.01 996.77,885.22 991.2,888.37 985.55,827.16 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="985.55,827.16 991.2,888.37 984.52,889.55 978.87,828.33 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="978.87,828.33 984.52,889.55 984.42,892.25 978.77,831.04 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1085.09,813.38 1090.74,874.59 1089.85,883.35 1084.2,822.14 			"
              />
            </g>
            <g>
              <polygon
                className="st128"
                points="1082.69,812.96 1088.34,874.18 1087.34,884 1081.69,822.79 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="978.77,831.04 984.42,892.25 981.32,896.22 975.67,835 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="975.67,835 981.32,896.22 980.33,899.36 974.68,838.14 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1092.22,828.75 1097.87,889.96 1087.34,884 1081.69,822.79 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1102.05,834.44 1107.7,895.65 1097.87,889.96 1092.22,828.75 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="985.17,845.54 990.82,906.76 980.33,899.36 974.68,838.14 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="982.92,846.22 988.56,907.44 977.72,899.86 972.07,838.64 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="987.78,849.6 993.43,910.81 990.82,906.76 985.17,845.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1104.21,838.98 1109.86,900.19 1107.7,895.65 1102.05,834.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="985.49,850.26 991.14,911.48 988.56,907.44 982.92,846.22 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="991.24,852.53 996.89,913.74 993.43,910.81 987.78,849.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="989.64,853.79 995.29,915 991.14,911.48 985.49,850.26 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="999.07,853.33 1004.72,914.54 996.89,913.74 991.24,852.53 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="1015.34,850.8 1020.99,912.01 1004.72,914.54 999.07,853.33 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1105.68,843.53 1111.33,904.75 1109.86,900.19 1104.21,838.98 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1022.74,851.44 1028.39,912.66 1020.99,912.01 1015.34,850.8 			"
              />
            </g>
            <g>
              <polygon
                className="st109"
                points="1020.5,852.66 1026.15,913.88 1020.6,913.39 1014.96,852.18 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="998.94,854.74 1004.59,915.96 995.29,915 989.64,853.79 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1014.96,852.18 1020.6,913.39 1004.59,915.96 998.94,854.74 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1105.68,843.53 1111.33,904.75 1108.78,908.4 1103.13,847.19 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1108.21,843.52 1113.86,904.74 1111.15,908.64 1105.51,847.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1021.51,856.82 1027.16,918.03 1026.15,913.88 1020.5,852.66 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1023.96,856.61 1029.61,917.83 1028.39,912.66 1022.74,851.44 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1105.51,847.42 1111.15,908.64 1110.18,911.78 1104.53,850.56 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="1103.13,847.19 1108.78,908.4 1107.57,912.33 1101.92,851.11 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1106.82,854.08 1112.47,915.29 1107.57,912.33 1101.92,851.11 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1023.96,856.61 1029.61,917.83 1029.01,923.41 1023.36,862.2 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1025.58,865.16 1031.23,926.38 1029.01,923.41 1023.36,862.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1109.74,857.4 1115.39,918.61 1112.47,915.29 1106.82,854.08 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1023.4,865.93 1029.05,927.14 1026.55,923.64 1020.9,862.43 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1028.99,867.96 1034.64,929.17 1031.23,926.38 1025.58,865.16 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1026.69,868.6 1032.34,929.81 1029.05,927.14 1023.4,865.93 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1109.74,857.4 1115.39,918.61 1110.16,922.87 1104.51,861.66 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1112.59,857.38 1118.24,918.59 1111.88,923.74 1106.23,862.52 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1077.69,867.16 1083.34,928.37 1075.68,928.99 1070.03,867.77 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1104.51,861.66 1110.16,922.87 1102.49,927.11 1096.84,865.9 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1070.03,867.77 1075.68,928.99 1068.81,930.33 1063.16,869.11 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1031.64,872.5 1037.29,933.72 1034.64,929.17 1028.99,867.96 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1077.21,868.55 1082.86,929.77 1076,930.33 1070.35,869.12 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1096.84,865.9 1102.49,927.11 1095.82,928.6 1090.17,867.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1029.37,873.17 1035.02,934.39 1032.34,929.81 1026.69,868.6 			"
              />
            </g>
            <g>
              <polygon
                className="st132"
                points="1083.64,868.2 1089.29,929.41 1083.34,928.37 1077.69,867.16 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1090.17,867.38 1095.82,928.6 1089.29,929.41 1083.64,868.2 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1106.23,862.52 1111.88,923.74 1103.43,928.33 1097.78,867.11 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1070.35,869.12 1076,930.33 1069.52,931.6 1063.87,870.39 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1063.16,869.11 1068.81,930.33 1061.77,932.92 1056.12,871.71 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1097.78,867.11 1103.43,928.33 1096.29,929.9 1090.64,868.68 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1090.64,868.68 1096.29,929.9 1088.92,930.84 1083.28,869.62 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1083.28,869.62 1088.92,930.84 1082.86,929.77 1077.21,868.55 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1063.87,870.39 1069.52,931.6 1063.57,933.81 1057.92,872.59 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1034.89,875.77 1040.54,936.99 1037.29,933.72 1031.64,872.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1032.65,876.47 1038.3,937.68 1035.02,934.39 1029.37,873.17 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1056.12,871.71 1061.77,932.92 1057.4,937.62 1051.75,876.4 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1057.92,872.59 1063.57,933.81 1059.26,938.38 1053.61,877.17 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1037.51,879.61 1043.16,940.83 1040.54,936.99 1034.89,875.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1035.23,880.25 1040.88,941.47 1038.3,937.68 1032.65,876.47 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1051.75,876.4 1057.4,937.62 1051.23,941.54 1045.59,880.33 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1053.61,877.17 1059.26,938.38 1053.12,942.3 1047.47,881.08 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: lerikColor ? lerikColor : null }}
                points="1089.4,789.93 1095.08,796.51 1094.6,804.96 1082.69,812.96 1081.69,822.79 1092.22,828.75 
1102.05,834.44 1104.21,838.98 1105.68,843.53 1103.13,847.19 1101.92,851.11 1106.82,854.08 1109.74,857.4 1104.51,861.66 
1096.84,865.9 1090.17,867.38 1083.64,868.2 1077.69,867.16 1070.03,867.77 1063.16,869.11 1056.12,871.71 1051.75,876.4 
1045.59,880.33 1041.2,884.15 1037.51,879.61 1034.89,875.77 1031.64,872.5 1028.99,867.96 1025.58,865.16 1023.36,862.2 
1023.96,856.61 1022.74,851.44 1015.34,850.8 999.07,853.33 991.24,852.53 987.78,849.6 985.17,845.54 974.68,838.14 975.67,835 
978.77,831.04 978.87,828.33 985.55,827.16 991.12,824.01 996.4,821.59 999.9,817.19 1004.28,813.5 1010.54,810.35 
1017.14,807.75 1024.5,806.07 1031.28,803.86 1061.24,797.34 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1041.2,884.15 1046.85,945.37 1043.16,940.83 1037.51,879.61 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1045.59,880.33 1051.23,941.54 1046.85,945.37 1041.2,884.15 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1108.97,853.29l3.62,4.09l-6.36,5.15l-8.45,4.59l-7.13,1.57l-7.37,0.94l-6.07-1.07l-6.86,0.56l-6.48,1.27
l-5.95,2.21l-4.31,4.57l-6.13,3.91l-6.79,5.91l-5.45-6.74l-2.58-3.78l-3.28-3.3l-2.68-4.57l-3.29-2.67l-2.5-3.5l0.61-5.61
l-1.01-4.16l-5.54-0.48l-16.02,2.57l-9.29-0.95l-4.15-3.53l-2.58-4.04l-10.85-7.58l1.24-3.9l3.03-3.87l0.1-3.51l7.97-1.41
l5.2-2.93l4.83-2.23l3.37-4.18l4.81-4.03l6.54-3.28l7.14-2.79l7.34-1.67l6.79-2.21l30.07-6.55l30.19-7.95l6.86,7.93l-0.52,9.28
l-11.94,8.02l-0.9,8.76l9.86,5.58l10.21,5.92l2.35,4.9l1.6,4.99l-2.71,3.9l-0.97,3.14L1108.97,853.29z M1104.51,861.66l5.23-4.26
l-2.92-3.32l-4.9-2.97l1.21-3.92l2.55-3.65l-1.47-4.55l-2.16-4.54l-9.83-5.69l-10.53-5.96l1-9.82l11.9-8l0.49-8.45l-5.68-6.58
l-28.16,7.41l-29.96,6.52l-6.77,2.21l-7.36,1.68l-6.6,2.6l-6.26,3.15l-4.38,3.69l-3.5,4.4l-5.27,2.42l-5.57,3.15l-6.68,1.18
l-0.1,2.71l-3.09,3.96l-0.99,3.14l10.49,7.4l2.61,4.06l3.45,2.93l7.84,0.8l16.27-2.53l7.4,0.65l1.22,5.17l-0.6,5.59l2.22,2.96
l3.41,2.8l2.65,4.54l3.25,3.27l2.63,3.84l3.69,4.54l4.38-3.83l6.16-3.93l4.37-4.69l7.04-2.6l6.87-1.34l7.66-0.62l5.94,1.04
l6.54-0.82l6.67-1.48L1104.51,861.66"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1047.47,881.08 1053.12,942.3 1046.33,948.21 1040.68,886.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1040.68,886.99 1046.33,948.21 1040.88,941.47 1035.23,880.25 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'neftcala' ? 'active' : null}`}
            onClick={() => openPopup('neftcala')}
          >
            <g>
              <polygon
                className="st133"
                points="1290.83,676.3 1296.48,737.51 1289.07,740.27 1283.42,679.06 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1283.42,679.06 1289.07,740.27 1287.15,742.23 1281.5,681.02 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1296.89,682.17 1302.54,743.39 1296.48,737.51 1290.83,676.3 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1281.5,681.02 1287.15,742.23 1283.6,745.56 1277.95,684.34 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1277.95,684.34 1283.6,745.56 1273.57,749.21 1267.92,687.99 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1267.92,687.99 1273.57,749.21 1272.01,750.41 1266.36,689.2 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1266.36,689.2 1272.01,750.41 1267.04,751.81 1261.39,690.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1303.42,689.79 1309.06,751 1302.54,743.39 1296.89,682.17 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1196.34,698.19 1201.99,759.4 1193.24,763.64 1187.59,702.42 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1261.39,690.6 1267.04,751.81 1257.42,757.97 1251.77,696.76 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1309.15,692.3 1314.8,753.52 1309.06,751 1303.42,689.79 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1339.29,690.95 1344.94,752.16 1333.35,753.2 1327.7,691.99 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1351.13,690.04 1356.78,751.26 1344.94,752.16 1339.29,690.95 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1315.9,693.71 1321.55,754.92 1314.8,753.52 1309.15,692.3 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1321.54,693.57 1327.19,754.78 1321.55,754.92 1315.9,693.71 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1327.7,691.99 1333.35,753.2 1327.19,754.78 1321.54,693.57 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1205.83,704.32 1211.47,765.54 1201.99,759.4 1196.34,698.19 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1187.59,702.42 1193.24,763.64 1184.48,768.77 1178.83,707.56 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="1350.8,690.04 1356.45,751.26 1355.94,753.21 1350.3,692 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1354.19,688.64 1359.84,749.86 1358.39,753.26 1352.74,692.05 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1251.77,696.76 1257.42,757.97 1255.92,762.98 1250.27,701.77 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1351.24,696.34 1356.89,757.56 1355.94,753.21 1350.3,692 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1250.27,701.77 1255.92,762.98 1255.2,768.18 1249.55,706.96 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1178.83,707.56 1184.48,768.77 1178.74,776.27 1173.1,715.06 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1215.82,711.31 1221.47,772.52 1211.47,765.54 1205.83,704.32 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1249.55,706.96 1255.2,768.18 1250.32,770.89 1244.67,709.67 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1244.67,709.67 1250.32,770.89 1244.49,771.17 1238.84,709.95 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1238.84,709.95 1244.49,771.17 1232.14,774.76 1226.49,713.54 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1226.49,713.54 1232.14,774.76 1221.47,772.52 1215.82,711.31 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1173.1,715.06 1178.74,776.27 1177.19,784.21 1171.54,722.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1371,712.51 1376.64,773.72 1356.89,757.56 1351.24,696.34 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1373.19,715.11 1378.83,776.33 1376.64,773.72 1371,712.51 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1374.09,717.72 1379.73,778.93 1378.83,776.33 1373.19,715.11 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1374.09,717.72 1379.73,778.93 1379.35,779.41 1373.71,718.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1175.44,737.07 1181.09,798.29 1177.19,784.21 1171.54,722.99 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1172.91,737.07 1178.56,798.28 1174.73,784.3 1169.08,723.08 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1373.71,718.19 1379.35,779.41 1377.75,780.03 1372.1,718.82 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1376.64,717.72 1382.28,778.94 1381.29,780.24 1375.64,719.03 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1375.64,719.03 1381.29,780.24 1379.69,780.87 1374.04,719.65 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1343.19,722.74 1348.84,783.96 1337.54,785.32 1331.89,724.1 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1342.17,724.23 1347.82,785.45 1338.47,786.58 1332.82,725.37 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1362.86,723.58 1368.51,784.8 1364.6,784.46 1358.96,723.24 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1331.89,724.1 1337.54,785.32 1327.49,790.19 1321.84,728.97 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1256.3,731.53 1261.95,792.75 1243.83,798.11 1238.18,736.9 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1363.75,725.88 1369.4,787.1 1368.51,784.8 1362.86,723.58 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1332.82,725.37 1338.47,786.58 1329.17,791.09 1323.52,729.87 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1372.1,718.82 1377.75,780.03 1372.5,787.1 1366.85,725.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1361.41,726.42 1367.06,787.63 1366.74,786.85 1361.09,725.63 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1255.38,733.29 1261.03,794.5 1244.98,799.25 1239.34,738.03 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1374.04,719.65 1379.69,780.87 1374.88,787.33 1369.23,726.12 			"
              />
            </g>
            <g>
              <polygon
                className="st138"
                points="1358.96,723.24 1364.6,784.46 1364.1,788.58 1358.45,727.37 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1361.09,725.63 1366.74,786.85 1366.54,788.4 1360.89,727.19 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1364.84,727.46 1370.49,788.67 1369.4,787.1 1363.75,725.88 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1263.33,737.01 1268.98,798.22 1261.95,792.75 1256.3,731.53 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1362.61,728.14 1368.26,789.36 1367.06,787.63 1361.41,726.42 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1260.97,737.54 1266.62,798.75 1261.03,794.5 1255.38,733.29 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1366.85,725.88 1372.5,787.1 1371.88,789.3 1366.23,728.09 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1369.23,726.12 1374.88,787.33 1374.34,789.21 1368.7,728 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1366.39,728.65 1372.04,789.86 1371.88,789.3 1366.23,728.09 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1366.39,728.65 1372.04,789.86 1370.49,788.67 1364.84,727.46 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1355.62,729.93 1361.27,791.14 1348.84,783.96 1343.19,722.74 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1353.39,730.65 1359.04,791.87 1347.82,785.45 1342.17,724.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1364.69,729.8 1370.34,791.02 1368.26,789.36 1362.61,728.14 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1359.23,730.97 1364.88,792.19 1364.1,788.58 1358.45,727.37 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1238.18,736.9 1243.83,798.11 1227.31,805.23 1221.66,744.01 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="1321.84,728.97 1327.49,790.19 1321.17,796.75 1315.52,735.54 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="1323.52,729.87 1329.17,791.09 1323.4,797.2 1317.75,735.99 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1239.34,738.03 1244.98,799.25 1228.65,806.29 1223,745.07 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1175.44,737.07 1181.09,798.29 1171.54,811.83 1165.89,750.61 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1357.79,733.19 1363.43,794.41 1361.27,791.14 1355.62,729.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1355.53,733.87 1361.18,795.09 1359.04,791.87 1353.39,730.65 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1370.9,732.64 1376.55,793.86 1370.34,791.02 1364.69,729.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1361.33,734.38 1366.98,795.6 1364.88,792.19 1359.23,730.97 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1265.63,744.02 1271.28,805.23 1268.98,798.22 1263.33,737.01 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1360.34,735.81 1365.99,797.03 1363.43,794.41 1357.79,733.19 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="1221.66,744.01 1227.31,805.23 1212.99,811.15 1207.34,749.94 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1263.34,744.77 1268.99,805.99 1266.62,798.75 1260.97,737.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1358.49,736.93 1364.13,798.14 1361.18,795.09 1355.53,733.87 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="1364.7,736.5 1370.35,797.71 1366.98,795.6 1361.33,734.38 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1223,745.07 1228.65,806.29 1214.39,812.18 1208.74,750.97 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1364.55,736.83 1370.19,798.05 1365.99,797.03 1360.34,735.81 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1364.7,736.5 1370.35,797.71 1370.19,798.05 1364.55,736.83 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="1178.06,755.44 1183.71,816.65 1171.54,811.83 1165.89,750.61 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1367.4,736 1373.05,797.21 1371.74,799.98 1366.1,738.76 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1366.1,738.76 1371.74,799.98 1364.13,798.14 1358.49,736.93 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1272.42,747.93 1278.07,809.14 1271.28,805.23 1265.63,744.02 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="1176.99,756.77 1182.63,817.99 1168.59,812.44 1162.94,751.22 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1270.69,749.01 1276.33,810.23 1268.99,805.99 1263.34,744.77 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1207.34,749.94 1212.99,811.15 1200.18,817.44 1194.53,756.23 			"
              />
            </g>
            <g>
              <polygon
                className="st115"
                points="1194.53,756.23 1200.18,817.44 1183.71,816.65 1178.06,755.44 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1208.74,750.97 1214.39,812.18 1200.8,818.86 1195.15,757.65 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="1195.15,757.65 1200.8,818.86 1182.63,817.99 1176.99,756.77 			"
              />
            </g>
            <g>
              <polygon
                className="st131"
                points="1281.26,750.4 1286.91,811.61 1278.07,809.14 1272.42,747.93 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1279.82,751.59 1285.46,812.8 1276.33,810.23 1270.69,749.01 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="1290.79,754.54 1296.43,815.75 1286.91,811.61 1281.26,750.4 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1289.24,755.7 1294.89,816.92 1285.46,812.8 1279.82,751.59 			"
              />
            </g>
            <g>
              <polygon
                className="st14"
                points="1301.75,757.27 1307.4,818.48 1296.43,815.75 1290.79,754.54 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1299.88,758.37 1305.52,819.58 1294.89,816.92 1289.24,755.7 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1315.52,735.54 1321.17,796.75 1310.58,819.55 1304.93,758.34 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1317.75,735.99 1323.4,797.2 1312.9,819.9 1307.25,758.68 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1303.87,759.6 1309.52,820.81 1307.4,818.48 1301.75,757.27 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: neftcalaColor ? neftcalaColor : null }}
                points="1290.83,676.3 1296.89,682.17 1303.42,689.79 1309.15,692.3 1315.9,693.71 1321.54,693.57 
1327.7,691.99 1339.29,690.95 1350.8,690.04 1350.3,692 1351.24,696.34 1371,712.51 1373.19,715.11 1374.09,717.72 
1373.71,718.19 1372.1,718.82 1366.85,725.88 1366.23,728.09 1366.39,728.65 1364.84,727.46 1363.75,725.88 1362.86,723.58 
1358.96,723.24 1358.45,727.37 1359.23,730.97 1361.33,734.38 1364.7,736.5 1364.55,736.83 1360.34,735.81 1357.79,733.19 
1355.62,729.93 1343.19,722.74 1331.89,724.1 1321.84,728.97 1315.52,735.54 1304.93,758.34 1303.87,759.6 1301.75,757.27 
1290.79,754.54 1281.26,750.4 1272.42,747.93 1265.63,744.02 1263.33,737.01 1256.3,731.53 1238.18,736.9 1221.66,744.01 
1207.34,749.94 1194.53,756.23 1178.06,755.44 1165.89,750.61 1175.44,737.07 1171.54,722.99 1173.1,715.06 1178.83,707.56 
1187.59,702.42 1196.34,698.19 1205.83,704.32 1215.82,711.31 1226.49,713.54 1238.84,709.95 1244.67,709.67 1249.55,706.96 
1250.27,701.77 1251.77,696.76 1261.39,690.6 1266.36,689.2 1267.92,687.99 1277.95,684.34 1281.5,681.02 1283.42,679.06 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="1304.93,758.34 1310.58,819.55 1309.52,820.81 1303.87,759.6 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1307.25,758.68 1312.9,819.9 1309.43,823.92 1303.78,762.7 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1303.78,762.7 1309.43,823.92 1305.52,819.58 1299.88,758.37 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1361.65,730.6l1.91,3.09l3.84,2.31l-1.31,2.77l-7.61-1.84l-2.95-3.05l-2.14-3.22l-11.22-6.42l-9.35,1.13
l-9.3,4.51l-5.77,6.11l-10.5,22.69l-3.47,4.02l-3.9-4.33l-10.64-2.67l-9.42-4.12l-9.13-2.57l-7.34-4.24l-2.38-7.24l-5.58-4.25
l-16.05,4.74l-16.33,7.04l-14.26,5.9l-13.59,6.68l-18.17-0.88l-14.05-5.55l9.97-14.15l-3.83-13.98l1.61-8.24l6.28-8.07l9.05-5.3
l10.76-5.21l11,7.11l9.66,6.75l9.15,1.91l12.06-3.44l5.11-0.25l3.4-1.9l0.66-4.69l1.65-5.51l10.79-6.81l4.58-1.28l1.71-1.22
l9.65-3.52l3.19-2.99l2.26-2.29l10.15-3.78l7.28,7.05l6.45,7.53l5.1,2.17l5.91,1.21l4.9-0.12l5.95-1.56l11.81-1.07l11.68-0.9
l3.3-0.03l-1.45,3.41l0.91,3.82l19.5,15.86l2.42,2.9l1.07,3.09l-0.99,1.3l-1.6,0.62l-4.81,6.47l-0.53,1.88l2.2,4.64l-6.21-2.84
l-2.08-1.66l-1.2-1.73l-0.32-0.78l-0.2,1.55L1361.65,730.6z M1364.55,736.83l0.16-0.33l-3.37-2.12l-2.1-3.41l-0.78-3.6l0.51-4.13
l3.9,0.34l0.89,2.3l1.1,1.58l1.55,1.19l-0.16-0.56l0.62-2.2l5.25-7.07l1.6-0.62l0.38-0.48l-0.9-2.6l-2.19-2.6l-19.76-16.17
l-0.95-4.34l0.84-1.96l-11.84,0.9l-11.59,1.04l-6.16,1.58l-5.64,0.14l-6.75-1.41l-5.74-2.52l-6.53-7.61l-6.06-5.87l-7.41,2.76
l-1.93,1.96l-3.55,3.32l-10.03,3.65l-1.57,1.2l-4.97,1.4l-9.61,6.16l-1.5,5.01l-0.72,5.19l-4.88,2.71l-5.84,0.28l-12.35,3.59
l-10.67-2.23l-10-6.98l-9.49-6.14l-8.75,4.23l-8.75,5.14l-5.74,7.5l-1.55,7.94l3.89,14.08l-9.55,13.54l12.17,4.83l16.47,0.79
l12.8-6.29l14.32-5.93l16.52-7.11l18.12-5.37l7.03,5.47l2.3,7.01l6.79,3.91l8.84,2.47l9.52,4.14l10.96,2.73l2.12,2.33l1.06-1.26
l10.53-22.71l6.38-6.65l10.05-4.87l11.3-1.36l12.43,7.18l2.17,3.26l2.56,2.62L1364.55,736.83"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'lenkeran' ? 'active' : null}`}
            onClick={() => openPopup('lenkeran')}
          >
            <g>
              <polygon
                className="st52"
                points="1255.69,733.37 1261.34,794.59 1245.27,799.33 1239.62,738.11 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1261.24,737.63 1266.89,798.85 1261.34,794.59 1255.69,733.37 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1239.62,738.11 1245.27,799.33 1228.93,806.38 1223.28,745.17 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1263.65,744.86 1269.3,806.07 1266.89,798.85 1261.24,737.63 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="1235.91,747.93 1241.56,809.14 1237.09,809.58 1231.44,748.37 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1223.28,745.17 1228.93,806.38 1214.67,812.26 1209.02,751.05 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1235.55,749.31 1241.2,810.53 1238.19,810.84 1232.54,749.62 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="1231.44,748.37 1237.09,809.58 1234.35,811.29 1228.7,750.08 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="1248.2,748.76 1253.84,809.98 1241.56,809.14 1235.91,747.93 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="1232.54,749.62 1238.19,810.84 1236.18,812.08 1230.53,750.86 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1247.28,750.1 1252.92,811.31 1241.2,810.53 1235.55,749.31 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1228.7,750.08 1234.35,811.29 1232.18,813.36 1226.53,752.15 			"
              />
            </g>
            <g>
              <polygon
                className="st134"
                points="1253.7,750.23 1259.35,811.44 1253.84,809.98 1248.2,748.76 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1230.53,750.86 1236.18,812.08 1234.18,814.02 1228.53,752.81 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1270.97,749.1 1276.62,810.31 1269.3,806.07 1263.65,744.86 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1251.83,751.26 1257.48,812.48 1252.92,811.31 1247.28,750.1 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="1226.53,752.15 1232.18,813.36 1228.45,816.14 1222.8,754.92 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1209.02,751.05 1214.67,812.26 1201.75,818.62 1196.1,757.41 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1228.53,752.81 1234.18,814.02 1230.45,816.79 1224.8,755.58 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="1222.8,754.92 1228.45,816.14 1227.47,817.45 1221.82,756.24 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1257.76,753.23 1263.41,814.45 1259.35,811.44 1253.7,750.23 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1224.8,755.58 1230.45,816.79 1229.8,817.73 1224.15,756.52 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1255.29,753.81 1260.94,815.02 1257.48,812.48 1251.83,751.26 			"
              />
            </g>
            <g>
              <polygon
                className="st124"
                points="1280.1,751.67 1285.75,812.88 1276.62,810.31 1270.97,749.1 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1221.82,756.24 1227.47,817.45 1226.53,819.63 1220.88,758.41 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1224.15,756.52 1229.8,817.73 1228.83,819.97 1223.19,758.75 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1257.76,753.23 1263.41,814.45 1263.36,817.14 1257.71,755.92 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1220.88,758.41 1226.53,819.63 1225.5,820.83 1219.85,759.61 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1217.8,759.64 1223.45,820.85 1221.66,821.19 1216.02,759.98 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1219.85,759.61 1225.5,820.83 1224.79,820.97 1219.14,759.75 			"
              />
            </g>
            <g>
              <polygon
                className="st63"
                points="1219.14,759.75 1224.79,820.97 1223.45,820.85 1217.8,759.64 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1223.19,758.75 1228.83,819.97 1227.17,821.89 1221.52,760.68 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="1196.1,757.41 1201.75,818.62 1196.45,824.89 1190.8,763.68 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1219.11,761.18 1224.76,822.39 1223.57,822.28 1217.92,761.06 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1221.52,760.68 1227.17,821.89 1224.76,822.39 1219.11,761.18 			"
              />
            </g>
            <g>
              <polygon
                className="st149"
                points="1289.54,755.78 1295.19,817 1285.75,812.88 1280.1,751.67 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1300.16,758.45 1305.81,819.66 1295.19,817 1289.54,755.78 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1190.8,763.68 1196.45,824.89 1193.69,831.35 1188.04,770.13 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1257.71,755.92 1263.36,817.14 1260.97,825.87 1255.32,764.65 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1302.75,761.32 1308.4,822.53 1305.81,819.66 1300.16,758.45 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1255.32,764.65 1260.97,825.87 1260.37,827.13 1254.72,765.91 			"
              />
            </g>
            <g>
              <polygon
                className="st142"
                points="1216.02,759.98 1221.66,821.19 1216.31,831.3 1210.66,770.08 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="1217.92,761.06 1223.57,822.28 1218.71,831.44 1213.06,770.23 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1192.74,773.82 1198.39,835.04 1193.69,831.35 1188.04,770.13 			"
              />
            </g>
            <g>
              <polygon
                className="st84"
                points="1267.36,767.31 1273.01,828.53 1260.37,827.13 1254.72,765.91 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1302.75,761.32 1308.4,822.53 1305.72,825.68 1300.07,764.46 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1190.67,774.67 1196.32,835.89 1191.05,831.77 1185.4,770.55 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1305.47,761.16 1311.12,822.37 1307.83,826.23 1302.18,765.02 			"
              />
            </g>
            <g>
              <polygon
                className="st47"
                points="1265.9,768.51 1271.55,829.73 1257.38,828.25 1251.73,767.03 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="1210.66,770.08 1216.31,831.3 1214.87,836.47 1209.22,775.25 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="1213.06,770.23 1218.71,831.44 1217.35,836.38 1211.71,775.16 			"
              />
            </g>
            <g>
              <polygon
                className="st95"
                points="1272.49,769.6 1278.14,830.81 1273.01,828.53 1267.36,767.31 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="1270.23,770.43 1275.88,831.65 1271.55,829.73 1265.9,768.51 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1197.71,778.29 1203.36,839.5 1198.39,835.04 1192.74,773.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1195.06,778.64 1200.7,839.86 1196.32,835.89 1190.67,774.67 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1300.07,764.46 1305.72,825.68 1298.54,832.08 1292.9,770.86 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1302.18,765.02 1307.83,826.23 1300.65,832.66 1295,771.44 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1271.44,774.23 1277.09,835.44 1275.88,831.65 1270.23,770.43 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1273.96,774.15 1279.61,835.37 1278.14,830.81 1272.49,769.6 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1211.61,780.15 1217.26,841.37 1214.87,836.47 1209.22,775.25 			"
              />
            </g>
            <g>
              <polygon
                className="st38"
                points="1292.9,770.86 1298.54,832.08 1295.58,835.3 1289.93,774.08 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="1197.71,778.29 1203.36,839.5 1200.89,844.58 1195.24,783.36 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1295,771.44 1300.65,832.66 1297.83,835.7 1292.18,774.49 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1273.96,774.15 1279.61,835.37 1277.16,839.84 1271.52,778.62 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1211.61,780.15 1217.26,841.37 1213.99,845.98 1208.35,784.77 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="1214.2,780.07 1219.85,841.29 1216.16,846.46 1210.51,785.25 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1195.24,783.36 1200.89,844.58 1199.32,850.03 1193.67,788.81 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1271.52,778.62 1277.16,839.84 1272.7,843.92 1267.05,782.71 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="1289.93,774.08 1295.58,835.3 1291.37,842.47 1285.72,781.25 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1292.18,774.49 1297.83,835.7 1293.64,842.83 1287.99,781.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1267.05,782.71 1272.7,843.92 1267.28,846.89 1261.63,785.68 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1243.18,786.97 1248.82,848.19 1246.12,849.26 1240.47,788.05 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1256.06,786.67 1261.71,847.88 1248.82,848.19 1243.18,786.97 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1261.63,785.68 1267.28,846.89 1261.71,847.88 1256.06,786.67 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1240.47,788.05 1246.12,849.26 1245.25,850.35 1239.6,789.13 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1285.72,781.25 1291.37,842.47 1288.07,846.68 1282.42,785.47 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1287.99,781.62 1293.64,842.83 1290.1,847.32 1284.45,786.1 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="1239.6,789.13 1245.25,850.35 1244.56,852.04 1238.92,790.82 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1192.36,795.96 1198.01,857.17 1196.86,850.06 1191.21,788.84 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1194.8,795.76 1200.45,856.98 1199.32,850.03 1193.67,788.81 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1282.42,785.47 1288.07,846.68 1283.98,849.88 1278.33,788.67 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1238.92,790.82 1244.56,852.04 1242.79,854.06 1237.14,792.85 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1284.45,786.1 1290.1,847.32 1285.49,850.93 1279.84,789.72 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1194.8,795.76 1200.45,856.98 1200.29,859.35 1194.64,798.13 			"
              />
            </g>
            <g>
              <polygon
                className="st36"
                points="1208.35,784.77 1213.99,845.98 1200.29,859.35 1194.64,798.13 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="1210.51,785.25 1216.16,846.46 1201.54,860.73 1195.89,799.52 			"
              />
            </g>
            <g>
              <polygon
                className="st94"
                points="1278.33,788.67 1283.98,849.88 1259.4,856.38 1253.76,795.17 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="1242.34,796.58 1247.99,857.79 1242.79,854.06 1237.14,792.85 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="1253.76,795.17 1259.4,856.38 1247.99,857.79 1242.34,796.58 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: lenkeranColor ? lenkeranColor : null }}
                points="1300.16,758.45 1302.75,761.32 1300.07,764.46 1292.9,770.86 1289.93,774.08 1285.72,781.25 
1282.42,785.47 1278.33,788.67 1253.76,795.17 1242.34,796.58 1237.14,792.85 1238.92,790.82 1239.6,789.13 1240.47,788.05 
1243.18,786.97 1256.06,786.67 1261.63,785.68 1267.05,782.71 1271.52,778.62 1273.96,774.15 1272.49,769.6 1267.36,767.31 
1254.72,765.91 1255.32,764.65 1257.71,755.92 1257.76,753.23 1253.7,750.23 1248.2,748.76 1235.91,747.93 1231.44,748.37 
1228.7,750.08 1226.53,752.15 1222.8,754.92 1221.82,756.24 1220.88,758.41 1219.85,759.61 1219.14,759.75 1217.8,759.64 
1216.02,759.98 1210.66,770.08 1209.22,775.25 1211.61,780.15 1208.35,784.77 1194.64,798.13 1194.8,795.76 1193.67,788.81 
1195.24,783.36 1197.71,778.29 1192.74,773.82 1188.04,770.13 1190.8,763.68 1196.1,757.41 1209.02,751.05 1223.28,745.17 
1239.62,738.11 1255.69,733.37 1261.24,737.63 1263.65,744.86 1270.97,749.1 1280.1,751.67 1289.54,755.78 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="1279.84,789.72 1285.49,850.93 1259.82,857.72 1254.17,796.5 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1241.18,798.1 1246.83,859.31 1239.94,854.36 1234.29,793.14 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1254.17,796.5 1259.82,857.72 1246.83,859.31 1241.18,798.1 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1195.89,799.52 1201.54,860.73 1197.5,863.89 1191.85,802.67 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1302.05,757.35l3.42,3.81l-3.29,3.86l-7.19,6.42l-2.81,3.04l-4.19,7.13l-3.54,4.49l-4.62,3.61l-25.67,6.78
l-12.98,1.59l-6.89-4.95l2.3-2.66l0.69-1.65l1.28-1.62l4.02-1.61l13.49-0.31l4.42-0.81l4.81-2.63l4.06-3.76l2.07-3.86l-1.21-3.79
l-4.33-1.92l-14.17-1.48l1.19-2.51l2.36-8.56l0.02-2.15l-3.46-2.55l-4.56-1.16l-11.73-0.78l-3.01,0.31l-2.01,1.24l-2,1.94
l-3.73,2.77l-0.64,0.94l-0.97,2.24l-1.66,1.92l-2.41,0.5l-1.19-0.12l-4.86,9.17l-1.35,4.93l2.5,4.91l-3.69,5.17l-14.62,14.27
l-4.04,3.15l0.51-6.72l-1.15-7.12l1.63-5.62l2.21-4.58l-4.39-3.97l-5.27-4.12l3.09-7.19l5.66-6.72l13.47-6.61l14.34-5.93
l16.52-7.1l18.12-5.37l7.03,5.47l2.31,7l6.77,3.91l8.87,2.47l9.5,4.13L1302.05,757.35z M1300.07,764.46l2.68-3.15l-2.6-2.87
l-10.62-2.66l-9.44-4.12l-9.13-2.57l-7.32-4.24l-2.4-7.23l-5.56-4.26l-16.07,4.74l-16.34,7.05l-14.26,5.88l-12.93,6.36
l-5.29,6.27l-2.76,6.46l4.69,3.69l4.97,4.47l-2.47,5.08l-1.58,5.45l1.13,6.95l-0.17,2.37l13.71-13.37l3.27-4.61l-2.39-4.9
l1.44-5.17l5.35-10.11l1.78-0.34l1.34,0.12l0.71-0.14l1.03-1.2l0.94-2.18l0.98-1.32l3.73-2.77l2.18-2.07l2.74-1.71l4.47-0.44
l12.28,0.83l5.5,1.46l4.06,3.01l-0.05,2.69l-2.39,8.73l-0.6,1.26l12.64,1.4l5.14,2.29l1.47,4.55l-2.44,4.47l-4.46,4.09
l-5.42,2.97l-5.57,0.99l-12.88,0.3l-2.71,1.08l-0.87,1.08l-0.69,1.69l-1.78,2.03l5.21,3.73l11.41-1.41l24.58-6.5l4.09-3.2
l3.3-4.21l4.21-7.17l2.97-3.22L1300.07,764.46"
              />
            </g>
          </g>
          <g
            className={`st0 ${region == 'lenkeran' ? 'active' : null}`}
            onClick={() => openPopup('lenkeran')}
          >
            <g>
              <polygon
                className="st11"
                points="1102.17,811.18 1107.82,872.39 1101.61,867.58 1095.97,806.36 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1164.06,805.29 1169.71,866.51 1165.29,867.56 1159.64,806.35 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1095.97,806.36 1101.61,867.58 1091.04,874.69 1085.39,813.47 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1174.53,806.31 1180.18,867.52 1169.71,866.51 1164.06,805.29 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1159.64,806.35 1165.29,867.56 1160.27,869.71 1154.63,808.5 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1154.63,808.5 1160.27,869.71 1150.64,871.87 1144.99,810.65 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1181.09,808.02 1186.74,869.23 1180.18,867.52 1174.53,806.31 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1144.99,810.65 1150.64,871.87 1140.38,873.58 1134.73,812.37 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="1110.73,815.61 1116.38,876.83 1107.82,872.39 1102.17,811.18 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="1122.64,815.26 1128.29,876.48 1116.38,876.83 1110.73,815.61 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="1134.73,812.37 1140.38,873.58 1128.29,876.48 1122.64,815.26 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="1172.72,811.87 1178.37,873.08 1168.22,870.51 1162.57,809.29 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="1181.09,808.02 1186.74,869.23 1178.37,873.08 1172.72,811.87 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1185.61,807.62 1191.25,868.84 1178.5,874.7 1172.85,813.48 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="1172.85,813.48 1178.5,874.7 1169.8,872.48 1164.15,811.27 			"
              />
            </g>
            <g>
              <polygon
                className="st120"
                points="1085.39,813.47 1091.04,874.69 1090.14,883.45 1084.49,822.23 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1162.57,809.29 1168.22,870.51 1164.55,878.8 1158.9,817.58 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1164.15,811.27 1169.8,872.48 1166.95,878.93 1161.3,817.72 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="1158.9,817.58 1164.55,878.8 1163.56,882.31 1157.91,821.09 			"
              />
            </g>
            <g>
              <polygon
                className="st151"
                points="1161.3,817.72 1166.95,878.93 1165.99,882.29 1160.34,821.08 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1094.36,827.82 1100,889.03 1090.14,883.45 1084.49,822.23 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1092.49,828.84 1098.14,890.05 1087.64,884.1 1081.99,822.88 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1157.91,821.09 1163.56,882.31 1163.28,885.77 1157.63,824.55 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1160.34,821.08 1165.99,882.29 1165.72,885.57 1160.07,824.36 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1158.23,827.37 1163.88,888.59 1163.28,885.77 1157.63,824.55 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="1158.23,827.37 1163.88,888.59 1157.4,890.07 1151.76,828.86 			"
              />
            </g>
            <g>
              <polygon
                className="st16"
                points="1104.57,833.73 1110.22,894.95 1100,889.03 1094.36,827.82 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1102.32,834.53 1107.97,895.75 1098.14,890.05 1092.49,828.84 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1160.9,828.19 1166.55,889.41 1159.41,891.04 1153.76,829.82 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1106.9,838.62 1112.55,899.83 1110.22,894.95 1104.57,833.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1104.51,839.07 1110.16,900.29 1107.97,895.75 1102.32,834.53 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="1151.76,828.86 1157.4,890.07 1154.16,896.94 1148.51,835.73 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1153.76,829.82 1159.41,891.04 1156.58,897.04 1150.93,835.83 			"
              />
            </g>
            <g>
              <polygon
                className="st78"
                points="1148.51,835.73 1154.16,896.94 1153.63,899.76 1147.98,838.54 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="1150.93,835.83 1156.58,897.04 1156.11,899.62 1150.47,838.4 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1105.98,843.63 1111.62,904.84 1110.16,900.29 1104.51,839.07 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1108.51,843.62 1114.16,904.84 1112.55,899.83 1106.9,838.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1158.24,838.29 1163.89,899.51 1160.29,901.26 1154.64,840.04 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="1154.64,840.04 1160.29,901.26 1154.66,902.14 1149.01,840.93 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1149.01,840.93 1154.66,902.14 1153.63,899.76 1147.98,838.54 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="1108.51,843.62 1114.16,904.84 1111.45,908.73 1105.8,847.52 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="1105.8,847.52 1111.45,908.73 1110.48,911.87 1104.83,850.66 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="1109.27,853.38 1114.92,914.6 1110.48,911.87 1104.83,850.66 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="1107.1,854.17 1112.75,915.39 1107.87,912.42 1102.22,851.21 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1112.89,857.46 1118.54,918.68 1114.92,914.6 1109.27,853.38 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1110.04,857.49 1115.69,918.71 1112.75,915.39 1107.1,854.17 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="1112.89,857.46 1118.54,918.68 1112.16,923.83 1106.51,862.62 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1106.51,862.62 1112.16,923.83 1106.02,927.24 1100.37,866.02 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="1123.42,867.31 1129.07,928.53 1106.02,927.24 1100.37,866.02 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1158.24,838.29 1163.89,899.51 1152.22,927.07 1146.58,865.85 			"
              />
            </g>
            <g>
              <polygon
                className="st97"
                points="1146.58,865.85 1152.22,927.07 1129.07,928.53 1123.42,867.31 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1185.61,807.62l-12.76,5.86l-8.7-2.22l-2.85,6.45l-0.97,3.36l-0.26,3.28l0.83,3.84l-7.14,1.63l-2.84,6
l-0.46,2.57l0.38,0.85l2.8-0.43l8.61-4.22l-13.75,32.5l-25.46,1.6l-27.96-1.58l9.71-5.38l5.25-4.25l-2.94-3.32l-4.88-2.97
l1.21-3.92l2.55-3.65l-1.47-4.55l-2.18-4.54l-9.83-5.69l-10.5-5.96l1-9.82l13.27-8.93l7.81,6.08l7.77,4l10.71-0.3l11.59-2.83
l10.29-1.73l9.33-2.08l4.97-2.15l5.32-1.26l11.58,1.15L1185.61,807.62z M1162.57,809.29l10.15,2.57l8.37-3.85l-6.56-1.71
l-10.46-1.02l-4.43,1.05l-5.01,2.15l-9.63,2.15l-10.27,1.72l-12.09,2.9l-11.91,0.35l-8.56-4.44l-6.2-4.82l-10.57,7.11l-0.9,8.76
l9.86,5.58l10.21,5.92l2.33,4.89l1.61,5l-2.71,3.9l-0.97,3.14l4.44,2.72l3.62,4.08l-6.39,5.16l-6.14,3.4l23.05,1.29l23.15-1.46
l11.67-27.56l-3.6,1.75l-5.63,0.88l-1.03-2.39l0.53-2.81l3.25-6.87l6.47-1.49l-0.6-2.82l0.28-3.46l0.99-3.51L1162.57,809.29"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: lenkeranColor ? lenkeranColor : null }}
                points="1164.06,805.29 1174.53,806.31 1181.09,808.02 1172.72,811.87 1162.57,809.29 1158.9,817.58 
1157.91,821.09 1157.63,824.55 1158.23,827.37 1151.76,828.86 1148.51,835.73 1147.98,838.54 1149.01,840.93 1154.64,840.04 
1158.24,838.29 1146.58,865.85 1123.42,867.31 1100.37,866.02 1106.51,862.62 1112.89,857.46 1109.27,853.38 1104.83,850.66 
1105.8,847.52 1108.51,843.62 1106.9,838.62 1104.57,833.73 1094.36,827.82 1084.49,822.23 1085.39,813.47 1095.97,806.36 
1102.17,811.18 1110.73,815.61 1122.64,815.26 1134.73,812.37 1144.99,810.65 1154.63,808.5 1159.64,806.35 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1123.04,868.69 1128.69,929.91 1100.73,928.33 1095.08,867.11 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1148.5,867.09 1154.15,928.31 1128.69,929.91 1123.04,868.69 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1162.25,834.6 1167.9,895.81 1154.15,928.31 1148.5,867.09 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'astara' ? 'active' : null}`}
            onClick={() => openPopup('astara')}
          >
            <g>
              <polygon
                className="st57"
                points="1077.21,868.55 1082.86,929.77 1075.98,930.32 1070.34,869.1 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1070.34,869.1 1075.98,930.32 1069.5,931.59 1063.85,870.37 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1097.46,867.18 1103.11,928.39 1096.27,929.9 1090.62,868.68 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="1083.25,869.62 1088.9,930.83 1082.86,929.77 1077.21,868.55 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="1090.62,868.68 1096.27,929.9 1088.9,930.83 1083.25,869.62 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="1063.85,870.37 1069.5,931.59 1063.55,933.81 1057.9,872.59 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="1122.74,868.6 1128.39,929.81 1103.11,928.39 1097.46,867.18 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1145.68,867.16 1151.33,928.38 1128.39,929.81 1122.74,868.6 			"
              />
            </g>
            <g>
              <polygon
                className="st52"
                points="1057.9,872.59 1063.55,933.81 1059.26,938.38 1053.61,877.17 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="1053.61,877.17 1059.26,938.38 1053.12,942.3 1047.47,881.08 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1047.47,881.08 1053.12,942.3 1047.98,946.78 1042.33,885.57 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1044.44,888.2 1050.09,949.42 1047.98,946.78 1042.33,885.57 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1042.14,888.8 1047.79,950.02 1045.18,946.8 1039.53,885.59 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1048.64,896.22 1054.29,957.44 1050.09,949.42 1044.44,888.2 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1046.32,896.82 1051.97,958.04 1047.79,950.02 1042.14,888.8 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="1106.51,895.8 1112.16,957.02 1104.65,958.49 1099,897.27 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="1054.94,901.73 1060.59,962.95 1054.29,957.44 1048.64,896.22 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1055.92,901.87 1061.57,963.09 1060.59,962.95 1054.94,901.73 			"
              />
            </g>
            <g>
              <polygon
                className="st40"
                points="1115.77,896.65 1121.42,957.86 1112.16,957.02 1106.51,895.8 			"
              />
            </g>
            <g>
              <polygon
                className="st29"
                points="1106.47,897.21 1112.12,958.43 1105.39,959.75 1099.74,898.54 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="1053.28,902.95 1058.93,964.17 1051.97,958.04 1046.32,896.82 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="1099,897.27 1104.65,958.49 1097.39,961.26 1091.75,900.04 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="1114.88,897.97 1120.53,959.19 1112.12,958.43 1106.47,897.21 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="1091.75,900.04 1097.39,961.26 1089.39,963.12 1083.74,901.91 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="1099.74,898.54 1105.39,959.75 1098.23,962.49 1092.58,901.28 			"
              />
            </g>
            <g>
              <polygon
                className="st117"
                points="1068.38,903.59 1074.03,964.8 1061.57,963.09 1055.92,901.87 			"
              />
            </g>
            <g>
              <polygon
                className="st53"
                points="1075.43,903.09 1081.08,964.31 1074.03,964.8 1068.38,903.59 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="1083.74,901.91 1089.39,963.12 1081.08,964.31 1075.43,903.09 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="1092.58,901.28 1098.23,962.49 1089.93,964.42 1084.28,903.2 			"
              />
            </g>
            <g>
              <polygon
                className="st93"
                points="1067.95,904.98 1073.6,966.19 1058.93,964.17 1053.28,902.95 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="1084.28,903.2 1089.93,964.42 1081.27,965.65 1075.62,904.44 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="1075.62,904.44 1081.27,965.65 1073.6,966.19 1067.95,904.98 			"
              />
            </g>
            <g>
              <polygon
                className="st70"
                points="1131.7,900.13 1137.35,961.35 1121.42,957.86 1115.77,896.65 			"
              />
            </g>
            <g>
              <polygon
                className="st147"
                points="1145.68,867.16 1151.33,928.38 1137.35,961.35 1131.7,900.13 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: astaraColor ? astaraColor : null }}
                points="1145.68,867.16 1131.7,900.13 1115.77,896.65 1106.51,895.8 1099,897.27 1091.75,900.04 
1083.74,901.91 1075.43,903.09 1068.38,903.59 1055.92,901.87 1054.94,901.73 1048.64,896.22 1044.44,888.2 1042.33,885.57 
1047.47,881.08 1053.61,877.17 1057.9,872.59 1063.85,870.37 1070.34,869.1 1077.21,868.55 1083.25,869.62 1090.62,868.68 
1097.46,867.18 1122.74,868.6 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="1131.97,901.73 1137.62,962.94 1120.53,959.19 1114.88,897.97 			"
              />
            </g>
            <g>
              <polygon
                className="st86"
                points="1133.38,901.99 1139.03,963.2 1137.62,962.94 1131.97,901.73 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="1148.8,865.6 1154.45,926.81 1139.03,963.2 1133.38,901.99 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M1148.8,865.6l-15.42,36.39l-1.41-0.26l-17.1-3.75l-8.41-0.76l-6.73,1.32l-7.16,2.74l-8.3,1.93l-8.66,1.23
l-7.67,0.54l-14.67-2.02l-6.96-6.13l-4.18-8.02l-2.61-3.21l6.04-5.26l6.16-3.93l4.37-4.69l7.04-2.6l6.87-1.34l7.66-0.62
l5.97,1.04l6.51-0.82l7.24-1.61l25.73,1.46L1148.8,865.6z M1115.77,896.65l15.93,3.49l13.98-32.97l-22.94,1.44l-25.28-1.42
l-6.84,1.51l-7.37,0.94l-6.05-1.06l-6.87,0.55l-6.48,1.27l-5.96,2.22l-4.29,4.57l-6.13,3.91l-5.14,4.49l2.11,2.63l4.2,8.02
l6.3,5.51l0.98,0.14l12.46,1.72l7.05-0.49l8.31-1.19l8.01-1.86l7.26-2.77l7.51-1.47L1115.77,896.65"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'fuzuli' ? 'active' : null}`}
            onClick={() => openPopup('fuzuli')}
          >
            <g>
              <polygon
                className="st34"
                points="777.86,601.48 783.51,662.7 777.8,663.83 772.15,602.62 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="786.49,601.38 792.14,662.6 783.51,662.7 777.86,601.48 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="795.64,600.87 801.29,662.09 792.14,662.6 786.49,601.38 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="807.2,600.59 812.85,661.81 801.29,662.09 795.64,600.87 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="818.59,599.97 824.24,661.19 812.85,661.81 807.2,600.59 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="772.15,602.62 777.8,663.83 771.31,666.53 765.66,605.31 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="841.67,598.53 847.32,659.75 824.24,661.19 818.59,599.97 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="765.66,605.31 771.31,666.53 764.13,668.04 758.48,606.83 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="750.76,607.77 756.41,668.99 743.88,668.48 738.23,607.26 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="758.48,606.83 764.13,668.04 756.41,668.99 750.76,607.77 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="863.84,597.47 869.49,658.69 847.32,659.75 841.67,598.53 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="738.23,607.26 743.88,668.48 738.47,671.77 732.82,610.55 			"
              />
            </g>
            <g>
              <polygon
                className="st69"
                points="740.97,612.33 746.62,673.54 738.47,671.77 732.82,610.55 			"
              />
            </g>
            <g>
              <polygon
                className="st136"
                points="753.11,612.48 758.75,673.7 746.62,673.54 740.97,612.33 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="740.24,613.69 745.89,674.9 734.28,672.4 728.64,611.18 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="746.83,613.78 752.48,675 745.89,674.9 740.24,613.69 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="865.44,603.8 871.09,665.02 869.49,658.69 863.84,597.47 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="753.11,612.48 758.75,673.7 747.54,679.07 741.89,617.85 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="741.89,617.85 747.54,679.07 735.27,679.56 729.62,618.35 			"
              />
            </g>
            <g>
              <polygon
                className="st15"
                points="874.28,606.09 879.93,667.31 871.09,665.02 865.44,603.8 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="729.62,618.35 735.27,679.56 730.12,681.83 724.47,620.61 			"
              />
            </g>
            <g>
              <polygon
                className="st145"
                points="747.21,619.73 752.86,680.95 742.26,682.39 736.61,621.18 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="724.47,620.61 730.12,681.83 730.16,684.55 724.51,623.33 			"
              />
            </g>
            <g>
              <polygon
                className="st101"
                points="744.76,621.45 750.41,682.66 742.77,683.7 737.12,622.49 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="736.61,621.18 742.26,682.39 735.27,684.64 729.62,623.42 			"
              />
            </g>
            <g>
              <polygon
                className="st140"
                points="729.62,623.42 735.27,684.64 730.16,684.55 724.51,623.33 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="878.68,610.93 884.33,672.14 879.93,667.31 874.28,606.09 			"
              />
            </g>
            <g>
              <polygon
                className="st151"
                points="729.98,624.82 735.63,686.04 727.76,685.88 722.11,624.67 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="737.12,622.49 742.77,683.7 735.63,686.04 729.98,624.82 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="747.21,619.73 752.86,680.95 752.84,686.88 747.19,625.66 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="747.19,625.66 752.84,686.88 748.59,689.06 742.94,627.84 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="742.94,627.84 748.59,689.06 745.45,691.04 739.81,629.83 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="882.15,617.11 887.8,678.33 884.33,672.14 878.68,610.93 			"
              />
            </g>
            <g>
              <polygon
                className="st25"
                points="739.81,629.83 745.45,691.04 745.26,695.08 739.61,633.86 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="732.49,639.16 738.14,700.37 733.4,700.45 727.75,639.23 			"
              />
            </g>
            <g>
              <polygon
                className="st42"
                points="739.61,633.86 745.26,695.08 744.52,699.84 738.87,638.62 			"
              />
            </g>
            <g>
              <polygon
                className="st8"
                points="738.87,638.62 744.52,699.84 738.14,700.37 732.49,639.16 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="727.75,639.23 733.4,700.45 732.53,702.33 726.88,641.12 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="895.74,627.17 901.39,688.38 887.8,678.33 882.15,617.11 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="726.88,641.12 732.53,702.33 729.71,704.41 724.06,643.19 			"
              />
            </g>
            <g>
              <polygon
                className="st112"
                points="731.94,646.56 737.59,707.78 729.71,704.41 724.06,643.19 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="730.25,647.65 735.9,708.87 726.31,704.77 720.67,643.56 			"
              />
            </g>
            <g>
              <polygon
                className="st54"
                points="895.74,627.17 901.39,688.38 897.73,696.17 892.08,634.96 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="892.08,634.96 897.73,696.17 892.62,697.22 886.97,636 			"
              />
            </g>
            <g>
              <polygon
                className="st56"
                points="898.41,626.74 904.05,687.95 899.77,697.08 894.12,635.86 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="894.12,635.86 899.77,697.08 899.03,697.32 893.38,636.11 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="893.38,636.11 899.03,697.32 893.38,698.47 887.73,637.26 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="740.39,650.86 746.04,712.07 737.59,707.78 731.94,646.56 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="738.16,651.68 743.81,712.9 735.9,708.87 730.25,647.65 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="886.97,636 892.62,697.22 873.57,704.6 867.92,643.38 			"
              />
            </g>
            <g>
              <polygon
                className="st27"
                points="857.73,644.28 863.38,705.49 841.47,707.67 835.82,646.45 			"
              />
            </g>
            <g>
              <polygon
                className="st104"
                points="867.92,643.38 873.57,704.6 869.55,705.63 863.9,644.42 			"
              />
            </g>
            <g>
              <polygon
                className="st49"
                points="863.9,644.42 869.55,705.63 863.38,705.49 857.73,644.28 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="887.73,637.26 893.38,698.47 874.59,705.77 868.94,644.55 			"
              />
            </g>
            <g>
              <polygon
                className="st1"
                points="857.5,645.66 863.15,706.87 841.82,708.99 836.17,647.77 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="835.82,646.45 841.47,707.67 827.73,710.42 822.09,649.21 			"
              />
            </g>
            <g>
              <polygon
                className="st85"
                points="868.94,644.55 874.59,705.77 869.79,707.02 864.14,645.81 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="864.14,645.81 869.79,707.02 863.15,706.87 857.5,645.66 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="743.12,657.25 748.77,718.46 746.04,712.07 740.39,650.86 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="740.77,657.77 746.42,718.98 743.81,712.9 738.16,651.68 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="836.17,647.77 841.82,708.99 828.55,711.65 822.9,650.44 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="822.09,649.21 827.73,710.42 816.48,714.04 810.83,652.83 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="822.9,650.44 828.55,711.65 817.63,715.17 811.98,653.96 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="746.18,662.23 751.83,723.45 748.77,718.46 743.12,657.25 			"
              />
            </g>
            <g>
              <polygon
                className="st144"
                points="753.38,662.23 759.03,723.44 751.83,723.45 746.18,662.23 			"
              />
            </g>
            <g>
              <polygon
                className="st91"
                points="762.37,661.89 768.02,723.1 759.03,723.44 753.38,662.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="744.34,663.6 749.99,724.82 746.42,718.98 740.77,657.77 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="753.17,663.61 758.82,724.82 749.99,724.82 744.34,663.6 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="761.24,663.3 766.89,724.51 758.82,724.82 753.17,663.61 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="810.83,652.83 816.48,714.04 797.51,723.18 791.86,661.97 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="811.98,653.96 817.63,715.17 798.91,724.21 793.26,663 			"
              />
            </g>
            <g>
              <polygon
                className="st76"
                points="770.04,666.11 775.69,727.32 768.02,723.1 762.37,661.89 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="767.84,666.87 773.48,728.08 766.89,724.51 761.24,663.3 			"
              />
            </g>
            <g>
              <polygon
                className="st55"
                points="791.86,661.97 797.51,723.18 785.82,727.77 780.17,666.56 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="771.66,668.31 777.31,729.53 775.69,727.32 770.04,666.11 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="780.17,666.56 785.82,727.77 777.31,729.53 771.66,668.31 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M898.41,626.74l-4.29,9.13l-0.74,0.24l-5.65,1.15l-18.79,7.3l-4.81,1.25l-6.63-0.15l-21.33,2.11
l-13.27,2.67l-10.92,3.52L793.26,663l-12.3,4.8l-10.78,2.23l-2.35-3.16l-6.59-3.57l-8.07,0.31l-8.83-0.01l-3.57-5.84l-2.61-6.08
l-7.91-4.03l-9.59-4.09l3.99-2.94l1.27-2.73l6.64-0.1l4.05-0.35l0.57-4.26l0.21-3.77l3.92-2.48l3.43-1.77l0.03-3.72l-7.64,1.04
l-7.14,2.33l-7.87-0.15l-0.13-4.62l6.94-3.04l12.2-0.5l5.71-2.73l-6.59-0.09l-11.6-2.51l8.78-5.34l13.61,0.55l7.01-0.88
l6.81-1.45l6.52-2.71l6.52-1.25l8.75-0.1l9.1-0.52l11.58-0.26l11.31-0.63l23.07-1.43l24.28-1.17l1.73,6.81l8.44,2.19l4.8,5.29
l3.48,6.17L898.41,626.74z M886.97,636l5.11-1.05l3.66-7.79l-13.59-10.05l-3.47-6.18l-4.4-4.84l-8.84-2.29l-1.61-6.33
l-22.17,1.06l-23.08,1.44l-11.39,0.62l-11.56,0.28l-9.14,0.51l-8.64,0.1l-5.71,1.13l-6.49,2.7l-7.18,1.52l-7.73,0.94l-12.52-0.51
l-5.42,3.29l8.15,1.77l12.14,0.16l-11.22,5.37l-12.27,0.5l-5.15,2.27l0.05,2.72l5.11,0.09l6.99-2.25l10.6-1.44l-0.03,5.93
l-4.25,2.18l-3.13,1.98l-0.2,4.04l-0.74,4.76l-6.38,0.54l-4.74,0.07l-0.87,1.89l-2.82,2.07l7.89,3.37l8.45,4.3l2.73,6.39
l3.06,4.99l7.2-0.01l8.99-0.34l7.66,4.22l1.62,2.21l8.51-1.76l11.69-4.59l18.97-9.14l11.25-3.62l13.73-2.75l21.92-2.18l6.17,0.14
l4.02-1.04L886.97,636"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: fuzuliColor ? fuzuliColor : null }}
                points="863.84,597.47 865.44,603.8 874.28,606.09 878.68,610.93 882.15,617.11 895.74,627.17 
892.08,634.96 886.97,636 867.92,643.38 863.9,644.42 857.73,644.28 835.82,646.45 822.09,649.21 810.83,652.83 791.86,661.97 
780.17,666.56 771.66,668.31 770.04,666.11 762.37,661.89 753.38,662.23 746.18,662.23 743.12,657.25 740.39,650.86 
731.94,646.56 724.06,643.19 726.88,641.12 727.75,639.23 732.49,639.16 738.87,638.62 739.61,633.86 739.81,629.83 
742.94,627.84 747.19,625.66 747.21,619.73 736.61,621.18 729.62,623.42 724.51,623.33 724.47,620.61 729.62,618.35 
741.89,617.85 753.11,612.48 740.97,612.33 732.82,610.55 738.23,607.26 750.76,607.77 758.48,606.83 765.66,605.31 
772.15,602.62 777.86,601.48 786.49,601.38 795.64,600.87 807.2,600.59 818.59,599.97 841.67,598.53 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="793.26,663 798.91,724.21 786.61,729.02 780.96,667.8 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="770.18,670.03 775.83,731.25 773.48,728.08 767.84,666.87 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="780.96,667.8 786.61,729.02 775.83,731.25 770.18,670.03 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'cebrayil' ? 'active' : null}`}
            onClick={() => openPopup('cebrayil')}
          >
            <g>
              <polygon
                className="st85"
                points="660.25,636.3 665.9,697.52 660.44,698.88 654.8,637.66 			"
              />
            </g>
            <g>
              <polygon
                className="st17"
                points="665.46,639.3 671.1,700.51 665.9,697.52 660.25,636.3 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="673.14,641.92 678.79,703.13 671.1,700.51 665.46,639.3 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="653.4,644.2 659.04,705.41 657.82,698.09 652.17,636.87 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="655.91,644.38 661.56,705.59 660.44,698.88 654.8,637.66 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="673.78,644 679.42,705.22 678.79,703.13 673.14,641.92 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="627.64,646.66 633.28,707.88 625.97,710.58 620.32,649.36 			"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="636.92,649.9 642.57,711.11 633.28,707.88 627.64,646.66 			"
              />
            </g>
            <g>
              <polygon
                className="st103"
                points="676.3,643.97 681.95,705.18 680.29,707.85 674.64,646.63 			"
              />
            </g>
            <g>
              <polygon
                className="st102"
                points="673.78,644 679.42,705.22 677.57,708.21 671.92,646.99 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="648.25,651.62 653.9,712.83 642.57,711.11 636.92,649.9 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="655.91,644.38 661.56,705.59 653.9,712.83 648.25,651.62 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="620.32,649.36 625.97,710.58 621.46,716.42 615.81,655.21 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="722.37,644.29 728.02,705.5 720.76,709.63 715.11,648.41 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="678.56,652.02 684.2,713.24 677.57,708.21 671.92,646.99 			"
              />
            </g>
            <g>
              <polygon
                className="st20"
                points="730.24,647.64 735.89,708.86 728.02,705.5 722.37,644.29 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="617.6,659.88 623.24,721.09 621.46,716.42 615.81,655.21 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="615.23,660.39 620.87,721.6 618.9,716.43 613.25,655.21 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="686.5,655.83 692.15,717.05 684.2,713.24 678.56,652.02 			"
              />
            </g>
            <g>
              <polygon
                className="st119"
                points="738.14,651.68 743.79,712.89 735.89,708.86 730.24,647.64 			"
              />
            </g>
            <g>
              <polygon
                className="st5"
                points="715.11,648.41 720.76,709.63 721.23,716.21 715.58,654.99 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="620.83,665.06 626.48,726.28 623.24,721.09 617.6,659.88 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="618.29,665.32 623.94,726.54 620.87,721.6 615.23,660.39 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="708.25,658.09 713.9,719.31 708.64,719.25 703,658.04 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="688.35,660.23 694,721.44 692.15,717.05 686.5,655.83 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="715.58,654.99 721.23,716.21 719.36,719.59 713.71,658.38 			"
              />
            </g>
            <g>
              <polygon
                className="st150"
                points="713.71,658.38 719.36,719.59 713.9,719.31 708.25,658.09 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="703,658.04 708.64,719.25 702.63,721.36 696.99,660.14 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="696.99,660.14 702.63,721.36 694,721.44 688.35,660.23 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="740.75,657.76 746.4,718.97 743.79,712.89 738.14,651.68 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="620.83,665.06 626.48,726.28 624.18,733.27 618.53,672.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="744.32,663.59 749.97,724.81 746.4,718.97 740.75,657.76 			"
              />
            </g>
            <g>
              <polygon
                className="st79"
                points="753.16,663.6 758.8,724.82 749.97,724.81 744.32,663.59 			"
              />
            </g>
            <g>
              <polygon
                className="st92"
                points="761.23,663.29 766.88,724.5 758.8,724.82 753.16,663.6 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="620.01,677.99 625.66,739.2 624.18,733.27 618.53,672.05 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="617.69,678.76 623.34,739.98 621.73,733.28 616.08,672.07 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="767.82,666.86 773.47,728.08 766.88,724.5 761.23,663.29 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="769.26,668.8 774.91,730.01 773.47,728.08 767.82,666.86 			"
              />
            </g>
            <g>
              <polygon
                className="st12"
                points="627.79,681.95 633.44,743.16 625.66,739.2 620.01,677.99 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="769.26,668.8 774.91,730.01 772.67,730.48 767.02,669.27 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="625.98,682.97 631.63,744.19 623.34,739.98 617.69,678.76 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="772.5,669.45 778.15,730.67 776.85,731.03 771.2,669.81 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="767.02,669.27 772.67,730.48 741.55,734.33 735.9,673.11 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="771.2,669.81 776.85,731.03 773.09,731.79 767.44,670.58 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="767.44,670.58 773.09,731.79 742.02,735.63 736.37,674.42 			"
              />
            </g>
            <g>
              <polygon
                className="st105"
                points="735.9,673.11 741.55,734.33 725.89,737.87 720.24,676.65 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="736.37,674.42 742.02,735.63 726.75,739.1 721.1,677.89 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="720.24,676.65 725.89,737.87 721.75,739.62 716.1,678.41 			"
              />
            </g>
            <g>
              <polygon
                className="st125"
                points="635.48,686.78 641.13,747.99 633.44,743.16 627.79,681.95 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="721.1,677.89 726.75,739.1 723.59,740.47 717.94,679.26 			"
              />
            </g>
            <g>
              <polygon
                className="st148"
                points="633.28,687.53 638.93,748.74 631.63,744.19 625.98,682.97 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="716.1,678.41 721.75,739.62 719.77,741.67 714.12,680.45 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="717.94,679.26 723.59,740.47 721.55,742.49 715.9,681.27 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="714.12,680.45 719.77,741.67 705.35,750.01 699.7,688.8 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="641.81,695.08 647.46,756.3 641.13,747.99 635.48,686.78 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="639.37,695.36 645.02,756.57 638.93,748.74 633.28,687.53 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="715.9,681.27 721.55,742.49 707.13,750.84 701.48,689.63 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="641.81,695.08 647.46,756.3 647.33,761.47 641.68,700.25 			"
              />
            </g>
            <g>
              <polygon
                className="st48"
                points="645.97,699.63 651.61,760.85 647.33,761.47 641.68,700.25 			"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="650.67,699.97 656.32,761.18 651.61,760.85 645.97,699.63 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="699.7,688.8 705.35,750.01 696.86,757.5 691.21,696.28 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="701.48,689.63 707.13,750.84 698.76,758.22 693.11,697.01 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="645.63,701.01 651.28,762.22 644.85,763.24 639.2,702.02 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="691.21,696.28 696.86,757.5 694.76,758.84 689.11,697.63 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="649.56,701.29 655.21,762.5 651.28,762.22 645.63,701.01 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="693.11,697.01 698.76,758.22 696.27,759.83 690.62,698.62 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="689.11,697.63 694.76,758.84 691.18,760.46 685.53,699.24 			"
              />
            </g>
            <g>
              <polygon
                className="st126"
                points="657.77,702.74 663.42,763.96 656.32,761.18 650.67,699.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="690.62,698.62 696.27,759.83 692.01,761.7 686.36,700.49 			"
              />
            </g>
            <g>
              <polygon
                className="st127"
                points="656.38,703.95 662.03,765.16 655.21,762.5 649.56,701.29 			"
              />
            </g>
            <g>
              <polygon
                className="st146"
                points="661.27,703.65 666.92,764.86 663.42,763.96 657.77,702.74 			"
              />
            </g>
            <g>
              <polygon
                className="st106"
                points="685.53,699.24 691.18,760.46 670.01,764.8 664.36,703.59 			"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: cebrayilColor ? cebrayilColor : null }}
                points="660.25,636.3 665.46,639.3 673.14,641.92 673.78,644 671.92,646.99 678.56,652.02 686.5,655.83 
688.35,660.23 696.99,660.14 703,658.04 708.25,658.09 713.71,658.38 715.58,654.99 715.11,648.41 722.37,644.29 730.24,647.64 
738.14,651.68 740.75,657.76 744.32,663.59 753.16,663.6 761.23,663.29 767.82,666.86 769.26,668.8 767.02,669.27 735.9,673.11 
720.24,676.65 716.1,678.41 714.12,680.45 699.7,688.8 691.21,696.28 689.11,697.63 685.53,699.24 664.36,703.59 661.27,703.65 
657.77,702.74 650.67,699.97 645.97,699.63 641.68,700.25 641.81,695.08 635.48,686.78 627.79,681.95 620.01,677.99 
618.53,672.05 620.83,665.06 617.6,659.88 615.81,655.21 620.32,649.36 627.64,646.66 636.92,649.9 648.25,651.62 655.91,644.38 
654.8,637.66 			"
              />
            </g>
            <g>
              <polygon
                className="st89"
                points="664.36,703.59 670.01,764.8 666.92,764.86 661.27,703.65 			"
              />
            </g>
            <g>
              <polygon
                className="st113"
                points="660.51,705.04 666.15,766.25 662.03,765.16 656.38,703.95 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="664.36,704.97 670.01,766.18 666.15,766.25 660.51,705.04 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M770.02,666.1l2.48,3.35l-1.3,0.36l-3.76,0.77l-31.07,3.84l-15.27,3.47l-3.16,1.37l-2.04,2.02l-14.42,8.36
l-8.37,7.38l-2.49,1.61l-4.26,1.87l-22,4.48l-3.85,0.07l-4.13-1.09l-6.82-2.66l-3.93-0.28l-6.43,1.02l0.01-0.82l0.17-5.85
l-6.09-7.83l-7.3-4.55l-8.29-4.21l-1.61-6.7l2.21-6.75l-3.06-4.94l-1.97-5.17l5.16-6.7l9.42-3.49l10.4,3.64l9,1.35l6.16-5.81
l-1.22-7.33l8.86-2.21l6.19,3.58l8.18,2.76l0.9,2.97l-1.66,2.66l5.86,4.45l8.22,3.89l1.64,3.86l6.13-0.06l6.06-2.12l6.1,0.06
l3.38,0.18l1.07-1.93l-0.48-6.97l9.7-5.54l9.6,4.11l8.45,4.3l2.73,6.39l3.06,4.99l7.2-0.01l8.99-0.34L770.02,666.1z
 M767.02,669.27l2.24-0.47l-1.44-1.94l-6.59-3.57l-8.07,0.31l-8.83-0.01l-3.57-5.84l-2.61-6.08l-7.91-4.03l-7.87-3.35l-7.25,4.12
l0.46,6.58l-1.86,3.38l-5.46-0.29l-5.26-0.05l-6.01,2.1l-8.63,0.09l-1.85-4.4l-7.95-3.81l-6.63-5.03l1.86-2.99l-0.64-2.08
l-7.68-2.62l-5.2-2.99l-5.46,1.36l1.11,6.72l-7.66,7.24l-11.33-1.72l-9.28-3.24l-7.31,2.7l-4.51,5.84l1.79,4.67l3.24,5.18
l-2.3,6.99l1.48,5.93l7.79,3.96l7.68,4.83l6.33,8.31l-0.13,5.17l4.29-0.62l4.7,0.33l7.1,2.78l3.5,0.91l3.09-0.06l21.17-4.35
l3.58-1.61l2.1-1.35l8.49-7.48l14.42-8.34l1.99-2.05l4.14-1.76l15.66-3.54L767.02,669.27"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="686.36,700.49 692.01,761.7 670.01,766.18 664.36,704.97 			"
              />
            </g>
          </g>
        </g>
        <g>
          <g
            className={`st0 ${region == 'zengilan' ? 'active' : null}`}
            onClick={() => openPopup('zengilan')}
          >
            <g>
              <polygon
                className="st32"
                points="519.36,681.27 525.01,742.48 517.9,745.04 512.25,683.82 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="528.13,682.73 533.77,743.95 525.01,742.48 519.36,681.27 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="570,683.83 575.65,745.05 575.27,743.04 569.62,681.82 			"
              />
            </g>
            <g>
              <polygon
                className="st98"
                points="512.25,683.82 517.9,745.04 515.42,750.85 509.77,689.64 			"
              />
            </g>
            <g>
              <polygon
                className="st74"
                points="577.99,684.56 583.64,745.77 578.13,744.93 572.48,683.72 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="572.67,684.77 578.32,745.98 578.13,744.93 572.48,683.72 			"
              />
            </g>
            <g>
              <polygon
                className="st28"
                points="568.28,685.47 573.93,746.68 568.33,746.32 562.68,685.11 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="572.67,684.77 578.32,745.98 573.93,746.68 568.28,685.47 			"
              />
            </g>
            <g>
              <polygon
                className="st3"
                points="542.96,689.26 548.61,750.48 533.77,743.95 528.13,682.73 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="562.68,685.11 568.33,746.32 552.86,750.49 547.21,689.28 			"
              />
            </g>
            <g>
              <polygon
                className="st108"
                points="547.21,689.28 552.86,750.49 548.61,750.48 542.96,689.26 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="514.67,694.06 520.32,755.27 515.42,750.85 509.77,689.64 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="512.63,694.95 518.28,756.17 512.8,751.22 507.15,690.01 			"
              />
            </g>
            <g>
              <polygon
                className="st111"
                points="522.47,697.86 528.11,759.08 520.32,755.27 514.67,694.06 			"
              />
            </g>
            <g>
              <polygon
                className="st87"
                points="520.62,698.86 526.27,760.08 518.28,756.17 512.63,694.95 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="633.65,687.97 639.29,749.19 627.23,751.6 621.58,690.39 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="621.58,690.39 627.23,751.6 614.59,754.68 608.94,693.47 			"
              />
            </g>
            <g>
              <polygon
                className="st77"
                points="528.12,701.83 533.77,763.04 528.11,759.08 522.47,697.86 			"
              />
            </g>
            <g>
              <polygon
                className="st66"
                points="525.67,702.44 531.32,763.66 526.27,760.08 520.62,698.86 			"
              />
            </g>
            <g>
              <polygon
                className="st114"
                points="577.99,684.56 583.64,745.77 579.71,760.81 574.06,699.6 			"
              />
            </g>
            <g>
              <polygon
                className="st51"
                points="580.7,683.51 586.35,744.72 582.31,760.19 576.66,698.97 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="608.94,693.47 614.59,754.68 602.49,759.95 596.84,698.73 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="639.39,695.36 645.04,756.58 639.29,749.19 633.65,687.97 			"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="528.12,701.83 533.77,763.04 534.1,770.37 528.45,709.15 			"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="584.81,705.17 590.46,766.38 579.71,760.81 574.06,699.6 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="596.84,698.73 602.49,759.95 590.46,766.38 584.81,705.17 			"
              />
            </g>
            <g>
              <polygon
                className="st90"
                points="639.39,695.36 645.04,756.58 644.88,761.99 639.23,700.77 			"
              />
            </g>
            <g>
              <polygon
                className="st118"
                points="641.82,695.09 647.47,756.31 647.3,762.79 641.65,701.57 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="639.23,700.77 644.88,761.99 640.11,763.52 634.47,702.31 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="641.65,701.57 647.3,762.79 646.47,762.98 640.83,701.77 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="640.83,701.77 646.47,762.98 641.3,764.63 635.65,703.42 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="634.47,702.31 640.11,763.52 634.92,765.82 629.27,704.61 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="635.65,703.42 641.3,764.63 636.47,766.77 630.82,705.56 			"
              />
            </g>
            <g>
              <polygon
                className="st46"
                points="528.45,709.15 534.1,770.37 532.88,776.54 527.23,715.32 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="629.27,704.61 634.92,765.82 624.42,772.31 618.77,711.1 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="618.77,711.1 624.42,772.31 622.34,773.38 616.69,712.17 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="630.82,705.56 636.47,766.77 626.05,773.23 620.4,712.02 			"
              />
            </g>
            <g>
              <polygon
                className="st99"
                points="527.23,715.32 532.88,776.54 529.15,782.49 523.51,721.27 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="616.69,712.17 622.34,773.38 619.99,774.12 614.34,712.91 			"
              />
            </g>
            <g>
              <polygon
                className="st26"
                points="614.34,712.91 619.99,774.12 614.43,775.23 608.78,714.01 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="620.4,712.02 626.05,773.23 623.48,774.53 617.83,713.31 			"
              />
            </g>
            <g>
              <polygon
                className="st50"
                points="608.78,714.01 614.43,775.23 611.77,776.07 606.12,714.85 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="617.83,713.31 623.48,774.53 620.76,775.36 615.11,714.15 			"
              />
            </g>
            <g>
              <polygon
                className="st34"
                points="615.11,714.15 620.76,775.36 615.22,776.46 609.57,715.24 			"
              />
            </g>
            <g>
              <polygon
                className="st32"
                points="609.57,715.24 615.22,776.46 612.97,777.19 607.32,715.98 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="606.12,714.85 611.77,776.07 606.48,779.05 600.83,717.83 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="607.32,715.98 612.97,777.19 608.26,779.87 602.61,718.65 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="523.51,721.27 529.15,782.49 522.37,788.73 516.72,727.52 			"
              />
            </g>
            <g>
              <polygon
                className="st67"
                points="516.72,727.52 522.37,788.73 521.93,789.27 516.29,728.05 			"
              />
            </g>
            <g>
              <polygon
                className="st58"
                points="516.29,728.05 521.93,789.27 521.12,789.96 515.47,728.75 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="600.83,717.83 606.48,779.05 598.94,784.79 593.3,723.58 			"
              />
            </g>
            <g>
              <polygon
                className="st41"
                points="515.47,728.75 521.12,789.96 519.07,792.28 513.42,731.07 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="602.61,718.65 608.26,779.87 600.33,785.84 594.69,724.63 			"
              />
            </g>
            <g>
              <polygon
                className="st116"
                points="593.3,723.58 598.94,784.79 593.52,786.79 587.87,725.58 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="513.42,731.07 519.07,792.28 517.43,794.71 511.78,733.5 			"
              />
            </g>
            <g>
              <polygon
                className="st133"
                points="594.69,724.63 600.33,785.84 594.2,788.06 588.55,726.85 			"
              />
            </g>
            <g>
              <polygon
                className="st71"
                points="587.87,725.58 593.52,786.79 575.17,790.1 569.52,728.88 			"
              />
            </g>
            <g>
              <polygon
                className="st35"
                points="588.55,726.85 594.2,788.06 575.81,791.38 570.16,730.16 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="569.52,728.88 575.17,790.1 568.63,792.19 562.99,730.98 			"
              />
            </g>
            <g>
              <polygon
                className="st6"
                points="511.78,733.5 517.43,794.71 516.61,797.07 510.96,735.85 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="570.16,730.16 575.81,791.38 569.79,793.33 564.14,732.11 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="562.99,730.98 568.63,792.19 563.35,794.74 557.7,733.52 			"
              />
            </g>
            <g>
              <polygon
                className="st123"
                points="510.96,735.85 516.61,797.07 516.65,799.51 511,738.29 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="564.14,732.11 569.79,793.33 564.94,795.68 559.29,734.47 			"
              />
            </g>
            <g>
              <polygon
                className="st30"
                points="557.7,733.52 563.35,794.74 550.91,803.49 545.26,742.27 			"
              />
            </g>
            <g>
              <polygon
                className="st37"
                points="559.29,734.47 564.94,795.68 552.67,804.32 547.02,743.1 			"
              />
            </g>
            <g>
              <polygon
                className="st39"
                points="545.26,742.27 550.91,803.49 546.47,806.07 540.82,744.85 			"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="547.02,743.1 552.67,804.32 547.98,807.06 542.33,745.84 			"
              />
            </g>
            <g>
              <polygon
                className="st73"
                points="540.82,744.85 546.47,806.07 541.59,808.14 535.94,746.93 			"
              />
            </g>
            <g>
              <polygon
                className="st107"
                points="535.94,746.93 541.59,808.14 536.42,809.78 530.77,748.57 			"
              />
            </g>
            <g>
              <polygon
                className="st31"
                points="542.33,745.84 547.98,807.06 542.74,809.26 537.1,748.05 			"
              />
            </g>
            <g>
              <polygon
                className="st10"
                points="515.01,750.39 520.66,811.6 516.65,799.51 511,738.29 			"
              />
            </g>
            <g>
              <polygon
                className="st96"
                points="530.77,748.57 536.42,809.78 531.32,810.98 525.67,749.77 			"
              />
            </g>
            <g>
              <polygon
                className="st135"
                points="519.15,750.61 524.8,811.83 520.66,811.6 515.01,750.39 			"
              />
            </g>
            <g>
              <polygon
                className="st129"
                points="525.67,749.77 531.32,810.98 524.8,811.83 519.15,750.61 			"
              />
            </g>
            <g>
              <path
                className="st60"
                d="M641.82,695.09l-0.17,6.48l-0.82,0.2l-5.18,1.65l-4.83,2.14l-10.42,6.46l-2.56,1.29l-2.72,0.83l-5.54,1.1
l-2.26,0.73l-4.7,2.68l-7.93,5.98l-6.13,2.22l-18.39,3.31l-6.03,1.95l-4.84,2.36l-12.27,8.63l-4.69,2.74l-5.24,2.21l-5.48,1.74
l-5.44,1.27l-7.34,0.93l-5.94-0.3l-4.33-13.11l-0.04-2.77l0.93-2.53l1.73-2.63l2.24-2.49l0.78-0.66l0.39-0.5l6.77-6.26l3.45-5.57
l1.2-5.92l-0.34-6.8l-5.06-3.58l-7.99-3.91l-5.48-4.94l2.98-6.97l9.11-3.26l10.35,1.77l14.41,6.34l2.93,0l15.57-4.2l6.07,0.4
l1.43-0.26l-0.38-2.01l11.08,1.68l-4.04,15.46l8.33,4.31l10.37-5.55l12.67-5.49l12.89-3.13l14.18-2.85L641.82,695.09z
 M634.47,702.31l4.76-1.53l0.16-5.41l-5.74-7.39l-12.06,2.41l-12.64,3.08l-12.1,5.27l-12.03,6.43l-10.75-5.57l3.93-15.04
l-5.51-0.84l0.19,1.05l-4.39,0.7l-5.6-0.36l-15.47,4.17l-4.25-0.02l-14.83-6.53l-8.76-1.47l-7.11,2.55l-2.48,5.82l4.9,4.42
l7.79,3.81l5.65,3.96l0.34,7.33l-1.23,6.17l-3.72,5.95l-6.79,6.24l-0.43,0.54l-0.82,0.69l-2.05,2.32l-1.64,2.43l-0.82,2.35
l0.05,2.44l4.01,12.1l4.14,0.22l6.53-0.84l5.1-1.2l5.17-1.64l4.89-2.07l4.44-2.58l12.43-8.75l5.29-2.55l6.54-2.09l18.34-3.31
l5.43-2l7.53-5.74l5.29-2.98l2.66-0.84l5.56-1.11l2.35-0.74l2.08-1.07l10.5-6.49L634.47,702.31"
              />
            </g>
            <g>
              <polygon
                className="st59"
                style={{ fill: zengilanColor ? zengilanColor : null }}
                points="633.65,687.97 639.39,695.36 639.23,700.77 634.47,702.31 629.27,704.61 618.77,711.1 
616.69,712.17 614.34,712.91 608.78,714.01 606.12,714.85 600.83,717.83 593.3,723.58 587.87,725.58 569.52,728.88 
562.99,730.98 557.7,733.52 545.26,742.27 540.82,744.85 535.94,746.93 530.77,748.57 525.67,749.77 519.15,750.61 
515.01,750.39 511,738.29 510.96,735.85 511.78,733.5 513.42,731.07 515.47,728.75 516.29,728.05 516.72,727.52 523.51,721.27 
527.23,715.32 528.45,709.15 528.12,701.83 522.47,697.86 514.67,694.06 509.77,689.64 512.25,683.82 519.36,681.27 
528.13,682.73 542.96,689.26 547.21,689.28 562.68,685.11 568.28,685.47 572.67,684.77 572.48,683.72 577.99,684.56 
574.06,699.6 584.81,705.17 596.84,698.73 608.94,693.47 621.58,690.39 			"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="537.1,748.05 542.74,809.26 537.26,811 531.61,749.79 			"
              />
            </g>
            <g>
              <polygon
                className="st11"
                points="512.89,751.68 518.54,812.9 514.21,799.79 508.56,738.57 			"
              />
            </g>
            <g>
              <polygon
                className="st80"
                points="531.61,749.79 537.26,811 531.82,812.27 526.17,751.06 			"
              />
            </g>
            <g>
              <polygon
                className="st7"
                points="518.83,751.99 524.48,813.2 518.54,812.9 512.89,751.68 			"
              />
            </g>
            <g>
              <polygon
                className="st130"
                points="526.17,751.06 531.82,812.27 524.48,813.2 518.83,751.99 			"
              />
            </g>
          </g>
        </g>
      </svg>
      {isPopupOpen && (
        <MapPopup
          isOpen={isPopupOpen}
          onClose={closePopup}
          RegionData={RegionData}
          selectedRegion={selectedRegion}
        />
      )}{' '}
    </div>
  );
};

export const MapPopup = ({ isOpen, onClose, RegionData, selectedRegion }) => {
  const router = useRouter();
  const { translate, Language, currentLanguage } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const selectedHandle = selectedRegion;

  const selectedData = RegionData?.data?.find(
    (region) => region.handle === selectedHandle
  );

  const handleButtonClick = () => {
    router.push(`${selectedData?.button}`);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <Popup
      open={isOpen}
      modal
      nested
      contentStyle={{
        padding: '0px',
        borderRadius: '50px',
        borderColor: '#5b2d90',
        width: windowWidth > 768 ? '50%' : '80%',
        height: '50%',
        overflow: 'auto',
      }}
    >
      {(close) => (
        <>
          <div
            onClick={() => {
              onClose();
            }}
            className="absolute cursor-pointer rounded-3xl bg-[#5B2D90]  flex justify-center items-center text-white right-5 top-5 w-[30px] h-[30px] max-sm:w-[20px] max-sm:h-[21px]"
          >
            X
          </div>

          <div className="absolute top-10 w-[439px]  left-10">
            <h3 className="region-name">{selectedData?.name}</h3>
            <span
              dangerouslySetInnerHTML={{
                __html: selectedData?.description,
              }}
            ></span>
            {selectedData?.button && (
              <button
                onClick={handleButtonClick}
                className="w-[178px] h-[40px] block bg-[#5B2D90] rounded-3xl text-[16px] text-white font-medium mt-5"
              >
                {translate('Register', Language)}
              </button>
            )}
          </div>
        </>
      )}
    </Popup>
  );
};
